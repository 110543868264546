import React, {useCallback, useMemo} from "react";
import {LayerType} from "../../../../model/LayerType";
import CrossSectionX from "../Widgets/CrossSectionX";
import CrossSectionY from "../Widgets/CrossSectionY";
import CrossSectionZ from "../Widgets/CrossSectionZ";
import LineSegment, {ISegment} from "../BasicControls/ParallelLineSegments";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import {withTranslation, WithTranslation} from "react-i18next";
import {Cartesian3} from "cesium";
import {DEFAULT_NEUTRAL_COLOR} from "../../domain/IStyle";
import {CesiumMovementEvent} from "resium";

interface IProps extends WithTranslation {
  blockSize: Cartesian3;
  onLeftClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onRightClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onDoubleClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseMove?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseUp?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseDown?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
}

const BISECT_OFFSET = 0.05 ;
const BISECT_COLOR = DEFAULT_NEUTRAL_COLOR ;

const CrossSectionFrames: React.FC<IProps> = (props) => {
  const [siteData] = useSiteConfig();
  const [userSession] = useUserSessionContext();

  // ... Compute the intersection between the XZ and XY cross-section planes
  const xLines = useMemo((): ISegment[] => {
    if ( !userSession.layersVisibility.CrossSectionZ || !userSession.layersVisibility.CrossSectionY || !userSession.boundingBox ) {
      return [] ;
    }
    const y = userSession.boundingBox.minPos.y + userSession.crossSectionOffsetY;
    const z = userSession.boundingBox.minPos.z + userSession.crossSectionOffsetZ;

    return [
      { p1: new Cartesian3( userSession.boundingBox.minPos.x, y+BISECT_OFFSET, z+BISECT_OFFSET ), p2: new Cartesian3( userSession.boundingBox.maxPos.x, y, z ) },
      { p1: new Cartesian3( userSession.boundingBox.minPos.x, y+BISECT_OFFSET, z-BISECT_OFFSET ), p2: new Cartesian3( userSession.boundingBox.maxPos.x, y, z ) },
      { p1: new Cartesian3( userSession.boundingBox.minPos.x, y-BISECT_OFFSET, z-BISECT_OFFSET ), p2: new Cartesian3( userSession.boundingBox.maxPos.x, y, z ) },
      { p1: new Cartesian3( userSession.boundingBox.minPos.x, y-BISECT_OFFSET, z+BISECT_OFFSET ), p2: new Cartesian3( userSession.boundingBox.maxPos.x, y, z ) },
    ] ;

  }, [
    userSession.layersVisibility.CrossSectionY,
    userSession.layersVisibility.CrossSectionZ,
    userSession.crossSectionOffsetY,
    userSession.crossSectionOffsetZ,
    userSession.boundingBox
  ]);

  // ... Compute the intersection between the XY and YZ cross-section planes
  const yLines = useMemo((): ISegment[] => {
    if ( !userSession.layersVisibility.CrossSectionZ || !userSession.layersVisibility.CrossSectionX || !userSession.boundingBox ) {
      return [] ;
    }

    const x = userSession.boundingBox.minPos.x + userSession.crossSectionOffsetX;
    const z = userSession.boundingBox.minPos.z + userSession.crossSectionOffsetZ;

    return [
      { p1: new Cartesian3( x+BISECT_OFFSET, userSession.boundingBox.minPos.y, z+BISECT_OFFSET ), p2:new Cartesian3( x, userSession.boundingBox.maxPos.y, z ) },
      { p1: new Cartesian3( x+BISECT_OFFSET, userSession.boundingBox.minPos.y, z-BISECT_OFFSET ), p2:new Cartesian3( x, userSession.boundingBox.maxPos.y, z ) },
      { p1: new Cartesian3( x-BISECT_OFFSET, userSession.boundingBox.minPos.y, z-BISECT_OFFSET ), p2:new Cartesian3( x, userSession.boundingBox.maxPos.y, z ) },
      { p1: new Cartesian3( x-BISECT_OFFSET, userSession.boundingBox.minPos.y, z+BISECT_OFFSET ), p2:new Cartesian3( x, userSession.boundingBox.maxPos.y, z ) },
    ] ;

  }, [
    userSession.layersVisibility.CrossSectionX,
    userSession.layersVisibility.CrossSectionZ,
    userSession.crossSectionOffsetX,
    userSession.crossSectionOffsetZ,
    userSession.boundingBox
  ]);

  // ... Compute the intersection between the XZ and YZ cross-section planes
  const zLines = useMemo((): ISegment[] => {
    if ( !userSession.layersVisibility.CrossSectionY || !userSession.layersVisibility.CrossSectionX || !userSession.boundingBox) {
      return [] ;
    }

    const x = userSession.boundingBox.minPos.x + userSession.crossSectionOffsetX;
    const y = userSession.boundingBox.minPos.y + userSession.crossSectionOffsetY;

    return [
      { p1: new Cartesian3( x+BISECT_OFFSET, y+BISECT_OFFSET, userSession.boundingBox.minPos.z), p2: new Cartesian3( x, y, userSession.boundingBox.maxPos.z) },
      { p1: new Cartesian3( x+BISECT_OFFSET, y-BISECT_OFFSET, userSession.boundingBox.minPos.z), p2: new Cartesian3( x, y, userSession.boundingBox.maxPos.z) },
      { p1: new Cartesian3( x-BISECT_OFFSET, y-BISECT_OFFSET, userSession.boundingBox.minPos.z), p2: new Cartesian3( x, y, userSession.boundingBox.maxPos.z) },
      { p1: new Cartesian3( x-BISECT_OFFSET, y+BISECT_OFFSET, userSession.boundingBox.minPos.z), p2: new Cartesian3( x, y, userSession.boundingBox.maxPos.z) },
    ] ;

  }, [
    userSession.layersVisibility.CrossSectionX,
    userSession.layersVisibility.CrossSectionY,
    userSession.crossSectionOffsetX,
    userSession.crossSectionOffsetZ,
    userSession.boundingBox
  ]);

  const handleLeftClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onLeftClick ) {
      props.onLeftClick( movement, target );
    }
  }, []);
  const handleRightClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onRightClick ) {
      props.onRightClick( movement, target );
    }
  }, []);
  const handleDoubleClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onDoubleClick ) {
      props.onDoubleClick( movement, target );
    }
  }, []);
  const handleMouseMove = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseMove ) {
      props.onMouseMove( movement, target );
    }
  }, []);
  const handleMouseUp = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseUp ) {
      props.onMouseUp( movement, target );
    }
  }, []);
  const handleMouseDown = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseDown ) {
      props.onMouseDown( movement, target );
    }
  }, []);

  return (
      <>
        { userSession.originReference && userSession.boundingBox && (
            <>
              {userSession.layersVisibility.CrossSectionX &&
                  <CrossSectionX
                      originReference={userSession.originReference}
                      boundingBox={userSession.boundingBox}
                      blockSize={props.blockSize}
                      properties={{Layer: LayerType[LayerType.CrossSectionX]}}
                      onLeftClick={handleLeftClick}
                      onRightClick={handleRightClick}
                      onDoubleClick={handleDoubleClick}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      onMouseDown={handleMouseDown}
                  />
              }
              {userSession.layersVisibility.CrossSectionY &&
                  <CrossSectionY
                      originReference={userSession.originReference}
                      boundingBox={userSession.boundingBox}
                      blockSize={props.blockSize}
                      properties={{Layer: LayerType[LayerType.CrossSectionY]}}
                      onLeftClick={handleLeftClick}
                      onRightClick={handleRightClick}
                      onDoubleClick={handleDoubleClick}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      onMouseDown={handleMouseDown}
                  />
              }
              {userSession.layersVisibility.CrossSectionZ &&
                  <CrossSectionZ
                      originReference={userSession.originReference}
                      boundingBox={userSession.boundingBox}
                      blockSize={props.blockSize}
                      properties={{Layer: LayerType[LayerType.CrossSectionZ]}}
                      onLeftClick={handleLeftClick}
                      onRightClick={handleRightClick}
                      onDoubleClick={handleDoubleClick}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      onMouseDown={handleMouseDown}
                  />
              }
              {
                  (xLines.length > 0) && (
                      <LineSegment
                          name={"XY-XZ Intersection"}
                          show={true}
                          segments={xLines}
                          lineWidth={userSession.selectedCrossSectionStyle.outlineThickness}
                          color={BISECT_COLOR}
                          modelMatrix={siteData.modelMatrix}
                      />
                  )
              }
              {
                  (yLines.length > 0) && (
                      <LineSegment
                          name={"XY-XZ Intersection"}
                          show={true}
                          segments={yLines}
                          lineWidth={userSession.selectedCrossSectionStyle.outlineThickness}
                          color={BISECT_COLOR}
                          modelMatrix={siteData.modelMatrix}
                      />
                  )
              }
              {
                  (zLines.length > 0) && (
                      <LineSegment
                          name={"XY-XZ Intersection"}
                          show={true}
                          segments={zLines}
                          lineWidth={userSession.selectedCrossSectionStyle.outlineThickness}
                          color={BISECT_COLOR}
                          modelMatrix={siteData.modelMatrix}
                      />
                  )
              }
            </>
        )}
      </>
  )
}

export default withTranslation()(CrossSectionFrames);
