import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {CSSProperties, useState} from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { useFetchWithAuth } from "../../lib/auth/fetchWithAuth";
import { UserAccountApi } from "../../lib/routes";
import UserSession from "../../lib/userSession";

interface Props extends WithTranslation {
  className?:string|undefined,
  style?:CSSProperties|undefined;
}

const validateForm = (
  previousPassword: string,
  password: string,
  confirmPassword: string,
  t: (key: string) => string
) => {
  let errors: any = {};

  if (password !== confirmPassword)
    errors.confirmPassword = t("passwords_must_be_identical");

  if (!previousPassword || previousPassword.length === 0)
    errors.previousPassword = t("cannot_be_empty");
  if (!password || password.length === 0)
    errors.password = t("cannot_be_empty");
  if (!confirmPassword || confirmPassword.length === 0)
    errors.confirmPassword = t("cannot_be_empty");

  return errors;
};

const ChangePasswordForm: React.FC<Props> = (props) => {
  const [errors, setErrors] = useState<any>({});
  const [updating, setUpdating] = useState<boolean>(false);
  const [passwordUpdated, setPasswordUpdated] = useState<boolean>(false);

  const fetchWithAuth = useFetchWithAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const eventTarget = e.target as any;
    const previousPassword = eventTarget.elements.previousPassword.value;
    const password = eventTarget.elements.password.value;
    const confirmPassword = eventTarget.elements.confirmPassword.value;

    const errors = validateForm(previousPassword, password, confirmPassword, props.t);
    setErrors(errors);

    if (Object.keys(errors).length === 0)
      updatePassword(previousPassword, password);
  };

  const updatePassword = async (
    oldPassword: string,
    newPassword: string
  ) => {
    setPasswordUpdated(false);
    setUpdating(true);
    const response = await fetchWithAuth(
      UserAccountApi.updateUserPasswordUrl(),
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
        },
        body: JSON.stringify({ oldPassword, newPassword }),
      }
    );
    setUpdating(false);

    if (response.ok) {
      setPasswordUpdated(true);
    } else if (response.status === 400) {
      setErrors({
        previousPassword: props.t("invalid_password"),
      });
    }
  };

  return (
    <div
        className={`${props.className}`}
        style={{
          ...props.style,
          maxWidth: "24em"
        }}
    >
      <form onSubmit={handleSubmit}>
        <label htmlFor="password">
          <b>{props.t("previous_password")}</b>
        </label>
        <input
          id="previousPassword"
          className="w3-input w3-border"
          type="password"
          placeholder={props.t("enter_password")}
          name="previousPassword"
          autoComplete="new-password"
        />
        {errors.previousPassword && (
          <span className="w3-text-red">
            <FontAwesomeIcon icon={faWarning} /> {errors.previousPassword}
            <br />
          </span>
        )}
        <br />
        <label htmlFor="password">
          <b>{props.t("new_password")}</b>
        </label>
        <input
          id="password"
          className="w3-input w3-border"
          type="password"
          placeholder={props.t("enter_password")}
          name="password"
          autoComplete="new-password"
        />
        {errors.password && (
          <span className="w3-text-red">
            <FontAwesomeIcon icon={faWarning} /> {errors.password}
            <br />
          </span>
        )}
        <label htmlFor="password">
          <b>
            {props.t("confirm")} {props.t("new_password").toLowerCase()}
          </b>
        </label>
        <input
          id="confirmPassword"
          className="w3-input w3-border"
          type="password"
          placeholder={props.t("enter_password")}
          name="confirmPassword"
          autoComplete="new-password"
        />
        {errors.confirmPassword && (
          <span className="w3-text-red">
            <FontAwesomeIcon icon={faWarning} /> {errors.confirmPassword}
          </span>
        )}
        <label>&nbsp;</label>
        <button
          className="w3-button w3-light-blue w3-round-medium w3-block"
          type="submit"
          disabled={updating}
        >
          {props.t("update_password")}
        </button>
        {passwordUpdated && <i>{props.t("password_update_successful")}</i>}
      </form>
    </div>
  );
};

export default withTranslation()(ChangePasswordForm);
