import React from "react";
import SideMenuRow from "../../../SideMenuItems/SideMenuRow";
import {OrthogonalDirection} from "../../../../model/OrthogonalDirection";
import {t} from "i18next";
import cubeDown from "../../../../img/cube_down.png";
import cubeNorth from "../../../../img/cube_north.png";
import cubeEast from "../../../../img/cube_east.png";
import cubeUp from "../../../../img/cube_up.png";
import cubeSouth from "../../../../img/cube_south.png";
import cubeWest from "../../../../img/cube_west.png";

interface IProps {
  onViewOrthogonalDirection: (direction: OrthogonalDirection) => void;
}
const btnIconSize = 24;
const btnIconMarginLeft = 5;

const CameraPresetsSideMenuItem: React.FC<IProps> = (props) => {
  return (
      <div>
        <div className={"w3-margin-top"}>
          <SideMenuRow widths={["33%", "34%", "33%"]} >
            <ImageButton label={t("down")} image={cubeDown} onClick={()=>props.onViewOrthogonalDirection(OrthogonalDirection.Down)}/>
            <ImageButton label={t("north")} image={cubeNorth} onClick={()=>props.onViewOrthogonalDirection(OrthogonalDirection.North)}/>
            <ImageButton label={t("east")} image={cubeEast} onClick={()=>props.onViewOrthogonalDirection(OrthogonalDirection.East)}/>
          </SideMenuRow>
        </div>

        <div className={"w3-margin-top w3-margin-bottom"}>
          <SideMenuRow
              widths={["33%", "34%", "33%"]}
          >
            <ImageButton label={t("up")} image={cubeUp} onClick={()=>props.onViewOrthogonalDirection(OrthogonalDirection.Up)}/>
            <ImageButton label={t("south")} image={cubeSouth} onClick={()=>props.onViewOrthogonalDirection(OrthogonalDirection.South)}/>
            <ImageButton label={t("west")} image={cubeWest} onClick={()=>props.onViewOrthogonalDirection(OrthogonalDirection.West)}/>
          </SideMenuRow>
        </div>
      </div>
  )
}

export default CameraPresetsSideMenuItem ;

interface ImageBtnProps {
  label: string;
  image: string;
  onClick: () => void;
}

const ImageButton: React.FC<ImageBtnProps> = (props)=>{
  return (
      <button
          className="w3-button w3-border w3-border-light-blue w3-hover-light-blue w3-round-medium unselectable"
          style={{ fontSize: "12px", width:"100%", height:"100%" }}
          onClick={(e) => {props.onClick()}}
      >
        {props.label}
        <img src={props.image} style={{marginLeft: `${btnIconMarginLeft}px`, width:`${btnIconSize}px`, height:`${btnIconSize}px`}} alt={""}/>
      </button>
  )
}
