import React, {useContext, useEffect, useMemo, useState} from "react";
import {
  Cartesian3,
  Cartesian4, Color,
  Matrix4
} from "cesium";
import {t} from "i18next";
import Axis from "./Axis";
import {withTranslation, WithTranslation} from "react-i18next";
import {useSiteConfig} from "../../hooks/useSiteConfig";

interface IProps extends WithTranslation {
  show: boolean;
  originReference: Cartesian3;
  size: Cartesian3;
  color?: Color;
}

const AxesXYZ: React.FC<IProps> = (props) => {
  const [siteData] = useSiteConfig();

  const [state, setState] = useState<{
    transformedOrigin: Cartesian3;
    xAxisEnd: Cartesian3;
    yAxisEnd: Cartesian3;
    zAxisEnd: Cartesian3;
  }>();

  useEffect(()=>{
    if ( siteData ) {
        setState({
          transformedOrigin: Cartesian3.fromCartesian4(
              Matrix4.multiplyByVector(
                  siteData.modelMatrix,
                  Cartesian4.fromArray([0, 0, 0, 1]),
                  new Cartesian4()
              )
          ),
          xAxisEnd: Cartesian3.fromCartesian4(
              Matrix4.multiplyByVector(
                  siteData.modelMatrix,
                  Cartesian4.fromArray([props.size.x, 0, 0, 1]),
                  new Cartesian4()
              )
          ),
          yAxisEnd: Cartesian3.fromCartesian4(
              Matrix4.multiplyByVector(
                  siteData.modelMatrix,
                  Cartesian4.fromArray([0, props.size.y, 0, 1]),
                  new Cartesian4()
              )
          ),
          zAxisEnd: Cartesian3.fromCartesian4(
              Matrix4.multiplyByVector(
                  siteData.modelMatrix,
                  Cartesian4.fromArray([0, 0, props.size.z, 1]),
                  new Cartesian4()
              )
          ),
        })
      }
  }, [siteData]);

  return (
      <>
        { state && (
          <>
            <Axis
                show={props.show}
                label={t("easting")}
                start={state.transformedOrigin}
                end={state.xAxisEnd}
                color={props.color}
            />
            <Axis
                show={props.show}
                label={t("northing")}
                start={state.transformedOrigin}
                end={state.yAxisEnd}
                color={props.color}
            />
            <Axis
                show={props.show}
                label={t("elevation")}
                start={state.transformedOrigin}
                end={state.zAxisEnd}
                color={props.color}
            />
          </>
        ) }
      </>
  )
}

export default withTranslation()(React.memo(AxesXYZ));
