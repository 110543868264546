import React, {useEffect, useState} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {t} from "i18next";
import {faCircle, faSearch} from "@fortawesome/free-solid-svg-icons";
import InputWithIcon from "../components/Gen2/components/BasicControls/InputWithIcon";
import {IHelpRequestedProps} from "./HelpContextProvider";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IProps extends WithTranslation {
  topics: IHelpTopic[];
}

export interface IHelpTopic extends IHelpRequestedProps {
  icon?: IconProp;
  showWhenEmptySearchQuery?:boolean;
}

const Help_Navigator: React.FC<IProps> = (props)=>{

  const [filteredHelpTopics, setFilteredHelpTopics] = useState<IHelpTopic[]>(props.topics);
  const [selectedTopic, setSelectedTopic] = useState<IHelpTopic|undefined>();

  function handleSearchChanged(value: string) {
    let trimmedValue = value.trim();
    setFilteredHelpTopics( props.topics.filter( topic => {
      if ( (trimmedValue.length === 0) && topic.helpTitle ) {
        return topic.showWhenEmptySearchQuery ;
      }
      return topic.helpTitle && (topic.helpTitle.toLowerCase().indexOf( trimmedValue.toLowerCase() ) !== -1) ;
    }));
  }

  useEffect(()=>{
    if ( selectedTopic && filteredHelpTopics.indexOf(selectedTopic) === -1 ) {
      setSelectedTopic( undefined );
    }
  }, [filteredHelpTopics]);

  useEffect(()=>{
    handleSearchChanged('');
  }, []);

  return (
      <div style={{ width: "100%", }}>
        {/* Top bar */}
        <div style={{display: "flex", alignItems: "center"}}>
          {/* Title */}
          <span
              className={"w3-half w3-large w3-left-align"}
          >
            {t('GeoModeling Viewer Help')}
          </span>

          {/* Search box */}
          <div className={"w3-half w3-right"}>
            <InputWithIcon
                icon={faSearch}
                type={"text"}
                placeholder={t('Search...')}
                onChange={(e)=>handleSearchChanged(e.currentTarget.value)}
            />
          </div>
        </div>


        {/* Content */}
        <div
            className={"w3-row w3-margin-top"}
            style={{
              height: "calc(100vh*0.70)",
              display: "flex",
              // alignItems: "center",
            }}
        >
          {/* TOC (Master) */}
          <ul
              className={"w3-ul w3-col m4 l3"}
              style={{height: "100%"}}
          >
            {
              filteredHelpTopics.map( (topic, idx) => (
                  <li
                      key={`filteredHelpTopic_${idx}`}
                      style={{borderBottom: "none"}}
                      className={`unselectable w3-display-container w3-hover-light-blue w3-left-align ${selectedTopic === topic && "w3-light-blue"}`}
                      onClick={()=>setSelectedTopic(topic)}
                  >
                    <span className={'w3-margin-right'}>
                      {topic.icon ? <FontAwesomeIcon icon={topic.icon}/> : '- '}
                    </span>
                    {topic.helpTitle}
                  </li>
              ))
            }
          </ul>

          {/* Details/search results */}
          <div
              className={"w3-col m8 l9 w3-theme-d3 w3-card-4 w3-padding"}
              style={{
                height: "100%",
                overflowX: "auto",
                overflowY: "auto",
              }}
          >
            {selectedTopic?.helpContent}
          </div>
        </div>
      </div>
  )
}

export default withTranslation()(Help_Navigator);
