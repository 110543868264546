import {LegendType} from "./LegendType";
import IColorLegendItemConfiguration from "./IColorLegendItemConfiguration";
import {Color} from "cesium";
  import {IColorLegend, IColorLegendItem} from "../hooks/useColorLegendApi";

export default interface IColorLegendConfiguration {
  legendType: LegendType;
  invalidItem: IColorLegendItemConfiguration;
  rangeExceededItem: IColorLegendItemConfiguration|undefined ;
  items: IColorLegendItemConfiguration[];
  maskedColor: string;
  defaultRange: number;
  units: string;
  precision: number;
}

export const DEFAULT_COLOR_LEGEND_CONFIGURATION: IColorLegendConfiguration = {
  legendType: LegendType.Ranges,
  invalidItem: {
    range: [],
    label: undefined,
    color: Color.BLACK,
  },
  rangeExceededItem: {
    range: [],
    label: undefined,
    color: Color.BLACK,
  },
  items: [],
  maskedColor: "#000000",
  defaultRange: 100,
  units: "",
  precision: 0
}

export function pickColorLegend(value: number, colorLegendConfig: IColorLegendConfiguration, colorLegendState: IColorLegend) : [IColorLegendItemConfiguration, IColorLegendItem] | undefined {
  if ( colorLegendConfig.legendType === LegendType.Enum || colorLegendConfig.legendType === LegendType.Mapped ) {
    for ( let i = 0; i < colorLegendConfig.items.length; i+=1 ) {
      if ( value >= colorLegendConfig.items[i].range[0] && value < colorLegendConfig.items[i].range[1]) {
        return [colorLegendConfig.items[i], colorLegendState.colorLegendItems[i]] ;
      }
    }
    return [colorLegendConfig.invalidItem, colorLegendState.colorInvalidValue];
  }
  else if ( colorLegendConfig.legendType === LegendType.Ranges ) {
    if ( value < 0 ) {
      return [colorLegendConfig.invalidItem, colorLegendState.colorInvalidValue];
    }
    for ( let i = 0; i < colorLegendConfig.items.length; i+=1 ) {
      if ( value >= colorLegendConfig.items[i].range[0] && value < colorLegendConfig.items[i].range[1]) {
        return [colorLegendConfig.items[i], colorLegendState.colorLegendItems[i]] ;
      }
    }
    return (colorLegendConfig.rangeExceededItem && colorLegendState.colorRangeExceeded )
      ? [colorLegendConfig.rangeExceededItem, colorLegendState.colorRangeExceeded]
      : [colorLegendConfig.invalidItem, colorLegendState.colorInvalidValue];
  }
  else {
    return undefined;
  }
}

export function pickColorLegendColor(value: number, colorLegendConfig: IColorLegendConfiguration, colorLegendStyle: IColorLegend, defaultColor: Color): Color | undefined {

  const colorLegend = pickColorLegend( value, colorLegendConfig, colorLegendStyle );
  if ( !colorLegend ) {
    return undefined ;
  }
  const [config, state] = colorLegend ;
  return state.visible ? config.color : defaultColor ;
}