import {Cartesian3, Color, Matrix4, PolygonHierarchy, PropertyBag, ShadowMode} from "cesium";
import {t} from "i18next";
import {CesiumMovementEvent, Entity, PolygonGraphics} from "resium";
import React, {useCallback, useMemo} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {TilesetUtils} from "../../util/TilesetUtils";

interface IProps extends WithTranslation {
  name: string;
  show: boolean;
  modelMatrix: Matrix4;
  ecefVertices: Cartesian3[];
  outline?: boolean
  outlineColor?: Color;
  outlineWidth?: number;
  fillColor?: Color ;
  properties?: PropertyBag | {
    [key: string]: any;
  }
  onLeftClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onRightClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onDoubleClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseMove?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseUp?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseDown?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
}

const FilledRectangle: React.FC<IProps> = (props)=>{
  // console.log("FilledRectangle: rendering");

  const transformedVertices = useMemo(() => {
    // console.log("FilledRectangle: rendering transformedVertices");
    return props.ecefVertices.map(vertex => TilesetUtils.localToWorld(vertex, props.modelMatrix));
  }, [props.modelMatrix, props.ecefVertices]);

  const handleLeftClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onLeftClick ) {
      props.onLeftClick( movement, target );
    }
  }, []);
  const handleRightClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onRightClick ) {
      props.onRightClick( movement, target );
    }
  }, []);
  const handleDoubleClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onDoubleClick ) {
      props.onDoubleClick( movement, target );
    }
  }, []);
  const handleMouseMove = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseMove ) {
      props.onMouseMove( movement, target );
    }
  }, []);
  const handleMouseUp = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseUp ) {
      props.onMouseUp( movement, target );
    }
  }, []);
  const handleMouseDown = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseDown ) {
      props.onMouseDown( movement, target );
    }
  }, []);

  return (
      <>
        {transformedVertices &&
            <Entity
                name={t(props.name)}
                show={props.show}
                properties={props.properties}
                onClick={handleLeftClick}
                onRightClick={handleRightClick}
                onDoubleClick={handleDoubleClick}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseDown={handleMouseDown}
            >
              {/*Encapsulating rectangle*/}
              <PolygonGraphics
                  hierarchy={{
                    positions: transformedVertices,
                    holes: []
                  } as PolygonHierarchy}
                  perPositionHeight={true}
                  material={props.fillColor}
                  outline={props.outline}
                  outlineColor={props.outlineColor}
                  outlineWidth={props.outlineWidth}
                  shadows={ShadowMode.ENABLED}
              />
            </Entity>
        }
      </>
  )
}

export default withTranslation()(React.memo(FilledRectangle));
