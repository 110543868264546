import {withTranslation, WithTranslation} from "react-i18next";
import Cesium, {JulianDate, Property} from "cesium";
import React, {useCallback, useEffect, useMemo} from "react";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {ColorUtil} from "../../../../lib/ColorUtil";

interface IEntityInfoboxProps extends WithTranslation {
  title?:string;
  entity: Cesium.Entity;
  className?:string;
  style?:any;
}

const EntityInfobox: React.FC<IEntityInfoboxProps> = (props) => {
  const [, actions] = useSiteConfig();
  const t = props.t;

  // ... Define a callback for formatting each feature property
  const getFormattedValue = useCallback(( attributeName: string, attributeValue: any ): string|undefined =>{
    if ( !attributeValue ) {
      return undefined;
    }
    const precision = actions.getPrecisionForAttribute( attributeName );
    if ( precision ) {
      return attributeValue.toFixed(precision);
    }
    else if ( attributeValue instanceof Array ) {
      return JSON.stringify(attributeValue, function(key, val) {
        return val.toFixed ? Number(val.toFixed(3)) : val;
      });
    }
    else if ( attributeValue instanceof Object ) {
      return JSON.stringify(attributeValue, function(key, val) {
        return val.toFixed ? Number(val.toFixed(3)) : val;
      });
    }
    return attributeValue ? t(attributeValue) : attributeValue;
  }, [actions]);

  const featureProperties = useMemo(()=>
      props.entity.description
        ? Array.from( ( (props.entity.description as Property)?.getValue(JulianDate.fromDate(new Date())) as Map<string,any> ).entries())
        : []
      , [props.entity]);

  const infoBoxLabel = useMemo((): string =>{
    return props.title ?? t(props.entity.name ?? 'Unnamed Entity');
  }, [props.entity, props.title]);

  return (
      <div className={props.className} style={props.style}>
        <div className={"w3-center w3-small w3-padding-small"}>
          {`${t(infoBoxLabel)}`}
        </div>
        <table className={`w3-table w3-tiny w3-border ${props.className}`}>
          <tbody>
          {
            featureProperties.map( ([k, v], idx) => (
                  <tr
                      key={`FeatureInfobox_r${idx}`}
                      className={`w3-border ${props.className}`}
                  >
                    <td>{t(k)}</td>
                    <td>{getFormattedValue(k, v)}</td>
                  </tr>
              )
            )
          }
          </tbody>
        </table>
      </div>
  )
}

export default withTranslation()(EntityInfobox);
