import {Cartesian3, Color, InfoBox, Matrix4, Plane, PolygonHierarchy} from "cesium";
import {withTranslation} from "react-i18next";
import React, {useEffect, useMemo} from "react";
import {Entity, PolygonGraphics, PolylineGraphics} from "resium";
import {t} from "i18next";
import { TilesetUtils } from "../../util/TilesetUtils";

export interface IWireframeBoxProps {
  show: boolean;
  name?: string;
  description?: string;
  color?: Color;
  lineWidth?: number;
  minPosECEF: Cartesian3;
  maxPosECEF: Cartesian3;
  modelMatrix: Matrix4;
}

const DEFAULT_WIREFRAME_BOX_LINE_WIDTH = 0.5;
const DEFAULT_WIREFRAME_BOX_LINE_COLOR = Color.WHITE;

interface IBoxCoordinates {
  // ... Top Rectangle
  xyz: Cartesian3;
  xYz: Cartesian3;
  XYz: Cartesian3;
  Xyz: Cartesian3;

  // ... Bottom rectangle
  xyZ: Cartesian3;
  xYZ: Cartesian3;
  XYZ: Cartesian3;
  XyZ: Cartesian3;
}

const WireframeBox: React.FC<IWireframeBoxProps> = (props) => {
  // console.log(`WireframeBox: rendering`) ;

  const color = props.color ;
  const outlineColor = useMemo(()=> color ?? DEFAULT_WIREFRAME_BOX_LINE_COLOR, [color] );
  // const fillColor = useMemo(()=> outlineColor.withAlpha(0.01), [outlineColor] );

  const lineWidth = props.lineWidth ;

  const box: IBoxCoordinates = useMemo(()=>{
    // console.log(`WireframeBox: recomputing box`) ;
    return {

      // ... Top rectangle
      xyz: TilesetUtils.localToWorld(Cartesian3.fromArray([props.minPosECEF.x, props.minPosECEF.y, props.minPosECEF.z]), props.modelMatrix),
      xYz: TilesetUtils.localToWorld(Cartesian3.fromArray([props.minPosECEF.x, props.maxPosECEF.y, props.minPosECEF.z]), props.modelMatrix),
      XYz: TilesetUtils.localToWorld(Cartesian3.fromArray([props.maxPosECEF.x, props.maxPosECEF.y, props.minPosECEF.z]), props.modelMatrix),
      Xyz: TilesetUtils.localToWorld(Cartesian3.fromArray([props.maxPosECEF.x, props.minPosECEF.y, props.minPosECEF.z]), props.modelMatrix),

      // ... Bottom rectangle
      xyZ: TilesetUtils.localToWorld(Cartesian3.fromArray([props.minPosECEF.x, props.minPosECEF.y, props.maxPosECEF.z]), props.modelMatrix),
      xYZ: TilesetUtils.localToWorld(Cartesian3.fromArray([props.minPosECEF.x, props.maxPosECEF.y, props.maxPosECEF.z]), props.modelMatrix),
      XYZ: TilesetUtils.localToWorld(Cartesian3.fromArray([props.maxPosECEF.x, props.maxPosECEF.y, props.maxPosECEF.z]), props.modelMatrix),
      XyZ: TilesetUtils.localToWorld(Cartesian3.fromArray([props.maxPosECEF.x, props.minPosECEF.y, props.maxPosECEF.z]), props.modelMatrix)
    }
  }, [props.minPosECEF, props.maxPosECEF, props.modelMatrix]);

  // const topPolygon = useMemo(()=>{
  //   return new PolygonHierarchy([ box.xyZ, box.xYZ, box.XYZ, box.XyZ, box.xyZ ]) ;
  // }, [box.xyZ, box.xYZ, box.XYZ, box.XyZ]);

  // useEffect(()=>console.log('WireframeBox: box has changed'), [box]);
  // useEffect(()=>console.log('WireframeBox: props.name has changed'), [props.name]);
  // useEffect(()=>console.log('WireframeBox: props.show has changed'), [props.show]);
  // useEffect(()=>console.log('WireframeBox: props.description has changed'), [props.description]);
  // useEffect(()=>console.log('WireframeBox: props.color has changed'), [props.color]);
  // useEffect(()=>console.log('WireframeBox: props.lineWidth has changed'), [props.lineWidth]);
  // useEffect(()=>console.log('WireframeBox: props.lineWidth has changed'), [props.lineWidth]);

  return (
      <>
        <Entity
            name={props.name ? t(props.name) : undefined}
            show={props.show}
            description={props.description}
        >
          <PolylineGraphics
              material={outlineColor}
              width={lineWidth}
              positions={[ box.xyz, box.xYz, box.XYz, box.Xyz, box.xyz ]}
          />
        </Entity>
        <Entity
            name={props.name ? t(props.name) : undefined}
            show={props.show}
            description={props.description}
        >
          {/*<PolygonGraphics*/}
          {/*    height={box.xyZ.z}*/}
          {/*    perPositionHeight={true}*/}
          {/*    outline={true}*/}
          {/*    outlineWidth={lineWidth}*/}
          {/*    outlineColor={outlineColor}*/}
          {/*    fill={true}*/}
          {/*    material={fillColor}*/}
          {/*    hierarchy={topPolygon}*/}
          {/*/>*/}
          <PolylineGraphics
              material={outlineColor}
              width={lineWidth}
              positions={[ box.xyZ, box.xYZ, box.XYZ, box.XyZ, box.xyZ ]}
          />
        </Entity>
        <Entity
            name={props.name ? t(props.name) : undefined}
            show={props.show}
            description={props.description}
        >
          <PolylineGraphics
              material={outlineColor}
              width={lineWidth}
              positions={[ box.xyz, box.xyZ ]}
          />
        </Entity>
        <Entity
            name={props.name ? t(props.name) : undefined}
            show={props.show}
            description={props.description}
        >
          <PolylineGraphics
              material={outlineColor}
              width={lineWidth}
              positions={[ box.xYz, box.xYZ ]}
          />
        </Entity>
        <Entity
            name={props.name ? t(props.name) : undefined}
            show={props.show}
            description={props.description}
        >
          <PolylineGraphics
              material={outlineColor}
              width={lineWidth}
              positions={[ box.XYz, box.XYZ ]}
          />
        </Entity>
        <Entity
            name={props.name ? t(props.name) : undefined}
            show={props.show}
            description={props.description}
        >
          <PolylineGraphics
              material={outlineColor}
              width={lineWidth}
              positions={[ box.Xyz, box.XyZ ]}
          />
        </Entity>
      </>
  )
}

export default withTranslation()(React.memo(WireframeBox));
