import {t} from "i18next";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {
  AttributeType,
  fromAttributeTypeLabel
} from "../../domain/AttributeType";

interface IProps extends WithTranslation {
  selectedAttribute: AttributeType|undefined;
  availableAttributes: AttributeType[];
  onSelectedAttributeChanged: (selectedAttribute: AttributeType) => void;
  className?:string;
  style?:any;
}

const SELECT_ATTRIBUTE = "Select attribute";

const AttributeSelector: React.FC<IProps> = (props) => {

  const handleSelectionChanged = ( value: string ) => {
    if ( value != SELECT_ATTRIBUTE ) {
      let selectedAttribute = AttributeType[fromAttributeTypeLabel( value )];
      props.onSelectedAttributeChanged( selectedAttribute );
    }
  }

  return (
      <select
          className={`w3-select unselectable w3-theme-d4 w3-center w3-border w3-border-grey w3-round-medium ${props.className}`}
          style={{ ...props.style, fontSize: "12px", cursor: "pointer" }}
          name="option"
          value={props.selectedAttribute ? `${AttributeType[props.selectedAttribute]}` : undefined }
          onChange={(e) => handleSelectionChanged(e.target.value) }
      >
        {props.selectedAttribute ||
            <option key={SELECT_ATTRIBUTE} value={undefined}>{t(SELECT_ATTRIBUTE)}</option>
        }
        {props.availableAttributes
            .map((attribute) => (
                <PureAttributeOption key={AttributeType[attribute]} attribute={attribute}/>
            ))
        }
      </select>
  )
}

interface IAttributeOptionProps extends WithTranslation {
  attribute: AttributeType
}

const AttributeOption: React.FC<IAttributeOptionProps> = (props) => {
  const optionString: string = `${AttributeType[props.attribute]}` ;
  return (<option value={optionString}>{t(optionString)}</option>);
}
const PureAttributeOption = withTranslation()(React.memo(AttributeOption));

export default withTranslation()(React.memo(AttributeSelector));
