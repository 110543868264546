import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import VideoClip from "./Widgets/VideoClip";
import {VideoResourceUrl} from "../lib/VideoResourceUrl";

/**
 * Help page for using the Pattern Explorer page.
 *
 *
 * Saving changes
 * Click on the 'Save' button to apply your changes.  This will save the site configuration at the back end server and
 * persist for all users.
 *
 * Undoing changes
 * Click on the 'Undo' button to undo any changes you have made.
 *
 * @param props
 * @constructor
 */

const Help_SiteConfig_ColorLegend: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <>
        <div className={'w3-panel'}>
          <h2 className={"w3-left-align"}>{t("The Color Legend")}</h2>
          <p className={"text-full-justify"}>
            {t("The color legend section assigns a unique color for each numeric range - or category - for every " +
                "supported geo-modeling attribute.  Currently supported attributes are:")}
          </p>
          <ul className={"w3-left-align"}>
            <li className={"w3-padding-small"}>{t("SED")}</li>
            <li className={"w3-padding-small"}>{t("Coal probability")}</li>
            <li className={"w3-padding-small"}>{t("Rock Hardness")}</li>
            <li className={"w3-padding-small"}>{t("Rate of penetration")}</li>
            <li className={"w3-padding-small"}>{t("Drill Work Index (BI)")}</li>
            <li className={"w3-padding-small"}>{t("Rock Mass Index (CBI)")}</li>
            <li className={"w3-padding-small"}>{t("Fracture Prominence")}</li>
            <li className={"w3-padding-small"}>{t("Fracture Reduction Factor")}</li>
          </ul>
        </div>

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <div className={'w3-panel'}>
          <h3 className={"w3-left-align"}>{t("Changing Colors")}</h3>
          <div className={"w3-row"}>
            <div className={"w3-col w3-third"}>
              <p className={"text-full-justify w3-margin-right"}>
                {t("Hover the mouse pointer over a numeric range - or category - and click on the color palette icon " +
                    "to change the color.")}
              </p>
            </div>
            <VideoClip src={VideoResourceUrl("color_adjust.webm")} className={`w3-col w3-twothird w3-padding`}/>
          </div>
        </div>

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <div className={'w3-panel'}>
          <h3 className={"w3-left-align"}>{t("Adjusting Numeric Ranges")}</h3>
          <div className={"w3-row"}>
            <div className={"w3-col w3-half"}>
              <p className={"text-full-justify w3-margin-right"}>
                {t("Hover the mouse pointer over the boundary between two range boxes, then click-and-drag the edge " +
                    "to adjust the numeric range.")}
              </p>
            </div>
            <VideoClip src={VideoResourceUrl("range_adjust.webm")} className={`w3-col w3-half w3-padding`}/>
          </div>
        </div>

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <div className={'w3-panel'}>
          <h3 className={"w3-left-align"}>{t("Split a Numeric Range in Two")}</h3>
          <div className={"w3-row"}>
            <div className={"w3-col w3-half"}>
              <p className={"text-full-justify w3-margin-right"}>
                {t("Hover the mouse pointer over a range box, then click on the division icon in the top right-hand " +
                    "corner.  This will split the numeric range into two, thus creating a new numeric range.")}
              </p>
            </div>
            <VideoClip src={VideoResourceUrl("range_split.webm")} className={`w3-col w3-half w3-padding`}/>
          </div>
        </div>

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <div className={'w3-panel'}>
          <h3 className={"w3-left-align"}>{t("Remove Numeric Range")}</h3>
          <div className={"w3-row"}>
            <div className={"w3-col w3-half"}>
              <p className={"text-full-justify w3-margin-right"}>
                {t("Hover the mouse pointer over a range box, then click on the 'X' icon in the top right-hand corner. " +
                    "This will remove the numeric range.")}
              </p>
            </div>
            <VideoClip src={VideoResourceUrl("range_delete.webm")} className={`w3-col w3-half w3-padding`}/>
          </div>
        </div>
      </>
  )
}

export default withTranslation()(Help_SiteConfig_ColorLegend);
