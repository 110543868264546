import React, {useCallback, useEffect, useState} from "react";
import {ICustomerSite} from "../../pages/CustomerSites";
import {withTranslation, WithTranslation} from "react-i18next";
import SortHeaderItem, {IColumnSort} from "../Sorting/SortHeaderItem";
import {sortObjects} from "../../lib/sort";
import SortHeader from "../Sorting/SortHeader";
import CustomerSiteRow from "./CustomerSiteRow";

interface IProps extends WithTranslation {
  customerSites: ICustomerSite[];
  onCustomerSiteChanged: (customerSite: ICustomerSite) => void;
  onCustomerSiteDelete: (customerSite: ICustomerSite) => void;
}

const COL_WIDTHS = [40, 10, 15, 15, 15, 5]

const CustomerSitesList: React.FC<IProps> = (props) => {
  const t = props.t ;

  const [columnSort, setColumnSort] = useState<IColumnSort>({
    sortBy: "name",
    sortOrder: "asc"
  });
  const [editedRow, setEditedRow] = useState<string|undefined>();

  const sortedCustomerSites = sortObjects(props.customerSites, columnSort.sortBy, columnSort.sortOrder);

  useEffect(()=> {
    const handleClick = () => {
      if ( editedRow !== undefined ) setEditedRow( undefined ) ;
    }

    document.addEventListener('click', handleClick, false);

    return () => {
      document.removeEventListener('click', handleClick, false);
    }

  }, [editedRow]);

  const handleEditing = useCallback((customerSite: ICustomerSite, isEditing: boolean) => {
    console.log(`row ${customerSite.id} is ${isEditing?'':"not "}being edited`);
    isEditing && setEditedRow(customerSite.id)
  }, []);

  const onCustomerSiteDelete = props.onCustomerSiteDelete ;
  const handleDelete = useCallback((customerSite: ICustomerSite) => {
    // if ( editedRow === customerSite.id ) {
    //   setEditedRow( undefined ) ;
    // }
    onCustomerSiteDelete(customerSite);
  }, [onCustomerSiteDelete]);

  const customerSites = props.customerSites;
  useEffect(()=>{
    if ( editedRow && !customerSites.find( customerSite => customerSite.id === editedRow ) ) {
      setEditedRow( undefined ) ;
    }
  }, [customerSites, editedRow]);

  return (
      <>
        <SortHeader>
          <SortHeaderItem
              widthPercent={COL_WIDTHS[0]}
              defaultFilterprop="name"
              filterProp="name"
              columnSort={columnSort}
              onColumnSort={(sort=>setColumnSort(sort))}
          >
            {t("name")}
          </SortHeaderItem>
          <SortHeaderItem
              className={'w3-center'}
              widthPercent={COL_WIDTHS[1]}
              defaultFilterprop="nick"
              filterProp="nick"
              columnSort={columnSort}
              onColumnSort={(sort=>setColumnSort(sort))}
          >
            {t("nick")}
          </SortHeaderItem>
          <SortHeaderItem
              className={'w3-center'}
              widthPercent={COL_WIDTHS[2]}
              defaultFilterprop="dmmHost"
              filterProp="dmmHost"
              columnSort={columnSort}
              onColumnSort={(sort=>setColumnSort(sort))}
          >
            {t("dmmHost")}
          </SortHeaderItem>
          <SortHeaderItem
              className={'w3-center'}
              widthPercent={COL_WIDTHS[3]}
              defaultFilterprop="dmmPublicPort"
              filterProp="dmmPublicPort"
              columnSort={columnSort}
              onColumnSort={(sort=>setColumnSort(sort))}
          >
            {t("dmmPublicPort")}
          </SortHeaderItem>
          <SortHeaderItem
              className={'w3-center'}
              widthPercent={COL_WIDTHS[4]}
              defaultFilterprop="dmmPrivatePort"
              filterProp="dmmPrivatePort"
              columnSort={columnSort}
              onColumnSort={(sort=>setColumnSort(sort))}
          >
            {t("dmmPrivatePort")}
          </SortHeaderItem>

          {/*Empty column*/}
          <div className="w3-cell" style={{ width: `${COL_WIDTHS[5]}%` }}/>

        </SortHeader>

        <ul className="w3-ul">
          {sortedCustomerSites.map((customerSite) =>
              <React.Fragment key={customerSite.id}>
                <CustomerSiteRow
                    customerSite={customerSite}
                    onCustomerSiteChanged={props.onCustomerSiteChanged}
                    isEditing={editedRow === customerSite.id}
                    onEditing={b=>handleEditing(customerSite, b)}
                    onDelete={()=>handleDelete(customerSite)}
                    colWidths={COL_WIDTHS}
                />
              </React.Fragment>
          )}
        </ul>
      </>
  )
}

export default withTranslation()(CustomerSitesList);
