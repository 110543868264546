import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

import TextAndImage from "./Widgets/TextAndImage";
import {VideoResourceUrl} from "../lib/VideoResourceUrl";

/**
 * Color Legend side menu help
 *
 * @param props
 * @constructor
 */

const Help_CesiumView_SideMenu_Legend: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <>
        <div className={'w3-panel'}>
          <h2 className={"w3-left-align"}>{t("The 'Legend' Side Menu")}</h2>
          <p className={"text-full-justify"}>
            {t("This section allows you to adjust the display parameters of each geo-modeling attribute.  Geo-modeling attributes are colored according to their value and the value range color defined herein")}
          </p>
        </div>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Show/Hide Category or Value Range"}
                      text={"Use the checkboxes to toggle the visibility of one or more categories or value ranges."}
                      src={VideoResourceUrl("color_visibility.webm")}
                      imgRatio={"w3-half"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Adjust the Opacity"}
                      text={"Use the 'Opacity' sliders to adjust the opacity of one or more vategories or value ranges."}
                      src={VideoResourceUrl("color_opacity.webm")}
                      imgRatio={"w3-half"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Zoom to Color"}
                      text={"Click on the 'Zoom' icons to highlight the blocks that belong to one or more categories or value ranges"}
                      src={VideoResourceUrl("color_zoom.webm")}
                      imgRatio={"w3-half"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Reset Color Legend"}
                      text={"Click on the 'Reset' button to reset all categories/ranges to full visibility and opacity"}
                      src={VideoResourceUrl("color_reset.webm")}
                      imgRatio={"w3-half"}/>

      </>
  )
}

export default withTranslation()(Help_CesiumView_SideMenu_Legend);

