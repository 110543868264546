import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {apiRoot} from "../../../../lib/routes";
import {BoundingSphere, Cartesian3, Cesium3DTileset, Color, SceneMode} from "cesium";
import ISavedSession from "../../../../model/ISavedSession";
import {IPatternSummary} from "../../domain/IPatternSummary";
import IAssetSummary from "../../domain/IAssetSummary";
import {RockMassDomainRange} from "../../../../model/RockMassDomainRange";
import {AttributeType, SELECTABLE_ATTRIBUTE_TYPES} from "../../domain/AttributeType";
import {IColorLegend, useColorLegendApi} from "../../hooks/useColorLegendApi";
import ILayerTypes, {LayerType} from "../../../../model/LayerType";
import {CompositeFeatureIndex} from "../../domain/CompositeFeatureIndex";
import {IBlockModel} from "./BlockModelContextProvider";
import {BoundingBoxUtil, IBoundingBox} from "../../domain/IBoundingBox";
import {useFetchWithAuth} from "../../../../lib/auth/fetchWithAuth";
import PatternMapper from "../../mappers/PatternSummaryMapper";
import {IPatternSummaryDto} from "../../model/IPatternSummaryDto";
import AssetSummaryMapper from "../../mappers/AssetSummaryMapper";
import {IAssetSummaryDto} from "../../model/IAssetSummaryDto";
import useSafeInterval from "../../../../lib/hooks/useSafeInterval";
import {useMap} from "usehooks-ts";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {DEFAULT_NEUTRAL_COLOR} from "../../domain/IStyle";
import {CameraFrustum} from "../../domain/CameraFrustum";
import {CollarAppearance, ICollarStyle} from "../../domain/ICollarStyle";
import {Adhesiveness, ISnapPosition} from "../../hooks/useSnapPositioning";
import {format} from "date-fns";
import {t} from "i18next";
import {ICameraSavedVantagePoint} from "../Views/ICameraVantagePoint";
import {ColorUtil} from "../../../../lib/ColorUtil";
import {HoleLabelType} from "../BasicControls/HoleLabelSelector";
import {IOrthogonalCrossSectionStyle} from "../../model/IOrthogonalCrossSectionStyle";
import {ISecondarySegmentOptions} from "../../domain/ISecondarySegmentOptions";

export const UserSessionContext = React.createContext<any>({});

interface IProps {
  sessionUuid: string;
  patternIds?: string[];
  updateIntervalMSec?: number|undefined ;
}

const DEFAULT_UPDATE_INTERVAL_MSEC = 5000 ;

const AUTO_UPDATE_LAYERS: LayerType[] = [
  LayerType.BlastholeSecondarySegments,
  LayerType.BlastholeSingleSegment,
  LayerType.BlastholeFractures,
];

/**
 * Context povider
 * @param props
 * @constructor
 */
export const UserSessionContextProvider: React.FC<IProps> = (props) => {
  const userSessionApi = useUserSessionApi();
  const colorLegendApi = useColorLegendApi();
  const [siteConfig] = useSiteConfig();
  const [updateInterval] = useState<number>(props.updateIntervalMSec ?? DEFAULT_UPDATE_INTERVAL_MSEC);
  const [state, setState] = useState<UserSessionState>({...DEFAULT_USER_SESSION, sessionId: props.sessionUuid});
  const [patternHoleCount] = useState<Map<string, number>>(new Map<string, number>());
  const [patternDrillIds] = useState<Map<string, string[]>>(new Map<string, string[]>());
  const [blockModels] = useState<Map<string, IBlockModel>>(new Map<string, IBlockModel>());
  const imageDataUrl = useRef<string>();
  const savedCamera3D = useRef<ICameraSavedVantagePoint>();

  const getPatternHoleCount = (patternId: string ): number => {
    console.log(`UserSessionContextProvider: getPatternHoleCount(${patternId} => ${patternHoleCount.get(patternId)})`);
    return patternHoleCount.get(patternId) ?? 0;
  }
  const setPatternHoleCount = (patternId: string, v: number ) => {
    patternHoleCount.set( patternId, v );
    console.log(`UserSessionContextProvider: setPatternHoleCount(${patternId}, ${v})`);
  }
  const getPatternDrillIds = (patternId: string ): string[] => {
    return patternDrillIds.get(patternId) ?? [];
  }
  const setPatternDrillIds = (patternId: string, drillIds: string[] ) => {
    patternDrillIds.set( patternId, drillIds );
  }
  const getDrillIdLabelColor = (drillId: string): Color|undefined => {
    let drillIdSet = new Set<string>();
    patternDrillIds.forEach( (v, k) => {
      v.forEach( v => drillIdSet.add( v ) );
    })
    let drillIdList = Array.from( drillIdSet.values() );
    const idx = drillIdList.indexOf( drillId );
    if ( idx === -1 ) {
      return undefined ;
    }
    return ColorUtil.rainbow( 100 * (idx + 1) / drillIdList.length );
  }
  const addBlockModel = (blockModel: IBlockModel) => { blockModels.set( blockModel.assetSummary.name, blockModel ); }

  const setRockMassDefinitions = (defs: Map<string,RockMassDomainRange[]>)=>{
    setState( prevState => ({ ...prevState, rockMassDefinitions: defs }))
  };

  useEffect(()=>console.log(`selectedAttribute: ${state.selectedAttribute && AttributeType[state.selectedAttribute]}`), [state.selectedAttribute]);

  // const setAttributeMappings = (defs: Map<AttributeType,AttributeMapping>)=>{
  //   setState( prevState => ({ ...prevState, attributeMappings: defs }))
  // };

  // useEffect(()=>{
  //   if ( siteConfig?.legendConfigs && !state.maskedColor ) {
  //     setMaskedColor(Color.fromCssColorString(siteConfig.legendConfigs.SED.maskedColor));
  //   }
  // }, [siteConfig?.legendConfigs, state.maskedColor]);

  // ... Initial load
  useEffect(()=>{

    // ... Fetch the sessions details the session
    console.log(`>>> Fetching user session: ${props.sessionUuid}`);
    userSessionApi.fetchUserSession(props.sessionUuid)
        .then( savedSession => {
          let savedState = savedSession.sessionData ? viewModelDtoToUserSessionState( savedSession.sessionData ) : DEFAULT_USER_SESSION;

          // ... Set the state
          setState( prevState => ({
            ...prevState,   // defaults
            ...savedState,  // saved on server
            userSession: {
              ...savedSession,
              sessionData: undefined // removed to reduce mem usage
            }
          }));
          // console.log(">>> Fetching pattern summaries...");
          return userSessionApi.fetchManyPatternSummaries( savedSession.patternIds );
        })
        .then( patternSummaries => {
          // console.log(`... Fetched pattern summaries: ${JSON.stringify(patternSummaries)}`);
          // ... Set the state
          setState( prevState => ({ ...prevState, patternSummaries: patternSummaries }));
          // console.log(`>>> Fetching assets summaries...`);

          // ... Update pattern styles (remove obsolete and add defaults for missing)
          //TODO

          return userSessionApi.fetchAssetSummariesForPatterns( patternSummaries.map( ps => ps.patternId ));

        })
        .then( assetSummaries => {
          // console.log(`... Fetched assets summaries: ${JSON.stringify(assetSummaries)}`);

          // ... Set origin reference and block size
          let referenceAsset = assetSummaries.find(assetSummary => assetSummary.origin && assetSummary.origin.length === 3);
          const originReference = referenceAsset && Cartesian3.fromArray(referenceAsset.origin);

          referenceAsset = assetSummaries.find(assetSummary => assetSummary.blockSize && assetSummary.blockSize.length === 3);
          let blockSize: Cartesian3|undefined ;

          if (referenceAsset?.blockSize) {
            const x = referenceAsset.blockSize[0];
            const y = referenceAsset.blockSize[1];
            const z = referenceAsset.blockSize[2];
            if (x && y && z) {
              blockSize = Cartesian3.fromArray([x, y, z]) ;
            }
          }

          // ... Set bench elevation thresholds
          referenceAsset = assetSummaries.find(assetSummary => assetSummary.blockSize && assetSummary.blockSize.length === 3);

          // ... Set the state
          setState( prevState => ({
            ...prevState,
            originReference: originReference,
            blockSize: blockSize,
            assetSummaries: assetSummaries
          }));
          // console.log(`>>> Fetching color legends...`);
          return colorLegendApi.fetchAllColorLegends( props.sessionUuid ) ;
        })
        .then( colorLegends => {
          // console.log(`... Fetched color legends: ${JSON.stringify(colorLegends)}`);
          let colorLegendMap = new Map<AttributeType, IColorLegend>();
          colorLegends.forEach( colorLegend => {
            colorLegendMap.set( colorLegend.attributeType, colorLegend );
          })
          setState(prevState => ({...prevState, colorLegendStates: colorLegendMap}));
        })
        .catch( err => console.error(`userSessionApi.fetchUserSession(${props.sessionUuid}): ${err}`));

  }, []);

  // ... periodic checks for new pattern data
  useSafeInterval(async () => {

    checkForUpdates();

  }, updateInterval );

  /**
   * Checks for updates to the assets for the session patterns
   */
  const checkForUpdates = () => {

    if (state.patternSummaries && state.assetSummaries && !state.loadingData3D && !state.loadingData2D ) {
      // console.log(`Check for updates to ${state.patternSummaries.map(p => p.name)}; ${state.assetSummaries.map(s=>s.name)}`)
      const patternIds = state.patternSummaries.map(patternSummary => patternSummary.patternId);

      const assetSummaries = state.assetSummaries ;

      userSessionApi.fetchAssetSummariesForPatterns(patternIds)
          .then(summaries => {
            let toManuallyUpdate: IAssetSummary[] = [];
            let toAutoUpdate: IAssetSummary[] = [];

            // ... For all assets in this pattern
            summaries.forEach(assetSummary => {

              // ... Is this a manual-update asset?
              const isManualUpdate = AUTO_UPDATE_LAYERS.indexOf(assetSummary.layerType) === -1;

              // ... Do we already have data for this asset
              const preExisting = assetSummaries.find(as => as.name === assetSummary.name);

              // ... If there is already some data for this asset (the asset is being updated)
              if (preExisting) {
                // ... If this is a more recent version of data
                if (assetSummary.end > preExisting.end) {
                  console.log(`${assetSummary.name} needs some updating`)
                  // ... We are updating an existing asset
                  if (isManualUpdate) {
                    toManuallyUpdate.push(assetSummary);
                  } else {
                    toAutoUpdate.push(assetSummary);
                  }
                }
              } else {
                console.log(`${assetSummary.name} is a new asset`)
                // ... This is a new asset
                if (isManualUpdate) {
                  toManuallyUpdate.push(assetSummary);
                } else {
                  toAutoUpdate.push(assetSummary);
                }
              }
            })

            if ( toAutoUpdate.length > 0 || toManuallyUpdate.length > 0 ) {

              // ... First remove preexisting
              const namesToUpdate = toAutoUpdate.map(assetSummary => assetSummary.name);
              let unchangedAssetSummaries = assetSummaries.filter(assetSummary => namesToUpdate.indexOf(assetSummary.name) === -1);

              // ... then add all updates
              setState(prevState => ({
                ...prevState,
                assetSummaries: [...unchangedAssetSummaries, ...toAutoUpdate],
                assetsToManuallyUpdate: toManuallyUpdate
              }))
            }
          })
    }
  }

  /**
   * Manually updates a select list of asset summaries
   */
  const manuallyUpdateAssets = useCallback(async (assets: IAssetSummary[]) => {

    userSessionApi.fetchManyAssetSummaries( assets.map( asset => asset.id ) )
        .then( assetSummaries => {
          const namesToUpdate = assetSummaries.map( assetSummary => assetSummary.name );
          let unchangedAssetSummaries = state.assetSummaries?.filter( assetSummary => namesToUpdate.indexOf( assetSummary.name ) === -1 ) ?? [] ;
          setState( prevState => ({
            ...prevState,
            assetSummaries: [ ...unchangedAssetSummaries, ...assets ],
            assetsToManuallyUpdate: []
          }))
        })

  }, [state.assetSummaries]);

  const setLoadingData = (sceneMode: SceneMode, isLoading:boolean)=>{
    console.log(`userSessionActions.setLoadingData: sceneMode=${sceneMode}, isLoading=${isLoading}`);
    if ( sceneMode === SceneMode.SCENE3D ) {
      setState(prevState => ({...prevState, loadingData3D: isLoading}));
    }
    else if ( sceneMode === SceneMode.SCENE2D ) {
      setState(prevState => ({...prevState, loadingData2D: isLoading}));
    }
  }

  const updateBoundingBox = (addedBoundingBox: IBoundingBox, onUpdated: (boundingBox: IBoundingBox)=>void) => {
    setState( prevState => {
      let result = prevState.boundingBox
          ? BoundingBoxUtil.extend(prevState.boundingBox, addedBoundingBox)
          : addedBoundingBox;
      onUpdated(result);
      return { ...prevState, boundingBox: result };
    });
  }

  const updateBoundingSphere = (boundingSphere: BoundingSphere) => {
    setState( prevState => {
      let result = prevState.boundingSphere
          ? BoundingSphere.fromBoundingSpheres([prevState.boundingSphere, boundingSphere])
          : boundingSphere
      return { ...prevState, boundingSphere: result };
    });
  }

  const setTilesetAttributes = (tileset: Cesium3DTileset) => {
    // ... Collect the attributes in this tileset
    let attributesInData = new Set<AttributeType>();
    if (tileset.properties) {
      for ( let key in tileset.properties ) {
        let attribute = SELECTABLE_ATTRIBUTE_TYPES.find( attributeType => AttributeType[attributeType] === key )
        if ( attribute ) {
          attributesInData.add( attribute );
        }
      }
    }


    // ... Update the list of data attributes
    setState( prevState => {
      let unchangedAttributesInData = prevState.attributesInData?.filter( a => !attributesInData.has( a ) ) ?? [];
      const newAttributesInData = [...unchangedAttributesInData, ...Array.from(attributesInData)];
      return {
        ...prevState,
        attributesInData: newAttributesInData,
        selectedAttribute: prevState.selectedAttribute ?? newAttributesInData[0]
      }
    })
  }

  const resetColorLegend = (colorLegend: IColorLegend, toReset: { visible?: boolean|undefined, opacity?:number|undefined} )=> {

    console.log(`>>> resetColorLegend: toReset=${JSON.stringify(toReset)})`);


    // console.log(`... resetColorLegend: toUpdate.before=${JSON.stringify(colorLegend)})`);

    if ( toReset.visible !== undefined ) {
      colorLegend.colorInvalidValue.visible = toReset.visible;
      if (colorLegend.colorRangeExceeded) colorLegend.colorRangeExceeded.visible = toReset.visible;
      for ( let i = 0; i < colorLegend.colorLegendItems.length; ++i ) {
        colorLegend.colorLegendItems[i].visible = toReset.visible;
      }
    }

    if ( toReset.opacity !== undefined ) {
      colorLegend.colorInvalidValue.opacity = toReset.opacity;
      if (colorLegend.colorRangeExceeded) colorLegend.colorRangeExceeded.opacity = toReset.opacity;
      for ( let i = 0; i < colorLegend.colorLegendItems.length; ++i ) {
        colorLegend.colorLegendItems[i].opacity = toReset.opacity;
      }
    }

    console.log(`>>> resetColorLegend: toUpdate.after=${JSON.stringify(colorLegend)})`);
    setState(prevState => {
      let colorLegends = new Map<AttributeType, IColorLegend>( prevState.colorLegendStates ) ;
      colorLegends.set(colorLegend.attributeType, colorLegend);
      return {...prevState,
        colorLegendStates: colorLegends
      }
    })

    // colorLegendApi.updateColorLegend( props.sessionUuid, colorLegend )
    //     .then( updatedColorLegend => {
    //       console.log( `<<< updateColorLegend: ${JSON.stringify(colorLegend)}` )
    //       setState( prevState => {
    //         let colorLegends = new Map<AttributeType, IColorLegend>( prevState.colorLegends ) ;
    //         colorLegends.set(colorLegend.attributeType, updatedColorLegend);
    //         return {...prevState,
    //           colorLegends: colorLegends
    //         }
    //       })
    //     })
  }

  const resetColorLegendItem = (colorLegend: IColorLegend, idx: number, toReset: { visible?: boolean|undefined, opacity?:number|undefined} )=> {
    // console.log(`>>> resetColorLegendItem: idx=${idx}, toReset=${JSON.stringify(toReset)})`);
    let toUpdate = {...colorLegend};

    // console.log(`... resetColorLegendItem: toUpdate.before=${JSON.stringify(toUpdate)})`);

    switch( idx ) {
      case -1:
        if (toReset.visible !== undefined) {
          toUpdate.colorInvalidValue.visible = toReset.visible;
        }
        if (toReset.opacity !== undefined) {
          toUpdate.colorInvalidValue.opacity = toReset.opacity;
        }
        break ;
      case toUpdate.colorLegendItems.length:
        if (toUpdate.colorRangeExceeded) {
          if (toReset.visible !== undefined) {
            toUpdate.colorRangeExceeded.visible = toReset.visible;
          }
          if (toReset.opacity !== undefined) {
            toUpdate.colorRangeExceeded.opacity = toReset.opacity;
          }
        }
        break ;
      default:
        if ( idx < toUpdate.colorLegendItems.length ) {
          if (toReset.visible !== undefined) {
            toUpdate.colorLegendItems[idx].visible = toReset.visible;
          }
          if (toReset.opacity !== undefined) {
            toUpdate.colorLegendItems[idx].opacity = toReset.opacity;
          }
        }
        break ;
    }

    // console.log(`... resetColorLegendItem: toUpdate.after=${JSON.stringify(toUpdate)})`);
    setState(prevState => {
      let colorLegends = new Map<AttributeType, IColorLegend>( prevState.colorLegendStates ) ;
      colorLegends.set(colorLegend.attributeType, colorLegend);
      return {...prevState,
        colorLegendStates: colorLegends
      }
    })

    // colorLegendApi.updateColorLegend(props.sessionUuid, toUpdate)
    //     .then( result => {
    //       setState(prevState => {
    //         let colorLegends = new Map<AttributeType, IColorLegend>( prevState.colorLegends ) ;
    //         colorLegends.set(colorLegend.attributeType, result);
    //         return {...prevState,
    //           colorLegends: colorLegends
    //         }
    //       })
    //     })
  }

  /**
   * Makes one color visible and all the otehrs invisible
   * @param colorLegend
   * @param idx
   */
  const isolateColorLegendItem = (colorLegend: IColorLegend, idx: number ) => {
    const numRanges = colorLegend.colorLegendItems.length;
    colorLegend.colorInvalidValue.visible = idx === -1 ;
    if (colorLegend.colorRangeExceeded) colorLegend.colorRangeExceeded.visible = idx === numRanges ;
    for ( let i = 0; i < numRanges; ++i ) {
      colorLegend.colorLegendItems[i].visible = idx === i ;
    }
    setState(prevState => {
      let colorLegends = new Map<AttributeType, IColorLegend>( prevState.colorLegendStates ) ;
      colorLegends.set(colorLegend.attributeType, colorLegend);
      return {...prevState,
        colorLegendStates: colorLegends
      }
    })

    // colorLegendApi.updateColorLegend(props.sessionUuid, colorLegend)
    //     .then( result => {
    //       setState(prevState => {
    //         let colorLegends = new Map<AttributeType, IColorLegend>( prevState.colorLegends ) ;
    //         colorLegends.set(colorLegend.attributeType, result);
    //         return {...prevState,
    //           colorLegends: colorLegends
    //         }
    //       })
    //     })
  }

  const persistColorLegend = (colorLegend: IColorLegend) => {
    console.log(">>> persistColorLegend");
    colorLegendApi.updateColorLegend(props.sessionUuid, colorLegend)
        .then( result => {
          console.log("<<< persistColorLegend");
          // setState(prevState => {
          //   let colorLegends = new Map<AttributeType, IColorLegend>( prevState.colorLegends ) ;
          //   colorLegends.set(colorLegend.attributeType, result);
          //   return {...prevState,
          //     colorLegends: colorLegends
          //   }
          // })
        })
  }

  const setLayerVisibility = (layerType: LayerType, visibility: boolean) => {
    const key = LayerType[layerType] as keyof ILayerTypes<boolean>;
    setState( prevState => {
      let visibilities: ILayerTypes<boolean> = { ...prevState.layersVisibility };
      visibilities[ key ] = visibility;

      switch( layerType ) {
        case LayerType.CrossSectionX:
        case LayerType.CrossSectionY:
        case LayerType.CrossSectionZ:
          let selectedCrossSection = prevState.selectedCrossSection;

          if ( visibility ) {
            if ( !prevState.selectedCrossSection ) {
              // ... Automatically select this cross-section is none is defined
              selectedCrossSection = layerType ;
            }
          } else { // turning OFF the cross-section
            // ... If the cross-section being turned OFF was the selected cross-section
            if ( prevState.selectedCrossSection === layerType ) {
              // ... Find another cross-section to select by default
              const candidates = [ LayerType.CrossSectionX, LayerType.CrossSectionY, LayerType.CrossSectionZ ]
                  .filter( layer => layer !== layerType ) // find another one than this one
                  .filter( layer => visibilities[ LayerType[layer] as keyof ILayerTypes<boolean> ] ) ; // make sure it is visible

              // ... If at least one was found, pick the first
              if ( candidates.length > 0 ) {
                selectedCrossSection = (candidates )[0] as LayerType.CrossSectionX|LayerType.CrossSectionY|LayerType.CrossSectionZ;
              } else {
                // ... Otherwise there is no longer a selected cross-section
                selectedCrossSection = undefined ;
              }
            }
          }

          if ( selectedCrossSection !== prevState.selectedCrossSection ) {
            return { ...prevState, layersVisibility: visibilities, selectedCrossSection: selectedCrossSection };
          }
          else {
            return { ...prevState, layersVisibility: visibilities };
          }

        default:
          return { ...prevState, layersVisibility: visibilities };
      }

    });
  }

  const setLayerOpacity = (layerType: LayerType, opacity: number) => {
    const key = LayerType[layerType] as keyof ILayerTypes<number>;
    setState( prevState => {
      let opacities: ILayerTypes<number> = { ...prevState.layersOpacity };
      opacities[ key ] = opacity;
      return { ...prevState, layersOpacity: opacities };
    });
  }

  const setLayerColor = (layerType: LayerType, color: Color) => {
    const key = LayerType[layerType] as keyof ILayerTypes<Color>;
    setState( prevState => {
      let colors: ILayerTypes<Color> = { ...prevState.layersColor };
      colors[ key ] = color;
      return { ...prevState, layersColor: colors };
    });
  }

  const resetLayersVisibilityAndOpacity = () => {
    let layersVisibility: ILayerTypes<boolean> = {
      BlastholeClusters: false,
      BlastholeFractures: true,
      BlastholeSecondarySegments: true,
      BlastholeSingleSegment: true,
      BlockModel: true,
      BlockVolume: false,
      Boundary: true,
      CrossSectionX: false,
      CrossSectionY: false,
      CrossSectionZ: false,
      DrilledHoleCollars: false,
      DrilledHolesContour: false,
      Undefined: false
    }
    let layersOpacity: ILayerTypes<number> = {
      BlastholeClusters: 1,
      BlastholeFractures: 1,
      BlastholeSecondarySegments: 1,
      BlastholeSingleSegment: 1,
      BlockModel: 1,
      BlockVolume: 0,
      Boundary: 0.4,
      CrossSectionX: 1,
      CrossSectionY: 1,
      CrossSectionZ: 1,
      DrilledHoleCollars: 0,
      DrilledHolesContour: 0,
      Undefined: 0
    }
    setState( prevState => ({...prevState, layersVisibility: layersVisibility, layersOpacity: layersOpacity}));
  }

  const isolateLayerVisibility = (layerType: LayerType)=>{
    let layersVisibility: ILayerTypes<boolean> = {
      BlastholeClusters: false,
      BlastholeFractures: false,
      BlastholeSecondarySegments: false,
      BlastholeSingleSegment: false,
      BlockModel: false,
      BlockVolume: false,
      Boundary: false,
      CrossSectionX: false,
      CrossSectionY: false,
      CrossSectionZ: false,
      DrilledHoleCollars: false,
      DrilledHolesContour: false,
      Undefined: false
    }
    layersVisibility[ LayerType[layerType] as keyof ILayerTypes<boolean> ] = true ;
    setState( prevState => ({...prevState, layersVisibility: layersVisibility}));
  }

  const setPatternVisibility = (patternId: string, visibility: boolean) => {
    setState( prevState => {
      return { ...prevState,
        patternVisibilities: [
          ...prevState.patternVisibilities.filter( pv => pv.patternId !== patternId ),
          { patternId: patternId, visibility: visibility }
        ]
      };
    });
  }
  const setPatternOpacity = (patternId: string, opacity: number) => {
    setState( prevState => {
      return { ...prevState,
        patternOpacities: [
          ...prevState.patternOpacities.filter( pv => pv.patternId !== patternId ),
          { patternId: patternId, opacity: opacity }
        ]
      };
    });
  }
  const setPatternColor = (patternId: string, color: Color) => {
    setState( prevState => {
      return { ...prevState,
        patternColors: [
          ...prevState.patternColors.filter( pv => pv.patternId !== patternId ),
          { patternId: patternId, color: color }
        ]
      };
    });
  }

  const setSelectedAttribute = (selectedAttribute: AttributeType|undefined)=> {
    setState(prevState => ({...prevState, selectedAttribute: selectedAttribute}));
  }

  const setMaskedColor = (color:Color) => {
    setState( prevState => {
      return {...prevState, maskedColor: color};
    });
  }

  const setCameraFrustum = (cameraFrustum: CameraFrustum) => {
    setState( prevState => {
      return {...prevState, cameraFrustum: cameraFrustum};
    });
  }

  const toggleCameraFrustum = () => {
    setState( prevState => {
      switch( prevState.cameraFrustum ) {
        case CameraFrustum.PERSPECTIVE:
          return {...prevState, cameraFrustum: CameraFrustum.ORTHOGRAPHIC};
        case CameraFrustum.ORTHOGRAPHIC:
          return {...prevState, cameraFrustum: CameraFrustum.PERSPECTIVE};
      }
    });
  }

  const setShowSubDrill = (showSubDrill: boolean) => {
    setState( prevState => {
      return {...prevState, showSubDrill: showSubDrill};
    });
  }

  const setTopPlaneVisibility = (showTopPLane: boolean ) => {
    setState( prevState => {
      return {...prevState, topPlaneVisibility: showTopPLane};
    });
  }

  const setAxesVisibility = (axesVisibility: boolean ) => {
    setState( prevState => {
      return {...prevState, axesVisibility: axesVisibility};
    });
  }

  const setSelectedCrossSection = (selectedCrossSection: LayerType.CrossSectionX|LayerType.CrossSectionY|LayerType.CrossSectionZ|undefined) => {
    setState( prevState => {

      // ... If resetting the selection
      if ( !selectedCrossSection ) {
        return {...prevState, selectedCrossSection: selectedCrossSection};
      }

      // ... If the section is visible and therefore can be ome selected
      const key = LayerType[ selectedCrossSection ] as keyof ILayerTypes<boolean>;
      if ( prevState.layersVisibility[ key ] ) {
        return {...prevState, selectedCrossSection: selectedCrossSection};
      }

      // ... Otherwise no change
      return prevState;
    });
  }

  const setCrossSectionOffsetX = (offset: number ) => {
    setState( prevState => {
      return {...prevState, crossSectionOffsetX: offset};
    });
  }
  const setCrossSectionOffsetY = (offset: number ) => {
    setState( prevState => {
      return {...prevState, crossSectionOffsetY: offset};
    });
  }
  const setCrossSectionOffsetZ = (offset: number ) => {
    setState( prevState => {
      return {...prevState, crossSectionOffsetZ: offset};
    });
  }

  const setSelectedCrossSectionOffset = (offset: number ) => {
    setState( prevState => {
      if ( prevState.selectedCrossSection === LayerType.CrossSectionX ) {
        return { ...prevState, crossSectionOffsetX: Math.max( 0, offset ) }
      }
      else if ( prevState.selectedCrossSection === LayerType.CrossSectionY ) {
        return { ...prevState, crossSectionOffsetY: Math.max( 0, offset ) }
      }
      else if ( prevState.selectedCrossSection === LayerType.CrossSectionZ ) {
        return { ...prevState, crossSectionOffsetZ: Math.max( 0, offset ) }
      }
      else {
        return prevState;
      }
    });
  }
  const moveSelectedCrossSectionOffset = (delta: number ) => {
    setState( prevState => {
      if ( prevState.selectedCrossSection === LayerType.CrossSectionX ) {
        return { ...prevState, crossSectionOffsetX: Math.max( 0, prevState.crossSectionOffsetX + delta ) }
      }
      else if ( prevState.selectedCrossSection === LayerType.CrossSectionY ) {
        return { ...prevState, crossSectionOffsetY: Math.max( 0, prevState.crossSectionOffsetY + delta ) }
      }
      else if ( prevState.selectedCrossSection === LayerType.CrossSectionZ ) {
        return { ...prevState, crossSectionOffsetZ: Math.max( 0, prevState.crossSectionOffsetZ + delta ) }
      }
      else {
        return prevState;
      }
    });
  }

  const resetSelectedCrossSectionOffset = () => {
    setState( prevState => {
      if (prevState.selectedCrossSection === LayerType.CrossSectionZ) {
        return {...prevState, crossSectionOffsetZ: 0 }
      }
      if ( prevState.boundingBox ) {
        if (prevState.selectedCrossSection === LayerType.CrossSectionX) {
          const offset = (prevState.boundingBox.maxPos.x - prevState.boundingBox.minPos.x) / 2;
          return {...prevState, crossSectionOffsetX: offset };
        }
        if (prevState.selectedCrossSection === LayerType.CrossSectionY) {
          const offset = (prevState.boundingBox.maxPos.y - prevState.boundingBox.minPos.y) / 2;
          return {...prevState, crossSectionOffsetY: offset };
        }
      }
      return prevState;
    });
  }

  const setShowMap2D = (showMap: boolean ) => {
    setState( prevState => ({...prevState, showMap2D: showMap }));
  }

  const setBoundingBoxVisibility = (boundingBoxVisibility: boolean) => {
    setState( prevState => ({...prevState, boundingBoxVisibility: boundingBoxVisibility }));
  }
  const setBoundingBoxColor = (boundingBoxColor: Color)=>{
    setState( prevState => ({...prevState, boundingBoxColor: boundingBoxColor }));
  }
  const setTopOfBenchPlaneColor = (topOfBenchPlaneColor: Color)=>{
    setState( prevState => ({...prevState, topOfBenchPlaneColor: topOfBenchPlaneColor }));
  }

  const setCollarStyle = ( collarStyle: ICollarStyle) => {
    setState( prevState => ({...prevState, collarStyle: collarStyle }));
  }
  const setCollarDepth = ( collarDepth: number) => {
    setState( prevState => ({...prevState, collarDepth: collarDepth }));
  }
  const setSectionViewDistanceThreshold = ( sectionViewDistanceThreshold: number) => {
    setState( prevState => ({...prevState, sectionViewDistanceThreshold: sectionViewDistanceThreshold }));
  }
  const setSelectedCrossSectionStyle = ( selectedCrossSectionStyle: IOrthogonalCrossSectionStyle) => {
    setState( prevState => ({...prevState, selectedCrossSectionStyle: selectedCrossSectionStyle }));
  }
  const setShowToolbar = ( showToolbar: boolean) => {
    setState( prevState => ({...prevState, showToolbar: showToolbar }));
  }
  const setShowControlsHelp = ( showControlsHelp: boolean) => {
    setState( prevState => ({...prevState, showControlsHelp: showControlsHelp }));
  }
  const setFloatingMapSnapPosition = ( floatingMapSnapPosition: ISnapPosition) => {
    setState( prevState => ({...prevState, floatingMapSnapPosition: floatingMapSnapPosition }));
  }
  const setInfoBoxSnapPosition = ( infoBoxSnapPosition: ISnapPosition) => {
    setState( prevState => ({...prevState, infoBoxSnapPosition: infoBoxSnapPosition }));
  }
  const setProminenceFilter = ( prominenceFilter: number) => {
    setState( prevState => ({...prevState, prominenceFilter: prominenceFilter }));
  }
  const setColorLegendSnapPosition = (colorLegendSnapPosition: ISnapPosition) => {
    setState( prevState => ({...prevState, colorLegendSnapPosition: colorLegendSnapPosition }));
  }

  const setShowCenterOfRotationEntity = (showCenterOfRotationEntity: boolean) => {
    setState( prevState => ({...prevState, showCenterOfRotationEntity: showCenterOfRotationEntity }));
  }

  const takeScreenshot = (sceneMode: SceneMode|undefined) => {
    setState( prevState => ({...prevState, screenshot: sceneMode}))
  }

  const setCameraVantagePoint3D = (cameraVantagePoint3D: ICameraSavedVantagePoint|undefined) => {
    if ( savedCamera3D ) {
      savedCamera3D.current = cameraVantagePoint3D;
    }
  }

  const setShowAboveBench = (v:boolean)=>{
   setState( prevState => ({...prevState, showAboveBench: v}));
  }
  const setShowWithinBench = (v:boolean)=>{
    setState( prevState => ({...prevState, showWithinBench: v}));
  }
  const setShowBeneathBench = (v:boolean)=>{
    setState( prevState => ({...prevState, showBeneathBench: v}));
  }

  const setBenchElevationLimits = (v: {min: number, max:number}) => {
    setState( prevState => ({...prevState, benchElevationLimits: {...v}}));
  }
  // useEffect(()=> console.log(`benchElevationLimits=${JSON.stringify(state.benchElevationLimits)}`), [state.benchElevationLimits]);

  const setHoleLabelsType = (v: HoleLabelType)=> {
    setState( prevState => ({...prevState, holeLabels: v}));
  }

  const setShowFloatingLegend = ( v: boolean ) => {
    setState( prevState => ({...prevState, showFloatingLegend: v}));
  }

  const setSegmentStyle = (v: ISecondarySegmentOptions) => {
    setState( prevState => ({...prevState, segmentStyle: {...v}}));
  }

  // const setCameraVantagePoint2D = (cameraVantagePoint2D: ICameraSavedVantagePoint|undefined) => {
  //   setState( prevState => ({...prevState, cameraVantagePoint2D: cameraVantagePoint2D}))
  // }

  const setImageDataUrl = (imageDataURL: string | undefined) => {
    // setState( prevState => ({...prevState, imageDataURL: imageDataURL }));
    if ( imageDataURL ) {
      imageDataUrl.current = imageDataURL;
      persistToServer();
    }
  }

  const persistToServer = useCallback(() => {
    console.log(`persisToServer: selectedAttribute=${state.selectedAttribute && AttributeType[state.selectedAttribute]}`);

    if (state.userSession) {
      const sessionToSave: ISavedSession = {
        ...state.userSession,
        sessionData: userSessionStateToViewModelDto({
          ...state,
          cameraVantagePoint3D: savedCamera3D.current,
          imageDataURL: imageDataUrl.current ?? state.imageDataURL
        })
      }
      // console.log(`persisToServer: floatingMapSnapPosition=${JSON.stringify(sessionToSave.sessionData.floatingMapSnapPosition)}`);
      userSessionApi.saveUserSession( sessionToSave )
          .then( result => {
            console.log(`persisToServer: done`);
          } )
    }
  }, [state]);

  /**
   * Tracks changes to the feature index/block model
   */
  useEffect(()=>{
    if (state.originReference && state.boundingBox && state.blockSize && siteConfig && siteConfig?.modelMatrix) {
      let featureIndex = new CompositeFeatureIndex(state.originReference, state.boundingBox, state.blockSize, siteConfig.modelMatrix);
      blockModels.forEach((blockModel) => featureIndex.addBlockFeatures(blockModel.assetSummary, blockModel.tileset));
      setState( prevState => ({
        ...prevState,
        featureIndex: featureIndex
      }))
    }
  }, [state.originReference, state.boundingBox, state.blockSize, blockModels, siteConfig?.modelMatrix]);

  const actions = useMemo((): UserSessionActions => {
    return {
      setLoadingData,
      updateAssets: manuallyUpdateAssets,
      getPatternHoleCount,
      setPatternHoleCount,
      setRockMassDefinitions,
      addBlockModel,
      updateBoundingBox,
      updateBoundingSphere,
      setTilesetAttributes,
      resetColorLegend,
      resetColorLegendItem,
      isolateColorLegendItem,
      persistColorLegend,
      setLayerVisibility,
      setLayerOpacity,
      setLayerColor,
      resetLayersVisibilityAndOpacity,
      isolateLayerVisibility,
      setPatternVisibility,
      setPatternOpacity,
      setPatternColor,
      setSelectedAttribute,
      setMaskedColor,
      setCameraFrustum,
      toggleCameraFrustum,
      setShowSubDrill,
      setTopPlaneVisibility,
      setAxesVisibility,
      setSelectedCrossSection,
      setCrossSectionOffsetX,
      setCrossSectionOffsetY,
      setCrossSectionOffsetZ,
      setSelectedCrossSectionOffset,
      moveSelectedCrossSectionOffset,
      resetSelectedCrossSectionOffset,
      setShowMap2D,
      setBoundingBoxColor,
      setBoundingBoxVisibility,
      setTopOfBenchPlaneColor,
      setCollarStyle,
      setCollarDepth,
      setSectionViewDistanceThreshold,
      setSelectedCrossSectionStyle,
      setShowToolbar,
      setShowControlsHelp,
      setFloatingMapSnapPosition,
      setInfoBoxSnapPosition,
      setProminenceFilter,
      setColorLegendSnapPosition,
      setImageDataUrl,
      setShowCenterOfRotationEntity,
      takeScreenshot,
      setCameraVantagePoint3D,
      // setCameraVantagePoint2D,
      setShowAboveBench,
      setShowWithinBench,
      setShowBeneathBench,
      setBenchElevationLimits,
      setHoleLabelsType,
      setShowFloatingLegend,
      setSegmentStyle
    }
  }, []);

  const moreActions = useMemo((): UserSessionMoreActions => {
    return {
      persistToServer,
      getPatternDrillIds,
      setPatternDrillIds,
      getDrillIdLabelColor
    }
  }, [state, patternDrillIds]);

  return (
      <UserSessionContext.Provider value={[state, actions, moreActions]}>
        {props.children}
      </UserSessionContext.Provider>
  )
}

/**
 * Use context
 */
export const useUserSessionContext = (): [UserSessionState, UserSessionActions, UserSessionMoreActions] => {
  const [state, actions, moreActions] = React.useContext(UserSessionContext);
  return [state, actions, moreActions] ;
}

//----------------------------------------------------------------------------------------------------------------------
// Model
//----------------------------------------------------------------------------------------------------------------------
export interface IPatternVisibility {
  patternId: string;
  visibility: boolean;
}
export interface IPatternOpacity {
  patternId: string;
  opacity: number;
}
export interface IPatternColor {
  patternId: string;
  color: Color;
}
export interface UserSessionState {
  sessionId: string;
  userSession?: ISavedSession|undefined;
  loadingData3D?: boolean|undefined;
  loadingData2D?: boolean|undefined;

  /*
  View model:
   */
  patternSummaries?: IPatternSummary[]|undefined;
  assetSummaries?: IAssetSummary[]|undefined;
  originReference?: Cartesian3|undefined;
  blockSize?: Cartesian3|undefined;
  featureIndex?: CompositeFeatureIndex|undefined;
  assetsToManuallyUpdate?: IAssetSummary[]|undefined;
  rockMassDefinitions?: Map<string,RockMassDomainRange[]>|undefined; // references the rock mass definitions that should be common for all assets in the current session
  boundingSphere?: BoundingSphere|undefined;
  attributesInData?: AttributeType[]|undefined;
  badAssets?: IBadAssetContext[]|undefined;
  colorLegendStates?: Map<AttributeType, IColorLegend>|undefined;
  boundingBox?: IBoundingBox|undefined;
  layersVisibility: ILayerTypes<boolean>;
  layersOpacity: ILayerTypes<number>;
  layersColor: ILayerTypes<Color>;
  patternVisibilities: IPatternVisibility[];
  patternOpacities: IPatternOpacity[];
  patternColors: IPatternColor[];
  selectedAttribute?: AttributeType|undefined;
  maskedColor: Color ;
  cameraFrustum: CameraFrustum ;
  showSubDrill: boolean;
  topPlaneVisibility: boolean;
  axesVisibility: boolean;
  selectedCrossSection?: LayerType.CrossSectionX|LayerType.CrossSectionY|LayerType.CrossSectionZ|undefined;
  crossSectionOffsetX: number;
  crossSectionOffsetY: number;
  crossSectionOffsetZ: number;
  showMap2D: boolean;
  boundingBoxVisibility: boolean;
  boundingBoxColor: Color;
  topOfBenchPlaneColor: Color;
  collarStyle: ICollarStyle;
  collarDepth: number;
  sectionViewDistanceThreshold: number;
  selectedCrossSectionStyle: IOrthogonalCrossSectionStyle;
  showToolbar: boolean;
  showControlsHelp: boolean;
  floatingMapSnapPosition: ISnapPosition;
  infoBoxSnapPosition: ISnapPosition;
  colorLegendSnapPosition: ISnapPosition;
  prominenceFilter: number;
  imageDataURL?: string|undefined;
  showCenterOfRotationEntity: boolean;
  screenshot?: SceneMode|undefined;
  cameraVantagePoint3D?: ICameraSavedVantagePoint|undefined;
  // cameraVantagePoint2D?: ICameraSavedVantagePoint|undefined;
  showAboveBench: boolean;
  showWithinBench: boolean;
  showBeneathBench: boolean;
  benchElevationLimits: {min: number, max: number}|undefined;
  holeLabels: HoleLabelType;
  showFloatingLegend: boolean;
  segmentStyle: ISecondarySegmentOptions;
}

interface UserSessionActions {
  setLoadingData: (sceneMode: SceneMode, isLoading: boolean)=>void;
  updateAssets: (assetsToUpdate: IAssetSummary[])=>void;
  getPatternHoleCount: (patternId: string )=>number;
  setPatternHoleCount: (patternId: string, v: number )=>void;
  setRockMassDefinitions: (rockMassDefinitions: Map<string,RockMassDomainRange[]>)=>void;
  addBlockModel: (blockModel: IBlockModel)=>void;
  updateBoundingBox: (boundingBox: IBoundingBox, onUpdated: (boundingBox: IBoundingBox)=>void)=>void;
  updateBoundingSphere: (v: BoundingSphere)=>void,
  setTilesetAttributes: (tileset: Cesium3DTileset)=>void;
  resetColorLegend: (colorLegend: IColorLegend, toReset: { visible?: boolean|undefined, opacity?:number|undefined})=>void;
  resetColorLegendItem: (colorLegend: IColorLegend, idx: number, toReset: { visible?: boolean|undefined, opacity?:number|undefined})=>void;
  isolateColorLegendItem: (colorLegend: IColorLegend, idx: number)=>void;
  persistColorLegend: (colorLegend: IColorLegend)=>void;
  setLayerVisibility: (layerType: LayerType, visibility: boolean)=>void;
  setLayerOpacity: (layerType: LayerType, opacity: number)=>void;
  setLayerColor: (layerType: LayerType, color: Color)=>void;
  resetLayersVisibilityAndOpacity: ()=>void;
  isolateLayerVisibility: ( layerType: LayerType )=>void;
  setPatternVisibility: (patternId: string, visibility: boolean)=>void;
  setPatternOpacity: (patternId: string, opacity: number)=>void;
  setPatternColor: (patternId: string, color: Color)=>void;
  setSelectedAttribute: (selectedAttribute: AttributeType|undefined)=>void;
  setMaskedColor: (color: Color)=>void;
  setCameraFrustum: (cameraFrustum: CameraFrustum)=>void;
  toggleCameraFrustum: ()=>void;
  setShowSubDrill: ( showSubDrill: boolean )=>void;
  setTopPlaneVisibility: (v: boolean)=>void;
  setAxesVisibility: (v: boolean)=>void;
  setSelectedCrossSection: (selectedCrossSection: LayerType.CrossSectionX|LayerType.CrossSectionY|LayerType.CrossSectionZ|undefined)=>void;
  setCrossSectionOffsetX: (offset: number)=>void;
  setCrossSectionOffsetY: (offset: number)=>void;
  setCrossSectionOffsetZ: (offset: number)=>void;
  setSelectedCrossSectionOffset: (offset: number)=>void;
  moveSelectedCrossSectionOffset: (delta: number)=>void;
  resetSelectedCrossSectionOffset: ()=>void;
  setShowMap2D: (v: boolean)=>void;
  setBoundingBoxVisibility: (boundingBoxVisibility: boolean)=>void;
  setBoundingBoxColor: (boundingBoxColor: Color)=>void;
  setTopOfBenchPlaneColor: (topOfBenchPlaneColor: Color)=>void;
  setCollarStyle: ( collarStyle: ICollarStyle) => void;
  setCollarDepth: ( collarDepth: number) => void;
  setSectionViewDistanceThreshold: ( sectionViewDistanceThreshold: number) => void;
  setSelectedCrossSectionStyle: ( selectedCrossSectionStyle: IOrthogonalCrossSectionStyle) => void;
  setShowToolbar: ( showToolbar: boolean) => void;
  setShowControlsHelp: ( showControlsHelp: boolean) => void;
  setFloatingMapSnapPosition: ( floatingMapSnapPosition: ISnapPosition) => void;
  setInfoBoxSnapPosition: ( infoBoxSnapPosition: ISnapPosition) => void;
  setProminenceFilter: ( prominenceFilter: number) => void;
  setColorLegendSnapPosition: (colorLegendSnapPosition: ISnapPosition)=>void;
  setImageDataUrl: (s: string | undefined) => void;
  setShowCenterOfRotationEntity: (v:boolean)=>void;
  takeScreenshot: (sceneMode: SceneMode|undefined)=>void;
  setCameraVantagePoint3D: (cameraVantagePoint3D: ICameraSavedVantagePoint|undefined)=>void;
  // setCameraVantagePoint2D: (cameraVantagePoint2D: ICameraSavedVantagePoint|undefined)=>void;
  setShowAboveBench: (v:boolean)=>void;
  setShowWithinBench: (v:boolean)=>void;
  setShowBeneathBench: (v:boolean)=>void;
  setBenchElevationLimits: (benchElevationLimits: {min: number, max: number})=>void;
  setHoleLabelsType: (holeLabels: HoleLabelType)=>void;
  setShowFloatingLegend: (showFloatingLegend: boolean) => void;
  setSegmentStyle: (segmentStyle: ISecondarySegmentOptions)=>void;
}

export interface UserSessionMoreActions {
  persistToServer: ()=>void;
  getPatternDrillIds: (patternId: string )=>string[];
  setPatternDrillIds: (patternId: string, drillIds: string[] )=>void;
  getDrillIdLabelColor: (drillId: string)=> Color|undefined;
}

interface IBadAssetContext {
  assetSummary: IAssetSummary;
  reason: string
}

export const DEFAULT_USER_SESSION: UserSessionState = {
  sessionId: '',
  patternSummaries: [],
  assetSummaries: [],

  /*
  View model:
   */
  layersVisibility: {
    BlastholeClusters: false,
    BlastholeFractures: true,
    BlastholeSecondarySegments: true,
    BlastholeSingleSegment: true,
    BlockModel: true,
    BlockVolume: false,
    Boundary: true,
    CrossSectionX: false,
    CrossSectionY: false,
    CrossSectionZ: false,
    DrilledHoleCollars: false,
    DrilledHolesContour: false,
    Undefined: false
  },
  layersOpacity: {
    BlastholeClusters: 1,
    BlastholeFractures: 1,
    BlastholeSecondarySegments: 1,
    BlastholeSingleSegment: 1,
    BlockModel: 1,
    BlockVolume: 0,
    Boundary: 0.4,
    CrossSectionX: 1,
    CrossSectionY: 1,
    CrossSectionZ: 1,
    DrilledHoleCollars: 0,
    DrilledHolesContour: 0,
    Undefined: 0
  },
  layersColor: {
    BlastholeClusters: DEFAULT_NEUTRAL_COLOR,
    BlastholeFractures: DEFAULT_NEUTRAL_COLOR,
    BlastholeSecondarySegments: DEFAULT_NEUTRAL_COLOR,
    BlastholeSingleSegment: DEFAULT_NEUTRAL_COLOR,
    BlockModel: DEFAULT_NEUTRAL_COLOR,
    BlockVolume: DEFAULT_NEUTRAL_COLOR,
    Boundary: DEFAULT_NEUTRAL_COLOR,
    CrossSectionX: DEFAULT_NEUTRAL_COLOR,
    CrossSectionY: DEFAULT_NEUTRAL_COLOR,
    CrossSectionZ: DEFAULT_NEUTRAL_COLOR,
    DrilledHoleCollars: DEFAULT_NEUTRAL_COLOR,
    DrilledHolesContour: DEFAULT_NEUTRAL_COLOR,
    Undefined: DEFAULT_NEUTRAL_COLOR
  },
  patternVisibilities: [],
  patternOpacities: [],
  patternColors: [],
  maskedColor: Color.BLACK,
  cameraFrustum: CameraFrustum.PERSPECTIVE,
  showSubDrill: true,
  topPlaneVisibility: false,
  axesVisibility: true,
  crossSectionOffsetX: 0,
  crossSectionOffsetY: 0,
  crossSectionOffsetZ: 0,
  showMap2D: false,
  boundingBoxVisibility: false,
  boundingBoxColor: DEFAULT_NEUTRAL_COLOR,
  topOfBenchPlaneColor: DEFAULT_NEUTRAL_COLOR,
  collarDepth: 0,
  collarStyle: {
    collarAppearance: CollarAppearance.Disk,
    collarDiameter: 0.75//0.3048
  },
  sectionViewDistanceThreshold: 20,
  selectedCrossSectionStyle: { outlineColor: Color.ORANGE, outlineThickness: 2.0 },
  showToolbar: true,
  showControlsHelp: true,
  floatingMapSnapPosition: { adhesiveness: Adhesiveness.None, x: 2048, y: 0 },
  colorLegendSnapPosition: { adhesiveness: Adhesiveness.None, x: 2048, y: 2048 },
  infoBoxSnapPosition: { adhesiveness: Adhesiveness.None, x: 0, y: 0 },
  prominenceFilter: 0,
  showCenterOfRotationEntity: false,
  showAboveBench: true,
  showWithinBench: true,
  showBeneathBench: true,
  benchElevationLimits: undefined,
  holeLabels: HoleLabelType.None,
  showFloatingLegend: true,
  segmentStyle: { segmentDisplayStyle: "large"}
}

//----------------------------------------------------------------------------------------------------------------------
// API
//----------------------------------------------------------------------------------------------------------------------
class UserSessionUrls {
  public static allSessionsUrl = () => `${apiRoot}/userSession`;
  public static oneSessionUrl = (sessionUuid: string) => `${apiRoot}/userSession/${sessionUuid}`;
}

class PatternSummaryUrls {
  public static onePatternSummary = (patternId: string ) => `${apiRoot}/pattern/${patternId}/summary`;
  public static allAssetSummaries = (patternId: string ) => `${apiRoot}/pattern/asset?pattern=${patternId}`;
}

class AssetSummaryUrls {
  public static oneAssetSummary = (assetId: string ) => `${apiRoot}/asset/${assetId}`;
}

/*
  API DTO converters
 */
function savedSessionToDto( userSession: ISavedSession ): any {
  return {
    uuid: userSession.uuid,
    name: userSession.getName(),
    patternIds: userSession.patternIds,
    viewModelJson: JSON.stringify(userSession.sessionData),
  }
}

function dtoToSavedSession( dto: any ): ISavedSession {
  return {
    uuid: dto.id,
    getName: () => dto.name ?? `Updated ${format(new Date(dto.lastModifiedDate), t("LONG_DATE_FORMAT"))}`,
    patternIds: dto.patternIds,
    sessionData: dto.viewModelJson ? JSON.parse(dto.viewModelJson) : undefined,
    createdTimestamp: new Date(dto.createdTimestamp),
    lastModifiedDate: new Date(dto.lastModifiedDate)
  } as ISavedSession ;
}

/*
  API methods
 */
export const useUserSessionApi = () => {
  const fetchWithAuth = useFetchWithAuth();

  const INVALID_PATTERN_SUMMARY = {patternId: ""} as IPatternSummary;
  const IS_INVALID_PATTERN_SUMMARY = (summary: IPatternSummary): boolean => (summary.patternId.trim().length === 0);
  const INVALID_ASSET_SUMMARY = {identifier: ""} as IAssetSummary;
  const IS_INVALID_ASSET_SUMMARY = (summary: IAssetSummary): boolean => (summary.identifier.trim().length === 0);

  /**
   * Fetch a specific saved session
   * @param userSessionUuid
   */
  const fetchUserSession = async ( userSessionUuid: string ) => {
    console.log(`>>> fetchUserSession(${UserSessionUrls.oneSessionUrl(userSessionUuid)})`);
    return await fetchWithAuth( UserSessionUrls.oneSessionUrl(userSessionUuid) )
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`fetchUserSession: status=${resp.status}`)
          }
          return resp.json();
        })
        .then( json => {
          return dtoToSavedSession( json );
        })
  }
  /**
   * Update (or create) a specific saved session
   * @param userSession
   */
  const saveUserSession = async ( userSession: ISavedSession ) => {
    const isCreate = !userSession.uuid ;
    const url = userSession.uuid ? UserSessionUrls.oneSessionUrl(userSession.uuid) : UserSessionUrls.allSessionsUrl() ;

    return await fetchWithAuth( url, {
      method: isCreate ? "POST" : "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify( savedSessionToDto( userSession ) )
    })
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`saveUserSession: status=${resp.status}`)
          }
          return isCreate ? resp.json() : true ;
        })
        .then( content => {
          let result: ISavedSession ;
          if ( isCreate ) {
            result = dtoToSavedSession( content ) ;
          } else {
            result = userSession ;
          }
          return result ;
        })
  }
  /**
   * Fetch a specific pattern summary
   * @param patternId
   */
  const fetchPatternSummary = async ( patternId: string) => {
    return await fetchWithAuth( PatternSummaryUrls.onePatternSummary(patternId) )
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`fetchPatternSummary: status=${resp.status}`)
          }
          return resp.json();
        })
        .then( json => {
          try {
            return PatternMapper.patternSummaryDtoToPatternSummary(json as IPatternSummaryDto);
          }
          catch (error) {
            return INVALID_PATTERN_SUMMARY;
          }
        })
  }
  /**
   * Fetches asset summaries for a given pattern
   * @param patternId
   */
  const fetchAssetSummaries = async ( patternId: string) => {
    return await fetchWithAuth( PatternSummaryUrls.allAssetSummaries( patternId ) )
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`fetchPatternSummary: status=${resp.status}`)
          }
          return resp.json();
        })
        .then( json => {
          return (json.content as any[]).map( json => {
            try {
              return AssetSummaryMapper.assetSummaryDtoToAssetSummary(json as IAssetSummaryDto);
            }
            catch (error) {
              return INVALID_ASSET_SUMMARY;
            }
          });
        })
  }
  /**
   * Fetch many pattern summaries
   * @param patternIds
   */
  const fetchManyPatternSummaries = async ( patternIds: string[] ) => {
    if ( patternIds.length > 0 ) {
      return Promise.all(patternIds.map( async (patternId) => {
        return await fetchPatternSummary( patternId )
      })).then((summaries) => {
        return summaries.filter(item => !IS_INVALID_PATTERN_SUMMARY(item))
      });
    } else {
      return [];
    }
  }
  /**
   * Fetches assets summaries for multiple patterns
   * @param patternIds
   */
  const fetchAssetSummariesForPatterns = async ( patternIds: string[] ) => {
    if ( patternIds.length > 0 ) {
      return Promise.all(patternIds.map( async (patternId) => {
        return await fetchAssetSummaries( patternId )
      })).then((resultSets) => resultSets
          .flat(1)
          .filter(item => !IS_INVALID_ASSET_SUMMARY(item))
      );
    } else {
      return [];
    }
  }
  /**
   * fetches a specific asset summary
   * @param assetId
   */
  const fetchAssetSummary = async (assetId: string) => {
    return await fetchWithAuth( AssetSummaryUrls.oneAssetSummary(assetId) )
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`fetchAssetSummary: status=${resp.status}`)
          }
          return resp.json();
        })
        .then( json => {
          try {
            return AssetSummaryMapper.assetSummaryDtoToAssetSummary(json as IAssetSummaryDto);
          } catch ( error ) {
            return INVALID_ASSET_SUMMARY;
          }
        })
  }
  /**
   * Fetch a collection of asset summaries
   * @param assetIds
   */
  const fetchManyAssetSummaries = async (assetIds: string[] ) => {
    if ( assetIds.length > 0 ) {
      return Promise.all(assetIds.map( async (assetId) => {
        return await fetchAssetSummary( assetId );
      })).then((resultSets) => resultSets
          .flat(1)
          .filter(item => !IS_INVALID_ASSET_SUMMARY(item))
      );
    } else {
      return [];
    }
  }

  return {
    fetchUserSession,
    saveUserSession,
    fetchManyPatternSummaries,
    fetchAssetSummariesForPatterns,
    fetchManyAssetSummaries
  }
}


function userSessionStateToViewModelDto(state: UserSessionState): any {
  return {
    // patternSummaries: state.patternSummaries?.map(PatternMapper.patternSummaryToPatternSummaryDto) ?? null,
    // assetSummaries: state.assetSummaries?.map(AssetSummaryMapper.assetSummaryToAssetSummaryDto) ?? null,
    // originReference: state.originReference? [state.originReference.x, state.originReference.y, state.originReference.z] : null,
    // blockSize: state.blockSize? [state.blockSize.x, state.blockSize.y, state.blockSize.z] : null,
    // featureIndex?: CompositeFeatureIndex|undefined;
    // assetsToManuallyUpdate?: IAssetSummary[]|undefined;
    // rockMassDefinitions?: Map<string,RockMassDomainRange[]>|undefined; // references the rock mass definitions that should be common for all assets in the current session
    // boundingSphere?: BoundingSphere|undefined;
    // attributesInData?: AttributeType[]|undefined;
    // badAssets?: IBadAssetContext[]|undefined;
    // colorLegendStates?: Map<AttributeType, IColorLegend>|undefined;
    // boundingBox?: IBoundingBox|undefined;
    layersVisibility: state.layersVisibility,
    layersOpacity: state.layersOpacity,
    layersColor: {
      Undefined: state.layersColor.Undefined.toCssHexString(),
      BlockModel: state.layersColor.Undefined.toCssHexString(),
      BlastholeSecondarySegments: state.layersColor.BlastholeSecondarySegments.toCssHexString(),
      BlastholeSingleSegment: state.layersColor.BlastholeSingleSegment.toCssHexString(),
      DrilledHolesContour: state.layersColor.DrilledHolesContour.toCssHexString(),
      BlockVolume: state.layersColor.BlockVolume.toCssHexString(),
      DrilledHoleCollars: state.layersColor.DrilledHoleCollars.toCssHexString(),
      Boundary: state.layersColor.Boundary.toCssHexString(),
      BlastholeClusters: state.layersColor.BlastholeClusters.toCssHexString(),
      BlastholeFractures: state.layersColor.BlastholeFractures.toCssHexString(),
      CrossSectionX: state.layersColor.CrossSectionX.toCssHexString(),
      CrossSectionY: state.layersColor.CrossSectionY.toCssHexString(),
      CrossSectionZ: state.layersColor.CrossSectionZ.toCssHexString(),
    } as ILayerTypes<string>,
    patternVisibilities: state.patternVisibilities,
    patternOpacities: state.patternOpacities,
    patternColors: state.patternColors.map( patternColorStruct => ({ patternId: patternColorStruct.patternId, color: patternColorStruct.color.toCssHexString() })),
    selectedAttribute: state.selectedAttribute ? AttributeType[ state.selectedAttribute ] : null,
    maskedColor: state.maskedColor.toCssHexString(),
    cameraFrustum: CameraFrustum[state.cameraFrustum],
    showSubDrill: state.showSubDrill,
    topPlaneVisibility: state.topPlaneVisibility,
    axesVisibility: state.axesVisibility,
    selectedCrossSection: state.selectedCrossSection && LayerType[state.selectedCrossSection],
    crossSectionOffsetX: state.crossSectionOffsetX,
    crossSectionOffsetY: state.crossSectionOffsetY,
    crossSectionOffsetZ: state.crossSectionOffsetZ,
    showMap2D: state.showMap2D,
    boundingBoxVisibility: state.boundingBoxVisibility,
    boundingBoxColor: state.boundingBoxColor.toCssHexString(),
    topOfBenchPlaneColor: state.topOfBenchPlaneColor.toCssHexString(),
    collarStyle: {
      collarDiameter: state.collarStyle.collarDiameter,
      collarAppearance: CollarAppearance[ state.collarStyle.collarAppearance ]
    },
    collarDepth: state.collarDepth,
    sectionViewDistanceThreshold: state.sectionViewDistanceThreshold,
    selectedCrossSectionStyle: {
      outlineColor: state.selectedCrossSectionStyle.outlineColor.toCssHexString(),
      outlineThickness: state.selectedCrossSectionStyle.outlineThickness
    },
    showToolbar: state.showToolbar,
    showControlsHelp: state.showControlsHelp,
    floatingMapSnapPosition: {
      adhesiveness: Adhesiveness[ state.floatingMapSnapPosition.adhesiveness ],
      x: state.floatingMapSnapPosition.x,
      y: state.floatingMapSnapPosition.y
    },
    infoBoxSnapPosition: {
      adhesiveness: Adhesiveness[ state.infoBoxSnapPosition.adhesiveness ],
      x: state.infoBoxSnapPosition.x,
      y: state.infoBoxSnapPosition.y
    },
    colorLegendSnapPosition: {
      adhesiveness: Adhesiveness[ state.colorLegendSnapPosition.adhesiveness ],
      x: state.colorLegendSnapPosition.x,
      y: state.colorLegendSnapPosition.y
    },
    prominenceFilter: state.prominenceFilter,
    showCenterOfRotationEntity: state.showCenterOfRotationEntity,
    imageDataURL: state.imageDataURL,
    cameraVantagePoint3D: state.cameraVantagePoint3D,
    showAboveBench: state.showAboveBench,
    showWithinBench: state.showWithinBench,
    showBeneathBench: state.showBeneathBench,
    holeLabels: HoleLabelType[ state.holeLabels ?? HoleLabelType.None ],
    showFloatingLegend: state.showFloatingLegend,
    segmentStyle: state.segmentStyle
  }
}

function viewModelDtoToUserSessionState(dto: any): UserSessionState {
  try {
    // console.log(`viewModelDtoToUserSessionState=${dto}`)
    return {
      sessionId: '',
      // patternSummaries: dto.patternSummaries ? (dto.patternSummaries as any[]).map(PatternMapper.patternSummaryDtoToPatternSummary) : undefined,
      // assetSummaries: dto.assetSummaries?.map(AssetSummaryMapper.assetSummaryDtoToAssetSummary) ?? undefined,
      // originReference: dto.originReference? Cartesian3.fromArray(dto.originReference) : undefined,
      // blockSize: dto.blockSize? Cartesian3.fromArray(dto.blockSize) : undefined,
      // featureIndex?: CompositeFeatureIndex|undefined;
      // assetsToManuallyUpdate?: IAssetSummary[]|undefined;
      // rockMassDefinitions?: Map<string,RockMassDomainRange[]>|undefined; // references the rock mass definitions that should be common for all assets in the current session
      // boundingSphere?: BoundingSphere|undefined;
      // attributesInData?: AttributeType[]|undefined;
      // badAssets?: IBadAssetContext[]|undefined;
      // colorLegendStates?: Map<AttributeType, IColorLegend>|undefined;
      // boundingBox?: IBoundingBox|undefined;
      layersVisibility: dto.layersVisibility as ILayerTypes<boolean> ?? DEFAULT_USER_SESSION.layersVisibility,
      layersOpacity: dto.layersOpacity as ILayerTypes<number> ?? DEFAULT_USER_SESSION.layersOpacity,
      layersColor: dto.layersColor ? {
        Undefined: dto.layersColor.Undefined ? Color.fromCssColorString(dto.layersColor.Undefined) : DEFAULT_USER_SESSION.layersColor.Undefined,
        BlockModel: dto.layersColor.BlockModel ? Color.fromCssColorString(dto.layersColor.BlockModel) : DEFAULT_USER_SESSION.layersColor.BlockModel,
        BlastholeSecondarySegments: dto.layersColor.BlastholeSecondarySegments ? Color.fromCssColorString(dto.layersColor.BlastholeSecondarySegments) : DEFAULT_USER_SESSION.layersColor.BlastholeSecondarySegments,
        BlastholeSingleSegment: dto.layersColor.BlastholeSingleSegment ? Color.fromCssColorString(dto.layersColor.BlastholeSingleSegment) : DEFAULT_USER_SESSION.layersColor.BlastholeSingleSegment,
        DrilledHolesContour: dto.layersColor.DrilledHolesContour ? Color.fromCssColorString(dto.layersColor.DrilledHolesContour) : DEFAULT_USER_SESSION.layersColor.DrilledHolesContour,
        BlockVolume: dto.layersColor.BlockVolume ? Color.fromCssColorString(dto.layersColor.BlockVolume) : DEFAULT_USER_SESSION.layersColor.BlockVolume,
        DrilledHoleCollars: dto.layersColor.DrilledHoleCollars ? Color.fromCssColorString(dto.layersColor.DrilledHoleCollars) : DEFAULT_USER_SESSION.layersColor.DrilledHoleCollars,
        Boundary: dto.layersColor.Boundary ? Color.fromCssColorString(dto.layersColor.Boundary) : DEFAULT_USER_SESSION.layersColor.Boundary,
        BlastholeClusters: dto.layersColor.BlastholeClusters ? Color.fromCssColorString(dto.layersColor.BlastholeClusters) : DEFAULT_USER_SESSION.layersColor.BlastholeClusters,
        BlastholeFractures: dto.layersColor.BlastholeFractures ? Color.fromCssColorString(dto.layersColor.BlastholeFractures) : DEFAULT_USER_SESSION.layersColor.BlastholeFractures,
        CrossSectionX: dto.layersColor.CrossSectionX ? Color.fromCssColorString(dto.layersColor.CrossSectionX) : DEFAULT_USER_SESSION.layersColor.CrossSectionX,
        CrossSectionY: dto.layersColor.CrossSectionY ? Color.fromCssColorString(dto.layersColor.CrossSectionY) : DEFAULT_USER_SESSION.layersColor.CrossSectionY,
        CrossSectionZ: dto.layersColor.CrossSectionZ ? Color.fromCssColorString(dto.layersColor.CrossSectionZ) : DEFAULT_USER_SESSION.layersColor.CrossSectionZ,
      } as ILayerTypes<Color> : {...DEFAULT_USER_SESSION.layersColor},
      patternVisibilities: dto.patternVisibilities ?? [],
      patternOpacities: dto.patternOpacities ?? [],
      patternColors: (dto.patternColors as any[] ?? []).map(patternColorStruct => ({
        patternId: patternColorStruct.patternId,
        color: Color.fromCssColorString(patternColorStruct.color)
      })),
      selectedAttribute: dto.selectedAttribute ? (AttributeType[dto.selectedAttribute as keyof typeof AttributeType] ?? undefined) : undefined,
      maskedColor: Color.fromCssColorString(dto.maskedColor ?? DEFAULT_USER_SESSION.maskedColor),
      cameraFrustum: dto.cameraFrustum ? (CameraFrustum[dto.cameraFrustum as keyof typeof CameraFrustum] ?? DEFAULT_USER_SESSION.cameraFrustum) : DEFAULT_USER_SESSION.cameraFrustum,
      showSubDrill: dto.showSubDrill ?? DEFAULT_USER_SESSION.showSubDrill,
      topPlaneVisibility: dto.topPlaneVisibility ?? DEFAULT_USER_SESSION.topPlaneVisibility,
      axesVisibility: dto.axesVisibility ?? DEFAULT_USER_SESSION.axesVisibility,
      selectedCrossSection: (dto.selectedCrossSection ? (LayerType[dto.selectedCrossSection as keyof typeof LayerType] ?? undefined) : undefined) as LayerType.CrossSectionX | LayerType.CrossSectionY | LayerType.CrossSectionZ | undefined,
      crossSectionOffsetX: dto.crossSectionOffsetX ?? DEFAULT_USER_SESSION.crossSectionOffsetX,
      crossSectionOffsetY: dto.crossSectionOffsetY ?? DEFAULT_USER_SESSION.crossSectionOffsetY,
      crossSectionOffsetZ: dto.crossSectionOffsetZ ?? DEFAULT_USER_SESSION.crossSectionOffsetZ,
      showMap2D: dto.showMap2D ?? DEFAULT_USER_SESSION.showMap2D,
      boundingBoxVisibility: dto.boundingBoxVisibility ?? DEFAULT_USER_SESSION.boundingBoxVisibility,
      boundingBoxColor: dto.boundingBoxColor ? Color.fromCssColorString(dto.boundingBoxColor) : DEFAULT_USER_SESSION.boundingBoxColor,
      topOfBenchPlaneColor: dto.topOfBenchPlaneColor ? Color.fromCssColorString(dto.topOfBenchPlaneColor) : DEFAULT_USER_SESSION.topOfBenchPlaneColor,
      collarStyle: dto.collarStyle ? {
        collarDiameter: dto.collarStyle.collarDiameter ?? DEFAULT_USER_SESSION.collarStyle.collarDiameter,
        collarAppearance: dto.collarStyle.collarAppearance ? CollarAppearance[dto.collarStyle.collarAppearance as keyof typeof CollarAppearance] : DEFAULT_USER_SESSION.collarStyle.collarAppearance
      } : {...DEFAULT_USER_SESSION.collarStyle},
      collarDepth: dto.collarDepth ?? DEFAULT_USER_SESSION.collarDepth,
      sectionViewDistanceThreshold: dto.sectionViewDistanceThreshold ?? DEFAULT_USER_SESSION.sectionViewDistanceThreshold,
      selectedCrossSectionStyle: dto.selectedCrossSectionStyle ? {
        outlineColor: dto.selectedCrossSectionStyle.outlineColor ? Color.fromCssColorString(dto.selectedCrossSectionStyle.outlineColor) : DEFAULT_USER_SESSION.selectedCrossSectionStyle.outlineColor,
        outlineThickness: dto.selectedCrossSectionStyle.outlineThickness ?? DEFAULT_USER_SESSION.selectedCrossSectionStyle.outlineThickness
      } : {...DEFAULT_USER_SESSION.selectedCrossSectionStyle},
      showToolbar: dto.showToolbar ?? DEFAULT_USER_SESSION.showToolbar,
      showControlsHelp: dto.showControlsHelp ?? DEFAULT_USER_SESSION.showControlsHelp,
      floatingMapSnapPosition: dto.floatingMapSnapPosition ? {
        adhesiveness: dto.floatingMapSnapPosition.adhesiveness ? Adhesiveness[dto.floatingMapSnapPosition.adhesiveness as keyof typeof Adhesiveness] : DEFAULT_USER_SESSION.floatingMapSnapPosition.adhesiveness,
        x: dto.floatingMapSnapPosition.x ?? DEFAULT_USER_SESSION.floatingMapSnapPosition.x,
        y: dto.floatingMapSnapPosition.y ?? DEFAULT_USER_SESSION.floatingMapSnapPosition.y
      } : {...DEFAULT_USER_SESSION.floatingMapSnapPosition},
      infoBoxSnapPosition: dto.infoBoxSnapPosition ? {
        adhesiveness: dto.infoBoxSnapPosition.adhesiveness ? Adhesiveness[dto.infoBoxSnapPosition.adhesiveness as keyof typeof Adhesiveness] : DEFAULT_USER_SESSION.infoBoxSnapPosition.adhesiveness,
        x: dto.infoBoxSnapPosition.x ?? DEFAULT_USER_SESSION.infoBoxSnapPosition.x,
        y: dto.infoBoxSnapPosition.y ?? DEFAULT_USER_SESSION.infoBoxSnapPosition.y
      } : {...DEFAULT_USER_SESSION.infoBoxSnapPosition},
      colorLegendSnapPosition: dto.colorLegendSnapPosition ? {
        adhesiveness: dto.colorLegendSnapPosition.adhesiveness ? Adhesiveness[dto.colorLegendSnapPosition.adhesiveness as keyof typeof Adhesiveness] : DEFAULT_USER_SESSION.colorLegendSnapPosition.adhesiveness,
        x: dto.colorLegendSnapPosition.x ?? DEFAULT_USER_SESSION.colorLegendSnapPosition.x,
        y: dto.colorLegendSnapPosition.y ?? DEFAULT_USER_SESSION.colorLegendSnapPosition.y
      } : {...DEFAULT_USER_SESSION.colorLegendSnapPosition},
      prominenceFilter: dto.prominenceFilter ?? DEFAULT_USER_SESSION.prominenceFilter,
      showCenterOfRotationEntity: dto.showCenterOfRotationEntity ?? DEFAULT_USER_SESSION.showCenterOfRotationEntity,
      imageDataURL: dto.imageDataURL ?? undefined,
      cameraVantagePoint3D: dto.cameraVantagePoint3D ?? undefined,
      showAboveBench: dto.showAboveBench ?? true,
      showWithinBench: dto.showWithinBench ?? true,
      showBeneathBench: dto.showBeneathBench ?? true,
      benchElevationLimits: undefined,
      holeLabels: dto.holeLabels ? HoleLabelType[ dto.holeLabels as keyof typeof HoleLabelType ] : HoleLabelType.None,
      showFloatingLegend: dto.showFloatingLegend ?? true,
      segmentStyle: dto.segmentStyle ? {
        segmentDisplayStyle: dto.segmentStyle.segmentDisplayStyle ?? DEFAULT_USER_SESSION.segmentStyle.segmentDisplayStyle
      } : {...DEFAULT_USER_SESSION.segmentStyle}
    }
  }
  catch ( error ) {
    console.error(`viewModelDtoToUserSessionState: ${error}`) ;
    throw error ;
  }
}

