import IPattern from "../../model/IPattern";

export const parsePatternsResponse = async (res: Response) => {
  const jsonData = await res.json();
  const patterns = jsonData
    ? (jsonData.content as any[]).map(
        (item) =>
          ({
            name: item.name,
            patternId: item.patternId,
            layers: item.layers,
            start: new Date(item.start),
            end: new Date(item.end),
            dmmRevisionTimestamp: item.dmmRevisionTimestamp ? new Date(item.dmmRevisionTimestamp) : undefined,
            tags: item.tags ?? [],
          } as IPattern)
      )
    : [];
  return patterns;
};
