import React from "react";
import ITaskProgress from "../../model/ITaskProgress";
import {withTranslation, WithTranslation} from "react-i18next";
import {t} from "i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

export interface IUploadResult {
    subject: string;
    message: string;
    isError: boolean;
}

interface UploadResultDialogProps extends WithTranslation {
    uploadResults: IUploadResult[];
    onDismiss: ()=>void;
}

const BORDER_RADIUS = "5px";

const UploadResultDialog: React.FC<UploadResultDialogProps> = (props) => {
    return (
        <>
            <div className="w3-modal" style={{display: "block"}}>
                <div className="w3-modal-content w3-card-4 w3-theme-d4" style={{borderRadius: BORDER_RADIUS}}>
                    <header className="w3-container w3-light-blue w3-center" style={{borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0px 0px`}}>
                        <h6>{t("Upload Results")}</h6>
                    </header>
                    <div>
                        <ul
                            className={"w3-ul w3-padding"}
                            style={{
                                maxHeight: "50vh",
                                overflowY: "auto"
                            }}
                        >
                        {
                            props.uploadResults && props.uploadResults.map((uploadResult, idx)=>(
                                <li key={"progress_"+idx} className="w3-cell-row w3-section w3-container">
                                    <div
                                        className={"w3-cell w3-cell-middle w3-center"}
                                        style={{width: "50px"}}
                                    >
                                        <FontAwesomeIcon
                                            className={`${uploadResult.isError ? "w3-text-red" : "w3-text-light-blue"}`}
                                            style={{fontSize: "24px"}}
                                            icon={uploadResult.isError ? faTriangleExclamation : faCircleInfo}
                                        />
                                    </div>
                                    <div className={"w3-cell w3-cell-middle"}>
                                        <div style={{fontSize: "12px"}}>{t(uploadResult.subject)}</div>
                                        <div>{t(uploadResult.message)}</div>
                                    </div>
                                </li>
                            ))
                        }
                        </ul>
                    </div>
                    <footer className="w3-container w3-theme-d3 w3-padding w3-center" style={{borderRadius: `0px 0px ${BORDER_RADIUS} ${BORDER_RADIUS}`}}>
                        <button className="w3-btn w3-grey w3-round-medium w3-hover-light-blue" onClick={props.onDismiss}>{t("Dismiss")}</button>
                    </footer>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(UploadResultDialog) ;
