import React from "react";
import {t} from "i18next";
import {withTranslation, WithTranslation} from "react-i18next";
import {LegendType} from "../../domain/LegendType";
import IColorLegendItemConfiguration from "../../domain/IColorLegendItemConfiguration";
import IColorLegendConfiguration from "../../domain/IColorLegendConfiguration";
import {IColorLegend} from "../../hooks/useColorLegendApi";
import {useSiteConfig} from "../../hooks/useSiteConfig";

interface Segment {
  color: string;
  start: number;
  end: number;
  text: string;
}
const foregroundColorCss = "#ffffffbb"

interface IProps extends WithTranslation {
  width: number;
  height: number;
  fontSize: number;
  margin: number;
  colorLegendConfig: IColorLegendConfiguration|undefined,
  colorLegendState: IColorLegend|undefined
}

// export function colorRangeToString( legendType: LegendType, item: IColorLegendItemConfiguration, precision: number ): string {
//   if ( legendType === LegendType.Enum || item.range.length === 1 ) {
//     return item.label ?? item.range[0].toFixed(precision);
//   }
//   else if ( item.range.length === 2 ) {
//     return item.label ?? (item.range[0].toFixed(precision) + " - " + item.range[1].toFixed(precision));
//   }
//   else {
//     throw new Error(`colorRangeToString: Invalid arguments: range=${JSON.stringify(item.range)}`);
//   }
// }

const CompactColorLegend: React.FC<IProps> = (props) => {
  const space = 10;
  let totalWeight: number = 0;
  let segments: Segment[] = [];
  let clusterSegments: Segment[] = [];
  const [siteConfig] = useSiteConfig();

  let maxLenAttribute = 0;
  let startOffset = 0 ;
  const fixedHeight = 2 * props.fontSize ;
  let varHeight = props.height - 2 * fixedHeight ;

  const colorLegendConfig = props.colorLegendConfig;
  const colorLegendState = props.colorLegendState;

  if (colorLegendState && colorLegendConfig) {
      totalWeight = colorLegendConfig.items
          .reduce((sum, item) => sum + item.range[1] - item.range[0], 0) ?? 0;

      if (totalWeight > 0) {

        // ... Build the segment list
        if (siteConfig.showInvalidColorConfig) {
          segments.push({
            color: colorLegendConfig.invalidItem.color.toCssHexString(),
            text: t(colorLegendState.colorInvalidValue.label),
            start: 0,
            end: fixedHeight,
          } as Segment);
        }

        startOffset = segments.length > 0 ? segments[segments.length - 1].end : 0;

        for (let i = 0; i < colorLegendConfig.items.length; i += 1) {
          let item = colorLegendConfig.items[i];
          let label = colorLegendState.colorLegendItems[i].label;
          segments.push({
            color: colorLegendConfig.items[i].color.toCssHexString(),
            text: label,
            start: startOffset + varHeight * item.range[0] / totalWeight,
            end: startOffset + varHeight * item.range[1] / totalWeight,
          } as Segment);
          maxLenAttribute = Math.max(maxLenAttribute, label.length);
        }

        if (colorLegendState.colorRangeExceeded && colorLegendConfig.rangeExceededItem) {
          // let item = colorLegendConfig.rangeExceededItem;

          let label = colorLegendState.colorRangeExceeded.label;

          let start = segments[segments.length - 1].end;
          segments.push({
            color: colorLegendConfig.rangeExceededItem.color.toCssHexString(),
            text: label,
            // "> max",
            start: start,
            end: start + fixedHeight,
          } as Segment);
        }
      }
    }

  const guide = 0.95 * props.width;

  return (
      <svg
          className={"w3-padding-small w3-round-medium"}
          style={{margin: `${props.margin}px`}}
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.height}
          viewBox={`0 0 ${props.width} ${props.height}`}
      >
        {
          segments
              .reverse()
              .map( ( segment, idx ) => (
                  <React.Fragment key={`Attr_Segment_${idx}`}>
                    <line
                        style={{
                          stroke: segment.color,
                          strokeWidth: "10"
                        }}
                        x1={guide}
                        y1={props.height - segment.start}
                        x2={guide}
                        y2={props.height - segment.end}
                    />
                    <text
                        x={guide - 10}
                        textAnchor={"end"}
                        y={props.height - (segment.start + segment.end)/2}
                        style={{
                          fill: foregroundColorCss,
                          fontSize: `${props.fontSize}px`
                        }}
                        className={"unselectable"}
                    >
                      {segment.text}
                    </text>
                  </React.Fragment>
              ) )
        }
        {
          clusterSegments
              .reverse()
              .map( ( segment, idx ) => (
                  <React.Fragment key={`Cluster_Segment_${idx}`}>
                    <text
                        x={guide + space + 10}
                        textAnchor="left"
                        y={props.height - (segment.start + segment.end)/2}
                        style={{
                          fill: foregroundColorCss,
                          fontSize: `${props.fontSize}px`
                        }}
                        className={"unselectable"}
                    >
                      {segment.text}
                    </text>
                    <line
                        style={{
                          stroke: foregroundColorCss,
                          strokeWidth: "2"
                        }}
                        x1={guide + space}
                        y1={props.height - segment.start}
                        x2={guide + space + 5}
                        y2={props.height - segment.start}
                    />
                    <line
                        style={{
                          stroke: foregroundColorCss,
                          strokeWidth: "1"
                        }}
                        x1={guide + space + 5}
                        y1={props.height - segment.start}
                        x2={guide + space + 5}
                        y2={props.height - segment.end}
                    />
                    <line
                        style={{
                          stroke: foregroundColorCss,
                          strokeWidth: "2"
                        }}
                        x1={guide + space}
                        y1={props.height - segment.end}
                        x2={guide + space + 5}
                        y2={props.height - segment.end}
                    />
                  </React.Fragment>
              ) )
        }
      </svg>
  )
}

export default withTranslation()(CompactColorLegend);