import React, {useCallback, useEffect, useMemo, useState} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import ColorPickerDialog, {IColorPickerDialogProps,} from "../components/Dialogs/ColorPickerDialog";
import {Cartesian3, Color, Matrix3} from "cesium";
import TranslationVectorEditor from "../components/SiteConfigEditor/TranslationVectorEditor";
import RotationMatrixEditor, {IMatrix3,} from "../components/SiteConfigEditor/RotationMatrixEditor";
import IVector3 from "../model/IVector3";
import PromptDialog, {IPromptDialogProps,} from "../components/Dialogs/PromptDialog";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faLockOpen} from "@fortawesome/free-solid-svg-icons";
import ColorLegendRangesConfig from "../components/SiteConfigEditor/ColorLegendRangesConfig";
import ColorLegendEnumsConfig from "../components/SiteConfigEditor/ColorLegendEnumsConfig";
import {t} from "i18next";
import {useHelp} from "../Help/HelpContextProvider";
import ColumnPane from "../components/Gen2/components/BasicControls/ColumnPane";
import RoundedPanel from "../components/Gen2/components/BasicControls/RoundedPanel";
import TitledSection from "../components/Gen2/components/BasicControls/TitledSection";
import ContentPage from "../components/Gen2/components/BasicControls/ContentPage";
import Help_SiteConfig_BaseTransformation from "../Help/Help_SiteConfig_BaseTransformation";
import Help_SiteConfig_ColorLegend from "../Help/Help_SiteConfig_ColorLegend";
import {useSiteConfig} from "../components/Gen2/hooks/useSiteConfig";
import {ISiteConfiguration} from "../components/Gen2/domain/ISiteConfiguration";
import {
  AttributeType,
  IAttributeTypeMap,
  SITE_CONFIG_EDITABLE_ATTRIBUTES
} from "../components/Gen2/domain/AttributeType";
import IColorLegendConfiguration from "../components/Gen2/domain/IColorLegendConfiguration";
import {LegendType} from "../components/Gen2/domain/LegendType";
import SiteConfigurationMapper from "../components/Gen2/mappers/SiteConfigurationMapper";

const TITLE_BAR_HEIGHT = 51;//px
const MARGIN_HEIGHT = 16;//px

const toMatrix3 = (arr: Matrix3): IMatrix3 => {
  return {
    c0r0: arr[Matrix3.COLUMN0ROW0],
    c0r1: arr[Matrix3.COLUMN0ROW1],
    c0r2: arr[Matrix3.COLUMN0ROW2],

    c1r0: arr[Matrix3.COLUMN1ROW0],
    c1r1: arr[Matrix3.COLUMN1ROW1],
    c1r2: arr[Matrix3.COLUMN1ROW2],

    c2r0: arr[Matrix3.COLUMN2ROW0],
    c2r1: arr[Matrix3.COLUMN2ROW1],
    c2r2: arr[Matrix3.COLUMN2ROW2],
  } as IMatrix3;
};

const SiteConfigPage: React.FC<WithTranslation> = ({ t }) => {

  const [siteConfig, siteConfigActions] = useSiteConfig();
  const [siteConfigLocal, setSiteConfigLocal] = useState<ISiteConfiguration>(/*DEFAULT_SITE_CONFIGURATION*/);
  const [selectedAttribute, setSelectedAttribute] = useState<AttributeType>();
  const [colorPickerProps, setColorPickerProps] = useState<IColorPickerDialogProps>();
  const [promptDlgProps, setPromptDlgProps] = useState<IPromptDialogProps>();
  const [unlocked, setUnlocked] = useState<boolean>(false);
  const [, setHelpRequested] = useHelp();
  const [columnPaneHeight, setColumnPaneHeight] = useState<number>();

  const updateSiteConfigLocal = useCallback(( cb: (prevState: ISiteConfiguration)=>ISiteConfiguration ) => {
    setSiteConfigLocal( prevState => prevState ? cb(prevState):undefined);
  }, [setSiteConfigLocal]);

  const siteConfigDirty = useMemo(()=>{
    if ( !siteConfig ) return false ;
    const siteConfigJson = JSON.stringify( SiteConfigurationMapper.siteConfigToSiteConfigDto(siteConfig) );
    const siteConfigLocalJson = JSON.stringify( siteConfigLocal ? SiteConfigurationMapper.siteConfigToSiteConfigDto(siteConfigLocal) : {});
    return siteConfigJson.localeCompare(siteConfigLocalJson) !== 0;

  }, [siteConfig, siteConfigLocal]);

  const selectedColorLegend = useMemo(()=>{
    if ( siteConfigLocal && selectedAttribute ) {
      return siteConfigLocal.legendConfigs[AttributeType[selectedAttribute] as keyof IAttributeTypeMap<IColorLegendConfiguration>];
    } else {
      return undefined ;
    }
  }, [selectedAttribute, siteConfigLocal]);

  const updateColorLegendConfig = useCallback(( attributeType: AttributeType, colorLegendConfig: IColorLegendConfiguration ) => {
    updateSiteConfigLocal( prevState => ({
      ...prevState,
      legendConfigs: {
        ...prevState.legendConfigs,
        [AttributeType[attributeType]]: colorLegendConfig
      }
    }) );
  }, [updateSiteConfigLocal]);

  const persistsSiteConfig = useCallback(()=>{
    if ( !siteConfigLocal ) return;
    siteConfigActions.saveSiteConfig( deepCopy( siteConfigLocal ) )
        .then( result => console.log('Site config saved'));
  }, [siteConfigLocal, siteConfigActions]);

  const resetSiteConfig = useCallback(async () => {
    const copy: ISiteConfiguration = deepCopy( siteConfig ) ;
    setSiteConfigLocal( copy );
  }, [siteConfig, setSiteConfigLocal]);

  useEffect(()=>{
    if ( !siteConfig ) return ;
    const copy: ISiteConfiguration = deepCopy( siteConfig ) ;
    setSiteConfigLocal( copy );
    setSelectedAttribute( prevState => prevState ? prevState : AttributeType[ copy.availableAttributes[ 0 ] ] );
  }, [siteConfig, resetSiteConfig, setSiteConfigLocal]);

  const onTranslationUpdated = useCallback((translation: IVector3) => {
    updateSiteConfigLocal( prevState => ({
      ...prevState,
      translation: Cartesian3.fromArray([translation.x, translation.y, translation.z])
    }));
  }, [updateSiteConfigLocal]);

  const onRotationUpdated = useCallback((matrix: IMatrix3) => {
    updateSiteConfigLocal( prevState => ({
      ...prevState,
      rotation: Matrix3.fromArray([
        matrix.c0r0,
        matrix.c0r1,
        matrix.c0r2,
        matrix.c1r0,
        matrix.c1r1,
        matrix.c1r2,
        matrix.c2r0,
        matrix.c2r1,
        matrix.c2r2,
      ])
    }));
  }, [updateSiteConfigLocal]);

  const updateInvalidItemColor = useCallback((color: string) => {
    if (!selectedAttribute) return;
    const colorLegendKey = AttributeType[selectedAttribute] as keyof IAttributeTypeMap<IColorLegendConfiguration>;

    updateSiteConfigLocal( prevState => ({
      ...prevState,
      legendConfigs: {
        ...prevState.legendConfigs,
        [colorLegendKey]: {
          ...prevState.legendConfigs[colorLegendKey],
          invalidItem : {
            ...prevState.legendConfigs[colorLegendKey].invalidItem,
            color: Color.fromCssColorString(color)
          }
        }
      }
    }));
  }, [updateSiteConfigLocal, selectedAttribute]);

  const updateRangeExceededItemColor = useCallback((color: string) => {
    if (!selectedAttribute) return;
    const colorLegendKey = AttributeType[selectedAttribute] as keyof IAttributeTypeMap<IColorLegendConfiguration>;

    updateSiteConfigLocal( prevState => ({
      ...prevState,
      legendConfigs: {
        ...prevState.legendConfigs,
        [colorLegendKey]: {
          ...prevState.legendConfigs[colorLegendKey],
          rangeExceededItem : {
            ...prevState.legendConfigs[colorLegendKey].rangeExceededItem,
            color: Color.fromCssColorString(color)
          }
        }
      }
    }));
  }, [updateSiteConfigLocal, selectedAttribute]);

  const updateItemColor = useCallback((itemIndex: number, color: string) => {
    if (!selectedAttribute) return;
    const colorLegendKey = AttributeType[selectedAttribute] as keyof IAttributeTypeMap<IColorLegendConfiguration>;

    updateSiteConfigLocal( prevState => ({
      ...prevState,
      legendConfigs: {
        ...prevState.legendConfigs,
        [colorLegendKey]: {
          ...prevState.legendConfigs[colorLegendKey],
          items : [
            ...prevState.legendConfigs[colorLegendKey].items.slice(0, itemIndex),
            {
              ...prevState.legendConfigs[colorLegendKey].items[itemIndex],
              color: Color.fromCssColorString(color)
            },
            ...prevState.legendConfigs[colorLegendKey].items.slice(itemIndex + 1),
          ]
        }
      }
    }));
  }, [updateSiteConfigLocal, selectedAttribute]);

  const updateItemLabel = useCallback((itemIndex: number, label: string) => {
    if (!selectedAttribute) return;
    const colorLegendKey = AttributeType[selectedAttribute] as keyof IAttributeTypeMap<IColorLegendConfiguration>;

    updateSiteConfigLocal( prevState => ({
      ...prevState,
      legendConfigs: {
        ...prevState.legendConfigs,
        [colorLegendKey]: {
          ...prevState.legendConfigs[colorLegendKey],
          items : [
            ...prevState.legendConfigs[colorLegendKey].items.slice(0, itemIndex),
            {
              ...prevState.legendConfigs[colorLegendKey].items[itemIndex],
              label: label
            },
            ...prevState.legendConfigs[colorLegendKey].items.slice(itemIndex + 1),
          ]
        }
      }
    }));
  }, [updateSiteConfigLocal, selectedAttribute]);

  const updateInvalidColorVisibility = useCallback((isVisible: boolean) => {
    updateSiteConfigLocal( prevState => ({
      ...prevState,
      showInvalidColorConfig: isVisible
    }))
  }, [updateSiteConfigLocal]);

  const onColorBoxClicked = useCallback((
    itemIndex: number,
    updatedColor: string,
    callback: ((updatedColor: string) => void) | undefined
  ) => {
    if ( selectedColorLegend ) {
      const idx = itemIndex;
      const numItems = selectedColorLegend.items.length;

      setColorPickerProps({
        color: Color.fromCssColorString(updatedColor),
        onCancel: () => {
          setColorPickerProps(undefined);
        },
        onColorSelected: (selectedColor) => {
          switch (idx) {
            case -1:
              updateInvalidItemColor(selectedColor.toCssHexString());
              break;
            case numItems:
              updateRangeExceededItemColor(selectedColor.toCssHexString());
              break;
            default:
              updateItemColor(idx, selectedColor.toCssHexString());
              break;
          }
          setColorPickerProps(undefined);
        },
      });
    }
  }, [selectedColorLegend, updateInvalidItemColor, updateRangeExceededItemColor, updateItemColor]);

  const onLabelEdit = useCallback((itemIndex: number, currentLabel: string | undefined) => {
    setPromptDlgProps({
      title: t("change_category_name"),
      prompt: t("enter_new_category_name"),
      defaultAnswer: currentLabel,
      onCancel: () => {
        setPromptDlgProps(undefined);
      },
      onAccept: (answer) => {
        if (selectedColorLegend) {
          updateItemLabel( itemIndex, answer ) ;
        }
        setPromptDlgProps(undefined);
      },
    } as IPromptDialogProps);

  }, [selectedColorLegend, updateItemLabel]);

  const handleSave = useCallback(async () => {
    await persistsSiteConfig();
  }, [persistsSiteConfig]);

  const handleCancel = useCallback( async () => {
    await resetSiteConfig();
  }, [resetSiteConfig]);


  const maxWorkAreaHeight = useMemo(()=>{
    if (columnPaneHeight) {
      return columnPaneHeight - 3 * MARGIN_HEIGHT - TITLE_BAR_HEIGHT;
    } else {
      return undefined ;
    }
  }, [columnPaneHeight]);

  const availableAttributes = useMemo(()=>{
    return SITE_CONFIG_EDITABLE_ATTRIBUTES;//.map(a=>AttributeType[a])
  }, []);

  const onSelectedColorLegendChanged=useCallback((colorLegend) => {
    if (selectedAttribute) {
      updateColorLegendConfig( selectedAttribute, colorLegend );
    }
  }, [selectedAttribute]);

  return (
    <>
      <ContentPage>
        <ColumnPane
            className="w3-rest" style={{width: "calc(100%)"}}
            onSizeChanged={(w,h)=>setColumnPaneHeight(h)}
        >

          {/* Rounded panel */}
          <RoundedPanel>
            <div
              style={{
                maxHeight: maxWorkAreaHeight ? `${maxWorkAreaHeight}px` : undefined,
                overflowY: 'auto'
              }}
            >
            <TitledSection
                w3BorderColor={'w3-border-light-blue'}
                title={t("site_transformation")}
                onHelpClicked={()=>setHelpRequested({
                  helpTitle: t('site_transformation'),
                  helpContent: <Help_SiteConfig_BaseTransformation />,
                  width: "50vw",
                })}
            >
              {/* Translation */}
              <div className={"w3-row w3-section"}>
                <div className={"w3-cell w3-container w3-quarter"} />
                <div className={"w3-cell w3-rest"}>
                  {siteConfigLocal && (
                      <>
                        {t("Translation")}
                        <div className={"w3-theme-d1 w3-padding"}>
                          <TranslationVectorEditor
                              enabled={unlocked}
                              translation={{
                                x: siteConfigLocal.translation.x,
                                y: siteConfigLocal.translation.y,
                                z: siteConfigLocal.translation.z,
                              }}
                              onChange={onTranslationUpdated}
                          />
                        </div>
                      </>
                  )}
                </div>
              </div>
              {/* Rotation */}
              <div className={"w3-row w3-section"}>
                <div className={"w3-cell w3-container w3-quarter"} />
                <div className={"w3-cell w3-rest"}>
                  {siteConfigLocal && (
                      <>
                        {t("Rotation")}
                        <div className={"w3-theme-d1 w3-padding"}>
                          <RotationMatrixEditor
                              enabled={unlocked}
                              matrix={toMatrix3(siteConfigLocal.rotation)}
                              onChange={onRotationUpdated}
                          />
                        </div>
                      </>
                  )}
                </div>
              </div>
              <div className={"w3-row"}>
                <div className={"w3-cell w3-container w3-quarter"} />
                <div
                    className={"w3-cell w3-rest"}
                    onClick={(e) => {
                      setUnlocked(!unlocked);
                    }}
                >
                  <FontAwesomeIcon
                      className={`unselectable w3-margin-right ${
                          unlocked ? "w3-text-red" : "w3-text-orange"
                      }`}
                      icon={unlocked ? faLockOpen : faLock}
                  />
                  {t(unlocked ? "click_to_lock" : "click_to_unlock")}
                </div>
              </div>
            </TitledSection>

            <TitledSection
                className={'w3-margin-top'}
                w3BorderColor={'w3-border-light-blue'}
                title={t("legend_configuration")}
                onHelpClicked={()=>setHelpRequested({
                  helpTitle: t('legend_configuration'),
                  helpContent: <Help_SiteConfig_ColorLegend/>,
                  width: "50vw",
                })}
            >
              {/* Master-Detail view */}
              {siteConfigLocal && (
                  <MasterDetailView
                      // availableAttributes={Object.keys(ATTRIBUTE_LABELS)}
                      availableAttributes={availableAttributes}
                      selectedColorLegend={selectedColorLegend}
                      onSelectedAttributeChanged={setSelectedAttribute}
                      availableColorLegends={siteConfigLocal.legendConfigs}
                      onSelectedColorLegendChanged={onSelectedColorLegendChanged}
                      onColorBoxClicked={onColorBoxClicked}
                      onLabelEditClicked={onLabelEdit}
                      invalidColorVisibility={siteConfigLocal.showInvalidColorConfig}
                      onInvalidColorVisibilityChanged={updateInvalidColorVisibility}
                  />
              )}
            </TitledSection>
            </div>

            <div style={{position: "absolute", left: `${MARGIN_HEIGHT}px`, bottom: `${MARGIN_HEIGHT}px`}}>
              <button
                  className="w3-button w3-light-blue w3-round-medium w3-margin w3-left site-config-save"
                  onClick={handleSave}
                  disabled={!siteConfigDirty}
              >
                {t("save")}
              </button>
              <button
                  className="w3-button w3-orange w3-round-medium w3-margin w3-left site-config-undo"
                  onClick={handleCancel}
                  disabled={!siteConfigDirty}
              >
                {t("undo")}
              </button>
            </div>
          </RoundedPanel>
        </ColumnPane>
      </ContentPage>

      {colorPickerProps && (
        <ColorPickerDialog
          color={colorPickerProps.color}
          onCancel={() => {
            colorPickerProps.onCancel();
            setColorPickerProps(undefined);
          }}
          onColorSelected={(c) => {
            colorPickerProps.onColorSelected(c);
            setColorPickerProps(undefined);
          }}
        />
      )}
      {promptDlgProps && (
        <PromptDialog
          title={promptDlgProps.title}
          prompt={promptDlgProps.prompt}
          defaultAnswer={promptDlgProps.defaultAnswer}
          onCancel={() => {
            promptDlgProps.onCancel();
            setPromptDlgProps(undefined);
          }}
          onAccept={(answer) => {
            promptDlgProps.onAccept(answer);
            setPromptDlgProps(undefined);
          }}
        />
      )}
    </>
  );
};

export default withTranslation()(SiteConfigPage);

interface IMasterDetailViewProps {
  availableAttributes: AttributeType[];
  availableColorLegends: IAttributeTypeMap<IColorLegendConfiguration>;
  selectedColorLegend: IColorLegendConfiguration|undefined;
  onSelectedAttributeChanged: (attribute: AttributeType) => void;
  onSelectedColorLegendChanged: (colorLegend: IColorLegendConfiguration) => void;
  onColorBoxClicked: (
    itemIndex: number,
    updatedColor: string,
    callback: ((updatedColor: string) => void) | undefined
  ) => void;
  onLabelEditClicked: (itemIndex: number, text: string) => void;
  invalidColorVisibility: boolean;
  onInvalidColorVisibilityChanged: (isVisible: boolean) => void;
  overrideMouseIsOver?: boolean;
}

const MasterDetailView: React.FC<IMasterDetailViewProps> = (props) => {

  const [selectedAttribute, setSelectedAttribute] = useState<AttributeType>();

  const selectedColorLegend = useMemo(()=>props.selectedColorLegend, [props.selectedColorLegend]);

  const onSelectedAttributeChanged = props.onSelectedAttributeChanged ;
  const handleSelectedAttribute = useCallback((attribute: AttributeType) => {
    setSelectedAttribute(attribute);
    onSelectedAttributeChanged( attribute );
  }, [onSelectedAttributeChanged]);


  const availableAttributes = props.availableAttributes;
  useEffect(() => {
    if (availableAttributes.length > 0) {
      handleSelectedAttribute(availableAttributes[0]);
    }
  }, [availableAttributes, handleSelectedAttribute]);

  function handleInvalidColorVisibilityChanged(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    let isChecked = e.target.checked;
    props.onInvalidColorVisibilityChanged(isChecked);
  }

  return (
      <div className={"w3-row"}>
        <div id={"master"} className={"w3-cell w3-quarter w3-center"}>
          <ul className="w3-ul">
            {
              props.availableAttributes
                  .map( ( attribute ) =>(
                  <div
                      id={`site-config-attr-${attribute}`}
                      key={attribute}
                      className={`w3-left-align unselectable w3-block w3-padding ${(attribute)===selectedAttribute ? "w3-theme-d1":"w3-theme-d4"} w3-theme-d1-hover site-config-select-attr`}
                      style={{cursor: "pointer"}}
                      onClick={(e)=>handleSelectedAttribute(attribute)}
                  >
                    {t(AttributeType[attribute])}
                  </div>
              ))
            }
          </ul>
        </div>
        <div id={"detail"}
             className={"w3-cell w3-theme-d1 w3-rest w3-padding "}
        >
          {
              selectedColorLegend &&
              {
                'Ranges':
                    <ColorLegendRangesConfig
                        legendConfig={selectedColorLegend}
                        onLegendConfigChanged={props.onSelectedColorLegendChanged}
                        onColorBoxClicked={props.onColorBoxClicked}
                        onLabelEdit={()=>{}}
                        overrideMouseIsOver={props.overrideMouseIsOver}
                    />,
                'Enum':
                    <>
                      { selectedAttribute &&
                          <div className={'w3-panel w3-pale-yellow w3-border w3-border-amber w3-round'}>
                            {`${t('Warning')}: ${t(AttributeType[selectedAttribute])} ${t("is_category_attribute")}.`}
                          </div>
                      }

                      <ColorLegendEnumsConfig
                          legendConfig={selectedColorLegend}
                          onColorBoxClicked={/*isEnumColorEditable ? */props.onColorBoxClicked /*: undefined*/}
                          onLabelEdit={props.onLabelEditClicked}
                          hasInvalid={ selectedAttribute
                              ? (AttributeType[selectedAttribute].indexOf("_Domain") === -1)
                              : true
                          }
                          overrideMouseIsOver={props.overrideMouseIsOver}
                      />
                    </>,
              }[ LegendType[selectedColorLegend.legendType] ]
          }
      </div>

      { selectedAttribute && (AttributeType[selectedAttribute].indexOf("_Domain") === -1) && ( // ignore for Cluster attributes
          <div className={"w3-cell-row w3-theme-d4"}>
            <label
                htmlFor={"invalidColorVisibilityCheckbox"}
                className={"w3-cell w3-cell-middle"}
            >
              <input
                  id={"invalidColorVisibilityCheckbox"}
                  type={"checkbox"}
                  className={"w3-check w3-margin-right"}
                  checked={props.invalidColorVisibility}
                  onChange={(e) => handleInvalidColorVisibilityChanged(e)}
              />
              {t("show_invalid_color_in_legend")}
            </label>
          </div>
      )}
    </div>
  );
};

const deepCopy = ( orig: ISiteConfiguration ): ISiteConfiguration => {
  const serialized = SiteConfigurationMapper.siteConfigToSiteConfigDto(orig) ;
  return SiteConfigurationMapper.siteConfigDtoToSiteConfig( serialized );
}
