import {useCallback, useContext, useEffect, useMemo} from "react";
import {NonModalZOrderContext} from "../components/Contexts/NonModalZOrderContextProvider";

export const useZOrderContext = ( id: string ): [number, ()=>void] => {
  const [stackedIds, setStackedIds] = useContext(NonModalZOrderContext);

  const z = useMemo(()=>{
    return stackedIds.indexOf( id ) ;
  }, [stackedIds]);

  const bringToFront = useCallback(()=>{
    setStackedIds(prevState => {
      return [
          ...prevState.filter(sid=>sid!==id),
          id
      ]
    })
  }, [setStackedIds]);

  useEffect(()=>{
    bringToFront();
  }, [id]);

  return [z, bringToFront];
}
