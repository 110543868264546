import React, {useMemo} from "react";
import EntityInfobox from "./EntityInfobox";
import {withTranslation, WithTranslation} from "react-i18next";
import {t} from "i18next";
import {useUserSessionContext} from "../Contexts/UserSessionContext";

interface IProps extends WithTranslation {
  entity: any;
  className?:string;
  style?:any;
}

const TopOfBenchPlaneInfoBox: React.FC<IProps> = (props) => {
  const [userSession] = useUserSessionContext();

  const elev = useMemo(()=>{
    if ( ! userSession?.originReference || !userSession.boundingBox?.maxPos) {
      return undefined;
    }
    return userSession.originReference.z + userSession.boundingBox.maxPos.z ;
  }, [userSession?.originReference, userSession.boundingBox?.maxPos])

  return (
      <>
        {userSession?.topPlaneVisibility && userSession?.originReference && userSession.boundingBox?.maxPos && elev && (
            <EntityInfobox
                title={`${t('TopOfBenchPlane')} ${t('at')} ${elev.toFixed(1)}m`}
                className={`w3-light-blue w3-text-black w3-round ${props.className}`}
                style={props.style}
                entity={props.entity}
            />
        )}
      </>
  )
}

export default withTranslation()(TopOfBenchPlaneInfoBox);
