import {IAssetSummaryDto} from "../model/IAssetSummaryDto";
import {LayerType, toLayerTypeString} from "../../../model/LayerType";
import IAssetSummary from "../domain/IAssetSummary";

export default class AssetSummaryMapper {
  public static assetSummaryToAssetSummaryDto(assetSummary: IAssetSummary ): IAssetSummaryDto {
    return {
      id: assetSummary.id,
      attribution: assetSummary.attribution,
      description: assetSummary.description,
      downloadPath: assetSummary.downloadUri,
      start: assetSummary.start.toISOString(),
      end: assetSummary.end.toISOString(),
      identifier: assetSummary.identifier,
      layerType: LayerType[assetSummary.layerType],
      name: assetSummary.name,
      mineSite: assetSummary.mineSite,
      patternName: assetSummary.patternName,
      patternId: assetSummary.patternId,
      type: assetSummary.type,
      origin: assetSummary.origin,
      blockSize: assetSummary.blockSize ?? null
    }
  }
  public static assetSummaryDtoToAssetSummary(assetSummaryDto: IAssetSummaryDto ): IAssetSummary {
    return {
      id: assetSummaryDto.id,
      attribution: assetSummaryDto.attribution,
      description: assetSummaryDto.description,
      downloadUri: assetSummaryDto.downloadPath,
      start: new Date(assetSummaryDto.start),
      end: new Date(assetSummaryDto.end),
      identifier: assetSummaryDto.identifier,
      layerType: LayerType[assetSummaryDto.layerType as keyof typeof LayerType],
      name: assetSummaryDto.name,
      mineSite: assetSummaryDto.mineSite,
      patternName: assetSummaryDto.patternName ?? "",
      patternId: assetSummaryDto.patternId,
      type: assetSummaryDto.type,
      origin: assetSummaryDto.origin,
      blockSize: assetSummaryDto.blockSize ?? undefined
    }
  }
}
