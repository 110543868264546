import { NavigateFunction } from "react-router-dom";

const redirectIfForbidden = (
  response: Response|{ status: number, body: string },
  navigate: NavigateFunction
) => {
  if (response?.status === 403) {
    /**
     * Forbidden: The refresh token has expired
     * We redirect to the login page
     */
    navigate("/login");
    return true;
  }
  return false;
};

export default redirectIfForbidden;
