import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Nav from "./components/Nav/Nav";
import { Toaster } from "react-hot-toast";
import useLogoutOnClose from "./lib/auth/useLogoutOnClose";
import {HelpContextProvider} from "./Help/HelpContextProvider";
import {SiteDataContextProvider} from "./components/Gen2/components/Contexts/SiteDataContextProvider";
import NotificationsProvider from "./components/Gen2/Notifications/NotificationsProvider";
import {SystemInfoContextProvider} from "./components/Gen2/components/Contexts/SystemInfoContext";
import Footer from "./components/Nav/Footer";
import {ScreenRecordingContextProvider} from "./components/Gen2/components/Contexts/ScreenRecordingContextProvider";

const App: React.FC = () => {
  const navigate = useNavigate();

  useLogoutOnClose();

  useEffect(() => {
    if (!sessionStorage.getItem("auth_token")) {
      console.log("no auth token found");
      navigate("/login");
    }
  });

  if (!sessionStorage.getItem("auth_token")) {
    return null;
  }

  return (
      <SystemInfoContextProvider updateInterval={60000}>
        <NotificationsProvider>
          <HelpContextProvider>
            <SiteDataContextProvider>
              <ScreenRecordingContextProvider>
                <div
                    className="w3-theme-d5 app"
                    style={{
                      height: "100%",
                      // minHeight: "768px", minWidth: "1024px", overflow: "hidden"
                    }}
                >
                  <Nav />
                  <Outlet />
                  <Toaster position="top-center" />
                  <Footer />
                </div>
              </ScreenRecordingContextProvider>
            </SiteDataContextProvider>
          </HelpContextProvider>
        </NotificationsProvider>
      </SystemInfoContextProvider>
  );
};

export default App;

