import React, {useEffect, useState} from "react";
import {ICustomerSite} from "../../pages/CustomerSites";
import {withTranslation, WithTranslation} from "react-i18next";
import SortHeaderItem, {IColumnSort} from "../Sorting/SortHeaderItem";
import {sortObjects} from "../../lib/sort";
import SortHeader from "../Sorting/SortHeader";
import CustomerSiteRow from "./CustomerSiteRow";
import {ILoggedInUser} from "../../pages/ActiveUsers";
import LoggedInUserRow from "./LoggedInUserRow";

interface IProps extends WithTranslation {
  loggedInUsers: ILoggedInUser[];
}

const COL_WIDTHS = [33, 33, 34]

const LoggedInUsersList: React.FC<IProps> = (props) => {
  const t = props.t ;

  const [columnSort, setColumnSort] = useState<IColumnSort>({
    sortBy: "userName",
    sortOrder: "asc"
  });

  const sortedLoggedInUsers = sortObjects(props.loggedInUsers, columnSort.sortBy, columnSort.sortOrder);

  return (
      <>
        <SortHeader>
          <SortHeaderItem
              widthPercent={COL_WIDTHS[0]}
              defaultFilterprop="userName"
              filterProp="userName"
              columnSort={columnSort}
              onColumnSort={(sort=>setColumnSort(sort))}
          >
            {t("User Name")}
          </SortHeaderItem>
          <SortHeaderItem
              widthPercent={COL_WIDTHS[1]}
              defaultFilterprop="customerSiteName"
              filterProp="customerSiteName"
              columnSort={columnSort}
              onColumnSort={(sort=>setColumnSort(sort))}
          >
            {t("Customer Site")}
          </SortHeaderItem>
          <SortHeaderItem
              widthPercent={COL_WIDTHS[2]}
              defaultFilterprop="lastAccessTimeStamp"
              filterProp="lastAccessTimeStamp"
              columnSort={columnSort}
              onColumnSort={(sort=>setColumnSort(sort))}
          >
            {t("Last Access Time")}
          </SortHeaderItem>

        </SortHeader>

        <ul className="w3-ul">
          {sortedLoggedInUsers.map((loggedInUser) =>(
              <React.Fragment key={loggedInUser.id}>
                <LoggedInUserRow
                    loggedInUser={loggedInUser}
                    colWidths={COL_WIDTHS}
                />
              </React.Fragment>
          ))}
        </ul>
      </>
  )
}

export default withTranslation()(LoggedInUsersList);
