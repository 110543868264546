import React, {CSSProperties} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {ColorUtil} from "../../../../lib/ColorUtil";
import IColorLegendConfiguration from "../../domain/IColorLegendConfiguration";
import {t} from "i18next";
import {IColorLegend} from "../../hooks/useColorLegendApi";

interface Segment {
  color: string;
  text: string;
  textColor: string;
}

interface IProps extends WithTranslation {
  className?:string;
  style?: CSSProperties|undefined;
  colorLegendConfig: IColorLegendConfiguration,
  colorLegendState: IColorLegend
}

const HorizontalColorLegend: React.FC<IProps> = (props) => {
  let totalWeight: number = 0;
  let segments: Segment[] = [];

  let maxLenAttribute = 0;

  if ( props.colorLegendState && props.colorLegendConfig ) {
    totalWeight = props.colorLegendConfig.items.length;

    /** Note: we don't include either of the "invalid" or range_exceeded colors, as they're mostly irrelevant in our
     *  use-case and they take up space
     *  */

    if ( totalWeight > 0 ) {

      // ... Build the segment list
      for ( let i = 0; i < props.colorLegendConfig.items.length; i += 1 ) {
        let itemCfg = props.colorLegendConfig.items[i];
        let label = itemCfg.label ?? i.toString();
        let color:string = itemCfg.color.toCssHexString();
        segments.push( {
          color: color,
          text: t(label),
          textColor: `#${ColorUtil.contrastingColor(color ?? "#000")}`
        } as Segment );
        maxLenAttribute = Math.max(maxLenAttribute, label.length );
      }
    }
  }

  let styleWidth = props.style?.width ?? "100%";

  return (
      <>
        {
          segments.map( segment => (
              <div
                  key={segment.text}
                  className={'w3-bar-item'}
                  style={{
                    color: segment.textColor,
                    backgroundColor: segment.color,
                    width: `calc( ${styleWidth} / ${segments.length} )`
                  }}
              >
                {segment.text}
              </div>
          ))
        }
      </>
  )
}

export default withTranslation()(HorizontalColorLegend) ;
