import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import {getPerimeters, IPerimeterSetProps, PerimeterSet} from "../Widgets/PerimeterSet";
import {IAssetEntityProps} from "./IAssetEntityProps";
import {CesiumMovementEvent} from "resium";
import {LayerType} from "../../../../model/LayerType";

interface IPatternBoundaryOutlineProps extends IAssetEntityProps {
  onLeftClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onRightClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onDoubleClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseMove?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseUp?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseDown?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  isSelected?:boolean|undefined;
}

const PatternBoundaryOutlineEntity: React.FC<IPatternBoundaryOutlineProps> = (props) => {
  const [siteConfig] = useSiteConfig();
  const [userSession] = useUserSessionContext();
  const [perimeterSets, setPerimeterSets] = useState<IPerimeterSetProps[]>([]);

  useEffect(()=>{
    const perims = getPerimeters( props.tileset, siteConfig.modelMatrix );
    if (perims) {
      setPerimeterSets( perims )
      console.log(`Perimeter sets for ${props.resource.name}: ${perims.map(perim => perim.perimeters[0].length)}`);
    } else {
      throw new Error(`No perimeter info for ${props.resource.name}`);
    }

  }, [props.tileset, siteConfig.modelMatrix]);

  useEffect(()=>{
    if (perimeterSets.length > 0 && userSession.layersVisibility.Boundary ) {
      console.log(`Rendering perimeter for ${props.resource.name}`);
    }
  }, [perimeterSets, userSession.layersVisibility.Boundary]);

  const handleLeftClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onLeftClick ) {
      props.onLeftClick( movement, target );
    }
  }, []);
  const handleRightClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onRightClick ) {
      props.onRightClick( movement, target );
    }
  }, []);
  const handleDoubleClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onDoubleClick ) {
      props.onDoubleClick( movement, target );
    }
  }, []);
  const handleMouseMove = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseMove ) {
      props.onMouseMove( movement, target );
    }
  }, []);
  const handleMouseUp = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseUp ) {
      props.onMouseUp( movement, target );
    }
  }, []);
  const handleMouseDown = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseDown ) {
      props.onMouseDown( movement, target );
    }
  }, []);

  const properties = useMemo(()=>{
    return { Layer : LayerType[LayerType.Boundary] };
  }, []);

  return (
      <>
        {
            userSession.layersVisibility.Boundary && (
                perimeterSets.map( (perimeterSet, idx) => (
                    <PerimeterSet
                        key={`PerimeterSet.${idx}`}
                        {...perimeterSet}
                        properties={properties}
                        color={props.isSelected ? userSession.selectedCrossSectionStyle.outlineColor : userSession.layersColor.Boundary}
                        onLeftClick={handleLeftClick}
                        onRightClick={handleRightClick}
                        onDoubleClick={handleDoubleClick}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseDown={handleMouseDown}
                    />
                ))
            )
        }
      </>
  )
}

export default React.memo(PatternBoundaryOutlineEntity);
