import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

import TextAndImage from "./Widgets/TextAndImage";
import {VideoResourceUrl} from "../lib/VideoResourceUrl";

/**
 * View side menu help
 *
 * @param props
 * @constructor
 */

const Help_CesiumView_SideMenu_View: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <>
        <div className={'w3-panel'}>
          <h2 className={"w3-left-align"}>{t("The 'View' Side Menu")}</h2>
          <p className={"text-full-justify"}>
            {t("This section is groups viewing parameters that affect how the 3D environment is displayed.")}
          </p>
        </div>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"The Camera Frustum"} text={"Use the drop-down box to toggle the camera frustum between Perspective and Orthographic."}
                      src={VideoResourceUrl("camera_frustum.webm")}
                      imgRatio={"w3-twothird"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"View Presets"} text={"Use any of the six predefined view presets to quickly position the observer on any of the orthogonal axes, or click 'Reset View' to reset the view to it's original position."}
                      src={VideoResourceUrl("camera_presets.webm")}
                      imgRatio={"w3-twothird"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Show/Hide the Bounding Box"} text={"Click on the 'Show Bounding Box' checkbox to toggle the bounding box visibility."}
                      src={VideoResourceUrl("bounding_box.webm")}
                      imgRatio={"w3-twothird"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Show/Hide the Top Plane"} text={"Click on the 'Show Top Plane' checkbox to toggle the top plane visibility."}
                      src={VideoResourceUrl("top_plane.webm")}
                      imgRatio={"w3-twothird"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Show/Hide the XYZ Axes"} text={"Click on the 'Show XYZ Axes' checkbox to toggle the axis visibility."}
                      src={VideoResourceUrl("xyz_axes.webm")}
                      imgRatio={"w3-twothird"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Show/Hide the Bottom Toolbar"} text={"Click on the 'Show Toolbar' checkbox to toggle the visibility of the bottom toolbar."}
                      src={VideoResourceUrl("bottom_toolbar.webm")}
                      imgRatio={"w3-twothird"}/>
      </>
  )
}

export default withTranslation()(Help_CesiumView_SideMenu_View);

