import { WithTranslation, withTranslation } from "react-i18next";
import ChangePasswordForm from "../components/UserAdministration/ChangePasswordForm";
import UserSession from "../lib/userSession";
import React, {useMemo, useState} from "react";
import ContentPage from "../components/Gen2/components/BasicControls/ContentPage";
import ColumnPane from "../components/Gen2/components/BasicControls/ColumnPane";
import RoundedPanel from "../components/Gen2/components/BasicControls/RoundedPanel";
import TitledSection from "../components/Gen2/components/BasicControls/TitledSection";
import {t} from "i18next";
import {useHelp} from "../Help/HelpContextProvider";

const MARGIN_HEIGHT = 16;//px

const UserProfile: React.FC<WithTranslation> = ({ t }) => {
  const [, setHelpRequested] = useHelp();
  const [columnPaneHeight, setColumnPaneHeight] = useState<number>();

  const maxWorkAreaHeight = useMemo(()=>{
    if (columnPaneHeight) {
      return columnPaneHeight - 2 * MARGIN_HEIGHT ;
    } else {
      return undefined ;
    }
  }, [columnPaneHeight]);

  return (
      <ContentPage>
        {/* Left hand */}
        <ColumnPane
            className="w3-rest" style={{width: "calc(100%)"}}
            onSizeChanged={(w,h)=>setColumnPaneHeight(h)}
        >
          <RoundedPanel>
            <div
                style={{
                  maxHeight: maxWorkAreaHeight ? `${maxWorkAreaHeight}px` : undefined,
                  overflowY: 'auto'
                }}
            >
              <TitledSection
                  w3BorderColor={'w3-border-light-blue'}
                  title={t("User Profile")}
                  onHelpClicked={()=>setHelpRequested({
                    helpTitle: t('user_profile'),
                    helpContent: <div style={{height: "250px"}}>Placeholder</div>,
                    width: "50vw",
                  })}
              >
                <div className={''}>
                  <h3>{t("update_password")} ({UserSession.getUsername()})</h3>
                  <ChangePasswordForm className={'w3-margin-top'}/>
                </div>
              </TitledSection>
            </div>
          </RoundedPanel>
        </ColumnPane>
      </ContentPage>
  );
};

export default withTranslation()(UserProfile);
