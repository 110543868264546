import {IPatternSummaryDto} from "../model/IPatternSummaryDto";
import {fromLayerTypeString, LayerType, toLayerTypeString} from "../../../model/LayerType";
import {IPatternSummary} from "../domain/IPatternSummary";

export default class PatternMapper {
  public static patternSummaryToPatternSummaryDto( patternSummary: IPatternSummary ): IPatternSummaryDto {
    return {
      name: patternSummary.name,
      patternId: patternSummary.patternId,
      layers: patternSummary.layers.map(l=>toLayerTypeString(l) ?? `${l}` ),
      start: patternSummary.start.toISOString(),
      end: patternSummary.end.toISOString(),
      dmmRevisionTimestamp: patternSummary.dmmRevisionTimestamp?.toISOString() ?? null,
      tags: patternSummary.tags ?? []

    }
  }
  public static patternSummaryDtoToPatternSummary( patternSummaryDto: IPatternSummaryDto ): IPatternSummary {
    return {
      name: patternSummaryDto.name,
      patternId: patternSummaryDto.patternId,
      layers: patternSummaryDto.layers?.map(s=>fromLayerTypeString(s) ?? LayerType.Undefined ) ?? [],
      start: new Date(patternSummaryDto.start),
      end: new Date(patternSummaryDto.end),
      dmmRevisionTimestamp: patternSummaryDto.dmmRevisionTimestamp ? new Date(patternSummaryDto.dmmRevisionTimestamp) : undefined,
      tags: patternSummaryDto.tags ?? []
    }
  }
}
