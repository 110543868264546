import React from "react";
import {t} from "i18next";
import {faCircleQuestion} from "@fortawesome/free-solid-svg-icons";
import NavMenu from "../../../Nav/NavMenu";
import {withTranslation, WithTranslation} from "react-i18next";
import {useHelp} from "../../../../Help/HelpContextProvider";
import Help_Navigator from "../../../../Help/Help_Navigator";
import {help_GettingStartedTopics} from "../../../../Help/Help_GettingStartedTopics";
import {AppConstants} from "../../../../AppConstants";
import Help_EULA from "../../../../Help/Help_EULA";

interface IProps extends WithTranslation {
  label?: string;
  theme?: string;
  side?: "left" | "right";
  dropDownItemsStyle?:any;
}

const apiEndpoint =
    (window
        ? (window as any).env?.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT) || "http://localhost:8080";

const HelpMenu: React.FC<IProps> = (props) => {
  const [, setHelpRequested] = useHelp();

  const handleOnAbout = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setHelpRequested({
      helpTitle: t('About this Application'),
      helpContent:
          <div className={"w3-left-align"} >
            <b className={'w3-medium'}>{t(AppConstants.About.appName)}</b>
            <div className={'w3-small'}>Version: {AppConstants.About.appVersion}</div>
            <div className={'w3-small'}>Copyright &copy; {AppConstants.About.AppCopyright.startDate}-{new Date().getFullYear()} {AppConstants.About.appVendor}</div>
            <div></div>
          </div>,
      width: "480px"
    });
  }
  const handleOnEULA = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    const url = window.location.href ;
    const off = url.indexOf( window.location.pathname );
    const urlEULA = `${url.substring(0, off)}/PT_GMV_EULA_2023.html`;

    setHelpRequested({
      helpTitle: t('EULA'),
      helpContent:
          <Help_EULA
              className={'w3-text-light-blue'}
              style={{ width: "100%", height: "768px"}}
          />
      ,
      width: "768px"
    });
  }
  const handleOnGettingStarted = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setHelpRequested({
      helpTitle: t('Getting started'),
      helpContent: <Help_Navigator topics={help_GettingStartedTopics}/>
    });
  }

  return (
      <NavMenu title={t(props?.label??'')} className={`w3-${props.side ?? "right"}`} icon={faCircleQuestion} theme={props.theme} dropDownItemsStyle={props.dropDownItemsStyle}>
        <div
            className={'w3-bar-item w3-button'}
            onClick={handleOnAbout}
        >
          {t('About this Application')}
        </div>
        <div
            className={'w3-bar-item w3-button'}
            onClick={handleOnEULA}
        >
          {t('End User License Agreement (EULA)')}
        </div>
        <div
            className={'w3-bar-item w3-button'}
            onClick={handleOnGettingStarted}
        >
          {t('Getting started')}
        </div>
      </NavMenu>
  )
}

export default withTranslation()(HelpMenu);
