export default interface ICollarDisplayTypes<T> {
    Disk: T;
    Sphere: T;
}

export const COLLAR_DISPLAY_TYPES: ICollarDisplayTypes<string> = {
    Disk: "Disk",
    Sphere: "Sphere",
}

export const fromCollarDisplayTypeString = ( str: string ): keyof ICollarDisplayTypes<string> => {
    let result ;
    Object.keys(COLLAR_DISPLAY_TYPES).forEach( key => {
        const k = key as keyof ICollarDisplayTypes<string>;
        const v = COLLAR_DISPLAY_TYPES[k];
        if ( str === v ) {
            result = k;
        }
    });
    if ( !result ) {
        throw new Error(`fromCollarDisplayTypeString: ${str} is not a valid key`);
    }
    return result ;
}
