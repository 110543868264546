import {Color} from "cesium";

export interface IStyle {
  visibility:  boolean;
  opacity: number;
  color: Color;
}

export const DEFAULT_NEUTRAL_COLOR = Color.GREY
export const DEFAULT_STYLE: IStyle = {visibility: true, opacity: 1.0, color: DEFAULT_NEUTRAL_COLOR };
