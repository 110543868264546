import {Cesium3DTileFeature, Cesium3DTileset} from "cesium";
import {TilesetUtils} from "../util/TilesetUtils";

export class FeatureIndex {
  private _featureIndex: Array<Array<Array<Cesium3DTileFeature>>>;

  /**
   * Prepopulates the feature index with a tileset's features
   * @param tileset
   */
  constructor(tileset: Cesium3DTileset) {
    this._featureIndex = new Array<Array<Array<Cesium3DTileFeature>>>();

    TilesetUtils.visitFeatures( tileset, feature => {
      const I = feature.getProperty("I");
      const J = feature.getProperty("J");
      const K = feature.getProperty("K");
      if (!this._featureIndex[I]) {
        this._featureIndex[I] = [];
      }
      if (!this._featureIndex[I][J]) {
        this._featureIndex[I][J] = [];
      }
      this._featureIndex[I][J][K] = feature;
    } ) ;

    // const root = tileset.root;
    //
    // if (root && root.content) {
    //   for (let i = 0; i < root.content.featuresLength; ++i) {
    //     let feature = root.content.getFeature(i);
    //     const I = feature.getProperty("I");
    //     const J = feature.getProperty("J");
    //     const K = feature.getProperty("K");
    //     if (!this._featureIndex[I]) {
    //       this._featureIndex[I] = [];
    //     }
    //     if (!this._featureIndex[I][J]) {
    //       this._featureIndex[I][J] = [];
    //     }
    //     this._featureIndex[I][J][K] = feature;
    //   }
    // }
  }

  /**
   * Iterates over all features and invokes user-supplied callback
   * @param callback
   */
  forEach( callback: (i:number,j:number,k:number, f: Cesium3DTileFeature)=>void) {
    this._featureIndex.forEach((val1, i) => {
      val1.forEach((val2, j) => {
        val2.forEach((feature, k) => {
          callback(i, j, k, feature);
        });
      });
    });
  }

  /**
   * Returns the feature indexed at a given position, if one exists
   * @param i
   * @param j
   * @param k
   */
  getFeatureAt( i: number, j: number, k: number ): Cesium3DTileFeature|undefined {
    if ( !this._featureIndex[i] ) return undefined;
    if ( !this._featureIndex[i][j] ) return undefined;
    return this._featureIndex[i][j][k];
  }

  /**
   * Iterates in X over the features intersecting the cross-section plane at the given offset.
   * @param offset
   * @param d the delta by which we divide the offset to get an index
   * @param callback
   */
  forEachAtOffsetX( offset: number, d: number, callback: (j:number,k:number, f: Cesium3DTileFeature)=>void) {

    const i = offset / d ;

    if (this._featureIndex[i]) {
      this._featureIndex[i].forEach((val2, j) => {
        val2.forEach((feature, k) => {
          callback(j, k, feature);
        });
      });
    }
  }

  /**
   * Iterates in Yover the features intersecting the cross-section plane at the given offset.
   * @param offset
   * @param d the delta by which we divide the offset to get an index
   * @param callback
   */
  forEachAtOffsetY( offset: number, d: number, callback: (i:number,k:number, f: Cesium3DTileFeature)=>void) {

    const j = offset / d ;

    this._featureIndex.forEach((val1, i) => {
      if (this._featureIndex[i][j]) {
        this._featureIndex[i][j].forEach((feature, k) => {
          callback(i, k, feature);
        });
      }
    });
  }

  /**
   * Iterates in Z over the features intersecting the cross-section plane at the given offset.
   * @param offset
   * @param d the delta by which we divide the offset to get an index
   * @param callback
   */
  forEachAtOffsetZ( offset: number, d: number, callback: (i:number,j:number, f: Cesium3DTileFeature)=>void) {

    const k = offset / d ;

    this._featureIndex.forEach((val1, i) => {
      val1.forEach((val2, j) => {
        this._featureIndex[i][j][k] && callback(i, j, this._featureIndex[i][j][k]);
      });
    });
  }
}