import React, {useCallback, useMemo, useState} from "react";
import ICollarDisplayType, {COLLAR_DISPLAY_TYPES, fromCollarDisplayTypeString} from "../../model/CollarDisplayType";
import ICollarDisplayTypes from "../../model/CollarDisplayType";
import {WithTranslation, withTranslation} from "react-i18next";
import {t} from "i18next";
import ButtonFooter from "../Gen2/components/BasicControls/ButtonFooter";
import { CollarAppearance } from "../Gen2/domain/ICollarStyle";
import {DEFAULT_USER_SESSION} from "../Gen2/components/Contexts/UserSessionContext";

export interface ICollarLayerSettings {
    collarDiameter: number;
    collarDisplayType: keyof ICollarDisplayTypes<string>;
}

interface ICollarLayerSettingsDialogProps extends WithTranslation {
    settings: ICollarLayerSettings;
    onAccept: (change: ICollarLayerSettings) => void;
    onCancel: () => void;
}

const BORDER_RADIUS = "5px";




const CollarLayerSettingsDialog: React.FC<ICollarLayerSettingsDialogProps> =
({
    settings,
    onAccept,
    onCancel
}) => {

    const [collarDiameter, setCollarDiameter] = useState<number>(settings.collarDiameter) ;
    const [collarDisplayType, setCollarDisplayType] = useState<keyof ICollarDisplayType<string>>(settings.collarDisplayType) ;

    const canBeReset = useMemo(()=>{
        if ( collarDiameter !== DEFAULT_USER_SESSION.collarStyle.collarDiameter ) {
            return true ;
        }
        return CollarAppearance[collarDisplayType] !== DEFAULT_USER_SESSION.collarStyle.collarAppearance;

    }, [collarDiameter, collarDisplayType]);

    const doReset = useCallback(()=>{
        setCollarDiameter( DEFAULT_USER_SESSION.collarStyle.collarDiameter );
        setCollarDisplayType( CollarAppearance[ DEFAULT_USER_SESSION.collarStyle.collarAppearance ] as keyof ICollarDisplayType<string> );
    }, []);

    return (
        <>
            <div className="w3-modal" style={{display: "block"}}>
                <div className="w3-modal-content w3-card-4 w3-theme-d4" style={{borderRadius: BORDER_RADIUS}}>
                    <header className="w3-container w3-light-blue w3-center" style={{borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0px 0px`}}>
                        <h6>{t("blast_hole_collar_settings")}</h6>
                    </header>

                    <table className="w3-table">
                        <tbody className="w3-container w3-padding">
                        <tr className={"w3-padding"}>
                            <td className={"w3-center"}>
                                {t("hole_collar_appearance")}
                            </td>
                            <td>
                                <select id="selectAttribute"
                                        className="w3-select w3-theme-d4 w3-center w3-border w3-border-grey w3-round-medium"
                                        name="option"
                                        style={{ fontSize: "12px", cursor: "pointer" }}
                                        value={COLLAR_DISPLAY_TYPES[collarDisplayType]}
                                        onChange={(e) => {
                                            let key = fromCollarDisplayTypeString( e.target.value );
                                            console.log("key="+key) ;
                                            setCollarDisplayType( key );
                                        }}>
                                    {Object.values(COLLAR_DISPLAY_TYPES).map((attr) => (
                                        <option key={attr} value={attr}>{t(attr)}</option>
                                    ))}
                                </select>
                            </td>
                            <td/>
                        </tr>
                        <tr className={"w3-padding"}>
                            <td className={"w3-center"}>
                                {t("hole_collar_diameter")}
                            </td>
                            <td className={"w3-row"}>
                                <input style={{width: "100%"}}
                                       type="range"
                                       min="0.15"
                                       max="5"
                                       step="0.01"
                                       value={collarDiameter}
                                       onChange={(e) => {
                                           setCollarDiameter( +e.target.value );
                                       }}
                                />
                            </td>
                            <td>
                                <div
                                    className={"w3-container w3-cell unselectable"}
                                    style={{fontSize: "12px", width: "100px"}}
                                >
                                    <div>({collarDiameter.toFixed(2)}m)</div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <ButtonFooter
                        borderRadius={BORDER_RADIUS}
                        onAccept={()=>onAccept({collarDiameter: collarDiameter, collarDisplayType: collarDisplayType})}
                        onCancel={onCancel}
                        onReset={canBeReset? (()=>doReset()):undefined}
                    />
                    {/*<footer className="w3-container w3-theme-d3 w3-padding" style={{borderRadius: `0px 0px ${BORDER_RADIUS} ${BORDER_RADIUS}`}}>*/}
                    {/*    <button*/}
                    {/*        className="w3-btn w3-left w3-grey w3-round-medium"*/}
                    {/*        onClick={onCancel}*/}
                    {/*    >*/}
                    {/*        {t("cancel")}*/}
                    {/*    </button>*/}
                    {/*    <button*/}
                    {/*        className="w3-btn w3-right w3-light-blue w3-round-medium"*/}
                    {/*        onClick={()=>onAccept({collarDiameter: collarDiameter, collarDisplayType: collarDisplayType}) }*/}
                    {/*    >*/}
                    {/*        {t("accept")}*/}
                    {/*    </button>*/}
                    {/*</footer>*/}

                </div>
            </div>
        </>
    );
};

export default withTranslation()(CollarLayerSettingsDialog);