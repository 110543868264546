import {BoundingSphere, Cartesian3, Cesium3DTileFeature, Cesium3DTileset, Matrix4} from "cesium";
import IFeatureReference from "../model/IFeatureReference";
import IAssetSummary from "./IAssetSummary";
import {IBoundingBox} from "./IBoundingBox";
import {IndexPlane} from "../util/IndexPlane";
import IVector3 from "../../../model/IVector3";
import {Predicate} from "../util/Predicate";
import {TilesetUtils} from "../util/TilesetUtils";
import {IBlockModelDto} from "../model/IBlockModelDto";
import BlockModelMapper from "../mappers/BlockModelMapper";


export class CompositeFeatureIndex {

  private _originReference: Cartesian3 ;
  private _boundingBox: IBoundingBox;
  private _blockSize: Cartesian3;
  private _modelMatrix: Matrix4;
  private _gridOriginError: Cartesian3|undefined;

  /*
  Everything is indexed by [I, J, K], which are block offsets from the origin reference, which means some indices are
  negative
   */
  private _xPlanes: Map<number, IndexPlane<IFeatureReference>>;
  private _yPlanes: Map<number, IndexPlane<IFeatureReference>>;
  private _zPlanes: Map<number, IndexPlane<IFeatureReference>>;

  /**
   * Prepopulates the feature index with a tileset's features
   * @param originReference
   * @param boundingBox
   * @param blockSize
   * @param modelMatrix
   */

  constructor(originReference: Cartesian3, boundingBox: IBoundingBox, blockSize: Cartesian3, modelMatrix: Matrix4) {
    this._originReference = originReference;
    this._boundingBox = boundingBox;
    this._blockSize = blockSize;
    this._modelMatrix = modelMatrix;

    // console.log(`CompositeFeatureIndex: modelMatrix=${this._modelMatrix}`);
    // console.log(`useBlockModelIndexV2: CompositeFeatureIndex(${originReference}, ${JSON.stringify(boundingBox)}, ${blockSize}, ${modelMatrix})`);

    this._xPlanes = new Map<number, IndexPlane<IFeatureReference>>();
    this._yPlanes = new Map<number, IndexPlane<IFeatureReference>>();
    this._zPlanes = new Map<number, IndexPlane<IFeatureReference>>();
  }

  /**
   * Resets the block index to empty, with new defaults for the origin, bounding box, block size and model matrix.
   * @param originReference
   * @param boundingBox
   * @param blockSize
   * @param modelMatrix
   */
  reset(originReference: Cartesian3, boundingBox: IBoundingBox, blockSize: Cartesian3, modelMatrix: Matrix4) {
    this._originReference = originReference;
    this._boundingBox = boundingBox;
    this._blockSize = blockSize;
    this._modelMatrix = modelMatrix;

    // console.log(`CompositeFeatureIndex.reset: modelMatrix=${this._modelMatrix}`);

    this._xPlanes.clear();
    this._yPlanes.clear();
    this._zPlanes.clear();
  }

  /**
   * Returns the grid origin error, which is the difference between the block grid origin, and the tileset's origin
   * position.
   */
  get gridOriginError(): Cartesian3 | undefined {
    return this._gridOriginError;
  }

  /**
   * Returns the block plane at X == i, as a stream
   * @param i
   */
  public getPlaneAsStreamAtI( i: number ): IterableIterator<IFeatureReference>|undefined {
    return this.getPlaneAtI( i )?.stream() ?? undefined;
  }
  /**
   * Returns the block plane at Y == j, as a stream
   * @param j
   */
  public getPlaneAsStreamAtJ( j: number ): IterableIterator<IFeatureReference>|undefined {
    return this.getPlaneAtJ( j )?.stream() ?? undefined;
  }
  /**
   * Returns the block plane at Z == k, as a stream
   * @param k
   */
  public getPlaneAsStreamAtK( k: number ): IterableIterator<IFeatureReference>|undefined {
    return this.getPlaneAtK( k )?.stream() ?? undefined;
  }

  /**
   * Inserts all features in a tileset into this block index
   * @param assetSummary
   * @param tileset
   */
  public addBlockFeatures( assetSummary: IAssetSummary, tileset: Cesium3DTileset ) {
    // console.log(`=> ADDING BLOCK FEATURE FROM: ${assetSummary.name} - tileset.ready=${tileset.ready}`)

    TilesetUtils.visitFeatures( tileset, feature => {
      this.addBlockFeature( assetSummary, tileset, feature );
    }) ;

    // const root = tileset.root;
    // if (root && root.content) {
    //   for (let i = 0; i < root.content.featuresLength; ++i) {
    //     let feature = root.content.getFeature(i);
    //     this.addBlockFeature( assetSummary, tileset, feature );
    //   }
    // }
  }

  /**
   * Inserts an individual block feature into this index
   * @param assetSummary
   * @param tileset
   * @param blockFeature
   */
  public addBlockFeature( assetSummary: IAssetSummary, tileset: Cesium3DTileset, blockFeature: Cesium3DTileFeature ) {

    // ... Extract block volume index offsets
    const offsetIJK = blockFeature.getProperty("OffsetIJK") ;

    // ... Extract block volume position offsets
    const offsetXYZ = blockFeature.getProperty("OffsetXYZ") ;

    // *** NOTE: The origin is NOT the origin of the block grid; it is an average center that is computed by the
    // Geo-modeling engine. Consequently, to compute our min/max, we'll need to take note of the error between
    // the block grid origin and the tileset relative origin.
    if ( !this._gridOriginError ) {
      // ... Compute the origin offset (done only once)
      this._gridOriginError = new Cartesian3(
        offsetXYZ[0] - offsetIJK[0] * this._blockSize.x,
        offsetXYZ[1] - offsetIJK[1] * this._blockSize.y,
        offsetXYZ[2] - offsetIJK[2] * this._blockSize.z,
      );
      // console.log("+++ _gridOriginError=" + JSON.stringify(this._gridOriginError));
    }

    // ... Compute absolute position (in mine-local coordinates)
    const posECEF = new Cartesian3(
        this._originReference.x + offsetXYZ[0],// - this._gridOriginError.x,
        this._originReference.y + offsetXYZ[1],// - this._gridOriginError.y,
        this._originReference.z + offsetXYZ[2],// - this._gridOriginError.z
    );

    // .... Compute absolute world position (on the globe)
    const posAbsolute = TilesetUtils.localToWorld( posECEF, this._modelMatrix )

    // console.log(`posECEF=${posECEF} => posAbsolute=${posAbsolute} (originRef=${this._originReference})`);

    // ... Define the feature's metadata that will be inserted into the indexes
    const featureRef: IFeatureReference = {
      assetSummary: assetSummary,
      feature: blockFeature,
      tileset: tileset,
      position: posAbsolute,
      I: +offsetIJK[0],
      J: +offsetIJK[1],
      K: +offsetIJK[2]
    } ;

    // ... Add the metadata to all indexes
    this.getOrCreateXPlane( offsetIJK[0] ).add( offsetIJK[1], offsetIJK[2], featureRef );
    this.getOrCreateYPlane( offsetIJK[1] ).add( offsetIJK[0], offsetIJK[2], featureRef );
    this.getOrCreateZPlane( offsetIJK[2] ).add( offsetIJK[0], offsetIJK[1], featureRef );
  }

  /**
   * Computes the bounding sphere for the current bounding box
   * @private
   */
  public computeBoundingSphere(): BoundingSphere {
    return CompositeFeatureIndex.computeBoundingSphere(this._boundingBox.minPos, this._boundingBox.maxPos, this._modelMatrix);
  }

  /**
   * Visits each feature in the block model
   * @param visitor
   */
  public getBlockModelDto(): IBlockModelDto {
    let result: IBlockModelDto = {
      originReference: [ this._originReference.x, this._originReference.y, this._originReference.z ],
      blockSize: [ this._blockSize.x, this._blockSize.y, this._blockSize.z ],
      features: []
    }

    this._xPlanes.forEach((indexPlane, key)=> {
      indexPlane.forEach((row, col, featureRef)=> {
        result.features.push( BlockModelMapper.featureToBlockModelFeatureDto( featureRef ) );
      })
    })

    return result ;
  }

  /**
   * Returns the minimal bounding sphere that wraps features that satisfy a given predicate
   * @param predicate
   */
  public getBoundingBoxWhere( predicate: Predicate<IFeatureReference> ): BoundingSphere {

    let minPos : Cartesian3|undefined = undefined ;
    let maxPos : Cartesian3|undefined = undefined ;

    this._xPlanes.forEach((indexPlane, key)=> {
      indexPlane.forEach((row, col, featureRef)=> {
        if ( predicate.test(featureRef) ) {
          const offset = Cartesian3.fromArray( featureRef.feature.getProperty("OffsetXYZ") as number[] ) ;

          if ( minPos ) {
            minPos.x = Math.min( minPos.x, offset.x );
            minPos.y = Math.min( minPos.y, offset.y );
            minPos.z = Math.min( minPos.z, offset.z );
          } else {
            minPos = new Cartesian3(offset.x, offset.y, offset.z);
          }
          if ( maxPos ) {
            maxPos.x = Math.max( maxPos.x, offset.x );
            maxPos.y = Math.max( maxPos.y, offset.y );
            maxPos.z = Math.max( maxPos.z, offset.z );
          } else {
            maxPos = new Cartesian3(offset.x, offset.y, offset.z);
          }
        }
      })
    })

    // ... return the bounding sphere
    if (minPos && maxPos ) {
      return CompositeFeatureIndex.computeBoundingSphere(minPos, maxPos, this._modelMatrix);
    } else {
      return this.computeBoundingSphere();
    }
  }

  /**
   * Computes the bounding sphere for the current bounding box, defined by the given min/max positions
   * @private
   */
  private static computeBoundingSphere(minPos: Cartesian3, maxPos: Cartesian3, modelMatrix?: Matrix4 ): BoundingSphere {
    let center = new Cartesian3(
        (minPos.x + maxPos.x) / 2,
        (minPos.y + maxPos.y) / 2,
        (minPos.z + maxPos.z) / 2
    )
    const radius = Cartesian3.distance(minPos, maxPos) / 2 ;

    if ( modelMatrix ) {
      center = TilesetUtils.localToWorld( center, modelMatrix );
    }

    // console.log(`computeBoundingSphere[${minPos}, ${maxPos}] => center=${center}`);

    return new BoundingSphere(center, radius);
  }

  // /**
  //  * Finds the first plane in X that satisfies a given predicate, if one exists
  //  * @param predicate
  //  * @param order
  //  * @param minIndex
  //  * @param maxIndex
  //  */
  // private findPlaneIndexX( predicate: Predicate<IFeatureReference>, order: string, minIndex?: number, maxIndex?: number ): number|undefined {
  //   if ( order === "asc" ) {
  //     const start = minIndex ?? this._boundingBox.minIdx.x;
  //     const end = maxIndex ?? this._boundingBox.maxIdx.x;
  //
  //     for ( let i = start; i <= end; i += 1 ) {
  //       let plane = this._xPlanes.get( i );
  //       if ( !plane ) continue ;
  //       if ( plane.contains( predicate ) ) {
  //         return i ;
  //       }
  //     }
  //   }
  //   else if ( order === "desc" ) {
  //     const start = maxIndex ?? this._boundingBox.maxIdx.x;
  //     const end = minIndex ?? this._boundingBox.minIdx.x;
  //
  //     for ( let i = start; i >= end; i -= 1 ) {
  //       let plane = this._xPlanes.get( i );
  //       if ( !plane ) continue ;
  //       if ( plane.contains( predicate ) ) {
  //         return i ;
  //       }
  //     }
  //   }
  //   else {
  //     throw new Error("findPlaneIndexX: invalid argument 'order'") ;
  //   }
  //   return undefined ;
  // }
  //
  // /**
  //  * Finds the first plane in Y that satisfies a given predicate, if one exists
  //  * @param predicate
  //  * @param order
  //  * @param minIndex
  //  * @param maxIndex
  //  */
  // private findPlaneIndexY( predicate: Predicate<IFeatureReference>, order: string, minIndex?: number, maxIndex?: number ): number|undefined {
  //   if ( order === "asc" ) {
  //     const start = minIndex ?? this._boundingBox.minIdx.y;
  //     const end = maxIndex ?? this._boundingBox.maxIdx.y;
  //
  //     for ( let i = start; i <= end; i += 1 ) {
  //       let plane = this._yPlanes.get( i );
  //       if ( !plane ) continue ;
  //       if ( plane.contains( predicate ) ) {
  //         return i ;
  //       }
  //     }
  //   }
  //   else if ( order === "desc" ) {
  //     const start = maxIndex ?? this._boundingBox.maxIdx.y;
  //     const end = minIndex ?? this._boundingBox.minIdx.y;
  //
  //     for ( let i = start; i >= end; i -= 1 ) {
  //       let plane = this._yPlanes.get( i );
  //       if ( !plane ) continue ;
  //       if ( plane.contains( predicate ) ) {
  //         return i ;
  //       }
  //     }
  //   }
  //   else {
  //     throw new Error("findPlaneIndexX: invalid argument 'order'") ;
  //   }
  //   return undefined ;
  // }
  //
  // /**
  //  * Finds the first plane in Z that satisfies a given predicate, if one exists
  //  * @param predicate
  //  * @param order
  //  * @param minIndex
  //  * @param maxIndex
  //  */
  // private findPlaneIndexZ( predicate: Predicate<IFeatureReference>, order: string, minIndex?: number, maxIndex?: number ): number|undefined {
  //   if ( order === "asc" ) {
  //     const start = minIndex ?? this._boundingBox.minIdx.z;
  //     const end = maxIndex ?? this._boundingBox.maxIdx.z;
  //
  //     for ( let i = start; i <= end; i += 1 ) {
  //       let plane = this._zPlanes.get( i );
  //       if ( !plane ) continue ;
  //       if ( plane.contains( predicate ) ) {
  //         return i ;
  //       }
  //     }
  //   }
  //   else if ( order === "desc" ) {
  //     const start = maxIndex ?? this._boundingBox.maxIdx.z;
  //     const end = minIndex ?? this._boundingBox.minIdx.z;
  //
  //     for ( let i = start; i >= end; i -= 1 ) {
  //       let plane = this._zPlanes.get( i );
  //       if ( !plane ) continue ;
  //       if ( plane.contains( predicate ) ) {
  //         return i ;
  //       }
  //     }
  //   }
  //   else {
  //     throw new Error("findPlaneIndexX: invalid argument 'order'") ;
  //   }
  //   return undefined ;
  // }

  /**
   * Returns the block plane at X == i
   * @param i
   * @private
   */
  private getPlaneAtI( i: number ): IndexPlane<IFeatureReference>|undefined {
    return this._xPlanes.get( i );
  }
  /**
   * Returns the block plane at Y == j
   * @private
   * @param j
   */
  private getPlaneAtJ( j: number ): IndexPlane<IFeatureReference>|undefined {
    return this._yPlanes.get( j );
  }
  /**
   * Returns the block plane at Z == k
   * @private
   * @param k
   */
  private getPlaneAtK( k: number ): IndexPlane<IFeatureReference>|undefined {
    return this._zPlanes.get( k );
  }

  /**
   * Returns the block plane at X == idx, oit creates it if it does not exist
   * @param idx
   * @private
   */
  private getOrCreateXPlane( idx: number ): IndexPlane<IFeatureReference> {
    let result = this._xPlanes.get( idx );
    if ( result ) {
      return result ;
    }
    const numBlocksY = Math.ceil((this._boundingBox.maxPos.y - this._boundingBox.minPos.y) / this._blockSize.y);
    const numBlocksZ = Math.ceil((this._boundingBox.maxPos.z - this._boundingBox.minPos.z) / this._blockSize.z);
    result = new IndexPlane<IFeatureReference>( numBlocksY, numBlocksZ );
    this._xPlanes.set( idx, result );
    return result ;
  }
  /**
   * Returns the block plane at Y == idx, oit creates it if it does not exist
   * @param idx
   * @private
   */
  private getOrCreateYPlane( idx: number ): IndexPlane<IFeatureReference> {
    let result = this._yPlanes.get( idx );
    if ( result ) {
      return result ;
    }
    const numBlocksX = Math.ceil((this._boundingBox.maxPos.x - this._boundingBox.minPos.x) / this._blockSize.x);
    const numBlocksZ = Math.ceil((this._boundingBox.maxPos.z - this._boundingBox.minPos.z) / this._blockSize.z);
    result = new IndexPlane<IFeatureReference>( numBlocksX, numBlocksZ );
    this._yPlanes.set( idx, result );
    return result ;
  }
  /**
   * Returns the block plane at Z == idx, oit creates it if it does not exist
   * @param idx
   * @private
   */
  private getOrCreateZPlane( idx: number ): IndexPlane<IFeatureReference> {
    let result = this._zPlanes.get( idx );
    if ( result ) {
      return result ;
    }
    const numBlocksX = Math.ceil((this._boundingBox.maxPos.x - this._boundingBox.minPos.x) / this._blockSize.x);
    const numBlocksY = Math.ceil((this._boundingBox.maxPos.y - this._boundingBox.minPos.y) / this._blockSize.y);
    result = new IndexPlane<IFeatureReference>( numBlocksX, numBlocksY );
    this._zPlanes.set( idx, result );
    return result ;
  }
}