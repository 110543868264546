interface Props {
  widthPercent: number;
}

const EmptySortHeaderItem: React.FC<Props> = ({ widthPercent }) => {
  return (
    <div className="w3-cell" style={{ width: `${widthPercent}%` }}>
      {/* Space for check box */}
    </div>
  );
};

export default EmptySortHeaderItem;
