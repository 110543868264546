import {withTranslation, WithTranslation} from "react-i18next";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import CollapsibleSideMenuContainer from "../BasicControls/CollapsibleSideMenuContainer";
import CollapsibleSideMenuSection from "../BasicControls/CollapsibleSideMenuSection";
import {t} from "i18next";
import {
  faArrowsToEye,
  faBinoculars,
  faChessBoard,
  faFilter,
  faLayerGroup,
  faRotateLeft,
  faSliders
} from "@fortawesome/free-solid-svg-icons";
import CameraProjectionSideMenuItem from "./CameraProjectionSideMenuItem";
import {HorizontalSeparator} from "../BasicControls/HorizontalSeparator";
import CameraPresetsSideMenuItem from "./CameraPresetsSideMenuItem";
import LabelIconButton from "../BasicControls/LabelIconButton";
import CheckboxLabelDotMenu from "../BasicControls/CheckboxLabelDotMenu";
import PatternsFilterTable from "./PatternsFilterTable";
import ConfirmationDialog, {IConfirmationDialogProps} from "../../../Dialogs/ConfirmationDialog";
import LayersFilterTable from "./LayersFilterTable";
import {SubMenuButtonState} from "../../../SideMenuItems/SubMenuButton";
import AttributeSelector from "../BasicControls/AttributeSelector";
import ColorLegendTable from "./ColorLegendTable";
import SideMenuRow from "../../../SideMenuItems/SideMenuRow";
import FlipSwitch from "../BasicControls/FlipSwitch";
import ColorBox from "../BasicControls/ColorBox";
import {getHeadingPitchRange} from "../../hooks/useCameraControls";
import BoundingBoxOptionsDialog from "../../../Dialogs/BoundingBoxOptionsDialog";
import {LayerType} from "../../../../model/LayerType";
import {CollarAppearance} from "../../domain/ICollarStyle";
import ICollarDisplayTypes from "../../../../model/CollarDisplayType";
import PatternBoundaryOptionsDialog from "../../../Dialogs/PatternBoundaryOptionsDialog";
import CollarLayerSettingsDialog, {ICollarLayerSettings} from "../../../Dialogs/CollarLayerSettingsDialog";
import BlastholeFracturesLayerSettingsDialog, {
  IBlastholeFracturesLayerSettings
} from "../../../Dialogs/BlastholeFracturesLayerSettingsDialog";
import ColorPickerDialog, {IColorPickerDialogProps} from "../../../Dialogs/ColorPickerDialog";
import {useColorLegendVisibilityContext} from "../../hooks/useFloatingColorLegendVisibilityContext";
import Help_CesiumView_SideMenu_View from "../../../../Help/Help_CesiumView_SideMenu_View";
import Help_CesiumView_SideMenu_Filters from "../../../../Help/Help_CesiumView_SideMenu_Filters";
import Help_CesiumView_SideMenu_Layers from "../../../../Help/Help_CesiumView_SideMenu_Layers";
import Help_CesiumView_SideMenu_Legend from "../../../../Help/Help_CesiumView_SideMenu_Legend";
import Help_CesiumView_SideMenu_SectionViewer from "../../../../Help/Help_CesiumView_SideMenu_SectionViewer";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import {HeadingPitchRange} from "cesium";
import NotificationDialog, {INotificationDialogProps} from "../../../Dialogs/NotificationDialog";
import {ICameraVantagePoint} from "./ICameraVantagePoint";
import Slider from "../BasicControls/Slider";
import HoleLabelSelector from "../BasicControls/HoleLabelSelector";
import {AppConstants} from "../../../../AppConstants";
import {ISecondarySegmentOptions} from "../../domain/ISecondarySegmentOptions";
import SecondarySegmentLayerSettingsDialog from "../../../Dialogs/SecondarySegmentLayerSettingsDialog";
import {ColorLegendFlags} from "../Contexts/FloatingColorLegendVisibilityContextProvider";

const CS_SECTION_COL_WIDTHS = ["80%", "20%"];

interface IProps extends WithTranslation {
  onCameraVantagePointSet: (cameraVantagePoint: ICameraVantagePoint)=>void;
}

const SideMenu: React.FC<IProps> = (props)=>{
  const [showBoundingBoxOptions, setShowBoundingBoxOptions] = useState<boolean>();
  const [confirmationDialogOptions, setConfirmationDialogOptions] = useState<IConfirmationDialogProps|undefined>();
  const [notificationDialogOptions, setNotificationDialogOptions] = useState<INotificationDialogProps|undefined>();
  const [showPatternBoundaryOptions, setShowPatternBoundaryOptions] = useState<boolean>();
  const [collarLayerSettings, setCollarLayerSettings] = useState<ICollarLayerSettings>();
  const [blastholeFracturesSettings, setBlastholeFracturesSettings] = useState<IBlastholeFracturesLayerSettings>();
  // const [, setFloatingColorLegendVisibility] = useColorLegendVisibilityContext();
  const [colorLegendVisibilityFlags, setColorLegendVisibilityFlags] = useColorLegendVisibilityContext();
  const [userSession, userSessionActions] = useUserSessionContext();
  const [blastholeLayerOptions, setBlastholeLayerOptions] = useState<ISecondarySegmentOptions>();

  // const camera3D = sessionState.camera3D;

  const assetLayers = useMemo(()=>{
    let result = new Set<LayerType>();
    userSession.assetSummaries?.forEach( assetSummary => {
      result.add(assetSummary.layerType);
    })
    return result;
  }, [userSession.assetSummaries]);

  const hasCrossSectionX = useMemo(()=>assetLayers.has( LayerType.CrossSectionX ) && userSession.layersVisibility?.CrossSectionX !== undefined, [assetLayers, userSession.layersVisibility]);
  const hasCrossSectionY = useMemo(()=>assetLayers.has( LayerType.CrossSectionY ) && userSession.layersVisibility?.CrossSectionY !== undefined, [assetLayers, userSession.layersVisibility]);
  const hasCrossSectionZ = useMemo(()=>assetLayers.has( LayerType.CrossSectionZ ) && userSession.layersVisibility?.CrossSectionZ !== undefined, [assetLayers, userSession.layersVisibility]);

  const hasCrossSectionData = useMemo((): boolean =>
          (hasCrossSectionX || hasCrossSectionY || hasCrossSectionZ), [hasCrossSectionX, hasCrossSectionY, hasCrossSectionZ]);

  const showLayerSettings = useCallback((
      layerType: LayerType
  ) => {
    switch (layerType) {
      case LayerType.BlockModel:
        break;
      case LayerType.BlastholeSecondarySegments:
        setBlastholeLayerOptions(userSession.segmentStyle);
        break;
      case LayerType.BlastholeSingleSegment:
        setCollarLayerSettings({
          collarDiameter: userSession.collarStyle.collarDiameter,
          collarDisplayType: CollarAppearance[userSession.collarStyle.collarAppearance] as keyof ICollarDisplayTypes<string>
        });
        break;
      case LayerType.DrilledHolesContour:
        break;
      case LayerType.BlockVolume:
        break;
      case LayerType.DrilledHoleCollars:
        break;
      case LayerType.Undefined:
        break;
      case LayerType.Boundary:
        setShowPatternBoundaryOptions(true);
        break;
      case LayerType.BlastholeClusters:
        break;
      case LayerType.BlastholeFractures:
        setBlastholeFracturesSettings({
          collarDepth: userSession.collarDepth,
          prominenceFilter: userSession.prominenceFilter, // 0 to 50
        });
        break;
      case LayerType.CrossSectionX:
      case LayerType.CrossSectionY:
      case LayerType.CrossSectionZ:
        break;
    }
  }, [ userSession.collarStyle.collarDiameter, userSession.collarStyle.collarAppearance, userSession.collarDepth, userSession.prominenceFilter, userSession.segmentStyle ]);

  const [colorPickerProps, setColorPickerProps] = useState<IColorPickerDialogProps>();
  const invokeColorPicker = useCallback((props: IColorPickerDialogProps) => {
    setColorPickerProps({
      color: props.color,
      onCancel: () => {
        props.onCancel();
        setColorPickerProps(undefined);
      },
      onColorSelected: (color) => {
        props.onColorSelected(color);
        setColorPickerProps(undefined);
      },
    })
  }, [setColorPickerProps]);

  const colorLegendState = useMemo(()=>{
    if ( !userSession.selectedAttribute || !userSession.colorLegendStates ) {
      return undefined;
    }
    return userSession.colorLegendStates.get( userSession.selectedAttribute );

  }, [userSession.selectedAttribute, userSession.colorLegendStates]);

  useEffect(() => {
    if ( userSession.selectedAttribute &&
        (userSession.attributesInData??[]).length > 0 &&
        userSession.attributesInData?.indexOf( userSession.selectedAttribute ) === -1 )
    {
      userSessionActions.setSelectedAttribute(userSession.attributesInData[0]);
    }
  }, [userSession.selectedAttribute, userSession.attributesInData ]);

  const isFirefox = useMemo(()=>(navigator.userAgent.indexOf("Firefox") !== -1), []);

  return (
      <>
        { userSession.patternSummaries && (
        <CollapsibleSideMenuContainer>

          {/* VIEW SECTION */}
          <CollapsibleSideMenuSection
              label={t("view")}
              icon={faBinoculars}
              buttonState={undefined}
              helpTitle={"Configuring View Parameters"}
              helpContent={
                <Help_CesiumView_SideMenu_View/>
              }
          >
            <CameraProjectionSideMenuItem
                selection={userSession.cameraFrustum}
                onCameraFrustrumChanged={userSessionActions.setCameraFrustum}
            />

            <HorizontalSeparator className={"w3-margin-left w3-margin-right"}/>

            <CameraPresetsSideMenuItem
                onViewOrthogonalDirection={(direction) => {
                  if (userSession.boundingSphere) {
                    props.onCameraVantagePointSet({
                      boundingSphere: userSession.boundingSphere,
                      hpr: getHeadingPitchRange(direction, userSession.boundingSphere.radius * 2)
                    });
                  }
                }}
            />

            <HorizontalSeparator className={"w3-margin-left w3-margin-right"}/>

            { AppConstants.OptionalFeatures.holeLabels && (
              <div className={'w3-right-align'}>
                <LabelIconButton
                    className={"w3-margin-right w3-border-red w3-hover-red"}
                    label={t("reset_camera")}
                    icon={faArrowsToEye}
                    onClick={()=> {
                      if (userSession.boundingSphere) {
                        props.onCameraVantagePointSet({
                          boundingSphere: userSession.boundingSphere,
                          hpr: new HeadingPitchRange(-Math.PI/4, -Math.PI / 4, 2 * userSession.boundingSphere.radius)
                        });
                      }
                    }}
                />
              </div>
            )}

            <HorizontalSeparator className={"w3-margin-left w3-margin-right"}/>

            <CheckboxLabelDotMenu
                label={t("show_bounding_box")}
                checked={userSession.boundingBoxVisibility}
                onCheckedChanged={userSessionActions.setBoundingBoxVisibility}
                onDotMenuClicked={()=>setShowBoundingBoxOptions(true)}
            />
            <CheckboxLabelDotMenu
                label={t("show_bounding_top")}
                checked={userSession.topPlaneVisibility}
                onCheckedChanged={userSessionActions.setTopPlaneVisibility}
            />
            <CheckboxLabelDotMenu
                label={t("show_xyz_axes")}
                checked={userSession.axesVisibility}
                onCheckedChanged={userSessionActions.setAxesVisibility}
            />
            <CheckboxLabelDotMenu
                label={t("show_2d")}
                checked={userSession.showMap2D}
                onCheckedChanged={userSessionActions.setShowMap2D}
            />
            <CheckboxLabelDotMenu
                label={t("show_toolbar")}
                checked={userSession.showToolbar}
                onCheckedChanged={userSessionActions.setShowToolbar}
            />
            <CheckboxLabelDotMenu
                label={t("show_section_controls")}
                checked={userSession.showControlsHelp}
                onCheckedChanged={userSessionActions.setShowControlsHelp}
            />
            { !isFirefox &&
                <CheckboxLabelDotMenu
                    label={t("show_center_rotation")}
                    checked={userSession.showCenterOfRotationEntity}
                    onCheckedChanged={userSessionActions.setShowCenterOfRotationEntity}
                />
            }
            <CheckboxLabelDotMenu
                label={t("show_above_bench")}
                checked={userSession.showAboveBench}
                onCheckedChanged={userSessionActions.setShowAboveBench}
            />
            <CheckboxLabelDotMenu
                label={t("show_within_bench")}
                checked={userSession.showWithinBench}
                onCheckedChanged={userSessionActions.setShowWithinBench}
            />
            <CheckboxLabelDotMenu
                label={t("show_beneath_bench")}
                checked={userSession.showBeneathBench}
                onCheckedChanged={userSessionActions.setShowBeneathBench}
            />
            <CheckboxLabelDotMenu
                label={`${t("Show")} ${t('Floating Legend')}`}
                checked={userSession.showFloatingLegend}
                onCheckedChanged={userSessionActions.setShowFloatingLegend}
            />

            <HorizontalSeparator className={"w3-margin-left w3-margin-right"}/>


            <div className={"w3-container w3-cell-row w3-margin-top"}>
              <div
                  className={"w3-cell w3-cell-middle w3-left-align unselectable"}
                  style={{ fontSize: "12px"}}
              >
                {t("Hole Labels")}
              </div>
              <div className={"w3-cell w3-cell-middle"}>
                <HoleLabelSelector selection={userSession.holeLabels} onSelectionChanged={userSessionActions.setHoleLabelsType}/>
              </div>
            </div>


            <HorizontalSeparator className={"w3-margin-left w3-margin-right"}/>

            <SideMenuRow
                widths={CS_SECTION_COL_WIDTHS}
                style={{fontSize: "12px"}}
                className={"w3-section"}
                disabled={!hasCrossSectionData}
            >
              <label htmlFor={"maskedColor"} className={"w3-left unselectable"}>{t("masked_color")}</label>
              <ColorBox
                  id={"maskedColor"}
                  color={userSession.maskedColor}
                  onColorPickerInvoked={invokeColorPicker}
                  onColorChanged={(c)=>{
                    userSessionActions.setMaskedColor(c)
                  }}
                  disabled={!hasCrossSectionData}
              />
            </SideMenuRow>
            <SideMenuRow
                widths={CS_SECTION_COL_WIDTHS}
                style={{fontSize: "12px"}}
                className={"w3-section"}
                disabled={!hasCrossSectionData}
            >
              <label htmlFor={"selectedOutline"} className={"w3-left unselectable"}>{t("selected_outline_color")}</label>
              <ColorBox
                  id={"selectedOutline"}
                  color={userSession.selectedCrossSectionStyle.outlineColor}
                  onColorPickerInvoked={invokeColorPicker}
                  onColorChanged={(value)=>userSessionActions.setSelectedCrossSectionStyle({...userSession.selectedCrossSectionStyle, outlineColor: value})}
                  disabled={!hasCrossSectionData}
              />
            </SideMenuRow>

          </CollapsibleSideMenuSection>

          {/* PATTERNS SECTION */}
          <CollapsibleSideMenuSection
              label={t("Patterns")} icon={faFilter}
              pillValue={userSession.assetsToManuallyUpdate?.length}
              onPillClicked={()=>console.log("Pill Clicked")}
              helpTitle={"Configuring Pattern Filters"}
              helpContent={
                <Help_CesiumView_SideMenu_Filters/>
              }
          >

            <CheckboxLabelDotMenu
                label={t("show_sub_drill")}
                className={'w3-margin-top'}
                checked={userSession.showSubDrill}
                onCheckedChanged={(value)=>userSessionActions.setShowSubDrill(value)}
            />

            <HorizontalSeparator className={"w3-margin-left w3-margin-right"}/>

            <PatternsFilterTable
                patternSummaries={userSession.patternSummaries}
                onPatternVisibilityChanged={(pattern, visibility)=>{
                  userSessionActions.setPatternVisibility(pattern.patternId, visibility);
                }}
                onUpdatePatternRequested={(assetsToUpdate)=>{
                  setConfirmationDialogOptions({
                    title: `${t("pattern_data_reload")}`,
                    prompt: `${t("prompt_reload_pattern_data")}: ${assetsToUpdate.map(a=>t(LayerType[a.layerType])).join(", ")}`,
                    onAccept: () => {
                      setConfirmationDialogOptions(undefined);
                      userSessionActions.updateAssets(assetsToUpdate);
                    },
                    onCancel: () => {
                      setConfirmationDialogOptions(undefined);
                    },
                  } as IConfirmationDialogProps);
                }}
            />

          </CollapsibleSideMenuSection>

          {/* LAYERS SECTION */}
          <CollapsibleSideMenuSection
              label={t("layers")}
              icon={faLayerGroup}
              helpTitle={"Configuring Layer properties"}
              helpContent={
                <Help_CesiumView_SideMenu_Layers/>
              }
          >

            <LayersFilterTable
                onLayerOptionsClicked={showLayerSettings}
            />

            <HorizontalSeparator className={"w3-margin-left w3-margin-right"}/>

            <div className={'w3-right-align'}>
              <LabelIconButton
                  className={"w3-margin-bottom w3-margin-right w3-hover-red w3-border-red"}
                  label={t("Reset")}
                  icon={faRotateLeft}
                  onClick={()=>userSessionActions.resetLayersVisibilityAndOpacity()}
              />
            </div>

          </CollapsibleSideMenuSection>

          {/* COLOR LEGEND */}
          <CollapsibleSideMenuSection
              id={'tour-side-menu-legend'}
              label={t("legend")}
              icon={faSliders}
              onSubMenuButtonStateChanged={(st)=> {
                if ( st === SubMenuButtonState.Collapsed ) {
                  setColorLegendVisibilityFlags( prevState => prevState & ~ColorLegendFlags.SideMenuLegendVisible )
                } else {
                  setColorLegendVisibilityFlags( prevState => prevState | ColorLegendFlags.SideMenuLegendVisible )
                }
                //setFloatingColorLegendVisibility(st === SubMenuButtonState.Collapsed)
              }}
              helpTitle={"Configuring Color Legend"}
              helpContent={
                <Help_CesiumView_SideMenu_Legend/>
              }
          >
            <div
              id={'tour-side-menu-legend-attr'}
              className={"w3-padding w3-margin-top"}
            >
              <AttributeSelector
                  selectedAttribute={userSession.selectedAttribute}
                  availableAttributes={userSession.attributesInData??[]}
                  onSelectedAttributeChanged={userSessionActions.setSelectedAttribute}
              />
            </div>

            <HorizontalSeparator className={"w3-margin-left w3-margin-right"}/>

            <ColorLegendTable onCameraVantagePointSet={props.onCameraVantagePointSet} />

            <HorizontalSeparator className={"w3-margin-left w3-margin-right"}/>

            <div className={'w3-right-align'}>
              <LabelIconButton
                  className={"w3-margin-bottom w3-margin-right w3-hover-red w3-border-red"}
                  label={t("Reset")}
                  icon={faRotateLeft}
                  onClick={() => {
                    if (colorLegendState) {
                      userSessionActions.resetColorLegend( colorLegendState, { visible: true, opacity: 1.0})
                      userSessionActions.persistColorLegend( colorLegendState );
                      // userSessionActions.resetLayersVisibilityAndOpacity();
                    }
                  }}
              />
            </div>

          </CollapsibleSideMenuSection>

          {/* CROSS-SECTIONS */}
          <CollapsibleSideMenuSection
              id={'tour-side-menu-sections'}
              label={t("section_viewer")}
              icon={faChessBoard}
              helpTitle={"Section Viewer Parameters"}
              helpContent={
                <Help_CesiumView_SideMenu_SectionViewer />
              }
          >

            <SideMenuRow
                id={'tour-side-menu-sections-xy'}
                widths={CS_SECTION_COL_WIDTHS}
                style={{fontSize: "12px"}}
                className={"w3-section"}
                disabled={!hasCrossSectionZ}
            >
              <label htmlFor={"checkboxShowCrossSectionXY"} className={"w3-left unselectable"}>{t("show_xy")}</label>
              <FlipSwitch
                  id={"checkboxShowCrossSectionXY"}
                  checked={userSession.layersVisibility?.CrossSectionZ??false}
                  onCheckedChanged={(value)=>{
                    userSessionActions.setLayerVisibility( LayerType.CrossSectionZ, value );
                  }}
                  disabled={!hasCrossSectionZ}
              />
            </SideMenuRow>
            <SideMenuRow
                id={'tour-side-menu-sections-xz'}
                widths={CS_SECTION_COL_WIDTHS}
                style={{fontSize: "12px"}}
                className={"w3-section"}
                disabled={!hasCrossSectionY}
            >
              <label htmlFor={"checkboxShowCrossSectionXZ"} className={"w3-left unselectable"}>{t("show_xz")}</label>
              <FlipSwitch
                  id={"checkboxShowCrossSectionXZ"}
                  checked={userSession.layersVisibility?.CrossSectionY??false}
                  onCheckedChanged={(value)=>{
                    // ... If turning ON, then set offset to halfway mark
                    if (userSession.boundingBox && value ) {
                      let len = userSession.boundingBox.maxPos.y - userSession.boundingBox.minPos.y;
                      userSessionActions.setCrossSectionOffsetY( len / 2 );
                    }
                    // ... Set the visibility
                    userSessionActions.setLayerVisibility( LayerType.CrossSectionY, value );
                  }}
                  disabled={!hasCrossSectionY}
              />
            </SideMenuRow>
            <SideMenuRow
                id={'tour-side-menu-sections-yz'}
                widths={CS_SECTION_COL_WIDTHS}
                style={{fontSize: "12px"}}
                className={"w3-section"}
                disabled={!hasCrossSectionX}
            >
              <label htmlFor={"checkboxShowCrossSectionYZ"} className={"w3-left unselectable"}>{t("show_yz")}</label>
              <FlipSwitch
                  id={"checkboxShowCrossSectionYZ"}
                  checked={userSession.layersVisibility?.CrossSectionX??false}
                  onCheckedChanged={(value)=>{
                    // ... If turning ON, then set offset to halfway mark
                    if (userSession.boundingBox && value ) {
                      let len = userSession.boundingBox.maxPos.x - userSession.boundingBox.minPos.x;
                      userSessionActions.setCrossSectionOffsetX( len / 2 );
                    }
                    // ... Set the visibility
                    userSessionActions.setLayerVisibility( LayerType.CrossSectionX, value );
                  }}
                  disabled={!hasCrossSectionX}
              />
            </SideMenuRow>
            <SideMenuRow
                id={'tour-side-menu-sections-dist-filter'}
                widths={["60%", "40%"]}
                style={{fontSize: "12px"}}
                className={"w3-section"}
                disabled={!hasCrossSectionData}
            >
              <label htmlFor={"distanceThreshold"} className={"w3-left unselectable"}>{t("hide_holes_further_than", {distance: userSession.sectionViewDistanceThreshold})}</label>
              {/*<input*/}
              {/*    id={"distanceThreshold"}*/}
              {/*    className={"w3-right w3-theme-d4"}*/}
              {/*    style={{width: "100px"}}*/}
              {/*    type="range"*/}
              {/*    min="0"*/}
              {/*    max="100"*/}
              {/*    step="5"*/}
              {/*    value={userSession.sectionViewDistanceThreshold}*/}
              {/*    onChange={(e) => userSessionActions.setSectionViewDistanceThreshold(+e.target.value)}*/}
              {/*    onKeyDown={(event) => {*/}
              {/*      event.preventDefault();*/}
              {/*    }}*/}
              {/*    disabled={!hasCrossSectionData}*/}
              {/*/>*/}
              <div style={{width: "80px"}} className={'w3-right'}>
                <Slider
                    // className={'w3-padding-small'}
                    min={0}
                    max={100}
                    step={5}
                    value={userSession.sectionViewDistanceThreshold}
                    onValueChanged={async(value)=>userSessionActions.setSectionViewDistanceThreshold( value )}
                    // onRangeOpacityReleased={props.onRangeOpacityReleased}
                    disabled={!hasCrossSectionData}
                />
              </div>
            </SideMenuRow>
          </CollapsibleSideMenuSection>

        </CollapsibleSideMenuContainer>
        )}
        {showBoundingBoxOptions && (
            <BoundingBoxOptionsDialog onDismiss={()=>setShowBoundingBoxOptions(false)}/>
        )}
        {collarLayerSettings && (
            <CollarLayerSettingsDialog
                settings={collarLayerSettings}
                onAccept={(newSettings) => {
                  console.log(`newSettings: ${JSON.stringify(newSettings)}`);
                  userSessionActions.setCollarStyle({
                    collarDiameter: newSettings.collarDiameter,
                    collarAppearance: CollarAppearance[ newSettings.collarDisplayType ]
                  });
                  setCollarLayerSettings(undefined);
                }}
                onCancel={() => setCollarLayerSettings(undefined)}
            />
        )}
        {showPatternBoundaryOptions && (
            <PatternBoundaryOptionsDialog
                onDismiss={()=>setShowPatternBoundaryOptions(false)}
            />
        )}
        {blastholeFracturesSettings && (
            <BlastholeFracturesLayerSettingsDialog
                settings={blastholeFracturesSettings}
                onAccept={(newSettings: IBlastholeFracturesLayerSettings) => {
                  console.log(`newSettings: ${JSON.stringify(newSettings)}`);
                  userSessionActions.setCollarDepth(newSettings.collarDepth);
                  userSessionActions .setProminenceFilter(newSettings.prominenceFilter);
                  setBlastholeFracturesSettings(undefined);
                }}
                onCancel={() => setBlastholeFracturesSettings(undefined)}
            />
        )}
        {colorPickerProps && (
            <ColorPickerDialog
                color={colorPickerProps.color}
                onCancel={() => {
                  colorPickerProps.onCancel();
                  setColorPickerProps(undefined);
                }}
                onColorSelected={(c) => {
                  colorPickerProps.onColorSelected(c);
                  setColorPickerProps(undefined);
                }}
            />
        )}
        {confirmationDialogOptions && (
            <ConfirmationDialog
                title={confirmationDialogOptions.title}
                prompt={confirmationDialogOptions.prompt}
                onAccept={confirmationDialogOptions.onAccept}
                onCancel={confirmationDialogOptions.onCancel}
            />
        )}
        {notificationDialogOptions && (
            <NotificationDialog
                title={notificationDialogOptions.title}
                prompt={notificationDialogOptions.prompt}
                onDismiss={notificationDialogOptions.onDismiss}
            />
        )}
        { blastholeLayerOptions && (
            <SecondarySegmentLayerSettingsDialog
                settings={blastholeLayerOptions}
                onAccept={(newSettings: ISecondarySegmentOptions) => {
                  console.log(`newSettings: ${JSON.stringify(newSettings)}`);
                  userSessionActions.setSegmentStyle(newSettings);
                  setBlastholeLayerOptions(undefined);
                }}
                onCancel={() => setBlastholeLayerOptions(undefined)}
            />
        )}
      </>
  )
}


export default withTranslation()(SideMenu);
