import {Cartesian3, Cesium3DTileFeature, Color, ConstantProperty, modelMatrix} from "cesium";
import {withTranslation, WithTranslation} from "react-i18next";
import {FeatureAdapter} from "../../../../lib/cesium/Tileset/FeatureAdapter";
import React, {useCallback, useEffect, useMemo} from "react";
import IAssetSummary from "../../domain/IAssetSummary";
import PureCollarEntity from "../BasicControls/PureCollarEntity";
import {useColorLegendUtil} from "../../hooks/useColorLegendUtil";
import {ADJUSTED_POS_PROPERTY_NAME, TilesetUtils} from "../../util/TilesetUtils";
import {AttributeType, IAttributeTypeMap} from "../../domain/AttributeType";
import IColorLegendConfiguration, {pickColorLegend} from "../../domain/IColorLegendConfiguration";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import {DEFAULT_NEUTRAL_COLOR} from "../../domain/IStyle";
import {CesiumMovementEvent} from "resium";
import {getTintedColor} from "../../hooks/useTilesetStyleExpression";

export interface ICollarEntityProps extends WithTranslation {
  show: boolean;
  assetSummary: IAssetSummary;
  feature: Cesium3DTileFeature;
  onLeftClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onRightClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onDoubleClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseMove?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseUp?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseDown?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  selectionPredicate?: (position: Cartesian3)=>boolean|undefined;
}

const CollarEntity: React.FC<ICollarEntityProps> = (props) => {
  const colorLegendUtil = useColorLegendUtil();
  const [siteData] = useSiteConfig();
  const [userSession, , userSessionMoreActions] = useUserSessionContext();
  const feature = props.feature;
  const selectionPredicate = props.selectionPredicate;
  const show = props.show ;
  const name = useMemo(() => {
    // return `${feature.featureId}`;
    return FeatureAdapter.createFeatureName(feature);
  }, [feature] ) ;
  const description = useMemo(() => new ConstantProperty(FeatureAdapter.getDescriptionItems(feature)), [feature]);

  const holeId = useMemo(()=>feature.getProperty("HoleID"), [feature]);
  const drillId = useMemo(()=>feature.getProperty("DrillID"), [feature]);
  const holeName = useMemo(()=>feature.getProperty("HoleName"), [feature]);

  const getDrillIdLabelColor = userSessionMoreActions.getDrillIdLabelColor ;
  const labelColor = useMemo(()=>{
    return getDrillIdLabelColor( drillId );
  }, [getDrillIdLabelColor, drillId]);

  const position = useMemo(() => {
    return Cartesian3.fromArray(feature.getProperty(ADJUSTED_POS_PROPERTY_NAME) as number[]);
  }, [feature]);

  const properties = useMemo(()=>{
    let result: {
      [key: string]: any
    } = {};
    feature.getPropertyNames().forEach( name => {
      result[ name ] = feature.getProperty( name ) ;
    } );
    return result ;
  }, [feature]);

  // ... Get active color legend config
  const colorLegendConfig = useMemo(()=>{
    if ( !userSession.selectedAttribute || !siteData?.legendConfigs ) {
      return undefined;
    }
    const attributeIndex = AttributeType[userSession.selectedAttribute] as keyof IAttributeTypeMap<IColorLegendConfiguration> ;
    return siteData.legendConfigs[attributeIndex] ;

  }, [userSession.selectedAttribute, siteData?.legendConfigs]);

  // ... Get active color legend state
  const colorLegendState = useMemo(()=>{
    if ( !userSession.selectedAttribute || !userSession.colorLegendStates ) {
      return undefined;
    }
    return userSession.colorLegendStates.get( userSession.selectedAttribute );

  }, [userSession.selectedAttribute, userSession.colorLegendStates]);

  // ... Get active masked color
  const maskedColor = useMemo(()=> {
    return colorLegendConfig ? Color.fromCssColorString( colorLegendConfig.maskedColor ): undefined
  }, [colorLegendConfig]);

  // ... Get collar color
  const baseColor = useMemo(()=>{
    if ( !maskedColor ) {
      return DEFAULT_NEUTRAL_COLOR;
    }
    if ( !colorLegendConfig || !colorLegendState ) {
      return maskedColor;
    }
    if ( !userSession.layersVisibility.BlastholeSingleSegment ) {
      return maskedColor;
    }
    const featureValue = +colorLegendUtil.actions.getFeatureValue(feature);
    const colorLegend = pickColorLegend( featureValue, colorLegendConfig, colorLegendState );
    if ( ! colorLegend ) {
      return maskedColor;
    }
    let [itemConfig, itemState] = colorLegend;
    if ( !itemConfig || !itemState ) {
      return maskedColor;
    }
    if ( !itemState.visible ) {
      return maskedColor;
    }

    // let opacity = itemState.opacity * viewModel.layerStyles.BlastholeSingleSegment.opacity ;
    let opacity = itemState.opacity * userSession.layersOpacity.BlastholeSingleSegment;

    return itemConfig.color.withAlpha( opacity );

  }, [
    feature,
    userSession.layersVisibility.BlastholeSingleSegment,
    userSession.layersOpacity.BlastholeSingleSegment,
    colorLegendConfig,
    colorLegendState,
    maskedColor,
    colorLegendUtil.actions
  ]);

  const color = useMemo(()=>{
    const tinted = (properties['tinted'] ?? false);
    return tinted ? getTintedColor( baseColor ) : baseColor ;
  }, [baseColor, properties]);

  const visible = useMemo(()=>{
    return selectionPredicate === undefined || (selectionPredicate(position) ?? true);
  }, [selectionPredicate, position]);

  const onLeftClick = props.onLeftClick;
  const onRightClick = props.onRightClick;
  const onDoubleClick = props.onDoubleClick;
  const onMouseMove = props.onMouseMove;
  const onMouseUp = props.onMouseUp;
  const onMouseDown = props.onMouseDown;

  const handleLeftClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onLeftClick ) {
      onLeftClick( movement, target );
    }
  }, [onLeftClick]);
  const handleRightClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onRightClick ) {
      onRightClick( movement, target );
    }
  }, [onRightClick]);
  const handleDoubleClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onDoubleClick ) {
      onDoubleClick( movement, target );
    }
  }, [onDoubleClick]);
  const handleMouseMove = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onMouseMove ) {
      onMouseMove( movement, target );
    }
  }, [onMouseMove]);
  const handleMouseUp = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onMouseUp ) {
      onMouseUp( movement, target );
    }
  }, [onMouseUp]);
  const handleMouseDown = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onMouseDown ) {
      onMouseDown( movement, target );
    }
  }, [onMouseDown]);

  return (
      <>
        { visible &&
          <PureCollarEntity
              show={show}
              holeLabels={userSession.holeLabels}
              name={name}
              holeId={holeId}
              holeName={holeName}
              drillName={drillId}
              labelColor={labelColor}
              description={description}
              position={position}
              collarStyle={userSession.collarStyle}
              color={color}
              properties={properties}
              onLeftClick={handleLeftClick}
              onRightClick={handleRightClick}
              onDoubleClick={handleDoubleClick}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseDown={handleMouseDown}
          />
        }
      </>
  )
}

export default withTranslation()(React.memo(CollarEntity));
