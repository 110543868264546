import React from "react";
import {t} from "i18next";
import {WithTranslation, withTranslation} from "react-i18next";

interface IProps extends WithTranslation {
  borderRadius: string;
  onAccept: () => void;
  onCancel: () => void;
  onReset?: () => void;
}

const ButtonFooter: React.FC<IProps> = (props) => {
  return (
      <footer className="w3-container w3-cell-row w3-theme-d3 w3-padding" style={{borderRadius: `0px 0px ${props.borderRadius} ${props.borderRadius}`}}>
        <div className={"w3-cell w3-cell-middle"}>
          <button
              className="w3-btn w3-grey w3-round-medium w3-left"
              onClick={props.onCancel}
          >
            {t("cancel")}
          </button>
        </div>
        <div className={"w3-cell w3-cell-middle w3-center"}>
          {
              props.onReset && (
                  <button
                      className="w3-btn w3-amber w3-round-medium"
                      onClick={props.onReset}
                  >
                    {t("reset")}
                  </button>
              )
          }
        </div>
        <div className={"w3-cell w3-cell-middle"}>
          <button
              className="w3-btn w3-light-green w3-round-medium w3-right"
              onClick={()=>props.onAccept()}
          >
            {t("accept")}
          </button>
        </div>
      </footer>
  )
}

export default withTranslation()(ButtonFooter);
