import React, {useCallback} from "react";
import {t} from "i18next";
import WireframeBox from "../BasicControls/WireframeBox";
import {withTranslation, WithTranslation} from "react-i18next";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import {Cartesian3, Matrix4, Plane} from "cesium";
import {IBoundingBox} from "../../domain/IBoundingBox";
import {TilesetUtils} from "../../util/TilesetUtils";

interface IProps extends WithTranslation {
}

const BoundingBox: React.FC<IProps> = (props) => {
  // console.log( 'BoundingBox: rendering...')

  const [siteData] = useSiteConfig();
  const [userSession] = useUserSessionContext();

  return (
      <>
        {userSession.boundingBox && (
            <WireframeBox
                name={t("Bounding box")}
                show={userSession.boundingBoxVisibility}
                color={userSession.boundingBoxColor}
                modelMatrix={siteData.modelMatrix}
                minPosECEF={userSession.boundingBox.minPos}
                maxPosECEF={userSession.boundingBox.maxPos}
            />
          )}
      </>
  )
}

export default withTranslation()(/*React.memo(*/BoundingBox/*)*/);
