import React from "react";
import "../../vertical_range_editor.css";

interface VSplitterProps {
    height: number;
    onMouseOver: (isMouseOver: boolean)=>void;
    onClick: (currentY: number)=>void;
    className?: string;
}

const VSplitter: React.FC<VSplitterProps> =
({
   height,
   onMouseOver,
   onClick,
   className
}) => {
    return (
        <div
            className={`resizer_vertical w3-white ${className}`}
            onMouseDown={(e)=> {
                onClick( e.clientY );
            } }
            onMouseOver={(e)=>{
                onMouseOver(true)
            }}
            onMouseLeave={(e)=>{
                onMouseOver(false);
            }}
            style={{cursor: "inherit", minHeight: `${height}px` }}
        >
        </div>
    );
}

export default VSplitter;
