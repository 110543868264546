import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

/**
 * Help page for using the Pattern Explorer page.
 *
 * @param props
 * @constructor
 */

const Help_PatternExplorer_AvailablePatterns: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <>
        <div className={'w3-panel'}>
          <p className={"text-full-justify"}>
            {t("The Available patterns section provides you with a list of patterns (Available patterns section) that are " +
                "currently available for viewing")}
          </p>
          <img src={"/help/pattern_explorer_1.jpg"} className={'w3-block'}/>
          <p className={"text-full-justify"}>
            {t("Each pattern in comprised of one or more assets, where each asset " +
              "represents a data layer.  The following layers are currently supported:")}
          </p>
          <ul className={"w3-left-align"}>
            <li>{t("Block Model")}</li>
            <li>{t("Blastholes")}</li>
            <li>{t("Blasthole Fractures")}</li>
            <li>{t("Collars")}</li>
            <li>{t("Pattern  Boundary")}</li>
            <li>{t("Rock Mass Domain clusters")}</li>
          </ul>
          <p className={"text-full-justify"}>
            {t("Each row defines one pattern (given name, start timestamp, end timestamp and the DMM revision, which " +
              "is a timestamp for when the DMM last modified any of the patterns' component asset files.")}
          </p>
        </div>

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <div className={"w3-panel"}>
          <h2 className={"w3-left-align"}>{t("Manually Uploading New Asset Files")}</h2>
          <div>
            <p className={'text-full-justify'}>
              <span>{t("Click on the 'Upload' button (")}</span>
              <span><img src={"/help/upload_button.jpg"} style={{height: "25px"}}/></span>
              <span>{t(") at the top-right hand side of the section title bar for " +
                  "'Available Patterns' to manually upload new asset files.  Supported assets files will typically use the " +
                  "*.json, *.i3dm and *.glb filename extensions.")}</span>
            </p>
          </div>
        </div>

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <div className={"w3-panel"}>
          <h2 className={"w3-left-align"}>{t("Row Actions")}</h2>

          <p className={"text-full-justify"}>
            {t("Click on any column to sort the table in ascending or descending order for that column:")}
            <img src={"/help/row_columns.jpg"} className={"w3-block w3-margin-top"}/>
          </p>
          <p className={"text-full-justify"}>
            {t("Click on the left-hand checkbox of any row to select that pattern.  When one or more patterns are " +
                "selected, you may view your selection in a 3D viewer by clicking on the 'Open' button at the bottom" +
                " left hand side of this page.  By clicking on the 'Reset' button, all selected patterns are unselected.")}
            <img src={"/help/available_patterns_selections.jpg"} className={"w3-block w3-margin-top"}/>
          </p>
          <p className={"text-full-justify"}>
            {t("Click on the right-hand dot-menu for more option:")}
          </p>
          <div className={"w3-row"}>
            <img src={"/help/dot_menu.jpg"} className={"w3-col w3-half w3-margin-top"}/>
            <div className={"w3-container w3-col w3-half w3-left-align"}>
              <ul>
                <li className={"w3-padding-small"}>{t("Select 'Activate' to mark the pattern as active")}</li>
                <li className={"w3-padding-small"}>{t("Select 'Deactivate' to mark the pattern as inactive")}</li>
                <li className={"w3-padding-small"}>{t("Select 'Delete' to remove the pattern in the backend/DMM repositories")}</li>
              </ul>
            </div>
          </div>
        </div>
      </>
  )
}

export default withTranslation()(Help_PatternExplorer_AvailablePatterns);
