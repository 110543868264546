import React, {CSSProperties, useEffect} from "react";
import useResizeObserver from "use-resize-observer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleQuestion} from "@fortawesome/free-solid-svg-icons";
import {withTranslation, WithTranslation} from "react-i18next";
import {t} from "i18next";

interface ITitledSectionProps extends WithTranslation {
  title: string;
  className?:string|undefined;
  style?:CSSProperties|undefined;
  w3BorderColor?:string|undefined;
  onHelpClicked?:()=>void;
  titleExtra?:React.ReactNode;
  onSizeChanged?:(w: number, h: number)=>void|undefined;
}

const TitledSection: React.FC<ITitledSectionProps> = (props) => {

  const w3BorderColor = props.w3BorderColor ? `w3-border ${props.w3BorderColor}`: '';

  const titleSize = "w3-large";

  const {ref, width=1, height=1} = useResizeObserver<HTMLDivElement>();

  useEffect(()=>{
    if ( width && height ) {
      props.onSizeChanged && props.onSizeChanged( width, height );
    }
  }, [width, height]);

  return (
      <div
          ref={ref}
          className={`unselectable w3-theme-d4 w3-round-large ${props.className} ${w3BorderColor}`}
          style={{ ...props.style, width: "100%" }}
      >
        {/* Title bar */}
        <div
            className={"w3-light-blue w3-show-inline-block"}
            style={{ borderRadius: "8px 8px 0px 0px", width: "100%", }}
        >
          <span
              className={`${titleSize}`}
              style={{
                display: "inline-block",
                padding:"8px 16px",
                verticalAlign: "middle",
                textAlign:"center",
                whiteSpace:"nowrap"
              }}
          >
            {t(props.title)}
            {props.onHelpClicked && (
              <FontAwesomeIcon
                  className={`w3-margin-left ${titleSize}`}
                  style={{ cursor: "help", }}
                  icon={faCircleQuestion}
                  onClick={(e)=> {
                    e.stopPropagation();
                    props.onHelpClicked && props.onHelpClicked();
                  }}
              />
            )}
          </span>

          { props.titleExtra &&
              <span className={"w3-right w3-padding-small"} style={{height: "100%"}}>
                {props.titleExtra}
              </span>
          }

        </div>

        {/* Contents */}
        <div
            className={"w3-padding w3-section"}
            style={{ width: "100%" }}
        >
          {props.children}
        </div>
      </div>
  )
}

export default withTranslation()(TitledSection);