import React from "react";
import {Color} from "cesium";

interface IColorBoxProps {
    cssHeight: string;
    cssWidth: string;
    color: Color;
    onClicked: (color:Color)=>void;
}

const ColorBox: React.FC<IColorBoxProps> =
({
     cssHeight,
     cssWidth,
     color,
     onClicked,
}) => {

    return (
        <>
            <div
                className={"w3-cell"}
                style={{
                    height: cssHeight,
                    width: cssWidth,
                    backgroundColor: color.toCssHexString(),
                    borderColor: color.toCssHexString(),
                }}
                onClick={(e)=>onClicked(color)}
            >
                {/* Empty space with background color */}
            </div>
        </>
    );
}

export default ColorBox;