import React, {useContext} from "react";
import {Cartesian2, Cartesian3, Color, LabelStyle, PolylineGlowMaterialProperty, VerticalOrigin} from "cesium";
import {Entity, LabelGraphics, PolylineGraphics} from "resium";
import {withTranslation, WithTranslation} from "react-i18next";

interface IProps extends WithTranslation {
  show: boolean;
  start: Cartesian3;
  end: Cartesian3;
  label: string;
  color?:Color;
}

const LINE_THICKNESS: number = 1 ;
const COLOR = Color.WHITE;

const Axis: React.FC<IProps> = (props) => {

  return (
      <>
        <Entity show={props.show}>
          <PolylineGraphics
            width={LINE_THICKNESS}
            positions={[props.start, props.end]}
            // material={new PolylineGlowMaterialProperty({
            //   color: props.color ?? COLOR,
            //   glowPower: 0.5,
            //   taperPower: 1.0
            // })}
            material={props.color ?? COLOR}
          />
        </Entity>
        <Entity
            show={props.show}
            position={props.end}
        >
          <LabelGraphics
              text={props.label}
              style={LabelStyle.FILL}
              // outlineColor={Color.WHITE}
              // outlineWidth={2}
              verticalOrigin={VerticalOrigin.BOTTOM}
              pixelOffset={new Cartesian2(0, 0)}
              fillColor={props.color ?? COLOR}
              scale={0.5}
              backgroundColor={Color.BLACK.withAlpha(0.5)} showBackground={true}
          />
        </Entity>
      </>
  )
}

export default withTranslation()(React.memo(Axis)) ;
