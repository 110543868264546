import { useNavigate } from "react-router-dom";
import ISavedSession from "../model/ISavedSession";
import { withTranslation, WithTranslation } from "react-i18next";
import { t } from "i18next";
import DotMenu from "./DotMenu/DotMenu";
import React, { useState } from "react";
import SavedSessionRenameModal from "./SavedSession/SavedSessionRenameModal";
import IPattern from "../model/IPattern";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotateLeft, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useTooltips} from "./Gen2/components/Contexts/TooltipContextProvider";

interface Props extends WithTranslation {
  style?: any;
  selected: boolean;
  savedSession: ISavedSession;
  patterns: IPattern[];
  onLoad: (savedSession: ISavedSession|undefined) => void;
  onDelete: (savedSession: ISavedSession) => void;
  dirty: boolean;
  onSave: (savedSession: ISavedSession) => void;
  onCancelModifications: (savedSession: ISavedSession) => void;
}

const WIDTH = 170;

const SavedSession: React.FC<Props> = ({
  style,
  selected,
  savedSession,
  patterns,
  onLoad,
  onDelete,
  dirty,
  onSave,
  onCancelModifications,
}) => {
  const navigate = useNavigate();
  const [,tooltipActions] = useTooltips();
  const [showRenameModal, setShowRenameModal] = useState(false);

  const onChangeTooltip = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, tooltip: React.ReactNode|undefined ) => {
    const rect = e.currentTarget.getBoundingClientRect() ;
    const x = rect.x;
    const y = rect.y;

    tooltipActions.setTooltip( tooltip ? {
      content: tooltip,
      position: {
        x: x ,//- rect.width,
        y: y - rect.height/2
      },
      orientation: "left"
    } : undefined )
  }

  const handleLoad3D = () => {
    navigate(
      `/viewer?patternId=${patterns
        .map((p) => p.patternId)
        .join(",")}&sessionUuid=${
        savedSession.uuid
      }&sessionName=${savedSession.getName()}`
    );
  };

  return (
    <div
      style={{
        ...style,
        display: "inline-block",
        fontSize: "12px",
        cursor: "pointer",
      }}
    >
      <div
        className={`card-container w3-card-4 w3-bottombar ${
          selected ? "w3-border-light-blue" : "w3-border-dark-grey"
        }`}
        style={{
          position: "relative",
          display: "inline-block",
          borderRadius: "5px 5px 5px 5px",
          width: `${WIDTH}px`,
        }}
        onClick={(e) => {
          e.stopPropagation();
          if ( !selected ) {
            onLoad(savedSession);
          } else {
            onLoad(undefined);
          }
        }}
      >
        {showRenameModal && (
          <SavedSessionRenameModal
            originalName={savedSession.getName()}
            onCancel={() => setShowRenameModal(false)}
            onSave={(name) => {
              savedSession.getName = () => name;
              onSave(savedSession);
              setShowRenameModal(false);
            }}
          /> // imageDataURL: any;
        )}
        <div
          className="w3-center"
          style={{ position: "relative", top: "0", left: "0" }}
        >
          {savedSession.sessionData?.imageDataURL ? (
            <img
              src={savedSession.sessionData.imageDataURL}
              alt="screenshot"
              style={{
                width: `${WIDTH}px`,
                height: `${WIDTH}px`,
                borderRadius: "5px 5px 0px 0px" }}
            />
          ) : (
            <div
              className={"w3-black"}
              style={{
                width: `${WIDTH}px`,
                height: `${WIDTH}px`,
                borderRadius: "5px 5px 0px 0px",
              }}
            />
          )}
          <div
            className="w3-row unselectable"
            style={{
              marginTop: "5px",
              position: "absolute",
              bottom: "0",
              left: "0",
              right: "0",
            }}
          >
            {patterns
              .map((p) => p.name)
              .slice(0, 3)
              .map((p) => (
                <div
                  key={p}
                  className="w3-col m6 w3-border-left"
                  style={{
                    paddingLeft: "5px",
                    marginBottom: "5px",
                  }}
                >
                  {p}
                </div>
              ))}
            {patterns.length > 3 && (
              <div
                // className="tooltip w3-col m6 w3-border-left"
                className="w3-col m6 w3-border-left"
                style={{ paddingLeft: "5px", marginBottom: "5px", float: "right" }}
                onMouseEnter={(e)=>onChangeTooltip(e,
                    <span className="w3-white" style={{width: "240px"}}>
                      {patterns.map((p) => p.name).join(", ")}
                    </span>
                )}
                onMouseLeave={(e)=>onChangeTooltip(e, undefined)}
              >
                {t("see_all")}
                {/*<span className="tooltiptext w3-white" style={{width: "320px"}}>*/}
                {/*  {patterns.map((p) => p.name).join(", ")}*/}
                {/*</span>*/}
              </div>
            )}
          </div>
        </div>
        <header
            className={`${
                selected ? "w3-light-blue" : "w3-theme-d6"
            } unselectable`}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              borderRadius: "5px 5px 0px 0px",
              width: "100%",
            }}
        >
          <div className="w3-row">
            <div className="w3-right">
              <DotMenu style={{ borderRadius: "0px 5px 0px 0px" }}>
                <div
                    className="w3-dropdown-content w3-bar-block w3-card-4 w3-show"
                    style={{transform: "translate(-90%)"}}
                >
                  <button
                      className="w3-bar-item w3-button"
                      onClick={() => {
                        setShowRenameModal(true);
                      }}
                  >
                    {t("rename")}
                  </button>
                  {/*<button className="w3-bar-item w3-button" onClick={() => {}}>*/}
                  {/*  {t("share")}*/}
                  {/*</button>*/}
                </div>
              </DotMenu>
            </div>
            <div className={"w3-padding w3-rest"} style={{ overflow: "visible" }}>
              {savedSession.getName().length > 25 ? (
                  <div className="tooltip-bottom">
                    {`${savedSession.getName().substring(0, 22)}...`}
                    <span className="tooltiptext">{savedSession.getName()}</span>
                  </div>
              ) : (
                  savedSession.getName()
              )}
            </div>
          </div>
        </header>
        <footer className={"w3-show-inline-block"} style={{width: "100%"}}>
          <div className="w3-bar w3-row">
            <button
              // className={`w3-button w3-left ${!dirty || !selected ? "w3-light-blue" : "w3-light-green"} w3-margin-right w3-round-medium`}
              className={`w3-btn w3-half ${!dirty || !selected ? "w3-light-blue" : "w3-light-green"}`}
              onClick={(e) => {
                e.stopPropagation();
                if (!dirty) {
                  handleLoad3D();
                } else if (selected) {
                  onSave(savedSession);
                }
              }}
            >
              {!dirty || !selected ? t("open") : t("save")}
            </button>
            {/*<button className="w3-button w3-light-blue w3-margin-right w3-round-medium" onClick={() => onLoad(savedSession)}>*/}
            {/*  Select*/}
            {/*</button>*/}
            <button
              // className="w3-button w3-red w3-round-medium w3-right"
              className="w3-btn w3-half w3-red"
              onClick={(e) => {
                e.stopPropagation();
                if (!dirty) {
                  onDelete(savedSession);
                } else if (selected) {
                  onCancelModifications(savedSession);
                }
              }}
            >

              {/*{!dirty || !selected ? t("delete") : t("cancel")}*/}
              {!dirty || !selected ? <FontAwesomeIcon icon={faTrash}/> : <FontAwesomeIcon icon={faRotateLeft}/>}
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default withTranslation()(SavedSession);
