import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
  title: string;
  className?:string|undefined;
  // side?: "left" | "right";
  icon?:IconProp;
  theme?:string;
  dropDownItemsStyle?:any;
}

const NavMenu: React.FC<Props> = (props) => {
  // const sideClass = props.side === "right" ? "w3-right" : "";

  return (
      <>
        <div className={`w3-dropdown-hover ${props.className}`}>
          <button className="w3-button w3-mobile">
              {props.icon && <FontAwesomeIcon style={{marginRight: "5px"}} icon={props.icon} size={'lg'}/>}
              {props.title}
          </button>
          <div className={`w3-dropdown-content w3-bar-block w3-card-4 ${props.theme ?? "w3-theme-d4"}`} style={props.dropDownItemsStyle}>
            {props.children}
          </div>
        </div>
      </>
  );
};

export default NavMenu;
