import React, {CSSProperties, useCallback, useEffect, useMemo, useState} from "react";
import PatternList from "../components/PatternList";
import CurrentSession from "../components/CurrentSelection";
import IPattern from "../model/IPattern";
import ISavedSession from "../model/ISavedSession";
import SavedSession from "../components/SavedSession";
import FiltersContainer from "../components/Filters/FiltersContainer";
import IFilter from "../components/Filters/IFilter";
import { useNavigate, useSearchParams } from "react-router-dom";
import SelectionDialog from "../components/Dialogs/SelectionDialog";
import ConfirmationDialog from "../components/Dialogs/ConfirmationDialog";
import { withTranslation, WithTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { PatternAndAssetsApi, UserSessionApi } from "../lib/routes";
import { useFetchWithAuth } from "../lib/auth/fetchWithAuth";
import { parsePatternsResponse } from "../lib/patterns/parsePatternResponse";
import PatternsUpdatedWarning from "../components/Assets/PatternsUpdatedWarning";
import ProgressDialog from "../components/Dialogs/ProgressDialog";
import ITaskProgress from "../model/ITaskProgress";
import {useUploadWithAuth_v2} from "../components/Gen2/hooks/useUploadWithAuth";
import UploadResultDialog, {IUploadResult} from "../components/Dialogs/UploadResultDialog";
import {useHelp} from "../Help/HelpContextProvider";
import ColumnPane from "../components/Gen2/components/BasicControls/ColumnPane";
import RoundedPanel from "../components/Gen2/components/BasicControls/RoundedPanel";
import TitledSection from "../components/Gen2/components/BasicControls/TitledSection";
import CollapsibleSection from "../components/Gen2/components/BasicControls/CollapsibleSection";
import ContentPage from "../components/Gen2/components/BasicControls/ContentPage";
import Help_PatternExplorer_AvailablePatterns from "../Help/Help_PatternExplorer_AvailablePatterns";
import Help_PatternExplorer_FilterPatterns from "../Help/Help_PatternExplorer_FilterPatterns";
import Help_PatternExplorer_RecentSessions from "../Help/Help_PatternExplorer_RecentSessions";
import {format} from "date-fns";
import {TooltipContextProvider} from "../components/Gen2/components/Contexts/TooltipContextProvider";
import ProgressBar from "../components/Gen2/components/BasicControls/ProgressBar";
import {useMap} from "usehooks-ts";

const TITLE_BAR_HEIGHT = 51;//px
const MARGIN_HEIGHT = 16;//px

const Assets: React.FC<WithTranslation> = ({ t }) => {
  const [patterns, setPatterns] = useState<IPattern[]>([]);
  const [selectedPatternIds, setSelectedPatternIds] = useState<string[]>([]);
  const [savedSessions, setSavedSessions] = useState<ISavedSession[]>([]);
  const [confirmMatchingSession, setConfirmMatchingSession] = useState<{
    showModal: boolean;
    default: ISavedSession | undefined;
    matches: ISavedSession[];
  }>({
    showModal: false,
    default: undefined,
    matches: [],
  });
  const [currentFilter, setCurrentFilter] = useState<IFilter>({
    name: "",
    startDate: "",
    endDate: "",
    includeInactive: true,
  });
  const [loadedSession, setLoadedSession] = useState<ISavedSession>();

  // ... Loaded session stashed for when we want to undo changes
  const [stashedSession, setStashedSession] = useState<ISavedSession>();
  const [sessionToDelete, setSessionToDelete] = useState<ISavedSession>();
  const [patternIdsToDelete, setPatternIdsToDelete] = useState<string[]>();
  const [deleteAssociatedSessions, setDeleteAssociatedSessions] = useState<boolean>();
  const [deleteProgress, setDeleteProgress] = useState<ITaskProgress | undefined>();

  const [uploadProgress, setUploadProgress] = useState<ITaskProgress | undefined>();
  const [uploadResult, setUploadResult] = useState<IUploadResult[] | undefined>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const fetchWithAuth = useFetchWithAuth();
  // const uploadWithAuth = useUploadWithAuth();
  const uploadWithAuth_v2 = useUploadWithAuth_v2();

  const [, setHelpRequested] = useHelp();

  const [roundedPanelSize, setRoundedPanelSize] = useState<{width: number, height: number}>();
  const [patternFiltersSize, setPatternFiltersSize] = useState<{width: number, height: number}>();
  const [savedSessionsSize, setSavedSessionsSize] = useState<{width: number, height: number}>();
  const [maxHeights, setMaxHeights] = useState<{patternFilters: number, savedSessions: number}>();

  useEffect(()=>{
    if (roundedPanelSize?.height && patternFiltersSize?.height && savedSessionsSize?.height) {
      setMaxHeights({
        patternFilters: (roundedPanelSize.height - 3 * MARGIN_HEIGHT) / 2,
        savedSessions: roundedPanelSize.height - 3 * MARGIN_HEIGHT - patternFiltersSize.height
      });
    }
  }, [roundedPanelSize?.height, patternFiltersSize?.height, savedSessionsSize?.height]);

  useEffect(() => {
    fetchAvailablePatterns().then((allPatterns) => {
      // console.log(`fetchAvailablePatterns.then: ${JSON.stringify(allPatterns)}`);
      setPatterns(allPatterns);
      fetchSavedSessions().then((savedSessions) => {
        if (searchParams.get("sessionUuid")) {
          const sessionToLoad = savedSessions.find(
            (s) => s.uuid === searchParams.get("sessionUuid")
          );
          sessionToLoad && loadSession(sessionToLoad, allPatterns);
        }
      });
    });
  }, [searchParams]);

  const fetchAvailablePatterns = async () => {
    console.log(">>> fetchAvailablePatterns");
    const res = await fetchWithAuth(
      PatternAndAssetsApi.getPatternSummariesUrl()
    );
    return await parsePatternsResponse(res);
  };

  const deletePattern = async (pattern: IPattern) => {
    const url = new URL(
      PatternAndAssetsApi.deletePatternUrl(pattern.patternId)
    );

    const response = await fetchWithAuth(url.toString(), {
      method: "DELETE",
    });

    if (response?.ok) {
      setPatterns(patterns.filter((p) => p.patternId !== pattern.patternId));
      setSelectedPatternIds(
        selectedPatternIds.filter((spi) => spi !== pattern.patternId)
      );
    } else console.error("Error deleting pattern: ", pattern.patternId);
  };

  const deletePatterns = async (toDelete: string[]) => {
    console.log(`deletePatterns: ${JSON.stringify(toDelete)}`)
    let errors: string[] = [];
    let successes: string[] = [];

    try {
      setDeleteProgress({text: t("Deleting patterns..."), percent: 0});

      for (let i = 0; i < toDelete.length; ++i) {
        const patternIdToDelete = toDelete[i];

        const url = new URL(PatternAndAssetsApi.deletePatternUrl(patternIdToDelete));

        const response = await fetchWithAuth(url.toString(), {
          method: "DELETE",
        });

        if (response?.ok) {
          successes.push(patternIdToDelete);
        } else {
          errors.push(patternIdToDelete);
          console.error("Error deleting pattern: ", patternIdToDelete);
        }

        setDeleteProgress(prevState => prevState ? {
          ...prevState,
          text: `${t("Pattern")} ${patternIdToDelete}`,
          percent: i * 100 / toDelete.length
        } : undefined);
      }

      // ... Update state
      setPatterns(prevState => prevState.filter((p) => successes.find(pid => p.patternId === pid) === undefined));
      setSelectedPatternIds(
          selectedPatternIds.filter((spi) => successes.find(pid => spi === pid) === undefined)
      );
    } finally {
      setDeleteProgress( undefined ) ;
    }

    return [successes, errors] ;
  };

  const fetchSavedSessions = async () => {
    const raw = await fetchWithAuth(UserSessionApi.listUserSessionsUrl());
    const json = await raw?.json();

    // console.log(`>>> fetchSavedSessions: ${JSON.stringify(json)}`) ;
    const sessions: ISavedSession[] = json
      ? (json.content as any[])
          // .filter((item)=>{
          //   console.log(`===> session: ${JSON.stringify(item)}`);
          //   return true ;
          // })
          .map((item) => ({
            getName: () => item.name ?? `Updated ${format(new Date(item.lastModifiedDate), t("LONG_DATE_FORMAT"))}`,
            patternIds: item.patternIds,
            createdTimestamp: new Date(item.createdTimestamp),
            uuid: item.id,
            sessionData: item.viewModelJson
              ? JSON.parse(item.viewModelJson)
              : undefined,
            blockSize: [
              item.blockSizeX,
              item.blockSizeY,
              item.blockSizeZ
            ],
            lastModifiedDate: new Date(item.lastModifiedDate)
          }))
      : [];
    // console.log(`<<< fetchSavedSessions: ${JSON.stringify(json)}`) ;

    setSavedSessions(sessions);
    return sessions;
  };

  const saveSession = async (savedSession: ISavedSession) => {
    const isNewSession = !savedSession.uuid;

    console.log(`===> saveSession: ${JSON.stringify(savedSession)}`);

    const url = new URL(
      isNewSession
        ? UserSessionApi.createUserSessionUrl()
        : UserSessionApi.updateUserSessionUrl(savedSession.uuid ?? "")
    );

    const response = await fetchWithAuth(url.toString(), {
      method: isNewSession ? "POST" : "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: savedSession.getName(),
        patternIds: savedSession.patternIds,
        viewModelJson: JSON.stringify(savedSession.sessionData),
        // blockSize: savedSession.blockSize
      }),
    });

    let result = isNewSession
      ? (await response?.json())?.id
      : savedSession.uuid;

    await fetchSavedSessions();

    setStashedSession(undefined);

    // console.log("saveSession: "+ JSON.stringify(json)) ;

    return result; //isNewSession ? json.id : savedSession.uuid;
  };

  const resetSession = () => {
    // console.log("******** resetSession") ;
    setSelectedPatternIds([]);
    setLoadedSession(undefined);
    setStashedSession(undefined);
    navigate("/assets");
  };

  const handleSelectionChange = (change: {
    added: string[];
    removed: string[];
  }) => {
    const withRemovedPatterns = selectedPatternIds.filter(
      (p) => !change.removed.includes(p)
    );
    const withAddedPatterns = [...withRemovedPatterns, ...change.added];
    setSelectedPatternIds(withAddedPatterns);

    // Todo: we can maintain the savedSessions object in a dictionary keyed on the uuid instead
    if (loadedSession) {
      loadedSession.patternIds = withAddedPatterns;

      const sessionInSavedSessions = savedSessions.find(
        (s) => s.uuid === loadedSession.uuid
      );

      if (sessionInSavedSessions) {
        sessionInSavedSessions.patternIds = withAddedPatterns;
      }
    }
  };

  const handleUploadFiles = async (evt: React.ChangeEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (!evt.target.form) return;

    setUploadProgress({ text: t("Uploading files..."), percent: 0 });

    let inputFiles = document.getElementById('files') as HTMLInputElement ;
    if ( !inputFiles ) throw new Error("Couldn't find the files input element");

    const responses = await uploadWithAuth_v2(
      PatternAndAssetsApi.uploadAssetsUrlV2(),
      // evt.currentTarget,
      inputFiles,
      (progress, assetName) => {
        // console.log(`uploadWithAuth: ${progress * 100}`);
        setUploadProgress(prevState => prevState ? { ...prevState, text: assetName, percent: progress * 100 } : undefined );
      }
    );
    // console.log(`handleUploadFiles: body=${JSON.stringify(responses)}`);
    const messages: any[] = [];
    responses.forEach( response => {
      console.log(`handleUploadFiles: response=${response.body}`);
      const json = JSON.parse(response.body);
      (json.messages as any[]).forEach(m=>messages.push(m));
    })
    // const json = JSON.parse(response.body);
    // const messages = json.messages as any[];
    setUploadResult(
      messages.map(
        (message, idx) =>
          ({
            subject: message.subject,
            message: message.message,
            isError: message.messageType === "Error",
          } as IUploadResult)
      )
    );
    const uploadedPatterns = await fetchAvailablePatterns();
    setPatterns(uploadedPatterns);
    setUploadProgress(undefined);

    // ... The following is needed to allow the page to resubmit w/o having to refresh the page
    let el: HTMLInputElement | undefined =
      (document.getElementById("files") as HTMLInputElement) ?? undefined;
    if (el) {
      el.value = "";
    }
  };

  const loadSession = (sessionToLoad: ISavedSession, patterns: IPattern[]) => {
    // setLoadedSession({ ...sessionToLoad });
    setLoadedSession(sessionToLoad);
    setStashedSession({ ...sessionToLoad });
    setSelectedPatternIds(
      patterns
        .filter((p) => sessionToLoad.patternIds.includes(p.patternId))
        .map((p) => p.patternId)
    );
  };

  const handleDeleteSession = async (sessionToDelete: ISavedSession) => {
    if (!sessionToDelete.uuid) {
      console.warn("Can't delete session without a uuid");
      return;
    }

    const response = await fetchWithAuth(
      UserSessionApi.getUserSessionsByIdUrl(sessionToDelete.uuid),
      {
        method: "DELETE",
      }
    );

    if (response?.ok) {
      if (sessionToDelete.uuid === loadedSession?.uuid) {
        resetSession();
      }
      setSavedSessions(
        savedSessions.filter((s) => s.uuid !== sessionToDelete.uuid)
      );
    } else {
      console.error(
        response ?? "no response (hints at an expired refresh token)"
      );
    }
    setSessionToDelete(undefined);
  };

  const sortedSessions = [...savedSessions].sort(
    (a, b) => b.lastModifiedDate.getTime() - a.lastModifiedDate.getTime()
  );

  const areEqual = (
    session1: ISavedSession | undefined,
    session2: ISavedSession | undefined
  ): boolean => {
    if (session1 == session2) return true;
    if (!session1 || !session2) return false;
    if (session1.patternIds.length !== session2.patternIds.length) return false;

    // If both sessions have the same number of patterns,
    // we verify that every pattern in session 1 is in session 2
    return session1.patternIds.every((p) => session2.patternIds.includes(p));
  };

  const findMatchingSession = (session: ISavedSession): ISavedSession[] => {
    let result: ISavedSession[] = [];
    for (let savedSessionIdx in savedSessions) {
      let savedSession = savedSessions[savedSessionIdx];
      if (areEqual(session, savedSession)) {
        result.push(savedSession);
      }
    }
    return result;
  };

  const getSessionSummary = (session: ISavedSession): string | undefined => {
    return `${session.getName()} (${session.patternIds.join(", ")})`;
  };

  const openExistingSession = (session: ISavedSession | undefined) => {
    if (session) {
      // console.log("You selected: " + JSON.stringify(session));
      navigate(
        `/viewer?patternId=${session.patternIds.join(",")}&sessionUuid=${
          session.uuid
        }&sessionName=${session.getName()}`
      );
      // } else {
      //   console.log("openSelectedSession: " + JSON.stringify(session) ) ;
    }
  };

  const openNewSession = async (session: ISavedSession | undefined) => {
    // console.log(">>> openNewSession: " + JSON.stringify(session));
    if (session) {
      session.uuid = undefined ;
      session.uuid = await saveSession(session);
      navigate(
        `/viewer?patternId=${session.patternIds.join(",")}&sessionUuid=${
          session.uuid
        }&sessionName=${session.getName()}`
      );
    }
  };

  function onRevertLoadedSession(s: ISavedSession) {
    if (stashedSession) {
      console.log(
        `s.patternIds=${s.patternIds}, stashedSession.patternIds=${stashedSession.patternIds}`
      );
      s.patternIds = stashedSession.patternIds;
      resetSession();
    }
  }

  const leftHandTitledSectionHeight = useMemo(()=>{
    if ( roundedPanelSize?.height ) {
      return roundedPanelSize.height - 2 * MARGIN_HEIGHT;
    } else {
      return undefined;
    }
  }, [roundedPanelSize?.height]);

  const patternListMaxHeight = useMemo(()=>{
    if ( leftHandTitledSectionHeight ) {
      return leftHandTitledSectionHeight - 4 * MARGIN_HEIGHT - 2 * TITLE_BAR_HEIGHT;
    } else {
      return undefined;
    }
  }, [leftHandTitledSectionHeight]);

  /**
   * Patterns associated with a sessions to delete
   */
  const associatedPatterns = useMemo(()=>{
    if ( !patternIdsToDelete || patternIdsToDelete.length === 0) {
      return '';
    }
    const result = savedSessions
        .filter( s => {
          // ... Keep sessions that include the patterns to delete
          return patternIdsToDelete.find( patternIdToDelete => s.patternIds.indexOf(patternIdToDelete) !== -1 ) !== undefined ;
          // return s.patternIds.indexOf(patternsToDelete.patternId) !== -1;
        })
        .map(s=>s.getName())
        .join("; ") ;
    if ( !result || result.length === 0 ) {
      return '';
    }
    return `(${result})`;
  }, [patternIdsToDelete, savedSessions])

  async function handleDeleteAssociatedSessions(pidsToDelete: string[] | undefined) {

    if ( !pidsToDelete ) return ;

    // ... Delete sessions that contain any of the given pattern IDs
    let sessionsToDelete = savedSessions
        .filter(s=> {
          return (!(!s.uuid)) && pidsToDelete.find( pidToDelete => s.patternIds.indexOf(pidToDelete) !== -1 ) !== undefined
        } );

    let deletedSessions: string[] = [];

    for ( let i = 0; i < sessionsToDelete.length; ++i ) {
      const s = sessionsToDelete[i];
      if ( !s.uuid ) continue;
      const response = await fetchWithAuth( UserSessionApi.getUserSessionsByIdUrl(s.uuid), { method: "DELETE" } );
      if (response?.ok) {
        deletedSessions.push(s.uuid);
      }
    }

    if ( deletedSessions.find( suuid => suuid === loadedSession?.uuid ) ) {
      resetSession();
    }

    setSavedSessions( prevState => prevState.filter( s => !s.uuid || deletedSessions.indexOf(s.uuid) === -1));
  }

  return (
    <>
      <TooltipContextProvider>
      <ContentPage>
        {/* Left hand */}
        <ColumnPane style={{width: "calc(100% - 455px)"}}>

          {/* Rounded panel */}
          <RoundedPanel>
            <TitledSection
                style={{height: leftHandTitledSectionHeight ? `${leftHandTitledSectionHeight}px`:undefined}}
                w3BorderColor={'w3-border-light-blue'}
                title={t("Available Patterns")}
                onHelpClicked={()=>setHelpRequested({
                  helpTitle: t('available_patterns'),
                  helpContent: <Help_PatternExplorer_AvailablePatterns/>,
                  width: "50vw",
                })}
                titleExtra={
                  <form encType="multipart/form-data" onChange={handleUploadFiles} >
                    <input id="files" type="file" name="file" accept=".json, .i3dm, .b3dm, .glb" multiple style={{ display: "none" }} />
                    <label htmlFor="files">
                    <span className="w3-button w3-hover-shadow w3-hover-black w3-theme-d3 w3-round-medium w3-small">
                      <FontAwesomeIcon icon={faUpload} className="w3-margin-right" />
                      {t("import_files")}
                    </span>
                    </label>
                  </form>
                }
            >

              { (uploadProgress === undefined) && <PatternsUpdatedWarning existingPatterns={patterns} /> }

              <PatternList
                  availablePatterns={patterns}
                  selectedPatternIds={selectedPatternIds}
                  onSelectionChange={handleSelectionChange}
                  filter={currentFilter}
                  onPatternActiveStateChanged={() => {
                    setCurrentFilter({ ...currentFilter });
                  }}
                  onPatternDeleteRequested={(p)=>setPatternIdsToDelete([p.patternId])}
                  style={{overflowY: "auto", maxHeight: patternListMaxHeight ? `${patternListMaxHeight}px`: '100%'}}
              />

              <CurrentSession
                  style={{position: "absolute", left: "56px", bottom: "48px"}}
                  patterns={patterns.filter((p) =>
                      selectedPatternIds.includes(p.patternId)
                  )}
                  session={loadedSession}
                  onSave={(s) => {
                    saveSession(s);
                  }}
                  onReset={(s) => {
                    if (loadedSession && loadedSession?.uuid === s.uuid) {
                      onRevertLoadedSession(s);
                    } else {
                      resetSession();
                    }
                  }}
                  onDelete={(s) => {
                    setPatternIdsToDelete(s.patternIds);
                  }}
                  onView={async (s) => {
                    // if (!s.uuid) {
                      // ... Check for duplicates
                      let matchingSessions: ISavedSession[] = findMatchingSession(s);

                      if (matchingSessions.length > 0) {
                        // ... there are very similar sessions already, so let's give the user the option to pick one
                        // of them.
                        setConfirmMatchingSession({
                          showModal: true,
                          default: s,
                          matches: matchingSessions,
                        });
                      } else {
                        // ... This is a whole new session
                        await openNewSession(s);
                      }
                    // } else {
                    //   openExistingSession(s);
                    // }
                  }}
              />
              {confirmMatchingSession.showModal &&
                  ((confirmMatchingSession.matches.length >= 1 && (
                      <SelectionDialog
                          key="SelectAmongMatchingSessions"
                          title="Similar sessions already exist"
                          selection={0}
                          choices={[...confirmMatchingSession.matches.map((item) =>
                              getSessionSummary(item)
                          ), t("Create a new session")]}
                          prompt="Please select an existing session"
                          onAccept={(selection) => {
                            console.log("YOU SELECTED: " + selection);
                            if (selection !== undefined) {
                              if ( selection < confirmMatchingSession.matches.length ) {
                                openExistingSession(
                                    confirmMatchingSession.matches[selection]
                                );
                              } else {
                                openNewSession(confirmMatchingSession.default)
                                    .then( () => {
                                      setConfirmMatchingSession({
                                        showModal: false,
                                        matches: [],
                                        default: undefined,
                                      });
                                    })
                              }
                            }
                            setConfirmMatchingSession({
                              showModal: false,
                              matches: [],
                              default: undefined,
                            });
                          }}
                          onCancel={() => {
                            setConfirmMatchingSession({
                              showModal: false,
                              matches: [],
                              default: undefined,
                            });
                          }}
                      />
                  )))}
            </TitledSection>
          </RoundedPanel>
        </ColumnPane>

        {/* Right hand */}
        <ColumnPane style={{paddingLeft: "0", width: "455px"}}>
          <RoundedPanel
              onSizeChanged={(w,h)=>setRoundedPanelSize({width: w, height: h})}
          >
            <CollapsibleSection
                title={t('Filter Patterns')}
                collapsed={true}
                onHelpClicked={()=>{
                  setHelpRequested({
                    helpTitle: t('filter_patterns'),
                    helpContent: <Help_PatternExplorer_FilterPatterns/>,
                    width: "50vw",
                  })
                }}
                onSizeChanged={(w,h)=>setPatternFiltersSize({width: w, height: h})}
                maxHeight={maxHeights?.patternFilters}
            >
              <FiltersContainer onFilterChanged={(f) => setCurrentFilter(f)} />
              {/*<div className={"w3-grey"} style={{height:"2048px", width:"100%"}}/>*/}

            </CollapsibleSection>

            <CollapsibleSection
                className={"w3-margin-top"}
                title={t('Recent Sessions')}
                onHelpClicked={()=>{
                  setHelpRequested({
                    helpTitle: t('recent_sessions'),
                    helpContent: <Help_PatternExplorer_RecentSessions/>,
                    width: "50vw",
                  })
                }}
                onSizeChanged={(w,h)=>setSavedSessionsSize({width: w, height: h})}
                maxHeight={maxHeights?.savedSessions}
            >
              {
                (savedSessions.length > 0)
                  ? (<div className={'flex-container'} style={{height: "100%"}}>
                        {sortedSessions.map((s) => (
                            <SavedSession
                                key={s.uuid}
                                // style={{ marginRight: "10px", marginTop: "10px" }}
                                selected={loadedSession?.uuid === s.uuid}
                                savedSession={s}
                                patterns={patterns.filter((pattern) => {
                                  // console.log(`--- pattern=${JSON.stringify(pattern)}`) ;
                                  return s.patternIds.indexOf(pattern.patternId) != -1;
                                })}
                                onLoad={(s) => {
                                  if ( s !== undefined ) {
                                    navigate(`/assets?sessionUuid=${s.uuid}`);
                                    loadSession(s, patterns);
                                  } else {
                                    resetSession();
                                  }
                                }}
                                onDelete={(s) => {
                                  setSessionToDelete(s);
                                }}
                                dirty={
                                    stashedSession !== undefined &&
                                    !areEqual(stashedSession, loadedSession)
                                }
                                onSave={(s) => {
                                  saveSession(s);
                                }}
                                onCancelModifications={(s) => {
                                  onRevertLoadedSession(s);
                                }}
                            />
                        ))
                      }
                    </div>)

                    : (<div className={'w3-center w3-opacity-min'}><i>{t("no_sessions_available")}</i></div>)
              }
            </CollapsibleSection>
          </RoundedPanel>
        </ColumnPane>
      </ContentPage>
      </TooltipContextProvider>

      {sessionToDelete && (
        <ConfirmationDialog
          key="ConfirmDelete"
          title={t("confirmation_required")}
          prompt={`${t("delete_session")} '${sessionToDelete.getName()}' ?`}
          onAccept={() => {
            handleDeleteSession(sessionToDelete);
          }}
          onCancel={async () => {
            setSessionToDelete(undefined);
          }}
        />
      )}
      {patternIdsToDelete && (
        <ConfirmationDialog
          key="ConfirmDelete"
          title={t("confirmation_required")}
          prompt={`${t("Delete patterns")} '${patternIdsToDelete.join(", ")}' ?`}
          onAccept={() => {
            deletePatterns(patternIdsToDelete)
              .then(([successes, errors]) => {

                if ( deleteAssociatedSessions ) {
                  handleDeleteAssociatedSessions(patternIdsToDelete)
                      .then(()=>{})
                  setDeleteAssociatedSessions( false );
                }
                setPatternIdsToDelete(undefined);
                errors?.length > 0 && console.error(`Patterns NOT successfully deleted: ${errors.join(", ")}`);
              })
              .catch(() =>
                console.error(
                  `Failed to delete Patterns ${patternIdsToDelete.join(", ")}`
                )
              );
          }}
          onCancel={async () => {
            setPatternIdsToDelete(undefined);
          }}
          extra={
            <span>
              <input
                  id={'alsoDeletedSessions'}
                  className={"w3-check w3-padding-small"}
                  type="checkbox"
                  onChange={(e)=>{
                    setDeleteAssociatedSessions( e.target.checked );
                  }}
              />
              <label htmlFor={'alsoDeletedSessions'} className={"w3-padding-small"} >
                {
                  `${t("Delete associated sessions")} ${associatedPatterns}?`
                }
              </label>
            </span>
          }
        />
      )}
      {uploadProgress && (
        <ProgressDialog
          title={t("Uploading assets...")}
          tasks={[uploadProgress]}
          onDismiss={() => setUploadProgress(undefined)}
        />
      )}
      {uploadResult && (
        <UploadResultDialog
          uploadResults={uploadResult}
          onDismiss={() => {
            setUploadResult(undefined);
          }}
        />
      )}
      {deleteProgress && (
          <ProgressDialog
              title={`${t("Deleting patterns")}...`}
              tasks={[deleteProgress]}
              // onDismiss={() => setDeleteProgress(undefined)}
          />
      )}
    </>
  );
};

export default withTranslation()(Assets);
