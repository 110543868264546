import {MutableRefObject, useEffect} from "react";

const useResizeObserver = (ref: MutableRefObject<HTMLDivElement|null>, callback: ()=>void) => {
  useEffect(()=>{
    // console.log(`+ userResizeObserver`);
    if ( !ref?.current ) {
      return ;
    }
    const draggableDiv: HTMLDivElement = ref.current;
    if ( !draggableDiv.parentElement ) {
      return ;
    }
    const parentDiv: HTMLElement =  draggableDiv.parentElement ;

    const resizeObserver = new ResizeObserver(callback);
    if (parentDiv) {
      resizeObserver.observe(parentDiv);
      return () => {
        // console.log(`- userResizeObserver`);
        resizeObserver.unobserve(parentDiv);
      };
    }
  }, [ref/*, callback*/]);
}

export default useResizeObserver ;
