import {ISiteConfigurationDto} from "../model/ISiteConfigurationDto";
import {ISiteConfiguration} from "../domain/ISiteConfiguration";
import {Cartesian3, Matrix3} from "cesium";
import {IAttributeTypeMap, SELECTABLE_ATTRIBUTES_KEYS} from "../domain/AttributeType";
import ColorLegendConfigMapper from "./ColorLegendConfigMapper";
import {LayerType} from "../../../model/LayerType";
import IColorLegendConfiguration from "../domain/IColorLegendConfiguration";

export default class SiteConfigurationMapper {
  /**
   * White list of assets layers (doesn't include all named layers)
   */
  private static readonly LAYER_ALLOW_LIST: LayerType[] = [
    LayerType.BlockModel, // for Block model
    LayerType.BlastholeSecondarySegments, // for Blastholes
    LayerType.BlastholeSingleSegment, // for Collars
    // LayerType.DrilledHolesContour, // for pattern volume
    LayerType.Boundary, // for pattern boundary
    // LayerType.BlockVolume, // not used
    // LayerType.DrilledHoleCollars, // misnomer; point cloud
    // LayerType.Undefined, // undefined
    LayerType.BlastholeClusters,
    LayerType.BlastholeFractures,
    LayerType.CrossSectionX,
    LayerType.CrossSectionY,
    LayerType.CrossSectionZ,
  ];

  public static siteConfigToSiteConfigDto( siteConfig: ISiteConfiguration ): ISiteConfigurationDto {

    const _colorLegendConfig: IAttributeTypeMap<any> = {
      CoalProbability:  ColorLegendConfigMapper.colorLegendConfigToColorLegendConfigDto( siteConfig.legendConfigs.CoalProbability ),
      BI:               ColorLegendConfigMapper.colorLegendConfigToColorLegendConfigDto( siteConfig.legendConfigs.BI ),
      FRF:              ColorLegendConfigMapper.colorLegendConfigToColorLegendConfigDto( siteConfig.legendConfigs.FRF ),
      Hardness:         ColorLegendConfigMapper.colorLegendConfigToColorLegendConfigDto( siteConfig.legendConfigs.Hardness ),
      Other:            ColorLegendConfigMapper.colorLegendConfigToColorLegendConfigDto( siteConfig.legendConfigs.Other ),
      Prominence:       ColorLegendConfigMapper.colorLegendConfigToColorLegendConfigDto( siteConfig.legendConfigs.Prominence ),
      CBI:              ColorLegendConfigMapper.colorLegendConfigToColorLegendConfigDto( siteConfig.legendConfigs.CBI ),
      ROP:              ColorLegendConfigMapper.colorLegendConfigToColorLegendConfigDto( siteConfig.legendConfigs.ROP ),
      SED:              ColorLegendConfigMapper.colorLegendConfigToColorLegendConfigDto( siteConfig.legendConfigs.SED )
    };

    return {
      version: siteConfig.version,
      translation: [siteConfig.translation.x, siteConfig.translation.y, siteConfig.translation.z],
      rotation: [
        siteConfig.rotation[0],
        siteConfig.rotation[3],
        siteConfig.rotation[6],

        siteConfig.rotation[1],
        siteConfig.rotation[4],
        siteConfig.rotation[7],

        siteConfig.rotation[2],
        siteConfig.rotation[5],
        siteConfig.rotation[8],
      ],
      colorLegendConfig: _colorLegendConfig,
      //     Array.from( siteConfig.legendConfigs.entries() ).map( ([k,v]) => {
      //   let dto = ColorLegendConfigMapper.colorLegendConfigToColorLegendConfigDto( v ) ;
      //   return [k, dto ];
      // }),
      layerTypes: siteConfig.layerConfigs.map( layerConfig => LayerType[layerConfig.layerType] ),
      showInvalidColorConfig: siteConfig.showInvalidColorConfig
    } as ISiteConfigurationDto;
  }
  public static siteConfigDtoToSiteConfig( siteConfigDto: ISiteConfigurationDto ): ISiteConfiguration {
    const _availableAttributes = Object.keys( siteConfigDto.colorLegendConfig )
        .map( k => k as keyof IAttributeTypeMap<string> );

    const _legendConfigs: IAttributeTypeMap<IColorLegendConfiguration> = {
      CoalProbability:  ColorLegendConfigMapper.colorLegendConfigDtoToColorLegendConfig( siteConfigDto.colorLegendConfig.CoalProbability ),
      BI:               ColorLegendConfigMapper.colorLegendConfigDtoToColorLegendConfig( siteConfigDto.colorLegendConfig.BI ),
      FRF:              ColorLegendConfigMapper.colorLegendConfigDtoToColorLegendConfig( siteConfigDto.colorLegendConfig.FRF ),
      Hardness:         ColorLegendConfigMapper.colorLegendConfigDtoToColorLegendConfig( siteConfigDto.colorLegendConfig.Hardness ),
      Other:            ColorLegendConfigMapper.colorLegendConfigDtoToColorLegendConfig( siteConfigDto.colorLegendConfig.Other ),
      Prominence:       ColorLegendConfigMapper.colorLegendConfigDtoToColorLegendConfig( siteConfigDto.colorLegendConfig.Prominence ),
      CBI:              ColorLegendConfigMapper.colorLegendConfigDtoToColorLegendConfig( siteConfigDto.colorLegendConfig.CBI ),
      ROP:              ColorLegendConfigMapper.colorLegendConfigDtoToColorLegendConfig( siteConfigDto.colorLegendConfig.ROP ),
      SED:              ColorLegendConfigMapper.colorLegendConfigDtoToColorLegendConfig( siteConfigDto.colorLegendConfig.SED )
    };

    return {
      version: siteConfigDto.version,
      translation: Cartesian3.fromArray(siteConfigDto.translation),
      rotation: Matrix3.fromRowMajorArray(siteConfigDto.rotation),
      availableAttributes: _availableAttributes,
      selectableAttributes: SELECTABLE_ATTRIBUTES_KEYS,
      legendConfigs: _legendConfigs,
      layerConfigs: siteConfigDto.layerTypes
          .map( s => LayerType[ s as keyof typeof LayerType ] )
          .filter( lt => this.LAYER_ALLOW_LIST.indexOf( lt ) !== -1 )
          .map( lt => ({ label: LayerType[ lt ], layerType: lt }) ),

      showInvalidColorConfig: siteConfigDto.showInvalidColorConfig
    } as ISiteConfiguration;
  }
}
