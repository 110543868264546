import React, {CSSProperties, useEffect} from "react";
import useResizeObserver from "use-resize-observer";

interface IColumnPaneProps {
  className?:string|undefined;
  style?:CSSProperties|undefined;
  w3BorderColor?:string|undefined;
  onSizeChanged?:(w: number, h: number)=>void|undefined;
}

const ColumnPane: React.FC<IColumnPaneProps> = (props)=>{
  const w3BorderColor = props.w3BorderColor ? `w3-border ${props.w3BorderColor}`: '';

  const {ref, width=1, height=1} = useResizeObserver<HTMLDivElement>();

  useEffect(()=>{
    if ( width && height ) {
      props.onSizeChanged && props.onSizeChanged( width, height );
    }
  }, [width, height]);

  return (
      <>
        <div
            ref={ref}
            className={`w3-container w3-col w3-padding-16 ${props.className} ${w3BorderColor}`}
            style={{ ...props.style, height: "100%" }}
        >
          {props.children}
        </div>
      </>
  )
}

export default ColumnPane ;
