import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

import TextAndImage from "./Widgets/TextAndImage";
import VideoClip from "./Widgets/VideoClip";
import {VideoResourceUrl} from "../lib/VideoResourceUrl";


/**
 * Section Viewer side menu help
 *
 * @param props
 * @constructor
 */

const Help_CesiumView_BottomToolbar: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <>
        <div className={'w3-panel'}>
          <h2 className={"w3-left-align"}>{t("Using the Bottom Toolbar")}</h2>
          <p className={"text-full-justify"}>
            {t("The bottom toolbar, which can be enabled or disabled from the 'View' side menu, provides quick access " +
                "to the 'View' submenu's settings.")}
          </p>

          <VideoClip
              src={VideoResourceUrl("bottom_toolbar_interactive.webm")}
              className={`w3-col w3-block w3-padding`}/>
        </div>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Pinning the Mouse-Keyboard Hints"}
                      text={"Toggle the keyboard/mouse hints to pin the hints to the right-hand side of the toolbar."}
                      src={VideoResourceUrl("bottom_toolbar_hints.webm")}
                      imgRatio={"w3-half"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Context-Specific Keyboard Hints"}
                      text={"When mouse/keyboard hints are enabled, the right-hand side of the bottom toolbar " +
            "will display a quick reference for keyboard and mouse controls that are applicable to whatever you are " +
            "currently doing.  For example, if you are viewing sections, keyboard shortcuts will be displayed. Also, " +
            "the keyboard shortcuts will respond to mouse clicks"}
                      src={VideoResourceUrl("bottom_toolbar_interactive.webm")}
                      imgRatio={"w3-half"}/>
      </>
  )
}

export default withTranslation()(Help_CesiumView_BottomToolbar);

