import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { useState } from "react";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import { useFetchWithAuth } from "../../lib/auth/fetchWithAuth";
import { logoutCallback } from "../../lib/auth/useLogoutOnClose";

const LogoutComponent: React.FC = () => {
  const navigate = useNavigate();

  const [isLogoutModalShown, setLogoutModalShown] = useState(false);
  const fetchWithAuth = useFetchWithAuth();

  const handleLogout = async () => {
    logoutCallback();

    navigate("/login");
  };

  return (
    <>
      <button
        className="w3-bar-item w3-button w3-right"
        onClick={async () => {
          setLogoutModalShown(true);
        }}
      >
        {t("logout")}
      </button>
      {isLogoutModalShown && (
        <ConfirmationDialog
          key="confirm_logout"
          title={t("logout")}
          prompt={t("are_you_sure")}
          onAccept={handleLogout}
          onCancel={() => setLogoutModalShown(false)}
        ></ConfirmationDialog>
      )}
    </>
  );
};

export default withTranslation()(LogoutComponent);
