export interface RockMassDomainRange {
  Name: string;
  min: number;
  max: number;
}

function compareRockMassDomainRanges( r1: RockMassDomainRange, r2: RockMassDomainRange ): boolean {
  if ( r1.Name !== r2.Name ) return false ;
  if ( r1.min !== r2.min ) return false ;
  if ( r1.max !== r2.max ) return false ;
  return true ;
}

export function compareRockMassDomains( d1: Map<string,RockMassDomainRange[]>, d2: Map<string,RockMassDomainRange[]> ): boolean {
  if ( d1.size != d2.size ) {
    return false ;
  }
  let result = true ;

  d1.forEach( (v, k) => {
    if (result) { // if mismatch not yet found
      let v2 = d2.get(k);
      if (v2 && v2.length == v.length ) {
        for ( let i = 0; i < v.length && result; i += 1 ) {
          if ( !compareRockMassDomainRanges( v[i], v2[i] ) ) {
            result = false;
          }
        }
      } else {
        result = false;
      }
    }
  }) ;

  return result ;
}