import {IHelpTopic} from "./Help_Navigator";
import {faFolderOpen, faGears, faMapLocationDot, faUserGear} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Help_PatternExplorer from "./Help_PatternExplorer";
import Help_SiteConfig from "./Help_SiteConfig";
import Help_CesiumView from "./Help_CesiumView";
import Help_PatternExplorer_AvailablePatterns from "./Help_PatternExplorer_AvailablePatterns";
import Help_PatternExplorer_FilterPatterns from "./Help_PatternExplorer_FilterPatterns";
import Help_PatternExplorer_RecentSessions from "./Help_PatternExplorer_RecentSessions";
import Help_SiteConfig_BaseTransformation from "./Help_SiteConfig_BaseTransformation";
import Help_SiteConfig_ColorLegend from "./Help_SiteConfig_ColorLegend";
import Help_CesiumView_SideMenu_View from "./Help_CesiumView_SideMenu_View";
import Help_CesiumView_SideMenu_Filters from "./Help_CesiumView_SideMenu_Filters";
import Help_CesiumView_SideMenu_Layers from "./Help_CesiumView_SideMenu_Layers";
import Help_CesiumView_SideMenu_Legend from "./Help_CesiumView_SideMenu_Legend";
import Help_CesiumView_SideMenu_SectionViewer from "./Help_CesiumView_SideMenu_SectionViewer";
import Help_CesiumView_FloatingColorLegend from "./Help_CesiumView_FloatingColorLegend";
import Help_CesiumView_BottomToolbar from "./Help_CesiumView_BottomToolbar";

const LOREM_IPSUM = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

const showSubTopics = true;

export const help_GettingStartedTopics: IHelpTopic[] = [
  { helpTitle: "Using the Patterns Explorer", helpContent: <Help_PatternExplorer />, icon: faFolderOpen, showWhenEmptySearchQuery: true },
  { helpTitle: "List of available patterns",  helpContent: <Help_PatternExplorer_AvailablePatterns />, }, { helpTitle: "Filtering the available patterns", helpContent: <Help_PatternExplorer_FilterPatterns /> },
  { helpTitle: "Recent work sessions",        helpContent: <Help_PatternExplorer_RecentSessions />, showWhenEmptySearchQuery: showSubTopics },
  { helpTitle: "Site Configuration",          helpContent: <Help_SiteConfig />, icon: faGears, showWhenEmptySearchQuery: true },
  { helpTitle: "Site Base Transformation",    helpContent: <Help_SiteConfig_BaseTransformation />, showWhenEmptySearchQuery: showSubTopics },
  { helpTitle: "Site Color Legend Settings",  helpContent: <Help_SiteConfig_ColorLegend />, showWhenEmptySearchQuery: showSubTopics },
  { helpTitle: "Viewing in 3D",               helpContent: <Help_CesiumView/>, icon: faMapLocationDot, showWhenEmptySearchQuery: true },
  { helpTitle: "3D View Settings",            helpContent: <Help_CesiumView_SideMenu_View/>, showWhenEmptySearchQuery: showSubTopics },
  { helpTitle: "3D Pattern Filters",          helpContent: <Help_CesiumView_SideMenu_Filters/>, showWhenEmptySearchQuery: showSubTopics },
  { helpTitle: "3D Layer Filters",            helpContent: <Help_CesiumView_SideMenu_Layers/>, showWhenEmptySearchQuery: showSubTopics },
  { helpTitle: "3D Color Legend",             helpContent: <Help_CesiumView_SideMenu_Legend/>, showWhenEmptySearchQuery: showSubTopics },
  { helpTitle: "3D Section Viewer",           helpContent: <Help_CesiumView_SideMenu_SectionViewer/>, showWhenEmptySearchQuery: showSubTopics },
  { helpTitle: "3D Floating Color legend",    helpContent: <Help_CesiumView_FloatingColorLegend/>, showWhenEmptySearchQuery: showSubTopics },
  { helpTitle: "3D View Bottom Toolbar",      helpContent: <Help_CesiumView_BottomToolbar/>, showWhenEmptySearchQuery: showSubTopics },

  { helpTitle: "Managing Users",              helpContent:
        <div>
          <h1>Placeholder - Managing Users</h1>
          <p>{LOREM_IPSUM}</p>
        </div>,
    icon: faUserGear,
    showWhenEmptySearchQuery: true
  }
];
