import IPattern from "../../model/IPattern";

export function arrayEquals<T>(a: T[], b: T[]) {
  return a.length === b.length && a.every((val, index) => val === b[index]);
}

export function patternEquals(a: IPattern, b: IPattern) {
  // we compare the sorted arrays, since we're not guaranteed order of layers and tags.
  const arePatternsIdentical =
    a.end.getTime() === b.end.getTime() &&
    a.start.getTime() === b.start.getTime() &&
    a.name === b.name &&
    a.patternId === b.patternId &&
    arrayEquals([...a.layers].sort(), [...b.layers].sort()) &&
    arrayEquals([...a.tags].sort(), [...b.tags].sort());

  return arePatternsIdentical;
}
