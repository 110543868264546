import React, {useMemo} from "react";
import {ArcType, Cartesian3, Color, Matrix4} from "cesium";
import {Entity, PolylineGraphics} from "resium";
import {TilesetUtils} from "../../util/TilesetUtils";
import {withTranslation, WithTranslation} from "react-i18next";

export interface ISegment {
  p1: Cartesian3;
  p2: Cartesian3;
}

interface IProps extends WithTranslation {
  name:string;
  show: boolean;
  modelMatrix: Matrix4;
  segments: ISegment[];
  color?: Color;
  lineWidth?: number;
}

const ParallelLineSegments: React.FC<IProps> = (props) => {
  const t = props.t;

  const transformedSegments = useMemo(()=>
      props.segments.map( seg => ({
        p1:TilesetUtils.localToWorld(seg.p1, props.modelMatrix),
        p2:TilesetUtils.localToWorld(seg.p2, props.modelMatrix)
      } as ISegment)
  ), [props.segments]);

  return (
      <>
        {props.show && (
          transformedSegments.map( (segment, idx) => (
            <Entity
              key={`${props.name}_${idx}`}
              name={t(props.name)}
              show={props.show}
            >
              {/*Encapsulating rectangle*/}
              <PolylineGraphics
                positions={[segment.p1, segment.p2]}
                width={props.lineWidth}
                material={props.color}
                arcType={ArcType.NONE}
              />
            </Entity>
          ))
        )}
      </>
  )
}

export default withTranslation()(ParallelLineSegments);
