import { format, parse } from "date-fns";
import { useEffect, useState } from "react";
import { DateRange, RangeKeyDict } from "react-date-range";
import { WithTranslation, withTranslation } from "react-i18next";
import { usePopper } from "react-popper";

const RANGE_KEY = "selection";

interface Props extends WithTranslation {
  startDate: string;
  endDate: string;
  onChange: (startDate: string, endDate: string) => void;
}

const MultiDateFilter: React.FC<Props> = ({
  startDate,
  endDate,
  onChange,
  t,
}) => {
  // A bit quirky, but straight from the docs: https://popper.js.org/react-popper/v2/
  const [referenceElt, setReferenceElt] = useState<any>(null);
  const [popperElt, setPopperElt] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElt, popperElt, {
    placement: "bottom-start",
  });

  const [isDateRangeOpen, setDateRangeOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleEventOutside, true);
    return () => {
      document.removeEventListener("click", handleEventOutside, true);
    };
  });

  const handleEventOutside = (event: any) => {
    if (popperElt && !popperElt.contains(event.target)) {
      setDateRangeOpen(false);
    }
  };

  const showDateRange = () => {
    setDateRangeOpen(true);
  };

  const onRangeChange = (change: RangeKeyDict) => {
    const start = change[RANGE_KEY].startDate
      ? format(change[RANGE_KEY].startDate ?? 0, t("SHORT_DATE_FORMAT"))
      : "";
    const end = change[RANGE_KEY].endDate
      ? format(change[RANGE_KEY].endDate ?? 0, t("SHORT_DATE_FORMAT"))
      : "";

    onChange(start, end);
  };

  // We need to make sure we pass valid instances of a Date object to the DateRange component
  const rangeStart = parse(startDate, t("SHORT_DATE_FORMAT"), new Date());
  const rangeEnd = parse(endDate, t("SHORT_DATE_FORMAT"), new Date());
  const range = {
    startDate: isNaN(rangeStart.valueOf()) ? new Date() : rangeStart,
    endDate: isNaN(rangeEnd.valueOf()) ? new Date() : rangeEnd,
    key: RANGE_KEY,
  };

  return (
    <>
      <div className="w3-col m6" style={{ padding: "0 5px 5px 0" }}>
        <label htmlFor="startDate">{t("start_date")}</label>
        <input
          autoComplete={"off"}
          className="w3-input w3-border"
          style={{ margin: "5px 0 0 0" }}
          id="startDate"
          value={startDate}
          onClick={showDateRange}
          ref={setReferenceElt}
          onChange={(evt) => onChange(evt.target.value, endDate)}
        />
      </div>
      <div className="w3-col m6" style={{ padding: "0 0 5px 5px" }}>
        <label htmlFor="endDate">{t("end_date")}</label>
        <input
          autoComplete={"off"}
          className="w3-input w3-border"
          style={{ margin: "5px 0 0 0" }}
          id="endDate"
          value={endDate}
          onClick={showDateRange}
          onChange={(evt) => onChange(startDate, evt.target.value)}
        />
      </div>

      {isDateRangeOpen && (
        <div
          ref={setPopperElt}
          style={{
            ...styles.popper,
            padding: 2,
            background: "black",
            zIndex: 1,
          }}
          {...attributes.popper}
        >
          <div>
            <DateRange
              ranges={[range]}
              onChange={(change) => onRangeChange(change)}
              moveRangeOnFirstSelection={false}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(MultiDateFilter);
