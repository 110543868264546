import React, {useCallback, useEffect, useMemo} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {t} from "i18next";

export interface IBasicDialogProps extends WithTranslation {
  title: string;
  width?:string;
  btnLeft?: { label: string, className?: string, action: ()=>void }
  btnCenter?: { label: string, className?: string, action: ()=>void }
  btnRight?: { label: string, className?: string, action: ()=>void }
}

const BORDER_RADIUS = "5px";

const BasicDialog: React.FC<IBasicDialogProps> = (props) => {

  const children = props.children;

  const findButtonAtPosition = useCallback((position: "left"|"center"|"right")=>{
    let result = undefined;
    React.Children.forEach( children, (child, idx) => {
      if (React.isValidElement( child )) {
        if ( child.props.position === position ) {
          result = child ;
        }
      }
    })
    return result ;
  }, [children]);

  const leftButton = useMemo(()=>findButtonAtPosition("left"), [findButtonAtPosition]);
  const centerButton = useMemo(()=>findButtonAtPosition("center"), [findButtonAtPosition]);
  const rightButton = useMemo(()=>findButtonAtPosition("right"), [findButtonAtPosition]);

  return (
    <>
      <div className="w3-modal" style={{ display: "block" }}>
        <div
          className="w3-modal-content w3-theme-d4 w3-card-4"
          style={{
            width: props.width ?? "50vw",
            borderRadius: BORDER_RADIUS,
            maxWidth: "1024px"
          }}
        >
          <header
            className="w3-container w3-light-blue w3-center"
            style={{
              borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0px 0px`,
            }}
          >
            <h6>{t(props.title)}</h6>
          </header>

          <div
              className={'w3-padding'}
              style={{
                maxHeight: "calc(100vh - 250px)",
                overflowX: "auto",
                overflowY: "auto"
              }}
          >
            {React.Children.toArray( props.children )
                .filter( (child) => {
                  return !React.isValidElement( child ) || child.type !== BasicDialogButton;
                })
            }
          </div>

          <footer
            className="w3-container w3-theme-d3 w3-padding w3-show-block"
            style={{
              borderRadius: `0px 0px ${BORDER_RADIUS} ${BORDER_RADIUS}`,
            }}
          >
            <div className={'w3-third'} style={{width: "33%", float: "left"}}>
              {leftButton ? leftButton
                  :
                  <button
                      style={{display: "block", margin:"0 auto"}}
                      className={`w3-btn w3-round-medium w3-left ${props.btnLeft?.className}`}
                      onClick={props.btnLeft?.action}
                  >
                    {props.btnLeft && t(props.btnLeft.label)}
                  </button>
              }
            </div>
            <div className={'w3-third'} style={{width: "33%", float: "left"}}>
              {centerButton ? centerButton
                  :
                  <button
                      style={{display: "block", margin:"0 auto"}}
                      className={`w3-btn w3-round-medium ${props.btnCenter?.className}`}
                      onClick={props.btnCenter?.action}
                  >
                    {props.btnCenter && t(props.btnCenter.label)}
                  </button>
              }
            </div>
            <div className={'w3-third'} style={{width: "33%", float: "right"}}>
              {rightButton ? rightButton
                  :
                  <button
                      style={{display: "block", margin:"0 auto"}}
                      className={`w3-btn w3-round-medium w3-right ${props.btnRight?.className}`}
                      onClick={props.btnRight?.action}
                  >
                    {props.btnRight && t(props.btnRight.label)}
                  </button>
              }
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(BasicDialog) ;

interface IButtonProps {
  label: string;
  position: "left"|"center"|"right";
  disabled?:boolean|undefined;
  onClick?: (()=>void|undefined);
  className?:string|undefined;
}
export const BasicDialogButton: React.FC<IButtonProps> = (props)=>{

  const classExtra = useMemo(()=>{
    switch( props.position ) {
      case "left":
        return 'w3-left';
      case "right":
        return 'w3-right';
      default:
      case "center":
        return '';
    }
  }, [props]);

  return (
      <button
          style={{display: "block", margin:"0 auto"}}
          className={`w3-btn w3-round-medium ${props.className} ${classExtra}`}
          onClick={props.onClick}
          disabled={props.disabled}
      >
        {props.label}
      </button>
  )
}
