import React, {CSSProperties, useEffect, useMemo, useState} from "react";
import useResizeObserver from "use-resize-observer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp, faCircleQuestion} from "@fortawesome/free-solid-svg-icons";
import {WithTranslation, withTranslation} from "react-i18next";
import {t} from "i18next";

interface ICollapsibleSectionProps extends WithTranslation {
  title: string;
  className?:string|undefined;
  style?:CSSProperties|undefined;
  collapsed?: boolean;
  onHelpClicked?:()=>void;
  w3BorderColor?:string|undefined;
  onSizeChanged?:(w: number, h: number)=>void|undefined;
  maxHeight?:number|undefined;
}

const TITLE_BAR_HEIGHT = 51;//px
const MARGIN_HEIGHT = 16;//px

const CollapsibleSection: React.FC<ICollapsibleSectionProps> = (props) => {

  const [collapsed, setCollapsed] = useState<boolean>(props.collapsed ?? false);
  const w3BorderColor = props.w3BorderColor ? `w3-border ${props.w3BorderColor}`: '';
  const titleSize = "w3-large";
  const {ref, width=1, height=1} = useResizeObserver<HTMLDivElement>();

  useEffect(()=>{
    if ( width && height ) {
      props.onSizeChanged && props.onSizeChanged( width, height );
    }
  }, [width, height]);

  const maxUserHeight = useMemo(()=>{
    return props.maxHeight
        ? props.maxHeight - 2 * MARGIN_HEIGHT - TITLE_BAR_HEIGHT
        : undefined ;
  }, [props.maxHeight]);

  return (
      <div
          ref={ref}
          className={`unselectable w3-theme-d4 w3-round-large ${collapsed ? "" : "w3-border w3-border-light-blue"} ${props.className}`}
          style={{ ...props.style, width: "100%", height: collapsed ? undefined : props.maxHeight }}
      >
        {/* Title bar */}
        <div
            className={"w3-light-blue w3-show-inline-block"}
            style={{ borderRadius: `8px 8px ${ collapsed? "8px 8px":"0px 0px"}`, width: "100%", }}
            onClick={()=>setCollapsed(prevState => !prevState)}
        >
          <span
              className={`w3-left ${titleSize}`}
              style={{
                display: "inline-block",
                padding:"8px 16px",
                verticalAlign: "middle",
                textAlign:"center",
                whiteSpace:"nowrap"
              }}
          >
            {t(props.title)}
          </span>
          <span
              className={`w3-right ${titleSize}`}
              style={{
                display: "inline-block",
                padding:"8px 16px",
                verticalAlign: "middle",
                textAlign:"center",
                whiteSpace:"nowrap"
              }}
          >
            {props.onHelpClicked && (
              <FontAwesomeIcon
                  className={`w3-margin-left w3-margin-right ${titleSize}`}
                  style={{
                    cursor: "help",
                  }}
                  icon={faCircleQuestion}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onHelpClicked && props.onHelpClicked();
                  }}
              />
            )}
            <FontAwesomeIcon
                className={`${titleSize}`}
                icon={collapsed ? faCaretDown : faCaretUp}
            />
          </span>
        </div>

        {/* Contents */}
        <div
            className={`w3-container ${collapsed ? 'w3-hide':'w3-show'}`}
            style={{
              width: "100%",
            }}
        >
          <div
              className={`w3-section ${w3BorderColor} hidden-scrollbars`}
              style={{
                maxHeight: maxUserHeight ? `${maxUserHeight}px` : undefined,
                overflowY: 'scroll',
                overflowX: 'scroll',
              }}
          >
            {props.children}
          </div>
        </div>
      </div>
  )
}

export default withTranslation()(CollapsibleSection);