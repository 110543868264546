import {useCallback, useMemo} from "react";
import {AttributeType, IAttributeTypeMap} from "../domain/AttributeType";
import IColorLegendConfiguration, {
  pickColorLegend,
  pickColorLegendColor
} from "../domain/IColorLegendConfiguration";
import {Cesium3DTileFeature, Color} from "cesium";
import {DEFAULT_NEUTRAL_COLOR, IStyle} from "../domain/IStyle";
import {useSiteConfig} from "./useSiteConfig";
import {useUserSessionContext} from "../components/Contexts/UserSessionContext";

export const useColorLegendUtil = () => {
  const [siteConfiguration] = useSiteConfig();
  const [userSession] = useUserSessionContext();

  const attributeKey = useMemo(()=>{
    if ( !userSession.selectedAttribute ) {
      return undefined ;
    }
    return AttributeType[userSession.selectedAttribute] as keyof IAttributeTypeMap<IColorLegendConfiguration> ;

  }, [userSession.selectedAttribute]);

  const colorLegendConfig = useMemo(()=>{
    return attributeKey && siteConfiguration.legendConfigs[ attributeKey ];

  }, [attributeKey]);

  const colorLegendState = useMemo(()=>{
    if ( !userSession.selectedAttribute ) {
      return undefined ;
    }
    return userSession.colorLegendStates?.get( userSession.selectedAttribute );

  }, [userSession.selectedAttribute]);
  /**
   * Returns the active color legend's configuration, if any
   */
  const getFeatureValue = useCallback((feature: Cesium3DTileFeature): any|undefined => {
    if (!attributeKey) {
      return undefined;
    }
    // ... Evaluate the value in the feature block for the selected attribute
    return feature.getProperty(attributeKey);

  }, [attributeKey]);

  /**
   * Given the current state of the selected attribute, the color legend configs and the view model's current color
   * legend styles, this method returns the color for a given feature.
   */
  const getFeatureColor = useCallback((feature: Cesium3DTileFeature, defaultColor: Color)=>{

    if ( !colorLegendConfig || !colorLegendState || !attributeKey ) {
      return undefined ;
    }

    // ... Evaluate the value in the feature block for the selected attribute
    let attributeValue = attributeKey && feature.getProperty( attributeKey );
    if (!attributeValue) {
      return defaultColor;
    }
    // ... Set the corresponding color
    return pickColorLegendColor( attributeValue, colorLegendConfig, colorLegendState, defaultColor ) ?? DEFAULT_NEUTRAL_COLOR;
  }, [colorLegendConfig, colorLegendState, attributeKey]);

  const getFeatureStyle = useCallback((feature: Cesium3DTileFeature, defaultStyle: IStyle): IStyle => {
    if ( !colorLegendConfig || !colorLegendState || !attributeKey ) {
      return defaultStyle ;
    }

    // ... Evaluate the value in the feature block for the selected attribute
    let attributeValue = feature.getProperty( attributeKey );
    if (!attributeValue) {
      return defaultStyle;
    }
    // ... Set the corresponding color
    const colorLegend = pickColorLegend( attributeValue, colorLegendConfig, colorLegendState );
    if ( !colorLegend ) {
      return defaultStyle;
    }
    const [config, state] = colorLegend;

    return {
      visibility: state.visible,
      opacity: state.opacity,
      color: config.color
    } as IStyle;

  }, [colorLegendConfig, colorLegendState, attributeKey]);

  return {
    actions: {
      getFeatureColor: getFeatureColor,
      getFeatureStyle: getFeatureStyle,
      getFeatureValue: getFeatureValue,
    }
  }
}