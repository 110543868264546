import React, {useCallback, useEffect, useMemo, useState} from "react";
import {t} from "i18next";
import {withTranslation, WithTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsToEye,
  faFloppyDisk,
  faLightbulb,
  faMap,
  faObjectGroup,
  faSliders
} from "@fortawesome/free-solid-svg-icons";
import {CameraFrustum} from "../../domain/CameraFrustum";
import BottomBarContainer, {BottomBarItem} from "../BasicControls/BottomBarContainer";
import {OrthogonalDirection} from "../../../../model/OrthogonalDirection";
import perspectiveIconBlack from "../../../../img/perspective.svg";
import orthographicIconBlack from "../../../../img/orthographic_wire.svg";
import cubeDown from "../../../../img/cube_down.png";
import cubeNorth from "../../../../img/cube_north.png";
import cubeEast from "../../../../img/cube_east.png";
import cubeUp from "../../../../img/cube_up.png";
import cubeSouth from "../../../../img/cube_south.png";
import cubeWest from "../../../../img/cube_west.png";
import axesIcon from "../../../../img/axes.svg";
import topPlaneIcon from "../../../../img/top_plane.svg";
import selectHolesIcon from "../../../../img/select_dots.png";
import UIControlsHelp from "./UIControlsHelp";
import {useTooltips} from "../Contexts/TooltipContextProvider";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import {SceneMode} from "cesium";
import {ColorLegendFlags} from "../Contexts/FloatingColorLegendVisibilityContextProvider";
import {useColorLegendVisibilityContext} from "../../hooks/useFloatingColorLegendVisibilityContext";

interface IContextualStatusProps extends WithTranslation {
  onResetCamera: ()=>void;
  onSetCameraDirection: (direction: OrthogonalDirection, paddingFactor: number)=>void;
  linearSelectionMode: boolean;
  onLinearSelectionModeChanged: (v: boolean)=>void;
}

const BottomToolbar : React.FC<IContextualStatusProps> = (props) => {
  const [, tooltipActions] = useTooltips();
  const [userSession, userSessionActions, userSessionMoreActions] = useUserSessionContext();
  const [saveButtonDepressed, setSaveButtonDepressed] = useState<boolean>();
  const [colorLegendVisibilityFlags, setColorLegendVisibilityFlags] = useColorLegendVisibilityContext();

  const cameraFrustumStr = useMemo(()=>CameraFrustum[userSession.cameraFrustum].toLowerCase(), [userSession.cameraFrustum])
  const cameraFrustumImg = useMemo(()=>{
    switch (userSession.cameraFrustum) {
      default:
      case CameraFrustum.PERSPECTIVE:
        return <img
            className={`svg-white svg-hover-light-blue`}
            src={perspectiveIconBlack}
            alt={t(cameraFrustumStr)}
            width={32}
            height={32}
        />
      case CameraFrustum.ORTHOGRAPHIC:
        return <img
            className={`svg-white svg-hover-light-blue`}
            src={orthographicIconBlack}
            alt={t(cameraFrustumStr)}
            width={32}
            height={32}
        />
    }
  }, [userSession.cameraFrustum, cameraFrustumStr]);

  const xyCrossSectionElevation = useMemo(()=>{
    if (userSession.boundingBox && userSession.layersVisibility?.CrossSectionY && userSession.originReference) {
      return userSession.originReference.z + userSession.boundingBox.minPos.z + userSession.crossSectionOffsetZ;
    } else {
      return undefined ;
    }
  }, [userSession.layersVisibility?.CrossSectionY, userSession.boundingBox, userSession.originReference, userSession.crossSectionOffsetZ]);

  useEffect(()=>{
    if (userSession.cameraFrustum !== undefined) {
      tooltipActions.updateTooltip(<div>{t(CameraFrustum[userSession.cameraFrustum].toLowerCase())}</div>);
    }
  }, [userSession.cameraFrustum, tooltipActions]);

  const onResetCamera = props.onResetCamera ;
  const resetCamera = useCallback(()=>{
    onResetCamera()
  }, [onResetCamera]);

  const onSetCameraDirection = props.onSetCameraDirection ;
  const setView = useCallback((direction: OrthogonalDirection, paddingFactor = 1.0) => {
    onSetCameraDirection( direction, paddingFactor );
  }, [onSetCameraDirection]);

  useEffect(()=>{console.log('props.showHelp has changed');}, [userSession.showControlsHelp]);

  // const setShowMap2D = userSessionActions.setShowMap2D;
  // const handleToggleShowMap = useCallback(() =>{
  //   userSessionActions.setShowMap2D( !userSession.showMap2D ) ;
  // }, [setShowMap2D]);

  const handleSaveSession = useCallback(()=> {
    userSessionActions.takeScreenshot(SceneMode.SCENE3D)
    // userSessionMoreActions.persistToServer();
  }, [userSessionActions]);

  const persistToServer = userSessionMoreActions.persistToServer;
  useEffect(()=>{
    // ... Save the session when a screenshot has been taken
    if (userSession.screenshot) {
      persistToServer();
    }
  }, [userSession.screenshot, persistToServer]);

  return (
      <>
        {/* Pinned help */}
        {
            <UIControlsHelp
                style={{
                  display: userSession.showControlsHelp ? "inherit":"none",
                  position: "absolute",
                  right: "10px",
                  bottom: "50px",
                  zIndex: 1
                }}
                className={"w3-black w3-round w3-opacity w3-hover-opacity-off w3-border"}
                foreColor={"white"}
                onMoveCrossSection={userSessionActions.moveSelectedCrossSectionOffset}
                onResetCrossSectionOffset={userSessionActions.resetSelectedCrossSectionOffset}
            />
        }

        {/* Bottom bar div */}
        {
          <BottomBarContainer
              onChangeTooltip={ (newTooltip, position) =>
                  tooltipActions.setTooltip( newTooltip ? { content: newTooltip, position: {...position, y: position.y - 5}, orientation: "top" } : undefined )
              }
          >
            {/*Bounding Box*/}
            <BottomBarItem
                content={<FontAwesomeIcon icon={faObjectGroup} size={"2x"} />}
                className={userSession.boundingBoxVisibility ? "w3-border w3-round-small w3-light-blue" : "w3-hover-text-light-blue"}
                tooltip={<div>{t(userSession.boundingBoxVisibility ? "hide_bounding_box":"show_bounding_box")}</div>}
                onClick={()=>{
                  const newVisibility = !userSession.boundingBoxVisibility;
                  userSessionActions.setBoundingBoxVisibility(newVisibility);
                  tooltipActions.updateTooltip(<div>{t(newVisibility ? "hide_bounding_box":"show_bounding_box")}</div>);
                }}
            />
            {/*Axes visibility*/}
            <BottomBarItem
                content={<img
                    className={`${userSession.axesVisibility ? "":"svg-white svg-hover-light-blue"}`}
                    src={axesIcon}
                    alt={t(userSession.axesVisibility ? "hide_xyz_axes":"show_xyz_axes")}
                    width={32}
                    height={32}
                />}
                className={userSession.axesVisibility ? "w3-border w3-round-small w3-light-blue " : ""}
                tooltip={<div>{t(userSession.axesVisibility ? "hide_xyz_axes":"show_xyz_axes")}</div>}
                onClick={()=>{
                  const newVisibility = !userSession.axesVisibility;
                  userSessionActions.setAxesVisibility(newVisibility);
                  tooltipActions.updateTooltip(<div>{t(newVisibility ? "hide_xyz_axes":"show_xyz_axes")}</div>);
                }}
            />
            {/*Top plane*/}
            <BottomBarItem
                content={<img
                  className={`svg-rotate-270 ${userSession.topPlaneVisibility ? "":"svg-white svg-hover-light-blue"}`}
                  src={topPlaneIcon}
                  alt={t(userSession.topPlaneVisibility ? "hide_bounding_top":"show_bounding_top")}
                  width={32}
                  height={32}
                />}
                className={userSession.topPlaneVisibility ? "w3-border w3-round-small w3-light-blue " : ""}
                tooltip={<div>{t(userSession.topPlaneVisibility ? "hide_bounding_top":"show_bounding_top")}</div>}
                onClick={()=>{
                  const newVisibility = !userSession.topPlaneVisibility;
                  userSessionActions.setTopPlaneVisibility(newVisibility);
                  tooltipActions.updateTooltip(<div>{t(newVisibility ? "hide_bounding_top":"show_bounding_top")}</div>);
                }}
            />
            {/*2D Map visibility*/}
            <BottomBarItem
                content={<FontAwesomeIcon icon={faMap} size={"2x"}/>}
                onClick={()=> {
                  const newVisibility = !userSession.showMap2D;
                  userSessionActions.setShowMap2D(newVisibility);
                  // handleToggleShowMap();
                }}
                tooltip={<div>{t(userSession.showMap2D ? "hide_2d":"show_2d")}</div>}
                className={userSession.showMap2D ? "w3-border w3-round-small w3-light-blue" : "w3-hover-text-light-blue "}
            />
            {/*Camera Frustum*/}
            <BottomBarItem
                content={cameraFrustumImg}
                onClick={userSessionActions.toggleCameraFrustum}
                tooltip={<div>{t(cameraFrustumStr)}</div>}
                className={"w3-hover-text-light-blue "}
            />
            {/*Reset view*/}
            <BottomBarItem
                content={<FontAwesomeIcon icon={faArrowsToEye} size={"2x"}/>}
                onClick={resetCamera}
                tooltip={<div>{t("reset_camera")}</div>}
                className={"w3-hover-text-light-blue "}
            />
            {/*Presets*/}
            <BottomBarItem
                content={<img src={cubeDown} alt={t("down")} width={32} height={32}/>}
                onClick={()=>setView(OrthogonalDirection.Down)}
                tooltip={<div>{t("down")}</div>}
                className={"w3-opacity w3-hover-opacity-off"}
            />
            <BottomBarItem
                content={<img src={cubeUp} alt={t("up")} width={32} height={32}/>}
                onClick={()=>setView(OrthogonalDirection.Up)}
                tooltip={<div>{t("up")}</div>}
                className={"w3-opacity w3-hover-opacity-off"}
            />
            <BottomBarItem
                content={<img src={cubeEast} alt={t("east")} width={32} height={32}/>}
                onClick={()=>setView(OrthogonalDirection.East)}
                tooltip={<div>{t("east")}</div>}
                className={"w3-opacity w3-hover-opacity-off"}
            />
            <BottomBarItem
                content={<img src={cubeWest} alt={t("west")} width={32} height={32}/>}
                onClick={()=>setView(OrthogonalDirection.West)}
                tooltip={<div>{t("west")}</div>}
                className={"w3-opacity w3-hover-opacity-off"}
            />
            <BottomBarItem
                content={<img src={cubeNorth} alt={t("north")} width={32} height={32}/>}
                onClick={()=>setView(OrthogonalDirection.North)}
                tooltip={<div>{t("north")}</div>}
                className={"w3-opacity w3-hover-opacity-off"}
            />
            <BottomBarItem
                content={<img src={cubeSouth} alt={t("south")} width={32} height={32}/>}
                onClick={()=>setView(OrthogonalDirection.South)}
                tooltip={<div>{t("south")}</div>}
                className={"w3-opacity w3-hover-opacity-off"}
            />
            {/*XY Horizontal section elevation*/}
            { xyCrossSectionElevation && (
                <BottomBarItem
                    content={<div>{t("elevation")}: {xyCrossSectionElevation.toFixed(0)}</div>}
                    className={"w3-hover-text-light-blue"}
                />
            )}
            {/* Disabled until this is part of requirements */}
            {/*<BottomBarItem*/}
            {/*    content={<img src={selectHolesIcon} alt={t("select holes")} width={props.linearSelectionMode ? 30 : 32} height={props.linearSelectionMode ? 30 : 32}/>}*/}
            {/*    onClick={()=>props.onLinearSelectionModeChanged(!props.linearSelectionMode)}*/}
            {/*    tooltip={<div>{t("Select Blast Holes")}</div>}*/}
            {/*    className={props.linearSelectionMode ? "w3-border w3-border-light-blue": "w3-opacity w3-hover-opacity-off"}*/}
            {/*/>*/}
            { ((colorLegendVisibilityFlags & ColorLegendFlags.SideMenuLegendVisible) === 0) &&
              <BottomBarItem
                  content={<FontAwesomeIcon icon={faSliders} size={"2x"}/>}
                  onClick={()=> {
                    const showFloatingLegend = !userSession.showFloatingLegend;
                    //userSessionActions.setShowFloatingLegend(showFloatingLegend);
                    if ( showFloatingLegend ) {
                      setColorLegendVisibilityFlags(prevState => prevState | ColorLegendFlags.FloatingLegendAllowed )
                    } else {
                      setColorLegendVisibilityFlags(prevState => prevState & ~ColorLegendFlags.FloatingLegendAllowed )
                    }
                  }}
                  tooltip={<div>{`${t(userSession.showFloatingLegend ? "Hide":"Show")} ${t('Floating Legend')}`}</div>}
                  className={userSession.showFloatingLegend ? "w3-border w3-round-small w3-light-blue" : "w3-hover-text-light-blue "}
              />
            }
            <BottomBarItem
                content={
                  <FontAwesomeIcon
                      className={`${saveButtonDepressed ? 'w3-text-dark-gray' : ''}`}
                      icon={faFloppyDisk}
                      size={"2x"}
                      onMouseDown={()=>setSaveButtonDepressed(true)}
                      onMouseUp={()=>setSaveButtonDepressed(false)}
                      onMouseLeave={()=>setSaveButtonDepressed(false)}
                      onMouseOut={()=>setSaveButtonDepressed(false)}
                  />
                }
                onClick={handleSaveSession}
                tooltip={<div>{t("Save Session (and thumbnail image)")}</div>}
                className={`w3-opacity w3-hover-opacity-off ${saveButtonDepressed ? 'w3-light-blue':'w3-hover-text-light-blue'}`}
            />
            {/*Show hints (controls)*/}
            <BottomBarItem
                content={<FontAwesomeIcon icon={faLightbulb} size={"2x"}/>}
                onClick={()=>{
                  let newVisibility = !userSession.showControlsHelp;
                  userSessionActions.setShowControlsHelp(newVisibility);
                  tooltipActions.updateTooltip( newVisibility
                      ? <div>{t("hide_section_controls")}</div>
                      : <UIControlsHelp foreColor={'black'}/>
                  )
                }}
                tooltip={ userSession.showControlsHelp
                    ? <div>{t("hide_section_controls")}</div>
                    : <UIControlsHelp foreColor={'black'}/>
                }
                className={userSession.showControlsHelp ? "w3-border w3-round-small w3-light-blue": "w3-hover-text-amber"}
            />
          </BottomBarContainer>
        }
      </>
  )
}

export default withTranslation()(BottomToolbar) ;

