import React, {useMemo} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import VideoClip from "./VideoClip";

interface ITextAndImageProps extends WithTranslation {
  title: string;
  text: string;
  src: string;
  imgRatio?: string;
}

const TextAndImage: React.FC<ITextAndImageProps> = (props) => {
  const t = props.t;

  const imgRatio = props.imgRatio ?? "w3-half";
  const textRatio = useMemo(()=>{
    switch(imgRatio) {
      case "w3-quarter":
        return "w3-threequarter";
      case "w3-third":
        return "w3-twothird";
      case "w3-half":
        return "w3-half";
      case "w3-twothird":
        return "w3-third";
      case "w3-threequarter":
        return "w3-quarter";
      default:
        return "w3-rest";
    }
  }, [imgRatio]);

  return (
      <div className={'w3-panel'}>
        <h3 className={"w3-left-align"}>{t(props.title)}</h3>
        <div className={"w3-row"}>
          <div className={`w3-col ${textRatio ?? "w3-half"}`}>
            <p className={"text-full-justify"}>
              {t(props.text)}
            </p>
          </div>
          <VideoClip src={props.src} className={`w3-col ${imgRatio} w3-padding`}/>
          {/*<img src={props.src} className={`w3-col ${imgRatio} w3-padding`}/>*/}
        </div>
      </div>
  )
}

export default withTranslation()(TextAndImage);
