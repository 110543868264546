import {UserSessionState} from "../components/Contexts/UserSessionContext";
import {ISiteConfiguration} from "../domain/ISiteConfiguration";
import {LayerType} from "../../../model/LayerType";
import {Cesium3DTileFeature, Color, SceneMode} from "cesium";
import {SegmentDisplayStyle, strToSegmentDisplayStyle} from "../domain/ISecondarySegmentOptions";
import {BaseHoleStylist} from "./BaseHoleStylist";

export class BlastHoleStylist extends BaseHoleStylist {

  private _segmentDisplayStyle: SegmentDisplayStyle;

  constructor(userSessionState: UserSessionState, siteConfig: ISiteConfiguration, sceneMode: SceneMode) {
    super(userSessionState, siteConfig, LayerType.BlastholeSecondarySegments, sceneMode);
    this._segmentDisplayStyle = this.userSessionState.segmentStyle.segmentDisplayStyle;
  }

  get isMaskable(): boolean {
    return true;
  }

  evaluateShow(feature: Cesium3DTileFeature): boolean {

    // ... Check for segment style before doing any extensive testing
    let result: boolean = this.layerVisibility ;

    // ... For secondary segments, is this segment of the required style ?
    if ( result ) {
      const value = this.extractValue( feature ) ;
      const isValid = ! this.isValueInvalid( value );
      const isMasked = this.isValueMasked( value )
      if ( isValid && ! isMasked ) {
        result = this.isSelectedSegmentStyle( feature );
      } else {
        result = this.isToScale( feature ) ;
      }
    }

    // ... If it is, then evaluate the rest (ex: distance from threshold)
    if ( result ) {
      result = super.evaluateShow(feature);
    }

    return result;
  }

  private isSelectedSegmentStyle( feature: Cesium3DTileFeature ): boolean {
    const widthProperty = feature.getProperty("width");
    if (widthProperty === undefined) {
      return true ;
    }
    return strToSegmentDisplayStyle( widthProperty ) === this._segmentDisplayStyle ;
  }
  private isToScale( feature: Cesium3DTileFeature ): boolean {
    const widthProperty = feature.getProperty("width");
    if (widthProperty === undefined) {
      return false ;
    }
    return strToSegmentDisplayStyle( widthProperty ) === 'small';
  }
}