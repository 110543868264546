import {IColorLegendConfigDto} from "../model/IColorLegendConfigDto";
import ColorLegendItemConfigMapper from "./ColorLegendItemConfigMapper";
import IColorLegendConfiguration from "../domain/IColorLegendConfiguration";
import {LegendType} from "../domain/LegendType";
import {IColorLegendItemConfigDto} from "../model/IColorLegendItemConfigDto";
import {Color} from "cesium";

export default class ColorLegendConfigMapper {
  public static colorLegendConfigToColorLegendConfigDto( colorLegendConfig: IColorLegendConfiguration ): IColorLegendConfigDto {
    return {
      legendType: LegendType[colorLegendConfig.legendType],
      numColors: colorLegendConfig.items.length,
      colorInvalidValue: ColorLegendItemConfigMapper.colorLegendItemConfigToColorLegendItemConfigDto( colorLegendConfig.invalidItem, colorLegendConfig.precision ),
      colorRangeExceeded: colorLegendConfig.rangeExceededItem ? ColorLegendItemConfigMapper.colorLegendItemConfigToColorLegendItemConfigDto( colorLegendConfig.rangeExceededItem, colorLegendConfig.precision ) : null,
      items: colorLegendConfig.items.map( item => ColorLegendItemConfigMapper.colorLegendItemConfigToColorLegendItemConfigDto( item, colorLegendConfig.precision )),
      colorMaskedColor: colorLegendConfig.maskedColor,
      defaultRange: colorLegendConfig.defaultRange,
      units: colorLegendConfig.units,
      precision: colorLegendConfig.precision
    }
  }
  public static colorLegendConfigDtoToColorLegendConfig( colorLegendConfigDto: IColorLegendConfigDto ): IColorLegendConfiguration {
    return {
      legendType: LegendType[colorLegendConfigDto.legendType as keyof typeof LegendType],
      invalidItem: ColorLegendItemConfigMapper.colorLegendItemConfigDtoToColorLegendItemConfig( colorLegendConfigDto.colorInvalidValue as IColorLegendItemConfigDto ),
      rangeExceededItem: colorLegendConfigDto.colorRangeExceeded ? ColorLegendItemConfigMapper.colorLegendItemConfigDtoToColorLegendItemConfig( colorLegendConfigDto.colorRangeExceeded as IColorLegendItemConfigDto ) : undefined,
      items: colorLegendConfigDto.items.map( item =>
          ColorLegendItemConfigMapper.colorLegendItemConfigDtoToColorLegendItemConfig( item as IColorLegendItemConfigDto )
        ),
      maskedColor: colorLegendConfigDto.colorMaskedColor,
      defaultRange: colorLegendConfigDto.defaultRange,
      units: colorLegendConfigDto.units,
      precision: colorLegendConfigDto.precision
    }
  }
}
