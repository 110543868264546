import {IconProp} from "@fortawesome/fontawesome-svg-core";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IProps {
  id?:string;
  label: string;
  icon: IconProp;
  onClick: ()=>void;
  className?: any
}

const LabelIconButton: React.FC<IProps> = (props)=>{
  return (
      <button
          id={props.id}
          className={`w3-button w3-border w3-round-medium unselectable ${props.className}`}
          style={{ fontSize: "12px", minWidth: "50%" }}
          onClick={(e) => props.onClick()}
      >
        {props.label}
        <FontAwesomeIcon
            className="w3-margin-left"
            icon={props.icon}
        />
      </button>
  )
}

export default LabelIconButton;
