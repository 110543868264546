import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./lang/en.json";
import fr from "./lang/fr.json";

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("lang") || "en",
  interpolation: {
    // React already escapes strings for us
    // It's important to make sure strings are always escaped to avoid XSS (cross site scripting) and javascript injection
    escapeValue: false,
  },
});

i18n.on("languageChanged", (newLang) => {
  localStorage.setItem("lang", newLang);
});

export default i18n;
