import { parse } from "date-fns";
import { withTranslation, WithTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { sortPatterns } from "../lib/sort";
import IPattern from "../model/IPattern";
import IFilter from "./Filters/IFilter";
import PatternListItem from "./PatternListItem";
import EmptySortHeaderItem from "./Sorting/EmptySortHeaderItem";
import SortHeader from "./Sorting/SortHeader";
import SortHeaderItem, {IColumnSort} from "./Sorting/SortHeaderItem";
import TriStateCheckbox from "./TriStateCheckbox";
import WarningBox from "./WarningBox";
import {CSSProperties, useState} from "react";

interface Props extends WithTranslation {
  availablePatterns: IPattern[];
  filter: IFilter;
  selectedPatternIds: string[];
  onSelectionChange: (change: { added: string[]; removed: string[] }) => void;
  onPatternActiveStateChanged: (change: { pattern: IPattern }) => void;
  onPatternDeleteRequested: (pattern: IPattern) => void;
  style?:CSSProperties|undefined;
}

const PatternList: React.FC<Props> = ({
  availablePatterns,
  filter,
  selectedPatternIds,
  onSelectionChange,
  onPatternActiveStateChanged,
  onPatternDeleteRequested,
  t,
  style
}) => {
  const [columnSort, setColumnSort] = useState<IColumnSort>({
    sortBy: "end",
    sortOrder: "desc"
  });

  const filteredPatterns = availablePatterns
    .filter((p) => p.name.includes(filter.name))
    .filter((p) =>
      filter.startDate
        ? p.end >= parse(filter.startDate, t("SHORT_DATE_FORMAT"), new Date())
        : true
    )
    .filter((p) =>
      filter.endDate
        ? p.start <= parse(filter.endDate, t("SHORT_DATE_FORMAT"), new Date())
        : true
    )
    .filter((p) =>
      filter.includeInactive ? true : !p.tags.includes("inactive")
    );

  const sortedFilteredPatterns = sortPatterns(filteredPatterns, columnSort.sortBy, columnSort.sortOrder);

  const onSelectionChanged = (pattern: IPattern, selected: boolean) => {
    selected
      ? onSelectionChange({ added: [pattern.patternId], removed: [] })
      : onSelectionChange({ added: [], removed: [pattern.patternId] });
  };

  const onActiveStateChanged = (pattern: IPattern) => {
    onPatternActiveStateChanged({ pattern: pattern });
  };

  let allChecked: boolean | undefined = undefined;
  if (selectedPatternIds.length === 0) allChecked = false;
  else if ( sortedFilteredPatterns.every((p) => selectedPatternIds.includes(p.patternId) ) ) {
    allChecked = true;
  }

  return (
    <div style={{...style, height: "100%", overflowY: 'auto'}}>
      <div className="w3-padding-small w3-row w3-margin-bottom">
        <div className="w3-col m2" style={{ marginTop: "-5px" }}>
          <TriStateCheckbox
            id="select-all-checkbox"
            checked={allChecked}
            className="w3-check w3-margin-right"
            onChange={(e) => {
              if (e.target.checked)
                onSelectionChange({
                  added: sortedFilteredPatterns
                    .filter((p) => !selectedPatternIds.includes(p.patternId))
                    .map((p) => p.patternId),
                  removed: [],
                });
              else
                onSelectionChange({
                  added: [],
                  removed: sortedFilteredPatterns
                    .filter((p) => selectedPatternIds.includes(p.patternId))
                    .map((p) => p.patternId),
                });
            }}
          />
          <label htmlFor="select-all-checkbox">{t("Select All")}</label>
        </div>
        {selectedPatternIds.length > 3 && (
            <div className="w3-col m10" style={{ marginTop: "-5px" }}>
              <WarningBox>{t("more_than_3_selected_patterns")}</WarningBox>
            </div>
        )}
      </div>
      <SortHeader>
        <EmptySortHeaderItem widthPercent={5} />
        <SortHeaderItem
          defaultFilterprop="end"
          filterProp="name"
          widthPercent={20}
          columnSort={columnSort}
          onColumnSort={(sort=>setColumnSort(sort))}
        >
          {t("name")}
        </SortHeaderItem>
        <SortHeaderItem
          defaultFilterprop="end"
          filterProp="start"
          widthPercent={20}
          columnSort={columnSort}
          onColumnSort={(sort=>setColumnSort(sort))}
        >
          {t("start_date")}
        </SortHeaderItem>
        <SortHeaderItem
          defaultFilterprop="end"
          filterProp="end"
          widthPercent={20}
          columnSort={columnSort}
          onColumnSort={(sort=>setColumnSort(sort))}
        >
          {t("end_date")}
        </SortHeaderItem>
        <SortHeaderItem
          defaultFilterprop="end"
          filterProp="dmm_rev"
          widthPercent={20}
          columnSort={columnSort}
          onColumnSort={(sort=>setColumnSort(sort))}
        >
          {t("dmm_rev")}
        </SortHeaderItem>
        <SortHeaderItem
          defaultFilterprop="end"
          filterProp="active"
          widthPercent={15}
          columnSort={columnSort}
          onColumnSort={(sort=>setColumnSort(sort))}
        >
          {t("status")}
        </SortHeaderItem>
      </SortHeader>
      <div
        style={{
          // height: "75vh",
          height: "100%",
          overflow: "auto",
        }}
      >
        <ul className="w3-ul">
          {sortedFilteredPatterns.map((p) => (
            <PatternListItem
              selected={selectedPatternIds.includes(p.patternId)}
              key={p.name}
              pattern={p}
              onSelectionChanged={onSelectionChanged}
              onActiveStateChanged={onActiveStateChanged}
              onPatternDeleteRequested={onPatternDeleteRequested}
            />
          ))}
          {sortedFilteredPatterns.length === 0 && (
            <li className="w3-center w3-opacity-min">
              <i>{t("no_patterns_found")}</i>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default withTranslation()(PatternList);
