import Role from "../model/UserAdministration/Role";

export default class UserSession {
  public static getUsername = () => {
    return sessionStorage.getItem("userName") || "";
  };

  public static getCustomerSiteId = () => {
    return sessionStorage.getItem("customerSiteId") || "";
  };

  public static getAuthHeaders = () => {
    return {
      Authorization: `${sessionStorage.getItem(
        "auth_token_header"
      )} ${sessionStorage.getItem("auth_token")}`,
    };
  };

  public static roles: () => Role[] = () => {
    return JSON.parse(sessionStorage.getItem("roles") ?? "[]");
  };

  public static isInRoles(roles: Role[]) {
    const userRoles = UserSession.roles();

    // An intersect of the logged in user's roles and the roles passed in
    return userRoles.some((r) => roles.includes(r));
  }
}
