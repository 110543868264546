import { faBan, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { useFetchWithAuth } from "../../lib/auth/fetchWithAuth";
import { UserAccountApi } from "../../lib/routes";
import IUser from "../../model/UserAdministration/IUser";
import Role from "../../model/UserAdministration/Role";
import DotMenu from "../DotMenu/DotMenu";
import ListItem from "../List/ListItem";
import AssignRolesModal from "./AssignRolesModal";

interface Props extends WithTranslation {
  user: IUser;
}

const UserRow: React.FC<Props> = ({ user, t }) => {
  const fetchWithAuth = useFetchWithAuth();
  const [localUser, setLocalUser] = useState(user);
  const [changeRoleModalShown, setChangeRoleModalShown] = useState(false);

  const updateUser = async (user: IUser) => {
    const response = await fetchWithAuth(
      UserAccountApi.updateUserAccountUrl(),
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }
    );
    if (response.ok) setLocalUser(user);
  };

  const handleToggleActive = async (active: boolean) => {
    const updatedUser = { ...localUser, enabled: active };
    await updateUser(updatedUser);
  };

  const setUserRoles = async (r: Role[]) => {
    const updatedUser = { ...localUser, roles: r };
    await updateUser(updatedUser);
  };

  return (
    <>
      <ListItem>
        <div className="w3-cell w3-cell-middle" style={{ width: "30%" }}>
          {localUser.username}
        </div>
        <div className="w3-cell w3-cell-middle" style={{ width: "55%" }}>
          {t("user_role")}
          {": "}
          {[...localUser.roles].sort().map((r, index) => (
            <span key={r}>
              {index === 0 ? "" : ", "}
              {r}
            </span>
          ))}
        </div>
        <div className="w3-cell w3-cell-middle" style={{ width: "15%" }}>
          <div className={localUser.enabled ? "" : "w3-text-black"}>
            {localUser.enabled ? t("active") : t("inactive")}
          </div>
        </div>
        <div className="w3-cell w3-cell-middle">
          <DotMenu style={{ padding: "8px 16px", float: "right" }}>
            <div className="w3-dropdown-content w3-bar-block w3-card-4 w3-show">
              <button
                className="w3-bar-item w3-button"
                onClick={() => handleToggleActive(true)}
                disabled={localUser.enabled}
              >
                <FontAwesomeIcon icon={faCheckCircle} /> {t("activate")}
              </button>
              <button
                className="w3-bar-item w3-button"
                onClick={() => handleToggleActive(false)}
                disabled={!localUser.enabled}
              >
                <FontAwesomeIcon icon={faBan} /> {t("deactivate")}
              </button>
              <button
                className="w3-bar-item w3-button"
                onClick={() => setChangeRoleModalShown(true)}
              >
                {t("define_user_role")}
              </button>
            </div>
          </DotMenu>
        </div>
      </ListItem>
      {changeRoleModalShown && (
        <AssignRolesModal
          userName={localUser.username}
          onSave={(roles) => {
            setChangeRoleModalShown(false);
            setUserRoles(roles);
          }}
          onCancel={() => setChangeRoleModalShown(false)}
          userRoles={localUser.roles}
        />
      )}
    </>
  );
};

export default withTranslation()(UserRow);
