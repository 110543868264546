import {
    CallbackProperty,
    Cesium3DTileFeature,
    Entity
} from "cesium";
import {t} from "i18next";

export class FeatureAdapter {

    public static readonly ATTRIBUTE_KEY_MAPPINGS = new Map<string,string>([
        [t("Pattern"), "Pattern"],
        [t("PatternID"), "PatternID"],
        [t("HoleID"), "HoleID"],
        [t("HoleName"), "HoleName"],
        [t("DrillID"), "DrillID"],
        [t("easting"), "x"],
        [t("northing"), "y"],
        [t("elevation"), "z"],
        [t("top_elevation"), "from_z"],
        [t("bottom_elevation"), "to_z"],
        [t("SED"), "SED"],
        // [t("DWI"), "DWI"],
        // [t("RMI"), "RMI"],
        [t("BI"), "BI"],
        [t("CBI"), "CBI"],
        [t("CoalProbability"), "CoalProbability"],
        [t("Hardness"), "Hardness"],
        [t("FRF"), "FRF"],
        [t("ROP"), "ROP"],
        [t("BI_Domain"), "BI_Domain"],
        [t("CBI_Domain"), "CBI_Domain"],
        [t("Prominence"), "Prominence"],
        [t("Depth"), "Depth"],

        // [t("I"), "I"],
        // [t("J"), "J"],
        // [t("K"), "K"],
        // [t("OffsetXYZ"), "OffsetXYZ"],
        // [t("OffsetIJK"), "OffsetIJK"],
        [t("Layer"), "Layer"],
        // [t("rx"), "rx"],
        // [t("ry"), "ry"],
        // [t("rz"), "rz"],

        // [t(ADJUSTED_POS_PROPERTY_NAME), ADJUSTED_POS_PROPERTY_NAME],

        // [t("SegmentWidth"), "width"],
        //
        // [t("Relative Block Position"), "relposmin"], // CS, BM
        // [t("Relative Position"), "relpos"], // Clusters, Fractures
        // [t("Relative Top Position"), "relpostop"], // SingleSeg
        // [t("Relative Bottom Position"), "relposbottom"], // SingleSeg, SecSeg
    ]);

    private static _precisionGetter: undefined | ((attr:string) => number|undefined);

    static setAttributePrecisionGetter( getter: (attr:string) => number|undefined ) {
        this._precisionGetter = getter ;
    }

    static createInfobox(feature: Cesium3DTileFeature): Entity {

        return new Entity({
            id: `${feature.featureId}`,
            name: FeatureAdapter.createFeatureName(feature),
            description: new CallbackProperty(function () {
                return FeatureAdapter.createDescription(
                    FeatureAdapter.getDescriptionItems(feature)
                );
            }, false)
        })
    }

    static createFeatureName(feature: Cesium3DTileFeature): string {
        let patternName = feature.getProperty("Pattern");
        let holeName = feature.getProperty("HoleName");
        let holeId = feature.getProperty("HoleID");

        if ( patternName ) {
            if (holeName || holeId ) {
                if ( !holeId ) {
                    return `${patternName} - ${holeName}`;
                }
                else if ( !holeName ) {
                    return `${patternName} - ${holeId}`;
                }
                else if (holeName !== holeId.toString()) {
                    return `${patternName} - ${holeName} (${holeId})`;
                }
                else {
                    return `${patternName} - ${holeName}`;
                }
            } else {
                return patternName ;
            }
        } else {
            return feature.featureId.toString();
        }
    }

    static createDescription(items: Map<String,any>): string {

        const td = (text:string, align:string) => `<td style="border: 1px solid; padding: 5px; text-align: ${align}">`+text+'</td>';

        let entries: string[] = [];

        items.forEach((value, key) => {
            entries.push(
                `<tr>${td(key.toString(), 'left')}${td(value, 'center')}</tr>`
            ) ;
        });
        return '<table style="border: 1px solid; border-collapse: collapse; width: 100%"><tbody>' +
            entries.join('') + '</tbody></table>';
    }

    /**
     * Note: to support multilingual display, this is called continuously, as long as the infobox is visible; this
     * happens because the html content is refreshed continuously, during which the content are continually rebuilt.
     * This infobox widget is an opaque object within the Cesium library, so we must make due with it's non-React
     * behavior... at least until we completely move to Resium.
     *
     * @param feature
     * @return A mapping of feature property names to their values.
     */
    static getDescriptionItems(feature: Cesium3DTileFeature): Map<string, any> {
        let result = new Map<string,any>();
        FeatureAdapter.ATTRIBUTE_KEY_MAPPINGS.forEach( (v, k) => {
            let propValue = feature.getProperty(v);
            if ( propValue ) {
                // ... Use appropriate precision for numbers
                let precision ;
                if ( this._precisionGetter ) {
                    precision = this._precisionGetter( v ) ;
                }
                if ( precision !== undefined ) {
                    result.set(v, propValue.toFixed(precision));
                } else {
                    result.set(v, propValue);
                }
            }
        });
        return result ;
    }
}