import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {Cesium3DTileFeature, Entity} from "cesium";
import EntityInfobox from "./EntityInfobox";
import TopOfBenchPlaneInfoBox from "./TopOfBenchPlaneInfoBox";
import BlastholeInfobox from "./BlastholeInfobox";
import {LayerType} from "../../../../model/LayerType";
import FeatureInfobox from "./FeatureInfobox";

export interface ICustomInfoBoxProps extends WithTranslation {
  selected: Cesium3DTileFeature|Entity;
  className?:string;
  style?:any;
  onSelectionChanged?: (selected: Cesium3DTileFeature|Entity)=>void;
}

const CustomInfoBox: React.FC<ICustomInfoBoxProps> = (props) => {

  // console.log(`CustomInfoBox: ${props.selected?.name}`);

  if (!props.selected) {
    return (<></>);
  }

  if ( props.selected instanceof Entity ) {
    console.log(`CustomInfoBox: is an entity`);
    if ( props.selected.name === 'TopOfBenchPlane' ) {
      return <TopOfBenchPlaneInfoBox className={props.className} style={props.style} entity={props.selected}/>
    } else {
      return <EntityInfobox className={props.className} style={props.style} entity={props.selected}/>
    }
  }

  if ( props.selected instanceof Cesium3DTileFeature ) {
    console.log(`CustomInfoBox: is a tileset feature`);
    // TODO: more sophisticated blasthole infobox needs more performance testing
    const layer = props.selected.getProperty("Layer");
    if ( LayerType[ layer as keyof typeof LayerType ] === LayerType.BlastholeSecondarySegments ) {
      return <BlastholeInfobox className={props.className} style={props.style} feature={props.selected}/>
    } else {
      return <FeatureInfobox className={props.className} style={props.style} feature={props.selected}/>
    }
  }

  return (<></>);
}

export default withTranslation()(CustomInfoBox);
