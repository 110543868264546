import {INotification} from "../components/Gen2/Notifications/INotification";

export default class ClientEventsStore {

  private static SESSION_STORAGE_KEY = "ClientEvents";

  public static getStoredEvents = (): INotification[] => {
    const storedEventsJson = sessionStorage.getItem(ClientEventsStore.SESSION_STORAGE_KEY) ;
    return storedEventsJson ? JSON.parse(storedEventsJson) : [] ;
  }

  public static setStoredEvents = ( events: INotification[]) => {
    sessionStorage.setItem(ClientEventsStore.SESSION_STORAGE_KEY, JSON.stringify(events) ) ;
  }

  public static addStoredEvent = ( event: INotification ): INotification[] => {
    const events = [...this.getStoredEvents(), event];
    this.setStoredEvents( events );
    return events ;
  }

  public static updateStoredEvent = ( event: INotification ) => {
    let events: INotification[] = this.getStoredEvents();
    let eventToUpdate = events.find( e => e.id === event.id ) ;
    if ( eventToUpdate ) {
      const idx = events.indexOf( eventToUpdate );
      this.setStoredEvents([
        ...events.slice( 0, idx ),
        { ...event},
        ...events.slice( idx + 1 )
      ])
    }
  }

  public static removeStoredEvent = ( event: INotification ): INotification[] => {
    let events = this.getStoredEvents().filter( e => e.id !== event.id ) ;
    this.setStoredEvents( events )
    return events ;
  }

  public static clear = (): INotification[] => {
    this.setStoredEvents( [] );
    return [];
  }
}
