export enum AttributeType {
  Other,
  SED,
  BI,
  CBI,
  Hardness,
  CoalProbability,
  FRF,
  ROP,
  Prominence
}

export interface IAttributeTypeMap<T> {
  Other: T;
  SED: T;
  BI: T;
  CBI: T;
  Hardness: T;
  CoalProbability: T;
  FRF: T;
  ROP: T;
  Prominence: T;
}

export const ATTRIBUTE_LABELS: IAttributeTypeMap<string> = {
  CoalProbability: "CoalProbability",
  BI: "BI",
  FRF: "FRF",
  Hardness: "Hardness",
  Other: "Other",
  Prominence: "Prominence",
  CBI: "CBI",
  ROP: "ROP",
  SED: "SED"
}

export const SELECTABLE_ATTRIBUTE_TYPES: AttributeType[] = [
  AttributeType.SED,
  AttributeType.BI,
  AttributeType.CBI,
  AttributeType.Hardness,
  AttributeType.CoalProbability,
  AttributeType.FRF,
  AttributeType.ROP
];

export const SITE_CONFIG_EDITABLE_ATTRIBUTES = [
  AttributeType.SED,
  AttributeType.BI,
  AttributeType.CBI,
  AttributeType.Prominence,
  AttributeType.CoalProbability,
  AttributeType.FRF,
  AttributeType.ROP
]

export const CATEGORY_ATTRIBUTE_TYPES: AttributeType[] = [
  AttributeType.Hardness,
];

export const MAPPED_ATTRIBUTE_TYPES: AttributeType[] = [
  AttributeType.Hardness,
];

export const SELECTABLE_ATTRIBUTES_KEYS: (keyof IAttributeTypeMap<string>)[] = [
  ATTRIBUTE_LABELS.SED as keyof IAttributeTypeMap<string>,
  ATTRIBUTE_LABELS.BI as keyof IAttributeTypeMap<string>,
  ATTRIBUTE_LABELS.CBI as keyof IAttributeTypeMap<string>,
  ATTRIBUTE_LABELS.Hardness as keyof IAttributeTypeMap<string>,
  ATTRIBUTE_LABELS.CoalProbability as keyof IAttributeTypeMap<string>,
  ATTRIBUTE_LABELS.FRF as keyof IAttributeTypeMap<string>,
  ATTRIBUTE_LABELS.ROP as keyof IAttributeTypeMap<string>,
];

export const fromAttributeTypeLabel = ( str: string ): keyof IAttributeTypeMap<string> => {
  let result ;
  Object.keys(ATTRIBUTE_LABELS).forEach( key => {
    const k = key as keyof IAttributeTypeMap<string>;
    const v = ATTRIBUTE_LABELS[k];
    if ( str === v ) {
      result = k;
    }
  });
  if ( !result ) {
    throw new Error(`fromLabel: ${str} is not a valid key`);
  }
  return result ;
}

// export const ATTRIBUTE_TYPES: AttributeType[] =
//     Object.keys(SELECTABLE_ATTRIBUTES_KEYS)
//         .map( key => AttributeType[key as keyof typeof AttributeType])