import {createContext, Dispatch, SetStateAction, useState} from "react";

/**
 * This code is used to help with Z-index ordering of non-modal / floating windows.
 *
 * It manages a simple array that dictates the stack order.
 */

export const NonModalZOrderContext = createContext<[string[], Dispatch<SetStateAction<string[]>>]>([[], ()=>{}]);

export const NonModalZOrderContextProvider: React.FC = (props)=>{
  const [stackedIds, setStackedIds] = useState<string[]>([]);

  return (
      // <NonModalZOrderContext.Provider value={[getZ, bringToFront]}>
      <NonModalZOrderContext.Provider value={[stackedIds, setStackedIds]}>
        {props.children}
      </NonModalZOrderContext.Provider>
  )
}
