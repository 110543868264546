import {Cartesian3} from "cesium";

/**
 * This class is used to test the intersection of blast holes with an arbitrary corridor selection.
 */
export class Corridor {
    private readonly _p0: Cartesian3;
    private readonly _p1: Cartesian3;
    private readonly _p2: Cartesian3;
    private readonly _p3: Cartesian3;
    private readonly _wallThickness: number;

    private readonly _normal: Cartesian3;
    private readonly _D: number;
    private readonly _xAxis: {x: number, y: number};
    private readonly _yAxis: {x: number, y: number};

    private readonly _minX: number;
    private readonly _maxX: number;
    private readonly _minY: number;
    private readonly _maxY: number;

    constructor(p1: Cartesian3, p2: Cartesian3, p3: Cartesian3, wallThickness: number) {
        this._p0 = p1;
        this._p1 = p2;
        this._p2 = p3;
        this._p3 = this.computeFourthCorner( p1, p2, p3 );
        this._wallThickness = wallThickness;

        /*
         Compute the normal and D coefficient
         */

        // ... Create two vectors in the plane
        const v1 = {x: this._p1.x - this._p0.x, y: this._p1.y - this._p0.y, z: this._p1.z - this._p0.z};
        const v2 = {x: this._p2.x - this._p0.x, y: this._p2.y - this._p0.y, z: this._p2.z - this._p0.z};

        // ... Compute the cross product of these vectors to get a vector normal to the plane
        this._normal = new Cartesian3(
            v1.y * v2.z - v1.z * v2.y,
            v1.z * v2.x - v1.x * v2.z,
            v1.x * v2.y - v1.y * v2.x
        );

        // ... Compute the coefficient D
        this._D = - this._normal.x * this._p0.x - this._normal.y * this._p0.y - this._normal.z * this._p0.z;

        // ... Define local x-axis and y-axis based on rectangle corners
        this._xAxis = {
            x: this._p1.x - this._p0.x,
            y: this._p1.y - this._p0.y
        };
        this._yAxis = {
            x: this._p2.x - this._p0.x,
            y: this._p2.y - this._p0.y
        };

        // ... Some advanced calculations that will be reused
        const origin = this._p0;
        const localA = this.toLocal(this._p0, origin);
        const localB = this.toLocal(this._p1, origin);
        const localC = this.toLocal(this._p2, origin);
        const localD = this.toLocal(this._p3, origin);
        this._minX = Math.min( localA.x, localB.x, localC.x, localD.x);
        this._maxX = Math.max( localA.x, localB.x, localC.x, localD.x);
        this._minY = Math.min( localA.y, localB.y, localC.y, localD.y);
        this._maxY = Math.max( localA.y, localB.y, localC.y, localD.y);
    }

    public contains( arbitraryPoint: Cartesian3 ): boolean {
        // ... Compute the distance from the point to the plane
        let distance = Math.abs(this._normal.x * arbitraryPoint.x + this._normal.y * arbitraryPoint.y + this._normal.z * arbitraryPoint.z + this._D) / Math.sqrt(this._normal.x * this._normal.x + this._normal.y * this._normal.y + this._normal.z * this._normal.z);

        // ... Are we too far?
        if ( distance > this._wallThickness/2 ) {
            return false
        }

        // ... Now project the point onto the wall plane
        const projectedPoint = this.projectOntoPlane( arbitraryPoint, this._normal, distance );

        const origin = this._p0;
        const localPoint = this.toLocal(projectedPoint, origin);

        return localPoint.x >= this._minX && localPoint.x <= this._maxX && localPoint.y >= this._minY && localPoint.y <= this._maxY;
    }

    // ... Function to convert a point to local coordinates
    private toLocal(p: Cartesian3, origin: Cartesian3): { x: number, y: number } {
        const dx = p.x - origin.x;
        const dy = p.y - origin.y;
        return {
            x: dx * this._xAxis.x + dy * this._xAxis.y,
            y: dx * this._yAxis.x + dy * this._yAxis.y
        };
    };

    /**
     * Function to project a point onto a plane
     * @param point
     * @param normal
     * @param distance
     */
    private projectOntoPlane(point: Cartesian3, normal: Cartesian3, distance: number): Cartesian3 {
        const scale = distance / (normal.x ** 2 + normal.y ** 2 + normal.z ** 2);
        return new Cartesian3(
            point.x - scale * normal.x,
            point.y - scale * normal.y,
            point.z - scale * normal.z
        );
    }

    private computeFourthCorner(A: Cartesian3, B: Cartesian3, C: Cartesian3): Cartesian3 {
        return new Cartesian3(
            A.x + (B.x - A.x) + (C.x - A.x),
            A.y + (B.y - A.y) + (C.y - A.y),
            A.z + (B.z - A.z) + (C.z - A.z)
        );
    }
}