import React, {useEffect, useMemo} from "react";

import "../viewer.css";
import {withTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {
  FloatingColorLegendVisibilityProvider
} from "../components/Gen2/components/Contexts/FloatingColorLegendVisibilityContextProvider";
import {NonModalZOrderContextProvider} from "../components/Gen2/components/Contexts/NonModalZOrderContextProvider";
import ErrorBoundary from "../components/Gen2/components/BasicControls/ErrorBoundary";
import {TooltipContextProvider} from "../components/Gen2/components/Contexts/TooltipContextProvider";
import {
  UserSessionContextProvider
} from "../components/Gen2/components/Contexts/UserSessionContext";
import TilesetView2D from "../components/Gen2/components/Views/TilesetView2D";
import TilesetView3D from "../components/Gen2/components/Views/TilesetView3D";
import ContentPage from "../components/Gen2/components/BasicControls/ContentPage";

/**
 * The floating color legend is tied to the side menu
 */
const CesiumPage2: React.FC = () => {

    /*
    Hooks
     */
    const [searchParams] = useSearchParams();
    const patternIds = useMemo(()=> searchParams.get("patternId")?.split(",").filter(s=>s.trim().length > 0) ?? [], [searchParams]);
    const sessionUuid = useMemo(()=> searchParams.get("sessionUuid") ?? undefined, [searchParams]);

    // const [cameraVantagePoint, setCameraVantagePoint] = useState<ICameraVantagePoint>();

    return (
      <>
        <div className={"w3-display-container"}>
          <ErrorBoundary>
            { patternIds && sessionUuid && (
              <UserSessionContextProvider sessionUuid={sessionUuid} patternIds={patternIds}>
                <NonModalZOrderContextProvider>
                  <FloatingColorLegendVisibilityProvider>
                    <TooltipContextProvider>
                      <div id={'cesiumViewer'} className="cesiumViewer" onClick={(e) => e.stopPropagation()}>

                        {/* 3D View */}
                        <TilesetView3D />

                        {/* FLOATING CONTAINER OR THE 2D VIE */}
                        <TilesetView2D />

                      </div>
                    </TooltipContextProvider>
                  </FloatingColorLegendVisibilityProvider>
                </NonModalZOrderContextProvider>
              </UserSessionContextProvider>
            )}
          </ErrorBoundary>
        </div>
      </>
  );
};

export default withTranslation()(CesiumPage2);
