import React, {InputHTMLAttributes} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWarning} from "@fortawesome/free-solid-svg-icons";

interface IProps extends WithTranslation, InputHTMLAttributes<any> {
  label: string;
  error?:any;
}

const CustomerFormField: React.FC<IProps> = (props) => {
  const { t, i18n, tReady, ...inputProps} = props;

  return (
      <>
        <div className={'w3-margin-bottom'} style={{height: "100%"}}>
          <div>
            <label htmlFor={props.id} className={'w3-small'}>
              <b>{t(props.label)}:</b>
            </label>
          </div>
          <div>
            <input {...inputProps} />
            {props.error && (<span className="w3-text-red"><FontAwesomeIcon icon={faWarning} /> {props.error}</span>)}
          </div>
        </div>
      </>
)
}

export default withTranslation()(CustomerFormField);
