import React, {CSSProperties, useMemo, useState} from "react";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import useDocumentEvent from "../../../../hooks/useDocumentEvent";
import {HeadingPitchRange, SceneMode} from "cesium";
import TilesetView from "./TilesetView";
import SideMenu from "./SideMenu";
import {ColorLegendFlags} from "../Contexts/FloatingColorLegendVisibilityContextProvider";
import FloatingContainer from "../BasicControls/FloatingContainer";
import CollapsibleColorLegend from "../Widgets/CollapsibleColorLegend";
import BottomToolbar from "../Widgets/BottomToolbar";
import {getHeadingPitchRange} from "../../hooks/useCameraControls";
import {t} from "i18next";
import {ICameraVantagePoint} from "./ICameraVantagePoint";
import {useColorLegendVisibilityContext} from "../../hooks/useFloatingColorLegendVisibilityContext";

const STYLE_3D: CSSProperties = {
  width: "calc(100vw - 45px)",
  height: "calc(100vh - 39px)",
  marginLeft: "45px"
};

const TilesetView3D: React.FC = (props) => {
  const [userSession, userSessionActions] = useUserSessionContext();
  const [cameraVantagePoint3D, setCameraVantagePoint3D] = useState<ICameraVantagePoint>();
  //const [floatingColorLegendVisibility] = useContext(FloatingColorLegendVisibilityContext);
  const [colorLegendVisibilityFlags, setColorLegendVisibilityFlags] = useColorLegendVisibilityContext();
  const [floatingColorLegendExpanded, setFloatingColorLegendExpanded] = useState<boolean>(false);
  const [linearSelectionMode, setLinearSelectionMode] = useState<boolean>(false);
  const [loadingComplete, setLoadingComplete] = useState<boolean>();

  /**
   * Keyboard input handler
   */
  useDocumentEvent("keydown", (e) => {
    e.stopPropagation();
    // console.log(`useDocumentEvent[keydown]: ${e.key}`);
    switch (e.key) {
      case "ArrowUp":
        userSessionActions.moveSelectedCrossSectionOffset(1);
        break;
      case "ArrowDown":
        userSessionActions.moveSelectedCrossSectionOffset(-1);
        break;
      case "PageUp":
        userSessionActions.moveSelectedCrossSectionOffset(10);
        break;
      case "PageDown":
        userSessionActions.moveSelectedCrossSectionOffset(-10);
        break;
      case "End":
        //TODO
        break;
      case "Home":
        userSessionActions.resetSelectedCrossSectionOffset();
        break;
    }
  });

  const sessionTitle = useMemo(()=>{
    return userSession.userSession?.getName() ;
  }, [userSession.userSession]);

  return (
      <>
        {/* SIDE MENU */}
        <SideMenu onCameraVantagePointSet={setCameraVantagePoint3D}/>

        {/* THE 3D VIEW */}
        <TilesetView
            style={STYLE_3D}
            sceneMode={SceneMode.SCENE3D}
            cameraVantagePoint={cameraVantagePoint3D}
            linearSelectionMode={linearSelectionMode}
            onLinearSelectionModeChanged={setLinearSelectionMode}
            onLoadingComplete={()=>setLoadingComplete(true)}
        >
          { sessionTitle && sessionTitle.length > 0 && (
              <div
                  className="w3-container w3-block w3-center"
                  style={{ position: "absolute", top: "5px" }}
              >
                <div className="w3-grey w3-tag w3-round w3-opacity w3-hover-opacity-off" style={{userSelect: "none"}}>
                  {t("saved_session") + ": " + sessionTitle}
                </div>
              </div>
          )}
          {(colorLegendVisibilityFlags === (ColorLegendFlags.FloatingLegendAllowed)) && (
              <FloatingContainer
                  name={"CollapsibleColorLegend"}
                  className={`w3-round w3-opacity w3-hover-opacity-off`}
                  snapPosition={userSession.colorLegendSnapPosition}
                  onPositionChanged={userSessionActions.setColorLegendSnapPosition}
              >
                <CollapsibleColorLegend
                    expanded={floatingColorLegendExpanded}
                    onExpandedChanged={(expanded, width, height) => {
                      setFloatingColorLegendExpanded(expanded)
                    }}
                    onCameraVantagePointSet={setCameraVantagePoint3D}
                />
              </FloatingContainer>
          )}
        </TilesetView>

        {/* BOTTOM TOOLBAR */}
        {userSession.showToolbar && loadingComplete &&
            <BottomToolbar
                onSetCameraDirection={(direction, paddingFactor)=>{
                  if (userSession.boundingSphere) {
                    setCameraVantagePoint3D({
                      boundingSphere: userSession.boundingSphere,
                      hpr: getHeadingPitchRange(direction, userSession.boundingSphere.radius * 2 * paddingFactor)
                    });
                  }
                }}
                onResetCamera={()=>{
                  if (userSession.boundingSphere) {
                    setCameraVantagePoint3D({
                      boundingSphere: userSession.boundingSphere,
                      hpr: new HeadingPitchRange(-Math.PI/4, -Math.PI / 4, userSession.boundingSphere.radius * 2),
                    });
                  }
                }}
                linearSelectionMode={linearSelectionMode}
                onLinearSelectionModeChanged={setLinearSelectionMode}
            />
        }
      </>
  )
}

export default React.memo(TilesetView3D);
