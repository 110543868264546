import {Dispatch, SetStateAction, useContext} from "react";
import {
  ColorLegendFlags,
  FloatingColorLegendVisibilityContext
} from "../components/Contexts/FloatingColorLegendVisibilityContextProvider";

export const useColorLegendVisibilityContext = (): [ColorLegendFlags, Dispatch<SetStateAction<ColorLegendFlags>>] => {
  const [visibilityFlags, setVisibilityFlags] = useContext(FloatingColorLegendVisibilityContext);
  return [visibilityFlags, setVisibilityFlags] ;
}
