import { faBan, faCheckCircle, faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import IPattern from "../model/IPattern";
import { t } from "i18next";
import DotMenu from "./DotMenu/DotMenu";
import { PatternAndAssetsApi } from "../lib/routes";
import { useFetchWithAuth } from "../lib/auth/fetchWithAuth";
import {useMemo, useState} from "react";
import ListItem from "./List/ListItem";
import UserSession from "../lib/userSession";

interface Props {
  pattern: IPattern;
  selected: boolean;
  onSelectionChanged: (pattern: IPattern, selected: boolean) => void;
  onActiveStateChanged: (pattern: IPattern) => void;
  onPatternDeleteRequested: (pattern: IPattern) => void;
}

const PatternListItem: React.FC<Props> = ({
  pattern,
  selected,
  onSelectionChanged,
  onActiveStateChanged,
  onPatternDeleteRequested,
}) => {
  const fetchWithAuth = useFetchWithAuth();
  const isAdmin = useMemo(()=>UserSession.isInRoles(["SITE_ADMIN"]), []);
  const [isInactive, setIsInactive] = useState<boolean>(
    pattern.tags.includes("inactive")
  );

  const setPatternActive = async (pattern: IPattern, active: boolean) => {
    const url = new URL(PatternAndAssetsApi.updatePatternTagUrl(pattern.patternId, ["inactive"]));

    // we delete the inactive tag when we activate
    const method = active ? "DELETE" : "POST";

    console.log(method, " to ", url.toString());

    const response = await fetchWithAuth(url.toString(), {
      method,
    });

    if (response?.ok) {
      pattern.tags = [
        ...pattern.tags.filter((tag) => tag != "inactive"),
        ...(active ? [] : ["inactive"]),
      ];
      setIsInactive(!active);
      onActiveStateChanged(pattern);
    } else console.error("Error setting pattern active tag: ", active);
  };

  return (
    <label htmlFor={`check-label-${pattern.name}`}>
      <ListItem>
        <div className="w3-cell" style={{ width: "25%" }}>
          <input
            id={`check-label-${pattern.name}`}
            className="w3-check w3-margin-right tour-patterns-check-item"
            type="checkbox"
            checked={selected}
            onChange={(e) => onSelectionChanged(pattern, e.target.checked)}
          />
          &nbsp;
          {pattern.name}
        </div>

        <div className="w3-cell w3-cell-middle" style={{ width: "20%" }}>
          {format(pattern.start, t("LONG_DATETIME_FORMAT"))}
        </div>
        <div className="w3-cell w3-cell-middle" style={{ width: "20%" }}>
          {format(pattern.end, t("LONG_DATETIME_FORMAT"))}
        </div>
        <div className="w3-cell w3-cell-middle" style={{ width: "20%" }}>
          {pattern.dmmRevisionTimestamp
            ? format(pattern.dmmRevisionTimestamp, t("LONG_DATETIME_FORMAT"))
            : t("n/a")
          }
        </div>
        <div className="w3-cell w3-cell-middle">
          {isInactive ? (
            <>
              <div
                className="w3-left w3-cell w3-text-black"
                style={{ width: "100px" }}
              >
                {t("inactive")}
              </div>
            </>
          ) : (
            <>
              <div className="w3-left w3-cell" style={{ width: "100px" }}>
                {t("active")}
              </div>
            </>
          )}
        </div>
        <div className="w3-cell w3-cell-middle">
          <DotMenu
            style={{ padding: "8px 16px", float: "right" }}
          >
            <div
                className="w3-dropdown-content w3-bar-block w3-card-4 w3-show"
            >
              <button
                className="w3-bar-item w3-button"
                onClick={() => setPatternActive(pattern, true)}
                disabled={!isInactive}
              >
                <FontAwesomeIcon icon={faCheckCircle} /> {t("activate")}
              </button>
              <button
                className="w3-bar-item w3-button"
                onClick={() => setPatternActive(pattern, false)}
                disabled={isInactive}
              >
                <FontAwesomeIcon icon={faBan} /> {t("deactivate")}
              </button>
              { isAdmin &&
                <button
                    className="w3-bar-item w3-button"
                    onClick={() => onPatternDeleteRequested(pattern)}
                >
                  <FontAwesomeIcon icon={faRemove} /> {t("delete")}
                </button>
              }
            </div>
          </DotMenu>
        </div>
      </ListItem>
    </label>
  );
};

export default PatternListItem;
