import { format, sub } from "date-fns";
import { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import IFilter from "./IFilter";
import LastPeriodFilter from "./LastPeriodFilter";
import MultiDateFilter from "./MultiDateFilter";

const LAST_DAYS_OPTIONS = [7, 15, 30, 90, 180];

interface Props extends WithTranslation {
  onFilterChanged: (newFilter: IFilter) => void;
}

const FiltersContainer: React.FC<Props> = ({ onFilterChanged, t }) => {
  const [nameFilter, setNameFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [sliderValue, setSliderValue] = useState<number>(); // "All" selected
  const [includeInactive, setIncludeInactive] = useState<boolean>(true);

  const handleFilterReset = () => {
    setSliderValue(undefined);
    handleChange({
      name: "",
      startDate: "",
      endDate: "",
    });
  };

  const handleChange = (changeObject: Partial<IFilter>) => {
    changeObject.name !== undefined && setNameFilter(changeObject.name);
    changeObject.endDate !== undefined &&
      setEndDateFilter(changeObject.endDate);
    changeObject.startDate !== undefined &&
      setStartDateFilter(changeObject.startDate);
    changeObject.includeInactive !== undefined &&
      setIncludeInactive(changeObject.includeInactive);

    onFilterChanged({
      name: nameFilter,
      startDate: startDateFilter,
      endDate: endDateFilter,
      includeInactive: includeInactive,
      ...changeObject,
    });
  };

  return (
    <>
      {/*<div className="w3-row">*/}
      {/*  <div className="w3-col m6">*/}
      {/*    <h3>{t("filter_patterns")}</h3>*/}
      {/*  </div>*/}
      {/*  <div className="w3-col m6">*/}
      {/*    <button*/}
      {/*      className="w3-button w3-orange w3-right w3-round-medium"*/}
      {/*      style={{ marginTop: "10px" }}*/}
      {/*      onClick={handleFilterReset}*/}
      {/*    >*/}
      {/*      {t("reset_filters")}*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="w3-row w3-margin-top">
        <div className="w3-col m12" style={{ padding: "0 5px 5px 0" }}>
          <MultiDateFilter
            startDate={startDateFilter}
            endDate={endDateFilter}
            onChange={(start, end) => {
              console.log("change:");
              console.log(start, end);
              setSliderValue(start || end ? -1 : undefined);
              handleChange({ startDate: start, endDate: end });
            }}
          />
        </div>
        <div className="w3-col m12 w3-margin-top">
          <LastPeriodFilter
            selectedOption={sliderValue}
            lastDaysOptions={LAST_DAYS_OPTIONS}
            onOptionSelected={(option) => {
              setSliderValue(option);
              if (option) {
                handleChange({
                  startDate: format(
                    sub(new Date(), { days: option }),
                    t("SHORT_DATE_FORMAT")
                  ),
                  endDate: format(new Date(), t("SHORT_DATE_FORMAT")),
                });
              } else {
                handleChange({
                  startDate: "",
                  endDate: "",
                });
              }
            }}
          />
        </div>
      </div>
      <div className="w3-row w3-margin-top">
        <div className="w3-col m12" style={{ padding: "5px 0 5px 0" }}>
          <label htmlFor="textSearch">{t("pattern_name")}</label>
          <input
            id="textSearch"
            className="w3-input w3-border w3-padding"
            style={{ margin: "5px 0 0 0" }}
            type="text"
            placeholder={t("search_available_patterns")}
            value={nameFilter}
            onChange={(e) => handleChange({ name: e.target.value })}
          />
        </div>
        {/*<div className="w3-col m6">*/}
        {/*  <label>*/}
        {/*    <b>&nbsp;</b>*/}
        {/*  </label>*/}
        {/*</div>*/}
      </div>
      <div className="w3-row w3-margin-top">
        <div className="w3-col m12">
          <label htmlFor="inclInactive">{t("show_inactive")}</label>
          <input
            id="inclInactive"
            className="w3-check w3-margin"
            type="checkbox"
            checked={includeInactive}
            onChange={(e) =>
              handleChange({ includeInactive: e.target.checked })
            }
          />
        </div>
      </div>
      <div className="w3-margin w3-center">
        <button
            className="w3-button w3-orange w3-round-medium"
            style={{ marginTop: "10px" }}
            onClick={handleFilterReset}
        >
          {t("Reset")}
        </button>
      </div>
    </>
  );
};

export default withTranslation()(FiltersContainer);
