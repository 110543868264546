import React, {useCallback, useEffect, useMemo, useState} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {useFetchWithAuth} from "../lib/auth/fetchWithAuth";
import {apiRoot} from "../lib/routes";
import ColumnPane from "../components/Gen2/components/BasicControls/ColumnPane";
import RoundedPanel from "../components/Gen2/components/BasicControls/RoundedPanel";
import TitledSection from "../components/Gen2/components/BasicControls/TitledSection";
import ContentPage from "../components/Gen2/components/BasicControls/ContentPage";
import {useMap} from "usehooks-ts";
import LoggedInUsersList from "../components/CustomerManagement/LoggedInUsersList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons";

const MARGIN_HEIGHT = 16;//px

/**
 * Component page
 * @param t
 * @constructor
 */

export interface ILoggedInUser {
  id: number;
  userName: string;
  customerSiteName: string;
  lastAccessTimeStamp: Date;
}

const ActiveUsers: React.FC<WithTranslation> = ({ t }) => {
  const loggedInUsersApi = useLoggedInUsersApi();

  const [loggedInUsers, loggedInUserActions] = useMap<string, ILoggedInUser[]>([]);
  const setAll = loggedInUserActions.setAll;

  useEffect(()=>console.log(`loggedInUsersApi has changed`), [loggedInUsersApi]);
  useEffect(()=>console.log(`loggedInUsers has changed`), [loggedInUsers]);
  useEffect(()=>console.log(`loggedInUserActions has changed`), [loggedInUserActions]);

  /**
   * Reload all logged-in users
   */
  const reload = useCallback(() => {
    let map = new Map<string, ILoggedInUser[]>();
    loggedInUsersApi.fetchLoggedInUsers()
        .then( result => {
          console.log(`fetchLoggedInUsers: result=${JSON.stringify(result)}`)
          result.forEach( loggedInUser => {
            let siteList = map.get(loggedInUser.customerSiteName);
            if ( !siteList ) {
              siteList = [] as ILoggedInUser[] ;
            }
            siteList.push( loggedInUser ) ;
            map.set(loggedInUser.customerSiteName, siteList);
          })
          setAll( map ) ;
        } )
  }, [loggedInUsersApi, setAll]);

  /**
   * At first render
   */
  useEffect(() => {
    reload();
  }, [reload]);

  const [columnPaneHeight, setColumnPaneHeight] = useState<number>();

  const maxWorkAreaHeight = useMemo(()=>{
    if (columnPaneHeight) {
      return columnPaneHeight - 2 * MARGIN_HEIGHT ;
    } else {
      return undefined ;
    }
  }, [columnPaneHeight]);

  return (
    <>
      <ContentPage>
        <ColumnPane
            className="w3-rest" style={{width: "calc(100%)"}}
            onSizeChanged={(w,h)=>setColumnPaneHeight(h)}
        >
          <RoundedPanel>
            <div
                style={{
                  maxHeight: maxWorkAreaHeight ? `${maxWorkAreaHeight}px` : undefined,
                  overflowY: 'auto'
                }}
            >
              <div className={'w3-container'}>
                <span
                    className="w3-button w3-hover-shadow w3-light-blue w3-hover-light-grey w3-round-medium w3-right"
                    onClick={()=>reload()}
                >
                  {t("Reload")}
                  <FontAwesomeIcon icon={faRotateRight} className="w3-margin-left" />
                </span>
              </div>
              {
                loggedInUsers.size > 0
                  ? Array.from(loggedInUsers.entries()).map( ([customerSiteName, siteUsers]) => (
                    <TitledSection
                        key={customerSiteName}
                        className={'w3-margin-top'}
                        w3BorderColor={'w3-border-light-blue'}
                        title={customerSiteName}
                    >
                      <div style={{ width: "calc(100% - 8em)"}}>
                        <LoggedInUsersList
                            loggedInUsers={siteUsers}
                        />
                      </div>
                    </TitledSection>
                  ))
                  : <div className={"w3-padding w3-center"}>
                      <i>({t('There are no other users currently logged in')})</i>
                    </div>
              }
            </div>
          </RoundedPanel>
        </ColumnPane>
      </ContentPage>
    </>
  );
};

export default withTranslation()(ActiveUsers);

/**
 * API
 * @param t
 * @constructor
 */
class LoggedInUsersUrls {
  public static allRecordsUrl = () => `${apiRoot}/logged_in`;
}

export const useLoggedInUsersApi = () => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchLoggedInUsers = useCallback(async (): Promise<ILoggedInUser[]> => {

    return await fetchWithAuth( LoggedInUsersUrls.allRecordsUrl() )
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`fetchLoggedInUsers: status=${resp.status}`)
          }
          return resp.json();
        })
        .then( json => {
          return (json as ILoggedInUser[]).map( item => ({
            id: item.id,
            userName: item.userName,
            customerSiteName: item.customerSiteName,
            lastAccessTimeStamp: new Date(item.lastAccessTimeStamp)
          } as ILoggedInUser ))
        })
  }, [fetchWithAuth]);

  return useMemo(() => ({
    fetchLoggedInUsers,
  }), [
    fetchLoggedInUsers,
  ]);
}

