import { useCallback, useEffect, useRef } from "react";

/**
 * Wrapper hook to achieve an interval behaviour, except we wait on the callback to finish before scheduling the next run.
 * This allows a network polling strategy that uses retries, without starting parallel requests.
 * @param callback The function to execute at a repeated interval
 * @param delay The delay, in millisecond, between callback calls
 */
const useSafeInterval = (callback: () => void, delay: number | undefined) => {
  // Using a ref so that we persist the timeout id between renders
  let id = useRef<any>();

  // Memoizing the callback for the effect
  // To avoid recreating it on each re-render
  const resetIntervalCallback = useCallback(async () => {
    await callback();
    id.current = setTimeout(resetIntervalCallback, delay);
  }, [callback, delay]);

  // Using an effect to make sure we cleanup the timeout when we the component unloads
  useEffect(() => {
    if (!delay) return;

    id.current = setTimeout(resetIntervalCallback, delay);

    return () => clearTimeout(id.current);
  }, [callback, delay, resetIntervalCallback]);
};

export default useSafeInterval;
