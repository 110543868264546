import React, {createContext, useCallback, useEffect, useMemo, useState} from "react";
import {INotification, notificationsAreEqual} from "./INotification";
import {withTranslation} from "react-i18next";
import {useServerNotificationApi} from "./ServerNotificationApi";
import {useRefreshAuthTokens} from "../../../lib/auth/refreshAuthTokens";
import { v4 } from "uuid";
import NotificationsDialog from "./NotificationsDialog";

export const NotificationsContext = createContext<any>({});

export interface INotificationActions {
  createNotification: (text: string, type: "info"|"warning"|"error")=>void;
  acknowledgeNotifications: (notifications: INotification[])=>void;
  deleteNotifications: (notifications: INotification[])=>void;
  showDialog: ()=>void;
}

const NotificationsProvider: React.FC = (props)=>{
  const serverNotificationsApi = useServerNotificationApi();
  const refreshAuthTokens = useRefreshAuthTokens();
  const [notifications, _setNotifications] = useState<INotification[]>([]);
  const [showDialog, setShowDialog] = useState<boolean|undefined>();

  const fetchUserEvents = serverNotificationsApi.fetchUserEvents ;
  const deleteUserEvents = serverNotificationsApi.deleteUserEvents ;

  // useEffect(()=>console.log(`Notifications: count=${notifications.length}`), [notifications]);

  const setNotifications = useCallback((updater: (prevState: INotification[]) => INotification[]) => {
    const compareNotifications = (a: INotification, b: INotification ): number => {
      return -(a.timeStamp - b.timeStamp) ;
    }
    _setNotifications( prevState => updater(prevState).sort(compareNotifications) );
  }, []);

  /**
   * Update existing notifications
   * @param updatedNotifications
   */
  const updateNotifications = useCallback((updatedNotifications: INotification[]) => {
    setNotifications(prevState => {
      let newState: INotification[] = [];
      let hasChanged = false ;
      prevState.forEach( prevNotification => {
        const updatedNotification = updatedNotifications.find( n => n.id === prevNotification.id );
        if ( updatedNotification && !notificationsAreEqual( prevNotification, updatedNotification) ) {
          newState.push( updatedNotification ) ;
          hasChanged = true ;
        } else {
          newState.push( prevNotification ) ;
        }
      })
      return hasChanged ? newState : prevState;
    })
  }, []);

  /**
   * Removes one or more notifications from the state
   * @param notificationsToRemove
   */
  const removeNotifications = useCallback((notificationsToRemove: INotification[]) => {
    setNotifications( prevState => {
      return (notificationsToRemove.length === 0 ) ? prevState
          : prevState.filter(notification => notificationsToRemove.find(n=>n.id === notification.id) === undefined);
    })
  }, []);

  /**
   * Fetch server notifications (aka user events) from the backend
   */
  const refreshUserEvents = useCallback(async() => {
    return await refreshAuthTokens()
        .then( async ()=> {
          // console.log('fetchUserEvents...');
          fetchUserEvents()
              .then( updatedNotifications => {
                // console.log(`fetchUserEvents: ${updatedNotifications.length} notifications`);
                setNotifications( prevState => {

                  // ... Identify new notifications
                  let newNotifications: INotification[] = [];

                  updatedNotifications.forEach( notification => {
                    const match = prevState.find( n => n.id === notification.id );
                    if ( !match ) {
                      newNotifications.push( notification );
                    }
                  })

                  // ... has the list changed
                  const hasChanged = newNotifications.length > 0 ;//|| obsoleteNotifications.length > 0 ;

                  if ( hasChanged ) {
                    return [
                      ...prevState,
                      ...newNotifications
                    ]
                  } else {
                    return prevState;
                  }
                })
              })

        })
        .catch( error => {
          console.error(`refreshUserEvents: ${error}`);
        })
  }, [refreshAuthTokens, fetchUserEvents]);

  /**
   * Setup periodic fetching of server notifications
   */
  useEffect(()=>{
    let id = setInterval(refreshUserEvents, 5000);
    return () => {
      clearTimeout(id);
    }
  }, [refreshUserEvents]);

  /**
   * Create a new local client notification
   */
  const createNotification = useCallback( (text: string, type: "info"|"warning"|"error")=> {
    // console.log(`createNotification: ${text} (${type})`);
    const newNotification: INotification = {
      id: v4(),
      isRead: false,
      source: "client",
      text: text,
      timeStamp: Date.now(),
      type: type
    };
    setNotifications( prevState => ([...prevState, newNotification]));
  }, []);

  /**
   * Acknowledge one or more notifications
   */
  const acknowledgeNotifications = useCallback( (notifications: INotification[])=> {
    // console.log(`acknowledgeNotifications: ${notifications.map(n=>JSON.stringify(n, null, 2))}`);

    updateNotifications( notifications.filter( notification => !notification.isRead )
        .map( notification => ({ ...notification, isRead: true }) ) );

  }, [updateNotifications]);

  /**
   * Delete one or more notifications
   */
  const deleteNotifications = useCallback( (notificationsToDelete: INotification[])=> {
    console.log(`deleteNotifications: ${notificationsToDelete.map(n=>JSON.stringify(n, null, 2))}`);
    // ... Server notifications must be acknowledged at the backend
    let serverNotifications = notificationsToDelete.filter( notification => notification.source === "server" );
    deleteUserEvents( serverNotifications )
        .then( deleted => removeNotifications( serverNotifications ) )

    // ... Client notifications are updated locally
    removeNotifications( notificationsToDelete.filter( notification => notification.source === "client" ) )

  }, [deleteUserEvents, removeNotifications]);

  const actions = useMemo((): INotificationActions =>({
    createNotification,
    acknowledgeNotifications,
    deleteNotifications,
    showDialog: ()=>setShowDialog(true)
  }), [
    createNotification,
    acknowledgeNotifications,
    deleteNotifications
  ]);

  return (
      <NotificationsContext.Provider value={[notifications, actions]}>
        {props.children}
        { showDialog && <NotificationsDialog onDismiss={()=>setShowDialog( undefined )}/> }
      </NotificationsContext.Provider>
  )
}

export default withTranslation()(NotificationsProvider);

export const useNotifications = (): [INotification[], INotificationActions ] => {
  const [notifications, notificationActions] = React.useContext(NotificationsContext);
  return [notifications, notificationActions];
}
