import React from "react";
import SubMenuButton, {SubMenuButtonState} from "../../../SideMenuItems/SubMenuButton";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {IHelpRequestedProps} from "../../../../Help/HelpContextProvider";

interface IProps extends IHelpRequestedProps {
  id?:string;
  label: string;
  icon: IconProp;
  buttonState: SubMenuButtonState;
  onSubMenuButtonStateChange: (value: SubMenuButtonState) => void;
  pillValue?:      number;
  onPillClicked?:  ()=>void;
}

const SideMenuContainer: React.FC<IProps> = (props) => {
  return (
      <>
        <SubMenuButton
            id={props.id}
            label={props.label}
            icon={props.icon}
            state={props.buttonState}
            onSubMenuButtonStateChange={props.onSubMenuButtonStateChange}
            pillValue={props.pillValue}
            onPillClicked={props.onPillClicked}
            helpTitle={props.helpTitle}
            helpContent={props.helpContent}
        />
        <div
            className={`w3-center w3-white w3-card w3-theme-d4 w3-border w3-border-light-blue ${
                props.buttonState === SubMenuButtonState.Expanded ? "w3-show" : "w3-hide"
            }`}
        >
          {props.children}
        </div>
      </>
  )
}

export default SideMenuContainer;
