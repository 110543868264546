import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

import TextAndImage from "./Widgets/TextAndImage";
import {VideoResourceUrl} from "../lib/VideoResourceUrl";

/**
 * Heo Modeling Layers side menu help
 *
 * @param props
 * @constructor
 */

const Help_CesiumView_SideMenu_Layers: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <>
        <div className={'w3-panel'}>
          <h2 className={"w3-left-align"}>{t("The 'Layers' Side Menu")}</h2>
          <p className={"text-full-justify"}>
            {t("This section contains viewing parameters for the various layers that are supported in this application.")}
          </p>
        </div>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Show/Hide Layers"}
                      text={"Use the 'Show' checkbox to toggle the visibility of one or more layers."}
                      src={VideoResourceUrl("layer_visibility.webm")}
                      imgRatio={"w3-half"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Adjust Layer Opacity"}
                      text={"Use the 'Opacity' slider to adjust the opacity of one or more layers."}
                      src={VideoResourceUrl("layer_opacity.webm")}
                      imgRatio={"w3-half"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Reset layer parameters"}
                      text={"Use the 'Reset' button to reset the layer parameters to their default values."}
                      src={VideoResourceUrl("layer_reset.webm")}
                      imgRatio={"w3-half"}/>

      </>
  )
}

export default withTranslation()(Help_CesiumView_SideMenu_Layers);

