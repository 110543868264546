import ptLogo from "./img/PeckTech_Endorsed_Logo_Reverse.png";
import React from "react";

export default () => (
  <main style={{ padding: "1rem" }}>
    <div className="w3-container w3-center">
      <img src={ptLogo} style={{ width: "25%" }} alt={''}/>
      <h1>The URL or resource could not be found.</h1>
    </div>
  </main>
);
