import React, {useCallback, useMemo, useState} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {t} from "i18next";
import ButtonFooter from "../Gen2/components/BasicControls/ButtonFooter";
import {DEFAULT_USER_SESSION} from "../Gen2/components/Contexts/UserSessionContext";

export interface IBlastholeFracturesLayerSettings {
    collarDepth: number;
    prominenceFilter: number;
}

interface IBlastholeFracturesLayerSettingsDialogProps extends WithTranslation {
    settings: IBlastholeFracturesLayerSettings;
    onAccept: (change: IBlastholeFracturesLayerSettings) => void;
    onCancel: () => void;
}

const BORDER_RADIUS = "5px";

const BlastholeFracturesLayerSettingsDialog: React.FC<IBlastholeFracturesLayerSettingsDialogProps> =
({
    settings,
    onAccept,
    onCancel
}) => {

    const [collarDepth, setCollarDepth] = useState<number>(settings.collarDepth) ;
    const [prominenceFilter, setProminenceFilter] = useState<number>(settings.prominenceFilter) ;

    const canBeReset = useMemo(()=>{
        return collarDepth != DEFAULT_USER_SESSION.collarDepth || prominenceFilter !== DEFAULT_USER_SESSION.prominenceFilter;
    }, [collarDepth]);

    const doReset = useCallback(()=>{
        setCollarDepth(DEFAULT_USER_SESSION.collarDepth);
        setProminenceFilter(DEFAULT_USER_SESSION.prominenceFilter);
    }, []);

    return (
        <>
            <div className="w3-modal" style={{display: "block"}}>
                <div className="w3-modal-content w3-card-4 w3-theme-d4" style={{borderRadius: BORDER_RADIUS}}>
                    <header className="w3-container w3-light-blue w3-center" style={{borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0px 0px`}}>
                        <h6>{t("blast_hole_BlastholeFractures_settings")}</h6>
                    </header>

                    <table className="w3-table">
                        <tbody className="w3-container w3-padding">
                        <tr className={"w3-padding"}>
                            <td className={"w3-center"}>
                                {t("collaring_depth")}
                            </td>
                            <td className={"w3-row"}>
                                <input style={{width: "100%"}}
                                       type="range"
                                       min="0"
                                       max="5"
                                       step="0.01"
                                       value={collarDepth}
                                       onChange={(e) => {
                                           setCollarDepth( +e.target.value );
                                       }}
                                />
                            </td>
                            <td>
                                <div
                                    className={"w3-container w3-cell unselectable"}
                                    style={{fontSize: "12px", width: "100px"}}
                                >
                                    <div>({collarDepth.toFixed(2)})</div>
                                </div>
                            </td>
                        </tr>
                        <tr className={"w3-padding"}>
                            <td className={"w3-center"}>
                                {t("Prominence Filter")}
                            </td>
                            <td className={"w3-row"}>
                                <input style={{width: "100%"}}
                                       type="range"
                                       min="0"
                                       max="50"
                                       step="1"
                                       value={prominenceFilter}
                                       onChange={(e) => {
                                           setProminenceFilter( +e.target.value );
                                       }}
                                />
                            </td>
                            <td>
                                <div
                                    className={"w3-container w3-cell unselectable"}
                                    style={{fontSize: "12px", width: "100px"}}
                                >
                                    <div>({prominenceFilter.toFixed(2)})</div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <ButtonFooter
                        borderRadius={BORDER_RADIUS}
                        onAccept={()=>onAccept({collarDepth: collarDepth, prominenceFilter: prominenceFilter})}
                        onReset={canBeReset? (()=>doReset()):undefined}
                        onCancel={onCancel}
                    />

                </div>
            </div>
        </>
    );
};

export default withTranslation()(BlastholeFracturesLayerSettingsDialog);
