import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {useEffect, useState} from "react";
import { WithTranslation, withTranslation } from "react-i18next";

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface Props extends WithTranslation {
  onCreateNewUser: (email: string, password: string) => void;
  errors: any;
}

const validateForm = (
  email: string,
  password: string,
  t: (key: string) => string
) => {
  let errors: any = {};

  if (!String(email).toLowerCase().match(EMAIL_REGEX))
    errors.email = `${t("email")} ${t("email_must_be_valid")}`;
  if (!email) errors.email = `${t("email")} ${t("cannot_be_empty")}`;
  if (!password) errors.password = `${t("password")} ${t("cannot_be_empty")}`;

  return errors;
};

const NewUserForm: React.FC<Props> = (props) => {
  const [errors, setErrors] = useState<any>({});

  useEffect(()=>{
    setErrors(props.errors);
  }, [props.errors]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const eventTarget = e.target as any;
    const email = eventTarget.elements.new_username.value;
    const password = eventTarget.elements.new_password.value;

    const errors = validateForm(email, password, props.t);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      props.onCreateNewUser(email, password);
      eventTarget.elements.new_username.value = '';
      eventTarget.elements.new_password.value = '';
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="w3-row">
          <div className="w3-col m3">
            <label htmlFor="new_username">
              <b>{props.t("email")}</b>
            </label>
            <input
              id="new_username"
              className="w3-input w3-border"
              type="email"
              placeholder={props.t("user@domain.com")}
              // type="text"
              // placeholder={props.t("enter_username")}
              name="new_username"
            />
            {errors.email && (
              <span className="w3-text-red">
                <FontAwesomeIcon icon={faWarning} /> {errors.email}
              </span>
            )}
          </div>
          <div className="w3-col m3" style={{ paddingLeft: "16px" }}>
            <label htmlFor="new_password">
              <b>{props.t("password")}</b>
            </label>
            <input
              id="new_password"
              className="w3-input w3-border"
              type="password"
              placeholder={props.t("enter_password")}
              name="new_password"
              autoComplete="new-password"
            />
            {errors.password && (
              <span className="w3-text-red">
                <FontAwesomeIcon icon={faWarning} /> {errors.password}
              </span>
            )}
          </div>
          <div className="w3-col m3" style={{ paddingLeft: "16px" }}>
            <label>&nbsp;</label>
            <button
              className="w3-button w3-light-blue w3-round-medium w3-block"
              type="submit"
            >
              {props.t("create_user")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withTranslation()(NewUserForm);
