import {Viewer as CesiumViewer} from "cesium";
import {useCallback} from "react";

export function toSquareCanvas( canvas: HTMLCanvasElement, size: number, hwRatio: number ): HTMLCanvasElement {
  // ... Sanity test
  if (size <= 0) throw new Error(`copyCanvas: invalid size ${size}`);
  if (hwRatio <= 0) throw new Error(`copyCanvas: invalid hwRatio ${hwRatio}`);
  // ... Create new canvas
  const newCanvas = document.createElement("canvas");
  newCanvas.setAttribute("width", size.toString());
  newCanvas.setAttribute("height", size.toString());
  const ctx = newCanvas.getContext("2d");
  if (!ctx) throw new Error("copyCanvas: error creating 2D context");
  // ... Define offsets to account for HW ratio
  const dw = hwRatio <= 1 ? size : size / hwRatio;
  const dh = hwRatio < 1 ? hwRatio * size : size;
  const dx = (size - dw) / 2;
  const dy = (size - dh) / 2;
  // ... Fill with BLACK
  ctx.beginPath();
  ctx.rect(0, 0, size, size);
  ctx.fillStyle = "black";
  ctx.fill();
  // ... Copy the image
  ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, dx, dy, dw, dh);
  // ... Return the new canvas element
  return newCanvas;
};

export const useCesiumScreenshot = () => {

  const callback = useCallback((viewer: CesiumViewer) => {
    viewer.render();
    let canvas = viewer.canvas;
    if (canvas) {
      try {
        const ratio = canvas.height / canvas.width;
        let cardCanvas = toSquareCanvas(canvas, 150, ratio);
        console.log(`useCesiumScreenshot: Created screenshot ${canvas.width} x ${canvas.height}`);
        return cardCanvas.toDataURL("image/png");
      } catch (e) {
        console.log("useCesiumScreenshot: copyCanvas threw: " + e);
      }
    } else {
      console.log(`useCesiumScreenshot: element 'cesiumObject.viewer' not found`);
    }
    return undefined;
  }, [])

  return callback ;
}
