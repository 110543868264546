import {createContext, useEffect, useMemo, useState} from "react";
import {useUserSessionContext} from "./UserSessionContext";


export const FloatingColorLegendVisibilityContext = createContext<any>({});

export enum ColorLegendFlags {
  None = 0,
  SideMenuLegendVisible = 1,
  FloatingLegendAllowed = 2
}

export const FloatingColorLegendVisibilityProvider: React.FC = (props)=>{
  const [userSessionState, userSessionStateActions] = useUserSessionContext();

  const [visibilityFlags, setVisibilityFlags] = useState<ColorLegendFlags>(userSessionState.showFloatingLegend ? ColorLegendFlags.FloatingLegendAllowed : ColorLegendFlags.None) ;

  // const showFloatingLegend = userSessionState.showFloatingLegend ;

  //const [floatingColorLegendVisible, setFloatingColorLegendVisible] = useState<boolean>(true);

  //const floatingColorLegendVisibility = useMemo(()=>{return showFloatingLegend && floatingColorLegendVisible}, [showFloatingLegend, floatingColorLegendVisible]);

  useEffect(()=>{
    if (userSessionState.showFloatingLegend ) {
      setVisibilityFlags( prevState => prevState | ColorLegendFlags.FloatingLegendAllowed);
    }
    else {
      setVisibilityFlags( prevState => prevState & ~ColorLegendFlags.FloatingLegendAllowed);
    }
  }, [userSessionState.showFloatingLegend, setVisibilityFlags])

  useEffect(()=>{
    if ((visibilityFlags & ColorLegendFlags.FloatingLegendAllowed) === ColorLegendFlags.FloatingLegendAllowed) {
      userSessionStateActions.setShowFloatingLegend( true );
    }
    else {
      userSessionStateActions.setShowFloatingLegend( false );
    }
  }, [visibilityFlags, userSessionStateActions])

  return (
      <FloatingColorLegendVisibilityContext.Provider value={[visibilityFlags, setVisibilityFlags]}>
        {props.children}
      </FloatingColorLegendVisibilityContext.Provider>
  )
}
