import { useEffect } from "react";

// React hook wrapping the event registering at component mount, and deregistering at component unmount time.
// Typing mimics the one of the standard library for convenience.
//
// Example usage:
//   withEvent('keydown', (e) => { ... doSomething(e); });
//

function useDocumentEvent<TEvent extends keyof DocumentEventMap>(
  eventName: TEvent,
  listener: (this: Document, ev: DocumentEventMap[TEvent]) => any
) {
  return useEffect(() => {
    document.addEventListener(eventName, listener, false);
    return () => {
      document.removeEventListener(eventName, listener, false);
    };
  });
}

export default useDocumentEvent;

export function useWindowEvent<TEvent extends keyof WindowEventMap>(
    eventName: TEvent,
    listener: (this: Window, ev: WindowEventMap[TEvent]) => any
) {
  return useEffect(() => {
    window.addEventListener(eventName, listener, false);
    return () => {
      window.removeEventListener(eventName, listener, false);
    };
  });
}
