import React, {useEffect, useMemo, useState} from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import PageSection from "../components/Gen2/components/BasicControls/PageSection";
import NewUserForm from "../components/UserAdministration/NewUserForm";
import UserList from "../components/UserAdministration/UserList";
import { useFetchWithAuth } from "../lib/auth/fetchWithAuth";
import { UserAccountApi } from "../lib/routes";
import IUser from "../model/UserAdministration/IUser";
import {useHelp} from "../Help/HelpContextProvider";
import ColumnPane from "../components/Gen2/components/BasicControls/ColumnPane";
import RoundedPanel from "../components/Gen2/components/BasicControls/RoundedPanel";
import TitledSection from "../components/Gen2/components/BasicControls/TitledSection";
import {t} from "i18next";
import ContentPage from "../components/Gen2/components/BasicControls/ContentPage";

const parseUsers = (jsonData: any): IUser[] => {
  try {
    return jsonData.content.map((user: any) => {
      return {
        accountNonExpired: user.accountNonExpired,
        accountNonLocked: user.accountNonLocked,
        credentialsNonExpired: user.credentialsNonExpired,
        enabled: user.enabled ?? false,
        roles: user.roles,
        username: user.username,
      };
    });
  } catch {
    console.error("Error parsing json data for users");
    return [];
  }
};

const MARGIN_HEIGHT = 16;//px

const UserAccounts: React.FC<WithTranslation> = ({ t }) => {
  const fetchWithAuth = useFetchWithAuth();

  const [users, setUsers] = useState<IUser[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [, setHelpRequested] = useHelp();

  const fetchUsers = async () => {
    const data = await fetchWithAuth(UserAccountApi.listUserAccountsUrl());
    const jsonData = await data.json();
    const users = parseUsers(jsonData);
    setUsers(users);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAddUser = async (email: string, password: string) => {
    const newUser = {
      accountNonExpired: true,
      accountNonLocked: true,
      credentialsNonExpired: true,
      roles: ["SITE_USER"],
      username: email,
      password,
    };

    const createResponse = await fetchWithAuth(
      UserAccountApi.createUserAccountUrl(),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
        },
        body: JSON.stringify(newUser),
      }
    );

    if (createResponse.ok) {
      await fetchUsers();
    } else {
      let errorMessage = await createResponse.text();
      setErrors({email: errorMessage});
    }
  };

  const [columnPaneHeight, setColumnPaneHeight] = useState<number>();

  const maxWorkAreaHeight = useMemo(()=>{
    if (columnPaneHeight) {
      return columnPaneHeight - 2 * MARGIN_HEIGHT ;
    } else {
      return undefined ;
    }
  }, [columnPaneHeight]);

  return (
    <>
      <ContentPage>
        <ColumnPane
            className="w3-rest" style={{width: "calc(100%)"}}
            onSizeChanged={(w,h)=>setColumnPaneHeight(h)}
        >
          <RoundedPanel>
            <div
                style={{
                  maxHeight: maxWorkAreaHeight ? `${maxWorkAreaHeight}px` : undefined,
                  overflowY: 'auto'
                }}
            >
              <TitledSection
                  w3BorderColor={'w3-border-light-blue'}
                  title={t("new_user")}
                  onHelpClicked={()=>setHelpRequested({
                    helpTitle: t('new_user'),
                    helpContent: <div style={{height: "250px"}}>Placeholder</div>,
                    width: "50vw",
                  })}
              >
                <NewUserForm onCreateNewUser={handleAddUser} errors={errors}/>
              </TitledSection>

              <TitledSection
                  className={'w3-margin-top'}
                  w3BorderColor={'w3-border-light-blue'}
                  title={t("user_list")}
                  onHelpClicked={()=>setHelpRequested({
                    helpTitle: t('user_list'),
                    helpContent: <div style={{height: "250px"}}>Placeholder</div>,
                    width: "50vw",
                  })}
              >
                <div style={{ width: "calc(100% - 8em)"}}>
                  <UserList users={users} />
                </div>
              </TitledSection>
            </div>
          </RoundedPanel>
        </ColumnPane>
      </ContentPage>
    </>
  );
};

export default withTranslation()(UserAccounts);
