import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WarningBox: React.FC = (props) => {
  return (
    <div /*className="w3-section"*/>
      <div className="w3-pale-yellow w3-round" style={{ padding: "0px 16px" }}>
        <i>
          <FontAwesomeIcon icon={faWarning} />
          &nbsp;
          {props.children}
        </i>
      </div>
    </div>
  );
};

export default WarningBox;
