import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";

export const getSortIcon = (
  column: string,
  sortBy: string | null,
  sortOrder: "asc" | "desc" | any
) => {
  if (sortBy !== column) return faSort;
  if (sortOrder === "asc") return faSortUp;
  if (sortOrder === "desc") return faSortDown;
  return faSort;
};
