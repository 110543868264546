import {Predicate} from "./Predicate";

export class IndexPlane<T> {

  private readonly _rowCount: number;
  private readonly _colCount: number;
  private _index: Map<number, T>;

  constructor( rowCount: number, colCount: number ) {
    this._rowCount = rowCount;
    this._colCount = colCount;
    this._index= new Map<number, T>();
  }

  public clear() {
    this._index.clear();
  }

  public get isEmpty() {
    return this._index.size == 0;
  }

  private computeIndex( row: number, col: number ): number {
    return row * this._colCount + col ;
  }

  public at( row: number, col: number ): T|undefined {
    const idx = this.computeIndex( row, col ) ;
    return this._index.get( idx );
  }

  public add( row: number, col: number, item: T ) {
    const idx = this.computeIndex( row, col ) ;
    this._index.set( idx, item );
  }

  public stream(): IterableIterator<T> {
    return this._index.values() ;
  }

  public forEach( callback: (row: number, col: number, item:T)=>void ) {
    this._index.forEach((value,key)=>{
      let indices = this.getRowAndCol( key );
      callback( indices[0], indices[1], value );
    });
  }

  public contains( predicate: Predicate<T> ): boolean {
    const entries = Array.from( this._index.values() );
    for ( let i = 0; i < entries.length; i += 1 ) {
      if ( predicate.test( entries[ i ] ) ) {
        return true ;
      }
    }
    return false ;
  }

  private getRowAndCol( idx: number ): [number, number] {
    const row = Math.floor(idx / this._colCount);
    const col = idx % this._colCount;
    return[row, col];
  }
}
