import React, {useCallback, useMemo, useState} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {t} from "i18next";
import ColorPickerDialog, {IColorPickerDialogProps} from "./ColorPickerDialog";
import {Color} from "cesium";
import ColorBox from "../ColorLegend/ColorBox";
import {LayerType} from "../../model/LayerType";
import ButtonFooter from "../Gen2/components/BasicControls/ButtonFooter";
import {useUserSessionContext} from "../Gen2/components/Contexts/UserSessionContext";
import {DEFAULT_NEUTRAL_COLOR} from "../Gen2/domain/IStyle";

interface IPatternBoundaryOptionsDialogProps extends WithTranslation {
    onDismiss: () => void;
}

const BORDER_RADIUS = "5px";

const PatternBoundaryOptionsDialog: React.FC<IPatternBoundaryOptionsDialogProps> = (props) => {
    const [userSession, userSessionActions] = useUserSessionContext();
    const [colorPickerProps, setColorPickerProps] = useState<IColorPickerDialogProps>();
    const [color, setColor] = useState<Color>(userSession.layersColor.Boundary/*viewModel.layerStyles.Boundary.color*/) ;

    const canBeReset = useMemo(()=>{
        return !color.equals( DEFAULT_NEUTRAL_COLOR/*DEFAULT_VIEW_MODEL.layerStyles.Boundary.color*/ );
    }, [color]);

    const doReset = ()=>{
        // setColor(DEFAULT_VIEW_MODEL.layerStyles.Boundary.color);
        setColor(DEFAULT_NEUTRAL_COLOR);
    };

    const onAccept = ()=> {
        // viewModelActions.layer.setColor( LayerType.Boundary, color );
        userSessionActions.setLayerColor( LayerType.Boundary, color );
        setColorPickerProps(undefined);
        props.onDismiss();
    };

    const onCancel = () => {
        setColorPickerProps(undefined);
        props.onDismiss();
    }

    const onColorPickerRequested = (props: IColorPickerDialogProps): void => {
        setColorPickerProps({
            color: props.color,
            onCancel: () => {
                props.onCancel();
                setColorPickerProps(undefined);
            },
            onColorSelected: (color) => {
                props.onColorSelected(color);
                setColorPickerProps(undefined);
                setColor( color );
            },
        });
    }
    return (
        <>
            <div className="w3-modal" style={{display: "block"}}>
                <div className="w3-modal-content w3-card-4 w3-theme-d4" style={{borderRadius: BORDER_RADIUS}}>
                    <header className="w3-container w3-light-blue w3-center" style={{borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0px 0px`}}>
                        <h6>{t("Pattern boundary options")}</h6>
                    </header>

                    <div className={"w3-section w3-cell-row"}>
                        <div
                            className={"w3-cell w3-center w3-cell-middle"}
                            style={{width: "50%"}}
                        >
                            {t("Polygon Color")}
                        </div>
                        <div
                            className={"w3-cell w3-cell-middle w3-center"}
                            style={{width: "50%"}}
                        >
                            <ColorBox
                                cssHeight={"40px"}
                                cssWidth={"250px"}
                                color={color}
                                onClicked={(c) => onColorPickerRequested({
                                    color: color,
                                    onColorSelected: (color)=> {
                                        setColor(color);
                                    },
                                    onCancel: () => {}
                                })}
                            />
                        </div>
                    </div>

                    <ButtonFooter
                        borderRadius={BORDER_RADIUS}
                        onAccept={()=>onAccept()}
                        onReset={canBeReset? (()=>doReset()):undefined}
                        onCancel={onCancel}
                    />
                </div>
            </div>
            {colorPickerProps && (
                <ColorPickerDialog
                    color={colorPickerProps.color}
                    onCancel={() => {
                        colorPickerProps.onCancel();
                        setColorPickerProps(undefined);
                    }}
                    onColorSelected={(c) => {
                        colorPickerProps.onColorSelected(c);
                        setColorPickerProps(undefined);
                    }}
                />
            )}
        </>
    );
};

export default withTranslation()(PatternBoundaryOptionsDialog);