import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ISiteConfiguration} from "../../domain/ISiteConfiguration";
import {Matrix4} from "cesium";
import {useFetchWithAuth} from "../../../../lib/auth/fetchWithAuth";
import SiteConfigurationMapper from "../../mappers/SiteConfigurationMapper";
import {ISiteConfigurationDto} from "../../model/ISiteConfigurationDto";
import {AttributeType, IAttributeTypeMap} from "../../domain/AttributeType";
import IColorLegendConfiguration from "../../domain/IColorLegendConfiguration";
import {LegendType} from "../../domain/LegendType";
import UserSession from "../../../../lib/userSession";

const apiEndpoint = ( window
        ? (window as any).env?.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT
) || "http://localhost:8080";
const apiBasePath = "api/4.0";
const apiRoot = `${apiEndpoint}/${apiBasePath}`;

export interface ISiteDataActions {
  getPrecisionForAttribute: (attributeName: string) => number|undefined;
  getColorLegendConfig: (attributeType: AttributeType|undefined) => IColorLegendConfiguration|undefined;
  getColorLegendValueRange: (attributeType: AttributeType|undefined, rangeIndex: number ) => (number[]|undefined);
  saveSiteConfig: (siteConfig:ISiteConfiguration)=>Promise<ISiteConfiguration>;
}

export const SiteDataContext2 = React.createContext<any>({});

interface IProps {
}

export const SiteDataContextProvider : React.FC<IProps> = (props) => {
  const fetchWithAuth = useFetchWithAuth();
  const [siteConfiguration, setSiteConfiguration] = useState<ISiteConfiguration>();

  // const refreshSiteConfiguration = /*useCallback(*/async ()=>{
  //   await fetchWithAuth(`${apiRoot}/configuration/SiteConfiguration`)
  //       .then((resp) => resp.json())
  //       .then((json) => SiteConfigurationMapper.siteConfigDtoToSiteConfig(json as ISiteConfigurationDto))
  //       .then((scfg) => {
  //         scfg.modelMatrix = Matrix4.fromRotationTranslation(scfg.rotation, scfg.translation);
  //         setSiteConfiguration(scfg);
  //       } );
  // }//, []);

  useEffect(()=>{
    if ( UserSession.isInRoles(["DEV"]) ) {
      // Superuser has no customer site
      return;
    }
    // refreshSiteConfiguration();
    fetchWithAuth(`${apiRoot}/configuration/SiteConfiguration`)
        .then((resp) => resp.json())
        .then((json) => SiteConfigurationMapper.siteConfigDtoToSiteConfig(json as ISiteConfigurationDto))
        .then((scfg) => {
          scfg.modelMatrix = Matrix4.fromRotationTranslation(scfg.rotation, scfg.translation);
          setSiteConfiguration(scfg);
        } );
  }, [fetchWithAuth]);
  const saveSiteConfig = useCallback(async (siteConfig: ISiteConfiguration): Promise<ISiteConfiguration> => {
    return await fetchWithAuth( `${apiRoot}/configuration/SiteConfiguration`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "*/*",
      },
      body: JSON.stringify(SiteConfigurationMapper.siteConfigToSiteConfigDto(siteConfig))
    })
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`saveSiteConfig: status=${resp.status}`) ;
          }
          return resp.json() ;
        })
        .then( json => {
          let scfg: ISiteConfiguration = SiteConfigurationMapper.siteConfigDtoToSiteConfig( json ) ;
          scfg.modelMatrix = Matrix4.fromRotationTranslation(scfg.rotation, scfg.translation);
          setSiteConfiguration(scfg);
          return scfg;
        })
  }, [fetchWithAuth]);

  const getColorLegendConfig = useCallback((attributeType: AttributeType|undefined): IColorLegendConfiguration|undefined =>{
    if ( attributeType && siteConfiguration) {
      const key = AttributeType[attributeType] as keyof IAttributeTypeMap<IColorLegendConfiguration>;
      return siteConfiguration.legendConfigs[key];
    } else {
      return undefined ;
    }

  }, [siteConfiguration]);

  const getPrecisionForAttribute = useCallback((attributeName: string): number|undefined =>{
    if ( !siteConfiguration ) {
      return undefined;
    }

    let config = siteConfiguration.legendConfigs[ attributeName as keyof IAttributeTypeMap<IColorLegendConfiguration> ];
    if (config && config.legendType === LegendType.Ranges) {
      return config.precision;
    }
    // ... or, for other well-known attributes
    switch (attributeName) {
        // ... Positional attributes
      case "x":
      case "y":
      case "z":
      case "from_z":
      case "to_z":
      case "Depth":
        return 2;
        // ... Obsolete calculated attributes
      case "CBI":
      case "BI":
        return 1;
      case "I": case "J": case "K":
        return 0 ;
    }
    // ... Undefined
    return undefined;
  }, [siteConfiguration]);

  /**
   * Retrieved the value range for a given color legend item
   */
  const getColorLegendValueRange = useCallback((attributeType: AttributeType|undefined, rangeIndex: number): (number[]|undefined) =>{
    const colorLegendConfig = getColorLegendConfig(attributeType);
    if ( !colorLegendConfig ) {
      console.log(`getColorLegendValueRange(${rangeIndex}): no color legend for ${attributeType}`)
      return undefined ;
    }

    switch ( rangeIndex) {
      case -1: {
        const min = -Number.MAX_VALUE
        const max = colorLegendConfig.items[0].range[0];
        return [min, max];
      }
      case colorLegendConfig.items.length: {
        const min = colorLegendConfig.items[colorLegendConfig.items.length - 1].range[1];
        const max = Number.MAX_VALUE
        return [min, max];
      }
      default:
        if ( rangeIndex > colorLegendConfig.items.length ) {
          throw new Error(`getColorLegendValueRange: invalid argument 'rangeIndex': ${rangeIndex}`);
        }
        return colorLegendConfig.items[ rangeIndex ].range;
    }

  }, [getColorLegendConfig]);

  const actions = useMemo((): ISiteDataActions =>{
    return {
      getPrecisionForAttribute,
      getColorLegendConfig,
      getColorLegendValueRange,
      saveSiteConfig
    }
  }, [getColorLegendConfig, getColorLegendValueRange, getPrecisionForAttribute, saveSiteConfig]);

  return (
      <>
        {/*{ siteConfiguration && actions && (*/}
            <SiteDataContext2.Provider value={[siteConfiguration, actions]}>
              { props.children }
            </SiteDataContext2.Provider>
        {/*)}*/}
      </>
  )
}
