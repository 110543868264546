import { format } from "date-fns";
import {useEffect, useMemo, useState} from "react";

import IPattern from "../model/IPattern";
import ISavedSession from "../model/ISavedSession";
// import {LONG_DATETIME_FORMAT} from "../lib/date";
import {t} from "i18next";
import {withTranslation, WithTranslation} from "react-i18next";
import UserSession from "../lib/userSession";
import Role from "../model/UserAdministration/Role";

interface Props extends WithTranslation {
  patterns: IPattern[];
  session?: ISavedSession;
  onSave: (savedSession: ISavedSession) => void;
  onView: (savedSession: ISavedSession) => void;
  onReset: (savedSession: ISavedSession) => void;
  onDelete: (savedSession: ISavedSession) => void;
  className?:string|undefined;
  style?:any|undefined;
}

const CurrentSelection: React.FC<Props> = ({
    session,
    patterns,
    onSave,
    onView,  // Button: Open
    onReset, // Button: Reset
    onDelete, // Button: Delete
    className,
    style
}) => {
  // const navigate = useNavigate();

  // const [isEditing, setIsEditing] = useState(false);
  // const [currentName, setCurrentName] = useState(session?.name ?? "");
  // const [nameDateFormat,setNameDateFormat] = useState<string>(t("LONG_DATETIME_FORMAT"));

  // useEffect(()=>{
  //   setNameDateFormat(t("LONG_DATETIME_FORMAT"));
  // });

  const isAdmin = useMemo(()=>UserSession.isInRoles(["SITE_ADMIN"]), []);

  const createNewSession = (name?: string) => {
    const today = new Date();
    return {
      getName: ()=> { return name ?? `Created ${format(today, t("SHORT_DATE_FORMAT"))}`; },
      patternIds: [],
      uuid: undefined,
      createdTimestamp: today,
      sessionData: undefined,
      blockSize: [null, null, null],
      lastModifiedDate: new Date()
      // imageDataURL: undefined,
    };
  };

  const [currentSession, setCurrentSession] = useState<ISavedSession>(
    session ?? createNewSession()
  );

  useEffect(() => {
    if ( session ) {
      setCurrentSession(session);
    } else {
      setCurrentSession(createNewSession());
    }
    // console.log(`CurrentSelection: session=${session?.uuid??'n/a'}`) ;
  }, [session]);

  const handleView = () => {
    currentSession.patternIds = patterns.map((p) => p.patternId);
    onView(currentSession);
  };

  // const handleSave = () => {
  //   currentSession.patternIds = patterns.map((p) => p.name);
  //   onSave(currentSession);
  // };

  const handleReset = () => {
    currentSession.patternIds = patterns.map((p) => p.patternId);
    onReset(currentSession);
    setCurrentSession(createNewSession());
  };

  const handleDelete = () => {
    currentSession.patternIds = patterns.map((p) => p.patternId);
    onDelete(currentSession);
  };

  return (
    <div className={`w3-row ${className}`} style={style}>
      <div className="w3-left" style={{ paddingRight: "6px" }}>
        <button
          className="w3-button w3-light-blue w3-block w3-round-medium"
          onClick={handleView}
          disabled={patterns.length === 0}
        >
          {t("open")}
        </button>
      </div>
      <div className="w3-left">
        <button
            className="w3-button w3-orange w3-block w3-round-medium"
            onClick={handleReset}
            disabled={patterns.length === 0}
        >
          {t("Reset")}
        </button>
      </div>
      { isAdmin && patterns.length > 0 && (
        <div className="w3-left" style={{ paddingLeft: "6px" }}>
          <button
              className="w3-button w3-red w3-block w3-round-medium"
              onClick={handleDelete}
          >
            {t("delete")}
          </button>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(CurrentSelection);
