import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams } from "react-router-dom";
import { getSortIcon } from "./utils";

interface Props {
  filterProp: string;
  defaultFilterprop: string;
  widthPercent: number;
  columnSort: IColumnSort;
  onColumnSort: (columnSort: IColumnSort)=>void;
  className?:string|undefined;
}

export interface IColumnSort {
  sortBy: string;
  sortOrder: "asc" | "desc";
}

const SortHeaderItem: React.FC<Props> = (props) => {
  const handleHeaderClick = (filterName: string) => () => {
    props.onColumnSort({
      sortBy: filterName,
      sortOrder: props.columnSort.sortOrder === "desc" && filterName === props.columnSort.sortBy ? "asc" : "desc",
    });
  };

  return (
    <div className={`w3-cell ${props.className}`} style={{ width: `${props.widthPercent}%` }}>
      <span
        onClick={handleHeaderClick(props.filterProp)}
        style={{ marginLeft: "5px" }}
        className="w3-pointer"
      >
        <FontAwesomeIcon icon={getSortIcon(props.filterProp, props.columnSort.sortBy, props.columnSort.sortOrder)} />{" "}
        {props.children}
      </span>
    </div>
  );
};

export default SortHeaderItem;
