import ILayerTypes, {LayerType} from "../../../../model/LayerType";
import React, {useEffect, useMemo} from "react";
import SideMenuRow from "../../../SideMenuItems/SideMenuRow";
import {t} from "i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import {withTranslation, WithTranslation} from "react-i18next";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import Slider from "../BasicControls/Slider";

const LAYERS_WITH_OPTIONS: ILayerTypes<boolean> = {
  BlockModel: false,
  BlastholeSecondarySegments: true,
  BlastholeSingleSegment: true,
  DrilledHolesContour: true,
  BlockVolume: false,
  DrilledHoleCollars: false,
  Undefined: false,
  Boundary: true,
  BlastholeClusters: false,
  BlastholeFractures: true,
  CrossSectionX: false,
  CrossSectionY: false,
  CrossSectionZ: false,
};

const widths = ["15%", "45%", "30%", "10%"];

interface IProps extends WithTranslation {
  id?:string;
  onLayerOptionsClicked: (layer: LayerType)=>void;
}

const LayersFilterTable: React.FC<IProps> = (props)=>{
  const [userSession] = useUserSessionContext();

  useEffect(()=>console.log('~~~~~~~props.onLayerOptionsClicked has changed'), [props.onLayerOptionsClicked]);

  return (
      <div id={props.id}>
        {/*table header*/}
        <SideMenuRow widths={widths} className={"w3-margin-bottom w3-margin-top"}>
          <div className={"unselectable w3-left"} style={{ fontSize: "12px" }}><b>{t("show")}</b></div>
          <div className={"unselectable w3-left"} style={{ fontSize: "12px" }}><b>{t("layer")}</b></div>
          <div className={"unselectable w3-left"} style={{ fontSize: "12px" }}><b>{t("opacity")}</b></div>
          <div className={"unselectable"} style={{ fontSize: "12px" }}></div>
        </SideMenuRow>
        {
          // viewModel.layerStyles && (
            userSession.layersVisibility && userSession.layersOpacity && (
              <>
                <LayersFilterRow layerType={LayerType.BlockModel} {...props}/>
                <LayersFilterRow layerType={LayerType.BlastholeSecondarySegments} {...props}/>
                <LayersFilterRow layerType={LayerType.BlastholeSingleSegment} {...props}/>
                <LayersFilterRow layerType={LayerType.BlastholeFractures} {...props}/>
                <LayersFilterRow layerType={LayerType.Boundary} {...props}/>
                {/*<LayersFilterRow layerType={LayerType.CrossSectionX} {...props}/>*/}
                {/*<LayersFilterRow layerType={LayerType.CrossSectionY} {...props}/>*/}
                {/*<LayersFilterRow layerType={LayerType.CrossSectionZ} {...props}/>*/}
              </>
          )
        }
      </div>
  )
}

export default withTranslation()(React.memo(LayersFilterTable));

interface ILayerRowProps extends IProps {
  layerType: LayerType
}

const LayersFilterRow: React.FC<ILayerRowProps> = (props) => {
  // const [viewModel] = useViewModelV2();
  const [userSession, userSessionActions] = useUserSessionContext();

  const layerType = props.layerType;

  const assetLayers = useMemo(()=>{
    let result = new Set<LayerType>();
    userSession.assetSummaries?.forEach( assetSummary => {
      result.add( assetSummary.layerType );
    })
    return result ;
  }, [userSession.assetSummaries]);

  const isPresentInData = useMemo(()=>{
    return assetLayers?.has( layerType );
  }, [assetLayers, layerType]);

  const tag = useMemo(()=>LayerType[props.layerType], [props.layerType]);
  const layerVisibility = userSession.layersVisibility[LayerType[props.layerType] as keyof ILayerTypes<boolean>];
  const layerOpacity = userSession.layersOpacity[LayerType[props.layerType] as keyof ILayerTypes<number>];

  const hasOptionsMenu = useMemo(()=>LAYERS_WITH_OPTIONS[LayerType[props.layerType] as keyof ILayerTypes<boolean>], [props.layerType]);

  // useEffect(()=>console.log('layerVisibility has changed'), [layerVisibility]);
  // useEffect(()=>console.log('layerOpacity has changed'), [layerOpacity]);

  return (
      <>
        {/*{viewModel.layerStyles && (*/}
            <SideMenuRow
                disabled={!isPresentInData}
                widths={widths}
                className={"w3-margin-bottom"}
                style={{ fontSize: "12px", verticalAlign: "middle"}}
            >
              <div className={'w3-left-align'}>
                <input
                    id={tag + "_checkbox"}
                    type="checkbox"
                    className="w3-check"
                    disabled={!isPresentInData}
                    checked={layerVisibility}
                    onChange={(e) =>
                        userSessionActions.setLayerVisibility(layerType, e.target.checked)
                        // props.onLayerVisibilityChanged( layerType, e.target.checked )
                    }
                />
              </div>

              <div className={'w3-left-align'}>
                <label htmlFor={tag + "_checkbox"} className={"unselectable"}>
                  {t(tag)}
                </label>
              </div>

              <div className={'w3-left-align'}>
                <Slider
                    className={'w3-padding-small'}
                    min={0}
                    max={100}
                    step={5}
                    value={layerOpacity*100}
                    onValueChanged={async(value)=>userSessionActions.setLayerOpacity( layerType, value / 100)}
                    // onRangeOpacityReleased={props.onRangeOpacityReleased}
                />
              </div>

              <div
                  className={`w3-hover-text-light-blue w3-right-align w3-padding-small`}
                  style={{ verticalAlign: "middle", minWidth: "10%" }}
                  onClick={(e) => isPresentInData && props.onLayerOptionsClicked(layerType)}
              >
                <FontAwesomeIcon
                    className={`unselectable`}
                    style={ hasOptionsMenu ? {}:{ visibility: "hidden" }}
                    icon={faEllipsisVertical}
                />
              </div>
            </SideMenuRow>
        {/*)}*/}
      </>
  )
}
