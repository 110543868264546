import {IBlockModelDto} from "../model/IBlockModelDto";
import {IBlockModelFeatureDto} from "../model/IBlockModelFeatureDto";
import {Cesium3DTileFeature} from "cesium";
import IFeatureReference from "../model/IFeatureReference";
import {CompositeFeatureIndex} from "../domain/CompositeFeatureIndex";

export default class BlockModelMapper {

  public static blockModelToBlockModelDto( blockModel : CompositeFeatureIndex ): IBlockModelDto {
    return blockModel.getBlockModelDto();
  }

  public static featureToBlockModelFeatureDto( feature : IFeatureReference ): IBlockModelFeatureDto {

    const SED = feature.feature.getProperty("SED");
    const BI = feature.feature.getProperty("BI");
    const CBI = feature.feature.getProperty("CBI");
    const Hardness = feature.feature.getProperty("Hardness");
    const CoalProbability = feature.feature.getProperty("CoalProbability");
    const FRF = feature.feature.getProperty("FRF");
    const ROP = feature.feature.getProperty("ROP");
    const Prominence = feature.feature.getProperty("Prominence");
    const Other = feature.feature.getProperty("Other");
    const I = feature.I;
    const J = feature.J;
    const K = feature.K;
    const OffsetXYZ = feature.feature.getProperty("OffsetXYZ");

    return {
      SED: (SED!==undefined) ? +SED : undefined,
      BI: (BI!==undefined) ? +BI : undefined,
      CBI: (CBI!==undefined) ? +CBI : undefined,
      Hardness: (Hardness!==undefined) ? +Hardness : undefined,
      CoalProbability: (CoalProbability!==undefined) ? +CoalProbability : undefined,
      FRF: (FRF!==undefined) ? +FRF : undefined,
      ROP: (ROP!==undefined) ? +ROP : undefined,
      Prominence: (Prominence!==undefined) ? +Prominence : undefined,
      Other: (Other!==undefined) ? +Other : undefined,
      I: (I!==undefined) ? +I : undefined,
      J: (J!==undefined) ? +J : undefined,
      K: (K!==undefined) ? +K : undefined,
      OffsetXYZ: OffsetXYZ
    }
  }
}
