const DataAdminPage: React.FC = () => {
  return (
    <div className="w3-container">
      <h2>Data Administration</h2>
      <p>This page's intent is to only be visible for system administrators</p>
      <p>
        A page where people will be able to:
        <br />
        - View all the patterns and all the site assets uploaded into the system
        <br />- Delete or hide them from the patterns explorer View
        <br />- Grant or revoke access to users, manage roles
        <br /> ...
      </p>
    </div>
  );
};

export default DataAdminPage;
