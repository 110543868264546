import {t} from "i18next";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {CameraFrustum} from "../../domain/CameraFrustum";

interface IProps extends WithTranslation {
  selection: CameraFrustum;
  onSelectionChanged: (selected: CameraFrustum) => void;
}

const CameraFrustrumSelector: React.FC<IProps> = (props) => {
  // console.log(`AttributeSelector: rendering => ${props.selection ? CameraFrustrum[props.selection] : undefined}`) ;

  const handleSelectionChanged = ( value: string ) => {
    let selection = CameraFrustum[ value as keyof typeof CameraFrustum ];
    props.onSelectionChanged( selection );
  }

  const availableValues = Object.keys(CameraFrustum).filter(k => isNaN(Number(k)));

  return (
      <select
          className={`w3-select w3-theme-d4 w3-center w3-border w3-border-grey w3-round-medium`}
          style={{ fontSize: "12px", cursor: "pointer" }}
          name="option"
          value={(props.selection !== undefined) ? `${CameraFrustum[props.selection]}` : undefined }
          onChange={(e) => handleSelectionChanged(e.target.value) }
      >
        {availableValues
            .map((value) => (
                <PureCameraFrustrumOption key={value} value={CameraFrustum[value as keyof typeof CameraFrustum]}/>
            ))
        }
      </select>
  )
}

interface ICameraFrustrumOptionProps extends WithTranslation {
  value: CameraFrustum
}

const CameraFrustrumOption: React.FC<ICameraFrustrumOptionProps> = (props) => {
  // console.log(`PureCameraFrustrumOption: rendering`) ;
  const optionString: string = `${CameraFrustum[props.value]}` ;
  return (<option value={optionString}>{t(optionString)}</option>);
}
const PureCameraFrustrumOption = withTranslation()(React.memo(CameraFrustrumOption));

export default withTranslation()(React.memo(CameraFrustrumSelector));
