/**
 * A reusable wrapper class for our list items *
 */
const ListItem: React.FC = (props) => {
  return (
    <div
      className={`w3-cell-row w3-block w3-border-bottom w3-theme-d1 w3-theme-d1-hover w3-padding-small`}
      style={{ padding: "0px 0px 0px 8px" }}
    >
      {props.children}
    </div>
  );
};

export default ListItem;
