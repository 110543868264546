import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Role from "../../model/UserAdministration/Role";

interface Props extends WithTranslation {
  userName: string;
  userRoles: Role[];
  onCancel: () => void;
  onSave: (roles: Role[]) => void;
}

const assignableRoles: Role[] = ["SITE_ADMIN", "SITE_USER"];

const AssignRolesModal: React.FC<Props> = ({
  t,
  userName,
  userRoles,
  onCancel,
  onSave,
}) => {
  const [currentRoles, setCurrentRoles] = useState(userRoles);

  return (
    <div className="w3-modal w3-show">
      <div className="w3-modal-content w3-card-4  w3-theme-d4">
        <header className="w3-container w3-light-blue">
          <span onClick={onCancel} className="w3-button w3-display-topright">
            <FontAwesomeIcon icon={faTimes} />
          </span>
          <h2>
            {t("define_user_role")}: {userName}
          </h2>
        </header>
        <div className="w3-container w3-padding-large">
          {assignableRoles.map((r) => (
            <div key={r}>
              <input
                id={`role-check-${r}`}
                type="radio"
                name="role"
                checked={currentRoles.includes(r)}
                onChange={(evt) => {
                  if (evt.target.checked) {
                    setCurrentRoles([r]);
                  }
                }}
              />{" "}
              <label htmlFor={`role-check-${r}`}>{r}</label>
            </div>
          ))}
        </div>
        <footer className="w3-container w3-theme-d3 w3-padding">
          <div className="w3-right">
            <button
              onClick={onCancel}
              className="w3-button w3-margin-right w3-round-medium w3-orange"
            >
              {t("cancel")}
            </button>
            <button
              onClick={() => {
                onSave(currentRoles);
              }}
              className="w3-button w3-round-medium w3-light-green"
            >
              {t("save")}
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default withTranslation()(AssignRolesModal);
