import React, {CSSProperties} from "react";
import {withTranslation, WithTranslation} from "react-i18next";

interface IProps extends WithTranslation {
  className?:string|undefined;
  style?:CSSProperties|undefined;
}

const Help_EULA: React.FC<IProps> = (props) => {
  return (
      <div className={`w3-left-align w3-padding-small w3-small ${props.className}`} style={props.style}>
        <div style={{fontWeight: "bold"}}>PECK TECH A CATERPILLAR COMPANY SOFTWARE LICENSE TERMS</div>
        <div style={{fontWeight: "bold"}}>PECK TECH A CATERPILLAR COMPANY MWD GEOVIEWER 1.0 TRIAL EDITION</div>
        <hr style={{borderTop: "0.5px solid"}}/>
        <div className={'w3-margin-bottom text-full-justify'}>
          These license terms are an agreement between you and Peck Tech a Caterpillar Company. They apply to the
          software named above. The terms also apply to any Peck Tech a Caterpillar Company services and updates for the
          software, except to the extent those have different terms.
        </div>
        <div  style={{fontWeight: "bold"}}>
          BY USING THE SOFTWARE, YOU ACCEPT THESE TERMS. IF YOU DO NOT ACCEPT THEM, DO NOT USE THE SOFTWARE.
        </div>
        <hr style={{borderTop: "0.5px solid"}}/>

        <div className={'text-full-justify'}>
          <b>TRIAL EDITION USE RIGHTS.  </b>
          If you have not acquired a valid full-use license, the software is a trial edition, and this Section
          applies to your use of the trial edition.
        </div>
        <ol style={{listStyleType: "upper-alpha"}}>
          <li className={'w3-margin-bottom text-full-justify'}>
            <b>GENERAL. </b>
            You may
            only use the trial edition for internal evaluation purposes, and only during
            the trial period. You may run load tests of up to 25 users during the trial
            period.
          </li>
          <li className={'w3-margin-bottom text-full-justify'}>
            <b>TRIAL PERIOD AND CONVERSION. </b>
            The trial
            period lasts for 30 days after you were provided with the access for the trial
            edition, plus any permitted extension period. After the expiration of the trial
            period, the trial edition will stop running. You may extend the trial period by
            contacting Peck Tech team. You may not be able to access data used with the
            trial edition after it stops running.
          </li>
          <li className={'w3-margin-bottom text-full-justify'} style={{textTransform: "uppercase", fontWeight: "bold"}}>
            <b>Disclaimer of Warranty. </b>
            The TRIAL EDITION is licensed “as-is”.  You bear the risk of using
            it. Peck Tech a Caterpillar Company gives no express warranties, guarantees or
            conditions. To the extent permitted under your local laws, Peck Tech a
            Caterpillar Company excludes the implied warranties of merchantability, fitness
            for a particular purpose and non-infringement.
            <br/>
            FOR AUSTRALIA – You have statutory guarantees
            under the Australian Consumer Law and nothing in these terms is intended to
            affect those rights.
          </li>
          <li className={'w3-margin-bottom text-full-justify'}>
            <b>SUPPORT. </b>
            Because the trial edition is “as is”,
            we may not provide support services for it.
          </li>
          <li className={'w3-margin-bottom text-full-justify'}>
            <b style={{textTransform: "uppercase"}}>
              Limitations on Damages. You can recover from Peck Tech a Caterpillar Company and its
              suppliers only direct damages up to U.S. $5.00. You cannot recover any other
              damages, including consequential, lost profits, special, indirect or incidental
              damages.
            </b>
            <br/><br/>
            This
            limitation applies to (a) anything related to the trial version, services,
            content (including code) on third party Internet sites, or third party
            programs; and (b) claims for breach of contract, breach of warranty, guarantee
            or condition, strict liability, negligence, or other tort to the extent
            permitted by applicable law.
            <br/><br/>
            It also applies even if Peck Tech a Caterpillar
            Company knew or should have known about the possibility of the damages. The
            above limitation or exclusion may not apply to you because your country may not
            allow the exclusion or limitation of incidental, consequential or other
            damages.
          </li>
        </ol>
        <hr style={{borderTop: "0.5px solid"}}/>

        <div className={'text-full-justify'}>
          <b>LICENSE TERMS FOR THE TRIAL SOFTWARE: </b>
          When you were provided the
          access to the software, the terms below apply. You may not share your access
          credentials.
        </div>

        <ol style={{listStyleType: "decimal"}}>
          <li className={'w3-margin-bottom'}><b>OVERVIEW.</b>
            <ol style={{listStyleType: "lower-alpha"}}>
              <li className={'w3-margin-bottom text-full-justify'}><b>Software. </b>The software includes tools and documentation.</li>
              <li className={'w3-margin-bottom text-full-justify'}><b>License Model. </b>The software is licensed on a per user basis.</li>
            </ol>
          </li>
          <li className={'w3-margin-bottom'}><b>USE RIGHTS.</b>
            <ol style={{listStyleType: "lower-alpha"}}>
              <li className={'w3-margin-bottom text-full-justify'}><b>Online Services in the Software. </b>
                Some features of the software make use of
                online services to provide you with information about updates to the software
                or extensions, or to enable you to retrieve content, collaborate with others,
                or otherwise supplement your experience. As used throughout this agreement, the
                term “software” includes these online service features.</li>
              <li className={'w3-margin-bottom text-full-justify'}><b>Demo Use.</b>The use rights permitted above include using the
                software to demonstrate your applications.</li>
            </ol>
          </li>
          <li className={'w3-margin-bottom'}><b>DATA.</b>
            <ol style={{listStyleType: "lower-alpha"}}>
              <li className={'w3-margin-bottom text-full-justify'}><b>Data Collection. </b>
                The software does not collect information about you
                and may collect anonymous information about use of the software, and send that
                to Peck Tech a Caterpillar Company. Peck Tech a Caterpillar Company may use
                this information to provide services and improve our products and services.</li>
            </ol>
          </li>
          <li className={'w3-margin-bottom text-full-justify'}><b>SCOPE OF LICENSE. </b>
            The software is licensed, not sold. These
            license terms only give you some rights to use the software. Peck Tech a
            Caterpillar Company reserves all other rights. Unless applicable law gives you
            more rights despite this limitation, you may use the software only as expressly
            permitted in these license terms. In doing so, you must comply with any
            technical limitations in the software that only allow you to use it in certain
            ways. In addition, you may not:
            <ul className={'w3-margin-top'} style={{listStyleType: "disc", paddingLeft: "10px"}}>
              <li className={'w3-margin-bottom text-full-justify'}>
                work around any technical limitations in the software;
              </li>
              <li className={'w3-margin-bottom text-full-justify'}>
                reverse engineer, decompile or disassemble the software, or otherwise attempt to derive the source code
                for the software, except and to the extent required by third party licensing terms governing use of
                certain open source components that may be included in the software;
              </li>
              <li className={'w3-margin-bottom text-full-justify'}>
                remove, minimize, block, or modify any notices of Peck Tech a Caterpillar Company or its suppliers in
                the software;
              </li>
              <li className={'w3-margin-bottom text-full-justify'}>
                use the software in any way that is against the law;
              </li>
              <li className={'w3-margin-bottom text-full-justify'}>
                share, publish, rent, or lease the software; or
              </li>
              <li className={'w3-margin-bottom text-full-justify'}>
                provide the software as a stand-alone offering or combine it with any of your applications for others
                to use.
              </li>
            </ul>
          </li>
          <li className={'w3-margin-bottom text-full-justify'}><b>NOT FOR RESALE SOFTWARE. </b>
            You may not sell the software or share the access in any other way.
          </li>
          <li className={'w3-margin-bottom text-full-justify'}><b>PREVIOUS VERSIONS OR OTHER EDITIONS. </b>
            These license terms do not supersede your right to use validly licensed previous versions or other editions
            of the software. You may use the software and those previous versions or other editions of the software
            concurrently.
          </li>
          <li className={'w3-margin-bottom text-full-justify'}><b>TRANSFER TO A THIRD PARTY. </b>
            You may not transfer the software or the software license agreement to another party.
          </li>
          <li className={'w3-margin-bottom text-full-justify'}><b>SUPPORT. </b>
            Peck Tech a Caterpillar Company provides support for the software.
          </li>
          <li className={'w3-margin-bottom text-full-justify'}><b>ENTIRE AGREEMENT. </b>
            These license terms (including the warranty below), and the terms for supplements, updates, Internet-based
            services, and support services, are the entire agreement for the software and support services.
          </li>
          <li className={'w3-margin-bottom text-full-justify'}><b>CONSUMER RIGHTS; REGIONAL VARIATIONS. </b>
            These
            license terms describe certain legal rights. You may have other rights,
            including consumer rights, under the laws of your state or country. You may also have rights with respect to
            the party from which you acquired the software. This agreement does not change those other rights if the
            laws of your state or country do not permit it to do so.
          </li>
        </ol>
        <div className={'w3-center'}>
          *************************************************************************
        </div>
        <div className={'w3-center'}><b>LIMITED WARRANTY</b></div>
        <div className={'text-full-justify'}>
          Peck
          Tech a Caterpillar Company warrants that properly licensed software will
          perform substantially as described in any Peck Tech a Caterpillar Company
          materials that accompany the software. This limited warranty does not cover
          problems that you cause, that arise when you fail to follow instructions, or
          that are caused by events beyond Peck Tech a Caterpillar Company’s reasonable
          control. The limited warranty starts when the first user acquires the software,
          and lasts for the duration of the trial. Any supplements, updates, or
          replacement software that you may receive from Peck Tech a Caterpillar Company
          during that trial are also covered, but only for the remainder of that trial
          period.
        </div>
        <br/>
        <div className={'text-full-justify'}>
          Peck
          Tech a Caterpillar Company gives no other express warranties, guarantees, or
          conditions. <b>Peck Tech a Caterpillar Company excludes all implied warranties
          and conditions, including those of merchantability, fitness for a particular
          purpose, and non-infringement. If your local law does not allow the exclusion
          of implied warranties, then any implied warranties, guarantees, or conditions
          last only during the term of the limited warranty and are limited as much as
          your local law allows. If your local law requires a longer limited warranty
          term, despite this agreement, then that longer term will apply, but you can
          recover only the remedies this agreement allows.</b>
        </div>
      </div>
  )
}

export default withTranslation()(Help_EULA);