import {Color} from "cesium";

export class ColorUtil {
  /**
   * Determines which color (between black and white) that best contrasts a given color.
   * This is used to colorize text for any given background color
   */
  public static contrastingColor = (color: string) => {
    let trimmed = color.startsWith("#") ? color.substring(1) : this.standardizeColor(color).substring(1);
    return (ColorUtil.luma(trimmed) >= 165) ? '000' : 'fff';
  }
  public static luma = (color: string): number => {
    let rgb = ColorUtil.hexToRGBArray(color);
    return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]); // SMPTE C, Rec. 709 weightings
  }
  public static standardizeColor = (str: string): string => {
    var ctx = document.createElement("canvas").getContext("2d");
    if ( !ctx ) throw new Error(`standardize_color: error creating 2d context`);
    ctx.fillStyle = str;
    return ctx.fillStyle;
  }
  public static hexToRGBArray = (color: string): number[] => {
    switch(color.length) {
      case 3: // 'RGB'
        color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
        break ;
      case 6: // 'RRGGBB'
      case 8: // 'RRGGBBAA'
        break ;
      default:
        throw('Invalid hex color: ' + color);
    }
    // if (color.length === 3)
    //     color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
    // else if (color.length !== 6)
    //     throw('Invalid hex color: ' + color);
    let rgb = [];
    for (let i = 0; i <= 2; i++)
      rgb[i] = parseInt(color.substr(i * 2, 2), 16);
    return rgb;
  }
  public static hsv2rgb(h: number,s: number,v: number): [number, number, number] {
    let f= (n: number,k=(n+h/60)%6) => v - v*s*Math.max( Math.min(k,4-k,1), 0);
    return [f(5),f(3),f(1)];
  }
  public static rainbow( percent: number ): Color {
    const rgb = ColorUtil.hsv2rgb( percent * 360 / 100, 1, 1 );
    const r =Math.floor( rgb[0] * 255);
    const g =Math.floor( rgb[1] * 255);
    const b =Math.floor( rgb[2] * 255);
    return Color.fromBytes( r, g, b );
  }

}