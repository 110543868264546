import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import useResizeObserver from "../../hooks/useResizeObserver";
import {ISnapPosition, useSnapPositioning} from "../../hooks/useSnapPositioning";
import {useZOrderContext} from "../../hooks/useZOrderContext";

export interface IFloatingContainerOffset {
  left: number;
  top: number;
}

interface IProps {
  name: string,
  className?: string;
  style?: any;
  onMouseOver?: ()=>void;
  onMouseLeave?: ()=>void;
  snapPosition: ISnapPosition;
  onPositionChanged: (v: ISnapPosition)=>void;
}

const LEFT_MOUSE_BTN = 0x01;

const FloatingContainer: React.FC<IProps> = (props) => {
  const [getSnapPosition] = useSnapPositioning( { left: 50, top: 30, right: 0, bottom: 50 } );
  const draggableDivRef = useRef(null);
  const xDown = useRef(0);
  const yDown = useRef(0);
  const snapPosition = useRef<ISnapPosition>();
  const [positionInParent, setPositionInParent] = useState<ISnapPosition>(props.snapPosition);
  const [zIndex, bringToFront] = useZOrderContext(props.name);

  useEffect(()=>{
    setPositionInParent(props.snapPosition);
  }, [props.snapPosition]);

  const moveByOffsetInParent = useCallback((delta: IFloatingContainerOffset)=>{
    if ( !draggableDivRef?.current ) {
      return ;
    }
    const draggableDiv: HTMLDivElement = draggableDivRef.current;
    if ( !draggableDiv.parentElement ) {
      return ;
    }

    setPositionInParent(prevState => {
      snapPosition.current = getSnapPosition( draggableDiv, prevState.x + delta.left, prevState.y + delta.top );
      // console.log(`snapPos=${JSON.stringify(snapPosition)}`)
      return {...snapPosition.current};
    });
  }, [draggableDivRef, getSnapPosition]);

  const elementDrag = useCallback((e: MouseEvent) => {
    e.preventDefault();

    if ( ( e.buttons & LEFT_MOUSE_BTN ) === LEFT_MOUSE_BTN ) {
      // ... Calculate the new cursor position:
      let xDelta = e.clientX - xDown.current;
      let yDelta = e.clientY - yDown.current;
      xDown.current = e.clientX;
      yDown.current = e.clientY;
      // ... Set the element's new position:
      moveByOffsetInParent({left: xDelta, top: yDelta});
    } else {
      closeDragElement();
    }

  }, [moveByOffsetInParent]);

  const closeDragElement = useCallback(() => {
    // ... Stop moving when mouse button is released:
    document.removeEventListener('mousemove', elementDrag);
    document.removeEventListener('mouseup', closeDragElement);

    if (snapPosition.current) {
      props.onPositionChanged(snapPosition.current);
    }

  }, [props]);

  const handleMouseOver = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    // console.log('handleMouseOver')
    props.onMouseOver && props.onMouseOver();
  }, [props.onMouseOver]);

  const handleMouseOut = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    // console.log('handleMouseOut')
    props.onMouseLeave && props.onMouseLeave();
  }, [props.onMouseLeave]);

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    // console.log('handleMouseDown')
    e.preventDefault();

    // ... Get the mouse cursor position at startup:
    xDown.current = e.clientX ;
    yDown.current = e.clientY ;
    document.addEventListener('mouseup', closeDragElement);
    document.addEventListener('mousemove', elementDrag);

    bringToFront( /*props.name*/ );

  }, [closeDragElement, elementDrag, bringToFront, props.name]);

  // ... Monitor parent resize
  useResizeObserver( draggableDivRef, () => moveByOffsetInParent({left: 0, top: 0}) );
  useEffect(()=>{
    if ( !draggableDivRef?.current ) {
      return ;
    }
    const draggableDiv: HTMLDivElement = draggableDivRef.current;
    if ( !draggableDiv.parentElement ) {
      return ;
    }
    const parentDiv: HTMLElement =  draggableDiv.parentElement ;

    const resizeObserver = new ResizeObserver(()=>{
      moveByOffsetInParent({left: 0, top: 0});
    });
    if (parentDiv) {
      resizeObserver.observe(parentDiv);
      resizeObserver.observe(draggableDiv);
      return () => {
        resizeObserver.unobserve(parentDiv);
        resizeObserver.unobserve(draggableDiv);
      };
    }
  }, [moveByOffsetInParent]);

  return (
      <>
      { positionInParent && (
        <div
            ref={draggableDivRef}
            className={`${props.className}`}
            style={{
              ...props.style,
              backgroundColor: "#000000dd",
              display: "flex",
              position: "absolute",
              left: `${positionInParent.x}px`,
              top: `${positionInParent.y}px`,
              zIndex: `${zIndex}`
            }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
          <div
              id="dragHeader"
              className="w3-center unselectable w3-padding-small"
              style={{cursor: "move"}}
              onMouseDown={handleMouseDown}
          >
            <div
              onClick={()=>{bringToFront()}}
              style={{cursor: "default"}}
            >
            {
              props.children
            }
            </div>
          </div>
        </div>
      )}
      </>
  )
}

export default FloatingContainer ;
