import React, {useRef, useEffect, CSSProperties, useCallback} from 'react';
import {withTranslation, WithTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquare} from "@fortawesome/free-solid-svg-icons";
import {useScreenRecording} from "../Contexts/ScreenRecordingContextProvider";

interface IProps extends WithTranslation {
  className?: string|undefined;
  style?: CSSProperties|undefined;
  idealWidth?: number;
  idealHeight?: number;
}

const ScreenRecording: React.FC<IProps> = (props) => {
  const t = props.t;

  const className = props.className ?? "w3-button";
  const style = props.style ;
  const idealWidth = props.idealWidth ?? 1280;
  const idealHeight = props.idealHeight ?? 1024;

  const [screenRecordingState, screenRecordingActions] = useScreenRecording();

  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const recordedChunks = useRef<Blob[]>([]);

  const setIsRecording = screenRecordingActions.setIsRecording ;

  const startRecording = useCallback( async () => {
    const displayMediaOptions = {
      video: {
        width: { ideal: idealWidth },  // Lower resolution
        height: { ideal: idealHeight },
        frameRate: { ideal: 10, max: 20 } // Lower frame rate
      }
    };
    const stream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
    mediaRecorder.current = new MediaRecorder(stream);

    mediaRecorder.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunks.current.push(event.data);
      }
    };

    mediaRecorder.current.onerror = (event) => {
      console.error("Error recording: ", event.error);
    };

    mediaRecorder.current.onstop = () => {
      const filename = window.prompt(t("File Name"), "recorded-video.webm");
      if (!filename) {
        console.warn("No filename!");
        return;
      }  // User canceled the prompt

      const blob = new Blob(recordedChunks.current, { type: 'video/webm' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      recordedChunks.current = [];
      setIsRecording(false);
    };

    mediaRecorder.current.start();
    setIsRecording(true);
  }, [setIsRecording, t, idealWidth, idealHeight]);

  const stopRecording = useCallback(() => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();

      // Stop the tracks to end screen sharing
      mediaRecorder.current.stream.getTracks().forEach(track => track.stop());

      setIsRecording(false);
    }
  }, [setIsRecording]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && mediaRecorder.current && mediaRecorder.current.state === "recording") {
        stopRecording();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      // Stop recording if it's still ongoing
      if (screenRecordingState.isRecording) {
        stopRecording();
      }
    };
  }, [stopRecording, screenRecordingState.isRecording]);

  return (
      <>
        { screenRecordingState.isEnabled && !screenRecordingState.isRecording && (
            <button className={className} style={style} onClick={screenRecordingState.isRecording ? stopRecording : startRecording}>
              <FontAwesomeIcon icon={faSquare}/> {screenRecordingState.isRecording ? t("Stop Recording") : t("Start Recording")}
            </button>
        )}
      </>
  );
}

export default withTranslation()(ScreenRecording);
