import {Cartesian3, Matrix4, Plane} from "cesium";
import {TilesetUtils} from "../util/TilesetUtils";

export interface IBoundingBox {
  minPos: Cartesian3;
  maxPos: Cartesian3;
  minIdx: Cartesian3;
  maxIdx: Cartesian3;
}

export const DEFAULT_BOUNDING_BOX: IBoundingBox = {
  minPos: new Cartesian3(),
  maxPos: new Cartesian3(),
  minIdx: new Cartesian3(),
  maxIdx: new Cartesian3(),
}

export class BoundingBoxUtil {
  public static extend(toExtend: IBoundingBox|undefined, other: IBoundingBox): IBoundingBox {
    if (!toExtend) return {...other};

    toExtend.minIdx = toExtend.minIdx ? BoundingBoxUtil.min( toExtend.minIdx, other.minIdx ) : other.minIdx ;
    toExtend.maxIdx = toExtend.maxIdx ? BoundingBoxUtil.max( toExtend.maxIdx, other.maxIdx ) : other.maxIdx ;
    toExtend.minPos = toExtend.minPos ? BoundingBoxUtil.min( toExtend.minPos, other.minPos ) : other.minPos ;
    toExtend.maxPos = toExtend.maxPos ? BoundingBoxUtil.max( toExtend.maxPos, other.maxPos ) : other.maxPos ;

    return {...toExtend} ; // have to spread-copy to trigger rendering refresh
  }

  public static center( boundingBox: IBoundingBox ): Cartesian3 {
    return new Cartesian3(
        (boundingBox.minPos.x + boundingBox.maxPos.x) / 2,
        (boundingBox.minPos.y + boundingBox.maxPos.y) / 2,
        (boundingBox.minPos.z + boundingBox.maxPos.z) / 2
    );
  }

  public static diagonalLength( boundingBox: IBoundingBox ): number {
    return Cartesian3.distance( boundingBox.minPos, boundingBox.maxPos );
  }

  private static min( p1: Cartesian3, p2: Cartesian3): Cartesian3 {
    return new Cartesian3(
        Math.min( p1.x, p2.x ),
        Math.min( p1.y, p2.y ),
        Math.min( p1.z, p2.z ),
    );
  }
  private static max( p1: Cartesian3, p2: Cartesian3): Cartesian3 {
    return new Cartesian3(
        Math.max( p1.x, p2.x ),
        Math.max( p1.y, p2.y ),
        Math.max( p1.z, p2.z ),
    );
  }
  public static getTopPlane(boundingBox: IBoundingBox, modelMatrix: Matrix4):  Plane|undefined {
    let p1 = TilesetUtils.localToWorld( new Cartesian3( boundingBox.minPos.x, boundingBox.minPos.y, boundingBox.maxPos.z ), modelMatrix )
    let p2 = TilesetUtils.localToWorld( new Cartesian3( boundingBox.minPos.x, boundingBox.minPos.y, boundingBox.maxPos.z + 10 ), modelMatrix )
    let normal = Cartesian3.normalize(Cartesian3.subtract( p2, p1, new Cartesian3() ), new Cartesian3());
    return Plane.fromPointNormal(p1, normal);
  }
}