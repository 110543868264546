import React, {useCallback} from "react";
import {IPatternSummary} from "../../domain/IPatternSummary";
import SideMenuRow from "../../../SideMenuItems/SideMenuRow";
import {withTranslation, WithTranslation} from "react-i18next";
import {t} from "i18next";
import {faCloudArrowDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IAssetSummary from "../../domain/IAssetSummary";
import {useTooltips} from "../Contexts/TooltipContextProvider";
import {useUserSessionContext} from "../Contexts/UserSessionContext";

interface IProps extends WithTranslation {
  id?:string;
  patternSummaries: IPatternSummary[];
  onPatternVisibilityChanged: (pattern: IPatternSummary, visibility: boolean )=>void;
  onUpdatePatternRequested: (assetsToUpdate: IAssetSummary[]) => void;
}

const widths = ["20%", "60%", "20%"];

const PatternsFilterTable: React.FC<IProps> = (props) => {
  const [, tooltipActions] = useTooltips();
  const [userSession] = useUserSessionContext();

  const assetsToUpdateForThisPattern = useCallback((patternSummary: IPatternSummary): IAssetSummary[] => {
    if ( !userSession.assetsToManuallyUpdate || userSession.assetsToManuallyUpdate.length === 0 ) {
      return [] ;
    }
    console.log(`patternNeedsUpdate(${patternSummary.patternId}): ${userSession.assetsToManuallyUpdate.map(ass=>ass.patternId)}`);
    return userSession.assetsToManuallyUpdate.filter( assetToUpdate => assetToUpdate.patternId === patternSummary.patternId );
  }, [userSession.assetsToManuallyUpdate]);

  const onChangeTooltip = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, tooltip: React.ReactNode|undefined ) => {
    const parent = document.getElementById("cesiumViewer")?.getBoundingClientRect() ?? document.body.getBoundingClientRect();
    const rect = e.currentTarget.getBoundingClientRect() ;
    tooltipActions.setTooltip(tooltip ? {
      content: tooltip,
      position: {
        x: rect.x + rect.width + 20,
        y: rect.y - parent.y + rect.height/2
      },
      orientation: "right"
    } : undefined )
  }

  return (
      <div id={props.id}>
        {/*table header*/}
        <SideMenuRow widths={widths} className={"w3-margin-bottom w3-margin-top"}>
          <div className={"unselectable w3-left-align"} style={{ fontSize: "12px" }}><b>{t("visibility")}</b></div>
          <div className={"unselectable w3-left-align"} style={{ fontSize: "12px" }}><b>{t("Pattern")}</b></div>
          <div className={"unselectable w3-right-align"} style={{ fontSize: "12px" }}><b>{t("update")}</b></div>
        </SideMenuRow>

        {
          props.patternSummaries
              .sort((a, b) => a.name.localeCompare(b.name))
              .map( (patternSummary, idx) => {

                let patternVisibility = userSession.patternVisibilities.find( pv => pv.patternId === patternSummary.patternId )?.visibility ?? true ;

                const assetsToUpdate = assetsToUpdateForThisPattern( patternSummary ) ;
                const needsUpdate = assetsToUpdate.length > 0 ;
                const tooltipMsg = needsUpdate ? t("data_available_click_to_refresh") : t("data_up_to_date")

                return (
                    <SideMenuRow
                        key={`PatternFilterTable_${idx}`}
                        widths={widths}
                        className={"w3-margin-bottom"}
                        style={{ fontSize: "12px", verticalAlign: "middle"}}
                    >
                      <div className={'w3-left-align'}>
                        <input
                            id={patternSummary.patternId + "_checkbox"}
                            type="checkbox"
                            className="w3-check"
                            checked={patternVisibility}
                            onChange={(e) => props.onPatternVisibilityChanged(patternSummary, e.target.checked)}
                        />
                      </div>
                      <div className={'w3-left-align'}>
                        <label
                            className={"unselectable"}
                            htmlFor={patternSummary.patternId + "_checkbox"}
                        >
                          {patternSummary.name}
                        </label>
                      </div>
                      <div
                          className={'w3-right-align'}
                          onMouseEnter={(e)=>onChangeTooltip(e, <div>{tooltipMsg}</div>)}
                          onMouseLeave={(e)=>onChangeTooltip(e, undefined)}
                      >
                        <FontAwesomeIcon
                            className={`unselectable ${needsUpdate ? "w3-text-light-blue" : "w3-text-gray"} tour-pattern-update-icon`}
                            icon={faCloudArrowDown}
                            bounce={needsUpdate}
                            onClick={(e) => {
                              if (needsUpdate) {
                                props.onUpdatePatternRequested(assetsToUpdate);
                              }
                            }}
                        />
                      </div>
                    </SideMenuRow>
                )
              })
        }
      </div>
  )
}

export default withTranslation()(React.memo(PatternsFilterTable));

