import React, {useCallback, useEffect, useMemo, useState} from "react";
import Cesium, {Cesium3DTileFeature, Color, SceneMode} from "cesium";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import {BlastHoleStylist} from "../../Styling/BlastHoleStylist";
import {useSiteConfig} from "../../hooks/useSiteConfig";

type SegmentType = "collar" | "segment" | "toe";

interface IProps {
  width: number|string;
  height: number|string;
  holeName: string;
  segmentFeatures: Cesium3DTileFeature[];
  selectedFeature:Cesium3DTileFeature|undefined;
  onSelectedFeatureChanged: (selectedFeature:Cesium3DTileFeature|undefined)=>void;
}

interface ISegment {
  segmentType: SegmentType;
  feature: Cesium3DTileFeature;
  startDepth: number;
  endDepth: number;
  color: string;
}

const BlastholeSvg: React.FC<IProps> = (props) => {
  const w3LightBlue = "#87CEEB";
  const viewPortWidth = 300 ; // props.width
  const viewPortHeight = 600 ; // props.height
  const titleFontSize = 16;
  const labelFontSize = 0.75 * titleFontSize;

  // ... Segments sorted by elevation
  const sf = props.segmentFeatures ;
  const selectedFeatureId = props.selectedFeature?.featureId;

  const [userSession] = useUserSessionContext();
  const [siteConfig] = useSiteConfig();
  const [segments, setSegments] = useState<ISegment[]>([]);
  const [totalDepth, setTotalDepth] = useState<number>();
  const [selected, setSelected] = useState<number|undefined>();

  const stylist = useMemo(()=>{
    return new BlastHoleStylist(userSession, siteConfig, SceneMode.SCENE3D );
  }, [userSession, siteConfig]);

  useEffect(()=>{
    const sortedFeatures = sf.sort((s1, s2)=> +s2.getProperty("from_z") - (+s1.getProperty("from_z")));
    let result: ISegment[] = [];
    let startDepth = 0;

    sortedFeatures.forEach( (feature, idx) => {
      const segmentType: SegmentType = (idx === 0) ? "collar" :
          (idx === sortedFeatures.length-1) ? "toe" : "segment";

      const from_z = +feature.getProperty("from_z");
      const to_z = +feature.getProperty("to_z");
      const depth = from_z - to_z;

      result.push({
        segmentType: segmentType,
        feature: feature,
        startDepth: startDepth,
        endDepth: startDepth + depth,
        color: stylist.evaluateColor( feature ).toCssColorString()
      });

      startDepth += depth ;

      if ( selectedFeatureId !== undefined && feature.featureId === selectedFeatureId ) {
        setSelected( idx );
      }
    } );

    setSegments(result);
    setTotalDepth(startDepth);
  }, [sf, stylist, selectedFeatureId]);

  const textColorTitle = w3LightBlue;
  const ellipseRadiusX = viewPortWidth/6;
  const ellipseRadiusY = ellipseRadiusX/5;
  const ellipseCenterX = viewPortWidth/2;
  // const ellipseCenterY = 4*(titleFontSize + 2);
  const ellipseCenterY = 3*(labelFontSize + 2);
  const ellipseLeft = ellipseCenterX - ellipseRadiusX;
  const ellipseRight = ellipseCenterX + ellipseRadiusX;

  // const depthLabelsY = 2.0*(titleFontSize + 2);
  const depthLabelsY = labelFontSize + 2;

  const onSelectedFeatureChanged = props.onSelectedFeatureChanged;
  const handleOnSelected = useCallback((idx: number) => {
    onSelectedFeatureChanged && onSelectedFeatureChanged(segments[idx].feature);
  }, [onSelectedFeatureChanged, segments]);

  return (
      <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            viewBox={`0 0 ${viewPortWidth} ${viewPortHeight}`}
            onClick={(e)=> {
              setSelected(undefined);
            }}
        >
          {/*Frame*/}
          {/*<rect width={viewPortWidth} height={viewPortHeight} style={{*/}
          {/*  stroke: "#ffffff",*/}
          {/*  strokeWidth: "1px",*/}
          {/*  // fill: "#FFaa00ff"*/}
          {/*}}/>*/}

          {/*Title*/}
          {/*<SvgTitle text={props.holeName} fontSize={titleFontSize} color={textColorTitle}/>*/}

          {/* Elev label */}
          <text x={ellipseLeft/2} textAnchor="middle" y={depthLabelsY} textDecoration={"underline"} className={"unselectable"} style={{ fill: "white", fontSize: `${titleFontSize}px` }}>
            Elev
          </text>

          {/* Depth label */}
          <text x={(ellipseRight+viewPortWidth)/2} textAnchor="middle" y={depthLabelsY} textDecoration={"underline"} className={"unselectable"} style={{ fill: "white", fontSize: `${titleFontSize}px` }} >
            Depth
          </text>

          {/* Segments */}
          { totalDepth &&
            segments.map( (segment, idx) => {

              const totalHeight = viewPortHeight - ellipseCenterY - ellipseRadiusY*2;

              return (
                <SegmentSvg
                    segment={segment}
                    left={ellipseLeft} right={ellipseRight}
                    top={ellipseCenterY + segment.startDepth * ( totalHeight ) / totalDepth }
                    bottom={ellipseCenterY + segment.endDepth * ( totalHeight ) / totalDepth}
                    ellipseRadiusY={ellipseRadiusY} stroke={selected === idx ? "white" : "#aaaaaa"} fill={segment.color} fontSize={labelFontSize}
                    onSelected={()=>handleOnSelected(idx)} isSelected={selected === idx}
                />
            )})
          }
        </svg>
      </>
  )
}

export default BlastholeSvg;

interface ISegmentSvgProps {
  segment: ISegment;
  left: number;
  right: number;
  top: number;
  bottom: number;
  ellipseRadiusY: number;
  stroke: string;
  fill: string;
  fontSize: number;
  isSelected?:boolean|undefined;
  onSelected: ()=>void;
}

const SegmentSvg: React.FC<ISegmentSvgProps> = (props) => {
  const selectedLineWidth = 3;

  const graduatedLineWidth = 15;
  const rx = (props.right - props.left)/2;
  const cx = (props.left + props.right)/2;

  const feature = props.segment.feature;
  const topElev = useMemo(()=> feature?.getProperty( "from_z"), [feature]);
  const bottomElev = useMemo(()=> feature?.getProperty( "to_z"), [feature]);
  const topDepth = props.segment.startDepth;
  const segmentDepth = useMemo(()=>topElev-bottomElev, [topElev, bottomElev]);
  const bottomDepth = useMemo(()=>topDepth+segmentDepth, [topDepth, segmentDepth]);

  return (
      <>
        { props.segment.segmentType === "collar" && (
            <ellipse
                cx={cx} cy={props.top} rx={rx} ry={props.ellipseRadiusY} stroke={props.stroke} fill={props.fill}
                onClick={(e)=> {
                  e.stopPropagation();
                  props.onSelected();
                }}
                strokeWidth={props.isSelected ? selectedLineWidth : 0.5}
            />
        )}

        {/* Draws the cylinder */}
        <path
            d={`M${props.left},${props.top} A${rx},${props.ellipseRadiusY} 0 0,0 ${props.right},${props.top} L${props.right},${props.bottom} A${rx},${props.ellipseRadiusY} 0 0,1 ${props.left},${props.bottom} L${props.left},${props.top}`}
            stroke={props.stroke} fill={props.fill} strokeWidth={props.isSelected ? selectedLineWidth : 1}
            onClick={(e)=> {
              e.stopPropagation();
              props.onSelected();
            }}
        />

        {/* Elevation */}
        <line x1={props.left - 5 - graduatedLineWidth} y1={props.top} x2={props.left-5} y2={props.top} stroke={"white"} strokeDasharray={"1 2"}/>
        <text x={props.left - 10 - graduatedLineWidth} textAnchor="end" y={props.top+props.fontSize*0.35} className={"unselectable"} style={{ fill: "white", fontSize: `${props.fontSize}px` }} >
          {topElev.toFixed(2)}
        </text>

        {/* Depth */}
        <line x1={props.right + 5} y1={props.top} x2={props.right + 5 + graduatedLineWidth} y2={props.top} stroke={"white"} strokeDasharray={"1 2"}/>
        <text x={props.right + 10 + graduatedLineWidth} textAnchor="start" y={props.top+props.fontSize*0.35} className={"unselectable"} style={{ fill: "white", fontSize: `${props.fontSize}px` }} >
          {topDepth.toFixed(2)}
        </text>

        { props.segment.segmentType === "toe" && (
            <>
              {/* Elevation */}
              <line x1={props.left - 5 - graduatedLineWidth} y1={props.bottom} x2={props.left-5} y2={props.bottom} stroke={"white"} strokeDasharray={"1 2"}/>
              <text x={props.left - 10 - graduatedLineWidth} textAnchor="end" y={props.bottom+props.fontSize*0.35} className={"unselectable"} style={{ fill: "white", fontSize: `${props.fontSize}px` }} >
                {bottomElev.toFixed(2)}
              </text>

              {/* Depth */}
              <line x1={props.right + 5} y1={props.bottom} x2={props.right + 5 + graduatedLineWidth} y2={props.bottom} stroke={"white"} strokeDasharray={"1 2"}/>
              <text x={props.right + 10 + graduatedLineWidth} textAnchor="start" y={props.bottom+props.fontSize*0.35} className={"unselectable"} style={{ fill: "white", fontSize: `${props.fontSize}px` }} >
                {bottomDepth.toFixed(2)}
              </text>
            </>
        )}

      </>
  )
}


const SvgTitle: React.FC<{text: string, fontSize: number, color: string}> = (props) => {
  return (
      <>
        <text
            x="50%" textAnchor="middle" y={props.fontSize + 2} className={"unselectable"}
            style={{ fill: props.color, fontSize: `${props.fontSize}px` }}
        >
          {props.text}
        </text>
      </>
  )
}
