import React, {useCallback, useEffect, useState} from "react";
import {useFetchWithAuth} from "../../../../lib/auth/fetchWithAuth";
import {apiRoot} from "../../../../lib/routes";
import useSafeInterval from "../../../../lib/hooks/useSafeInterval";

export const SystemInfoContext = React.createContext<any>({});

export interface ISystemInfo {
  fileSystemInfo: IFileSystemInfo ;
}

export interface IFileSystemInfo {
  totalSpaceBytes: number ;
  freeSpaceBytes: number ;
  usedSpaceBytes: number ;
  freeSpacePercent: number ;
  usedSpacePercent: number ;
}

export interface IProps {
  updateInterval: number;
}

export const SystemInfoContextProvider: React.FC<IProps> = (props) => {
  const fetchWithAuth = useFetchWithAuth();

  const updateInterval = props.updateInterval;

  const [state, setState] = useState<ISystemInfo>();

  const fetchFileSystemInfo = useCallback(async()=>{
    return await fetchWithAuth( `${apiRoot}/system/fsinfo` )
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`fetchFileSystemInfo: status=${resp.status}`)
          }
          return resp.json();
        })
        .then( json => {
          return {
            totalSpaceBytes: json.totalSpaceBytes,
            freeSpaceBytes: json.freeSpaceBytes,
            usedSpaceBytes: json.usedSpaceBytes,
            freeSpacePercent: json.freeSpacePercent,
            usedSpacePercent: json.usedSpacePercent
          } as IFileSystemInfo;
        })
  }, [fetchWithAuth])

  const refreshSystemInfo = useCallback(() => {
    fetchFileSystemInfo()
        .then( fileSystemInfo => {
          setState( prevState => {
            return prevState
                ? {...prevState, fileSystemInfo: fileSystemInfo }
                : { fileSystemInfo: fileSystemInfo }
          } )
        } );
  }, [fetchFileSystemInfo]);

  useEffect( ()=> refreshSystemInfo(), [refreshSystemInfo]);
  useSafeInterval(async () => refreshSystemInfo(), updateInterval );

  return (
      <SystemInfoContext.Provider value={[state]}>
        {props.children}
      </SystemInfoContext.Provider>
  )
}

export const useSystemInfo = (): [ISystemInfo] => {
  const [state] = React.useContext(SystemInfoContext);
  return [state] ;
}
