export enum LayerType {
    Undefined,
    Boundary,
    BlockModel,
    BlastholeSecondarySegments,
    BlastholeSingleSegment,
    DrilledHolesContour,
    BlockVolume,
    DrilledHoleCollars,
    BlastholeClusters,
    BlastholeFractures,
    CrossSectionX,
    CrossSectionY,
    CrossSectionZ
}

export const fromLayerTypeString = (value: string): LayerType|undefined => {
    for (let item in LayerType) {
        if (isNaN(Number(item))) {
            if ( item === value ) {
                return LayerType[item as keyof typeof LayerType] ;
            }
        }
    }
    return undefined ;
}

export const toLayerTypeString = ( value: LayerType ): string /*| undefined*/ => {
    return LayerType[value] ;
}

export const foreachLayerType = ( callback:( layerType: LayerType ) => void ) => {
    for (let item in LayerType) {
        if (isNaN(Number(item))) {
            callback( LayerType[item as keyof typeof LayerType] );
        }
    }
}

export default interface ILayerTypes<T> {
    Undefined: T;
    BlockModel: T;
    BlastholeSecondarySegments: T;
    BlastholeSingleSegment: T;
    DrilledHolesContour: T;
    BlockVolume: T;
    DrilledHoleCollars: T;
    Boundary: T;
    BlastholeClusters: T;
    BlastholeFractures: T;
    CrossSectionX: T;
    CrossSectionY: T;
    CrossSectionZ: T;
}

export const ACCESSIBLE_LAYERS: ILayerTypes<boolean> = {
    Undefined: false, // invalid
    BlockModel: true,
    BlastholeSecondarySegments: true,
    BlastholeSingleSegment: true,
    DrilledHolesContour: false, // obsolete
    BlockVolume: false, // obsolete
    DrilledHoleCollars: false, // point cloud... misleading name
    Boundary: true,
    BlastholeClusters: false, // hidden, used in 2D map
    BlastholeFractures: true,
    CrossSectionX: true,
    CrossSectionY: true,
    CrossSectionZ: true,
} ;
