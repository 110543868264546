import toast from "react-hot-toast";
import {Link, NavigateFunction, useNavigate} from "react-router-dom";
import UserSession from "../userSession";
import redirectIfForbidden from "./redirectIfForbidden";
import {refreshAuthTokens} from "./refreshAuthTokens";
import {useCallback} from "react";

const requestInProgressDelayMillisecond = 1000;
const noNetworkToastId = "no-network-toast";
const requestInProgressToastId = "request-in-progress";
const networkConnectionResumedToastId = "network-resumed-toast";

const showError = () => {
  // toast.dismiss(requestInProgressToastId);
  // toast.error("Network connectivity lost... Waiting for server to respond.", {
  //   duration: Infinity,
  //   id: noNetworkToastId,
  //   position: "top-center",
  // });

  console.warn("Network connectivity lost... Waiting for server to respond.");
};

const showConnectionResumed = () => {
  //const redirectUrl = window.location.pathname + window.location.search;

  console.log("Connection resumed!");

  // toast.success(
  //   (t) => (
  //     <>
  //       Network connectivity restored. &nbsp;
  //       <Link to={""} onClick={() => window.location.reload()}>
  //         reload
  //       </Link>
  //     </>
  //   ),
  //   {
  //     duration: Infinity,
  //     position: "top-center",
  //     id: networkConnectionResumedToastId,
  //   }
  // );
};

export async function fetchWithAuth(
  navigate: NavigateFunction,
  input: RequestInfo,
  init?: RequestInit | undefined,
  timeout: number = 10000, // 10s by default
  isRetry = false,
  isRefetchAfterRefresh = false
): Promise<Response> {
  let operationInProgressTimeout: NodeJS.Timeout | undefined = undefined;

  if (!isRetry)
    operationInProgressTimeout = setTimeout(() => {
      console.log("Fetching data...");
      // toast.loading("Fetching data...", {
      //   duration: Infinity,
      //   id: requestInProgressToastId,
      //   position: "top-center",
      // });
    }, requestInProgressDelayMillisecond);

  const abortController = new AbortController();
  const connectivityTimeout = setTimeout(() => {
    operationInProgressTimeout && clearTimeout(operationInProgressTimeout);

    abortController.abort();
    showError();

    // retry every 2s from then on
    fetchWithAuth(navigate, input, init, 2000, true).then(() => {
      showConnectionResumed();
    });
  }, timeout);

  const response = await fetch(input, {
    ...init,
    headers: {
      ...init?.headers,
      ...UserSession.getAuthHeaders(),
    },
    signal: abortController.signal,
  });

  clearTimeout(connectivityTimeout);
  operationInProgressTimeout && clearTimeout(operationInProgressTimeout);

  // Dismissing the error on every successful request
  // allows the system to seamlessly resume connection
  // toast.dismiss(requestInProgressToastId);
  // toast.dismiss(noNetworkToastId);

  if (redirectIfForbidden(response, navigate)) return response;

  if (response.status === 401 && !isRefetchAfterRefresh) {
    const refreshResponse = await refreshAuthTokens(navigate);

    if (!refreshResponse.ok) return response;

    const refetchResponse = await fetchWithAuth(
      navigate,
      input,
      init,
      timeout,
      isRetry,
      true
    );
    return refetchResponse;
  }

  return response;
}

/**
 * A Hook wrapper to the fetch with auth function
 * @returns a function that can be called to fetch data with authentication, token refresh and login redirect
 */
export const useFetchWithAuth = () => {
  const navigate = useNavigate();

  return useCallback((
      input: RequestInfo,
      init?: RequestInit | undefined,
      timeout: number = 10000
  ) => fetchWithAuth(navigate, input, init, timeout), [navigate]);
  // (
  //   input: RequestInfo,
  //   init?: RequestInit | undefined,
  //   timeout: number = 10000
  // ) => fetchWithAuth(navigate, input, init, timeout);
};
