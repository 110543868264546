import React from "react";
import {t} from "i18next";

interface IProps {
  id?: string;
  checked: boolean;
  onCheckedChanged: (checked: boolean)=>void;
  disabled?:boolean;
}

const FlipSwitch: React.FC<IProps> = (props) => {
  return (
      <input
          id={props.id}
          type="checkbox"
          flip-state-on={t("ON")}
          flip-state-off={t("OFF")}
          className="flipswitch w3-right"
          checked={props.checked}
          onChange={(e) => {
            props.onCheckedChanged(e.target.checked);
          }}
          disabled={props.disabled??false}
      />
  )
}

export default FlipSwitch ;

