import {ChangeEvent, CSSProperties, useState} from "react";

interface Props {
  id?: string;
  checked: boolean | undefined;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
  className?: string;
}

const TriStateCheckbox: React.FC<Props> = ({
  checked,
  disabled,
  id,
  onChange = () => {},
  style = {},
  className = "",
}) => {
  return (
    <input
      id={id}
      type="checkbox"
      style={style}
      className={className}
      ref={(input) => {
        if (!input) return;
        input.checked = checked === true;
        input.indeterminate = checked === undefined;
        input.disabled = disabled as boolean;
      }}
      onChange={(e) => {
        onChange(e);
      }}
    />
  );
};

export default TriStateCheckbox;
