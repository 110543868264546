import {withTranslation, WithTranslation} from "react-i18next";
import {Cesium3DTileFeature} from "cesium";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {FeatureAdapter} from "../../../../lib/cesium/Tileset/FeatureAdapter";
import {LayerType} from "../../../../model/LayerType";
import {AttributeType} from "../../domain/AttributeType";
import {useUserSessionContext} from "../Contexts/UserSessionContext";

interface IFeatureInfoboxProps extends WithTranslation {
  feature: Cesium3DTileFeature
  className?:string;
  style?:any;
}

const FeatureInfobox: React.FC<IFeatureInfoboxProps> = (props) => {
  const t = props.t;
  const [, actions] = useSiteConfig();
  const [holeCount, setHoleCount] = useState<number>();
  const [drillIds, setDrillIds] = useState<string>();
  const [userSession,userSessionActions, userSessionMoreActions] = useUserSessionContext();

  // useEffect(()=>console.log(`userSessionActions has changed`), [userSessionActions]);
  // useEffect(()=>console.log(`setHoleCount has changed`), [setHoleCount]);
  // useEffect(()=>console.log(`setDrillIds has changed`), [setDrillIds]);
  // useEffect(()=>console.log(`props.feature has changed`), [props.feature]);


  const getPatternHoleCount = userSessionActions.getPatternHoleCount ;
  const getPatternDrillIds = userSessionMoreActions.getPatternDrillIds ;

  useEffect(()=>{
    console.log(`FeatureInfobox: (1)`);
    const patternId = props.feature.tileset.extras.ID ;
    // console.log(`patternId=${patternId}`);
    const layerType = LayerType[ props.feature.tileset.extras.Layer as keyof typeof LayerType] ;
    if ( layerType === LayerType.Boundary ) {
      let holeCount = getPatternHoleCount(patternId);
      console.log(`FeatureInfobox: (2) = holeCount=${holeCount}`);
      setHoleCount(holeCount);
      setDrillIds(getPatternDrillIds(patternId).join(", "));
    }
    console.log(`FeatureInfobox: (3)`);
  }, [getPatternHoleCount, getPatternDrillIds, setHoleCount, props.feature, setDrillIds]);


  // useEffect(()=>console.log(`drillIds=${drillIds}`), [drillIds]);

  // ... Convert map if properties to an array
  const embeddedProperties = useMemo(()=>{
    return Array.from(FeatureAdapter.ATTRIBUTE_KEY_MAPPINGS.entries());
  }, []);

  // ... Set the title of the info box
  const title = useMemo(()=>{
    let patternName = props.feature.getProperty("Pattern");
    let holeName = props.feature.getProperty("HoleName");
    let holeId = props.feature.getProperty("HoleID");

    if ( patternName ) {
      if (holeName || holeId ) {
        if ( !holeId ) {
          return `${patternName} - ${holeName}`;
        }
        else if ( !holeName ) {
          return `${patternName} - ${holeId}`;
        }
        else if (holeName !== holeId.toString()) {
          return `${patternName} - ${holeName} (${holeId})`;
        }
        else {
          return `${patternName} - ${holeName}`;
        }
      } else {
        return patternName ;
      }
    } else {
      return props.feature.featureId.toString();
    }
  }, [props.feature]);

  // ... Define a callback for formatting each feature property
  const getFormattedValue = useCallback(( attributeName: string, attributeValue: any ): string|undefined =>{
    console.log(`>>> getFormattedValue: ${attributeName} = ${attributeValue}`)
    if ( attributeValue === undefined || attributeValue === null ) {
      return undefined;
    }
    const precision = actions.getPrecisionForAttribute( attributeName );
    if ( precision !== undefined ) {
      if ( !Number.isNaN(attributeValue) ) {
        return attributeValue.toFixed(precision);
      }
    }
    else if ( attributeValue instanceof Array ) {
      return JSON.stringify(attributeValue, function(key, val) {
        return val.toFixed ? Number(val.toFixed(3)) : val;
      });
    }
    else if ( attributeValue instanceof Object ) {
      return JSON.stringify(attributeValue, function(key, val) {
        return val.toFixed ? Number(val.toFixed(3)) : val;
      });
    }
    return `${attributeValue ? t(attributeValue) : attributeValue}`;
  }, [actions]);

  const selectedAttributeName = useMemo((): string|undefined => {
    return userSession.selectedAttribute ? AttributeType[userSession.selectedAttribute] : undefined ;
  }, [userSession.selectedAttribute]);

  return (
      <div className={props.className} style={props.style}>
        <div className={"w3-center w3-small w3-margin-bottom"}>
          {title}
        </div>
        <table className={`w3-table w3-tiny w3-border ${props.className}`}>
            <tbody>
            {
              embeddedProperties
                  .filter( ([v, k], idx) => {
                    const attributeValue = props.feature.getProperty(k);
                    return (attributeValue !== undefined && attributeValue !== null);
                  } )
                  .map( ([v, k], idx) => {
                    const attributeValue = props.feature.getProperty(k);
                    const isSelected = (selectedAttributeName === k) ;
                    return (
                        <tr
                            key={`FeatureInfobox_r${idx}`}
                            className={`w3-border ${props.className} ${isSelected ? "bold w3-text-white":""}`}
                        >
                          <td>{t(k)}</td>
                          <td>{getFormattedValue(k, attributeValue)}</td>
                        </tr>
                    );
                  })
            }
            { (holeCount !== undefined) && (
                <tr
                    className={`w3-border ${props.className}`}
                >
                  <td>{t("HoleCount")}</td>
                  <td>{holeCount}</td>
                </tr>
            )}
            { drillIds && (
                <tr
                    className={`w3-border ${props.className}`}
                >
                  <td>{t("Drills")}</td>
                  <td>{drillIds}</td>
                </tr>
            )}
            </tbody>
          </table>
      </div>
  )
}

export default withTranslation()(FeatureInfobox);
