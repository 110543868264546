import Cookies from "js-cookie";
import {useEffect, useState} from "react";
import { AuthApi } from "../routes";

export const logoutCallback = () => {
  if (!sessionStorage.getItem("auth_token")) return;

  const token = sessionStorage.getItem("refresh_token") || "";

  Cookies.remove("session");
  sessionStorage.removeItem("userName");
  sessionStorage.removeItem("auth_token");
  sessionStorage.removeItem("auth_token_header");
  sessionStorage.removeItem("roles");
  sessionStorage.removeItem("customerSiteId");
  // sessionStorage.removeItem("logged_in");

  navigator.sendBeacon(AuthApi.postLogoutUrl(), token);
};

// const hasReloaded = (): boolean => {
//   const entries = performance.getEntriesByType("navigation");
//
//   let result = false ;
//
//   entries.forEach((entry) => {
//     if ((entry as PerformanceNavigationTiming).type === "reload") {
//       result = true ;
//     }
//   });
//
//   return result ;
// }

const useLogoutOnClose = () => {

  // const observer = new PerformanceObserver((list) => {
  //   list.getEntries().forEach((entry) => {
  //     if ((entry as PerformanceNavigationTiming).type === "reload") {
  //       console.log(`${entry.name} was reloaded!`);
  //       console.log(entry);
  //     }
  //   });
  // });

  /**
   * BUG: this forces back to login on page refresh
   */

  useEffect(() => {

    // window.addEventListener("unload", logoutCallback, true);
    // window.addEventListener("beforeunload", logoutCallback, true);
    // window.addEventListener("pagehide", logoutCallback, true);

    return () => {

      // window.removeEventListener("unload", logoutCallback, true);
      // window.removeEventListener("beforeunload", logoutCallback, true);
      // window.removeEventListener("pagehide", logoutCallback, true);
    };
  }, []);
};

export default useLogoutOnClose;
