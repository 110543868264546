export interface INotification {
  id: string;
  text: string;
  type:"info"|"warning"|"error";
  timeStamp: number;
  isRead: boolean;
  source: "server"|"client";
}

export function notificationsAreEqual( a: INotification, b: INotification ) {
  if ( `${a.id}` !== `${b.id}` ) return false;
  if ( `${a.text}` !== `${b.text}` ) return false;
  if ( `${a.type}` !== `${b.type}` ) return false;
  if ( `${a.source}` !== `${b.source}` ) return false;
  if ( a.timeStamp !== b.timeStamp ) return false;
  if ( a.isRead !== b.isRead ) return false;
  return true ;
}