import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

import TextAndImage from "./Widgets/TextAndImage";
import {VideoResourceUrl} from "../lib/VideoResourceUrl";

/**
 * Pattern Filters side menu help
 *
 * @param props
 * @constructor
 */

const Help_CesiumView_SideMenu_Filters: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <>
        <div className={'w3-panel'}>
          <h2 className={"w3-left-align"}>{t("The 'Filters' Side Menu")}</h2>
          <p className={"text-full-justify"}>
            {t("This section contains viewing parameters that affect how patterns are displayed, and provides " +
                "notification indicators when patterns need to be updated.")}
          </p>
        </div>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Sub-Drill Display"} text={"Use the 'Show Sub-Drill' checkbox to toggle the visibility of sub-drill data."}
                      src={VideoResourceUrl("subdrill.webm")}
                      imgRatio={"w3-twothird"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Pattern Table"}
                      text={"Use the 'Visibility' column to individually toggle the visibility " +
            "of each pattern.  The 'Update' column will display animated icons whenever there is data to be fetched " +
            "from the application repositories"}
                      src={VideoResourceUrl("pattern_visibility.webm")}
                      imgRatio={"w3-twothird"}/>

      </>
  )
}

export default withTranslation()(Help_CesiumView_SideMenu_Filters);

