import React, {useCallback, useEffect, useMemo} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {useNotifications} from "./NotificationsProvider";
import {faCircleExclamation, faCircleInfo, faTriangleExclamation, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {format} from "date-fns";
import {t} from "i18next";
import BasicDialog from "../../Dialogs/BasicDialog";
import {INotification} from "./INotification";

interface IProps extends WithTranslation {
  onDismiss: ()=>void;
}

const NotificationsDialog: React.FC<IProps> = (props) => {
  const [notifications, notificationActions] = useNotifications();

  const acknowledgeNotifications = notificationActions.acknowledgeNotifications;
  const deleteNotifications = notificationActions.deleteNotifications;

  const handleDelete = useCallback((notification: INotification) => {
    deleteNotifications([notification]);
  }, [deleteNotifications]);

  const handleDeleteAll = useCallback((notificationsToDelete: INotification[]) => {
    deleteNotifications(notificationsToDelete);
  }, [deleteNotifications]);

  const handleViewed = useCallback((notification: INotification) => {
    acknowledgeNotifications([notification]);
  }, [acknowledgeNotifications]);

  // useEffect(()=>{
  //   acknowledgeNotifications([...notifications]);
  // }, []);

  return (
      <BasicDialog
          title={t('Session Events')}
          width={"calc(100vw - 400px)"}
          btnRight={{ label: t("Dismiss"), className: 'w3-grey w3-hover-light-blue', action: props.onDismiss }}
          btnLeft={{ label: t("Clear"), className: 'w3-red w3-hover-light-blue', action: ()=> {
              handleDeleteAll(notifications);
              props.onDismiss();
            }
          }}
      >
        <div className={'w3-padding'} style={{width: "100%", height: "100%"}}>
          { notifications.length > 0
              ? <ul className={`w3-ul w3-card`} >
                { notifications.map( (notification, idx ) => (
                    <NotificationItem
                        key={notification.id} {...notification}
                        onDelete={()=>handleDelete(notification)}
                        onViewed={()=>handleViewed(notification)}
                    />
                ))
                }
              </ul>
              : <div className={'w3-container w3-section w3-center w3-text-light-gray w3-opacity-min'}><i>({t('No events')})</i></div>
          }
        </div>
      </BasicDialog>
  )
}

export default withTranslation()(NotificationsDialog);

interface INotificationItemProps extends INotification {
  onDelete: ()=>void;
  onViewed: ()=>void;
}

const NotificationItem: React.FC<INotificationItemProps> = (props) => {

  const icon = useMemo(()=>{
    switch( props.type ) {
      case "info":
        return faCircleInfo;
      case "warning":
        return faTriangleExclamation
      case "error":
        return faCircleExclamation
    }
  }, [props.type]);

  const iconClass = useMemo(()=>{
    switch( props.type ) {
      case "info":
        return 'w3-text-light-blue';
      case "warning":
        return 'w3-text-amber';
      case "error":
        return 'w3-text-red';
    }
  }, [props.type]);

  const onDelete = props.onDelete;
  const handleDelete = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    onDelete && onDelete();
  }, [onDelete]);

  // ... TODO: need to invoke onViewed only when the user can truly see the item
  const onViewed = props.onViewed;
  useEffect(()=>{
    onViewed();
  }, [onViewed]);

  return (
      <>
        <li
            className={`w3-bar w3-theme-d1 w3-theme-d1-hover`}
            style={{
              height: "65px",
              padding: "1px 1px"
            }}
        >
          <div
              className={'w3-bar-item'}
              style={{
                width: "65px",
                height: "100%",
                padding: "15px 10px"
              }}
          >
            <FontAwesomeIcon
                style={{width: "100%", height: "100%"}}
                className={`${iconClass}`}
                icon={icon}
            />
          </div>
          <div
              className={'w3-bar-item'}
              style={{
                // color: "#ddd",
                width: "calc( 100% - 130px )",
                height: "100%",
                padding: "10px 5px"
              }}
          >
            <div className={''} style={{height: "65%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight: props.isRead ? "normal":"bold"}}>
              {props.text}
            </div>
            <div className={'w3-tiny'} style={{height: "35%"}}>{format(props.timeStamp, t("LONG_DATETIME_FORMAT"))}</div>
          </div>
          <div
              className={'w3-bar-item w3-right w3-padding-16 zoom'}
              style={{ height: "100%" }}
              onClick={handleDelete}
          >
            <FontAwesomeIcon icon={faXmark} size={'lg'}/>
          </div>
        </li>
      </>
  )
}
