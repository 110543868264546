import React, {useContext, useMemo} from "react";
import {Cartesian2, Cartesian3, GridMaterialProperty, Matrix4, Plane} from "cesium";
import {IBoundingBox} from "../../domain/IBoundingBox";
import {Entity, PlaneGraphics} from "resium";
import {withTranslation, WithTranslation} from "react-i18next";
import {TilesetUtils} from "../../util/TilesetUtils";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {useUserSessionContext} from "../Contexts/UserSessionContext";

interface IProps extends WithTranslation {
  visibility: boolean;
  originReference: Cartesian3;
  boundingBox: IBoundingBox;
}

const TOB_SIZE = 3000.0;
const TOB_LINE_COUNT = new Cartesian2( TOB_SIZE / 100, TOB_SIZE / 100 );
const TOB_LINE_WIDTH = new Cartesian2( 0.5, 0.5 );
const TOB_DIMENSIONS = Cartesian2.fromArray([TOB_SIZE, TOB_SIZE]);
const TOB_PLANE = new Plane(Cartesian3.UNIT_Z, 0.0);

const TopOfBenchPlane: React.FC<IProps> = (props) => {
  const [siteData] = useSiteConfig();
  const [userSession] = useUserSessionContext();

  const maxPos = useMemo(() => {
    return TilesetUtils.localToWorld(props.boundingBox.maxPos, siteData.modelMatrix) ;
  }, [props.boundingBox]);

  const material = useMemo(()=> {
    return new GridMaterialProperty({
      color: userSession.topOfBenchPlaneColor,
      cellAlpha: 0.1,
      lineCount: TOB_LINE_COUNT,
      lineThickness: TOB_LINE_WIDTH
    });
  }, [userSession.topOfBenchPlaneColor]);

  return (
      <>
        {userSession.topOfBenchPlaneColor && maxPos && (
            <Entity
                show={props.visibility}
                name={'TopOfBenchPlane'}
                position={maxPos}
            >
              <PlaneGraphics
                  plane={TOB_PLANE}
                  dimensions={TOB_DIMENSIONS}
                  fill={true}
                  material={material}
              />
            </Entity>
        )}
      </>
  )
}

export default withTranslation()(React.memo(TopOfBenchPlane));
