import {IColorLegendItemConfigDto} from "../model/IColorLegendItemConfigDto";
import IColorLegendItemConfiguration from "../domain/IColorLegendItemConfiguration";
import {Color} from "cesium";

export default class ColorLegendItemConfigMapper {
  public static colorLegendItemConfigToColorLegendItemConfigDto( colorLegendItemConfig: IColorLegendItemConfiguration, precision?:number|undefined ): IColorLegendItemConfigDto {
    return {
      range: precision
          ? colorLegendItemConfig.range.map( item => +item.toFixed( precision ) )
          : colorLegendItemConfig.range,
      label: colorLegendItemConfig.label ?? null,
      color: colorLegendItemConfig.color.toCssHexString()
    }
  }
  public static colorLegendItemConfigDtoToColorLegendItemConfig( colorLegendItemConfigDto: IColorLegendItemConfigDto ): IColorLegendItemConfiguration {
    return {
      range: colorLegendItemConfigDto.range,
      label: colorLegendItemConfigDto.label ?? undefined,
      color: Color.fromCssColorString(colorLegendItemConfigDto.color)
    }
  }
}
