import React, {useCallback, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp, faCircleQuestion
} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import Pill from "../Gen2/components/BasicControls/Pill";
import {IHelpRequestedProps, useHelp} from "../../Help/HelpContextProvider";

export enum SubMenuButtonState {
    Collapsed,
    Expanded
}

interface IProps extends IHelpRequestedProps {
    id?: string;
    label: string,
    icon: IconProp,
    state: SubMenuButtonState,
    onSubMenuButtonStateChange: (value: SubMenuButtonState) => void;
    pillValue?:      number;
    onPillClicked?:  ()=>void;
}

const SubMenuButton: React.FC<IProps> = (props) => {
    const [, setHelpRequested] = useHelp();

    const hasHelp: boolean = (props.helpContent !== undefined) ;

    const onToggleButtonState = useCallback(() => {
        switch( props.state ) {
            case SubMenuButtonState.Collapsed:
                props.onSubMenuButtonStateChange( SubMenuButtonState.Expanded ) ;
                break;
            case SubMenuButtonState.Expanded:
                props.onSubMenuButtonStateChange( SubMenuButtonState.Collapsed ) ;
                break;
        }
    }, [props.state, props.onSubMenuButtonStateChange]);

    return (
        <>
            <button
                id={props.id}
                className={`w3-button w3-block w3-bar w3-left-align ${props.state === SubMenuButtonState.Expanded ? "w3-light-blue w3-hover-light-blue" : ""}`}
                onClick={() => onToggleButtonState()}
            >
                <span className="w3-bar-item">
                    <FontAwesomeIcon icon={props.icon} className="w3-margin-right"/>
                    { props.label }
                </span>
                {
                    props.state === SubMenuButtonState.Expanded
                        ? <FontAwesomeIcon className="w3-bar-item w3-right" icon={faChevronUp} />
                        : <FontAwesomeIcon className="w3-bar-item w3-right" icon={faChevronDown} />
                }

                { props.state === SubMenuButtonState.Expanded && hasHelp &&
                    <FontAwesomeIcon
                        className="w3-bar-item w3-right" icon={faCircleQuestion} size={'lg'}
                        onClick={(e)=>{
                            e.stopPropagation();
                            // setNeedHelp( hasHelp );
                            setHelpRequested({
                                helpTitle: props.label,
                                helpContent: props.helpContent
                            })
                        }}
                    />
                    // <HelpMenu theme={'w3-theme-d2'} dropDownItemsStyle={{right: "0"}} disableGuidedTour={true}/>
                }

                { props.state !== SubMenuButtonState.Expanded &&
                    <Pill className={"w3-bar-item w3-right"} value={props.pillValue} onPillClicked={props.onPillClicked}/>
                }
            </button>
        </>
    )
}

export default SubMenuButton;
