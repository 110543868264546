import React, {CSSProperties, useCallback, useEffect, useMemo, useRef, useState} from "react";
import TilesetResource, {getUrlResource} from "./TilesetResource";
import Cesium, {
  Cartesian2,
  Cartesian3,
  Cesium3DTile,
  Cesium3DTileFeature,
  Cesium3DTileset as CesiumCesium3DTileset,
  Color, DirectionalLight,
  Entity as CesiumEntity, HeadingPitchRange, KeyboardEventModifier,
  Matrix3,
  Matrix4,
  NearFarScalar,
  SceneMode,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType
} from "cesium";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import ILayerTypes, {LayerType} from "../../../../model/LayerType";
import {Camera, CesiumComponentRef, CesiumMovementEvent, Globe, Scene, Viewer} from "resium";
import {CameraFrustum} from "../../domain/CameraFrustum";
import perspectiveIconBlack from "../../../../img/perspective.svg";
import orthographicIconBlack from "../../../../img/orthographic.svg";
import {useFetchWithAuth} from "../../../../lib/auth/fetchWithAuth";
import Scale2D, {getPixelPoints} from "../../../scale_2d/Scale2D";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import {TilesetUtils} from "../../util/TilesetUtils";
import IAssetSummary from "../../domain/IAssetSummary";
import {ITilesetResource} from "./ITilesetResource";
import CrossSectionFrames from "./CrossSectionFrames";
import BoundingBox from "../Widgets/BoundingBox";
import TopOfBenchPlane from "../Widgets/TopOfBenchPlane";
import AxesXYZ from "../BasicControls/AxesXYZ";
import ItemHighlight from "../BasicControls/ItemHighlight";
import {compareRockMassDomains, RockMassDomainRange} from "../../../../model/RockMassDomainRange";
import {useCesiumScreenshot} from "../../hooks/useCesiumScreenshot";
import CenterOfRotationEntity from "../BasicControls/CenterOfRotationEntity";
import {useMap} from "usehooks-ts";
import ProgressBar from "../BasicControls/ProgressBar";
import {t} from "i18next";
import {ICameraVantagePoint} from "./ICameraVantagePoint";
import {useRefreshAuthTokens} from "../../../../lib/auth/refreshAuthTokens";
import {useNotifications} from "../../Notifications/NotificationsProvider";
import LinearSelection from "../Widgets/LinearSelection";
import {AppConstants} from "../../../../AppConstants";
import {Corridor} from "../../util/Corridor";

interface IProps {
  className?:string|undefined;
  style?:CSSProperties|undefined;
  sceneMode: SceneMode;
  tilesetResources: ITilesetResource[];
  debugMode?:boolean|undefined;
  onTilesetResourcesRejected?: ((rejected: { tilesetResource: ITilesetResource, reason: string}[])=>void)|undefined;
  cameraVantagePoint?: ICameraVantagePoint|undefined;
  onItemSelected?: ((item: Cesium3DTileFeature|CesiumEntity|undefined)=>void)|undefined;
  children?: React.ReactNode|undefined ;
  handleAllTilesLoad?: ((tileset: Cesium.Cesium3DTileset, resource: ITilesetResource)=>void)|undefined;
  handleTileFailed?: ((tileset: CesiumCesium3DTileset, tilesetResource: ITilesetResource, err: any)=>void)|undefined;
  linearSelectionMode: boolean;
  onLinearSelectionModeChanged: (v: boolean)=>void;
  onLoadingComplete?:()=>void;
}

const VIEWER_STYLE: CSSProperties={ width: "100%", height: "100%" };

const NO_ROTATION = Matrix3.fromRowMajorArray([1, 0, 0, 0, 1, 0, 0, 0, 1]);

const DEFAULT_CORRIDOR_WIDTH_METERS = 10;

/**
 * Generic asset view.  Can be usedin 2D or 3D mode.
 * @param props
 * @constructor
 */
const GenericAssetView: React.FC<IProps> = (props) => {
  // console.log(`Rendering GenericAssetView`);
  const refreshAuthTokens = useRefreshAuthTokens();
  const fetchWithAuth = useFetchWithAuth();
  const getScreenshot = useCesiumScreenshot();
  const [siteConfig] = useSiteConfig();
  const [userSession, userSessionActions, userSessionMoreActions] = useUserSessionContext();
  const [tilesetResources, setTilesetResources] = useState<ITilesetResource[]>([]);
  const [selectedTarget, setSelectedTarget] = useState<Cesium3DTileFeature|CesiumEntity|undefined>();
  const [centerOfRotationPosition, setCenterOfRotationPosition] = useState<Cartesian3|undefined>();

  const [loadingText, setLoadingText] = useState<string | undefined>();
  const [loadingState, loadingStateActions] = useMap<string, number>([]);
  const setLoadingState = useCallback(( key: string, value: number ) => loadingStateActions.set( key, value ), []);
  const [loadingComplete, setLoadingComplete] = useState<boolean>();

  const [, notificationActions] = useNotifications();
  const createNotification = notificationActions.createNotification;

  let viewerRef = useRef<CesiumComponentRef<Cesium.Viewer>>( null );
  let sceneRef = useRef<CesiumComponentRef<Cesium.Scene>>( null );
  let cameraRef = useRef<CesiumComponentRef<Cesium.Camera>>( null );
  let globeRef = useRef<CesiumComponentRef<Cesium.Globe>>( null );

  let viewer = viewerRef.current?.cesiumElement;
  let canvas = viewerRef.current?.cesiumElement?.canvas;
  let scene = sceneRef.current?.cesiumElement;
  let camera = cameraRef.current?.cesiumElement;
  let globe = globeRef.current?.cesiumElement;

  let originReferenceRef = useRef<Cartesian3>();
  let blockSizeRef = useRef<Cartesian3>();
  let highlightTargetRef = useRef<Cesium3DTileFeature|CesiumEntity|undefined>();
  let loadedRef = useRef<boolean>();

  const sceneMode = props.sceneMode ;
  const linearSelectionMode = props.linearSelectionMode;
  // const onLinearSelectionModeChanged = props.onLinearSelectionModeChanged;

  const onLoadingComplete = props.onLoadingComplete;
  useEffect(()=>{
    if ( onLoadingComplete && loadingComplete ) {
      onLoadingComplete();
    }
  }, [onLoadingComplete, loadingComplete]);

  /**
   * Screenshot grabber function, which also saves the camera vantage point
   */
  useEffect(()=>{
    if ( userSession.screenshot !== undefined && camera && userSession.boundingSphere ) {
      if ( userSession.screenshot === sceneMode ) {
        if ( viewerRef.current?.cesiumElement && loadedRef.current ) {
          let imageUrl = getScreenshot(viewerRef.current.cesiumElement);
          console.log( '... screenshot ...')
          userSessionActions.setCameraVantagePoint3D( {
            position: camera.position,
            direction: camera.direction,
            up: camera.up
          } );
          userSessionActions.setImageDataUrl(imageUrl);
          userSessionActions.takeScreenshot(undefined);
        }
      }
    }
  }, [userSession.screenshot, camera, userSession.boundingSphere]);

  /**
   * Initialize the viewer
   */
  useEffect(()=>{
    console.log(`viewer has changed`);
    if ( viewer ) {
      viewer.clock.shouldAnimate = true;
      if (
          viewer &&
          viewer.cesiumWidget &&
          viewer.cesiumWidget.creditContainer &&
          viewer.cesiumWidget.creditContainer.parentNode
      ) {
        viewer.cesiumWidget.creditContainer.parentNode.removeChild(
            // Remove Cesium credits
            viewer.cesiumWidget.creditContainer
        );
      }
    }
  }, [viewer]);

  /**
   * Initialize the scene
   */
  useEffect(()=>{
    console.log(`scene has changed`);
    if ( scene ) {
      scene.screenSpaceCameraController.enableCollisionDetection = false;
      scene.screenSpaceCameraController.enableTilt = true;
      scene.postProcessStages.fxaa.enabled = true ;
    }
  }, [scene]);

  /**
   * Initialize the globe
   */
  useEffect(()=>{
    console.log(`globe has changed`);
    if ( globe ) {
      globe.translucency.enabled = true;
      globe.translucency.frontFaceAlpha = 0;
      globe.translucency.backFaceAlpha = 1;
      globe.translucency.frontFaceAlphaByDistance = new NearFarScalar(
          400.0,
          0.0,
          800.0,
          1.0
      );
      globe.dynamicAtmosphereLighting = false;
      globe.dynamicAtmosphereLightingFromSun = false;
      globe.enableLighting = true;
    }
  }, [globe]);

  /**
   * Fetches the metadata of an asset
   * @param tilesetResource
   */
  const fetchMetadata = useCallback(async (tilesetResource: ITilesetResource): Promise<ITilesetResource> => {
    console.log(`fetchMetadata: ${tilesetResource.name}`);

    setLoadingText(`${t('Analyzing')}: ${tilesetResource.name}}`)

    const urlResource = getUrlResource( tilesetResource );
    console.log(`fetchMetadata: urlResource=${urlResource.url}`);

    return await fetchWithAuth( /*tilesetResource.resource*/urlResource.url )
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error( `fetchMetadata(${/*tilesetResource.resource*/urlResource.url}): status=${resp.status}` )
          }
          console.log(`fetchMetadata(fetchWithAuth): resp.ok`);
          return resp.json()
        })
        .then( json => {
          if ( ! json.hasOwnProperty("extras") ) {
            throw new Error( `fetchMetadata(${/*tilesetResource.resource*/urlResource.url}): asset JSON file contains no 'extras'` )
          }

          tilesetResource.extras = json["extras"] ;

          if ( json.hasOwnProperty("properties") ) {
            const properties = json["properties"] ;
            if ( properties.hasOwnProperty("ID") ) {
              const idMinMax = properties["ID"];
              if ( idMinMax.hasOwnProperty("maximum") ) {
                const maximum: number = +idMinMax["maximum"];
                tilesetResource.featureCount = maximum + 1 ;
              }
            }
          }
          setLoadingState( tilesetResource.name, 1 );
          return tilesetResource ;
        })
        .catch( err => {
          console.error(err);
          throw err ;
        } );
  }, [fetchWithAuth, setLoadingState]);

  /**
   * Executes async functions on an array of tileset resources, in sequence, and returns the array of updated tilesets
   * @param tilesetResources
   * @param action
   */
  const forEachTileset = useCallback(async (tilesetResources: ITilesetResource[], action: (tilesetResources: ITilesetResource)=>Promise<ITilesetResource>) => {
    const results = [];
    for (const tilesetResource of tilesetResources) {
      results.push( await action( tilesetResource ) ) ;
    }
    return results ;
  }, []);

  /**
   * Given a list of tile set resources, we fetch the 'extras' section for all tilesets and return the updated list
   */
  const getTilesetResourcesWithExtras = useCallback(async (tilesetResources: ITilesetResource[] ): Promise<ITilesetResource[]> => {
    return await forEachTileset(tilesetResources, fetchMetadata) ;
  }, [forEachTileset, fetchMetadata]);

  /**
   * At initial load, let's extract the origin reference, block sizes and set the relative positioning transforms
   */

  const propsTilesetResources = props.tilesetResources;
  const propsOnTilesetResourcesRejected = props.onTilesetResourcesRejected;
  const setRockMassDefinitions = userSessionActions.setRockMassDefinitions;
  const setLoadingData = userSessionActions.setLoadingData ;

  useEffect(()=>console.log(`refreshAuthTokens has changed`), [refreshAuthTokens]);
  useEffect(()=>console.log(`propsTilesetResources has changed`), [propsTilesetResources]);
  useEffect(()=>console.log(`setRockMassDefinitions has changed`), [setRockMassDefinitions]);
  useEffect(()=>console.log(`getTilesetResourcesWithExtras has changed`), [getTilesetResourcesWithExtras]);
  useEffect(()=>console.log(`propsOnTilesetResourcesRejected has changed`), [propsOnTilesetResourcesRejected]);
  useEffect(()=>console.log(`setLoadingData has changed`), [setLoadingData]);

  /**
   * Initial processing
   */
  useEffect(()=>{
    // console.log(`${JSON.stringify(propsTilesetResources)}`);

    if ( propsTilesetResources.length === 0 ) {
      return;
    }

    console.log(`Processing assets`);

    setLoadingData( sceneMode, true ) ;

    loadingStateActions.setAll(
        propsTilesetResources
            .filter( resource => resourceApplicableToScene( resource, sceneMode ) )
            .map(resource=>[resource.name, 0])
    );

    refreshAuthTokens()
        .then( resp => {
          if ( resp.ok ) {
            getTilesetResourcesWithExtras(propsTilesetResources)
                .then((candidates) => {

                  // console.log(`Processing assets: ${candidates.length} candidates to process`);

                  let failures: { tilesetResource: ITilesetResource, reason: string }[] = []
                  let successes: ITilesetResource[] = [];

                  let rockMassDomainCandidates: ITilesetResource[] = [];

                  // ... Process the candidates
                  for (let i = 0; i < candidates.length; ++i) {
                    let candidate = candidates[i];
                    // console.log(`Processing candidate: ${candidate.name}...`);

                    if (!candidate.extras) {
                      failures.push({tilesetResource: candidate, reason: "No 'extras' in metadata"});
                      // console.warn(`Processing assets: ${candidate.name} failed: No 'extras' in metadata`);
                      continue;
                    }

                    // ... Extract origin reference is not yet set
                    if (!originReferenceRef.current) {
                      if (!candidate.extras.hasOwnProperty("Origin")) {
                        failures.push({tilesetResource: candidate, reason: 'No Origin in metadata'})
                        continue;
                      }
                      originReferenceRef.current = Cartesian3.fromArray(candidate.extras["Origin"]);
                    }

                    // ... Extract block size is not yet set
                    if (candidate.layerType === LayerType.BlockModel) {
                      // ... Ensure there is a block size
                      if (!candidate.extras.hasOwnProperty("BlockSize")) {
                        failures.push({tilesetResource: candidate, reason: 'No BlockSize in metadata'})
                        continue;
                      }
                      const blockSize = Cartesian3.fromArray(candidate.extras["BlockSize"]);
                      // console.log(`${candidate.name}: extras.blockSize=${JSON.stringify(candidate.extras["BlockSize"])} => blockSize=${blockSize}`);

                      if (!blockSizeRef.current) {
                        blockSizeRef.current = blockSize;
                      } else if (!blockSizeRef.current.equals(blockSize)) {
                        failures.push({
                          tilesetResource: candidate,
                          reason: `BlockSize mismatch: ref=${blockSizeRef.current}, candidate=${blockSize}`
                        })
                        continue;
                      }
                    }

                    // .. Extract rock mass domain definitions
                    if (candidate.layerType === LayerType.BlastholeClusters) {

                      if (!candidate.extras.CompletionDate) {
                        failures.push({tilesetResource: candidate, reason: 'No completion date in metadata'})
                        continue;
                      }
                      if (!candidate.extras.RockMassDomain) {
                        failures.push({tilesetResource: candidate, reason: 'No rock mass domain in metadata'})
                        continue;
                      }

                      // ... We'll deal with you later
                      rockMassDomainCandidates.push(candidate);
                      continue;
                    }

                    successes.push(candidate);
                  }

                  /**
                   * Validate cluster tilesets (Rock mass domain analysis)
                   */
                  let rockMassDefinitions: Map<string, RockMassDomainRange[]> | undefined;

                  // ... Sort the list in reversing order of timestamp
                  let sortedCandidates = rockMassDomainCandidates.sort((c1, c2) => {
                    return -(new Date(c1.extras.CompletionDate).getTime() - new Date(c2.extras.CompletionDate).getTime());
                  })

                  for (let i = 0; i < sortedCandidates.length; ++i) {
                    let candidate = sortedCandidates[i];

                    let rangeStats = extractRangeStatsFromJson(candidate.extras.RockMassDomain);

                    if (!rockMassDefinitions) {
                      rockMassDefinitions = rangeStats;
                    } else if (!compareRockMassDomains(rockMassDefinitions, rangeStats)) {
                      failures.push({tilesetResource: candidate, reason: 'Rock mass domain in metadata is obsolete'})
                      continue;
                    }
                    successes.push(candidate);
                  }

                  if (rockMassDefinitions) {
                    setRockMassDefinitions(rockMassDefinitions);
                  }

                  /**
                   * Validate Attribute mappings
                   */
                  //TODO


                  // ... Update progress on failed candidates (max out counters so that the progress bar won't be stuck with
                  //     unfinished tasks
                  failures.forEach(failure => loadingStateActions.set(failure.tilesetResource.name, 3));

                  if (propsOnTilesetResourcesRejected) {
                    propsOnTilesetResourcesRejected(failures);
                  }

                  // ... One last refresh to avoid timeing out
                  refreshAuthTokens()
                      .then( resp => {
                        setTilesetResources(successes);
                      });
                })
          }
        })

  }, [
    refreshAuthTokens,
    propsTilesetResources,
    setRockMassDefinitions,
    getTilesetResourcesWithExtras,
    propsOnTilesetResourcesRejected,
    setLoadingData
  ])

  /**
   * Progress bars handling
   */
  const handleLoadingProgress = useCallback((progress:number)=>{
    if ( progress === 100) {
      setLoadingText(t('All data loaded'));
      setLoadingComplete(true);
      setLoadingData( sceneMode, false ) ;

      // ... Restore the camera's last saved vantage point, if there is one
      console.log(`*** camera: ${camera !== undefined}, sceneMode: ${sceneMode}, cameraVantagePoint3D: ${JSON.stringify(userSession.cameraVantagePoint3D)}`);

      if ( camera && sceneMode === SceneMode.SCENE3D && userSession.cameraVantagePoint3D ) {
        console.log(`Restoring camera's last saved vantage point`);
        camera.flyTo({
          destination : userSession.cameraVantagePoint3D.position,
          orientation : {
            direction : userSession.cameraVantagePoint3D.direction,
            up : userSession.cameraVantagePoint3D.up
          }
        });
      }
    }
  }, [setLoadingText, setLoadingComplete, userSession.cameraVantagePoint3D, camera, sceneMode]);

  const progressBarStyle = useMemo((): CSSProperties => {
    if (!loadingComplete) {
      return {};
    }
    return {
      visibility: "hidden",
      opacity: "0",
      transition: "visibility 0s 2s, opacity 2s linear"
    } as CSSProperties;
  }, [loadingComplete])

  /**
   * Each time a new tileset is ready, let's update the bounding sphere
   * @param tileset
   */
  const updateBoundingSphere = userSessionActions.updateBoundingSphere ;
  const setTilesetAttributes = userSessionActions.setTilesetAttributes ;
  const setBenchElevationLimits = userSessionActions.setBenchElevationLimits ;

  const handleTileVisible = useCallback(()=>{
    loadedRef.current = true ;
  }, [])

  const handleReady = useCallback((tileset: Cesium.Cesium3DTileset, resource: ITilesetResource) => {
    setLoadingState( resource.name, 2 );
    setLoadingText(`${t("Loading")}: ${resource.name}` );

    // ... Compute the offset from the reference and apply a root transform
    if ( resource.extras.hasOwnProperty("Origin") && originReferenceRef.current ) {
      const origin: number[] = resource.extras["Origin"];
      const tilesetOrigin = Cartesian3.fromArray(origin) ;
      const offset = Cartesian3.subtract( tilesetOrigin, originReferenceRef.current, new Cartesian3())
      if ( Math.abs(offset.x) > 0.0000001 || Math.abs(offset.y) > 0.0000001 || Math.abs(offset.z) > 0.0000001 ) {
        tileset.root.transform = Matrix4.fromRotationTranslation(NO_ROTATION, offset);
      }
    }

    // ... Update the bench elevation limits
    if ( resource.extras.hasOwnProperty("BenchElevation") && resource.extras.hasOwnProperty("BenchHeight") ) {
      const benchElevation = resource.extras["BenchElevation"];
      const benchHeight = resource.extras["BenchHeight"];
      if ( benchElevation && !Number.isNaN( benchElevation ) && benchHeight && !Number.isNaN( benchHeight ) ) {
        setBenchElevationLimits({min: +benchElevation - (+benchHeight), max: +benchElevation}) ;
      }
    }

    // ... Update the session's bounding sphere
    if ( sceneMode === SceneMode.SCENE3D ) {
      updateBoundingSphere(tileset.boundingSphere);
    }

    // ... Define attributes in the data
    setTilesetAttributes( tileset );

  }, [sceneMode, updateBoundingSphere, setTilesetAttributes, setLoadingText, setLoadingState, setBenchElevationLimits]);

  /**
   * Handles errors in loading a tileset
   */
  const propsHandleTileFailed = props.handleTileFailed;
  const handleTileFailed = useCallback((tileset: CesiumCesium3DTileset, resource: ITilesetResource, err: any) => {

    // ... Invoke parent's callback
    propsHandleTileFailed && propsHandleTileFailed( tileset, resource, err );

    // ... Update the loaded state (to allow the progress bar to clear itself once we're done
    setLoadingState( resource.name, 3 );

    // ... Notify the user\
    createNotification(`${t('Failed to load asset')}: ${resource.name}`, "error");

  }, [propsHandleTileFailed, setLoadingState, createNotification]);

  /**
   * Assuming all tiles are loaded at this point
   * @param resource
   */
  const propsHandleAllTilesLoad = props.handleAllTilesLoad;
  const handleAllTilesLoad = useCallback((tileset: Cesium.Cesium3DTileset, resource: ITilesetResource) => {
    setLoadingText(`${t("Loaded")}: ${resource.name}` );

    // ... Tag all features with the layer type, pattern name and pattern ID
    TilesetUtils.addAttribute( tileset, "Layer", LayerType[ resource.layerType ] );
    TilesetUtils.addAttribute( tileset, "Pattern", resource.patternName )
    TilesetUtils.addAttribute( tileset, "PatternID", resource.patternId )

    /*
    Layer-specific processing
     */
    switch( resource.layerType ) {

      case LayerType.BlockModel:
        if ( !originReferenceRef.current ) {
          throw new Error(`handleAllTilesLoad: expected origin reference to be defined for ${resource.name}`);
        }
        userSessionActions.updateBoundingBox(
            TilesetUtils.prepareBlockModelTileset( tileset, originReferenceRef.current ),
            (updatedBoundingBox)=> {
              if (tileset) {
                userSessionActions.addBlockModel({
                  assetSummary: resource as IAssetSummary,
                  tileset: tileset,
                  boundingBox: updatedBoundingBox
                });
              }
            }
        );
        break ;

      case LayerType.BlastholeSecondarySegments:
        if ( !siteConfig?.modelMatrix ) {
          throw new Error(`handleAllTilesLoad: expected model matrix to be defined for ${resource.name}`);
        }
        TilesetUtils.addAdjustedPositionPropertyFromXYZ(tileset, siteConfig.modelMatrix, "from_z");
        break ;

      case LayerType.BlastholeSingleSegment:
        if ( !siteConfig?.modelMatrix ) {
          throw new Error(`handleAllTilesLoad: expected model matrix to be defined for ${resource.name}`);
        }
        // ... Features count is the hole count
        userSessionActions.setPatternHoleCount(resource.patternId, tileset.root.content.featuresLength);

        // ... Capture drill(s) that operated on this hole
        let drillIds = new Set<string>();

        // ... If the extras contain accurate position info
        if ( tileset.properties.hasOwnProperty("rx") && tileset.properties.hasOwnProperty("rx") && tileset.properties.hasOwnProperty("rx") ) {
          // ... Add adjusted position for entity rendering
          TilesetUtils.addAdjustedPositionProperty(
              tileset,
              siteConfig.modelMatrix,
              "rx", "ry", "rz",
              feature => {
                const drillId = feature.getProperty("DrillID");
                drillId && drillIds.add(drillId);
              });
        } else {
          TilesetUtils.addAdjustedPositionProperty2(
              tileset,
              siteConfig.modelMatrix,
              feature => {
                const prop = feature.getProperty('relpostop') ;
                if ( prop ) { // NEW VERSION (0.7.1 and up)
                  const relPosTop = JSON.parse(prop);
                  return {
                    x: relPosTop[0],
                    y: relPosTop[1],
                    z: relPosTop[2],
                  }
                } else { // OLD VERSION
                  const x = +feature.getProperty('x');
                  const y = +feature.getProperty('y');
                  const z = +feature.getProperty('from_z');
                  return {
                    x: x,
                    y: y,
                    z: z,
                  }
                }
              },
              feature => {
                const drillId = feature.getProperty("DrillID");
                drillId && drillIds.add(drillId);
              });
        } /*else {
          // ... Add adjusted position for entity rendering
          TilesetUtils.addAdjustedPositionPropertyFromXYZ(
              tileset, siteConfig.modelMatrix, "from_z", feature => {
                const drillId = feature.getProperty("DrillID");
                drillId && drillIds.add( drillId );
              } );
        }*/

        // console.log(`COLLARS: patternId=${resource.patternId}, drillIds=${Array.from(drillIds.values())}`);
        userSessionMoreActions.setPatternDrillIds(resource.patternId, Array.from(drillIds.values()));
        break;

      case LayerType.BlastholeClusters:
        userSessionActions.setPatternHoleCount(resource.patternId, tileset.root.content.featuresLength);

        if (!userSession.rockMassDefinitions) {
          throw new Error( `handleAllTilesLoad: expected rock mass definitions to be defined for ${resource.name}` );
        }

        addRockDomainAverages( tileset.root, userSession.rockMassDefinitions ) ;
        // ... Ensure the properties section reflects the true range of each cluster attribute (because the files only
        // show the min/max found in the data, as opposed to the absolute min-max range.
        overrideDomainMinMax( tileset, userSession.rockMassDefinitions ) ;

        break;

      case LayerType.BlastholeFractures:
        if ( !siteConfig?.modelMatrix ) {
          throw new Error(`handleAllTilesLoad: expected model matrix to be defined for ${resource.name}`);
        }
        TilesetUtils.addAdjustedPositionPropertyFromXYZ(tileset, siteConfig.modelMatrix, "z")
        break ;

      case LayerType.CrossSectionX:
      case LayerType.CrossSectionY:
      case LayerType.CrossSectionZ:
        if ( !originReferenceRef.current ) {
          throw new Error(`handleAllTilesLoad: expected origin reference to be defined for ${resource.name}`);
        }
        TilesetUtils.prepareBlockModelTileset( tileset, originReferenceRef.current );
        break;

      case LayerType.Boundary:
        break ;
    }

    // ... Invoke parent's callback
    propsHandleAllTilesLoad && propsHandleAllTilesLoad( tileset, resource );

    // ... Update the loaded state
    setLoadingState( resource.name, 3 );

    // ... Notify the user
    createNotification(`${t('Asset loaded')}: ${resource.name}`, "info");

  }, [siteConfig?.modelMatrix, userSession.rockMassDefinitions, propsHandleAllTilesLoad, userSessionActions, setLoadingText, setLoadingState, createNotification]);

  /**
   * Each time the  bounding sphere is updated, let's refocus the camera
   */
  useEffect(()=>{
    // console.log('boundingSphere has changed');

    if (camera && userSession.boundingSphere) {
      camera.flyToBoundingSphere( userSession.boundingSphere, { duration: 0.5} );
    }
  }, [userSession.boundingSphere, camera]);

  /**
   * Camera frustum changes
   */
  useEffect(()=>{
    if ( viewer?.camera ) {
      switch (userSession.cameraFrustum) {
        case CameraFrustum.PERSPECTIVE:
          viewer.camera.switchToPerspectiveFrustum();
          break;
        case CameraFrustum.ORTHOGRAPHIC:
          viewer.camera.switchToOrthographicFrustum();
          break;
      }
    }
  }, [userSession.cameraFrustum, viewer?.camera]);

  /**
   * We use the camera changed event to adjust the 2D scale
   */
  const [scalePixelPoints, setScalePixelPoints] = useState<[Cartesian3, Cartesian3]>();
  const handleCameraChanged = useCallback((percent: number) => {
    // console.log(`>>> handleCameraChanged: ${percent}`);
    if ( sceneMode === SceneMode.SCENE2D && scene ) {
      setScalePixelPoints( getPixelPoints( scene ) );
    }
  }, [scene, sceneMode, setScalePixelPoints]);

  /** When positioning the camera, it is non-trivial to determine the best zoom/rang to fit the model perfectly.  THis
   * method adjusts a padding factor used to tweak the range
   */
  const paddingFactor = useMemo(()=>{
    if (!viewer) return 1;
    var aspectRatio = viewer.canvas.clientWidth / viewer.canvas.clientHeight;
    var paddingFactor = 1.65;
    if (aspectRatio > 1) {
      paddingFactor += (aspectRatio - 1) / 2;
    }
    return paddingFactor;
  }, [viewer]);

  /**
   * Handle requests to change tha camera vantage point
   */
  useEffect(()=>{
    if (camera && props.cameraVantagePoint) {
      if ( props.sceneMode === SceneMode.SCENE3D ) {
        // ... Recompute the HPR with a best fit range
        const hpr = new HeadingPitchRange(
            props.cameraVantagePoint.hpr.heading,
            props.cameraVantagePoint.hpr.pitch,
            props.cameraVantagePoint.boundingSphere.radius * paddingFactor
        );
        camera.viewBoundingSphere(props.cameraVantagePoint.boundingSphere, hpr);
      } else{
        const hpr = new HeadingPitchRange( 0, Math.PI/2, props.cameraVantagePoint.boundingSphere.radius * paddingFactor );
        camera.viewBoundingSphere(props.cameraVantagePoint.boundingSphere, hpr);
        // camera.flyToBoundingSphere(props.cameraVantagePoint.boundingSphere, { duration: 0.5 })
      }
      camera.lookAtTransform(Matrix4.IDENTITY);
    }
  }, [props.cameraVantagePoint, camera, props.sceneMode, paddingFactor]);

  /**
   * Shorthand for getting a given pattern's visibility state
   */
  const getPatternVisibility = useCallback((patternId:string): boolean => {
    return userSession.patternVisibilities.find( pv => pv.patternId === patternId )?.visibility ?? true ;
  }, [userSession.patternVisibilities]);

  /**
   * Given the first detected item form a mouse event, this method will drill down to pick only items of interest.
   *
   * In other words, if, say the mouse hits a boundary entity, but there are higher importance items n=behind it, this
   * method will return the high-importance item.
   * @param movement
   * @param target
   */
  const propsOnItemSelected = props.onItemSelected;

  const pickHighlightableTarget = useCallback((position: Cartesian2, target: any): [Cesium3DTileFeature|CesiumEntity, LayerType|undefined] => {

    // ... If we can pick a pickable item, the return it
    let [ pickedTarget, layerType ] = getTargetAndLayerType( target ) ;
    if ( layerType && !mustLookBehind(pickedTarget, layerType) ) {
      return [pickedTarget, layerType];
    }
    const origLayerType = layerType ;

    // ... If we can pick a pickable item behind our current target, then return that
    const pickedObjects = position ? scene?.drillPick( position ) : undefined ;
    if (!pickedObjects) {
      return [pickedTarget, layerType];
    }

    for ( let i = 1; i < pickedObjects.length; ++i ) {
      [ pickedTarget, layerType ] = getTargetAndLayerType( pickedObjects[i] ) ;
      if ( layerType && !mustLookBehind(pickedTarget, layerType) ) {
        return [pickedTarget, layerType];
      }
    }

    // ... If all else fails, return the originally picked item
    return [target, origLayerType];
  }, [scene]);

  /**
   * Notify higher-level component
   */
  useEffect(()=>{
    if (selectedTarget) {
      switch( getTargetLayer(selectedTarget) ) {
        case LayerType.CrossSectionX:
          userSessionActions.setSelectedCrossSection(LayerType.CrossSectionX);
          break ;
        case LayerType.CrossSectionY:
          userSessionActions.setSelectedCrossSection(LayerType.CrossSectionY);
          break ;
        case LayerType.CrossSectionZ:
          userSessionActions.setSelectedCrossSection(LayerType.CrossSectionZ);
          break ;
      }
    }

    propsOnItemSelected && propsOnItemSelected(selectedTarget);

  }, [selectedTarget, userSessionActions, propsOnItemSelected]);

  /**
   * Any cross-section movement invalidates the selected target
   */
  useEffect(()=>{
    setSelectedTarget(undefined);
  }, [ userSession.crossSectionOffsetX,userSession.crossSectionOffsetY, userSession.crossSectionOffsetZ ])

  /**
   * If the selected target is on a hidden cross-section, invalidate the selected target
   */
  useEffect(()=>{
    switch( getTargetLayer( selectedTarget ) ) {
      case LayerType.CrossSectionX: if (!userSession.layersVisibility.CrossSectionX) setSelectedTarget(undefined); break;
      case LayerType.CrossSectionY: if (!userSession.layersVisibility.CrossSectionY) setSelectedTarget(undefined); break;
      case LayerType.CrossSectionZ: if (!userSession.layersVisibility.CrossSectionZ) setSelectedTarget(undefined); break;
    }
  }, [ selectedTarget, userSession.layersVisibility.CrossSectionX, userSession.layersVisibility.CrossSectionY, userSession.layersVisibility.CrossSectionZ ]);

  /**
   * If the selected cross-section changes, any selected target on it becomes invalidated
   */
  useEffect(()=>{
    const layerType = getTargetLayer( selectedTarget ) ;

    switch( layerType ) {
      case LayerType.CrossSectionX:
      case LayerType.CrossSectionY:
      case LayerType.CrossSectionZ:
        if ( layerType !== userSession.selectedCrossSection ) {
          setSelectedTarget(undefined)
        }
        break;
    }
  }, [ selectedTarget, userSession.selectedCrossSection ]);

  /**
   * Monitors items under the mouse pointer that should be selected when clicked, for info-box display
   */
  const monitorItemsOfInterestUnderMouse = useCallback((position: Cartesian2|undefined, target: any, onItemOfInterest?: (item: Cesium3DTileFeature|CesiumEntity|undefined)=>void)=>{

    if ( !target || !position ) {
      if (onItemOfInterest) {
        onItemOfInterest(undefined)
      } else {
        highlightTargetRef.current = undefined;
      }
      return;
    }

    const [highlightableTarget, layerType] = pickHighlightableTarget(position, target);
    if ( !layerType ) {
      if (onItemOfInterest) {
        onItemOfInterest(undefined)
      } else {
        highlightTargetRef.current = undefined;
      }
      return;
    }

    if (onItemOfInterest) {
      onItemOfInterest(highlightableTarget)
    } else {
      highlightTargetRef.current = highlightableTarget;
    }

  }, [pickHighlightableTarget]);

  /**
   * On left click, show info box or clear selection
   */
  const handleLeftClick = useCallback((movement: CesiumMovementEvent, target: any) => {

    // /// DEBUG
    // function debugShowLatLon(position: Cartesian2) {
    //   if (globeRef?.current?.cesiumElement) {
    //     let ellipsoid = globeRef.current.cesiumElement.ellipsoid;
    //     if (cameraRef?.current?.cesiumElement) {
    //       let cartesian = cameraRef.current.cesiumElement.pickEllipsoid(position, ellipsoid);
    //       if ( cartesian ) {
    //         var cartographic = ellipsoid.cartesianToCartographic(cartesian);
    //         var longitudeString = CesiumMath.toDegrees(cartographic.longitude).toFixed(6);
    //         var latitudeString = CesiumMath.toDegrees(cartographic.latitude).toFixed(6);
    //         console.log(`(${longitudeString}, ${latitudeString}) => ${cartographic}`);
    //       }
    //     }
    //   }
    // }
    // movement?.position && debugShowLatLon( movement.position ) ;
    // /// DEBUG END

    // console.log(`handleLeftClick: ${JSON.stringify(movement)}`);
    monitorItemsOfInterestUnderMouse( movement.position, target );
    setSelectedTarget(highlightTargetRef.current ? highlightTargetRef.current : undefined );
  }, [monitorItemsOfInterestUnderMouse]);

  /**
   * On right click, show info box or clear selection
   */
  const handleRightClick = useCallback((movement: CesiumMovementEvent, target: any) => {

    // console.log(`handleRightClick: ${JSON.stringify(movement)}`);
    monitorItemsOfInterestUnderMouse( movement.position, target );
    setSelectedTarget(highlightTargetRef.current ? highlightTargetRef.current : undefined );

  }, [monitorItemsOfInterestUnderMouse]);

  /**
   * On dbl-click, show info box or clear selection
   */
  const handleDoubleClick = useCallback((movement: CesiumMovementEvent, target: any) => {

    // console.log(`handleDoubleClick: ${JSON.stringify(movement)}`);
    monitorItemsOfInterestUnderMouse( movement.position, target );
    setSelectedTarget(highlightTargetRef.current ? highlightTargetRef.current : undefined );
  }, [monitorItemsOfInterestUnderMouse]);

  /**
   * Debug/test: post processing silhouette
   */
  // const selectedFeature = useRef<{item:Cesium3DTileFeature|CesiumEntity, origColor?:Color|undefined, alreadyTinted?:boolean}|undefined>();

  /**
   * Capture center of rotation so that we can show a marker in the 3D space
   */
  useEffect(()=>{
    if ( camera && canvas ) {
      const handler = new ScreenSpaceEventHandler(canvas) ;
      let cam = camera ;

      // ... On CTRL-LEFT_DOWN
      handler.setInputAction(function (click) {
        let selectedPoint = cam.pickEllipsoid( click.position );
        setCenterOfRotationPosition( selectedPoint ) ;
      }, ScreenSpaceEventType.LEFT_DOWN, KeyboardEventModifier.CTRL );

      // ... On CTRL-LEFT_UP
      handler.setInputAction(function (click) {
        setCenterOfRotationPosition( undefined ) ;
      }, ScreenSpaceEventType.LEFT_UP, KeyboardEventModifier.CTRL );

      // ... Or on LEFT_UP
      handler.setInputAction(function (click) {
        setCenterOfRotationPosition( undefined ) ;

      }, ScreenSpaceEventType.LEFT_UP );

      return () => {
        handler?.removeInputAction(ScreenSpaceEventType.LEFT_DOWN, KeyboardEventModifier.CTRL);
        handler?.removeInputAction(ScreenSpaceEventType.LEFT_UP, KeyboardEventModifier.CTRL);
        handler?.removeInputAction(ScreenSpaceEventType.LEFT_UP);
      }
    }
  }, [camera, canvas]);

  const [cursor, setCursor] = useState('default')


  /**
   * Management of a linear selection corridor
   */
  // const [selectionCorridor, setSelectionCorridor] = useState<{start: { top: Cartesian3, bottom: Cartesian3 }, end: Cartesian3, width: number}|undefined>();
  const [selectionCorridor, setSelectionCorridor] = useState<Corridor|undefined>();

  const checkIfInsideSelection = useCallback((pos: Cartesian3): boolean|undefined => {
    if (selectionCorridor && linearSelectionMode) {
      // const dist = distancePointToSegment3D( selectionCorridor.start, selectionCorridor.end, pos ) ;
      // const dist = distancePointToPlane( selectionCorridor.start.top, selectionCorridor.start.bottom, selectionCorridor.end, pos ) ;
      // return dist < selectionCorridor.width / 2;

      return selectionCorridor?.contains( pos );

    } else {
      return undefined ;
    }
  }, [selectionCorridor, linearSelectionMode]);

  useEffect(()=>{
    if ( linearSelectionMode ) {
      setCursor('crosshair');
    } else {
      setSelectionCorridor( undefined ) ;
    }
  }, [linearSelectionMode]);

  /*
  The lighting source will be like a flashlight
   */
  const fixedLighting = useMemo(()=>{
    if ( !scene ) {
      return undefined;
    }
    return new DirectionalLight( {
      direction: Cartesian3.clone( scene.camera.directionWC, new Cartesian3() ),
      intensity: 5
    } )
  }, [scene]);

  const preRenderScene = useCallback(()=> {
    // ... Adjust light direction to coincide with that of the camera (see notes above)
    if ( scene?.light && scene.light instanceof DirectionalLight && scene.camera ) {
      scene.light.direction = Cartesian3.clone( scene.camera.directionWC, scene.light.direction );
    }
  }, [scene]);

  /**
   * Component design
   */
  return (
      <div
          className={`${props.className}`}
          style={{...props.style, position: "relative", cursor: cursor}}
      >
        <Viewer
            // full
            style={VIEWER_STYLE}
            ref={viewerRef}
            homeButton={false}
            baseLayerPicker={false}
            navigationHelpButton={false}
            navigationInstructionsInitiallyVisible={false}
            geocoder={false}
            shadows={false}
            fullscreenButton={false}
            sceneMode={props.sceneMode}
            sceneModePicker={false}
            clockViewModel={undefined}
            animation={false}
            timeline={false}
            scene3DOnly={false}
            infoBox={false}
            selectionIndicator={false}
            onClick={handleLeftClick}
            onRightClick={handleRightClick}
            onDoubleClick={handleDoubleClick}
        >
          <Scene
              ref={sceneRef} onPreRender={preRenderScene}
              light={fixedLighting}
          />
          <Camera ref={cameraRef} onChange={handleCameraChanged} percentageChanged={0.05}/>
          <Globe ref={globeRef} depthTestAgainstTerrain={true}/>

          {/* ALL ASSETS RENDERED HERE */}
          { siteConfig?.modelMatrix &&
            tilesetResources.map( tilesetResource => (
                <TilesetResource
                    key={`TextView3D_${tilesetResource.identifier}`}
                    sceneMode={props.sceneMode}
                    showPattern={getPatternVisibility(tilesetResource.patternId)}
                    showLayer={userSession.layersVisibility[LayerType[tilesetResource.layerType] as keyof ILayerTypes<boolean>]}
                    selectedAttribute={userSession.selectedAttribute}
                    tilesetResource={tilesetResource}
                    modelMatrix={siteConfig.modelMatrix}
                    onReady={handleReady}
                    onAllTilesLoad={handleAllTilesLoad}
                    onLeftClick={handleLeftClick}
                    onDoubleClick={handleDoubleClick}
                    onVisible={handleTileVisible}
                    onTileFailed={handleTileFailed}
                    selectionPredicate={linearSelectionMode ? checkIfInsideSelection : undefined }
                    selectedTarget={selectedTarget}
                />
            ))
          }

          {/* AXES */}
          {props.sceneMode === SceneMode.SCENE3D && originReferenceRef.current && userSession.axesVisibility &&
              <AxesXYZ
                  show={userSession.axesVisibility}
                  originReference={originReferenceRef.current}
                  size={Cartesian3.fromArray([50, 50, 50])}
                  color={Color.fromCssColorString("yellow")}
              />
          }

          {/* CROSS-SECTION FRAMES */}
          {props.sceneMode === SceneMode.SCENE3D && blockSizeRef.current &&
              <CrossSectionFrames
                  blockSize={blockSizeRef.current}
                  onLeftClick={handleLeftClick}
                  onRightClick={handleRightClick}
                  onDoubleClick={handleDoubleClick}
              />
          }

          {/* BOUNDING BOX */}
          {props.sceneMode === SceneMode.SCENE3D &&
              <BoundingBox/>
          }

          {/* LINEAR SELECTION */}
          {AppConstants.OptionalFeatures.corridorSelectionFeature && props.sceneMode === SceneMode.SCENE3D && sceneRef.current?.cesiumElement && linearSelectionMode &&
              <LinearSelection
                  scene={sceneRef.current.cesiumElement}
                  corridorWidth={DEFAULT_CORRIDOR_WIDTH_METERS}
                  onSelectionStarted={()=>setCursor('all-scroll')}
                  onSelectionFinished={()=>setCursor('default')}
                  onSelectionChanged={(startPos, endPos) => {
                    const corridor = new Corridor( startPos.top, startPos.bottom, endPos, DEFAULT_CORRIDOR_WIDTH_METERS);
                    setSelectionCorridor(corridor);
                    // setSelectionCorridor({start: startPos, end: endPos, width: DEFAULT_CORRIDOR_WIDTH_METERS});
                  }}
              />
          }

          {/* TOP OF BENCH PLANE */}
          {props.sceneMode === SceneMode.SCENE3D &&
              originReferenceRef.current &&
              userSession.boundingBox &&
              <TopOfBenchPlane
                  originReference={originReferenceRef.current}
                  visibility={userSession.topPlaneVisibility}
                  boundingBox={userSession.boundingBox}
              />
          }

          {/* HIGHLIGHTED POSITION */}
          { siteConfig?.modelMatrix && originReferenceRef.current && blockSizeRef.current && selectedTarget && !linearSelectionMode && (
              <ItemHighlight
                  item={selectedTarget}
                  color={userSession.selectedCrossSectionStyle.outlineColor}
                  modelMatrix={siteConfig.modelMatrix}
                  originReference={originReferenceRef.current}
                  blockSize={blockSizeRef.current}
              />
          )}

          {/* 2D MAP ONLY: SCALE */}
          { globeRef.current?.cesiumElement && props.sceneMode === SceneMode.SCENE2D &&
            <Scale2D
                ellipsoid={globeRef.current.cesiumElement.ellipsoid}
                className={"w3-round w3-padding-small w3-margin-right"}
                style={{
                  position: "absolute",
                  top: "16px",
                  right: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  pointerEvents: "none",
                  userSelect: "none",
                  backgroundColor: "#00000088"
                }}
                scalePixelPoints={scalePixelPoints}
            />
          }

          {/* CENTER OF ROTATION MARKER */}
          { sceneMode === SceneMode.SCENE3D && userSession.showCenterOfRotationEntity && centerOfRotationPosition && (
              <CenterOfRotationEntity
                  show={true}
                  position={centerOfRotationPosition}
                  color={Color.fromCssColorString("#87CEEB")}
              />
          )}

          {/* CHILDREN FROM HIGHER-LEVEL COMPONENTS*/}
          {props.children}

          {/* LOADING PROGRESS BAR */}
          <ProgressBar
              style={{
                ...progressBarStyle,
                position: "absolute",
                top: "calc( 50% - 25px )",
                left: "25%",
                right: "25%",
              }}
              text={loadingText}
              tasks={loadingState} // map of ["task description", value], each task having a value between [0-taskMax]
              taskMax={3} // max value of each task's value field
              onProgressChanged={handleLoadingProgress}
          />
        </Viewer>

        {/*-------------------------------------------- DEBUG MODE ONLY --------------------------------------------*/}
        { props.debugMode &&
            <div
                className={'w3-bar w3-text-white'}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  width: "auto",
                  left: 0,
                  top: 0,
                  zIndex: "2"
                }}
            >
              <CameraFrustumToggleButton
                  className={'w3-bar-item w3-white w3-opacity w3-hover-opacity-off w3-border w3-margin-right'}
                  cameraFrustum={userSession.cameraFrustum}
                  onCameraFrustumChanged={userSessionActions.setCameraFrustum}
              />
              <PillButton className={'w3-bar-item w3-margin-right'}
                          text={LayerType[LayerType.Boundary]}
                          onStateChanged={ visibility => userSessionActions.setLayerVisibility(LayerType.Boundary, visibility) }
                          state={userSession.layersVisibility[LayerType[LayerType.Boundary] as keyof ILayerTypes<boolean>]}
              />
              <PillButton className={'w3-bar-item w3-margin-right'}
                          text={LayerType[LayerType.BlockModel]}
                          onStateChanged={ visibility => userSessionActions.setLayerVisibility(LayerType.BlockModel, visibility) }
                          state={userSession.layersVisibility[LayerType[LayerType.BlockModel] as keyof ILayerTypes<boolean>]}
              />
              <PillButton className={'w3-bar-item w3-margin-right'}
                          text={'Blast holes'}
                          onStateChanged={ visibility => userSessionActions.setLayerVisibility(LayerType.BlastholeSecondarySegments, visibility) }
                          state={userSession.layersVisibility[LayerType[LayerType.BlastholeSecondarySegments] as keyof ILayerTypes<boolean>]}
              />
              <PillButton className={'w3-bar-item w3-margin-right'}
                          text={'Collars'}
                          onStateChanged={ visibility => userSessionActions.setLayerVisibility(LayerType.BlastholeSingleSegment, visibility) }
                          state={userSession.layersVisibility[LayerType[LayerType.BlastholeSingleSegment] as keyof ILayerTypes<boolean>]}
              />
              <PillButton className={'w3-bar-item w3-margin-right'}
                          text={'Clusters'}
                          onStateChanged={ visibility => userSessionActions.setLayerVisibility(LayerType.BlastholeClusters, visibility) }
                          state={userSession.layersVisibility[LayerType[LayerType.BlastholeClusters] as keyof ILayerTypes<boolean>]}
              />
              <PillButton className={'w3-bar-item w3-margin-right'}
                          text={'Fractures'}
                          onStateChanged={ visibility => userSessionActions.setLayerVisibility(LayerType.BlastholeFractures, visibility) }
                          state={userSession.layersVisibility[LayerType[LayerType.BlastholeFractures] as keyof ILayerTypes<boolean>]}
              />
              <PillButton className={'w3-bar-item w3-margin-right'}
                          text={'CS-X'}
                          onStateChanged={ visibility => userSessionActions.setLayerVisibility(LayerType.CrossSectionX, visibility) }
                          state={userSession.layersVisibility[LayerType[LayerType.CrossSectionX] as keyof ILayerTypes<boolean>]}
              />
              <PillButton className={'w3-bar-item w3-margin-right'}
                          text={'CS-Y'}
                          onStateChanged={ visibility => userSessionActions.setLayerVisibility(LayerType.CrossSectionY, visibility) }
                          state={userSession.layersVisibility[LayerType[LayerType.CrossSectionY] as keyof ILayerTypes<boolean>]}
              />
              <PillButton className={'w3-bar-item w3-margin-right'}
                          text={'CS-Z'}
                          onStateChanged={ visibility => userSessionActions.setLayerVisibility(LayerType.CrossSectionZ, visibility) }
                          state={userSession.layersVisibility[LayerType[LayerType.CrossSectionZ] as keyof ILayerTypes<boolean>]}
              />
            </div>
        }
        {/*------------------------------------------ END DEBUG MODE ONLY ------------------------------------------*/}
      </div>
  )
}

export default React.memo(GenericAssetView);

interface ICameraFrustumToggleButtonProps {
  className?:string|undefined;
  style?:CSSProperties|undefined;
  iconSize?: number;
  cameraFrustum: CameraFrustum,
  onCameraFrustumChanged: (cameraFrustum: CameraFrustum)=>void;
}

const DEFAULT_ICON_SIZE = 32 ;

function toggleCameraFrustum( frustum: CameraFrustum ): CameraFrustum {
  let result ;
  switch( frustum ) {
    case CameraFrustum.PERSPECTIVE:
      result =CameraFrustum.ORTHOGRAPHIC;
      break;
    case CameraFrustum.ORTHOGRAPHIC:
      result =CameraFrustum.PERSPECTIVE;
      break;
    default:
      throw new Error(`toggleCameraFrustum: unexpected value: ${CameraFrustum[frustum]}`) ;
  }
  return result ;
}

const CameraFrustumToggleButton : React.FC<ICameraFrustumToggleButtonProps> = (props) => {

  const iconSize = useMemo(()=> props.iconSize ?? DEFAULT_ICON_SIZE, [props.iconSize]);

  return (
      <div
          className={props.className}
          style={{...props.style, aspectRatio: "1"}}
          onClick={()=>props.onCameraFrustumChanged(toggleCameraFrustum(props.cameraFrustum))}
      >
        { props.cameraFrustum === CameraFrustum.PERSPECTIVE && (
            <img
                className={`svg-white`}
                style={{userSelect: "none"}}
                src={perspectiveIconBlack}
                alt={CameraFrustum[CameraFrustum.PERSPECTIVE]}
                width={iconSize}
                height={iconSize}
            />
        )}
        { props.cameraFrustum === CameraFrustum.ORTHOGRAPHIC && (
            <img
                style={{userSelect: "none"}}
                className={`svg-white`}
                src={orthographicIconBlack}
                alt={CameraFrustum[CameraFrustum.ORTHOGRAPHIC]}
                width={iconSize}
                height={iconSize}
            />
        )}
      </div>
  )
}

interface IPillButtonProps  {
  className?:string|undefined;
  style?:CSSProperties|undefined;
  text: string;
  state: boolean;
  onStateChanged: (state: boolean)=>void;
}

const PillButton: React.FC<IPillButtonProps> = (props) => {
  const [state, setState] = useState<boolean>(props.state);
  const [pressed, setPressed] = useState<boolean>();

  const className = useMemo(()=>{

    let coloPart = state ? "w3-light-blue w3-border-light-blue w3-text-black" : "w3-black w3-border-white";

    let opacityPart = pressed ? "w3-opacity" : "w3-hover-opacity-off w3-opacity-min";

    return `w3-border ${coloPart} ${opacityPart}`;
  }, [pressed, state]);

  useEffect(()=>{
    if ( pressed ) {
      setState( prevState => !prevState );
    }
  }, [pressed]);

  const propsOnStateChanged = props.onStateChanged;
  useEffect(()=>{
    propsOnStateChanged( state );
  }, [state, propsOnStateChanged]);

  return (
      <div
          className={`unselectable w3-tag w3-round-large ${className} w3-text-white ${props.className}`}
          style={{...props.style, cursor: "pointer", userSelect: "none"}}
          onMouseDown={()=>setPressed(true)}
          onMouseUp={()=>setPressed(false)}
          onMouseLeave={()=>setPressed(false)}
          onMouseOut={()=>setPressed(false)}
      >
        {props.text}
      </div>
  )
}

/**
 * Returns a human-readable string describing a target (item on screen)
 * @param target
 */
export function getTargetId( target: any ) {
  if ( !target ) return "undefined";

  if ( target instanceof Cesium3DTileFeature ) {
    const feature = target as Cesium3DTileFeature;
    const layerName = feature.getProperty("Layer");
    return `feature ${feature.featureId} layer=${layerName}`;
  }
  else if (target instanceof CesiumEntity ) {
    const entity = target as CesiumEntity;
    const layerName = entity.properties ? entity.properties["Layer"] : undefined;
    return `entity name=${entity.name} layer=${layerName}`;
  }
  else if (target.id instanceof CesiumEntity ) {
    const entity = target.id as CesiumEntity;
    const layerName = entity.properties ? entity.properties["Layer"] : undefined;
    return `entity.id name=${entity.name} layer=${layerName}`;
  }
  else if (target.primitive.id instanceof CesiumEntity ) {
    const entity = target.primitive.id as CesiumEntity;
    const layerName = entity.properties ? entity.properties["Layer"] : undefined;
    return `entity.primitive.id name=${entity.name} layer=${layerName}`;
  }
  else {
    return `something truly mysterious`;
  }
}

/**
 * Determine the layer type of a picked item, if any
 * @param target
 */
export function getTargetLayer( target: any ): LayerType|undefined {
  let layerName: LayerType|undefined ;

  if ( !target ) {
    return undefined
  }

  if ( target instanceof Cesium3DTileFeature ) {
    const feature = target as Cesium3DTileFeature;
    layerName = feature.getProperty("Layer") ;
  }
  else if (target instanceof CesiumEntity ) {
    const entity = target as CesiumEntity;
    layerName = entity.properties ? entity.properties["Layer"] : undefined;
  }
  else if (target.id instanceof CesiumEntity ) {
    const entity = target.id as CesiumEntity;
    layerName = entity.properties ? entity.properties["Layer"] : undefined;
  }
  else if (target.primitive.id instanceof CesiumEntity ) {
    const entity = target.primitive.id as CesiumEntity;
    layerName = entity.properties ? entity.properties["Layer"] : undefined;
  }

  return layerName ? LayerType[ layerName as unknown as keyof typeof LayerType ] : undefined ;
}

/**
 * Given a target, return a "pickable" target, m eaning one that we might want some additional info on.
 * @param target
 */
function getTargetAndLayerType(target: any): [Cesium3DTileFeature|CesiumEntity, LayerType|undefined] {
  let layerType: LayerType|undefined;

  if ( !target ) {
    throw new Error('getTargetAndLayerType: expected target to be defined');
  }

  if ( target instanceof Cesium3DTileFeature ) {
    const feature = target as Cesium3DTileFeature;
    const layerName = feature.getProperty("Layer") ;
    layerType = layerName ? LayerType[ layerName as unknown as keyof typeof LayerType ] : undefined ;
    return [ target, layerType ];
  }
  else {
    let entity: CesiumEntity ;
    if (target instanceof CesiumEntity ) {
      entity = target as CesiumEntity;
    }
    else if (target.id instanceof CesiumEntity ) {
      entity = target.id as CesiumEntity;
    }
    else if (target.primitive.id instanceof CesiumEntity ) {
      entity = target.primitive.id as CesiumEntity;
    }
    else {
      return [ target, undefined ];
    }
    const layerName = entity.properties ? entity.properties["Layer"] : undefined;
    layerType = layerName ? LayerType[ layerName as unknown as keyof typeof LayerType ] : undefined ;
    return [ entity, layerType ];
  }
}

/**
 * Returns true if the given layer type represents an item that we do not want, and therefore need to look behind.
 * @param target
 * @param layerType
 */
function mustLookBehind( target: any, layerType: LayerType ): boolean {
  switch( layerType ) {
    case LayerType.CrossSectionY:
    case LayerType.CrossSectionZ:
    case LayerType.CrossSectionX:
      return !(target instanceof Cesium3DTileFeature);
    case LayerType.Boundary:
      return true ;
    default:
      return false ;
  }
}

/**
 * Ensures the min/max ranges are defined correctly in the 'properties' section of the tileset's metadata
 * @param tileset
 * @param ranges
 */
function overrideDomainMinMax(tileset: CesiumCesium3DTileset, ranges: Map<string,RockMassDomainRange[]> ) {
  let propertiesSection : any = tileset.properties
      ? tileset.properties
      : undefined ;

  if ( !propertiesSection ) {
    throw new Error("extractClustersMinMax: no properties section");
  }

  for ( let propName in propertiesSection ) {
    let rangeStats = ranges.get( propName ) ;
    if ( rangeStats ) {
      if ( propName.indexOf("_Domain") !== -1 ) {
        let max = propertiesSection[propName].maximum;
        if ( max < rangeStats.length - 1 ) {
          propertiesSection[propName].maximum = rangeStats.length - 1 ;
        }
      }
    }
  }
}

/**
 * For each feature, dereference its rock domain index and use the rock domain's average [(min+max)/2] to define
 * the related attribute's value.  For example, if avg(CBI_Domain) == 10, then set a feature's CBI value to 10
 */
function addRockDomainAverages(tile: Cesium3DTile, rangeStats: Map<string, RockMassDomainRange[]>): void {
  if (tile && tile.content) {
    // ... For each feature
    for (let i = 0; i < tile.content.featuresLength; ++i) {
      let feature = tile.content.getFeature(i);

      // ... For each cluster type
      rangeStats.forEach( (ranges, key) => {
        // console.log(`>>> Cluster type: ${key}`);
        // ... Dereference the cluster index
        let rockMassDomainName = key;
        let rockMassDomainIndex = feature.getProperty( rockMassDomainName );
        if ( rockMassDomainIndex < ranges.length ) {
          let rockMassDomainRange = ranges[rockMassDomainIndex];
          let relatedAttributeName = rockMassDomainName.substring( 0, rockMassDomainName.indexOf("_Domain"));
          // ... Assign the related attribute value as the cluster's avg
          let avg = (rockMassDomainRange.min+rockMassDomainRange.max)/2 ;
          feature.setProperty( relatedAttributeName, avg );
          // console.log(`"${relatedAttributeName}" = "${avg}"`) ;
        }
      }) ;
    }
  } else {
    console.log("*** addRockDomainAverages: Error! no tile");
  }
}

/**
 * Extracts rock mass domain definitions from JSON
 * @param rockMassDomainSection
 */
export function extractRangeStatsFromJson( rockMassDomainSection: any ): Map<string,RockMassDomainRange[]> {
  let rangeStats: Map<string,RockMassDomainRange[]> = new Map<string, RockMassDomainRange[]>() ;
  if ( rockMassDomainSection ) {
    for ( let key in rockMassDomainSection ) {
      let rangeStatsArray: RockMassDomainRange[] = [];//rangeStats.get(key);
      let ranges: RockMassDomainRange[] | undefined = rockMassDomainSection[key]?.Domains;
      if (ranges) {
        for (let idx in ranges) {
          let range = ranges[idx];
          if (range) {
            // console.log(`+++ rangeStats: ${JSON.stringify(range)}`)
            rangeStatsArray.push(range as RockMassDomainRange);
          }
        }
        // console.log(`+++ key:${key}, rangeStatsArray: ${JSON.stringify(rangeStatsArray)}`)
      }
      rangeStats.set( key, rangeStatsArray);
    }
  }
  return rangeStats ;
}

function resourceApplicableToScene( resource: ITilesetResource, sceneMode: SceneMode ): boolean {
  switch ( resource.layerType ) {
    case LayerType.Undefined:
      return false ;
    case LayerType.Boundary:
      return true ;
    case LayerType.BlockModel:
      return sceneMode === SceneMode.SCENE3D ;
    case LayerType.BlastholeSecondarySegments:
      return sceneMode === SceneMode.SCENE3D ;
    case LayerType.BlastholeSingleSegment:
      return sceneMode === SceneMode.SCENE3D ;
    case LayerType.DrilledHolesContour:
      return false ;
    case LayerType.BlockVolume:
      return false ;
    case LayerType.DrilledHoleCollars:
      return false ;
    case LayerType.BlastholeClusters:
      return sceneMode === SceneMode.SCENE2D ;
    case LayerType.BlastholeFractures:
      return sceneMode === SceneMode.SCENE3D ;
    case LayerType.CrossSectionX:
      return sceneMode === SceneMode.SCENE3D ;
    case LayerType.CrossSectionY:
      return sceneMode === SceneMode.SCENE3D ;
    case LayerType.CrossSectionZ:
      return sceneMode === SceneMode.SCENE3D ;

  }
}

/**
 * Compute the distance between an arbitrary point and a segment
 * @param segmentStart
 * @param segmentEnd
 * @param point
 */
function distancePointToSegment3D(segmentStart: Cartesian3, segmentEnd: Cartesian3, point: Cartesian3): number {
  // console.log(`distancePointToSegment3D(${segmentStart}, ${segmentEnd}, ${point})`);
  var dx = segmentEnd.x - segmentStart.x;
  var dy = segmentEnd.y - segmentStart.y;
  var dz = segmentEnd.z - segmentStart.z;
  var dAB = dx * dx + dy * dy + dz * dz;

  var u = ((point.x - segmentStart.x) * dx + (point.y - segmentStart.y) * dy + (point.z - segmentStart.z) * dz) / dAB;

  if (u > 1) {
    u = 1;
  }
  else if (u < 0) {
    u = 0;
  }

  var x = segmentStart.x + u * dx;
  var y = segmentStart.y + u * dy;
  var z = segmentStart.z + u * dz;

  var dX = x - point.x;
  var dY = y - point.y;
  var dZ = z - point.z;

  return Math.sqrt(dX * dX + dY * dY + dZ * dZ);
}

/**
 * Compute the distance between an arbitrary point and a plane
 * @param p1
 * @param p2
 * @param p3
 * @param arbitraryPoint
 */
function distancePointToPlane(p1: Cartesian3, p2: Cartesian3, p3: Cartesian3, arbitraryPoint: Cartesian3): number {
  // Create two vectors in the plane
  var v1 = {x: p2.x - p1.x, y: p2.y - p1.y, z: p2.z - p1.z};
  var v2 = {x: p3.x - p1.x, y: p3.y - p1.y, z: p3.z - p1.z};

  // Compute the cross product of these vectors to get a vector normal to the plane
  var n = {
    x: v1.y * v2.z - v1.z * v2.y,
    y: v1.z * v2.x - v1.x * v2.z,
    z: v1.x * v2.y - v1.y * v2.x
  };

  // Compute the coefficient D
  var D = - n.x * p1.x - n.y * p1.y - n.z * p1.z;

  // Compute the distance from the point to the plane
  return Math.abs(n.x * arbitraryPoint.x + n.y * arbitraryPoint.y + n.z * arbitraryPoint.z + D) / Math.sqrt(n.x * n.x + n.y * n.y + n.z * n.z);
}
