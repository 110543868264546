import React, { useState } from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {t} from "i18next";

export interface IPromptDialogProps extends WithTranslation {
  title: string;
  prompt: string;
  defaultAnswer: string|undefined;
  onAccept: (answer: string) => void;
  onCancel: () => void;
}

const BORDER_RADIUS = "5px";

const PromptDialog: React.FC<IPromptDialogProps> = ({
  title,
  prompt,
  defaultAnswer,
  onAccept,
  onCancel,
}) => {
  const placeholder: string = t("prompt_text_placeholder");
  const [text, setText] = useState<string>(defaultAnswer ? defaultAnswer : '' ) ;

  return (
    <>
      <div className="w3-modal" style={{ display: "block" }}>
        <div
          className="w3-modal-content w3-card-4 w3-theme-d4 w3-content"
          style={{ borderRadius: BORDER_RADIUS }}
        >
          <header
            className="w3-container w3-light-blue w3-center"
            style={{
              borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0px 0px`,
            }}
          >
            <h6>{t(title)}</h6>
          </header>

          <div
            className="w3-container w3-padding"
            style={{ textAlign: "center" }}
          >
            <div className="w3-margin w3-center">{t(prompt)}</div>
            <div
              className="w3-container w3-center"
              style={{ display: "inline-block", verticalAlign: "middle" }}
            >
              {
                <div className="w3-center">
                  <input
                    type="text"
                    id={"prompt_dialog_answer"}
                    name="prompt_dialog_answer"
                    className="w3-margin-right w3-left"
                    value={text}
                    style={{ marginTop: "5px" }}
                    placeholder={placeholder}
                    onChange={(e)=>{
                      setText(e.currentTarget.value) ;
                    }}
                  />
                </div>
              }
            </div>
          </div>

          <footer
            className="w3-container w3-theme-d3 w3-padding"
            style={{
              borderRadius: `0px 0px ${BORDER_RADIUS} ${BORDER_RADIUS}`,
            }}
          >
            <button
              className="w3-btn w3-left w3-grey w3-round-medium"
              onClick={onCancel}
            >
              {t("cancel")}
            </button>
            <button
              className="w3-btn w3-right w3-light-blue w3-round-medium"
              onClick={() => onAccept(text)}
            >
              {t("accept")}
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(PromptDialog) ;