import React, {useCallback, useEffect, useMemo, useState} from "react";
import {IAssetEntityProps} from "./IAssetEntityProps";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import {Cartesian3, Cesium3DTileFeature, Color, Entity as CesiumEntity} from "cesium";
import {TilesetUtils} from "../../util/TilesetUtils";
import CollarEntity from "../Widgets/CollarEntity";
import {CesiumMovementEvent} from "resium";
import {ColorUtil} from "../../../../lib/ColorUtil";

interface ICollarsEntityProps extends IAssetEntityProps {
  onLeftClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onRightClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onDoubleClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseMove?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseUp?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseDown?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  selectionPredicate?: (position: Cartesian3)=> boolean|undefined;
}


const CollarsEntity: React.FC<ICollarsEntityProps> = (props) => {
  const [userSession] = useUserSessionContext();
  const [features, setFeatures] = useState<Cesium3DTileFeature[]>();
  const [drillIds, setDrillIds] = useState<string[]>([]);

  const selectionPredicate = props.selectionPredicate ;

  // ... Get a list of features (each hole that will be used to create a collar item)
  useEffect(()=>{
    let featureList = new Array<Cesium3DTileFeature>();
    let drillIdList = new Set<string>();
    TilesetUtils.visitFeatures( props.tileset, feature => {
      // console.log(`feature[${feature.featureId}]: [${feature.getPropertyNames()}]`)
      featureList.push(feature);
      if ( feature.hasProperty("DrillID") ) {
        const drillId = feature.getProperty("DrillID") ;
        if ( drillId ) {
          drillIdList.add( drillId );
        }
      }
    })
    setFeatures(featureList);
    setDrillIds(Array.from(drillIdList.values()));
  }, [props.tileset])

  const resource = props.resource ;
  useEffect(()=>{
    if (features && features.length > 0 && userSession.layersVisibility.BlastholeSingleSegment ) {
      console.log(`Rendering CollarsEntity for ${resource.name}`);
    }
  }, [resource, features, userSession.layersVisibility.BlastholeSingleSegment]);

  const onLeftClick = props.onLeftClick;
  const handleLeftClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onLeftClick ) {
      onLeftClick( movement, target );
    }
  }, [onLeftClick]);

  const onRightClick = props.onRightClick ;
  const handleRightClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onRightClick ) {
      onRightClick( movement, target );
    }
  }, [onRightClick]);

  const onDoubleClick = props.onDoubleClick ;
  const handleDoubleClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onDoubleClick ) {
      onDoubleClick( movement, target );
    }
  }, [onDoubleClick]);

  const onMouseMove = props.onMouseMove;
  const handleMouseMove = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onMouseMove ) {
      onMouseMove( movement, target );
    }
  }, [onMouseMove]);

  const onMouseUp = props.onMouseUp ;
  const handleMouseUp = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onMouseUp ) {
      onMouseUp( movement, target );
    }
  }, [onMouseUp]);

  const onMouseDown = props.onMouseDown;
  const handleMouseDown = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( onMouseDown ) {
      onMouseDown( movement, target );
    }
  }, [onMouseDown]);

  return (
      <>
        {userSession.layersVisibility.BlastholeSingleSegment && features && (
            features.map( (feature, idx) => (
                <CollarEntity
                    show={true}
                    key={`CollarEntity_${idx}`}
                    assetSummary={props.resource}
                    feature={feature}
                    // selectedEntities={selectedEntities}
                    onLeftClick={handleLeftClick}
                    onRightClick={handleRightClick}
                    onDoubleClick={handleDoubleClick}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseDown={handleMouseDown}
                    selectionPredicate={props.selectionPredicate}
                />
            ))
        )}
      </>
  )
}

export default React.memo(CollarsEntity);
