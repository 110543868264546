import React, {useCallback, useEffect, useState} from "react";
import {
  EllipsoidGraphics,
  Entity
} from "resium";
import {
  Cartesian3,
  Cartesian4,
  Color,
  Matrix4
} from "cesium";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {TilesetUtils} from "../../util/TilesetUtils";
import Axis from "./Axis";

interface IProps {
  show: boolean;
  position: Cartesian3;
  color: Color;
}

const SIZE = 25;

const CenterOfRotationEntity: React.FC<IProps> = (props) => {
  const [siteData] = useSiteConfig();
  const [state, setState] = useState<{
    transformedOrigin: Cartesian3;
    xAxisBegin: Cartesian3;
    xAxisEnd: Cartesian3;
    yAxisBegin: Cartesian3;
    yAxisEnd: Cartesian3;
    zAxisBegin: Cartesian3;
    zAxisEnd: Cartesian3;
    radius: number ;
  }>();

  console.log(`props=${JSON.stringify(props)}`)

  useEffect(()=>{
    if ( siteData ) {

      const X_BEGIN = new Cartesian3(-SIZE / 2, 0, 0);
      const X_END = new Cartesian3(SIZE / 2, 0, 0);
      const Y_BEGIN = new Cartesian3(0, -SIZE / 2, 0);
      const Y_END = new Cartesian3(0, SIZE / 2, 0);
      const Z_BEGIN = new Cartesian3(0, 0, -SIZE / 2);
      const Z_END = new Cartesian3(0, 0, SIZE / 2);

      const transformedOrigin =       TilesetUtils.localToWorld(Cartesian4.fromArray([0, 0, 0, 1]), siteData.modelMatrix);
      // const transformedOrigin = Cartesian3.fromCartesian4(
      //     Matrix4.multiplyByVector(
      //         siteData.modelMatrix,
      //         Cartesian4.fromArray([0, 0, 0, 1]),
      //         new Cartesian4()
      //     )
      // );
      const t = Cartesian3.fromArray([
        props.position.x - transformedOrigin.x,
        props.position.y - transformedOrigin.y,
        props.position.z - transformedOrigin.z
      ]);

      const xAxisBegin = TilesetUtils.localToWorld(X_BEGIN, siteData.modelMatrix);
      const xAxisEnd = TilesetUtils.localToWorld(X_END, siteData.modelMatrix);
      const yAxisBegin = TilesetUtils.localToWorld(Y_BEGIN, siteData.modelMatrix);
      const yAxisEnd = TilesetUtils.localToWorld(Y_END, siteData.modelMatrix);
      const zAxisBegin = TilesetUtils.localToWorld(Z_BEGIN, siteData.modelMatrix);
      const zAxisEnd = TilesetUtils.localToWorld(Z_END, siteData.modelMatrix);

      setState({
        radius: SIZE/4,
        transformedOrigin: props.position,
        xAxisBegin: Cartesian3.add(xAxisBegin, t, xAxisBegin),
        xAxisEnd: Cartesian3.add(xAxisEnd, t, xAxisEnd),
        yAxisBegin: Cartesian3.add(yAxisBegin, t, yAxisBegin),
        yAxisEnd: Cartesian3.add(yAxisEnd, t, yAxisEnd),
        zAxisBegin: Cartesian3.add(zAxisBegin, t, zAxisBegin),
        zAxisEnd: Cartesian3.add(zAxisEnd, t, zAxisEnd),
      })
    }
  }, [siteData]);

  return (
      <>
        { state && (
            <>
              <Axis color={props.color} show={props.show} label={"X"} start={state.xAxisBegin} end={state.xAxisEnd}/>
              <Axis color={props.color} show={props.show} label={"Y"} start={state.yAxisBegin} end={state.yAxisEnd}/>
              <Axis color={props.color} show={props.show} label={"Z"} start={state.zAxisBegin} end={state.zAxisEnd}/>
              <Entity
                  show={props.show}
                  position={state.transformedOrigin}
              >
                <EllipsoidGraphics
                    radii={new Cartesian3(state.radius,state.radius,state.radius)}
                    material={Color.TRANSPARENT}
                    outline={true}
                    outlineColor={props.color}
                />
              </Entity>
            </>
        ) }
      </>
  )
}

export default CenterOfRotationEntity ;
