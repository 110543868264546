import { NavigateFunction, useNavigate } from "react-router-dom";
import { AuthApi } from "../routes";
import redirectIfForbidden from "./redirectIfForbidden";
import {useCallback} from "react";

export const refreshAuthTokens = async (navigate: NavigateFunction) => {
  let refreshResponse: Response | undefined = undefined;

  refreshResponse = await fetch(AuthApi.postRefreshTokenUrl(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${sessionStorage.getItem(
        "auth_token_header"
      )} ${sessionStorage.getItem("auth_token")}`,
    },
    body: JSON.stringify({
      refreshToken: sessionStorage.getItem("refresh_token"),
    }),
  });

  // We update the tokens in the session store here
  if (refreshResponse.ok) {
    const jsonData = await refreshResponse.json();
    sessionStorage.setItem("auth_token_header", jsonData.tokenHeader as string);
    sessionStorage.setItem("auth_token", jsonData.tokenValue as string);
    sessionStorage.setItem("refresh_token", jsonData.refreshToken as string);
  }

  redirectIfForbidden(refreshResponse, navigate);

  return refreshResponse;
};

/**
 * Refresh Auth Tokens function as a hook - to be able to call the router hooks inside
 * @returns
 */
export const useRefreshAuthTokens = () => {
  const navigate = useNavigate();

  return useCallback(() => refreshAuthTokens(navigate), [navigate]);
};
