import React, {CSSProperties, useEffect, useState} from "react";
import {ICustomerSite, useCustomerSitesApi} from "../../pages/CustomerSites";
import {TFunction, withTranslation, WithTranslation} from "react-i18next";
import CustomerFormField from "./CustomerFormField";
import {t} from "i18next";

interface IProps extends WithTranslation {
  className?:string|undefined;
  style?:CSSProperties|undefined;
  onCreateNewCustomerSite: (customerSite: ICustomerSite)=>void;
  errors: any;
}

export const IP_ADDRESS_PATTERN = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;

const validateCustomerSiteForm = (fields: ICustomerSite) => {
  let errors: any = {};
  if (!fields.name) errors.name = `${t("Customer Site Name")} ${t("cannot_be_empty")}`;
  if (!fields.nick) errors.nick = `${t("Nickname / Abbreviation")} ${t("cannot_be_empty")}`;
  // if (fields.dmmHost && !IP_ADDRESS_PATTERN.test(fields.dmmHost)) errors.dmmHost = `${t("DMM Host")} ${t("must be an IP address")}`;
  if (!fields.adminPassword) errors.adminPassword = `${t("Admin Password")} ${t("cannot_be_empty")}`;
  return errors ;
}

const NewCustomerForm: React.FC<IProps> = (props) => {
  const [errors, setErrors] = useState<any>({});
  useEffect(()=>{
    setErrors(props.errors);
  }, [props.errors]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const eventTarget = e.target as any;

    const errors = validateCustomerSiteForm({
      name: eventTarget.elements.name.value,
      nick: eventTarget.elements.nick.value,
      dmmHost: eventTarget.elements.dmmHost.value,
      dmmPrivatePort: eventTarget.elements.dmmPublicPort.value,
      dmmPublicPort: eventTarget.elements.dmmPrivatePort.value,
      adminPassword: eventTarget.elements.adminPassword.value,
    });
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      props.onCreateNewCustomerSite({
        name: eventTarget.elements.name.value,
        nick: eventTarget.elements.nick.value,
        dmmHost: eventTarget.elements.dmmHost.value,
        dmmPublicPort: eventTarget.elements.dmmPublicPort.value,
        dmmPrivatePort: eventTarget.elements.dmmPrivatePort.value,
        adminPassword: eventTarget.elements.adminPassword.value,
      } as ICustomerSite);

      eventTarget.elements.name.value = '';
      eventTarget.elements.nick.value = '';
      eventTarget.elements.dmmHost.value = '';
      eventTarget.elements.dmmPublicPort.value = '';
      eventTarget.elements.dmmPrivatePort.value = '';
      eventTarget.elements.adminPassword.value = '';
    }
  };

  return (
      <>
        <form className={props.className} style={props.style} onSubmit={handleSubmit}>
          <CustomerFormField id={'name'} name={'name'} label={'Customer Site Name'} placeholder={'ex: ACME Corp.'} error={errors.name} style={{width: "100%"}}/>
          <CustomerFormField id={'nick'} name={'nick'} label={'Nickname / Abbreviation'} placeholder={'ex: AC'} error={errors.nick} style={{width: "100%"}}/>
          <CustomerFormField id={'dmmHost'} name={'dmmHost'} label={'DMM Host'} placeholder={'ex: 192.168.1.10'} error={errors.dmmHost} style={{width: "100%"}}/>
          <CustomerFormField id={'dmmPublicPort'} name={'dmmPublicPort'} label={'DMM Public Port'} placeholder={'ex: 8091'} error={errors.dmmPublicPort} style={{width: "100%"}}/>
          <CustomerFormField id={'dmmPrivatePort'} name={'dmmPrivatePort'} label={'DMM Private Port'} placeholder={'ex: 8092'} error={errors.dmmPrivatePort} style={{width: "100%"}}/>
          <CustomerFormField id={'adminPassword'} name={'adminPassword'} label={'Admin Password'} type={'password'} autoComplete={'new-password'} error={errors.adminPassword} style={{width: "100%"}}/>

          <button
              className="w3-button w3-light-blue w3-round-medium"
              style={{height: "100%"}}
              type="submit"
          >
            {props.t("Create Customer Site")}
          </button>

        </form>
      </>
  )
}

export default withTranslation()(NewCustomerForm);
