import React, {CSSProperties, useEffect, useMemo, useState} from "react";
import {useMap} from "usehooks-ts";

interface IProps {
  className?:string|undefined;
  style?:CSSProperties|undefined;
  text?: string|undefined;
  tasks: Omit<Map<string, number>, "set" | "clear" | "delete">;
  taskMax: number;
  barHeight?: string|undefined;
  onProgressChanged: (progress: number)=>void;
}

function ProgressBar(props: React.PropsWithChildren<IProps>) {

  const [state, actions] = useMap<string, number>(Array.from(props.tasks.entries()));
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const barHeight = props.barHeight ?? "24px" ;
  const taskMax = props.taskMax;

  useEffect(()=>actions.setAll(Array.from(props.tasks.entries())), [props.tasks]);

  useEffect(()=>{
    const entries = Array.from(state.entries());
    // const loadedCount = entries.filter( ([,v]) => v ).length;
    const loadedCount = entries.reduce( (sum, item) => sum + item[1], 0 );
    const totalCount = entries.length * taskMax;

    if (totalCount === 0) {
      setUploadProgress(0);
    } else if ( loadedCount === totalCount ) {
      setUploadProgress(100);
    } else {
      const percent =  Math.min( 100 * (loadedCount+1) / totalCount, 100 ) ;
      setUploadProgress( percent )
    }
  }, [state, taskMax]);

  useEffect(()=>props.onProgressChanged(uploadProgress), [uploadProgress]);

  const progressText = useMemo(()=>{
    return props.text ? `(${props.text})`:'';
  }, [props.text]);

  const barClassName = useMemo(()=>{
    return uploadProgress < 100 ? 'w3-light-blue' : 'w3-light-green';
  }, [uploadProgress]);

  const textClassName = useMemo(()=>{
    return uploadProgress < 100 ? 'w3-text-light-blue' : 'w3-text-light-green';
  }, [uploadProgress]);

  function handleDblClick() {
    state.forEach((v,k)=>console.log(`${k}=${v}`))
  }

  return (
      <div
          className={`${props.className}`}
          style={props.style}
          onDoubleClick={handleDblClick}
      >
        <div className={`${textClassName}`} style={{ height: barHeight, width: "100%", paddingLeft: "10px", backgroundColor: "#000a" }}>
          <div className={'w3-opacity-off'}>{progressText}</div>
        </div>
        <div className={`w3-gray`}>
          <div className={barClassName} style={{ height: barHeight, width: `${uploadProgress}%`, paddingLeft: "10px" }}>
            {uploadProgress.toFixed(0)}%
          </div>
        </div>
      </div>
  )
}

export default ProgressBar;
