import React, {useMemo, useState} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {t} from "i18next";

interface IProps extends WithTranslation {
  title: string;
  prompt: string;
  choices: any[];
  onAccept: (itemIndex: number | undefined) => void;
  onCancel: () => void;
  selection: number;
  itemFactory?: ItemFactory ;
}

const BORDER_RADIUS = "5px";

interface ItemFactory {
  create: (choice: any, idx: number ) => React.ReactNode;
}

const SelectionDialog: React.FC<IProps> = (props) => {
  const [selection, setSelection] = useState<number>(props.selection);

  // const itemFactory = useMemo((): ItemFactory => {
  //   return props.itemFactory ?? {
  //     create: (choice: any, idx: number): React.ReactNode => {
  //       return <div key={"select_choice_" + idx} className="w3-center">
  //         <input
  //             type="radio"
  //             id={"select_choice_" + idx}
  //             name="selection_dialog"
  //             className="w3-margin-right w3-left"
  //             value={choice}
  //             style={{marginTop: "5px"}}
  //             onChange={() => {
  //               setSelection(idx)
  //             }}
  //             checked={idx === selection}
  //         />
  //         <label htmlFor={"select_choice_" + idx} className="w3-left">
  //           {choice}
  //         </label>
  //         <br/>
  //       </div>
  //     }
  //   }
  // }, [selection, setSelection, props.itemFactory]);

  return (
    <>
      <div className="w3-modal" style={{ display: "block" }}>
        <div
          className="w3-modal-content w3-card-4 w3-theme-d4"
          style={{ borderRadius: BORDER_RADIUS }}
        >
          <header
            className="w3-container w3-light-blue w3-center"
            style={{
              borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0px 0px`,
            }}
          >
            <h6>{t(props.title)}</h6>
          </header>

          <div
            className="w3-container w3-padding"
            style={{ textAlign: "center" }}
          >
            <div className="w3-margin w3-center">{t(props.prompt)}</div>
            <div
              className="w3-container w3-center"
              style={{ display: "inline-block", verticalAlign: "middle" }}
            >
              {props.choices.map((choice, idx) => (
                <div key={"select_choice_" + idx} className="w3-center">
                  <input
                    type="radio"
                    id={"select_choice_" + idx}
                    name="selection_dialog"
                    className="w3-margin-right w3-left"
                    value={choice}
                    style={{ marginTop: "5px" }}
                    onChange={() => {
                      // selection = idx;
                      setSelection(idx)
                    }}
                    checked={idx === selection}
                  />
                  <label htmlFor={"select_choice_" + idx} className="w3-left">
                    {choice}
                  </label>
                  <br />
                </div>
              ))}
            </div>
          </div>

          <footer
            className="w3-container w3-theme-d3 w3-padding"
            style={{
              borderRadius: `0px 0px ${BORDER_RADIUS} ${BORDER_RADIUS}`,
            }}
          >
            <button
              className="w3-btn w3-left w3-grey w3-round-medium"
              onClick={props.onCancel}
            >
              {t("cancel")}
            </button>
            <button
              className="w3-btn w3-right w3-light-blue w3-round-medium"
              onClick={() => props.onAccept(selection)}
            >
              {t("accept")}
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(SelectionDialog) ;