import {NavigateFunction, useNavigate} from "react-router-dom";
import React, {useCallback} from "react";
import redirectIfForbidden from "../../../lib/auth/redirectIfForbidden";
import {refreshAuthTokens} from "../../../lib/auth/refreshAuthTokens";
import {stringify} from "uuid/index";

/**
 * A Hook wrapper to the fetch with auth function
 * @returns a function that can be called to fetch data with authentication, token refresh and login redirect
 */
export const useUploadWithAuth_v2 = () => {
  const navigate = useNavigate();

  return useCallback((
      url: string,
      files: HTMLInputElement,
      onProgress: (progress: number, assetName: string)=>void
  ) => uploadWithAuth_v2(navigate, url + "?" + new Date().getTime(), files, onProgress), [navigate]);
};

export async function uploadWithAuth_v2(
    navigate: NavigateFunction,
    url: string,
    files: HTMLInputElement,
    onProgress: (progress: number, assetName: string)=>void

): Promise<{ status: number, body: string }[]> {

  const responses = await handleUploadFiles_v2( navigate, url, files, onProgress ) ;

  for ( let i = 0; i < responses?.length??0; ++i ) {
    const response = responses[ i ];
    if (redirectIfForbidden(response, navigate)) return responses;
  }

  return responses;
}

const handleUploadFiles_v2 = async (
    navigate: NavigateFunction,
    url: string,
    fileInput: HTMLInputElement,
    onProgress: (progress: number, assetName: string)=>void
): Promise<{ status: number, body: string }[]> => {
  let files: File[] = [];
  if ( fileInput?.files ) {
    for (let i = 0; i < fileInput.files.length; ++i) {
      files.push(fileInput.files[i]);
    }
  }
  return await forEachFile2( navigate, url, files, uploadFiles_v2, onProgress) ;
}

const forEachFile2 = async (
    navigate: NavigateFunction,
    url: string,
    files: File[],
    action: (url: string, files: File[])=>Promise<{ status: number, body: string }>,
    onProgress: (progress: number, assetName: string)=>void
): Promise<{ status: number, body: string }[]> => {
  const results: { status: number, body: string }[] = [];
  let count = 0;

  // ... Find each JSON metadata file and initialize file groups
  let uris = new Map<string, string[]>();
  let grouped = new Map<string, File[]>();
  for (const file of files) {
    // ... Groups keyed by metadata file name
    if (file.type === "application/json") {
      uris.set(file.name, []);
      grouped.set(file.name, [file]);

      // ... Extract relevant URIs from the jSON metadata file
      const text = await file.text();
      const json: any = JSON.parse(text);
      if (json.hasOwnProperty('root')) {
        extractUrls(json['root'], uri => {
          let urisInGroup = uris.get(file.name);
          if (urisInGroup) {
            urisInGroup.push(uri);
          }
        });
      }
    }
  }

  // ... Now iterate through all the other non-JSON files
  for (const file of files) {
    if (file.type !== "application/json") {
      let done = false ;
      // ... Find which group the file belongs to
      for ( let [groupName, memberUris] of Array.from(uris.entries()) ) {
        for ( let memberUri of memberUris ) {
          // ... If the file's name is part of this group's URIs
          if ( file.name === memberUri ) {
            // ... Add the file to the relevant group
            let filesInGroup = grouped.get( groupName );
            if ( filesInGroup ) {
              filesInGroup.push( file );
              done = true ;
              break ;
            }
          }
        }
        if ( done ) break ;
      }
    }
  }

  for ( let [assetName, files] of Array.from(grouped.entries()) ) {
    // console.log(`assetName=${assetName}, files=[${files.map(f=>f.name)}]`);
    const refreshResponse = await refreshAuthTokens(navigate);
    if ( !refreshResponse.ok ) {
      return results ;
    }
    results.push( await action( url, files ) ) ;
    onProgress( ++count / grouped.size, assetName );
  }

  return results ;
}

const forEachFile = async (
    navigate: NavigateFunction,
    url: string,
    files: File[],
    action: (url: string, files: File[])=>Promise<{ status: number, body: string }>,
    onProgress: (progress: number, assetName: string)=>void
): Promise<{ status: number, body: string }[]> => {
  const results: { status: number, body: string }[] = [];
  let count = 0;

  let grouped = new Map<string, File[]>();
  for (const file of files) {
    const filename = file.name.replace(/\.[^/.]+$/, "") ;
    let filesInGroup = grouped.get( filename );
    if ( filesInGroup ) {
      filesInGroup.push(file);
    } else {
      grouped.set( filename, [ file ]);
    }
  }

  for ( let [assetName, files] of Array.from(grouped.entries()) ) {
    const refreshResponse = await refreshAuthTokens(navigate);
    if ( !refreshResponse.ok ) {
      return results ;
    }
    results.push( await action( url, files ) ) ;
    onProgress( ++count / grouped.size, assetName );
  }

  return results ;
}

const extractUrls = (jsonNode: any, onUriExtracted: (uri: string)=>void ) => {
  // console.log(`>>> extractUrls: ${JSON.stringify(jsonNode, null, 2)}`);
  if ( jsonNode.hasOwnProperty('content') ) {
    const contentNode = jsonNode['content'];
    if ( contentNode.hasOwnProperty('uri') ) {
      const uriNode = contentNode['uri'];
      onUriExtracted( uriNode );
    }
  }
  if ( jsonNode.hasOwnProperty('children') ) {
    const childrenArray: any[] = jsonNode['children'];
    childrenArray.forEach( childNode => extractUrls( childNode, onUriExtracted ) ) ;
  }
  // console.log(`<<< extractUrls`);
}

const uploadFiles_v2 = async (url: string, files: File[]): Promise<{ status: number, body: string }> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    // xhr.upload.addEventListener('progress', e => onProgress(e.loaded / e.total));
    xhr.addEventListener('load', () => resolve({status: xhr.status, body: xhr.responseText}));
    xhr.addEventListener('error', () => reject(new Error('File upload failed')));
    xhr.addEventListener('abort', () => reject(new Error('File upload aborted')));
    xhr.open('POST', url, true);
    xhr.setRequestHeader("Authorization", `${sessionStorage.getItem("auth_token_header")} ${sessionStorage.getItem("auth_token")}`);
    const data = new FormData();
    files.forEach( file => data.append("file", file) );
    xhr.send(data);
  })
};

