import React, {useCallback} from "react";
import {Color} from "cesium";
import {IColorPickerDialogProps} from "../../../Dialogs/ColorPickerDialog";

interface IProps {
  id?: string;
  color: Color;
  width?:number;
  height?:number;
  onColorPickerInvoked: (props: IColorPickerDialogProps)=>void;
  onColorChanged: (color: Color)=>void;
  disabled?:boolean;
}

const DEFAULT_COLOR_BOX_WIDTH = 60;
const DEFAULT_COLOR_BOX_HEIGHT = 25;

const ColorBox: React.FC<IProps> = (props) => {

  const isDisabled = props.disabled ?? false;

  const onColorClicked = useCallback(()=>{
    if ( ! isDisabled ) {
      props.onColorPickerInvoked({
        color: props.color,
        onCancel: () => {
        },
        onColorSelected: color => props.onColorChanged(color)
      } as IColorPickerDialogProps);
    }
  }, [isDisabled])

  return (
      <div
          id={props.id}
          className="w3-container w3-round-medium w3-border w3-border-gray w3-right"
          style={{
            height: `${props.height ?? DEFAULT_COLOR_BOX_HEIGHT}px`,
            width: `${props.width ?? DEFAULT_COLOR_BOX_WIDTH}px`,
            backgroundColor: props.color.toCssHexString()
          }}
          onClick={onColorClicked}
      >
        {/* Empty space with background color */}
      </div>
  )
}

export default ColorBox;
