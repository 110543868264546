import {withTranslation, WithTranslation} from "react-i18next";
import React from "react";
import ListItem from "../List/ListItem";
import {ILoggedInUser} from "../../pages/ActiveUsers";
import {format} from "date-fns";
import {t} from "i18next";

interface IProps extends WithTranslation {
  loggedInUser: ILoggedInUser;
  colWidths: number[];
}

const LoggedInUserRow: React.FC<IProps> = (props) => {

  return (
      <>
        <ListItem>
          <div
              className={'w3-cell'}
              style={{width: `${props.colWidths[0]}%`}}
          >
            {props.loggedInUser.userName}
          </div>
          <div
              className={'w3-cell'}
              style={{width: `${props.colWidths[1]}%`}}
          >
            {props.loggedInUser.customerSiteName}
          </div>
          <div
              className={'w3-cell'}
              style={{width: `${props.colWidths[2]}%`}}
          >
            {format(props.loggedInUser.lastAccessTimeStamp, t("LONG_DATETIME_FORMAT"))}
          </div>
        </ListItem>
      </>
  )
}

export default withTranslation()(LoggedInUserRow);

