import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation {
  originalName: string;
  onSave: (newName: string) => void;
  onCancel: () => void;
}

const SavedSessionRenameModal: React.FC<Props> = ({
  onSave,
  onCancel,
  originalName,
}) => {
  const [currentName, setCurrentName] = useState(originalName);

  const handleSave = () => {
    onSave(currentName);
  };

  return (
    <div className="w3-modal w3-show">
      <div
          className="w3-modal-content w3-card-4 w3-theme-d4"
      >
        <header className="w3-container w3-light-blue">
          <span onClick={onCancel} className="w3-button w3-display-topright">
            <FontAwesomeIcon icon={faTimes} />
          </span>
          <div className={'w3-xlarge'}>{t("edit_saved_session")}</div>
        </header>
        <div className="w3-container w3-padding-large">
          <label htmlFor="textSearch">{t("session_name")}</label>
          <input
            className="w3-input w3-border w3-padding"
            style={{ margin: "5px 0 0 0", width: "100%" }}
            type="text"
            value={currentName}
            onChange={(e) => setCurrentName(e.target.value)}
          />
        </div>
        <footer className="w3-container w3-theme-d3 w3-padding">
          <div className="w3-right">
            <button
              onClick={onCancel}
              className="w3-button w3-margin-right w3-round-medium w3-orange"
            >
              {t("cancel")}
            </button>
            <button
              onClick={handleSave}
              className="w3-button w3-round-medium w3-light-green"
            >
              {t("save")}
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default withTranslation()(SavedSessionRenameModal);
