import Cesium, {
  Cartesian3,
  Cesium3DTile,
  Cesium3DTileFeature,
  Cesium3DTileset as CesiumCesium3DTileset,
  Cesium3DTileStyle,
  Color, Entity as CesiumEntity,
  Matrix4,
  SceneMode
} from "cesium";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Cesium3DTileset, CesiumMovementEvent} from "resium";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import {
  addCollarDepthAdditionalConditions,
  addDistFromSectionAdditionalConditions,
  addExcludedCondition,
  addProminenceFilterConditions,
  addSecondarySegmentStyleCondition,
  featurePropertyConditionExpr,
  featurePropertyConditionExpr2,
  featurePropertyConditionExprMinMax,
  getColorExpr,
  getSubDrillConditionExpression,
  IColorConditionExpression,
  IStyleExpression
} from "../../hooks/useTilesetStyleExpression";
import ILayerTypes, {LayerType} from "../../../../model/LayerType";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {AttributeType, IAttributeTypeMap} from "../../domain/AttributeType";
import IColorLegendConfiguration from "../../domain/IColorLegendConfiguration";
import {IColorLegend, IColorLegendItem} from "../../hooks/useColorLegendApi";
import IColorLegendItemConfiguration from "../../domain/IColorLegendItemConfiguration";
import {LegendType} from "../../domain/LegendType";
import {IStyle} from "../../domain/IStyle";
import {ITilesetResource} from "./ITilesetResource";
import PatternBoundaryOutlineEntity from "./PatternBoundaryOutlineEntity";
import CollarsEntity from "./CollarsEntity";
import IAssetSummary from "../../domain/IAssetSummary";
import {ADJUSTED_POS_PROPERTY_NAME, TilesetUtils} from "../../util/TilesetUtils";
import {IBoundingBox} from "../../domain/IBoundingBox";
import {BlastHoleStylist} from "../../Styling/BlastHoleStylist";

interface ITilesetResourceProps {
  sceneMode: SceneMode;
  showPattern: boolean;
  showLayer: boolean;
  selectedAttribute: AttributeType|undefined;
  tilesetResource: ITilesetResource ;
  modelMatrix: Matrix4|undefined;
  onReady?: ((tileset: Cesium.Cesium3DTileset, tilesetResource: ITilesetResource) => void) | undefined;
  onAllTilesLoad?: ((tileset: Cesium.Cesium3DTileset, tilesetResource: ITilesetResource) => void) | undefined;
  onVisible?: ((tile: CesiumCesium3DTileset|Cesium3DTile, tilesetResource: ITilesetResource) => void) | undefined;
  onTileFailed?: ((tile: CesiumCesium3DTileset, tilesetResource: ITilesetResource, err: any) => void) | undefined;
  onLeftClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onRightClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onDoubleClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseMove?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseUp?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseDown?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  selectionPredicate?: (position: Cartesian3)=> boolean|undefined;
  selectedTarget?:Cesium3DTileFeature|CesiumEntity|undefined;
}

export const getUrlResource = (assetSummary: IAssetSummary): Cesium.Resource => {
  const url = TilesetUtils.getUrlFromAsset(assetSummary);
  return TilesetUtils.getResourceFromUrl(url);
}

/**
 * Where the magic happens
 *
 * @param props
 * @constructor
 */
const TilesetResource: React.FC<ITilesetResourceProps> = (props)=> {
  // const refreshAuthTokens = useRefreshAuthTokens();
  const [userSession] = useUserSessionContext();
  const [siteConfig] = useSiteConfig();
  const tilesetRef = useRef<Cesium.Cesium3DTileset>();
  const [isLoaded, setLoaded] = useState<boolean>();
  const [urlResource, setUrlResource] = useState<Cesium.Resource>();


  const selectionPredicate = props.selectionPredicate;
  const colorLegendStates = userSession.colorLegendStates;
  const colorLegendConfigs = siteConfig.legendConfigs;
  // const urlResource = props.tilesetResource.resource;

  useEffect(()=>{
    // refreshAuthTokens()
    //     .then( resp => {
    //       if ( resp.ok ) {
            const urlResource = getUrlResource( props.tilesetResource );
            // console.log(`TilesetResource: access token refreshed for ${urlResource}`);
            setUrlResource( urlResource )
        //   }
        // })
  }, [props.tilesetResource/*, refreshAuthTokens*/]);


  useEffect(()=>console.log(`TilesetResource: ${props.tilesetResource.name}`), [props.tilesetResource.name]);

  /*
  Selected attribute based on layer type
   */
  const selectedAttribute = useMemo(()=>{
    switch( props.tilesetResource.layerType ) {
      case LayerType.BlastholeFractures:
        return AttributeType.Prominence;
      default:
        return props.selectedAttribute;
    }
  }, [props.tilesetResource.layerType, props.selectedAttribute]);

  // useEffect(()=>console.log(`selectedAttribute has changed to ${selectedAttribute}`), [selectedAttribute]);

  /*
  Tileset ready to load
   */
  const onReady = props.onReady;
  const tilesetResource = props.tilesetResource;
  const handleReady = useCallback( (tileset: Cesium.Cesium3DTileset) => {
    // console.log(`handleReady: awaiting ${props.tilesetResource.name}`);

    tileset.readyPromise
        .then((readyTileset) => {
          console.log(`handleReady: tileset: ${tilesetResource.name} content is ready`);

          // console.log(`handleReady(ready): ${props.tilesetResource.name}`);
          if (onReady) {
            onReady( readyTileset, tilesetResource ) ;
          }
          tilesetRef.current = readyTileset ;
        })

  }, [onReady, tilesetResource]);

  /*
  Tileset loaded
   */
  const onAllTilesLoad = props.onAllTilesLoad;
  const handleTilesLoaded = useCallback( () => {
    if (!tilesetRef.current) {
      throw new Error(`Expected tileset for ${tilesetResource.name} to be defined`);
    }

    // console.log(`... handleTilesLoaded: tileset ready? ${tilesetRef.current.ready}, root content len ${tilesetRef.current.root.content?.featuresLength}, num children: ${tilesetRef.current.root.children?.length}---`);
    // TilesetUtils.visitTilesRecursive( tilesetRef.current.root, tile => {
    //   console.log(`TILE: feature len=${tile.content?.featuresLength}`)
    // } );

    if ( tilesetRef.current.root.content?.featuresLength > 0 ) {
      // console.log(`--- WE HAVE ROOT CONTENT  (tileset ready? ${tilesetRef.current.ready}) (feature len ${tilesetRef.current.root.content?.featuresLength})---`);
      tilesetRef.current.root.content?.readyPromise
          .then(()=>{
            if (onAllTilesLoad && tilesetRef.current) {
              onAllTilesLoad( tilesetRef.current, tilesetResource );
            }
            console.log(`handleTilesLoaded: tileset: ${tilesetResource.name} content is processed`);
            setLoaded( true ) ;
          })
    } else if (tilesetRef.current.root.children.length > 0 ) {
      // console.log("+++ WE HAVE ROOT CHILDREN +++");
      tilesetRef.current.root.children[0].content?.readyPromise
          .then(()=>{
            if (onAllTilesLoad && tilesetRef.current) {
              onAllTilesLoad( tilesetRef.current, tilesetResource );
            }
            console.log(`handleTilesLoaded: tileset: ${tilesetResource.name} content is processed`);
            setLoaded( true ) ;
          })
    }

  }, [onAllTilesLoad, tilesetResource]);

  // useEffect(()=>{console.log(`handleInitialTilesLoad has changed for ${props.tilesetResource.name}`)}, [handleTilesLoaded]);

  /*
  At least one tile visible
   */
  const onVisible = props.onVisible ;
  const handleTileVisible = useCallback((tile: CesiumCesium3DTileset | Cesium3DTile) => {
    if (onVisible) {
      onVisible( tile, tilesetResource );
    }
  }, [onVisible, tilesetResource]);

  /**
   * Update features when the selection filter has changed
   */
  const layerType = tilesetResource.layerType;
  useEffect(()=>{
    switch (layerType) {
      case LayerType.BlastholeSecondarySegments:
      case LayerType.BlastholeFractures:
        if ( tilesetRef.current/*?.root.content*/) {
          TilesetUtils.visitFeatures(tilesetRef.current, feature => {
            if (selectionPredicate) {
              const position = Cartesian3.fromArray(feature.getProperty(ADJUSTED_POS_PROPERTY_NAME));
              const isShown = selectionPredicate( position ) ?? true ;
              feature.setProperty("excluded", isShown ? undefined : true);
            } else {
              feature.setProperty("excluded", undefined);
            }
          });
        }
        break;

      default:
        break ;
    }
  }, [selectionPredicate, layerType]);

  /**
   * An asset failed to load
   */
  // const onTileFailed = props.onTileFailed;
  const handleTileFailed = useCallback((err: any) => {
    if ( err?.hasOwnProperty( "message" ) ) {
      if ( err.message.indexOf("Status Code: 401") !== -1 ) {
        console.warn(`handleTileFailed: NEED TO REFRESH TOKEN FOR resource=${tilesetRef.current?.resource.url}`);

        // refreshAuthTokens()
        //     .then( resp => {
        //       if ( resp.ok ) {
        //         console.log(`handleTileFailed: YES WE CAN RETRY!`);
        //       } else {
        //         if (props.onTileFailed && tilesetRef.current) {
        //           props.onTileFailed( tilesetRef.current, props.tilesetResource, err );
        //         }
        //       }
        //     })

      }
    }
  }, [/*onTileFailed, tilesetResource*/]);

  /*
  Layer visibility
   */
  const showLayer = props.showLayer;
  const sceneMode = props.sceneMode;
  const layerVisibility = useMemo(()=>{
    if ( sceneMode === SceneMode.SCENE2D ) {
      switch (tilesetResource.layerType) {
        case LayerType.Boundary:
          return true ;
        case LayerType.BlastholeClusters:
          return true ;
        default:
          break;
      }
    }
    return showLayer ;
  }, [sceneMode, showLayer, tilesetResource.layerType]);
  // useEffect(()=>console.log(`layerVisibility has changed for ${props.tilesetResource.name}`), [layerVisibility]);

  /*
  Combined pattern + layer visibility
   */
  const showPattern = props.showPattern;
  const show = useMemo(()=>{
    return showPattern && layerVisibility;
  }, [showPattern, layerVisibility]);
  // useEffect(()=>console.log(`show has changed for ${props.tilesetResource.name}`), [show]);

  /*
  Layer opacity
   */
  const layerOpacity = useMemo(()=>{
    if ( sceneMode === SceneMode.SCENE2D ) {
      switch (tilesetResource.layerType) {
        case LayerType.Boundary:
          return 0.0;
        case LayerType.BlastholeClusters:
          return 1.0;
        default:
          break ;
      }
    }
    return userSession.layersOpacity[LayerType[tilesetResource.layerType] as keyof ILayerTypes<number>];

  }, [sceneMode, userSession.layersOpacity, tilesetResource.layerType]);
  // useEffect(()=>console.log(`layerOpacity has changed for ${props.tilesetResource.name}`), [layerOpacity]);

  /*
  Layer color
   */
  const layerColor = useMemo(()=>{
    return userSession.layersColor[ LayerType[tilesetResource.layerType] as keyof ILayerTypes<Color>];
  }, [userSession.layersColor, tilesetResource.layerType]);
  // useEffect(()=>console.log(`layerColor has changed for ${props.tilesetResource.name}`), [layerColor]);

  /*
  Selected attribute color legend config and state
   */
  const getColorLegend = useCallback((selectedAttribute: AttributeType|undefined): {config: IColorLegendConfiguration, state: IColorLegend}|undefined => {
    if ( !selectedAttribute || !colorLegendStates || !colorLegendConfigs ) {
      return undefined;
    }
    const attributeIndex = AttributeType[selectedAttribute] as keyof IAttributeTypeMap<IColorLegendConfiguration> ;
    const colorLegendConfig = colorLegendConfigs[attributeIndex];
    const colorLegendState = colorLegendStates.get( selectedAttribute );
    if ( !colorLegendConfig || !colorLegendState ) {
      return undefined ;
    }
    return { config: colorLegendConfig, state: colorLegendState };
  }, [colorLegendConfigs, colorLegendStates]);
  // useEffect(()=>console.log(`getColorLegend has changed for ${props.tilesetResource.name}`), [getColorLegend]);

  const colorLegend = useMemo(()=>{
    return getColorLegend( selectedAttribute );
  }, [getColorLegend, selectedAttribute]);
  // useEffect(()=>console.log(`colorLegend has changed for ${props.tilesetResource.name}`), [colorLegend]);

  /*
  Color legend layer-specific overrides
   */
  const layerSpecificOverrides = useMemo((): ILayerSpecificStyleOverrides => {
    return getLayerSpecificStyleOverrides( tilesetResource.layerType, sceneMode )
  }, [tilesetResource.layerType, sceneMode]);
  // useEffect(()=>console.log(`layerSpecificOverrides has changed for ${props.tilesetResource.name}`), [layerSpecificOverrides]);

  /*
  Some styling is affected by the use of cross-sections
  */
  const crossSectionsAreInUse = useMemo(()=>{
    const csXY = userSession.layersVisibility.CrossSectionZ;
    const csXZ = userSession.layersVisibility.CrossSectionY;
    const csYZ = userSession.layersVisibility.CrossSectionX;

    return csXY || csXZ || csYZ ;

  }, [
    userSession.layersVisibility.CrossSectionX,
    userSession.layersVisibility.CrossSectionY,
    userSession.layersVisibility.CrossSectionZ
  ]);

  // useEffect(()=>console.log(`CrossSectionX=${userSession.layersVisibility.CrossSectionX}`), [userSession.layersVisibility.CrossSectionX]);
  // useEffect(()=>console.log(`CrossSectionY=${userSession.layersVisibility.CrossSectionY}`), [userSession.layersVisibility.CrossSectionY]);
  // useEffect(()=>console.log(`CrossSectionZ=${userSession.layersVisibility.CrossSectionZ}`), [userSession.layersVisibility.CrossSectionZ]);

  /*
  Tileset expression
   */
  const maskedColor = userSession.maskedColor;
  const showSubDrill = userSession.showSubDrill;
  const boundingBox = userSession.boundingBox;
  const blockSize = userSession.blockSize;
  const layersVisibilityCrossSectionX = userSession.layersVisibility.CrossSectionX;
  const layersVisibilityCrossSectionY = userSession.layersVisibility.CrossSectionY;
  const layersVisibilityCrossSectionZ = userSession.layersVisibility.CrossSectionZ;
  const crossSectionOffsetX = userSession.crossSectionOffsetX;
  const crossSectionOffsetY = userSession.crossSectionOffsetY;
  const crossSectionOffsetZ = userSession.crossSectionOffsetZ;
  const selectedCrossSection = userSession.selectedCrossSection;
  const collarDepth = userSession.collarDepth;
  const prominenceFilter = userSession.prominenceFilter;
  const sectionViewDistanceThreshold = userSession.sectionViewDistanceThreshold;

  const originReference = userSession.originReference;
  const showAboveBench = userSession.showAboveBench;
  const showWithinBench = userSession.showWithinBench;
  const showBeneathBench = userSession.showBeneathBench;
  const benchElevationMin = userSession.benchElevationLimits?.min;
  const benchElevationMax = userSession.benchElevationLimits?.max;
  const segmentStyle = userSession.segmentStyle.segmentDisplayStyle;

  // ... See if sub-drilling info is included in the asset
  const includeSubDrill = useMemo(()=> {
      return tilesetResource.extras
          ? tilesetResource.extras.hasOwnProperty("IncludeSubDrill") && tilesetResource.extras.IncludeSubDrill
          : false;
    }, [tilesetResource]);

  const offsetZMin = useMemo(()=>{
    if ( originReference && benchElevationMin !== undefined ) {
      return benchElevationMin - originReference.z ;
    } else {
      return undefined ;
    }
  }, [originReference, benchElevationMin]);

  // useEffect(()=>console.log(`benchMinOffset: ${offsetZMin}, originReference=${originReference}`), [offsetZMin]);

  const offsetZMax = useMemo(()=>{
    if (originReference && blockSize && offsetZMin !== undefined && benchElevationMax !== undefined ) {
      return benchElevationMax - /*blockSize.z - */originReference.z;
    } else {
      return undefined ;
    }
  }, [originReference, blockSize, benchElevationMax, offsetZMin]);

  // useEffect(()=>console.log(`benchMaxOffset: ${offsetZMax}, blockSize=${blockSize}`), [offsetZMax]);

  /**
   * The 'tilesetStyle.show' value, based on bench filters
   */
  const visibilityFromBenchFilters = useMemo(()=>{
    let expressions: string[] = [];

    // ... If needed, include sub-drill filter
    if (includeSubDrill && show) {
      // result.show = getSubDrillConditionExpression(showSubDrill);
      expressions.push( getSubDrillConditionExpression(showSubDrill) ) ;
    }

    // ... If needed, include bench elevation filter
    if (offsetZMin && offsetZMax) {
      let benchElevationFilter: string[] = [];

      if ( showAboveBench ) {
        benchElevationFilter.push( featurePropertyConditionExpr2(["OffsetXYZ[2]"], ">=", offsetZMax, false) );
      }
      if ( showWithinBench ) {
        benchElevationFilter.push( featurePropertyConditionExprMinMax(["OffsetXYZ[2]"], offsetZMin, offsetZMax, false) );
      }
      if ( showBeneathBench ) {
        benchElevationFilter.push( featurePropertyConditionExpr2(["OffsetXYZ[2]"], "<", offsetZMin, false) );
      }
      expressions.push(
          benchElevationFilter.length > 0 ? ('(' + benchElevationFilter.join(" || ") + ')') : "false"
      )
    }

    return expressions.length > 0 ? expressions.join(" && ") : "true" ;

  }, [show, includeSubDrill, showSubDrill, offsetZMin, offsetZMax, showAboveBench, showWithinBench, showBeneathBench]);

  const tilesetStyleExpression = useMemo(()=>{
    let result: IStyleExpression ;

    /*
    Create the tileset style expression (common for all layer types)
     */
    if ( ! selectedAttribute || !colorLegend ) {
      // ... No selected attribute: use the layer's style
      result = {
        show: show,
        color: layerColor.withAlpha(layerOpacity).toCssHexString()
      } as IStyleExpression;
    }
    else {
      // ... Use the color legend to style each individual feature in the asset
      let layerStyle: IStyle = {
        visibility: show,
        opacity: layerOpacity,
        color: layerColor
      };
      result = getAttributeSpecificStyleExpression( selectedAttribute, layerStyle, colorLegend.config, colorLegend.state, maskedColor, layerSpecificOverrides );
    }

    /*
    Apply layer-specific tweaks to the style (as per Gen1 UI discussions)
    */
    switch (tilesetResource.layerType) {
      case LayerType.BlockModel:
        if (crossSectionsAreInUse || selectionPredicate) {
          result.show = false;
        } else {
          result.show = visibilityFromBenchFilters;
          // console.log(`result.show=${JSON.stringify(result.show)}`)
        }
        break;

      case LayerType.BlastholeSecondarySegments: {
        if (!siteConfig?.modelMatrix) {
          throw new Error(`Expected model matrix to be defined for ${tilesetResource.name}`);
        }

        addSecondarySegmentStyleCondition(
            result,
            segmentStyle,
            selectedAttribute
        );

        if (boundingBox &&
            !layersVisibilityCrossSectionZ &&
            (layersVisibilityCrossSectionX || layersVisibilityCrossSectionY)
        ) {
          addDistFromSectionAdditionalConditions(
              result,
              userSession,
              sectionViewDistanceThreshold,
              siteConfig.modelMatrix
          );
        }
        break;
      }
      case LayerType.BlastholeSingleSegment:
        // ... Blast-hole segments are never show as is (tileset features), but as collar entities instead
        result.show = false;
        break;

      case LayerType.BlastholeClusters:
        break;

      case LayerType.BlastholeFractures: {
        if (!siteConfig?.modelMatrix) {
          throw new Error(`Expected model matrix to be defined for ${tilesetResource.name}`);
        }

        // ... Rules for showing: is Z plane is ON, show them all;  if other planes are on, show only those that are close to the cross-section
        if (boundingBox &&
            !layersVisibilityCrossSectionZ &&
            (layersVisibilityCrossSectionX || layersVisibilityCrossSectionY)
        ) {
          addDistFromSectionAdditionalConditions( result, userSession, sectionViewDistanceThreshold, siteConfig.modelMatrix);
        }

        // ... Filter out fractures above a given collar depth
        addCollarDepthAdditionalConditions(result, collarDepth);

        // ... Filter out features below a certain threshold
        addProminenceFilterConditions(result, prominenceFilter);

        break;
      }

      case LayerType.Boundary:
        if (crossSectionsAreInUse || selectionPredicate) {
          result.show = false;
        }
        break;

      case LayerType.CrossSectionX:
        if (!tilesetResource.featureCount) {
          throw new Error(`Expected feature count to be defined for ${tilesetResource.name}`);
        }
        if (tilesetResource.featureCount > 0 && boundingBox && layersVisibilityCrossSectionX) {
          result.show = layersVisibilityCrossSectionX;
          if (result.show && blockSize) {
            result.show = visibilityFromBenchFilters;

            addCrossSectionVisibilityCondition(result, {
              blockSize: blockSize,
              boundingBox: boundingBox,
              crossSectionOffsetX: crossSectionOffsetX,
              crossSectionOffsetY: crossSectionOffsetY,
              crossSectionOffsetZ: crossSectionOffsetZ,
              layersVisibilityCrossSectionX: layersVisibilityCrossSectionX,
              layersVisibilityCrossSectionY: layersVisibilityCrossSectionY,
              layersVisibilityCrossSectionZ: layersVisibilityCrossSectionZ
            });
          }
        } else {
          result.show = "false";
        }
        break;

      case LayerType.CrossSectionY:
        if (!tilesetResource.featureCount) {
          throw new Error(`Expected feature count to be defined for ${tilesetResource.name}`);
        }
        if (tilesetResource.featureCount > 0 && boundingBox && layersVisibilityCrossSectionY) {
          result.show = layersVisibilityCrossSectionY;
          if (result.show && blockSize) {
            result.show = visibilityFromBenchFilters;

            addCrossSectionVisibilityCondition(result, {
              blockSize: blockSize,
              boundingBox: boundingBox,
              crossSectionOffsetX: crossSectionOffsetX,
              crossSectionOffsetY: crossSectionOffsetY,
              crossSectionOffsetZ: crossSectionOffsetZ,
              layersVisibilityCrossSectionX: layersVisibilityCrossSectionX,
              layersVisibilityCrossSectionY: layersVisibilityCrossSectionY,
              layersVisibilityCrossSectionZ: layersVisibilityCrossSectionZ
            });
          }
        } else {
          result.show = "false";
        }
        break;

      case LayerType.CrossSectionZ:
        if (!tilesetResource.featureCount) {
          throw new Error(`Expected feature count to be defined for ${tilesetResource.name}`);
        }
        if (tilesetResource.featureCount > 0 && boundingBox && layersVisibilityCrossSectionZ) {
          result.show = layersVisibilityCrossSectionZ;
          if (result.show && blockSize) {
            result.show = visibilityFromBenchFilters;

            addCrossSectionVisibilityCondition(result, {
              blockSize: blockSize,
              boundingBox: boundingBox,
              crossSectionOffsetX: crossSectionOffsetX,
              crossSectionOffsetY: crossSectionOffsetY,
              crossSectionOffsetZ: crossSectionOffsetZ,
              layersVisibilityCrossSectionX: layersVisibilityCrossSectionX,
              layersVisibilityCrossSectionY: layersVisibilityCrossSectionY,
              layersVisibilityCrossSectionZ: layersVisibilityCrossSectionZ
            });
          }
        } else {
          result.show = "false";
        }
        break;
    }
    return result ;

  }, [
    tilesetResource,
    selectedAttribute,
    layerOpacity,
    layerColor,
    show,
    colorLegend,
    crossSectionsAreInUse,
    layerSpecificOverrides,
    siteConfig.modelMatrix,
    maskedColor,
    boundingBox,
    blockSize,
    layersVisibilityCrossSectionX,
    layersVisibilityCrossSectionY,
    layersVisibilityCrossSectionZ,
    crossSectionOffsetX,
    crossSectionOffsetY,
    crossSectionOffsetZ,
    collarDepth,
    prominenceFilter,
    sectionViewDistanceThreshold,
    selectionPredicate,
    visibilityFromBenchFilters,
    segmentStyle,
    userSession
  ] )

  // useEffect(()=> {
  //   if ( tilesetResource.layerType === LayerType.BlastholeSecondarySegments ) {
  //     console.log(`tilesetStyleExpression has changed for ${tilesetResource.name}: ${JSON.stringify(tilesetStyleExpression, null, 2)}`)
  //   }
  // }, [tilesetResource, tilesetStyleExpression]);

  /*
  Tileset style
   */
  const tilesetStyle = useMemo(()=>{

    // ... Create style from tileset style expression
    let style = new Cesium3DTileStyle(
        // ... Some layers support an exclusion condition (e.g. corridor selection)
        addExcludedCondition(tilesetStyleExpression)
    ) ;

    // ... Some layers use callbacks for styling (only blast hole / secondary segments for now)
    if ( tilesetResource.layerType  === LayerType.BlastholeSecondarySegments ) {
      let stylist: BlastHoleStylist = new BlastHoleStylist( userSession, siteConfig, sceneMode ) ;
      style.show = {
        evaluate: f => {

          let visibility = stylist.evaluateShow(f);

          if (visibility && selectionPredicate) {
            const position = Cartesian3.fromArray(f.getProperty(ADJUSTED_POS_PROPERTY_NAME));
            visibility = selectionPredicate( position ) ?? true ;
          }

          return visibility;
        },
        evaluateColor: f => stylist.evaluateColor(f)
      }
      style.color = {
        evaluate: f => stylist.evaluateShow(f),
        evaluateColor: f => stylist.evaluateColor(f)
      }
    }

    return style ;

  }, [tilesetStyleExpression, tilesetResource, userSession, siteConfig, sceneMode]);
  // useEffect(()=>console.log(`tilesetStyle has changed for ${props.tilesetResource.name}`), [tilesetStyle]);

  /*
  Additional data for assets that have additional entities rendered
   */
  const layerName: string = useMemo(()=>LayerType[props.tilesetResource.layerType], [props.tilesetResource.layerType]);

  /*
  Model matrix may be altered for some layers, such as cross-sections where we play around with visibility/translation
  of some sections planes to simulate true cross-sections from a discrete set of coarse section planes
   */
  const extras = props.tilesetResource.extras ;
  const matrix = useMemo(()=>{
    switch( props.tilesetResource.layerType ) {
      case LayerType.CrossSectionX: {
        const blockSizeLocal = Cartesian3.fromArray(extras["BlockSize"]);
        let translation = new Cartesian3( userSession.crossSectionOffsetX % blockSizeLocal.x, 0, 0 );
        let t = Matrix4.fromTranslation(translation);
        return Matrix4.multiply( siteConfig.modelMatrix, t, t ) ;
      }
      case LayerType.CrossSectionY: {
        const blockSizeLocal = Cartesian3.fromArray(extras["BlockSize"]);
        let translation = new Cartesian3( 0, userSession.crossSectionOffsetY % blockSizeLocal.y, 0 );
        let t = Matrix4.fromTranslation(translation);
        return Matrix4.multiply( siteConfig.modelMatrix, t, t ) ;
      }
      case LayerType.CrossSectionZ: {
        const blockSizeLocal = Cartesian3.fromArray(extras["BlockSize"]);
        let translation = new Cartesian3( 0, 0, userSession.crossSectionOffsetZ % blockSizeLocal.z );
        let t = Matrix4.fromTranslation(translation);
        return Matrix4.multiply( siteConfig.modelMatrix, t, t ) ;
      }
      default:
        return siteConfig.modelMatrix;
    }
  }, [
    extras,
    props.tilesetResource.layerType,
    siteConfig.modelMatrix,
    userSession.crossSectionOffsetZ,
    userSession.crossSectionOffsetY,
    userSession.crossSectionOffsetX
  ]);

  /*
  Mouse events from the Cesium tileset
   */
  const onLeftClick = props.onLeftClick;
  const handleLeftClickFeature = useCallback((movement: CesiumMovementEvent, target: Cesium3DTileFeature) => {
    onLeftClick && onLeftClick( movement, target );
  }, [onLeftClick]);

  const onRightClick = props.onRightClick;
  const handleRightClickFeature = useCallback((movement: CesiumMovementEvent, target: Cesium3DTileFeature) => {
    onRightClick && onRightClick( movement, target );
  }, [onRightClick]);

  const onDoubleClick = props.onDoubleClick;
  const handleDoubleClickFeature = useCallback((movement: CesiumMovementEvent, target: Cesium3DTileFeature) => {
    onDoubleClick && onDoubleClick( movement, target );
  }, [onDoubleClick]);

  const onMouseMove = props.onMouseMove;
  const handleMouseMoveFeature = useCallback((movement: CesiumMovementEvent, target: Cesium3DTileFeature) => {
    onMouseMove && onMouseMove( movement, target );
  }, [onMouseMove]);

  const onMouseDown = props.onMouseDown;
  const handleMouseDownFeature = useCallback((movement: CesiumMovementEvent, target: Cesium3DTileFeature) => {
    onMouseDown && onMouseDown( movement, target );
  }, [onMouseDown]);

  const onMouseUp = props.onMouseUp;
  const handleMouseUpFeature = useCallback((movement: CesiumMovementEvent, target: Cesium3DTileFeature) => {
    onMouseUp && onMouseUp( movement, target );
  }, [onMouseUp]);

  /*
  Mouse events from the Cesium tileset
   */
  const handleLeftClickEntity = useCallback((movement: CesiumMovementEvent, target: any) => {
    onLeftClick && onLeftClick( movement, target );
  }, [onLeftClick]);

  const handleRightClickEntity = useCallback((movement: CesiumMovementEvent, target: any) => {
    onRightClick && onRightClick( movement, target );
  }, [onRightClick]);

  const handleDoubleClickEntity = useCallback((movement: CesiumMovementEvent, target: any) => {
    onDoubleClick && onDoubleClick( movement, target );
  }, [onDoubleClick]);

  const handleMouseMoveEntity = useCallback((movement: CesiumMovementEvent, target: any) => {
    onMouseMove && onMouseMove( movement, target );
  }, [onMouseMove]);

  const handleMouseDownEntity = useCallback((movement: CesiumMovementEvent, target: any) => {
    onMouseDown && onMouseDown( movement, target );
  }, [onMouseDown]);

  const handleMouseUpEntity = useCallback((movement: CesiumMovementEvent, target: any) => {
    onMouseUp && onMouseUp( movement, target );
  }, [onMouseUp]);

  // const spatialCrossSectionPlane = useMemo(()=>{
  //   return Plane.fromPointNormal(new Cartesian3(), Cartesian3.normalize(new Cartesian3(1, 0.5, 0.5), new Cartesian3())) ;
  // }, []);

  /*
  Component rendered
   */
  return (
      <>
        {urlResource &&
            <Cesium3DTileset
                show={show}
                modelMatrix={matrix}
                url={urlResource}
                style={tilesetStyle}
                onReady={handleReady}
                onTileVisible={handleTileVisible}
                onAllTilesLoad={handleTilesLoaded}
                onClick={handleLeftClickFeature}
                onRightClick={handleRightClickFeature}
                onDoubleClick={handleDoubleClickFeature}
                onMouseMove={handleMouseMoveFeature}
                onMouseDown={handleMouseDownFeature}
                onMouseUp={handleMouseUpFeature}
                preloadWhenHidden={true}
                onTileFailed={handleTileFailed}
                maximumScreenSpaceError={1}
                skipLevelOfDetail={false}
            />
        }
        {/*Additional entities*/}
        { tilesetRef.current && isLoaded && show &&
          {
            "Boundary":
                <PatternBoundaryOutlineEntity
                    tileset={tilesetRef.current}
                    resource={props.tilesetResource}
                    onLeftClick={handleLeftClickEntity}
                    onRightClick={handleRightClickEntity}
                    onDoubleClick={handleDoubleClickEntity}
                    onMouseMove={handleMouseMoveEntity}
                    onMouseUp={handleMouseUpEntity}
                    onMouseDown={handleMouseDownEntity}
                    isSelected={tilesetRef.current === props.selectedTarget?.tileset}
                />,

            "BlastholeSingleSegment":
                <CollarsEntity
                    tileset={tilesetRef.current}
                    resource={props.tilesetResource}
                    onLeftClick={handleLeftClickEntity}
                    onRightClick={handleRightClickEntity}
                    onDoubleClick={handleDoubleClickEntity}
                    onMouseMove={handleMouseMoveEntity}
                    onMouseUp={handleMouseUpEntity}
                    onMouseDown={handleMouseDownEntity}
                    selectionPredicate={selectionPredicate}
                />,

          } [ layerName ]
        }
        {/*{ tilesetResource.layerType === LayerType.BlockModel && tilesetRef.current && boundingBox &&*/}
        {/*  <SpatialCrossSection*/}
        {/*      tileset={tilesetRef.current}*/}
        {/*      boundingBox={boundingBox}*/}
        {/*      plane={spatialCrossSectionPlane}*/}
        {/*  />*/}
        {/*}*/}
      </>
  )
}

export default React.memo(TilesetResource);

/**
 * Although styling is somewhat consistent, there are situations where we veer off of the standard styling behavior.
 *
 * One example: 2D vs 3D: in 2D we don;t want to change the styling in response to the color legend controls.
 */
export interface ILayerSpecificStyleOverrides {
  isMaskable: boolean;
  hasVariableOpacity: boolean;
  visibilityOverride: boolean|undefined;
  opacityOverride: number|undefined;
  darkeningFactor: number|undefined;
}

/**
 * Custom overrides of color legend styling, based on layer type and scene mode
 * @param layerType
 * @param sceneMode
 */
function getLayerSpecificStyleOverrides( layerType: LayerType, sceneMode: SceneMode ): ILayerSpecificStyleOverrides {
  let result: ILayerSpecificStyleOverrides = {
    isMaskable: false,
    hasVariableOpacity: true,
    visibilityOverride: undefined,
    opacityOverride: undefined,
    darkeningFactor: undefined
  }
  switch ( layerType ) {
    case LayerType.BlastholeSecondarySegments:
      result.isMaskable = true ;
      break;
    case LayerType.CrossSectionX:
    case LayerType.CrossSectionY:
    case LayerType.CrossSectionZ:
      result.isMaskable = true ;
      result.hasVariableOpacity = false ;
      result.darkeningFactor = 0.35;
      break;
    case LayerType.Boundary:
      result.hasVariableOpacity = sceneMode !== SceneMode.SCENE2D;
      result.visibilityOverride = sceneMode === SceneMode.SCENE2D ? true : undefined;
      result.opacityOverride = sceneMode === SceneMode.SCENE2D ? 0 : undefined;
      break;
    case LayerType.BlastholeClusters:
      result.hasVariableOpacity = sceneMode !== SceneMode.SCENE2D ;
      result.visibilityOverride = sceneMode === SceneMode.SCENE2D ? true : undefined ;
      result.opacityOverride = sceneMode === SceneMode.SCENE2D ? 1.0 : undefined;
      break;
    default:
      break ;
  }
  return result ;
}

/**
 * Given a color legend and config, as well as layer specific overrides, this function returns the opacity and color to
 * be applied for a specific color legend item.
 * @param itemState
 * @param itemConfig
 * @param maskedColor
 * @param overrides
 */
function computeColorAndOpacityForLayerType( itemState: IColorLegendItem, itemConfig: IColorLegendItemConfiguration, maskedColor: Color, overrides: ILayerSpecificStyleOverrides ): [ number, Color, boolean] {

  if ( overrides.isMaskable ) {

    const opacity = overrides.hasVariableOpacity
        ? itemState.opacity
        : overrides.opacityOverride !== undefined
            ? overrides.opacityOverride
            : 1.0;

    const color = itemState.visible
        ? itemConfig.color.withAlpha(1)
        : maskedColor.withAlpha(1);

    const isMasked = !itemState.visible;

    if ( overrides.darkeningFactor ) {
      color.darken(overrides.darkeningFactor, color) ;
    }

    return [ opacity, color, isMasked];

  } else {

    const visibility = overrides.visibilityOverride !== undefined ? overrides.visibilityOverride : itemState.visible;
    const opacity = visibility
        ? overrides.opacityOverride !== undefined
            ? overrides.opacityOverride
            : overrides.hasVariableOpacity
                ? itemState.opacity
                : 1.0
        : 0.0;

    const color = itemConfig.color.withAlpha(opacity) ;

    return [opacity, color, false];
  }
}

/**
 * Given a starting style for the layer, this function returns a tileset style expression for the currently selected attribute
 * @param layerStyle
 * @param legendConfig
 * @param legendState
 * @param attributeType
 * @param maskedColor
 * @param overrides
 */
function getAttributeSpecificStyleExpression(
    attributeType: AttributeType,
    layerStyle: IStyle,
    legendConfig: IColorLegendConfiguration,
    legendState: IColorLegend,
    maskedColor: Color,
    overrides: ILayerSpecificStyleOverrides
): IStyleExpression {

  let styleExpression: IStyleExpression = {
    show: layerStyle.visibility,
    color: undefined
  };

  styleExpression.color = {
    conditions: []
  } as IColorConditionExpression ;

  let conditions = styleExpression.color.conditions;

  const legendType = legendConfig.legendType;
  const attributeName = AttributeType[attributeType] ;
  // const maskedColor = Color.fromCssColorString(legendConfig.maskedColor) ;

  // ... Undefined condition (for numeric-range type legends)
  {
    const [opacity, color, isMasked] = computeColorAndOpacityForLayerType( legendState.colorInvalidValue, legendConfig.invalidItem, maskedColor, overrides) ;
    const conditionExpr = featurePropertyConditionExpr([attributeName], "<", 0, true );
    const colorExpr = getColorExpr(color.withAlpha(opacity * layerStyle.opacity));
    conditions.push([conditionExpr,colorExpr]);
  }

  // ... Value range conditions
  // const maxCount = Math.min(legendConfig.items.length, legendState.colorLegendItems.length);
  for ( let i = 0; i < legendConfig.items.length; i += 1 ) {
    let config = legendConfig.items[i];

    if ( legendType === LegendType.Ranges ) {
      const [opacity, color, isMasked] = computeColorAndOpacityForLayerType( legendState.colorLegendItems[i], config, maskedColor, overrides) ;
      const rangeUpperLimit = config.range[1];
      const conditionExpr = featurePropertyConditionExpr([attributeName], "<=", rangeUpperLimit );
      const colorExpr = getColorExpr(color.withAlpha(opacity * layerStyle.opacity));
      conditions.push([conditionExpr,colorExpr]);
    }
    else if ( legendType === LegendType.Enum || legendType === LegendType.Mapped ) {
      // ... if there was no mapping, then do the usual
      const [opacity, color, isMasked] = computeColorAndOpacityForLayerType( legendState.colorLegendItems[i], config, maskedColor, overrides) ;

      const categoryValue = config.range[0];
      const conditionExpr = featurePropertyConditionExpr([attributeName], "===", categoryValue );
      const colorExpr = getColorExpr(color.withAlpha(opacity * layerStyle.opacity));
      conditions.push([conditionExpr,colorExpr]);
    }
  }

  // ... Range exceeded condition
  if ( legendType === LegendType.Ranges ) {
    const itemState = legendState.colorRangeExceeded ?? legendState.colorInvalidValue ;
    const itemConfig = legendConfig.rangeExceededItem ?? legendConfig.invalidItem;

    const [/*visibility, */opacity, color, isMasked] = computeColorAndOpacityForLayerType( itemState, itemConfig, maskedColor, overrides) ;
    const upperLimit = legendConfig.items[legendConfig.items.length-1].range[1];
    const conditionExpr = featurePropertyConditionExpr([attributeName], ">", upperLimit );
    const colorExpr = getColorExpr(color.withAlpha(opacity * layerStyle.opacity));
    conditions.push([conditionExpr,colorExpr]);
  }

  // ... Catch-all (use layer defaults)
  {
    const opacity = layerStyle.visibility ? layerStyle.opacity : 0.0;
    const color = layerStyle.color.withAlpha(1);
    const conditionExpr = "true";
    const colorExpr = getColorExpr(color.withAlpha(opacity ));
    conditions.push([conditionExpr, colorExpr]);
  }

  // console.log(`styleExpression: ${JSON.stringify(styleExpression)}`);
  return styleExpression ;
}

/**
 * Modifies the tileset style expression to show/hide a cross-section plane
 * @param styleExpression
 * @param userSession
 */
function addCrossSectionVisibilityCondition(
    styleExpression: IStyleExpression,
    userSession: {
      blockSize: Cartesian3,
      boundingBox: IBoundingBox,
      crossSectionOffsetX: number,
      crossSectionOffsetY: number,
      crossSectionOffsetZ: number,
      layersVisibilityCrossSectionX: boolean,
      layersVisibilityCrossSectionY: boolean,
      layersVisibilityCrossSectionZ: boolean
    }
    // userSession: UserSessionState
) {
  if ( !userSession.blockSize ) throw new Error('userSession.blockSize is undefined') ;
  if ( !userSession.boundingBox ) throw new Error('userSession.boundingBox is undefined') ;

  // console.log(`addCrossSectionVisibilityCondition: minIdx=${boundingBox.minIdx}, offset=${userSession.crossSectionOffsetY}`);
  styleExpression.defines = {
    ...(styleExpression.defines ?? {}),
    offsetFilterI: TilesetUtils.featurePropertyConditionExpression2( ["OffsetIJK[0]"], "===",
        Math.floor(userSession.crossSectionOffsetX / userSession.blockSize.x) + userSession.boundingBox.minIdx.x ),
    offsetFilterJ: TilesetUtils.featurePropertyConditionExpression2( ["OffsetIJK[1]"], "===",
        Math.floor(userSession.crossSectionOffsetY / userSession.blockSize.y) + userSession.boundingBox.minIdx.y ),
    offsetFilterK: TilesetUtils.featurePropertyConditionExpression2( ["OffsetIJK[2]"], "===",
        Math.floor(userSession.crossSectionOffsetZ / userSession.blockSize.z) + userSession.boundingBox.minIdx.z ),
    csVisibleX: userSession.layersVisibilityCrossSectionX,
    csVisibleY: userSession.layersVisibilityCrossSectionY,
    csVisibleZ: userSession.layersVisibilityCrossSectionZ,
  };

  let showConditionX = `((\${feature['Layer']} === 'CrossSectionX') && \${csVisibleX} && \${offsetFilterI})`;
  let showConditionY = `((\${feature['Layer']} === 'CrossSectionY') && \${csVisibleY} && \${offsetFilterJ})`;
  let showConditionZ = `((\${feature['Layer']} === 'CrossSectionZ') && \${csVisibleZ} && \${offsetFilterK})`;

  styleExpression.show = `(${styleExpression.show}) && (${showConditionX} || ${showConditionY} || ${showConditionZ})`;

  return styleExpression;
}
