import {ILayerSpecificStyleOverrides} from "../components/Views/TilesetResource";
import {UserSessionState} from "../components/Contexts/UserSessionContext";
import {ISiteConfiguration} from "../domain/ISiteConfiguration";
import ILayerTypes, {LayerType} from "../../../model/LayerType";
import {Color, SceneMode} from "cesium";
import {AttributeType, IAttributeTypeMap} from "../domain/AttributeType";
import IColorLegendConfiguration from "../domain/IColorLegendConfiguration";
import {IColorLegend, IColorLegendItem} from "../hooks/useColorLegendApi";
import IColorLegendItemConfiguration from "../domain/IColorLegendItemConfiguration";
import {IStylist} from "./IStylist";
import {DEFAULT_NEUTRAL_COLOR} from "../domain/IStyle";
import {LegendType} from "../domain/LegendType";

type ColorOpacityVisibility =[Color, number, boolean];

/**
 * Stylist base class: all stylists use a user session state to perform styling
 */
export abstract class BaseStylist<T> implements IStylist<T>, ILayerSpecificStyleOverrides {
  private _userSessionState: UserSessionState;
  private _siteConfig: ISiteConfiguration;
  private _layer: LayerType;
  private _sceneMode: SceneMode;
  private _layerColor: Color;
  private _layerOpacity: number;
  private _layerVisibility: boolean;
  private _selectedAttribute: AttributeType | undefined;
  private _selectedAttributeName: string | undefined;
  private _maskedColor: Color ;
  private _colorLegendConfig: IColorLegendConfiguration | undefined = undefined;
  private _colorLegendState: IColorLegend | undefined = undefined;

  protected _darkeningFactor: number | undefined = undefined;
  protected _hasVariableOpacity: boolean = true;
  protected _isMaskable: boolean = false;
  protected _opacityOverride: number | undefined = undefined;
  protected _visibilityOverride: boolean | undefined = undefined;

  private _invalidValue: ColorOpacityVisibility = [DEFAULT_NEUTRAL_COLOR, 1.0, true];
  private _rangeExceeded: ColorOpacityVisibility|undefined = undefined ;
  private _ranges: ColorOpacityVisibility[] = [];

  constructor(userSessionState: UserSessionState, siteConfig: ISiteConfiguration, layer: LayerType, sceneMode: SceneMode) {
    this._userSessionState = userSessionState;
    this._siteConfig = siteConfig;
    this._layer = layer;
    this._sceneMode = sceneMode;
    this._layerColor = this._userSessionState.layersColor[LayerType[this._layer] as keyof ILayerTypes<Color>];
    this._layerOpacity = this._userSessionState.layersOpacity[LayerType[this._layer] as keyof ILayerTypes<number>];
    this._layerVisibility = this._userSessionState.layersVisibility[LayerType[this._layer] as keyof ILayerTypes<boolean>];
    this._selectedAttribute = layer === LayerType.BlastholeFractures ? AttributeType.Prominence : this.userSessionState.selectedAttribute;
    this._maskedColor = this.userSessionState.maskedColor;

    if (this._selectedAttribute !== undefined) {
      this._selectedAttributeName = AttributeType[this._selectedAttribute];
      const attributeIndex = AttributeType[this._selectedAttribute] as keyof IAttributeTypeMap<IColorLegendConfiguration>;
      if (this.siteConfig.legendConfigs) {
        this._colorLegendConfig = this.siteConfig.legendConfigs[attributeIndex];
      }
      if (this.userSessionState.colorLegendStates) {
        this._colorLegendState = this.userSessionState.colorLegendStates.get(this._selectedAttribute);
      }
      // ... Setup value ranges for the selected attribute
      if ( this._colorLegendConfig && this._colorLegendState ) {
        const legendType = this._colorLegendConfig.legendType;

        this._invalidValue = this.computeColorAndOpacityForLayerType(this._colorLegendState.colorInvalidValue, this._colorLegendConfig.invalidItem);

        if ( legendType === LegendType.Ranges ) {
          this._rangeExceeded = this.computeColorAndOpacityForLayerType(
              this._colorLegendState.colorRangeExceeded ?? this._colorLegendState.colorInvalidValue,
              this._colorLegendConfig.rangeExceededItem ?? this._colorLegendConfig.invalidItem
          );
        }

        for ( let i = 0; i < this._colorLegendConfig.items.length; i += 1 ) {
          this._ranges.push(
              this.computeColorAndOpacityForLayerType(
                  this._colorLegendState.colorLegendItems[i],
                  this._colorLegendConfig.items[i]
              )
          );
        }
      }
    }
  }

  evaluateColor(item: T): Color {
    const value = this.extractValue( item ) ;
    const colorOpacityVisibility = this.getColorOpacityVisibility( value ) ;
    return colorOpacityVisibility[0].withAlpha(colorOpacityVisibility[1] * this._layerOpacity);
  }

  evaluateShow(item: T): boolean {
    return this._layerVisibility;
  }

  abstract extractValue(item: T): number|undefined;

  /**
   * Gets the color and opacity for a given item, using the color legend configuration and state
   * @private
   * @param value
   */
  protected getColorOpacityVisibility( value: number|undefined ): ColorOpacityVisibility {

    // ... If the value invalid
    if ( value === undefined || value < 0 ) {
      return this._invalidValue;
    }

    // ... Check all ranges to see if the value fits
    if ( this._colorLegendConfig ) {
      const legendType = this._colorLegendConfig.legendType;

      for (let i = 0; i < this._colorLegendConfig.items.length; i += 1) {
        if (legendType === LegendType.Ranges) {
          const rangeUpperLimit = this._colorLegendConfig.items[i].range[1];
          if (value <= rangeUpperLimit) {
            return this._ranges[i];
          }
        } else if (legendType === LegendType.Enum || legendType === LegendType.Mapped) {
          const categoryValue = this._colorLegendConfig.items[i].range[0];
          if (value === categoryValue) {
            return this._ranges[i];
          }
        }
      }
      // ... Check for the range exceeded condition
      if ( legendType === LegendType.Ranges && this._rangeExceeded ) {
        const upperLimit = this._colorLegendConfig.items[this._colorLegendConfig.items.length-1].range[1];
        if ( value > upperLimit ) {
          return this._rangeExceeded;
        }
      }
    }
    return this._invalidValue;
  }

  protected isValueInvalid( value: number|undefined ): boolean {
    // ... If the value invalid
    if ( value === undefined || value < 0 || isNaN( value )) {
      return true;
    }

    // ... Check all ranges to see if the value fits
    if ( this._colorLegendConfig ) {
      const legendType = this._colorLegendConfig.legendType;

      for (let i = 0; i < this._colorLegendConfig.items.length; i += 1) {
        if (legendType === LegendType.Ranges) {
          const rangeUpperLimit = this._colorLegendConfig.items[i].range[1];
          if (value <= rangeUpperLimit) {
            return false;
          }
        } else if (legendType === LegendType.Enum || legendType === LegendType.Mapped) {
          const categoryValue = this._colorLegendConfig.items[i].range[0];
          if (value === categoryValue) {
            return false;
          }
        }
      }
      // ... Check for the range exceeded condition
      if ( legendType === LegendType.Ranges && this._rangeExceeded ) {
        const upperLimit = this._colorLegendConfig.items[this._colorLegendConfig.items.length-1].range[1];
        if ( value > upperLimit ) {
          return false;
        }
      }
    }
    return true;
  }

  protected isValueMasked( value: number|undefined ): boolean {
    if ( !this.isMaskable ) {
      return false ;
    }
    const colorOpacityVisibility = this.getColorOpacityVisibility( value ) ;
    return ! colorOpacityVisibility[2];
  }

  get userSessionState(): UserSessionState {
    return this._userSessionState;
  }

  get siteConfig(): ISiteConfiguration {
    return this._siteConfig;
  }

  get layer(): LayerType {
    return this._layer;
  }

  get layerColor(): Color {
    return this._layerColor;
  }

  get layerOpacity(): number {
    return this._layerOpacity;
  }

  get layerVisibility(): boolean {
    return this._layerVisibility;
  }

  get selectedAttribute(): AttributeType | undefined {
    return this._selectedAttribute;
  }

  get selectedAttributeName(): string | undefined {
    return this._selectedAttributeName;
  }

  get colorLegendConfig(): IColorLegendConfiguration | undefined {
    return this._colorLegendConfig;
  }

  get colorLegendState(): IColorLegend | undefined {
    return this._colorLegendState;
  }

  get sceneMode(): SceneMode {
    return this._sceneMode;
  }

  get darkeningFactor(): number | undefined {
    return this._darkeningFactor;
  }

  get hasVariableOpacity(): boolean {
    return this._hasVariableOpacity;
  }

  get isMaskable(): boolean {
    return this._isMaskable;
  }

  get opacityOverride(): number | undefined {
    return this._opacityOverride;
  }

  get visibilityOverride(): boolean | undefined {
    return this._visibilityOverride;
  }

  get maskedColor(): Color {
    return this._maskedColor;
  }

  private computeColorAndOpacityForLayerType(itemState: IColorLegendItem, itemConfig: IColorLegendItemConfiguration): ColorOpacityVisibility {
    if (this.isMaskable) {
      const opacity = this.hasVariableOpacity
          ? itemState.opacity
          : this.opacityOverride !== undefined
              ? this.opacityOverride
              : 1.0;

      const color = itemState.visible
          ? itemConfig.color.withAlpha(1)
          : this.maskedColor.withAlpha(1);

      if (this.darkeningFactor) {
        color.darken(this.darkeningFactor, color);
      }

      return [color, opacity, itemState.visible];

    } else {

      const visibility = this.visibilityOverride !== undefined ? this.visibilityOverride : itemState.visible;
      const opacity = visibility
          ? this.opacityOverride !== undefined
              ? this.opacityOverride
              : this.hasVariableOpacity
                  ? itemState.opacity
                  : 1.0
          : 0.0;

      const color = itemConfig.color.withAlpha(opacity);

      return [color, opacity, itemState.visible];
    }
  }
}