import React from "react";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Pill from "./Gen2/components/BasicControls/Pill";
import {useTooltips} from "./Gen2/components/Contexts/TooltipContextProvider";

interface SubMenuIconButtonProps {
    id?: string;
    label: string,
    icon: IconProp,
    onClick: (btn: HTMLButtonElement) => void;
    pillValue?: number;
    onPillClicked?: ()=>void;
}

const SubMenuIconButton: React.FC<SubMenuIconButtonProps> = (props) => {

    const[,tooltipActions] = useTooltips();

    const onChangeTooltip = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, tooltip: React.ReactNode|undefined ) => {
      const parent = e.currentTarget.parentElement?.getBoundingClientRect() ?? document.body.getBoundingClientRect();
      const rect = e.currentTarget.getBoundingClientRect() ;
      tooltipActions.setTooltip(tooltip ? {
        content: tooltip,
        position: {
          x: rect.x + rect.width + 5,
          y: rect.y - parent.y + rect.height/2
        },
        orientation: "right"
      } : undefined )
    }

    return (
        <>
          {/*<div className={"w3-display-container"}>*/}
              <button
                  id={props.id}
                  className = "w3-button w3-padding-16"
                  onClick = {(e) => {
                    props.onClick( e.currentTarget );
                    onChangeTooltip(e, undefined);
                  }}
                  onMouseEnter={(e)=>onChangeTooltip(e, <div>{props.label}</div>)}
                  onMouseLeave={(e)=>onChangeTooltip(e, undefined)}
              >
                <FontAwesomeIcon icon={props.icon} />
                <Pill
                    className={"w3-opacity-min"}
                    style={{position: "relative", right: "10px", bottom:"10px"}}
                    value={props.pillValue}
                    onPillClicked={props.onPillClicked}
                />
              </button>
          {/*</div>*/}
        </>
    );
}

export default SubMenuIconButton ;
