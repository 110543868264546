import React, {useEffect, useMemo, useState} from "react";
import {useSystemInfo} from "../Gen2/components/Contexts/SystemInfoContext";
import {AppConstants} from "../../AppConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faDatabase} from "@fortawesome/free-solid-svg-icons";
import {withTranslation, WithTranslation} from "react-i18next";
import {useScreenRecording} from "../Gen2/components/Contexts/ScreenRecordingContextProvider";
import {useCustomerSitesApi} from "../../pages/CustomerSites";
import UserSession from "../../lib/userSession";

const Footer : React.FC<WithTranslation> = (props) => {
  const t = props.t ;

  const customerSitesApi = useCustomerSitesApi();
  const [systemInfo] = useSystemInfo();
  const [screenRecording] = useScreenRecording();

  const customerSiteId = UserSession.getCustomerSiteId();
  const [customerSiteName, setCustomerSiteName] = useState<string>();

  useEffect(()=>{
    customerSitesApi.fetchCustomerSite( customerSiteId )
        .then( customerSite => setCustomerSiteName( customerSite.name ) );

  }, [customerSitesApi, customerSiteId]);

  const usedSpacePercent = useMemo(()=>{
    return systemInfo?.fileSystemInfo.usedSpacePercent;
  }, [systemInfo]);

  const usedSpaceColor = useMemo(()=>{
    if (!usedSpacePercent) {
      return undefined ;
    }
    if (usedSpacePercent < 80) {
      return 'w3-opacity';
    }
    if (usedSpacePercent < 90) {
      return 'w3-text-amber';
    }
    return 'w3-text-red';
  }, [usedSpacePercent]);

  const isRecording = useMemo(()=>{
    return screenRecording.isRecording;
  }, [screenRecording]);

  return (
      <footer
          className=""
          style={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            textAlign: "right",
          }}
      >
        <div className="w3-cell-row">
          <div className={"w3-container w3-cell w3-cell-middle w3-third w3-left-align"}>
            { customerSiteName && (<span className={'w3-opacity'} style={{marginLeft: "32px"}}>{customerSiteName}</span>)}
            {isRecording && (
              <span className={"w3-margin-left w3-text-red w3-left-align"} >
                <FontAwesomeIcon icon={faCircle}/> {t("Recording (press ESC to stop)")}
              </span>
            )}
          </div>
          <div className={"w3-container w3-cell w3-cell-middle w3-third w3-center w3-opacity"}>
            Copyright &copy; {AppConstants.About.AppCopyright.startDate}-{new Date().getFullYear()} {AppConstants.About.appVendor}
          </div>
          <div className={`w3-container w3-cell w3-cell-middle w3-third w3-right-align ${usedSpaceColor}`}>
            <FontAwesomeIcon icon={faDatabase}/> {usedSpacePercent ?? 'n/a'} %
          </div>
        </div>
      </footer>
  )
}

export default withTranslation()(Footer);
