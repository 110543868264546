import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LanguageButtons from "../components/LanguageButtons";
import ptLogo from "../img/PeckTech_Endorsed_Logo_Reverse.png";
import {AuthApi} from "../lib/routes";
import {AppConstants} from "../AppConstants";
import UserSession from "../lib/userSession";
import {SyncLoader} from "react-spinners";
import ClientEventsStore from "../lib/ClientEventsStore";
import BlastholeSvg from "../components/Gen2/components/Widgets/BlastholeSvg";

interface Props {}

const LoginPage: React.FC<Props> = () => {
  const { t} = useTranslation();
  const navigate = useNavigate();
  const [loginFailed, setLoginFailed] = useState<string|undefined>();
  const [waitingForLoginResponse, setWaitingForLoginResponse] = useState<boolean>(false);

  console.log(AuthApi.postLoginUrl());

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const eventTarget = e.target as any;

    const formBody = JSON.stringify({
      username: eventTarget.elements.username.value,
      password: eventTarget.elements.password.value,
    });

    setWaitingForLoginResponse(true);

    const response = await fetch(AuthApi.postLoginUrl(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "*/*",
      },
      body: formBody,
    }).catch( error => {
      setLoginFailed("connection_refused");
    });

    console.log(`>>> response=> ${response?.statusText ?? "n/a"}`);

    if (response && response.ok && !response.url.includes("login?error=true")) {
      const json = await response.json();
      // console.log( ">>> response JSON: " + JSON.stringify(json));
      const auth_token = json.tokenValue;
      const auth_token_header = json.tokenHeader;
      const refresh_token = json.refreshToken;
      const customerSiteId = json.customerSiteId;
      sessionStorage.setItem("userName", json.username);
      sessionStorage.setItem("auth_token", auth_token as string);
      sessionStorage.setItem("auth_token_header", auth_token_header as string);
      sessionStorage.setItem("refresh_token", refresh_token as string);
      sessionStorage.setItem("roles", JSON.stringify(json.roles));
      sessionStorage.setItem("customerSiteId", customerSiteId as string );
      // sessionStorage.setItem("logged_in", "true" );

      // await timeout(3000);

      // ... Clear the client-side events
      ClientEventsStore.clear();

      if (UserSession.isInRoles(["DEV"])) {
        navigate("/customerSites");
      } else {
        navigate("/");
      }
    }
    else if (response && response.status === 401) {
      setWaitingForLoginResponse(false);
      setLoginFailed(t("invalid_username_or_password"));
    }
    else if (response) {
      setWaitingForLoginResponse(false);
      setLoginFailed(response.statusText);
    }
    else {
      setWaitingForLoginResponse(false);
    }
  };

  // function timeout(delay: number) {
  //   return new Promise( res => setTimeout(res, delay) );
  // }

  return (
    <>
      <div
        className="w3-modal w3-light-grey w3-theme-d4 w3-display-container"
        style={{ display: "block" }}
      >
        <div
          className="w3-modal-content w3-card-4 w3-dark-grey"
          style={{ maxWidth: "600px" }}
        >
          <div className="w3-right">
            <LanguageButtons variant="small" />
          </div>

          <div className="w3-container w3-center">
            <img src={ptLogo} style={{ width: "100%" }} alt={''}/>
          </div>

          <form className="w3-container" onSubmit={handleSubmit}>
            <div className="w3-section">
              {loginFailed && (
                <div className="w3-panel w3-round w3-pale-red w3-border-red w3-text-red">
                  <p>{t(loginFailed)}</p>
                </div>
              )}

              <label htmlFor="username">
                <b>{t("username")}</b>
              </label>
              <input
                id="username"
                className="w3-input w3-border w3-margin-bottom"
                type="text"
                placeholder={t("enter_username")}
                name="username"
                required
              />
              <label htmlFor="password">
                <b>{t("password")}</b>
              </label>
              <input
                id="password"
                className="w3-input w3-border"
                type="password"
                placeholder={t("enter_password")}
                name="password"
                required
              />
              <br />
              <button
                className="w3-button w3-block w3-light-blue w3-section w3-padding w3-round-medium"
                type="submit"
                disabled={waitingForLoginResponse}
              >
                {t("login")}
              </button>
            </div>

            <div className="w3-right w3-text-white w3-small w3-right-align w3-margin-bottom">
              <div>Version {AppConstants.About.appVersion}</div>
            </div>
          </form>
        </div>
      </div>
      <div className={'w3-display-middle'} style={{zIndex: 100}}>
        <SyncLoader color={'#87CEEB'} loading={waitingForLoginResponse}/>
      </div>
    </>
  );
};

export default LoginPage;
