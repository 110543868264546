import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, useRef, useState } from "react";
import DropdownPortal from "./DropdownPortal";

interface Props {
  style?: CSSProperties;
  className?: string;
}

const DotMenu: React.FC<Props> = (props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [menuOptions, setMenuOptions] = useState<{
    x?: number | undefined;
    y?: number | undefined;
    show: boolean;
  }>({ show: false });

  return (
    <>
      <div onClick={(e) => {setMenuOptions({ show: false })}}>
        <button
          className={`w3-button ${menuOptions.show ? "w3-theme-l3" : ""} ${props.className}`}
          style={{ padding: "8px", ...props.style }}
          ref={buttonRef}
          onClick={(e) => {
            e.stopPropagation();
            const position = buttonRef.current?.getBoundingClientRect();

            // We open the menu just below the dot-menu
            position &&
              setMenuOptions({
                x: position.x,
                y: position.y + position.height,
                show: true,
              });
          }}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </button>
        {/*<div className={'w3-border'} onClick={(e) => {setMenuOptions({ show: false })}}>*/}
        {/*</div>*/}
      </div>
      {menuOptions.show && (
          <DropdownPortal
              x={menuOptions.x}
              y={menuOptions.y}
              onDismiss={() => setMenuOptions({ show: false })}
          >
            {props.children}
          </DropdownPortal>
      )}
    </>
  );
};

export default DotMenu;
