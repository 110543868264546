import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

import TextAndImage from "./Widgets/TextAndImage";
import {VideoResourceUrl} from "../lib/VideoResourceUrl";

/**
 * Section Viewer side menu help
 *
 * @param props
 * @constructor
 */

const Help_CesiumView_SideMenu_SectionViewer: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <>
        <div className={'w3-panel'}>
          <h2 className={"w3-left-align"}>{t("The 'Section Viewer' Side Menu")}</h2>
          <p className={"text-full-justify"}>
            {t("This section allows you to adjust the display parameters of the block model orthogonal section planes")}
          </p>
        </div>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Show Horizontal Section"}
                      text={"Use the 'Show Horizontal Section' toggle switch to toggle " +
            "the visibility of the horizontal section plane of the block model.  When turned 'ON', ome layers may become hidden."}
                      src={VideoResourceUrl("cs_visibility_xy.webm")}
                      imgRatio={"w3-half"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Show North/South Section"}
                      text={"Use the 'Show North/South Section' toggle switch to toggle " +
            "the visibility of the north/south perpendicular section plane of the block model.  When turned 'ON', ome layers may become hidden."}
                      src={VideoResourceUrl("cs_visibility_xz.webm")}
                      imgRatio={"w3-half"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Show East/West Section"}
                      text={"Use the 'Show East/West Section' toggle switch to toggle " +
            "the visibility of the east/west perpendicular section plane of the block model.  When turned 'ON', ome layers may become hidden."}
                      src={VideoResourceUrl("cs_visibility_yz.webm")}
                      imgRatio={"w3-half"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage title={"Section Distance threshold"}
                      text={"Adjust the slider ('Ignore Blastholes Beyond...') to " +
            "show/hide holes, based on their distance from the section plane"}
                      src={VideoResourceUrl("cs_dist_threshold.webm")}
                      imgRatio={"w3-half"}/>

      </>
  )
}

export default withTranslation()(Help_CesiumView_SideMenu_SectionViewer);

