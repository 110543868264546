import React, {useEffect} from "react";

interface IScaleSvgProps {
  text: string;
  textColorCss: string;
  width: number;
  height: number;
  fontSize: number;
  margin: number;
}

const ScaleSvg: React.FC<IScaleSvgProps> = (props) => {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width}
          height={props.height}
          viewBox={`0 0 ${props.width} ${props.height}`}
      >
        <text
            x="50%"
            textAnchor="middle"
            y={props.fontSize + 2}
            className={"unselectable"}
            style={{
              fill: props.textColorCss,
              fontSize: `${props.fontSize}px`
            }}
        >
          {props.text}
        </text>
        <line
            style={{
              stroke: props.textColorCss,
              strokeWidth: "3"
            }}
            x1={props.margin}
            y1={props.fontSize + 6}
            x2={props.width - props.margin}
            y2={props.fontSize + 6}
        />
      </svg>
  );
}

export default ScaleSvg;