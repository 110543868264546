import React, {useMemo} from "react";

interface IProps {
  backColor?:string;
  foreColor?:string;
  position: {x:number, y:number};
  orientation: "left"|"top"|"right"|"bottom";
  content: React.ReactNode;
  skew?: "none"|"left"|"up"|"right"|"down";
}

const ARROW_WIDTH = 16;
const ARROW_HEIGHT = 8;

const TooltipContainer: React.FC<IProps> = (props) => {

  // console.log(`TooltipContainer: position=${JSON.stringify(props.position)}`);
  return (
      <div
          style={{
            zIndex: 2
          }}
      >
        <Bubble
            backColor={props.backColor}
            position={props.position}
            orientation={props.orientation}
            content={props.content}
        />
        <Arrow
            backColor={props.backColor}
            position={props.position}
            orientation={props.orientation}
            skew={props.skew}
        />
      </div>
  )
}

export default TooltipContainer;

interface IBubbleProps {
  backColor?: string;
  foreColor?:string;
  position: {x:number, y:number};
  orientation: "left"|"top"|"right"|"bottom";
  content: React.ReactNode;
}

const Bubble: React.FC<IBubbleProps> = (props) => {
  const backColor = props.backColor ?? 'white';

  const foreColor = props.foreColor ? { color: props.foreColor } : {color: 'black'};

  const style = useMemo((): any =>{
    switch (props.orientation) {
      case "left":
        return {
          left: `${props.position.x - ARROW_WIDTH}px`,
          top: `${props.position.y}px`,
          transform: "translate(-100%,-50%)"
        };
      case "top":
        return {
          left: `${props.position.x}px`,
          top: `${props.position.y - ARROW_HEIGHT}px`,
          transform: "translate(-50%,-100%)"
        };
      case "right":
        return {
          left: `${props.position.x + ARROW_WIDTH}px`,
          top: `${props.position.y}px`,
          transform: "translate(0%,-50%)"
        };
      case "bottom":
        return {
          left: `${props.position.x}px`,
          top: `${props.position.y + ARROW_HEIGHT}px`,
          transform: "translate(-50%,0%)"
        };
    }
  }, [props.orientation, props.position]);

  return (
      <span
          className={"w3-round w3-round-small w3-bar-item w3-padding-small"}
          style={{
            ...style,
            ...foreColor,
            float: "none",
            position: "absolute",
            background: `${backColor}`,
            borderColor: `${backColor}`,
            borderWidth: "1px"
          }}
      >
        {props.content}
      </span>
  )
}

interface IArrowProps {
  backColor?: string;
  position: {x:number, y:number};
  orientation: "left"|"top"|"right"|"bottom";
  skew?: "none"|"left"|"up"|"right"|"down";
}

const Arrow: React.FC<IArrowProps> = (props) => {
  const orientation = props.orientation;
  const skew = props.skew ?? "none";
  const position = props.position;
  const backColor = props.backColor ?? 'white';

  const xOffset = useMemo(()=>{
    switch( skew ) {
      case "none":
        return 0;
      case "left":
        return ARROW_WIDTH/2;
      case "up":
        return 0;
      case "right":
        return -ARROW_WIDTH/2;
      case "down":
        return 0;
    }
  }, [skew]);

  const yOffset = useMemo(()=>{
    switch( skew ) {
      case "none":
        return 0;
      case "left":
        return 0;
      case "up":
        return ARROW_HEIGHT/2;
      case "right":
        return 0;
      case "down":
        return -ARROW_HEIGHT/2;
    }
  }, [skew]);

  const arrowTipCoords = useMemo((): string=>{

    switch( orientation ) {
      case "left":
        return `0,0 ${ARROW_WIDTH},${yOffset + ARROW_HEIGHT/2} 0,${ARROW_HEIGHT}` ;
      case "top":
        return `${xOffset + ARROW_WIDTH/2},${ARROW_HEIGHT} 0,0 ${ARROW_WIDTH},0` ;
      case "right":
        return `${ARROW_WIDTH},0 ${ARROW_WIDTH},${ARROW_HEIGHT} 0,${yOffset + ARROW_HEIGHT/2}` ;
      case "bottom":
        return `${xOffset + ARROW_WIDTH/2},0 ${ARROW_WIDTH},${ARROW_HEIGHT} 0,${ARROW_HEIGHT}` ;
    }
  }, [orientation, xOffset, yOffset]);

  const style = useMemo((): any =>{
    switch (orientation) {
      case "left":
        return {
          left: `${props.position.x - ARROW_WIDTH}px`,
          top: `${props.position.y - ARROW_HEIGHT/2 - yOffset}px`,
        };
      case "top":
        return {
          left: `${props.position.x - ARROW_WIDTH/2 - xOffset}px`,
          top: `${props.position.y - ARROW_HEIGHT}px`,
        };
      case "right":
        return {
          left: `${props.position.x}px`,
          top: `${props.position.y - ARROW_HEIGHT/2 - yOffset}px`,
        };
      case "bottom":
        return {
          left: `${props.position.x - ARROW_WIDTH/2 - xOffset}px`,
          top: `${props.position.y}px`,
        };
    }
  }, [orientation, position, xOffset, yOffset]);

  return (
      <svg
          className={"w3-opacity-min"}
          style={{
            ...style,
            float: "none",
            position: "absolute",
          }}
          height={ARROW_HEIGHT}
          width={ARROW_WIDTH}
      >
        <polygon points={arrowTipCoords} style={{
          fill:`${backColor}`,
          stroke:`${backColor}`,
          strokeWidth: "1"
        }}/>
        Sorry, your browser does not support inline SVG.
      </svg>
  )
}