import React from "react";

export interface ICell {
  content: React.ReactNode;
  className?: string;
  onClick?: ()=>void;
}

interface IProps {
  cols:number;
  rows:number;
  contents: ICell[];
  // cellClasses: (string|undefined)[];
}

const ResponsiveSquareGrid: React.FC<IProps> = (props)=>{

  const rowAspectRatio = `${props.cols}`;
  const colWidth = `${(100 / props.cols).toFixed(0)}%`;
// console.log(`rowAspectRatio=${rowAspectRatio}, colWidth=${colWidth}`);
  return (
      <div className={'w3-container'}>
        {
          Array.from(Array(props.rows), (row, rowIdx) => (
              <div
                  key={`grid_row_${rowIdx}`}
                  className={'w3-cell-row'}
                  style={{aspectRatio: rowAspectRatio}}
              >
                {
                  Array.from(Array(props.cols), (col, colIdx) => {

                    const arrIdx = rowIdx * props.cols + colIdx ;
                    const cell = props.contents[arrIdx].content ;
                    const cellClass = props.contents[arrIdx].className ;
                    const onClick = props.contents[arrIdx].onClick ;

                    return (
                      <span key={`grid_cell_${rowIdx}_${colIdx}`}
                          className={`unselectable w3-cell w3-cell-middle ${cell ? cellClass: undefined}`}
                          style={{width: colWidth}}
                          onClick={onClick}
                      >
                        {cell}
                      </span>
                    )
                  })
                }
              </div>
          ))
        }
      </div>
  )
}

export default ResponsiveSquareGrid ;
