import React from "react";
import ITaskProgress from "../../model/ITaskProgress";
import {withTranslation, WithTranslation} from "react-i18next";
import {t} from "i18next";

interface IProgressDialogProps extends WithTranslation {
    title: string;
    tasks: Array<ITaskProgress>;
    onDismiss?: (()=>void)|undefined;
}

const BORDER_RADIUS = "5px";

const ProgressDialog: React.FC<IProgressDialogProps> =
({
    title,
    tasks,
    onDismiss,
}) => {
    return (
        <>
            <div className="w3-modal" style={{display: "block"}}>
                <div className="w3-modal-content w3-card-4 w3-theme-d4" style={{borderRadius: BORDER_RADIUS}}>
                    <header className="w3-container w3-light-blue w3-center" style={{borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0px 0px`}}>
                        <h6>{t(title)}</h6>
                    </header>
                    {
                        tasks && tasks.map((taskProgress, idx)=>(
                            <div key={"progress_"+idx} className="w3-section w3-container">
                                <div className="" style={{fontSize: "12px"}}>{t(taskProgress.text)}</div>
                                <div className="w3-light-grey w3-tiny w3-theme-d4">
                                    <div className="w3-container w3-light-blue w3-center"
                                         style={{width: `${taskProgress.percent}%`}}>{taskProgress.percent.toFixed(0) + "%"}</div>
                                </div>
                            </div>
                        ))
                    }
                    <footer className="w3-container w3-theme-d3 w3-padding w3-center" style={{borderRadius: `0px 0px ${BORDER_RADIUS} ${BORDER_RADIUS}`}}>
                        {onDismiss &&
                            <button className="w3-btn w3-grey w3-round-medium" onClick={onDismiss}>{t('Dismiss')}</button>
                        }
                    </footer>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(ProgressDialog) ;
