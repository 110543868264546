import {useCallback} from "react";
import {BoundingSphere, Camera, HeadingPitchRange, Matrix4} from "cesium";
import {CameraFrustum} from "../domain/CameraFrustum";
import {OrthogonalDirection} from "../../../model/OrthogonalDirection";

export interface ICameraControlActions {
  setCameraFrustum: (camera:Camera, value: CameraFrustum)=>void,
  focusCamera: (camera:Camera, boundingSphere: BoundingSphere, hpr: HeadingPitchRange)=>void,
  resetCamera: (camera:Camera, boundingSphere: BoundingSphere)=>void,
  viewOrthogonalDirection: (camera:Camera, boundingSphere: BoundingSphere, direction: OrthogonalDirection, range: number)=>void,
  // setView: (camera:Camera, vp: IVantagePoint)=>void,
}

export function getHeadingPitchRange(direction: OrthogonalDirection, range: number): HeadingPitchRange {
  console.log(`getHeadingPitchRange: ${OrthogonalDirection[direction]}`);
  switch(direction) {
    case OrthogonalDirection.Up:
      return new HeadingPitchRange(0, Math.PI / 2, range);
    case OrthogonalDirection.Down:
      return new HeadingPitchRange(0, -Math.PI / 2, range);
    case OrthogonalDirection.North:
      return new HeadingPitchRange(0, 0, range);
    case OrthogonalDirection.South:
      return new HeadingPitchRange(Math.PI, 0, range);
    case OrthogonalDirection.East:
      return new HeadingPitchRange(Math.PI / 2, 0, range);
    case OrthogonalDirection.West:
      return new HeadingPitchRange(-Math.PI / 2, 0, range);
    default: throw new Error(`useCameraControls.getHeadingPitchRange: unsupported OrthogonalDirection: ${direction}`)
  }
}

export const useCameraControls = (): ICameraControlActions => {
  /**
   * Focuses the camera as per the given heading-pitch-range
   */
  const focusCamera = useCallback( (camera:Camera, boundingSphere: BoundingSphere, hpr: HeadingPitchRange) => {
    if (camera) {
      // console.log(`flyToBoundingSphere: ${JSON.stringify(boundingSphere)}`)
      camera.flyToBoundingSphere( boundingSphere, { duration: 0.5, offset: hpr } );
    } else {
      console.log(`resetCamera: no camera or bounding sphere`);
    }
  }, [])

  /**
   * Resets the camera to look at the tilesets
   */
  const resetCamera = useCallback((camera:Camera, boundingSphere: BoundingSphere, vantagePoint?: HeadingPitchRange)=>{
    let hpr = vantagePoint ?? new HeadingPitchRange(-Math.PI/4, -Math.PI / 4, 2 * boundingSphere.radius);
    focusCamera(camera, boundingSphere, hpr );
  }, [focusCamera])

  /**
   * Focuses the camera to look at the tilesets as per the given direction.
   */
  const viewOrthogonalDirection = useCallback((camera:Camera, boundingSphere: BoundingSphere, direction: OrthogonalDirection, range: number = 500)=>{
    if (camera) {
      focusCamera(camera, boundingSphere, getHeadingPitchRange(direction, range));
      camera.lookAtTransform(Matrix4.IDENTITY);
    } else {
      console.log(`resetCamera: no camera`);
    }
  }, [focusCamera])

  /**
   * Changes the camera frustum
   */
  const setCameraFrustum = useCallback((camera:Camera, value: CameraFrustum)=>{
    if ( camera ) {
      switch( value ) {
        case CameraFrustum.PERSPECTIVE:
          camera.switchToPerspectiveFrustum();
          break;
        case CameraFrustum.ORTHOGRAPHIC:
          camera.switchToOrthographicFrustum();
          break;
      }
    }
  }, []);

  // const setView = useCallback((camera:Camera, vp: IVantagePoint)=>{
  //     console.log(`useCameraControls.setView: setting initial camera vantage point to ${JSON.stringify(vp)}`);
  //     camera.setView({
  //       destination: new Cartesian3(
  //           vp.position.x,
  //           vp.position.y,
  //           vp.position.z
  //       ),
  //       orientation: {
  //         heading: vp.heading,
  //         pitch: vp.pitch,
  //         roll: 0.0
  //       }
  //     });
  // }, [] );

  return {
    setCameraFrustum,
    focusCamera,
    resetCamera,
    viewOrthogonalDirection,
    // setView
  }
}
