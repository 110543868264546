import { useTranslation } from "react-i18next";

interface Props {
  variant: "small" | "navbar";
}

const LanguageButtons: React.FC<Props> = ({ variant }) => {
  const { i18n } = useTranslation();

  const className = `w3-button ${variant === "small" && "w3-small"}`;

  return (
    <>
      <div
        className={className}
        onClick={() => i18n.changeLanguage("fr")}
        style={{
          textDecoration: i18n.language === "fr" ? "underline" : "",
        }}
      >
        FR
      </div>
      <div
        className={className}
        onClick={() => i18n.changeLanguage("en")}
        style={{
          textDecoration: i18n.language === "en" ? "underline" : "",
        }}
      >
        EN
      </div>
    </>
  );
};
export default LanguageButtons;
