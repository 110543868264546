import React, {useEffect, useMemo, useState} from "react";
import {Cartesian3, Cesium3DTileFeature, Color, Entity as CesiumEntity, Matrix4} from "cesium";
import {LayerType} from "../../../../model/LayerType";
import WireframeBox from "./WireframeBox";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import FilledRectangle from "./FilledRectangle";
import {TilesetUtils} from "../../util/TilesetUtils";

interface IProps {
  item: Cesium3DTileFeature|CesiumEntity;
  color: Color;
  modelMatrix: Matrix4;
  originReference: Cartesian3;
  blockSize: Cartesian3;
}
interface IFeatureProps {
  feature: Cesium3DTileFeature
  color: Color;
  modelMatrix: Matrix4;
  originReference: Cartesian3;
  blockSize: Cartesian3;
}
interface IEntityProps {
  entity: CesiumEntity
  color: Color;
  modelMatrix: Matrix4;
  originReference: Cartesian3;
  blockSize: Cartesian3;
}

export const ITEM_HIGHLIGHT_ENTITY = "ItemHighlight";
const ARBITRARY_HOLE_RADIUS = 1;//0.3048;
const ARBITRARY_VERTICAL_SPACING = 0.025;

/**
 * Generic item highlighting
 * @param props
 * @constructor
 */
const ItemHighlight: React.FC<IProps> = (props) => {

  // console.log(`ItemHighlight: ${getTargetId(props.item)}`);

  return ( props.item instanceof Cesium3DTileFeature )
  ?    <Feature
          feature={props.item/* as Cesium3DTileFeature*/}
          color={props.color}
          modelMatrix={props.modelMatrix}
          originReference={props.originReference}
          blockSize={props.blockSize}
      />
  :   <CesiumEntityComponent
          entity={props.item/* as CesiumEntity*/}
          color={props.color}
          modelMatrix={props.modelMatrix}
          originReference={props.originReference}
          blockSize={props.blockSize}
      />
}

export default ItemHighlight;

/**
 * Generic Cesium feature highlighting
 * @param props
 * @constructor
 */
const Feature: React.FC<IFeatureProps> = (props) => {

  const feature = props.feature ;

  const layerTypeStr = useMemo(()=>{
    let str = feature.getProperty("Layer");
    if ( !str ) {
      console.warn(`Feature: Error! no Layer property in feature for ${feature.tileset.asset.name}` );
      return undefined ;
    }

    if ( !(str in LayerType) ) {
      console.warn(`Feature: Error! ${str} not a valid LayerType enum key` );
      return undefined ;
    }

    return str;

  }, [feature]);

  return (
      <>
        { layerTypeStr && {
              'BlockModel': <BlockModelFeature {...props}/>,
              'BlastholeSecondarySegments': <SecondarySegmentFeature {...props}/>,
              'BlastholeFractures': <FractureFeature {...props}/>,
              'CrossSectionX': <CrossSectionFeature {...props}/>,
              'CrossSectionY': <CrossSectionFeature {...props}/>,
              'CrossSectionZ': <CrossSectionFeature {...props}/>,
            }[layerTypeStr as string]
        }
      </>
  )
}

/**
 * Block Model feature highlighting
 * @param props
 * @constructor
 */
const BlockModelFeature: React.FC<IFeatureProps> = (props) => {
  const feature = props.feature;
  // const originReference = props.originReference;
  const blockSize = props.blockSize;
  const modelMatrix = props.modelMatrix;
  const color = props.color;

  let relativeMinPos = useMemo(()=>getOffsetCoordinateFromFeature(feature, "OffsetXYZ"), [feature]);
  let relativeMaxPos = useMemo(()=>{
      return relativeMinPos ? new Cartesian3(
          relativeMinPos.x + blockSize.x,
          relativeMinPos.y + blockSize.y,
          relativeMinPos.z + blockSize.z,
      ) : undefined ;
  }, [relativeMinPos]);

  // const [viewModel] = useViewModelV2();
  const [userSession] = useUserSessionContext();

  return (
      <>
        { relativeMinPos && relativeMaxPos && userSession.layersVisibility.BlockModel && (//viewModel.layerStyles.BlockModel.visibility && (
            <WireframeBox
                modelMatrix={modelMatrix}
                color={color}
                show={true}
                description={`Feature ${feature.featureId} highlight`}
                name={`${ITEM_HIGHLIGHT_ENTITY}_BlockModelFeature`}
                minPosECEF={relativeMinPos}
                maxPosECEF={relativeMaxPos}
                lineWidth={2}
            />
        )}
      </>
  )
}

/**
 * Cross Section feature highlighting
 * @param props
 * @constructor
 */
const CrossSectionFeature: React.FC<IFeatureProps> = (props) => {
  const [userSession] = useUserSessionContext();
  const feature = props.feature;
  const originReference = props.originReference;
  const blockSize = props.blockSize;
  const modelMatrix = props.modelMatrix;
  const color = props.color;

  const relMinPos = useMemo(()=>getOffsetCoordinateFromFeature(feature, "OffsetXYZ"), [feature]) ;
  const relMinIdx = useMemo(()=>getOffsetCoordinateFromFeature(feature, "OffsetIJK"), [feature]) ;
  const layerType = useMemo(()=>LayerType[ feature.getProperty("Layer") as keyof typeof LayerType ], [feature]);

  const vertices = useMemo((): Cartesian3[]|undefined=>{

    if ( !relMinPos || !relMinIdx || !layerType || !userSession.boundingBox ) {
      return undefined;
    }

    let result: Cartesian3[]|undefined ;

    switch( layerType ) {
      case LayerType.CrossSectionX:
        const x = userSession.boundingBox.minPos.x + userSession.crossSectionOffsetX
        result = [
          Cartesian3.fromArray( [ x, relMinPos.y,               relMinPos.z ]),
          Cartesian3.fromArray( [ x, relMinPos.y,               relMinPos.z + blockSize.z ]),
          Cartesian3.fromArray( [ x, relMinPos.y + blockSize.y, relMinPos.z + blockSize.z ]),
          Cartesian3.fromArray( [ x, relMinPos.y + blockSize.y, relMinPos.z ]),
        ]
        break;

      case LayerType.CrossSectionY:
        const y = userSession.boundingBox.minPos.y + userSession.crossSectionOffsetY
        result = [
          Cartesian3.fromArray( [ relMinPos.x,               y, relMinPos.z ]),
          Cartesian3.fromArray( [ relMinPos.x,               y, relMinPos.z + blockSize.z ]),
          Cartesian3.fromArray( [ relMinPos.x + blockSize.x, y, relMinPos.z + blockSize.z ]),
          Cartesian3.fromArray( [ relMinPos.x + blockSize.x, y, relMinPos.z ]),
        ]
        break;

      case LayerType.CrossSectionZ:
        const z = userSession.boundingBox.minPos.z + userSession.crossSectionOffsetZ
        result = [
          Cartesian3.fromArray( [ relMinPos.x,               relMinPos.y,               z ]),
          Cartesian3.fromArray( [ relMinPos.x,               relMinPos.y + blockSize.y, z ]),
          Cartesian3.fromArray( [ relMinPos.x + blockSize.x, relMinPos.y + blockSize.y, z ]),
          Cartesian3.fromArray( [ relMinPos.x + blockSize.x, relMinPos.y,               z ]),
        ]
        break;
    }

    return result;

  }, [
    relMinPos, relMinIdx, layerType,
    originReference,
    userSession.crossSectionOffsetX,
    userSession.crossSectionOffsetY,
    userSession.crossSectionOffsetZ,
    userSession.boundingBox,
    blockSize
  ]);

  return (
      <>
        {vertices &&
            <FilledRectangle
                show={true}
                name={`${feature.featureId}`}
                outline={true}
                outlineColor={color}
                outlineWidth={3}
                fillColor={Color.WHITE.withAlpha(.25)}
                modelMatrix={modelMatrix}
                ecefVertices={vertices}
            />
        }
      </>
  )
}

/**
 * Blasthole segment feature highlighting
 * @param props
 * @constructor
 */
const SecondarySegmentFeature: React.FC<IFeatureProps> = (props) => {
  const feature = props.feature;
  const originReference = props.originReference;
  const modelMatrix = props.modelMatrix;
  const color = props.color;
  const tileset = useMemo(()=>feature.tileset, [feature]);
  const [patternId, setPatternId] = useState<string>();
  const [holeId, setHoleId] = useState<string>();
  const [relativeMinPos, setRelativeMinPos] = useState<Cartesian3>();
  const [relativeMaxPos, setRelativeMaxPos] = useState<Cartesian3>();

  // ... Extract hole and pattern IDs
  useEffect(()=>{
    const p = feature.getProperty("Pattern");
    p && setPatternId( p );

    const h = feature.getProperty("HoleID");
    h && setHoleId( h );

  }, [feature]);

  // ... For all secondary segments that are part of the same hole,
  // capture the top and bottom (to get entire hole)
  useEffect(()=>{
    if ( patternId && holeId && tileset && originReference ) {

      let localMaxPos: Cartesian3|undefined = undefined;
      let localMinPos: Cartesian3|undefined = undefined;

      TilesetUtils.visitFeatures(tileset, f => {
        const p = f.getProperty("Pattern");
        const h = f.getProperty("HoleID");
        if (p === patternId && h === holeId  ) {

          // ... Update min & max
          let localCoord = getCoordinateFromFeatureXYZ(f, "x", "y", "from_z");
          if (localCoord) {
            if ( !localMaxPos || localCoord.z > localMaxPos.z) {
              localMaxPos = localCoord;
              setRelativeMaxPos(new Cartesian3(
                  localCoord.x - originReference.x + ARBITRARY_HOLE_RADIUS,
                  localCoord.y - originReference.y + ARBITRARY_HOLE_RADIUS,
                  localCoord.z - originReference.z
              ));
            }
          }

          localCoord = getCoordinateFromFeatureXYZ(f, "x", "y", "to_z");
          if (localCoord) {
            if ( !localMinPos || localCoord.z < localMinPos.z) {
              localMinPos = localCoord;
              setRelativeMinPos(new Cartesian3(
                  localCoord.x - originReference.x - ARBITRARY_HOLE_RADIUS,
                  localCoord.y - originReference.y - ARBITRARY_HOLE_RADIUS,
                  localCoord.z - originReference.z
              ));
            }
          }
        }
      });
    }
  }, [patternId, holeId, tileset, originReference]);



  // let relativeMaxPos = useMemo( ()=>{
  //   if ( originReference ) {
  //     let localCoord = getCoordinateFromFeatureXYZ(feature, "x", "y", "from_z");
  //     if (localCoord) {
  //       return new Cartesian3(
  //           localCoord.x - originReference.x + ARBITRARY_HOLE_RADIUS,
  //           localCoord.y - originReference.y + ARBITRARY_HOLE_RADIUS,
  //           localCoord.z - originReference.z
  //       );
  //     }
  //   }
  //   return undefined;
  // }, [feature, originReference]);
  //
  // let relativeMinPos = useMemo( ()=>{
  //   if ( originReference ) {
  //     let localCoord = getCoordinateFromFeatureXYZ(feature, "x", "y", "to_z");
  //     if (localCoord) {
  //       return new Cartesian3(
  //           localCoord.x - originReference.x - ARBITRARY_HOLE_RADIUS,
  //           localCoord.y - originReference.y - ARBITRARY_HOLE_RADIUS,
  //           localCoord.z - originReference.z
  //       );
  //     }
  //   }
  //   return undefined ;
  // }, [feature, originReference]);

  return (
      <>
        { relativeMinPos && relativeMaxPos && (
            <WireframeBox
                modelMatrix={modelMatrix}
                color={color}
                show={true}
                description={`Feature ${feature.featureId} highlight`}
                name={`${ITEM_HIGHLIGHT_ENTITY}_SecondarySegmentFeature`}
                minPosECEF={relativeMinPos}
                maxPosECEF={relativeMaxPos}
                lineWidth={1}
            />
        )}
      </>
  )
}

/**
 * Blasthole fracture feature highlighting
 * @param props
 * @constructor
 */
const FractureFeature: React.FC<IFeatureProps> = (props) => {
  const feature = props.feature;
  const originReference = props.originReference;
  const modelMatrix = props.modelMatrix;
  const color = props.color;
  const radius = 3;//0.25 + feature.getProperty("Prominence")*0.05; // magic numbers that look better

  // console.log(`Must highlight a FractureFeature`);

  let fracturePos = useMemo( ()=>{
    if ( originReference ) {
      let localCoord = getCoordinateFromFeatureXYZ(feature, "x", "y", "z");
      if (localCoord) {
        return new Cartesian3(
            localCoord.x - originReference.x,
            localCoord.y - originReference.y,
            localCoord.z - originReference.z
        );
      }
    }
    return undefined;
  }, [feature, originReference]);

  let relativeMaxPos = useMemo( ()=>{
    return fracturePos ?
        new Cartesian3(
            fracturePos.x+radius,
            fracturePos.y+radius,
            fracturePos.z + ARBITRARY_VERTICAL_SPACING ) : undefined;
  }, [fracturePos]);

  let relativeMinPos = useMemo( ()=>{
    return fracturePos ?
        new Cartesian3(
            fracturePos.x-radius,
            fracturePos.y-radius,
            fracturePos.z - ARBITRARY_VERTICAL_SPACING ) : undefined;
  }, [fracturePos]);

  return (
      <>
        { relativeMinPos && relativeMaxPos && (
            <WireframeBox
                modelMatrix={modelMatrix}
                color={color}
                show={true}
                description={`Feature ${feature.featureId} highlight`}
                name={`${ITEM_HIGHLIGHT_ENTITY}_FractureFeature`}
                minPosECEF={relativeMinPos}
                maxPosECEF={relativeMaxPos}
                lineWidth={1}
            />
        )}
      </>
  )
}

/**
 * Generic entity highlighting
 * @param props
 * @constructor
 */
const CesiumEntityComponent: React.FC<IEntityProps> = (props) => {

  // const layerType = getTargetLayer(props.entity);
  // console.log(`CesiumEntityComponent: ${getTargetId(props.entity)}, layerType=${layerType && LayerType[layerType]}`);

  const layerName: string|undefined = useMemo(()=>{
    return props.entity.properties ? props.entity.properties["Layer"] : undefined;
  }, [props.entity]);


  return (
      <>
        { layerName &&
            {
              'BlastholeSingleSegment': <BlastholeSingleSegmentEntity {...props}/>,
            }[layerName]
        }
      </>
  )
}

/**
 * Collar entity highlighting
 * @param props
 * @constructor
 */
const BlastholeSingleSegmentEntity: React.FC<IEntityProps> = (props) => {
  const [userSession] = useUserSessionContext();
  const entity = props.entity ;
  const originReference = props.originReference;
  const modelMatrix = props.modelMatrix;
  const color = props.color;

  // console.log(`BlastholeSingleSegmentEntity: ${getTargetId(props.entity)}`);

  let relativeMaxPos = useMemo( ()=>{
    const radius = 1;//userSession.collarStyle.collarDiameter / 2;
    if ( originReference ) {
      let localCoord = getCoordinateFromEntityXYZ(entity, "x", "y", "from_z");
      if (localCoord) {
        return new Cartesian3(
            localCoord.x - originReference.x + radius,
            localCoord.y - originReference.y + radius,
            localCoord.z - originReference.z
        );
      }
    }
    return undefined;
  }, [entity, originReference, userSession.collarStyle]);

  let relativeMinPos = useMemo( ()=>{
    const radius = 1;//userSession.collarStyle.collarDiameter / 2;
    if ( originReference ) {
      let localCoord = getCoordinateFromEntityXYZ(entity, "x", "y", "to_z");
      if (localCoord) {
        return new Cartesian3(
            localCoord.x - originReference.x - radius,
            localCoord.y - originReference.y - radius,
            localCoord.z - originReference.z
        );
      }
    }
    return undefined ;
  }, [entity, originReference, userSession.collarStyle]);

  return (
      <>
        { relativeMinPos && relativeMaxPos && (
            <WireframeBox
                modelMatrix={modelMatrix}
                color={color}
                show={true}
                description={`Entity ${entity.id} highlight`}
                name={`${ITEM_HIGHLIGHT_ENTITY}_BlastholeSingleSegmentEntity`}
                minPosECEF={relativeMinPos}
                maxPosECEF={relativeMaxPos}
                lineWidth={1}
            />
        )}
      </>
  )
}

function getRelativeCoordinateFromFeature( feature: Cesium3DTileFeature, propertyName: string, originReference: Cartesian3 ): Cartesian3|undefined {
  const coordinate = getCoordinateFromFeature( feature, propertyName );
  return coordinate ? new Cartesian3(
      coordinate.x - originReference.x,
      coordinate.y - originReference.y,
      coordinate.z - originReference.z,
  ) : undefined ;
}
function getOffsetCoordinateFromFeature( feature: Cesium3DTileFeature, propertyName: string ): Cartesian3|undefined {
  const offset = getCoordinateFromFeature( feature, propertyName );
  return offset ? new Cartesian3( offset.x, offset.y, offset.z ) : undefined ;
}
function getCoordinateFromFeature( feature: Cesium3DTileFeature, propertyName: string ): Cartesian3|undefined {
  let property = feature.getProperty( propertyName );
  if ( !property ) {
    console.error(`getRelativeCoordinateFromFeature: feature ${feature.featureId} has no property named '${propertyName}'`);
    return undefined ;
  }
  if ( ! Array.isArray( property ) ) {
    console.error(`getRelativeCoordinateFromFeature: property ${property} is not an array`);
    return undefined ;
  }

  if ( property.length !== 3 ) {
    console.error(`getRelativeCoordinateFromFeature: property ${propertyName} has an invalid array length`);
    return undefined ;
  }

  return Cartesian3.fromArray( property );
}

function getCoordinateFromFeatureXYZ( feature: Cesium3DTileFeature, propertyX: string, propertyY: string, propertyZ: string ): Cartesian3|undefined {
  let x = feature.getProperty(propertyX);
  if (!x) {
    console.error(`getRelativeCoordinateFromFeature: feature ${feature.featureId} has no property named '${propertyX}'`);
    return undefined;
  }
  let y = feature.getProperty(propertyY);
  if (!y) {
    console.error(`getRelativeCoordinateFromFeature: feature ${feature.featureId} has no property named '${propertyY}'`);
    return undefined;
  }
  let z = feature.getProperty(propertyZ);
  if (!z) {
    console.error(`getRelativeCoordinateFromFeature: feature ${feature.featureId} has no property named '${propertyZ}'`);
    return undefined;
  }

  return new Cartesian3( x, y, z );
}
function getCoordinateFromEntityXYZ( entity: CesiumEntity, propertyX: string, propertyY: string, propertyZ: string ): Cartesian3|undefined {
  if ( !entity.properties ) {
    console.error(`getCoordinateFromEntityXYZ: entity ${entity.id} has no properties`);
    return undefined ;
  }
  const properties = entity.properties ;

  let x = properties[ propertyX ];
  if (!x) {
    console.error(`getCoordinateFromEntityXYZ: entity ${entity.id} has no property named '${propertyX}'`);
    return undefined;
  }
  let y = properties[ propertyY ];
  if (!y) {
    console.error(`getCoordinateFromEntityXYZ: entity ${entity.id} has no property named '${propertyY}'`);
    return undefined;
  }
  let z = properties[ propertyZ ];
  if (!z) {
    console.error(`getCoordinateFromEntityXYZ: entity ${entity.id} has no property named '${propertyZ}'`);
    return undefined;
  }

  return new Cartesian3( x, y, z );
}
