import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faCircleExclamation, faCircleInfo, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {t} from "i18next";
import {format} from "date-fns";
import {useNotifications} from "../Gen2/Notifications/NotificationsProvider";
import {INotification} from "../Gen2/Notifications/INotification";


interface IProps {
  className?:string|undefined;
}

const MessagesNavMenu: React.FC<IProps> = (props) => {
  const [notifications, notificationActions] = useNotifications();
  const [notificationsSubset, setNotificationsSubset] = useState<INotification[]>([]);
  const [contentsVisible, setContentsVisible] = useState<boolean>();

  // useEffect(()=>console.log(`notifications has changed`), [notifications]);
  // useEffect(()=>console.log(`notificationActions has changed`), [notificationActions]);
  // useEffect(()=>console.log(`contentsVisible has changed`), [contentsVisible]);

  const ref = useRef<HTMLDivElement>(null);

  const numUnreadMessages = useMemo(()=>{
    return notifications.filter(e=>!e.isRead).length ;
  }, [notifications]);

  // useEffect(()=>console.log(`numUnreadMessages=${numUnreadMessages}`), [numUnreadMessages]);

  // ... Initially load a subset of notifications
  useEffect(()=>{
    const subset = notifications.slice(0, 16);
    setNotificationsSubset( subset );
  }, [notifications]);

  /**
   * Capture click on the Nav menu item
   */
  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setContentsVisible( prevState => !prevState );
  }, []);

  useEffect(()=>{
    if ( contentsVisible ) {
      console.log(`modifying ${notificationsSubset.length} events...`)
      notificationsSubset
          .filter( e => !e.isRead )
          .forEach( notification => notificationActions.acknowledgeNotifications([notification]) );
    }
  }, [contentsVisible, notificationsSubset, notificationActions]);

  /**
   * Capture click outside of event list
   */
  useEffect(() => {
    const handleEventOutside = (e: MouseEvent) => {
      if ( ref.current && !ref.current.contains(e.target as HTMLElement) ) {
        setContentsVisible(false);
      }
    }
    document.addEventListener("click", handleEventOutside, true);
    return () => {
      document.removeEventListener("click", handleEventOutside, true);
    };
  }, []);

  return (
      <>
        <div
            ref={ref}
            className={`w3-dropdown-click ${props.className} ${numUnreadMessages > 0 ? "w3-text-light-blue" : ''}`}
        >
          <button
              className={`w3-button w3-mobile`}
              onClick={e=>handleClick(e)}
          >
            <div className={numUnreadMessages > 0 ? 'pulse':''}>
              <FontAwesomeIcon
                  style={{marginRight: "5px"}}
                  icon={faBell}
                  size={'lg'}
              />
            </div>
          </button>
          <div className={`w3-dropdown-content w3-card-4 w3-theme-d3 ${contentsVisible ? "w3-show":""}`}
               style={{
                 zIndex: 5,
                 maxHeight: "calc( 100% - 65px )",
                 overflowY: "auto",
                 width: "520px",
                 right: "10px"
               }}
          >
            <ul className={'w3-ul'}>
              <li className={'w3-bar'}>
                <span className={'w3-half'}>{t('Events')}</span>
                { notificationsSubset.length > 0 && (
                    <div
                        style={{userSelect: "none", cursor: "pointer"}}
                        className={'w3-half w3-right-align w3-text-light-blue'}
                        onClick={()=>{
                          setContentsVisible(false);
                          notificationActions.showDialog();
                        }}
                    >
                      {t('View all events')}
                    </div>
                )}
              </li>
              {
                notificationsSubset.length > 0
                  ? notificationsSubset.map( (message, idx) => (
                      <MessageItem
                          key={message.id}
                          {...message}
                      />
                    ))
                  : <div className={'w3-container w3-section w3-center w3-text-light-gray w3-opacity-min'}><i>({t('No events')})</i></div>
              }
            </ul>
          </div>
        </div>
      </>
  )
}

export default MessagesNavMenu;

const MessageItem: React.FC<INotification> = (props) => {

  const icon = useMemo(()=>{
    switch( props.type ) {
      case "info":
        return faCircleInfo;
      case "warning":
        return faTriangleExclamation
      case "error":
        return faCircleExclamation
    }
  }, [props.type]);

  const iconClass = useMemo(()=>{
    switch( props.type ) {
      case "info":
        return 'w3-text-light-blue';
      case "warning":
        return 'w3-text-amber';
      case "error":
        return 'w3-text-red';
    }
  }, [props.type]);

  return (
      <>
        <li
            className={`w3-bar w3-hover-dark-grey`}
            style={{
              height: "65px",
              padding: "1px 1px",
              borderBottom: "1px solid #666"
            }}
        >
          <div
              className={'w3-bar-item'}
              style={{
                width: "65px",
                height: "100%",
                padding: "15px 10px"
              }}
          >
            <FontAwesomeIcon
                style={{width: "100%", height: "100%"}}
                className={`${iconClass}`}
                icon={icon}
            />
          </div>
          <div
              className={'w3-bar-item'}
              style={{
                color: "#ddd",
                width: "calc( 100% - 65px )",
                height: "100%",
                padding: "10px 5px"
              }}
          >
            <div className={''} style={{height: "65%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight: props.isRead ? "normal":"bold"}}>
              {props.text}
            </div>
            <div className={'w3-tiny'} style={{height: "35%"}}>{format(props.timeStamp, t("LONG_DATETIME_FORMAT"))}</div>
          </div>
        </li>
      </>
  )
}
