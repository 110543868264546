import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

import Help_PatternExplorer_AvailablePatterns from "./Help_PatternExplorer_AvailablePatterns";
import Help_PatternExplorer_FilterPatterns from "./Help_PatternExplorer_FilterPatterns";
import Help_PatternExplorer_RecentSessions from "./Help_PatternExplorer_RecentSessions";

/**
 * Help page for using the Pattern Explorer page.
 *
 * @param props
 * @constructor
 */

const Help_PatternExplorer: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <div className={"w3-container"}>
        <h1>{t('Using the Pattern Explorer')}</h1>

        <Help_PatternExplorer_AvailablePatterns />

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <Help_PatternExplorer_FilterPatterns />

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <Help_PatternExplorer_RecentSessions />

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

      </div>
  )
}

export default withTranslation()(Help_PatternExplorer);
