import React, {useState} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {t} from "i18next";
import VMultiSplitPanelItem, {VMultiSplitPanelItemOptions} from "../VMultiSplitPanel/VMultiSplitPanelItem";
import VMultiSplitPanel from "../VMultiSplitPanel/VMultiSplitPanel";
import IColorLegendConfiguration from "../Gen2/domain/IColorLegendConfiguration";

interface IColorLegendEnumsConfigProps extends WithTranslation {
    id?:string;
    legendConfig: IColorLegendConfiguration|null,
    onColorBoxClicked?: (itemIndex: number, color: string, callback: ((updatedColor: string) => void )|undefined)=>void;
    onLabelEdit: (itemIndex: number, text: string )=>void;
    hasInvalid: boolean; // Invalid range is relevant for regular attributes, but not for "Cluster" attributes
    overrideMouseIsOver?:boolean;
}

const ColorLegendEnumsConfig: React.FC<IColorLegendEnumsConfigProps> = (props) => {

    const [options] = useState<VMultiSplitPanelItemOptions>(
        props.onColorBoxClicked
            ? VMultiSplitPanelItemOptions.LabelEditable|VMultiSplitPanelItemOptions.ColorEditable
            : VMultiSplitPanelItemOptions.LabelEditable
    ) ;

    const legendConfig = props.legendConfig ;

    return (
        <>
            {
                legendConfig &&
                <div className={"w3-margin"} style={{maxWidth: "75%"}}>
                    {
                        <VMultiSplitPanel
                            overrideMouseIsOver={props.overrideMouseIsOver}
                            items={legendConfig.items
                                .map((item, idx) => {
                                        // console.log(`item=${JSON.stringify(item)}`);
                                        return {
                                            cssColor: item.color.toCssHexString(),
                                            weight: 1,
                                            text: item.label ?? `${idx}`
                                        }
                                    }
                                )
                                .reverse()
                            }
                            onItemWeightsUpdated={(i1, w1, i2, w2) => {}}
                            onInsertAfter={(idx) => {}}
                            onRemoveAt={(idx) => {}}
                            onColorSelectBtnClicked={ (idx) => {
                                  const itemIndex = legendConfig.items.length - 1 - idx;
                                  if ( props.onColorBoxClicked ) {
                                      props.onColorBoxClicked(itemIndex, legendConfig.items[itemIndex].color.toCssHexString(), undefined)
                                  }
                                }}
                            onLabelEdit={(idx) => {
                                const itemIndex = legendConfig.items.length - 1 - idx;
                                props.onLabelEdit(itemIndex, legendConfig.items[itemIndex].label??'');
                            }}
                            options={options}
                        />
                    }
                    { props.hasInvalid && (
                      <div
                          className={"w3-border"}
                      >
                          <VMultiSplitPanelItem
                              height={50}
                              cssColor={legendConfig.invalidItem.color.toCssHexString()}
                              text={t("invalid")}
                              onSplitBtnClicked={() => {}}
                              onRemoveBtnClicked={() => {}}
                              onColorSelectBtnClicked={() => {
                                if ( props.onColorBoxClicked ) {
                                    props.onColorBoxClicked(-1, legendConfig.invalidItem.color.toCssHexString(), undefined)
                                }
                              }}
                              onLabelEditBtnClicked={() => {}}
                              options={VMultiSplitPanelItemOptions.ColorEditable}
                          />
                      </div>
                    )}
                </div>
            }
        </>
    );
}

export default withTranslation()(ColorLegendEnumsConfig);