import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClone} from "@fortawesome/free-regular-svg-icons";
import toast from "react-hot-toast";
import {WithTranslation, withTranslation} from "react-i18next";

interface IProps extends WithTranslation {
  content?: string|undefined;
  validator?:((jsonObject: any)=>string|undefined)|undefined;
  width?:string|undefined;
  height?:string|undefined;
  onJsonValidChanged?: ((isValid:boolean, err?: string|undefined)=>void)|undefined;
  onValidContentChanged?: ((content: string)=>void)|undefined;
}

const TAB = "  ";

const JsonEditor: React.FC<IProps> = (props)=>{
  const t = props.t ;
  const validator = props.validator;
  const origContent = props.content??"";
  const width = props.width??"320px";
  const height = props.height??"240px";
  const onJsonValidChanged = props.onJsonValidChanged ;
  const onValidContentChanged = props.onValidContentChanged;
  const [content, setContent] = useState<string>(origContent);
  const [isValid, setValid] = useState<boolean>(true);
  const [err, setErr] = useState<string|undefined>();
  const [textAreaClass, setTextAreaClass] = useState<string>("w3-text-light-grey");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  /**
   * Change event handler
   */
  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent( e.target.value );
  }, [setContent]);

  /**
   * Detect changes
   */
  const changed = useMemo(()=>{
    const re = "/[ ]+/g";
    const currentContent = content.trim().replaceAll(re, " ");
    const originalContent = origContent.trim().replaceAll(re, " ");
    return (currentContent.localeCompare(originalContent) !== 0) ;

  }, [content, origContent]);

  /**
   * Notify the parent container is the content is valid or not; this can be useful in dialogs where we'll want to
   * disable buttons and such
   */
  useEffect(()=>{
    onJsonValidChanged && onJsonValidChanged(isValid, err);
    if ( !isValid ) {
      setTextAreaClass("w3-text-red");
    } else if ( changed ) {
      setTextAreaClass("w3-text-green");
    } else {
      setTextAreaClass("w3-text-light-grey");
    }

  }, [isValid, changed, onJsonValidChanged]);

  /**
   * Constantly monitor changes to determine if the content is valid
   */
  useEffect(()=>{
    try {
      const parsed = JSON.parse( content ) ;
      const err = validator && validator( parsed ) ;
      setValid( !err );
      setErr( err ) ;
      if ( changed && !err ) {
        onValidContentChanged && onValidContentChanged( content ) ;
      }
    }
    catch ( err ) {
      setValid( false );
      setErr( `${err}` ) ;
    }
  }, [content, validator, changed, onValidContentChanged]);

  /**
   * Capture the TAB key
   */
  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ( e.key === "Tab" ) {
      e.preventDefault();
      const start = e.currentTarget.selectionStart;
      const end = e.currentTarget.selectionEnd;

      setContent( prevState => {

        const newValue = prevState.substring(0, start) + TAB + prevState.substring(end) ;

        if (textAreaRef?.current) {
          textAreaRef.current.value = newValue;
          textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd = start + TAB.length;
        }
        return newValue;
      });
    }
  }, []);

  const handleCopyToClipboard = useCallback(()=>{
    if ( textAreaRef.current ) {
      textAreaRef.current.select();
      textAreaRef.current.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(textAreaRef.current.value);
      toast(t('Content copied to clipboard'));
      textAreaRef.current.setSelectionRange(0, 0);
    }
  }, []);

  return (
      <div
          // className={'w3-padding'}
          style={{width: width, height: height, position: "relative"}}
      >
        <textarea
            ref={textAreaRef}
            style={{width: "100%", height: "100%", resize: "none", overflow: "auto", backgroundColor: "#3a3a3a", fontWeight: "normal", fontFamily: "monospace"}}
            className={textAreaClass}
            value={content}
            onChange={handleChange}
            wrap={"OFF"}
            onKeyDown={handleKeyDown}
        />
        <FontAwesomeIcon
            className={'w3-hover-text-light-blue'}
            style={{position: "absolute", top: "10px", right: "25px", cursor: "pointer"}}
            icon={faClone}
            size={'lg'}
            onClick={handleCopyToClipboard}
        />
      </div>
  )
}

export default withTranslation()(JsonEditor) ;
