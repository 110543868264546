import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {t} from "i18next";
import VMultiSplitPanel from "../VMultiSplitPanel/VMultiSplitPanel";
import VMultiSplitPanelItem, {VMultiSplitPanelItemOptions} from "../VMultiSplitPanel/VMultiSplitPanelItem";
import IColorLegendItemConfiguration from "../Gen2/domain/IColorLegendItemConfiguration";
import IColorLegendConfiguration from "../Gen2/domain/IColorLegendConfiguration";
import BasicDialog, {BasicDialogButton} from "../Dialogs/BasicDialog";
import JsonEditor from "../Gen2/components/BasicControls/JsonEditor";
import SiteConfigurationMapper from "../Gen2/mappers/SiteConfigurationMapper";
import ColorLegendConfigMapper from "../Gen2/mappers/ColorLegendConfigMapper";
import {LegendType} from "../Gen2/domain/LegendType";
import {Color} from "cesium";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

interface IColorLegendRangesConfigProps extends WithTranslation {
    id?:string;
    legendConfig: IColorLegendConfiguration,
    onLegendConfigChanged: (legendConfig: IColorLegendConfiguration)=>void;
    onColorBoxClicked: (itemIndex: number, color: string, callback: ((updatedColor: string) => void )|undefined)=>void;
    onLabelEdit: (itemIndex: number, text: string )=>void;
    overrideMouseIsOver?: boolean;
}

const ColorLegendRangesConfig: React.FC<IColorLegendRangesConfigProps> = (props )=> {

    const computeTotalRange = (items: IColorLegendItemConfiguration[]) => {
        let result = props.legendConfig.items
            .reduce((sum, current)=> sum + (current.range[1]-current.range[0]), 0 ) ;
        return result ;
    };

    const [totalRange, setTotalRange] = useState<number>(computeTotalRange(props.legendConfig.items));

    useEffect( () => {
        setTotalRange(computeTotalRange(props.legendConfig.items));
    }, [props.legendConfig]);

    function updateLegendConfigItemsWeights(idx1: number, w1: number, idx2: number, w2: number) {

        let updatedItems: IColorLegendItemConfiguration[] = [];

        const oldRange1 = props.legendConfig.items[idx1].range[1]-props.legendConfig.items[idx1].range[0];
        const oldRange2 = props.legendConfig.items[idx2].range[1]-props.legendConfig.items[idx2].range[0];
        const totalRange = oldRange1 + oldRange2;
        const totalWeight = w1 + w2 ;

        const newRange1 = totalRange * w1 / totalWeight;

        for ( let i = 0; i < props.legendConfig.items.length; i+=1 ) {

            if ( i === idx1 ) {
                updatedItems.push({
                    ...props.legendConfig.items[i],
                    range: [
                        props.legendConfig.items[i].range[0],
                        props.legendConfig.items[i].range[0] + newRange1
                    ]
                });
            }
            else if ( i === idx2 ) {
                updatedItems.push({
                    ...props.legendConfig.items[i],
                    range: [
                        updatedItems[updatedItems.length-1].range[1],
                        props.legendConfig.items[i].range[1]
                    ]
                });
            }
            else {
                updatedItems.push({
                    ...props.legendConfig.items[i],
                    range: [
                        props.legendConfig.items[i].range[0],
                        props.legendConfig.items[i].range[1]
                    ]
                });
            }
        }
        props.onLegendConfigChanged({...props.legendConfig, items: updatedItems });
    }

    const splitLegendConfigItemAt = (itemIndex: number, equalDistribution: undefined | boolean) => {

        let updatedItems: IColorLegendItemConfiguration[] = [];

        let prevColor = props.legendConfig.invalidItem.color;

        for ( let i = 0; i < props.legendConfig.items.length; i += 1 ) {
            const thisColor = props.legendConfig.items[i].color;
            if ( i === itemIndex ) {
                let halfRange = (props.legendConfig.items[i].range[1] - props.legendConfig.items[i].range[0]) / 2 ;
                updatedItems.push({
                    ...props.legendConfig.items[i],
                    range: [
                        props.legendConfig.items[i].range[0],
                        props.legendConfig.items[i].range[0] + halfRange,
                    ],
                    color: Color.fromBytes(
                        255*(prevColor.red + thisColor.red)/2,
                        255*(prevColor.green + thisColor.green)/2,
                        255*(prevColor.blue + thisColor.blue)/2,
                    )
                });
                updatedItems.push({
                    ...props.legendConfig.items[i],
                    range: [
                        props.legendConfig.items[i].range[0] + halfRange,
                        props.legendConfig.items[i].range[1],
                    ]
                });
            }
            else {
                updatedItems.push({
                    ...props.legendConfig.items[i],
                    range: [
                        props.legendConfig.items[i].range[0],
                        props.legendConfig.items[i].range[1],
                    ]
                });
            }
            prevColor = thisColor;
        }

        if (equalDistribution && updatedItems.length > 1 ) {
            const span = updatedItems[updatedItems.length-1].range[1] - updatedItems[0].range[0];
            const itemRange = span / updatedItems.length ;
            updatedItems.forEach( (item, idx) => {
                item.range[0] = idx * itemRange;
                item.range[1] = (idx+1) * itemRange;
            } );
        }

        props.onLegendConfigChanged({...props.legendConfig, items: updatedItems });
    };

    const removeLegendConfigItemAt = ( itemIndex: number ) => {
        const itemToRemove = props.legendConfig.items[itemIndex];
        const firstIndex = 0 ;
        const lastIndex = props.legendConfig.items.length - 1 ;

        let updatedItems: IColorLegendItemConfiguration[] = [];

        // ... Copy all items except the item to remove
        for ( let i = 0; i < props.legendConfig.items.length; i += 1 ) {
            if ( i !== itemIndex ) {
                updatedItems.push({
                    ...props.legendConfig.items[i],
                    range: [
                        props.legendConfig.items[i].range[0],
                        props.legendConfig.items[i].range[1],
                    ]
                });
            }
        }

        // ... Bridge disjointed neighbors
        if ( itemIndex === firstIndex ) {
            updatedItems[firstIndex].range[0] = itemToRemove.range[0];
        }
        else if (itemIndex === lastIndex) {
            updatedItems[lastIndex-1].range[1] = itemToRemove.range[1];
        }
        else {
            for ( let i = 0; i < updatedItems.length - 1; i += 1 ) {
                if ( updatedItems[i].range[1] !== updatedItems[i+1].range[0] ) {
                    const dist = Math.abs(updatedItems[i+1].range[0] - updatedItems[i].range[1]);
                    updatedItems[i].range[1] += dist/2 ;
                    updatedItems[i+1].range[0] = updatedItems[i].range[1] ;
                }
            }
        }

        props.onLegendConfigChanged({...props.legendConfig, items: updatedItems });
    }

    const rangeExceededItem = useMemo(()=>props.legendConfig.rangeExceededItem, [props.legendConfig]);

    const legendConfig = props.legendConfig;
    const [configToEdit, setConfigToEdit] = useState<IColorLegendConfiguration|undefined>();
    const newConfig = useRef<IColorLegendConfiguration>();
    const showJsonEditor = useCallback(()=>{
        setConfigToEdit({...legendConfig});
    }, [legendConfig]);

    const [jsonValid, setJsonValid] = useState<boolean>(true);
    const [jsonErr, setJsonErr] = useState<string|undefined>();

    return (
      <>
          {t("adjust_ranges_by_dragging_the_color_boundaries")}:


          <div className={"w3-margin"} style={{maxWidth: "100%"}}>
              {rangeExceededItem &&
                  <div
                      className={"w3-border"}
                  >
                      <VMultiSplitPanelItem
                          height={50}
                          cssColor={rangeExceededItem.color.toCssHexString()}
                          text={"> max"}
                          onSplitBtnClicked={() => {}}
                          onRemoveBtnClicked={() => {}}
                          onColorSelectBtnClicked={() => props.onColorBoxClicked(props.legendConfig.items.length, rangeExceededItem.color.toCssHexString(), undefined)}
                          onLabelEditBtnClicked={() => {}}
                          options={VMultiSplitPanelItemOptions.ColorEditable}
                      />
                  </div>
              }
              {totalRange &&
                  <div
                      // className={"w3-cell-row"}
                  >
                      <div
                          // className={"w3-cell w3-cell-middle"}
                          // style={{width: `${clustersConfig ? "60%":"100%"}`}}
                      >
                          <VMultiSplitPanel
                              overrideMouseIsOver={props.overrideMouseIsOver}
                              items={props.legendConfig.items
                                  .map((item, idx) => {
                                          // console.log(`item=${JSON.stringify(item)}`);
                                          return {
                                              cssColor: item.color.toCssHexString(),
                                              weight: (item.range[1]-item.range[0])/totalRange,
                                              text: `${item.range[0].toFixed(props.legendConfig.precision)}-${item.range[1].toFixed(props.legendConfig.precision)}`
                                          }
                                      }
                                  )
                                  .reverse()
                              }
                              onItemWeightsUpdated={(i1, w1, i2, w2)=>{
                                  // console.log(`>>> onItemWeightsUpdated(${i1}: ${w1.toFixed(4)}, ${i2}: ${w2.toFixed(4)})`) ;
                                  updateLegendConfigItemsWeights(
                                      props.legendConfig.items.length - 1 - i2,
                                      w2,
                                      props.legendConfig.items.length - 1 - i1,
                                      w1,
                                  );
                              }}
                              onInsertAfter={(idx, equalDistribution) => {
                                  console.log(`>>> onInsertAfter(${idx}, equalDistribution=${equalDistribution})`) ;
                                  const itemIndex = props.legendConfig.items.length - 1 - idx ;
                                  splitLegendConfigItemAt( itemIndex, equalDistribution )
                              }}
                              onRemoveAt={(idx)=>{
                                  console.log(`>>> onRemoveAt(${idx})`) ;
                                  const itemIndex = props.legendConfig.items.length - 1 - idx ;
                                  removeLegendConfigItemAt(itemIndex) ;
                              }}
                              onColorSelectBtnClicked={(idx) => {
                                  console.log(`>>> onColorSelectBtnClicked(${idx})`) ;
                                  const itemIndex = props.legendConfig.items.length - 1 - idx ;
                                  props.onColorBoxClicked( itemIndex, props.legendConfig.items[itemIndex].color.toCssHexString(), undefined )
                              }}
                              onLabelEdit={(idx)=>{}}
                              options={
                                VMultiSplitPanelItemOptions.Removable|
                                VMultiSplitPanelItemOptions.Splittable|
                                VMultiSplitPanelItemOptions.Resizable|
                                VMultiSplitPanelItemOptions.ColorEditable
                                }
                          />
                      </div>
                  </div>
              }
              <div
                  className={"w3-border"}
              >
                  <VMultiSplitPanelItem
                      height={50}
                      cssColor={props.legendConfig.invalidItem.color.toCssHexString()}
                      text={t("invalid")}
                      onSplitBtnClicked={()=>{}}
                      onRemoveBtnClicked={()=>{}}
                      onColorSelectBtnClicked={()=>props.onColorBoxClicked( -1, props.legendConfig.invalidItem.color.toCssHexString(), undefined )}
                      onLabelEditBtnClicked={()=>{}}
                      options={VMultiSplitPanelItemOptions.ColorEditable}
                  />
              </div>
              <div className={'w3-text-light-blue w3-right-align'}>
                  <u
                      className={'w3-hover-text-blue'} style={{cursor: "pointer"}}
                      onClick={showJsonEditor}
                  >
                      {t("Edit JSON")}
                  </u>
              </div>
              {
                  configToEdit && (
                      <BasicDialog title={t('JSON Editor')} >
                          <JsonEditor
                              width={"100%"}
                              height={"calc( 50vh )"}
                              content={JSON.stringify(ColorLegendConfigMapper.colorLegendConfigToColorLegendConfigDto(configToEdit), null, 2 )}
                              validator={validateColorLegendConfig}
                              onJsonValidChanged={(isValid, err)=> {
                                  // console.log(`isValid=${isValid}, err=${err}`);
                                  setJsonValid(isValid);
                                  setJsonErr(err);
                              }}
                              onValidContentChanged={(newContent)=>{
                                  newConfig.current = ColorLegendConfigMapper.colorLegendConfigDtoToColorLegendConfig(JSON.parse(newContent));
                              }}
                          />
                          { jsonErr
                              ? <div className={'w3-text-amber w3-right-align'}>
                                  <FontAwesomeIcon icon={faTriangleExclamation} className={'w3-margin-right'}/>
                                  {jsonErr}
                                </div>
                              : <br/>
                          }
                          <BasicDialogButton
                              label={t('Cancel')} disabled={!jsonValid} position={"left"} className={'w3-grey'}
                              onClick={()=>setConfigToEdit(undefined)}
                          />
                          <BasicDialogButton
                              label={t('Apply')} disabled={!jsonValid} position={"right"} className={'w3-light-blue'}
                              onClick={()=>{
                                  console.log(`Applying new color legend config: ${JSON.stringify(newConfig.current, null, 2)}`);
                                  newConfig.current && props.onLegendConfigChanged(newConfig.current);
                                  setConfigToEdit(undefined)
                              }}
                          />
                      </BasicDialog>
                  )
              }
          </div>
      </>
    );
}

export default withTranslation()(ColorLegendRangesConfig);

/**
 * Validator function for JSON editor
 * @param json
 */
function validateColorLegendConfig(json: any): string|undefined {

    function isPositiveInteger( str: string ): boolean {
        return /^\+?\d+$/.test(str);
    }

    if ( !json.hasOwnProperty("legendType") || !Object.values(LegendType).includes(json.legendType) ) {
        return "ValidateColorLegendConfig: no valid legendType";
    }
    if ( !json.hasOwnProperty("colorMaskedColor") || !Color.fromCssColorString( json.colorMaskedColor ) ) {
        return "ValidateColorLegendConfig: no valid maskedColor";
    }
    if ( !json.hasOwnProperty("precision") || !isPositiveInteger(json.precision) ) {
        return "ValidateColorLegendConfig: no valid precision";
    }
    if ( !json.hasOwnProperty("defaultRange") || Number.isNaN(json.defaultRange) ) {
        return "ValidateColorLegendConfig: no valid defaultRange";
    }

    let err: string|undefined ;

    if ( !json.hasOwnProperty("colorInvalidValue") || ( err = validateColorLegendItemConfig(json.colorInvalidValue) ) ) {
        return `No valid colorInvalidValue (${err})`;
    }

    // ... Range exceeded is optional
    if ( json.hasOwnProperty("colorRangeExceeded") && ( err = validateColorLegendItemConfig(json.colorRangeExceeded) ) ) {
        return `No valid colorRangeExceeded (${err})`;
    }

    if ( !json.hasOwnProperty("items") || (json.items as any[]).some( (item, idx) => {
        let error = validateColorLegendItemConfig(item);
        if ( error ) {
            error = `item ${idx}: ${error}`;
            err = err ? `${err}, ${error}`: error;
        }
        return error;
    })) {
        return `Items contains at least one invalid configuration: ${err}`;
    }

    // ... Validate that the numeric ranges are consecutive
    try {
        const config = ColorLegendConfigMapper.colorLegendConfigDtoToColorLegendConfig(json);

        if (config.invalidItem.range[1] !== 0 ) {
            return 'Invalid range must be < 0';
        }
        let prevMax = config.invalidItem.range[1] ;

        for ( let i = 0; i < config.items.length; ++i ) {
            const item = config.items[i];
            if ( item.range[0] !== prevMax ) {
                return `Numeric range item ${i} is not contiguous with the previous range`;
            }
            prevMax = item.range[1];
        }

        if (config.rangeExceededItem && config.rangeExceededItem.range[0] !== prevMax ) {
            return "'Range Exceeded' item not contiguous with the previous range";
        }
    }
    catch ( err ) {
        return `Error parsing JSON: ${json}`;
    }

    return undefined ;
}

function validateColorLegendItemConfig( json: any ): string|undefined {
    if ( !json.hasOwnProperty("range") || !(json.range as any[])?.every( (item) => !Number.isNaN(item)) || (json.range as any[]).length <= 0 || (json.range as any[]).length > 2 ) {
        return "range";
    }
    if ( !json.hasOwnProperty("color") || !Color.fromCssColorString( json.color ) ) {
        return "invalid color";
    }
    return undefined ;
}