import React, {CSSProperties, useEffect} from "react";
import useResizeObserver from "use-resize-observer";

interface IRoundedPanelProps {
  className?:string|undefined;
  style?:CSSProperties|undefined;
  w3BorderColor?:string|undefined;
  onSizeChanged?:(w: number, h: number)=>void|undefined;
}

const RoundedPanel: React.FC<IRoundedPanelProps> = (props)=>{
  const w3BorderColor = props.w3BorderColor ? `w3-border ${props.w3BorderColor}`: '';

  const {ref, width=1, height=1} = useResizeObserver<HTMLDivElement>();

  useEffect(()=>{
    if ( width && height ) {
      props.onSizeChanged && props.onSizeChanged( width, height );
    }
  }, [width, height]);

  return (
      <>
        <div
            ref={ref}
            className={`w3-container w3-card-2 w3-theme-d3 w3-round-large ${props.className}`}
            style={{
              ...props.style,
              height: "100%",
              width: "100%"
            }}
        >
          <div className={`w3-section ${w3BorderColor}`} style={{ height: "calc(100% - 32px)" }}>
            {props.children}
          </div>
        </div>
      </>
  )
}

export default RoundedPanel;
