import React, {createContext, useCallback, useEffect, useMemo, useState} from "react";
import TooltipContainer from "../BasicControls/TooltipContainer";


export const TooltipContext = createContext<any>({});

export interface ITooltipState {
  position: {x:number, y:number};
  orientation: "left"|"top"|"right"|"bottom";
  content: React.ReactNode;
}

export interface ITooltipActions {
  setTooltip: (tooltip: ITooltipState|undefined)=>void;
  updateTooltip: (content: React.ReactNode)=>void;
}

export const TooltipContextProvider: React.FC = (props)=>{
  const [tooltip, setTooltip] = useState<ITooltipState|undefined>();

  const updateTooltip = useCallback((content: React.ReactNode) => {
    setTooltip( prevState => {
      return (content && prevState) ? {...prevState, content: content } : undefined;
    })
  }, [setTooltip]);

  const actions = useMemo(() : ITooltipActions =>{
    return {
      setTooltip, updateTooltip
    }
  }, [setTooltip, updateTooltip]);

  return (
      <TooltipContext.Provider value={[tooltip, actions]}>
        {props.children}
        {
          tooltip && (
            <TooltipContainer
                orientation={tooltip.orientation}
                position={tooltip.position}
                content={tooltip.content}
                backColor={'white'}
            />
          )
        }
      </TooltipContext.Provider>
  )
}

export const useTooltips = (): [(ITooltipState | undefined), ITooltipActions] => {
  const [state, actions] = React.useContext(TooltipContext);
  return [state, actions];
}
