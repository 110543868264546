import Cesium, {
  BillboardCollection,
  Cartesian3,
  Cartesian4,
  Cesium3DTileset as CesiumCesium3DTileset,
  Color, Label, LabelCollection,
  Matrix4, Model, ModelMesh, ModelNode, PointPrimitive, PointPrimitiveCollection, Polyline, PolylineCollection,
  Primitive,
  PropertyBag
} from "cesium";
import React, {useCallback, useMemo} from "react";
import {CesiumMovementEvent, Entity, PolylineGraphics} from "resium";
import {TilesetUtils} from "../../util/TilesetUtils";
import {DEFAULT_NEUTRAL_COLOR} from "../../domain/IStyle";

export interface IPerimeterSetProps {
  featureId: number;
  perimeters: Cartesian3[][] ;
  color: Color;
  lineWidth: number;
  properties?: PropertyBag | {[key: string]: any;}
  onLeftClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onRightClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onDoubleClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseMove?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseUp?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseDown?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
}

const LINE_WIDTH = 3;

/**
 * Perimeter entity
 * @param props
 * @constructor
 */
export const PerimeterSet: React.FC<IPerimeterSetProps> = (props)=>{
  // console.log(`PerimeterSet: featureId=${props.featureId}, count=${props.perimeters.length}`);

  const handleLeftClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onLeftClick ) {
      props.onLeftClick( movement, target );
    }
  }, []);
  const handleRightClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onRightClick ) {
      props.onRightClick( movement, target );
    }
  }, []);
  const handleDoubleClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onDoubleClick ) {
      props.onDoubleClick( movement, target );
    }
  }, []);
  const handleMouseMove = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseMove ) {
      props.onMouseMove( movement, target );
    }
  }, []);
  const handleMouseUp = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseUp ) {
      props.onMouseUp( movement, target );
    }
  }, []);
  const handleMouseDown = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseDown ) {
      props.onMouseDown( movement, target );
    }
  }, []);

  return (
      <>
        {
          props.perimeters.map( (perimeter, idx) => (
            <Perimeter
                key={`Perimeter_${props.featureId}.${idx}`}
                featureId={props.featureId}
                perimeter={perimeter}
                color={props.color}
                lineWidth={props.lineWidth}
                properties={props.properties}
                onLeftClick={handleLeftClick}
                onRightClick={handleRightClick}
                onDoubleClick={handleDoubleClick}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseDown={handleMouseDown}
            />
          ))
        }
      </>
  )
}

interface IPerimeterProps {
  featureId: number;
  perimeter: Cartesian3[] ;
  color: Color;
  lineWidth: number;
  properties?: PropertyBag | {[key: string]: any;}
  onLeftClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onRightClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onDoubleClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseMove?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseUp?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseDown?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
}

/**
 * Perimeter entity
 * @param props
 * @constructor
 */
const Perimeter: React.FC<IPerimeterProps> = (props)=>{
  // console.log(`Perimeter: featureId=${JSON.stringify(props)}`);

  const positions = useMemo(()=>{
    return props.perimeter.length > 0
        ? [...props.perimeter, props.perimeter[0]]
        : props.perimeter ;
  }, [props.perimeter]);

  const handleLeftClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onLeftClick ) {
      props.onLeftClick( movement, target );
    }
  }, []);
  const handleRightClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onRightClick ) {
      props.onRightClick( movement, target );
    }
  }, []);
  const handleDoubleClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onDoubleClick ) {
      props.onDoubleClick( movement, target );
    }
  }, []);
  const handleMouseMove = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseMove ) {
      props.onMouseMove( movement, target );
    }
  }, []);
  const handleMouseUp = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseUp ) {
      props.onMouseUp( movement, target );
    }
  }, []);
  const handleMouseDown = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseDown ) {
      props.onMouseDown( movement, target );
    }
  }, []);

  return (
      <>
        <Entity
            name={`${props.featureId}`}
            show={true}
            properties={props.properties}
            onClick={handleLeftClick}
            onRightClick={handleRightClick}
            onDoubleClick={handleDoubleClick}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseDown={handleMouseDown}
        >
          <PolylineGraphics
              material={props.color}
              width={props.lineWidth}
              positions={positions}
          />
        </Entity>
      </>
  )
}

/**
 * Extract a list of perimeters from the boundary layer data
 * @param tileset
 * @param modelMatrix
 */
export function getPerimeters(tileset: CesiumCesium3DTileset, modelMatrix: Matrix4): IPerimeterSetProps[]|undefined {
  const root = tileset.root;
  const origin = TilesetUtils.getOriginReference( tileset ) ;
  let transform = root.transform ?? Matrix4.IDENTITY ;
  let xyzIsAbsolute: boolean|undefined = undefined ;
  const content = root?.content;

  let result: IPerimeterSetProps[] = [];

  if (content && origin) {
    for (let i = 0; i < content.featuresLength; ++i) {
      let feature = content.getFeature(i);
      const points = feature.getProperty( "Points" ) ;
      if (points) {
        let polygons: number[][][] = JSON.parse(points);

        let perimeterSet: IPerimeterSetProps = {
          featureId: feature.featureId,

          perimeters: polygons.map((polygon) => polygon
              .map(vertex => {

                let xyz = Cartesian3.fromArray(vertex);

                // ... Heuristic: are the XYZ values absolute or relative?
                if (!xyzIsAbsolute) {
                  xyzIsAbsolute = Math.abs(xyz.x - origin.x) < 1000 && Math.abs(xyz.y - origin.y) < 1000;
                }
                if (xyzIsAbsolute) {
                  xyz.x -= origin.x;
                  xyz.y -= origin.y;
                  xyz.z -= origin.z;
                }

                // ... Add transform
                let transformed =
                    Matrix4.multiplyByVector(
                        transform, Cartesian4.fromArray([xyz.x, xyz.y, xyz.z, 1]), new Cartesian4());

                // ... Convert local offset to XYZ coordinate
                return TilesetUtils.localToWorld(transformed, modelMatrix);
              })),
          color: DEFAULT_NEUTRAL_COLOR,
          lineWidth: LINE_WIDTH,
          // properties: {}
        };

        // console.log(`Adding ${perimeterSet.perimeters.length} perimeters for feature ${perimeterSet.featureId}`)
        // feature.getPropertyNames().forEach( name => {
        //   if (perimeterSet.properties) {
        //     perimeterSet.properties[ name ] = feature.getProperty( name );
        //   }
        // } );

        result.push(perimeterSet)
      }
    }
  } else {
    console.error(`NO CONTENT!!!`);
  }
  return result ;
}

