import React from "react";

interface IProps {
  value?: number;
  onPillClicked?: ()=>void;
  className?: string;
  style?: any;
}

const Pill: React.FC<IProps> = (props) => {

  return (
      <>
        {
          ((props?.value ?? 0) > 0) && (
            <span
                className={`w3-badge w3-red unselectable w3-hover-black ${props.className} w3-tiny w3-padding-small`}
                style={{...props.style, cursor: "pointer"}}
                onClick={props.onPillClicked}
            >
              <b>{props.value}</b>
            </span>
          )}
      </>
  )
}

export default Pill;
