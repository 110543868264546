import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation {
  lastDaysOptions: number[];
  onOptionSelected: (option: number | undefined) => void;
  selectedOption?: number;
}

const LastPeriodFilter: React.FC<Props> = ({
  lastDaysOptions,
  onOptionSelected,
  selectedOption,
  t,
}) => {
  const widthPercent = (1 / (lastDaysOptions.length + 1)) * 100;

  const handleChange = (value: number) => {
    value === lastDaysOptions.length
      ? onOptionSelected(undefined)
      : onOptionSelected(lastDaysOptions[value]);
  };

  const sliderValue = selectedOption
    ? lastDaysOptions.indexOf(selectedOption)
    : lastDaysOptions.length;

  const hideThumbClass = sliderValue < 0 ? "hidden-thumb" : "";

  return (
    <>
      <label htmlFor="lastPeriod">{t("last")}</label>

      <div className="w3-row">
        <input
          id="lastPeriod"
          className={`slider ${hideThumbClass}`}
          type="range"
          min={0}
          max={lastDaysOptions.length}
          value={sliderValue}
          style={{
            width: `calc(${100 - widthPercent}% + 18px)`,
            marginLeft: `calc(${widthPercent / 2}% - 9px)`,
          }}
          onChange={(e) => handleChange(Number(e.target.value))}
        />
      </div>
      <div className="w3-row">
        {lastDaysOptions.map((opt, index) => (
          <div
            key={opt}
            className="w3-col w3-tiny w3-center"
            style={{ width: `${widthPercent}%`, cursor: "pointer" }}
            onClick={() => handleChange(index)}
          >
            {opt} {t("days")}
          </div>
        ))}
        <div
          className="w3-col w3-tiny w3-center"
          style={{ width: `${widthPercent}%`, cursor: "pointer" }}
          onClick={() => handleChange(lastDaysOptions.length)}
        >
          {t("all")}
        </div>
      </div>
    </>
  );
};

export default withTranslation()(LastPeriodFilter);
