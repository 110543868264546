import React, {useEffect, useState} from "react";

export interface IMatrix3 {
    c0r0:number;
    c0r1:number;
    c0r2:number;

    c1r0:number;
    c1r1:number;
    c1r2:number;

    c2r0:number;
    c2r1:number;
    c2r2:number;
}

interface IRotationMatrixEditorProps {
    enabled:boolean;
    matrix: IMatrix3;
    onChange: (matrix: IMatrix3) => void;
}

const RotationMatrixEditor: React.FC<IRotationMatrixEditorProps> =
({
    enabled,
    matrix,
    onChange
}) => {
    const [c0r0, set_c0r0] = useState<number>(matrix.c0r0);
    const [c0r1, set_c0r1] = useState<number>(matrix.c0r1);
    const [c0r2, set_c0r2] = useState<number>(matrix.c0r2);
    const [c1r0, set_c1r0] = useState<number>(matrix.c1r0);
    const [c1r1, set_c1r1] = useState<number>(matrix.c1r1);
    const [c1r2, set_c1r2] = useState<number>(matrix.c1r2);
    const [c2r0, set_c2r0] = useState<number>(matrix.c2r0);
    const [c2r1, set_c2r1] = useState<number>(matrix.c2r1);
    const [c2r2, set_c2r2] = useState<number>(matrix.c2r2);

    useEffect(()=>{
         set_c0r0( matrix.c0r0 ) ;
         set_c0r1( matrix.c0r1 ) ;
         set_c0r2( matrix.c0r2 ) ;
         set_c1r0( matrix.c1r0 ) ;
         set_c1r1( matrix.c1r1 ) ;
         set_c1r2( matrix.c1r2 ) ;
         set_c2r0( matrix.c2r0 ) ;
         set_c2r1( matrix.c2r1 ) ;
         set_c2r2( matrix.c2r2 ) ;
    }, [matrix]);

    const getMatrix = (): IMatrix3 => {
        return {
            c0r0: c0r0,
            c0r1: c0r1,
            c0r2: c0r2,
            c1r0: c1r0,
            c1r1: c1r1,
            c1r2: c1r2,
            c2r0: c2r0,
            c2r1: c2r1,
            c2r2: c2r2,
        };
    }

    const onChange_c0r0 = (value: number) => {
        set_c0r0( value ) ;
        onChange( { ...getMatrix(), c0r0: value } );
    };
    const onChange_c0r1 = (value: number) => {
        set_c0r1( value ) ;
        onChange( { ...getMatrix(), c0r1: value } );
    };
    const onChange_c0r2 = (value: number) => {
        set_c0r2( value ) ;
        onChange( { ...getMatrix(), c0r2: value } );
    };
    const onChange_c1r0 = (value: number) => {
        set_c1r0( value ) ;
        onChange( { ...getMatrix(), c1r0: value } );
    };
    const onChange_c1r1 = (value: number) => {
        set_c1r1( value ) ;
        onChange( { ...getMatrix(), c1r1: value } );
    };
    const onChange_c1r2 = (value: number) => {
        set_c1r2( value ) ;
        onChange( { ...getMatrix(), c1r2: value } );
    };
    const onChange_c2r0 = (value: number) => {
        set_c2r0( value ) ;
        onChange( { ...getMatrix(), c2r0: value } );
    };
    const onChange_c2r1 = (value: number) => {
        set_c2r1( value ) ;
        onChange( { ...getMatrix(), c2r1: value } );
    };
    const onChange_c2r2 = (value: number) => {
        set_c2r2( value ) ;
        onChange( { ...getMatrix(), c2r2: value } );
    };

    return (
        <>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <input style={{width:"100px"}} type={"number"} className={"w3-center"}
                                   value={c0r0}
                                   onChange={(e)=>onChange_c0r0( +e.currentTarget.value )}
                                   disabled={!enabled}
                            />
                        </td>
                        <td>
                            <input style={{width:"100px"}} type={"number"} className={"w3-center"}
                                   value={c1r0}
                                   onChange={(e)=>onChange_c1r0( +e.currentTarget.value )}
                                   disabled={!enabled}
                            />
                        </td>
                        <td>
                            <input style={{width:"100px"}} type={"number"} className={"w3-center"}
                                   value={c2r0}
                                   onChange={(e)=>onChange_c2r0( +e.currentTarget.value )}
                                   disabled={!enabled}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input style={{width:"100px"}} type={"number"} className={"w3-center"}
                                   value={c0r1}
                                   onChange={(e)=>onChange_c0r1( +e.currentTarget.value )}
                                   disabled={!enabled}
                            />
                        </td>
                        <td>
                            <input style={{width:"100px"}} type={"number"} className={"w3-center"}
                                   value={c1r1}
                                   onChange={(e)=>onChange_c1r1( +e.currentTarget.value )}
                                   disabled={!enabled}
                            />
                        </td>
                        <td>
                            <input style={{width:"100px"}} type={"number"} className={"w3-center"}
                                   value={c2r1}
                                   onChange={(e)=>onChange_c2r1( +e.currentTarget.value )}
                                   disabled={!enabled}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input style={{width:"100px"}} type={"number"} className={"w3-center"}
                                   value={c0r2}
                                   onChange={(e)=>onChange_c0r2( +e.currentTarget.value )}
                                   disabled={!enabled}
                            />
                        </td>
                        <td>
                            <input style={{width:"100px"}} type={"number"} className={"w3-center"}
                                   value={c1r2}
                                   onChange={(e)=>onChange_c1r2( +e.currentTarget.value )}
                                   disabled={!enabled}
                            />
                        </td>
                        <td>
                            <input style={{width:"100px"}} type={"number"} className={"w3-center"}
                                   value={c2r2}
                                   onChange={(e)=>onChange_c2r2( +e.currentTarget.value )}
                                   disabled={!enabled}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default RotationMatrixEditor ;
