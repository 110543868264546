import IPattern from "../model/IPattern";

const sortPatternsFunction = (sortBy: string, order: string) => {
  const sortingValue = order === "asc" ? 1 : -1;

  if (sortBy === "start" || sortBy === "end")
    return (a: IPattern, b: IPattern) =>
      (a[sortBy].valueOf() - b[sortBy].valueOf()) * sortingValue;

  if (sortBy === "active")
    return (a: IPattern, b: IPattern) =>
      (Number(b.tags.includes("inactive")) -
        Number(a.tags.includes("inactive"))) *
      sortingValue;

  if (sortBy === "name")
    return (a: IPattern, b: IPattern) => {
      if (a.name > b.name) return 1 * sortingValue;
      if (a.name < b.name) return -1 * sortingValue;
      return 0;
    };

  return undefined;
};

export const sortPatterns = (
  patterns: IPattern[],
  sortBy: string,
  order: string
) => {
  const sortedPatterns = [...patterns];

  sortedPatterns.sort(sortPatternsFunction(sortBy, order));

  return sortedPatterns;
};

function defaultCompareFunction<T>(a: T, b: T, sortBy: keyof T) {
  return (b[sortBy] as any)
    .toString()
    .localeCompare((a[sortBy] as any).toString());
}
// Generic sorting function, sorting an array of objects based on a key and an order.
// All keys are evaluated as strings and naively compared.
// If more sophisticated compare functions are required, we can extend this function to accept parameters, telling
// it how to evaluate / sort those specific keys.
export function sortObjects<T>(
  objects: T[],
  sortBy: string,
  order: string,
  parsers?: {
    [k in keyof T]: (val: T[k]) => string;
  }
) {
  const sortedObjects = [...objects];
  const sortingValue = order === "asc" ? 1 : -1;

  sortedObjects.sort(
    (a, b) => defaultCompareFunction(a, b, sortBy as keyof T) * sortingValue
  );
  return sortedObjects;
}
