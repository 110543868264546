import React, {useContext, useState} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {SubMenuButtonState} from "../../../SideMenuItems/SubMenuButton";
import {IHelpRequestedProps} from "../../../../Help/HelpContextProvider";

interface IProps extends WithTranslation, IHelpRequestedProps {
  id?:string;
  label: string;  // will be used by CollapsibleSideMenuContainer
  icon: IconProp; // will be used by CollapsibleSideMenuContainer
  buttonState?: SubMenuButtonState;
  className?:any;
  pillValue?: number;
  onPillClicked?: ()=>void;
  onSubMenuButtonStateChanged?: (state: SubMenuButtonState)=>void;
}

const CollapsibleSideMenuSection: React.FC<IProps> = (props) => {

  return (
    <div className={`w3-container ${props.className}`}>
      {
        React.Children.map( props.children, child => {
          if (!React.isValidElement(child)) {
            return;
          }
          return (
              <>
                {child}
              </>
          )
        })
      }
    </div>
  );
};

export default withTranslation()(CollapsibleSideMenuSection);