import React, {useCallback, useMemo} from "react";
import SideMenuRow from "../../../SideMenuItems/SideMenuRow";
import {t} from "i18next";
import TriStateCheckbox from "../../../TriStateCheckbox";
import {withTranslation, WithTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {AttributeType, IAttributeTypeMap} from "../../domain/AttributeType";
import {Predicate} from "../../util/Predicate";
import IFeatureReference from "../../model/IFeatureReference";
import {HeadingPitchRange} from "cesium";
import {useCameraControls} from "../../hooks/useCameraControls";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import IColorLegendConfiguration from "../../domain/IColorLegendConfiguration";
import {IColorLegend, IColorLegendItem} from "../../hooks/useColorLegendApi";
import IColorLegendItemConfiguration from "../../domain/IColorLegendItemConfiguration";
import {LayerType} from "../../../../model/LayerType";
import {ICameraVantagePoint} from "./ICameraVantagePoint";
import Slider from "../BasicControls/Slider";

interface IProps extends WithTranslation {
  onCameraVantagePointSet: (cameraVantagePoint: ICameraVantagePoint)=>void;
}

interface ICheckedStateCounters {
  checked: number;
  unchecked: number;
}

const widths = ["10%", "25%", "25%", "25%", "15%"];

const ColorLegendTable: React.FC<IProps> = (props) => {
  const [,actions] = useSiteConfig();
  const cameraControls = useCameraControls();

  const [siteConfig] = useSiteConfig();
  const [userSession, userSessionActions] = useUserSessionContext();

  const [colorLegendConfig, colorLegendState] = useMemo((): [IColorLegendConfiguration|undefined, IColorLegend|undefined]=>{
    if ( !userSession.selectedAttribute || !userSession.colorLegendStates || !siteConfig?.legendConfigs ) {
      return [undefined, undefined];
    }
    const attributeIndex = AttributeType[userSession.selectedAttribute] as keyof IAttributeTypeMap<IColorLegendConfiguration> ;
    const colorLegendConfig = siteConfig.legendConfigs[attributeIndex];
    const colorLegendState = userSession.colorLegendStates.get( userSession.selectedAttribute );

    if ( !colorLegendConfig || !colorLegendState ) {
      return [undefined, undefined];
    }

    return [{...colorLegendConfig}, {...colorLegendState}];

  }, [userSession.selectedAttribute, siteConfig?.legendConfigs, userSession.colorLegendStates]);

  const isolateColorLegendRangeVisibility = useCallback(( idx: number ) => {
    console.log('isolateColorLegendRangeVisibility');
    userSessionActions.isolateLayerVisibility(LayerType.BlockModel);
    if (colorLegendState) {
      userSessionActions.isolateColorLegendItem( colorLegendState, idx );
    }
  }, [colorLegendState, userSessionActions/*, viewModelActions.layer*/]);

  const visibilityStates = useMemo(()=>{
    return {
      invalidValueVisible: colorLegendState?.colorInvalidValue.visible??false,
      rangeExceededVisible: colorLegendState?.colorRangeExceeded?.visible,
      itemsVisible: (colorLegendState?.colorLegendItems??[]).map( item => item.visible )
    }
  }, [colorLegendState]);

  const checkedStateCounters = useMemo(()=>{
    console.log('>>> checkedStateCounters');
    let result = {checked: 0, unchecked: 0} as ICheckedStateCounters;
    if (visibilityStates.invalidValueVisible) {
      ++result.checked;
    } else {
      ++result.unchecked;
    }
    if (visibilityStates.rangeExceededVisible !== undefined) {
      if (visibilityStates.rangeExceededVisible) {
        ++result.checked;
      } else {
        ++result.unchecked;
      }
    }
    visibilityStates.itemsVisible.forEach(visible => {
      if (visible) {
        ++result.checked;
      } else {
        ++result.unchecked;
      }
    });
    return result ;
  }, [visibilityStates]);

  // const checkedStateCounters = useMemo(()=>{
  //   console.log('>>> checkedStateCounters');
  //   let result = {checked: 0, unchecked: 0} as ICheckedStateCounters;
  //   if ( colorLegendState ) {
  //     if (colorLegendState.colorInvalidValue.visible) {
  //       ++result.checked;
  //     } else {
  //       ++result.unchecked;
  //     }
  //     if (colorLegendState.colorRangeExceeded) {
  //       if (colorLegendState.colorRangeExceeded.visible) {
  //         ++result.checked;
  //       } else {
  //         ++result.unchecked;
  //       }
  //     }
  //     colorLegendState.colorLegendItems.forEach(item => {
  //       if (item.visible) {
  //         ++result.checked;
  //       } else {
  //         ++result.unchecked;
  //       }
  //     });
  //   }
  //   return result ;
  // }, [colorLegendState]);

  const allChecked = useMemo(()=>{
    if ( checkedStateCounters ) {
      return checkedStateCounters.checked === 0 ? false : checkedStateCounters.unchecked === 0 ? true : undefined ;
    }
  }, [checkedStateCounters]);

  const setAllCheckboxes = useCallback((checked: boolean)=>{
    if (colorLegendState) {
      userSessionActions.resetColorLegend( colorLegendState, { visible: checked });
      userSessionActions.persistColorLegend( colorLegendState );
    }
  }, [colorLegendState, userSessionActions]);

  /**
   * Zoom to a bounding sphere containing blocks who fit in the given color legend range
   */
  const onCameraVantagePointSet = props.onCameraVantagePointSet;
  const onZoomToColorRequested = useCallback((rangeIndex: number) => {

    if (!userSession.featureIndex) console.log('!userSession.featureIndex');
    if (!userSession.selectedAttribute) console.log('!selectedAttribute');
    if (!colorLegendState) console.log('!colorLegendState');

    if (!userSession.featureIndex || !userSession.selectedAttribute || /*!camera3D || */!colorLegendState) return;

    // ... Get the value range for the given index
    const valueRange = actions.getColorLegendValueRange(userSession.selectedAttribute, rangeIndex);
    if (!valueRange || valueRange.length === 0) {
      console.log(`No value range for ${AttributeType[userSession.selectedAttribute]}.${rangeIndex}`)
      return;
    }

    // ... Hide all other colors
    isolateColorLegendRangeVisibility( rangeIndex );

    // ... Build a predicate that will be used to narrow down the feature blocks that are within this value range
    const predicate: Predicate<IFeatureReference> = {
      test: (featureRef) => {
        if (!userSession.selectedAttribute) return false;
        const attributeValue = +featureRef.feature.getProperty(AttributeType[userSession.selectedAttribute]);
        if ( attributeValue < valueRange[ 0 ] ) return false;
        return !(valueRange.length > 1 && attributeValue >= valueRange[1]);
      }
    }

    // ... Get the bounding sphere for all blocks that satisfy the predicate
    const boundingSphere = userSession.featureIndex?.getBoundingBoxWhere(predicate);
    if (boundingSphere) {

      onCameraVantagePointSet({
        hpr: new HeadingPitchRange(-Math.PI/4, -Math.PI / 4, 2 * boundingSphere.radius),
        boundingSphere: boundingSphere
      })

      userSessionActions.resetColorLegendItem( colorLegendState, rangeIndex, { visible: true, opacity: 1 })
    }
    userSessionActions.persistColorLegend( colorLegendState );

  }, [actions, userSession.selectedAttribute, onCameraVantagePointSet, cameraControls, userSession.featureIndex, colorLegendState, isolateColorLegendRangeVisibility, userSessionActions]);

  return (
      <>
        {/*table header*/}
        <SideMenuRow widths={widths} className={`w3-margin-bottom`} style={{fontSize: "12px"}}>
          <TriStateCheckbox
              checked={allChecked}
              className="w3-check w3-left"
              onChange={(e) => {
                setAllCheckboxes(e.target.checked);
              }}
          />
          <span style={{verticalAlign: "middle", height: "100%"}} className={"w3-center unselectable"}><b>{t("range")}</b></span>
          <span style={{verticalAlign: "middle", height: "100%"}} className={"w3-center unselectable"}><b>{t("color")}</b></span>
          <span style={{verticalAlign: "middle", height: "100%"}} className={"w3-center unselectable"}><b>{t("opacity")}</b></span>
          <span style={{verticalAlign: "middle", height: "100%"}} className={"w3-right unselectable"}><b>{t("zoom")}</b></span>
        </SideMenuRow>

        { colorLegendState && colorLegendConfig && (
         <>
           {
             colorLegendState?.colorRangeExceeded && colorLegendConfig.rangeExceededItem && (
                   <ColorLegendRow
                      idx={colorLegendState.colorLegendItems.length}
                      widths={widths}
                      style={{
                        config: colorLegendConfig.rangeExceededItem,
                        state: colorLegendState.colorRangeExceeded
                      }}
                      onRangeVisibilityChanged={value => {
                        userSessionActions.resetColorLegendItem( colorLegendState, colorLegendState.colorLegendItems.length, { visible: value });
                        userSessionActions.persistColorLegend( colorLegendState );
                      } }
                      onRangeOpacityChanged={value => {
                        userSessionActions.resetColorLegendItem( colorLegendState, colorLegendState.colorLegendItems.length, { opacity: value })
                      } }
                      onRangeZoomClicked={() => onZoomToColorRequested(colorLegendState.colorLegendItems.length)}
                      onRangeOpacityReleased={()=>userSessionActions.persistColorLegend( colorLegendState )}
                   />
               )
           }
           {
             colorLegendState.colorLegendItems.map((range, idx)=>{
               if (!colorLegendState|| !colorLegendConfig) {
                 return (<></> );
               }

               let config = colorLegendConfig.items[idx];

               // console.log(`+++ ColorLegendTable: idx=${idx}, config=${JSON.stringify(config)}`);

               return (
                   <ColorLegendRow
                       key={`ColorLegendRow${idx}`}
                       idx={idx}
                       widths={widths}
                       style={{
                         config: config,
                         state: range
                       }}
                       onRangeVisibilityChanged={value => {
                         userSessionActions.resetColorLegendItem( colorLegendState, idx, { visible: value })
                         userSessionActions.persistColorLegend( colorLegendState );
                       } }
                       onRangeOpacityChanged={value => {
                         userSessionActions.resetColorLegendItem( colorLegendState, idx, { opacity: value })
                       } }
                       onRangeZoomClicked={() => onZoomToColorRequested(idx)}
                       onRangeOpacityReleased={()=>userSessionActions.persistColorLegend( colorLegendState )}
                   />
               )
               }).reverse()
           }
           {
             siteConfig.showInvalidColorConfig &&
             <ColorLegendRow
                 idx={-1}
                 widths={widths}
                 style={{
                   config: colorLegendConfig.invalidItem,
                   state: colorLegendState.colorInvalidValue
                 }}
                 onRangeVisibilityChanged={value => {
                   userSessionActions.resetColorLegendItem( colorLegendState, -1, { visible: value })
                   userSessionActions.persistColorLegend( colorLegendState );
                 } }
                 onRangeOpacityChanged={value => {
                   userSessionActions.resetColorLegendItem( colorLegendState, -1, { opacity: value })
                 } }
                 onRangeZoomClicked={() => onZoomToColorRequested(-1)}
                 onRangeOpacityReleased={()=>userSessionActions.persistColorLegend( colorLegendState )}
             />
           }
         </>
        )}
      </>
  )
}

export default withTranslation()(ColorLegendTable);

interface IRowProps {
  idx: number;
  widths: string[];
  style: { config: IColorLegendItemConfiguration, state: IColorLegendItem };
  onRangeVisibilityChanged: (visibility: boolean)=>void;
  onRangeOpacityChanged: (opacity: number)=>void;
  onRangeZoomClicked: ()=>void;
  onRangeOpacityReleased: ()=>void;
}

const ColorLegendRow: React.FC<IRowProps> = (props)=>{

  const onRangeOpacityChanged = props.onRangeOpacityChanged ;
  const onRangeOpacityReleased = props.onRangeOpacityReleased ;

  const opacity = props.style.state.opacity;

  const handleSliderValueChanged = useCallback((value: number) => {
    (async()=>onRangeOpacityChanged( value / 100))();
  }, [onRangeOpacityChanged]);

  return (
      <SideMenuRow widths={props.widths} className={"w3-margin-bottom"} style={{fontSize: "12px"}}>
        <div className={'w3-left-align'}>
          <input
              id={"checkbox_color_" + props.idx}
              type="checkbox"
              className="w3-check w3-left"
              checked={props.style.state.visible}
              onChange={(e) => {
                // console.log(`${props.idx} => ${e.target.checked}`)
                props.onRangeVisibilityChanged( e.target.checked );
              }}
          />
        </div>

        <div className={''}>
          <label htmlFor={"checkbox_color_" + props.idx}>
            {props.style.state.label}
          </label>
        </div>

        <div
            style={{
              height: "25px",
              marginLeft: "10px", marginRight: "10px",
              backgroundColor: props.style.config.color.toCssHexString(),
              borderColor: props.style.config.color.toCssHexString(),
            }}
        >
          {/* Empty space with background color */}
        </div>

        {/*<input*/}
        {/*    style={{ paddingLeft: "10px", paddingRight: "10px" }}*/}
        {/*    type="range"*/}
        {/*    min="0"*/}
        {/*    max="100"*/}
        {/*    step="5"*/}
        {/*    value={props.style.state.opacity*100}*/}
        {/*    onChange={(e) => {*/}
        {/*      props.onRangeOpacityChanged( (+e.target.value) / 100);*/}
        {/*    }}*/}
        {/*    onKeyDown={(event) => {*/}
        {/*      event.preventDefault();*/}
        {/*    }}*/}
        {/*    onMouseUp={()=>props.onRangeOpacityReleased()}*/}
        {/*/>*/}
        <Slider
            className={'w3-padding-small'}
            min={0}
            max={100}
            step={5}
            value={opacity*100}
            onValueChanged={handleSliderValueChanged}
            onRangeOpacityReleased={onRangeOpacityReleased}
        />

        <div
            className={"unselectable w3-right-align"}
            style={{ cursor: "pointer" }}
            onClick={(e) => props.onRangeZoomClicked()}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </div>
      </SideMenuRow>
  )
}