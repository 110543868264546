import {Cartesian2, Cartesian3, Color, Matrix4, Plane, SceneMode} from "cesium";
import {AttributeType, IAttributeTypeMap} from "../domain/AttributeType";
import ILayerTypes, {LayerType} from "../../../model/LayerType";
import IColorLegendConfiguration from "../domain/IColorLegendConfiguration";
import {ADJUSTED_POS_PROPERTY_NAME, TilesetUtils} from "../util/TilesetUtils";
import {IBoundingBox} from "../domain/IBoundingBox";
import {useSiteConfig} from "./useSiteConfig";
import {UserSessionState, useUserSessionContext} from "../components/Contexts/UserSessionContext";
import {IColorLegend, IColorLegendItem} from "./useColorLegendApi";
import {DEFAULT_NEUTRAL_COLOR, IStyle} from "../domain/IStyle";
import {useCallback} from "react";
import IColorLegendItemConfiguration from "../domain/IColorLegendItemConfiguration";
import {LegendType} from "../domain/LegendType";
import {SegmentDisplayStyle} from "../domain/ISecondarySegmentOptions";

export interface IColorConditionExpression {
  conditions: Array<string[]>; /* Array of [condition expression, color expression] */
}

export interface IStyleExpression {
  show: boolean|string,
  defines?: any;
  color: IColorConditionExpression|string|undefined;
}

// function darken( color: Color, darkeningFactor: number ): Color {
//   return new Color(
//       color.red * darkeningFactor,
//       color.green * darkeningFactor,
//       color.blue * darkeningFactor,
//   );
// }

/**
 * Produces a tileset style, given a pattern ID, layer type and attribute (if defined).
 *
 * @param sceneMode
 * @param patternId
 * @param layerType
 * @param attributeMappings
 * @param attributeOverride
 */
export const useTilesetStyleExpressionAsync = () => {
  const [userSession] = useUserSessionContext();
  const [siteConfig] = useSiteConfig();

  const colorLegendStates = userSession.colorLegendStates;
  const colorLegendConfigs = siteConfig.legendConfigs;
  const maskedColor = userSession.maskedColor;

  /**
   * Keep track of active color legend and config
   */
  const getColorLegend = useCallback((selectedAttribute: AttributeType|undefined): [IColorLegendConfiguration|undefined, IColorLegend|undefined] => {
    if ( !selectedAttribute || !colorLegendStates || !colorLegendConfigs ) {
      return [undefined, undefined];
    }
    const attributeIndex = AttributeType[selectedAttribute] as keyof IAttributeTypeMap<IColorLegendConfiguration> ;
    const colorLegendConfig = colorLegendConfigs[attributeIndex];
    const colorLegendState = colorLegendStates.get( selectedAttribute );

    return [colorLegendConfig, colorLegendState];
  }, [colorLegendConfigs, colorLegendStates]);

  /**
   * Compute (locally) the new tileset style
   */
  const getTilesetExpression = useCallback(( opts: {
    // sessionId: string,
    attributeType: AttributeType,
    layerType: LayerType,
    layerStyle: IStyle,
    combinedOpacity: number,
    sceneMode: SceneMode
  }): IStyleExpression =>
  {
    let styleExpression: IStyleExpression = {
      show: true,
      color: DEFAULT_NEUTRAL_COLOR.toCssHexString(),
    }

    if ( !colorLegendStates ) {
      console.log('no legend states');
      return styleExpression;
    }

    let [legendConfig, legendState] = getColorLegend( opts.attributeType );

    if ( !legendConfig ) {
      console.log('no legend config');
      return styleExpression;
    }
    if ( !legendState ) {
      console.log('no legend state');
      return styleExpression;
    }
    if ( !opts.attributeType ) {
      console.log('no selected attribute');
      return styleExpression;
    }

    function computeColorAndOpacityForLayerType( itemState: IColorLegendItem, itemConfig: IColorLegendItemConfiguration ): [ number, Color] {
      let isMaskable = false ;
      let hasVariableOpacity = true;
      let visibilityOverride: boolean|undefined ;
      let opacityOverride: number|undefined ;
      let darkeningFactor: number|undefined ;

      switch ( opts.layerType ) {
        case LayerType.BlastholeSecondarySegments:
          isMaskable = true ;
          break;
        case LayerType.CrossSectionX:
        case LayerType.CrossSectionY:
        case LayerType.CrossSectionZ:
          isMaskable = true ;
          hasVariableOpacity = false ;
          darkeningFactor = 0.35;
          break;
        case LayerType.Boundary:
          hasVariableOpacity = opts.sceneMode !== SceneMode.SCENE2D;
          visibilityOverride = opts.sceneMode === SceneMode.SCENE2D ? true : undefined;
          opacityOverride = opts.sceneMode === SceneMode.SCENE2D ? 0 : undefined;
          break;
        case LayerType.BlastholeClusters:
          hasVariableOpacity = opts.sceneMode !== SceneMode.SCENE2D ;
          visibilityOverride = opts.sceneMode === SceneMode.SCENE2D ? true : undefined ;
          opacityOverride = opts.sceneMode === SceneMode.SCENE2D ? 1.0 : undefined;
          break;
        default:
          break ;
      }

      if ( isMaskable ) {
        // const visibility = visibilityOverride !== undefined ? visibilityOverride : true;
        const opacity = hasVariableOpacity
            ? itemState.opacity
            : opacityOverride !== undefined
                ? opacityOverride
                : 1.0;
        const color = itemState.visible
            ? itemConfig.color.withAlpha(1)
            : maskedColor;

        if ( darkeningFactor ) {
          color.darken(darkeningFactor, color) ;
        }

        return [ opacity, color];

      } else {

        const visibility = visibilityOverride !== undefined ? visibilityOverride : itemState.visible;
        const opacity = visibility
          ? opacityOverride !== undefined
              ? opacityOverride
              : hasVariableOpacity
                  ? itemState.opacity
                  : 1.0
          : 0.0;

        const color = itemConfig.color.withAlpha(opacity) ;

        return [opacity, color];
      }
    }

    styleExpression.color = {
      conditions: []
    } as IColorConditionExpression ;

    let conditions = styleExpression.color.conditions;

    const legendType = legendConfig.legendType;
    const attributeName = AttributeType[opts.attributeType] ;

    // ... Undefined condition (for numeric-range type legends)
    if ( true ) {
      const [opacity, color] = computeColorAndOpacityForLayerType( legendState.colorInvalidValue, legendConfig.invalidItem ) ;
      const conditionExpr = featurePropertyConditionExpr([attributeName], "<", 0, true );
      const colorExpr = getColorExpr(color.withAlpha(opacity * opts.combinedOpacity));
      conditions.push([conditionExpr,colorExpr]);
    }

    // ... Value range conditions
    for ( let i = 0; i < legendConfig.items.length; i += 1 ) {
      let config = legendConfig.items[i];

      if ( legendType === LegendType.Ranges ) {
        const [opacity, color] = computeColorAndOpacityForLayerType( legendState.colorLegendItems[i], legendConfig.items[i] ) ;
        const rangeUpperLimit = config.range[1];
        const conditionExpr = featurePropertyConditionExpr([attributeName], "<=", rangeUpperLimit );
        const colorExpr = getColorExpr(color.withAlpha(opacity * opts.combinedOpacity));
        conditions.push([conditionExpr,colorExpr]);
      }
      else if ( legendType === LegendType.Enum || legendType === LegendType.Mapped ) {

        // ... if there was no mapping, then do the usual
        const [opacity, color] = computeColorAndOpacityForLayerType( legendState.colorLegendItems[i], legendConfig.items[i] ) ;

        const categoryValue = config.range[0];
        const conditionExpr = featurePropertyConditionExpr([attributeName], "===", categoryValue );
        const colorExpr = getColorExpr(color.withAlpha(opacity * opts.combinedOpacity));
        conditions.push([conditionExpr,colorExpr]);
      }
    }

    // ... Range exceeded condition
    if ( legendType === LegendType.Ranges ) {
      const itemState = legendState.colorRangeExceeded ?? legendState.colorInvalidValue ;
      const itemConfig = legendConfig.rangeExceededItem ?? legendConfig.invalidItem;

      const [/*visibility, */opacity, color] = computeColorAndOpacityForLayerType( itemState, itemConfig ) ;
      const upperLimit = legendConfig.items[legendConfig.items.length-1].range[1];
      const conditionExpr = featurePropertyConditionExpr([attributeName], ">", upperLimit );
      const colorExpr = getColorExpr(color.withAlpha(opacity * opts.combinedOpacity));
      conditions.push([conditionExpr,colorExpr]);
    }

    // ... Catch-all (use layer defaults)
    {
      const opacity = opts.layerStyle.visibility ? opts.layerStyle.opacity : 0.0;
      const color = opts.layerStyle.color.withAlpha(1);
      const conditionExpr = "true";
      const colorExpr = getColorExpr(color.withAlpha(opacity * opts.combinedOpacity));
      conditions.push([conditionExpr, colorExpr]);
    }

    // console.log(`styleExpression: ${JSON.stringify(styleExpression)}`);
    return styleExpression ;

  }, [colorLegendStates, maskedColor, getColorLegend]);

  const callback = useCallback(async (patternId: string, layerType: LayerType, attributeType: AttributeType|undefined, sceneMode: SceneMode ) => {

    let result: IStyleExpression = {
      show: true,
      color: DEFAULT_NEUTRAL_COLOR.toCssHexString()
    }
    let combinedOpacity = 1.0 ;

    // ... Apply the pattern's style
    // const patternStyle = viewModel.patternStyles?.get(patternId);
    // if ( !patternStyle ) {
    //   console.log('no pattern style');
    //   return result;
    // }
    //
    // result.show &&= patternStyle.visibility;
    // result.color = getColorExpr((patternStyle.color ?? DEFAULT_NEUTRAL_COLOR).withAlpha(patternStyle.opacity));
    // combinedOpacity *= patternStyle.opacity;

    // ... Apply the layer's style
    // const layerStyle = result.show ? viewModel.layerStyles[LayerType[layerType] as keyof ILayerStyleState] : undefined ;
    // if ( !layerStyle ) {
    //   console.log('no layer style');
    //   return result ;
    // }

    const layerStyle = {
      visibility: userSession.layersVisibility[LayerType[layerType] as keyof ILayerTypes<boolean>],
      opacity: userSession.layersOpacity[LayerType[layerType] as keyof ILayerTypes<number>],
      color: userSession.layersColor[LayerType[layerType] as keyof ILayerTypes<Color>],
    }

    if ( sceneMode === SceneMode.SCENE2D ) {
      if (layerType === LayerType.Boundary) {
        layerStyle.visibility = true;
        layerStyle.opacity = 0;
      } else if (layerType === LayerType.BlastholeClusters) {
        layerStyle.visibility = true;
        layerStyle.opacity = 1.0;
      }
    }

    result.show &&= layerStyle.visibility;
    result.color = getColorExpr(
        layerStyle.color.withAlpha(
            layerStyle.opacity
        )
    );

    // ... Nothing much left to do for some layers
    if ( layerType === LayerType.Boundary ) {
      return result ;
    }

    combinedOpacity *= layerStyle.opacity;

    // ... Apply the selected attribute's style
    if ( !attributeType || !result.show ) {
      console.log('no selected attribute or no show');
      return result ;
    }

    return getTilesetExpression({
      // sessionId: userSession.sessionId,
      attributeType: attributeType,
      layerType: layerType,
      layerStyle: layerStyle,
      combinedOpacity: combinedOpacity,
      sceneMode: sceneMode
    });
  }, [ userSession.layersVisibility, userSession.layersOpacity, userSession.layersColor, getTilesetExpression]);

  return callback ;
}



/**
 * Constructs a tileset style *condition* from:
 * - a given set of feature property names,
 * - a value to compare to
 * - an optional condition specifying if the condition should include "undefined properties"
 *
 * @param keys
 * @param op
 * @param value
 * @param includeUndefined
 * @private
 */
export function featurePropertyConditionExpr( keys: string[], op: string, value: any, includeUndefined = false ): string {
  let expressions: string[] = [] ;

  keys.forEach( (key) => {
    if ( includeUndefined ) {
      expressions.push(`((\${feature['${key}']} === undefined) || (isNaN(\${feature['${key}']})))`);
    }
    expressions.push(`((\${feature['${key}']} !== undefined) && (\${feature['${key}']} ${op} ${value}))`) ;
  });

  return expressions.length > 0 ? ('(' + expressions.join(" || ") + ')') : "true" ;
}
export function featurePropertyConditionExpr2( keys: string[], op: string, value: any, includeUndefined = false ): string {
  let expressions: string[] = [] ;

  keys.forEach( (key) => {
    if ( includeUndefined ) {
      expressions.push(`((\${${key}} === undefined) || (isNaN(\${${key}})))`);
    }
    expressions.push(`((\${${key}} !== undefined) && (\${${key}} ${op} ${value}))`) ;
  });

  return expressions.length > 0 ? ('(' + expressions.join(" || ") + ')') : "true" ;
}
export function featurePropertyConditionExprMinMax( keys: string[], valueMin: number, valueMax: number, includeUndefined = false ): string {
  let expressions: string[] = [] ;

  keys.forEach( (key) => {
    if ( includeUndefined ) {
      expressions.push(`((\${${key}} === undefined) || (isNaN(\${${key}})))`);
    }
    expressions.push(`((\${${key}} !== undefined) && (\${${key}} >= ${valueMin} && \${${key}} < ${valueMax}))`) ;
  });

  return expressions.length > 0 ? ('(' + expressions.join(" || ") + ')') : "true" ;
}

/**
 * Constructs a color expression for Cesium tile styles
 *
 * rgba(${red}, ${green}, ${blue}, (${volume} > 100 ? 0.5 : 1.0))
 *
 * rgba(${selected} ? (0.25 * (255 - ${colorOpaque.red}) : ${colorOpaque.red},${selected} ? (0.25 * (255 - ${colorOpaque.green}) : ${colorOpaque.green}, ${selected} ? (0.25 * (255 - ${colorOpaque.blue}) : ${colorOpaque.blue}, ${color.alpha.toFixed(2)})
 *
 * @param color
 * @private
 */
export function getColorExpr( color: Color ): string {
  // return `color('${color.withAlpha(1).toCssHexString()}', ${color.alpha.toFixed(2)})`;

  const colorOpaque = color.withAlpha(1);

  const tintFactor = 0.50;

  const r = +Math.round(255 * colorOpaque.red).toFixed(0);
  const g = +Math.round(255 * colorOpaque.green).toFixed(0);
  const b = +Math.round(255 * colorOpaque.blue).toFixed(0);

  const rt = +Math.round(r + tintFactor * (255 - r));
  const gt = +Math.round(g + tintFactor * (255 - g));
  const bt = +Math.round(b + tintFactor * (255 - b));

  return `rgba((\${tinted} === true ? ${rt}: ${r}), (\${tinted} === true ? ${gt} : ${g}), (\${tinted} === true ? ${bt} : ${b}), ${color.alpha.toFixed(2)})`;
}

export function getTintedColor( color: Color, tintFactor: number = 0.75 ): Color {
  const colorOpaque = color;
  const r = 255 * colorOpaque.red;
  const g = 255 * colorOpaque.green;
  const b = 255 * colorOpaque.blue;
  const rt = r + tintFactor * (255 - r);
  const gt = g + tintFactor * (255 - g);
  const bt = b + tintFactor * (255 - b);
  return Color.fromBytes( rt, gt, bt )
}

/**
 * Gets a sub-drill visibility expression
 * @param showSubDrill
 */
export function getSubDrillConditionExpression(showSubDrill: boolean): string {
  let expressions: string[] = [] ;
  if (showSubDrill) {
    expressions.push(featurePropertyConditionExpr([TilesetUtils.SUBDRILL_INDEX_NAME], ">=", 0, true));
  } else {
    expressions.push(featurePropertyConditionExpr([TilesetUtils.SUBDRILL_INDEX_NAME], "===", 0, true));
  }
  return expressions.length > 0 ? expressions.join(" || ") : "true" ;
}

export const getPlaneXZ = (offset: number, modelMatrix: Matrix4, boundingBox: IBoundingBox, stepSize?: number ): Plane|undefined => {
  if (!boundingBox) {
    return undefined;
  }
  let off1 = offset * (stepSize ?? 1) ;
  let off2 = (offset + 1) * (stepSize ?? 1) ;

  let p1 = TilesetUtils.localToWorld( new Cartesian3( boundingBox.minPos.x, boundingBox.minPos.y + off1, boundingBox.minPos.z ), modelMatrix )
  let p2 = TilesetUtils.localToWorld( new Cartesian3( boundingBox.minPos.x, boundingBox.minPos.y + off2, boundingBox.minPos.z ), modelMatrix )
  let normal = Cartesian3.normalize(Cartesian3.subtract( p2, p1, new Cartesian3() ), new Cartesian3());
  return Plane.fromPointNormal(p1, normal);
}

export const getPlaneYZ =(offset: number, modelMatrix: Matrix4, boundingBox: IBoundingBox, stepSize?: number ): Plane|undefined => {
  if (!boundingBox) {
    return undefined;
  }
  let off1 = offset * (stepSize ?? 1) ;
  let off2 = (offset + 1) * (stepSize ?? 1) ;

  let p1 = TilesetUtils.localToWorld( new Cartesian3( boundingBox.minPos.x + off1, boundingBox.minPos.y, boundingBox.minPos.z ), modelMatrix )
  let p2 = TilesetUtils.localToWorld( new Cartesian3( boundingBox.minPos.x + off2, boundingBox.minPos.y, boundingBox.minPos.z ), modelMatrix )
  let normal = Cartesian3.normalize(Cartesian3.subtract( p2, p1, new Cartesian3() ), new Cartesian3());
  return Plane.fromPointNormal(p1, normal);
}

/**
 * Adds a visibility condition for items based on their distance from a cross-section, to be used in the building of a
 * tilesete's style expression.
 * @param styleExpression
 * @param userSession
 * @param distanceThreshold
 * @param boundingBox
 * @param modelMatrix
 * @param stepSize
 */
export function addDistFromSectionAdditionalConditions(
    styleExpression: IStyleExpression,
    userSession: UserSessionState,
    distanceThreshold: number,
    modelMatrix: Matrix4,
    stepSize?: Cartesian3
): IStyleExpression {

  if (!userSession.boundingBox) {
    throw new Error('addDistFromSectionAdditionalConditions: expected boundingBox to be defined');
  }

  // ... If we're showing the vertical cross-sections, then we want to hide holes that are far away from
  //     the cut; this ONLY applies if the XY cross-section (horizontal) is NOT enabled.
  let planeXZ = getPlaneXZ( userSession.crossSectionOffsetY, modelMatrix, userSession.boundingBox, stepSize?.y ?? 1 );
  if (!planeXZ) {
    console.log("addDistFromSectionAdditionalConditions: boundingBox.getPlaneXZ has failed");
    return styleExpression;
  }
  // console.log(`planeXZ=d=${planeXZ.distance}, n=${planeXZ.normal}, modelMatrix=${modelMatrix}`);

  let planeYZ = getPlaneYZ( userSession.crossSectionOffsetX, modelMatrix, userSession.boundingBox, stepSize?.x ?? 1 );
  if (!planeYZ) {
    console.log("addDistFromSectionAdditionalConditions: boundingBox.getPlaneYZ has failed");
    return styleExpression;
  }
  // console.log(`planeYZ=d=${planeYZ.distance}, n=${planeYZ.normal}, modelMatrix=${modelMatrix}`);

  let distXZ = planeXZ.distance;
  let normXZ = planeXZ.normal;
  let distYZ = planeYZ.distance;
  let normYZ = planeYZ.normal;

  // const ADJUSTED_POS = new Cartesian3(18, 6378135, -80 );
  // let distFromXZ = Math.abs(Cartesian3.dot( normXZ, ADJUSTED_POS )) + distXZ;
  // let distFromYZ = Math.abs(Cartesian3.dot( normYZ, ADJUSTED_POS )) + distYZ;

  // ... Only filter for the cross-section that is "active" (i.e. selected)
  let hasSelectedXZPlane = userSession.selectedCrossSection === LayerType.CrossSectionY;
  let hasSelectedYZPlane = userSession.selectedCrossSection === LayerType.CrossSectionX;
  let sectionViewDistanceThreshold = distanceThreshold ?? 100000000;

  /*
   * Computation for point-plane distance:
   * Cartesian3.dot(plane.normal, point) + plane.distance;
   */
  styleExpression.defines = {
    ...(styleExpression.defines ?? {}),
    distFromXZ: `(\${${ADJUSTED_POS_PROPERTY_NAME}} !== undefined) ? abs(dot(vec3(${normXZ.x},${normXZ.y},${normXZ.z}), vec3(\${${ADJUSTED_POS_PROPERTY_NAME}[0]},\${${ADJUSTED_POS_PROPERTY_NAME}[1]},\${${ADJUSTED_POS_PROPERTY_NAME}[2]})) + ${distXZ}) : 0`,
    distFromYZ: `(\${${ADJUSTED_POS_PROPERTY_NAME}} !== undefined) ? abs(dot(vec3(${normYZ.x},${normYZ.y},${normYZ.z}), vec3(\${${ADJUSTED_POS_PROPERTY_NAME}[0]},\${${ADJUSTED_POS_PROPERTY_NAME}[1]},\${${ADJUSTED_POS_PROPERTY_NAME}[2]})) + ${distYZ}) : 0`,
    dthresh: sectionViewDistanceThreshold,
  }

  if (hasSelectedXZPlane) {
    if ( typeof styleExpression.show !== 'string' || styleExpression.show.indexOf('distFromXZ') === -1 ) {
      styleExpression.show = `(${styleExpression.show}) && (\${distFromXZ} < \${dthresh})`;
    }
  } else if (hasSelectedYZPlane) {
    if ( typeof styleExpression.show !== 'string' || styleExpression.show.indexOf('distFromYZ') === -1 ) {
      styleExpression.show = `(${styleExpression.show}) && (\${distFromYZ} < \${dthresh})`;
    }
  } else if (!userSession.layersVisibility.CrossSectionZ) {
    styleExpression.show = false; // hide holes (by default) if no selected vertical CS
  }

  return styleExpression;
}

/**
 * In the case of blasthole fractures, we xo not want to show fractures that reside within the collar depth, as
 * these are caused by the broken material thatis always expected at the top of the borehole.
 * @private
 * @param styleExpression
 * @param collarDepth
 */
export function addCollarDepthAdditionalConditions(styleExpression: IStyleExpression, collarDepth: number ): IStyleExpression {

  styleExpression.defines = {
    ...(styleExpression.defines ?? {}),
    notWithinCollarDepth: TilesetUtils.featurePropertyConditionExpression(
        ["Depth"],
        ">",
        collarDepth
    ),
  };
  if ( typeof styleExpression.show !== 'string' || styleExpression.show.indexOf('notWithinCollarDepth') === -1 ) {
    styleExpression.show = `(${styleExpression.show}) && (\${notWithinCollarDepth})`;
  }

  return styleExpression ;
}

/**
 * For blasthole fractures, this condition hides any fractures where the prominence is less than the given threshold
 * @param styleExpression
 * @param prominenceFilter
 */
export function addProminenceFilterConditions( styleExpression: IStyleExpression, prominenceFilter: number ): IStyleExpression {
  styleExpression.defines = {
    ...(styleExpression.defines ?? {}),
    aboveProminenceFilter: TilesetUtils.featurePropertyConditionExpression(
        ["Prominence"],
        ">",
        prominenceFilter
    ),
  };

  if ( typeof styleExpression.show !== 'string' || styleExpression.show.indexOf('aboveProminenceFilter') === -1 ) {
    styleExpression.show = `(${styleExpression.show}) && (\${aboveProminenceFilter})`;
  }

  return styleExpression ;
}

export function addExcludedCondition( styleExpression: IStyleExpression ): IStyleExpression {
  if ( typeof styleExpression.show !== 'string' || styleExpression.show.indexOf('excluded') === -1 ) {
    styleExpression.show = `(${styleExpression.show}) && (\${excluded} !== true)`;
  }
  return styleExpression ;
}

export function addSecondarySegmentStyleCondition( styleExpression: IStyleExpression, segmentStyle: SegmentDisplayStyle, selectedAttribute: AttributeType|undefined ): IStyleExpression {
  if ( typeof styleExpression.show !== 'string' || styleExpression.show.indexOf('width') === -1 ) {
    switch( segmentStyle ) {
      case "small":
        break;
      case "var":
      case "large":
        // ... Special case: if value of selected attribute is invalid, or if value's color is masked: use "small" rendering
        break;
    }

    styleExpression.show = `(${styleExpression.show}) && (\${width} === '${segmentStyle}')`;
  }
  return styleExpression ;
}
