import React, {useCallback, useEffect, useMemo, useState} from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useFetchWithAuth } from "../lib/auth/fetchWithAuth";
import {apiRoot} from "../lib/routes";
import {useHelp} from "../Help/HelpContextProvider";
import ColumnPane from "../components/Gen2/components/BasicControls/ColumnPane";
import RoundedPanel from "../components/Gen2/components/BasicControls/RoundedPanel";
import TitledSection from "../components/Gen2/components/BasicControls/TitledSection";
import ContentPage from "../components/Gen2/components/BasicControls/ContentPage";
import NewCustomerForm from "../components/CustomerManagement/NewCustomerForm";
import CustomerSitesList from "../components/CustomerManagement/CustomerSitesList";
import ConfirmationDialog, {IConfirmationDialogProps} from "../components/Dialogs/ConfirmationDialog";
import PromptDialog from "../components/Dialogs/PromptDialog";

const MARGIN_HEIGHT = 16;//px

/**
 * Component page
 * @param t
 * @constructor
 */
const CustomerSites: React.FC<WithTranslation> = ({ t }) => {
  const customerSitesApi = useCustomerSitesApi();

  const [customerSites, setCustomerSites] = useState<ICustomerSite[]>([]);
  const [errors] = useState<any>({});
  const [, setHelpRequested] = useHelp();
  const [confirmDlgProps, setConfirmDlgProps] = useState<IConfirmationDialogProps>();
  const [promptSaveAs, setPromptSaveAs] = useState<boolean>();

  const fetchCustomerSites = customerSitesApi.fetchCustomerSites ;
  useEffect(() => {
    fetchCustomerSites()
        .then( result => setCustomerSites( result ) )
  }, [fetchCustomerSites]);

  const [columnPaneHeight, setColumnPaneHeight] = useState<number>();

  const maxWorkAreaHeight = useMemo(()=>{
    if (columnPaneHeight) {
      return columnPaneHeight - 2 * MARGIN_HEIGHT ;
    } else {
      return undefined ;
    }
  }, [columnPaneHeight]);

  const saveCustomerSite = customerSitesApi.saveCustomerSite ;
  const handleSaveCustomerSite = useCallback(( customerSite: ICustomerSite ) => {
    // console.log(`>>>handleSaveCustomerSite: ${JSON.stringify(customerSite)}`);
    saveCustomerSite( customerSite )
        .then( created => {
          setCustomerSites( prevState => [ ...prevState.filter(ics=>ics.id!==created.id), created ] );
        })
  }, [saveCustomerSite]);

  const deleteCustomerSite = customerSitesApi.deleteCustomerSite ;
  const handleDeleteCustomerSite = useCallback((customerSite: ICustomerSite) => {
    setConfirmDlgProps({
      title: t('Delete Customer Site'),
      prompt: (`${t("You are about to delete the customer site")} '${customerSite.name}' ${t("and all of its data.  Proceed?")}`),
      onAccept: () => {
        // console.log(`>>>handleDeleteCustomerSite: ${JSON.stringify(customerSite)}`);
        deleteCustomerSite( customerSite )
            .then( deleted => {
              deleted && setCustomerSites( prevState => prevState.filter(ics=>ics.id!==deleted.id) );
            })
        setConfirmDlgProps( undefined ) ;
      },
      onCancel: () => {
        setConfirmDlgProps( undefined ) ;
      }
    } as IConfirmationDialogProps);
  }, [t, deleteCustomerSite]);

  function download(content: any, fileName: string, contentType: string) {
    let a = document.createElement("a");
    let file = new Blob([JSON.stringify( content )], {type: contentType});
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  function handleCreateBackup(fileName: string) {
    download( customerSites, fileName, 'application/json' );
  }

  return (
    <>
      <ContentPage>
        <ColumnPane
            className="w3-rest" style={{width: "calc(100%)"}}
            onSizeChanged={(w,h)=>setColumnPaneHeight(h)}
        >
          <RoundedPanel>
            <div
                style={{
                  maxHeight: maxWorkAreaHeight ? `${maxWorkAreaHeight}px` : undefined,
                  overflowY: 'auto'
                }}
            >
              {/*<div className={'w3-xlarge w3-margin-bottom'}>{t('Customers Management')}</div>*/}

              {/* New company form */}
              <TitledSection
                  w3BorderColor={'w3-border-light-blue'}
                  title={t("New Customer Site")}
                  onHelpClicked={()=>setHelpRequested({
                    helpTitle: t('New Customer Site Form'),
                    helpContent: <div style={{height: "250px"}}>Placeholder</div>,
                    width: "50vw",
                  })}
              >
                <NewCustomerForm
                    // className={'w3-border'}
                    style={{width: "33%"}}
                    onCreateNewCustomerSite={handleSaveCustomerSite}
                    errors={errors}
                />
              </TitledSection>

              {/* Companies list */}
              <TitledSection
                  className={'w3-margin-top'}
                  w3BorderColor={'w3-border-light-blue'}
                  title={t("Customer Sites")}
                  onHelpClicked={()=>setHelpRequested({
                    helpTitle: t('Customer Sites'),
                    helpContent: <div style={{height: "250px"}}>Placeholder</div>,
                    width: "50vw",
                  })}
              >
                <div style={{ width: "calc(100% - 8em)"}}>
                  <CustomerSitesList
                      customerSites={customerSites}
                      onCustomerSiteChanged={handleSaveCustomerSite}
                      onCustomerSiteDelete={handleDeleteCustomerSite}
                  />
                </div>
              </TitledSection>
            </div>
          </RoundedPanel>
        </ColumnPane>
      </ContentPage>
      { confirmDlgProps && <ConfirmationDialog {...confirmDlgProps} /> }
      { promptSaveAs && (
          <PromptDialog
              title={t('Save As')}
              prompt={`${t('Save As')}:`}
              defaultAnswer={t('backup.json')}
              onCancel={()=>setPromptSaveAs(false)}
              onAccept={(answer) => {
                handleCreateBackup(answer);
                setPromptSaveAs(false);
              }}
          />
      )}
    </>
  );
};

export default withTranslation()(CustomerSites);

/**
 * Model
 */
export interface ICustomerSite {
  id?: string|undefined;
  name: string;
  nick: string;
  dmmHost: string|undefined;
  dmmPrivatePort: string|number|undefined;
  dmmPublicPort: string|number|undefined;
  adminPassword:string;
}

/**
 * API
 * @param t
 * @constructor
 */
class CustomerSiteUrls {
  public static allRecordsUrl = () => `${apiRoot}/customerSites`;
  public static OneRecordUrl = (customerSiteId: string) => `${apiRoot}/customerSites/${customerSiteId}`;
}

export const useCustomerSitesApi = () => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchCustomerSites = useCallback(async (): Promise<ICustomerSite[]> => {
    return await fetchWithAuth( CustomerSiteUrls.allRecordsUrl() )
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`fetchCustomerSites: status=${resp.status}`)
          }
          return resp.json();
        })
        .then( json => {
          return (json.content as ICustomerSite[])
        })
  }, [fetchWithAuth]);

  const fetchCustomerSite = useCallback(async (customerSiteId: string): Promise<ICustomerSite> => {
    return await fetchWithAuth( CustomerSiteUrls.OneRecordUrl(customerSiteId) )
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`fetchCustomerSite: status=${resp.status}`)
          }
          return resp.json();
        })
        .then( json => {
          return json as ICustomerSite
        })
  }, [fetchWithAuth]);

  const saveCustomerSite = useCallback(async (customerSite: ICustomerSite): Promise<ICustomerSite> => {
    const isCreate = !customerSite.id ;
    const url = customerSite.id ? CustomerSiteUrls.OneRecordUrl(customerSite.id) : CustomerSiteUrls.allRecordsUrl() ;

    return await fetchWithAuth( url, {
          method: isCreate ? "POST" : "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify( customerSite )
        })
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`saveCustomerSite: status=${resp.status}`)
          }
          return isCreate ? resp.json() : customerSite;
        })
        .then( json => {
          return json as ICustomerSite
        })
  }, [fetchWithAuth]);

  const deleteCustomerSite = useCallback(async (customerSite: ICustomerSite): Promise<ICustomerSite|undefined> => {
    if (customerSite.id) {
      return await fetchWithAuth(CustomerSiteUrls.OneRecordUrl(customerSite.id), {method: "DELETE"})
          .then(resp => {
            if (!resp.ok) {
              throw new Error(`saveCustomerSite: status=${resp.status}`)
            }
            return customerSite;
          })
    } else {
      console.error(`Customer site has no UUID: ${JSON.stringify(customerSite)}`) ;
      return undefined;
    }
  }, [fetchWithAuth]);


  return useMemo(()=>({
    fetchCustomerSites,
    fetchCustomerSite,
    saveCustomerSite,
    deleteCustomerSite
  }), [
    fetchCustomerSites,
    fetchCustomerSite,
    saveCustomerSite,
    deleteCustomerSite
  ]);
}

