import ReactDOM from "react-dom";
import {CSSProperties, useEffect, useMemo} from "react";

interface Props {
  x: number | undefined;
  y: number | undefined;
  onDismiss: () => void;
}

const DropdownPortal: React.FC<Props> = (props) => {
  const dropdownRoot = document.getElementById("dropdown-menu-root");
  const el = document.createElement("div");

  const handleEventOutside = (event: any) => {
    if (!el.contains(event.target)) {
      props.onDismiss();
    }
  };

  useEffect(() => {
    dropdownRoot?.appendChild(el);
    document.addEventListener("mousedown", handleEventOutside, true);
    document.addEventListener("scroll", handleEventOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleEventOutside, true);
      document.removeEventListener("scroll", handleEventOutside, true);
      dropdownRoot?.removeChild(el);
    };
  }, []);

  return ReactDOM.createPortal(
    <div style={{ position: "absolute", top: props.y, left: props.x }}>
      {props.children}
    </div>,
    el
  );
};

export default DropdownPortal;
