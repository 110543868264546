import {apiRoot} from "../../../lib/routes";
import {AttributeType} from "../domain/AttributeType";
import {useFetchWithAuth} from "../../../lib/auth/fetchWithAuth";
import {LayerType} from "../../../model/LayerType";
import {IStyleExpression} from "./useTilesetStyleExpression";
import {IColorLegendItemDto, IColorLegendStateDto} from "../model/IColorLegendStateDto";

class ColorLegendApi {
  public static createUrl = (sessionId: string) =>
      `${apiRoot}/userSession/${sessionId}/colorlegend`;
  public static readAllUrl = (sessionId: string) =>
      `${apiRoot}/userSession/${sessionId}/colorlegend`;
  public static readOneUrl = (sessionId: string, attributeType: AttributeType) =>
      `${apiRoot}/userSession/${sessionId}/colorlegend/${AttributeType[attributeType]}`;
  public static updateOneUrl = (sessionId: string, attributeType: AttributeType) =>
      `${apiRoot}/userSession/${sessionId}/colorlegend/${AttributeType[attributeType]}`;
  public static deleteAllUrl = (sessionId: string) =>
      `${apiRoot}/userSession/${sessionId}/colorlegend`;
  public static getTilesetUrl = (sessionId: string, attributeType: AttributeType, layerType: LayerType, opacity: number, isMap2D: boolean) =>
      `${apiRoot}/userSession/${sessionId}/colorlegend/${AttributeType[attributeType]}/style?layerType=${LayerType[layerType]}&opacity=${opacity}&isMap2D=${isMap2D}`;
}

export interface IColorLegend {
  uuid?: string;
  attributeType: AttributeType;
  colorInvalidValue: IColorLegendItem;
  colorRangeExceeded?: IColorLegendItem;
  colorLegendItems: IColorLegendItem[];
}

export interface IColorLegendItem {
  uuid: string;
  label: string;
  visible: boolean;
  opacity: number;
}

function dtoToColorLegendItem( dto: any ): IColorLegendItem {
  return {
    uuid: dto.uuid,
    label: dto.label,
    visible: dto.visible,
    opacity: dto.opacity,
  } as IColorLegendItem ;
}
function colorLegendItemToDto( colorLegendItem: IColorLegendItem ): IColorLegendItemDto {
  return {
    uuid: colorLegendItem.uuid,
    label: colorLegendItem.label,
    visible: colorLegendItem.visible,
    opacity: colorLegendItem.opacity
  } as IColorLegendItemDto;
}
function dtoToColorLegend( dto: IColorLegendStateDto ): IColorLegend {
  // console.log(`--- dtoToColorLegend: ${JSON.stringify(dto)}`);
  return {
    uuid: dto.uuid,
    attributeType: AttributeType[dto.attributeType as keyof typeof AttributeType],
    colorInvalidValue: dtoToColorLegendItem(dto.colorInvalidValue),
    colorRangeExceeded: dto.colorRangeExceeded ? dtoToColorLegendItem(dto.colorRangeExceeded) : undefined,
    colorLegendItems: (dto.colorLegendItems as any[]).map( (item) => dtoToColorLegendItem(item) )
  } as IColorLegend;
}
function colorLegendToDto( colorLegend: IColorLegend ): IColorLegendStateDto {
  return {
    uuid: colorLegend.uuid,
    attributeType: AttributeType[colorLegend.attributeType],
    colorInvalidValue: colorLegendItemToDto(colorLegend.colorInvalidValue),
    colorRangeExceeded: colorLegend.colorRangeExceeded ? colorLegendItemToDto(colorLegend.colorRangeExceeded) : null,
    colorLegendItems: (colorLegend.colorLegendItems as any[]).map( (item) => colorLegendItemToDto(item) )
  } as IColorLegendStateDto ;
}

export const useColorLegendApi = () => {
  const fetchWithAuth = useFetchWithAuth();

  const createColorLegend = async ( sessionId: string, colorLegend: IColorLegend ) => {
    return await fetchWithAuth( ColorLegendApi.createUrl(sessionId), {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(colorLegendToDto(colorLegend))
        })
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`!!! createColorLegend: status=${resp.status}`);
          }
          return resp.json();
        })
        .then( json => {
          return dtoToColorLegend( json )
        })
  }

  const fetchAllColorLegends = async (sessionId: string) => {
    return await fetchWithAuth( ColorLegendApi.readAllUrl( sessionId ) )
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`!!! fetchColorAllLegends: status=${resp.status}`);
          }
          return resp.json();
        })
        .then( json => {
          return ( json as IColorLegendStateDto[] ).map( (item) => dtoToColorLegend( item ) )
        })
  }

  const fetchOneColorLegend = async (sessionId: string, attributeType: AttributeType) => {
    return await fetchWithAuth( ColorLegendApi.readOneUrl( sessionId, attributeType ) )
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`!!! fetchOneColorLegend: status=${resp.status}`);
          }
          return resp.json();
        })
        .then( json => {
          return dtoToColorLegend( json as IColorLegendStateDto )
        })
  }

  const updateColorLegend = async (sessionId: string, colorLegend: IColorLegend ) => {
    // console.log(`>>>updateColorLegend: ${ColorLegendApi.updateOneUrl( sessionId, colorLegend.attributeType )}`);
    return await fetchWithAuth( ColorLegendApi.updateOneUrl( sessionId, colorLegend.attributeType ), {
          method: "PUT",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(colorLegendToDto(colorLegend))
        })
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`!!! updateColorLegend: status=${resp.status}`);
          }
          return resp.json();
        })
        .then( json => {
          return dtoToColorLegend( json );
        })
  }

  const deleteAllColorLegends = async (sessionId: string) => {
    await fetchWithAuth( ColorLegendApi.deleteAllUrl(sessionId), {
          method: "DELETE"
        })
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`!!! deleteAllColorLegends: status=${resp.status}`);
          }
        })
  }

  const getTilesetExpression = async ( sessionId: string, attributeType: AttributeType, layerType: LayerType, opacity: number, isMap2D: boolean ): Promise<IStyleExpression> => {
    console.log(`getTilesetExpression: ${ColorLegendApi.getTilesetUrl( sessionId, attributeType, layerType, opacity, isMap2D )}`)
    return await fetchWithAuth( ColorLegendApi.getTilesetUrl( sessionId, attributeType, layerType, opacity, isMap2D ) )
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`!!! getTilesetExpression: status=${resp.status}`);
          }
          return resp.json();
        })
        .then( json => {
          return json as IStyleExpression ;
        })
  }

  return {
    createColorLegend,
    fetchAllColorLegends,
    fetchOneColorLegend,
    updateColorLegend,
    deleteAllColorLegends,
    getTilesetExpression
  }
}
