import {useFetchWithAuth} from "../../../lib/auth/fetchWithAuth";
import {useCallback, useMemo} from "react";
import {INotification} from "./INotification";

/**
 * API endpoint URL
 */
const apiEndpoint = ( window
        ? (window as any).env?.REACT_APP_API_ENDPOINT
        : process.env.REACT_APP_API_ENDPOINT
) || "http://localhost:8080";
const apiBasePath = "api/4.0";
const apiRoot = `${apiEndpoint}/${apiBasePath}`;

export const useServerNotificationApi = () => {
  const fetchWithAuth = useFetchWithAuth();

  // ... API endpoint: get the list of the logged-in user's user event messages
  const fetchUserEvents = useCallback(async(): Promise<INotification[]> => {
    return await fetchWithAuth(`${apiRoot}/userEvent`)
        .then((resp)=>resp.json())
        .then((json)=> {
          return (json.content as any[]).map( json => {
            let notificationType: "info"|"warning"|"error";
            switch( `${json.eventType}` ) {
              case "Notification":
                notificationType = "info";
                break ;
              case "Warning":
                notificationType = "warning";
                break ;
              case "Error":
                notificationType = "error";
                break ;
              default:
                throw new Error(`Invalid server notification type: ${json.eventType}`);
            }
            return {
              id: json.id,
              text: json.description,
              type: notificationType,
              timeStamp: Date.now(),
              isRead: false,
              source: "server"
            } as INotification
          });
        })
  }, [fetchWithAuth]);

  // ... API endpoint: deletes a user event
  const deleteUserEvents = useCallback(async (notifications: INotification[]): Promise<INotification[]> =>{
    if ( notifications.length === 0 ) {
      return notifications;
    }
    let queryParams = notifications
        .filter( notification => notification.source === "server" )
        .map( notification => `userEventId=${notification.id}`);
    let queryParamString = queryParams.join("&");
    return await fetchWithAuth(`${apiRoot}/userEvent?${queryParamString}`, { method: "DELETE" })
        .then( resp => {
          if ( !resp.ok ) {
            throw new Error(`deleteUserEvents has failed: ${resp.statusText}; status=${resp.status}`);
          }
          return notifications;
        })
  }, [fetchWithAuth]);

  return useMemo(() => ({
    fetchUserEvents,
    deleteUserEvents,
  }), [
    fetchUserEvents,
    deleteUserEvents,
  ]) ;
}