import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import SideMenuRow from "../../../SideMenuItems/SideMenuRow";
import React, {useCallback} from "react";

interface IProps {
  id?:string;
  checked: boolean;
  label: string;
  onCheckedChanged: (checked: boolean)=>void;
  onDotMenuClicked?: ()=>void;
  className?:string|undefined;
}

const CheckboxLabelDotMenu: React.FC<IProps> = (props) => {

  const onDotMenuCallback = useCallback(()=>{
    props.onDotMenuClicked && props.onDotMenuClicked();
  }, [])

  return (
      <SideMenuRow
          id={props.id}
          widths={["20%", "60%", "20%"]}
          className={`w3-margin-bottom ${props.className}`}
      >
        <input
            id={`cb_${props.label}`}
            type="checkbox"
            className="w3-check w3-left"
            checked={props.checked}
            onChange={(e) => props.onCheckedChanged(e.target.checked)}
        />
        <label
            className={"unselectable w3-left"}
            style={{ fontSize: "12px"}}
            htmlFor={`cb_${props.label}`}
        >
          {props.label}
        </label>
        { props.onDotMenuClicked && (
            <FontAwesomeIcon
                className="unselectable w3-right w3-padding-small w3-hover-text-light-blue"
                icon={faEllipsisVertical}
                onClick={onDotMenuCallback}
            />
        )}
      </SideMenuRow>
  )
}

export default CheckboxLabelDotMenu;
