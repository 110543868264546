import React, {useEffect, useState} from "react";
import IVector3 from "../../model/IVector3";

interface ITranslationVectorEditorProps {
    enabled: boolean;
    translation: IVector3;
    onChange: (translation: IVector3) => void;
}

const TranslationVectorEditor: React.FC<ITranslationVectorEditorProps> =
({
     enabled,
     translation,
     onChange
}) => {
    const [tX, setTX] = useState<number>(translation.x) ;
    const [tY, setTY] = useState<number>(translation.y) ;
    const [tZ, setTZ] = useState<number>(translation.z) ;

    const onXChanged = (value: number ) => {
        setTX( value ) ;
        onChange({x: value, y: tY, z: tZ});
    };
    const onYChanged = (value: number ) => {
        setTY( value ) ;
        onChange({x: tX, y: value, z: tZ});
    };
    const onZChanged = (value: number ) => {
        setTZ( value ) ;
        onChange({x: tX, y: tY, z: value});
    };

    useEffect(()=>{
        setTX( translation.x ) ;
        setTY( translation.y ) ;
        setTZ( translation.z ) ;
    }, [translation]);

    return (
        <>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <input
                                style={{width:"100px"}}
                                type={"number"}
                                className={"w3-center"}
                                value={tX}
                                onChange={(e)=>onXChanged(+e.currentTarget.value)}
                                disabled={!enabled}
                            />
                        </td>
                        <td>
                            <input
                                style={{width:"100px"}}
                                type={"number"}
                                className={"w3-center"}
                                value={tY}
                                onChange={(e)=>onYChanged(+e.currentTarget.value)}
                                disabled={!enabled}
                            />
                        </td>
                        <td>
                            <input
                                style={{width:"100px"}}
                                type={"number"}
                                className={"w3-center"}
                                value={tZ}
                                onChange={(e)=>onZChanged(+e.currentTarget.value)}
                                disabled={!enabled}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default TranslationVectorEditor ;
