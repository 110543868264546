import React, {useCallback, useEffect, useState} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {t} from "i18next";
import {
    ISecondarySegmentOptions,
    isSegmentDisplayStyle,
    strToSegmentDisplayStyle
} from "../Gen2/domain/ISecondarySegmentOptions";

interface IBlastholeLayerSettingsDialogProps extends WithTranslation {
    settings: ISecondarySegmentOptions;
    onAccept: (change: ISecondarySegmentOptions) => void;
    onCancel: () => void;
}

const BORDER_RADIUS = "5px";

const SecondarySegmentLayerSettingsDialog: React.FC<IBlastholeLayerSettingsDialogProps> =
({
    settings,
    onAccept,
    onCancel
}) => {

    const [segmentStyle, setSegmentStyle] = useState( settings.segmentDisplayStyle );

    useEffect(()=>setSegmentStyle(settings.segmentDisplayStyle), [settings.segmentDisplayStyle]);

    const handleSelectionChanged = useCallback((value: string) => {
        if ( isSegmentDisplayStyle(value)) {
            setSegmentStyle( strToSegmentDisplayStyle( value ) ) ;
        }
    }, [setSegmentStyle]);

    return (
        <>
            <div className="w3-modal" style={{display: "block"}}>
                <div className="w3-modal-content w3-card-4 w3-theme-d4" style={{borderRadius: BORDER_RADIUS}}>
                    <header className="w3-container w3-light-blue w3-center" style={{borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0px 0px`}}>
                        <h6>{t("blast_hole_settings")}</h6>
                    </header>

                    <table className="w3-table">
                        <tbody className="w3-container w3-padding">
                            <tr className={"w3-padding"}>
                                <td className={"w3-center"}>
                                    {t("Segment Style")}
                                </td>
                                <td>
                                    <select
                                        className={`w3-select w3-theme-d4 w3-center w3-border w3-border-grey w3-round-medium`}
                                        style={{fontSize: "12px", cursor: "pointer", maxWidth: "50%" }}
                                        name="option"
                                        value={segmentStyle}
                                        onChange={(e) => handleSelectionChanged(e.target.value) }
                                    >
                                        <option value={'small'}>{t('small')}</option>
                                        <option value={'var'}>{t('var')}</option>
                                        <option value={'large'}>{t('large')}</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <footer className="w3-container w3-theme-d3 w3-padding" style={{borderRadius: `0px 0px ${BORDER_RADIUS} ${BORDER_RADIUS}`}}>
                        <button
                            className="w3-btn w3-left w3-grey w3-round-medium"
                            onClick={onCancel}
                        >
                            {t("cancel")}
                        </button>
                        <button
                            className="w3-btn w3-right w3-light-blue w3-round-medium"
                            onClick={()=>onAccept({
                                ...settings,
                                segmentDisplayStyle: segmentStyle
                            }) }
                        >
                            {t("accept")}
                        </button>
                    </footer>

                </div>
            </div>
        </>
    );
};

export default withTranslation()(SecondarySegmentLayerSettingsDialog);
