import React, {useEffect, useMemo, useState} from "react";
import {
  Camera,
  Cartesian2,
  Cartesian3,
  Cartographic,
  Color, CornerType,
  IntersectionTests,
  Plane,
  Ray,
  Scene,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType
} from "cesium";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {BoundingBoxUtil} from "../../domain/IBoundingBox";
import {CorridorGraphics, Entity} from "resium";
import {TilesetUtils} from "../../util/TilesetUtils";

const DEFAULT_CORRIDOR_WIDTH_METERS = 10;

interface IProps {
  scene: Scene;
  corridorWidth?: number|undefined;
  onSelectionChanged?: (start: {top: Cartesian3, bottom: Cartesian3}, end: Cartesian3)=>void;
  onSelectionStarted?: ()=>void;
  onSelectionFinished?: ()=>void;
}

const LinearSelection: React.FC<IProps> = (props) => {
  const [siteConfig] = useSiteConfig();
  const [userSession] = useUserSessionContext();
  const [startPos, setStartPos] = useState<{top: Cartesian3, bottom: Cartesian3}>();
  const [currPos, setCurrPos] = useState<Cartesian3>();
  const [measuring, setMeasuring] = useState<boolean>(false);

  const modelMatrix = siteConfig?.modelMatrix;
  const boundingBox = userSession.boundingBox;
  const corridorWidth = props.corridorWidth ?? DEFAULT_CORRIDOR_WIDTH_METERS;
  const onSelectionChanged = props.onSelectionChanged;
  const onSelectionStarted = props.onSelectionStarted;
  const onSelectionFinished = props.onSelectionFinished;

  // ... We will attach mouse event handlers
  const scene = props.scene;
  const canvas = scene.canvas;
  const screenSpaceEventHandler = useMemo(()=>{
    return new ScreenSpaceEventHandler(canvas);
  }, [canvas]);

  /**
   * Define the horizontal plane that will always be the top of the selection volume
   */
  const topPlane = useMemo(()=>{
    if ( !modelMatrix || !boundingBox ) {
      return undefined ;
    }
    const plane = BoundingBoxUtil.getTopPlane( boundingBox, modelMatrix ) ;
    if ( !plane ) {
      return undefined ;
    }
    return plane ; //ecefToLocalPlane( plane );
  }, [modelMatrix, boundingBox]);

  /**
   * Bottom and top of the selection box
   */
  const [height, extrudedHeight] = useMemo(()=>{
    if (!boundingBox || !modelMatrix) {
      return [undefined, undefined] ;
    }
    return [
      Cartographic.fromCartesian( TilesetUtils.localToWorld( boundingBox.minPos, modelMatrix ) ).height,
      Cartographic.fromCartesian( TilesetUtils.localToWorld( boundingBox.maxPos, modelMatrix ) ).height
    ];
  }, [boundingBox, modelMatrix]);

  /**
   * Mouse handling setup
   */
  const camera = scene.camera;
  useEffect(()=>{

    if ( topPlane && height ) {
      // ... On LEFT_CLICK
      screenSpaceEventHandler.setInputAction(function (e) {
        // ... First click
        if ( !measuring ) {
          if ( !startPos ) {
            const startTop = getTopPlaneIntersection(camera, e.position, topPlane);
            let startBottom ;

            if (startTop) {
              const cartographic = Cartographic.fromCartesian( startTop ) ;
              cartographic.height = height;
              startBottom = Cartographic.toCartesian( cartographic );
              const start = {
                top: startTop,
                bottom: startBottom
              }
              setStartPos(start);
              setMeasuring(true);
              onSelectionStarted && onSelectionStarted();
            } else {
              setStartPos(undefined);
            }
            setCurrPos(startTop);
          }
        }
        // ... Subsequent click
        else if (measuring && startPos && currPos) {
          onSelectionFinished && onSelectionFinished();
          onSelectionChanged && onSelectionChanged(startPos, currPos);
          setMeasuring(false);
        }
      }, ScreenSpaceEventType.LEFT_CLICK);

      // ... On mouse move
      screenSpaceEventHandler.setInputAction(function (e) {
        if (measuring) {
          const planeIntersection = getTopPlaneIntersection( camera, e.endPosition, topPlane );
          // ... First click
          if (planeIntersection ) {
            if (!currPos || Cartesian3.distance(planeIntersection, currPos) > 5) {
              setCurrPos(planeIntersection);
            }
          }
        }
      }, ScreenSpaceEventType.MOUSE_MOVE);
    }

    return () => {
      if ( topPlane ) {
        screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
        screenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);
      }
    }
  }, [camera, screenSpaceEventHandler, topPlane, measuring, startPos, currPos, height, onSelectionChanged, onSelectionStarted, onSelectionFinished]);

  return (
      <>
        { startPos && currPos && measuring &&
          <Entity
              show={true}
              name={'Linear selection'}
          >
            <CorridorGraphics
                positions={[startPos.top, currPos]}
                height={height}
                extrudedHeight={extrudedHeight}
                width={corridorWidth}
                material={Color.WHITE.withAlpha(0.01)}
                outline={true}
                outlineColor={Color.WHITE}
                outlineWidth={0.5}
                cornerType={CornerType.MITERED}
            />
          </Entity>
        }
      </>
  )
}

export default LinearSelection ;

function getTopPlaneIntersection( camera: Camera, windowPosition: Cartesian2, plane: Plane ) {
  if ( !windowPosition ) {
    return undefined;
  }
  let selectedPoint = camera.pickEllipsoid( windowPosition );
  if ( !selectedPoint ) {
    return undefined;
  }
  let direction = Cartesian3.subtract( selectedPoint, camera.positionWC, new Cartesian3() );
  const ray = new Ray( camera.positionWC, direction );
  return IntersectionTests.rayPlane( ray, plane );
}
