import {withTranslation, WithTranslation} from "react-i18next";
import {Cesium3DTileFeature} from "cesium";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {FeatureAdapter} from "../../../../lib/cesium/Tileset/FeatureAdapter";
import {t} from "i18next";
import {LayerType} from "../../../../model/LayerType";
import {AttributeType} from "../../domain/AttributeType";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import BlastholeSvg from "./BlastholeSvg";
import featureInfobox from "./FeatureInfobox";
import {TilesetUtils} from "../../util/TilesetUtils";

interface IFeatureInfoboxProps extends WithTranslation {
  feature: Cesium3DTileFeature
  className?:string;
  style?:any;
}

const BlastholeInfobox: React.FC<IFeatureInfoboxProps> = (props) => {
  const [, actions] = useSiteConfig();
  const [holeCount, setHoleCount] = useState<number>();
  const [drillIds, setDrillIds] = useState<string>();
  const [userSession,userSessionActions, userSessionMoreActions] = useUserSessionContext();
  const [segmentFeatures, setSegmentFeatures] = useState<Cesium3DTileFeature[]>([]);

  const getPatternHoleCount = userSessionActions.getPatternHoleCount ;
  const getPatternDrillIds = userSessionMoreActions.getPatternDrillIds ;

  useEffect(()=>{
    const patternId = props.feature.tileset.extras.ID ;
    // console.log(`patternId=${patternId}`);
    const layerType = LayerType[ props.feature.tileset.extras.Layer as keyof typeof LayerType] ;
    if ( layerType === LayerType.Boundary ) {
      setHoleCount(getPatternHoleCount(patternId));
      setDrillIds(getPatternDrillIds(patternId).join(", "));
    }
  }, [getPatternHoleCount, getPatternDrillIds, setHoleCount, props.feature, setDrillIds]);


  const feature =  props.feature ;
  const segStyle = userSession.segmentStyle.segmentDisplayStyle ;

  const [patternId, setPatternId] = useState<string>();
  const [holeId, setHoleId] = useState<string>();
  const [selectedFeature, setSelectedFeature] = useState<Cesium3DTileFeature>();

  useEffect(()=>{
    setSelectedFeature( feature );
  }, [feature]);

  const tileset = useMemo(()=>feature.tileset, [feature]);

  useEffect(()=>{
    const p = feature.getProperty("Pattern");
    p && setPatternId( p );

    const h = feature.getProperty("HoleID");
    h && setHoleId( h );

  }, [feature]);

  // ... Updates all segments for the selected hole
  useEffect(()=>{
    let result: Cesium3DTileFeature[] = [];
    if ( patternId && holeId && tileset && segStyle ) {
      TilesetUtils.visitFeatures(tileset, f => {
        const ss = f.getProperty("width");
        const p = f.getProperty("Pattern");
        const h = f.getProperty("HoleID");
        if (p === patternId && h === holeId && ss === segStyle ) {
          // console.log(`F: ${f.featureId}, ${p}, ${h}, ${ss}, ${f.getProperty("from_z")}`)
          result.push(f);
        }
      });
    }
    // console.log(`Found ${result.length} segments in this hole`);
    setSegmentFeatures( result );
  }, [patternId, holeId, tileset, segStyle, feature]);


  // useEffect(()=>console.log(`drillIds=${drillIds}`), [drillIds]);

  // ... Convert map if properties to an array
  const embeddedProperties = useMemo(()=>{
    return Array.from(FeatureAdapter.ATTRIBUTE_KEY_MAPPINGS.entries());
  }, []);

  // ... Set the title of the info box
  const title = useMemo(()=>{
    let patternName = props.feature.getProperty("Pattern");
    let holeName = props.feature.getProperty("HoleName");
    let holeId = props.feature.getProperty("HoleID");

    if ( patternName ) {
      if (holeName || holeId ) {
        if ( !holeId ) {
          return `${patternName} - ${holeName}`;
        }
        else if ( !holeName ) {
          return `${patternName} - ${holeId}`;
        }
        else if (holeName !== holeId.toString()) {
          return `${patternName} - ${holeName} (${holeId})`;
        }
        else {
          return `${patternName} - ${holeName}`;
        }
      } else {
        return patternName ;
      }
    } else {
      return props.feature.featureId.toString();
    }
  }, [props.feature]);

  // ... Define a callback for formatting each feature property
  const getFormattedValue = useCallback(( attributeName: string, attributeValue: any ): string|undefined =>{
    // console.log(`>>> getFormattedValue: ${attributeName} = ${attributeValue}`)
    if ( attributeValue === undefined || attributeValue === null ) {
      return undefined;
    }
    const precision = actions.getPrecisionForAttribute( attributeName );
    if ( precision !== undefined ) {
      if ( !Number.isNaN(attributeValue) ) {
        return attributeValue.toFixed(precision);
      }
    }
    else if ( attributeValue instanceof Array ) {
      return JSON.stringify(attributeValue, function(key, val) {
        return val.toFixed ? Number(val.toFixed(3)) : val;
      });
    }
    else if ( attributeValue instanceof Object ) {
      return JSON.stringify(attributeValue, function(key, val) {
        return val.toFixed ? Number(val.toFixed(3)) : val;
      });
    }
    return `${attributeValue ? t(attributeValue) : attributeValue}`;
  }, [actions]);

  const selectedAttributeName = useMemo((): string|undefined => {
    return userSession.selectedAttribute ? AttributeType[userSession.selectedAttribute] : undefined ;
  }, [userSession.selectedAttribute]);

  return (
      <div className={props.className} style={props.style}>
        <div className={"w3-center w3-small w3-margin-bottom"}>
          {title}
        </div>
        <div className={"w3-row"}>
          <div className={"w3-col s8"}>
            <table className={`w3-table w3-tiny w3-border ${props.className}`}>
            <tbody>
            { selectedFeature &&
              embeddedProperties
                  .filter( ([v, k], idx) => {
                    const attributeValue = selectedFeature.getProperty(k);
                    return (attributeValue !== undefined && attributeValue !== null);
                  } )
                  .map( ([v, k], idx) => {
                    const attributeValue = selectedFeature.getProperty(k);
                    const isSelected = (selectedAttributeName === k);
                    const isVariable = isSelected || (k === "from_z") || (k === "to_z") || (k === "Hardness");

                    return (
                        <tr
                            key={`FeatureInfobox_r${idx}`}
                            className={`w3-border ${props.className} ${isSelected ? "bold":""} ${isVariable ? "w3-text-white":""}`}
                        >
                          <td>{t(k)}</td>
                          <td>{getFormattedValue(k, attributeValue)}</td>
                        </tr>
                    );
                  })
            }
            { (holeCount !== undefined) && (
                <tr
                    className={`w3-border ${props.className}`}
                >
                  <td>{t("HoleCount")}</td>
                  <td>{holeCount}</td>
                </tr>
            )}
            { drillIds && (
                <tr
                    className={`w3-border ${props.className}`}
                >
                  <td>{t("Drills")}</td>
                  <td>{drillIds}</td>
                </tr>
            )}
            </tbody>
          </table>
          </div>
          <div className={"w3-col s4"}>
            <BlastholeSvg
                height={"100%"} width={"100%"}
                holeName={title}
                segmentFeatures={segmentFeatures}
                selectedFeature={selectedFeature}
                onSelectedFeatureChanged={setSelectedFeature}
            />
          </div>
        </div>
      </div>
  )
}

export default withTranslation()(BlastholeInfobox);
