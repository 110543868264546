import React, {useEffect, useState} from "react";
import {Cartesian2, Cartesian3, Ellipsoid, EllipsoidGeodesic, Scene} from "cesium";
import ScaleSvg from "./ScaleSvg";
import useCameraEvent from "../../hooks/useCameraEvent";

const maxBarWidth = 100;

/**
 * Get two side-by-side pixels as a pair of Cartesian coordinates
 * @param scene
 * @param scaling
 */
export function getPixelPoints( scene: Scene ): [Cartesian3,Cartesian3]|undefined {
  const width = scene.canvas.clientWidth ;
  const height = scene.canvas.clientHeight ;
  const canvasY = Math.floor(height/2) ;
  const leftCanvasX = Math.floor(width/2) ;
  const rightCanvasX = Math.floor((width/2)+1) ;

  const leftPixel = new Cartesian2( leftCanvasX, canvasY );
  const rightPixel = new Cartesian2( rightCanvasX, canvasY );

  const left = scene.camera.pickEllipsoid(leftPixel) ;
  const right = scene.camera.pickEllipsoid(rightPixel) ;

  if ( !left || !right ) {
    return undefined ;
  }

  // console.log(`getPixelPoints: ${[left, right]}`)
  return [left, right] ;
}

/**
 * measure the distance of 2 cartesian points on the globe
 * @param points
 * @param ellipsoid
 */
export function measureDistance( points: Cartesian3[], ellipsoid: Ellipsoid ) : number {
  if (points.length > 1) {
    const start = ellipsoid.cartesianToCartographic(points[0]);
    const end = ellipsoid.cartesianToCartographic(points[1]);
    const geodesic = new EllipsoidGeodesic(start, end, ellipsoid);
    // console.log(`measureDistance: ${geodesic.surfaceDistance}`)
    return geodesic.surfaceDistance;
  }
  return 0;
}

/**
 * Finds a rounded distance that best represents our current zoom level
 * @param pixelDistance
 * @param step
 */
export function pickBestDistance( pixelDistance: number, step:number=0.05 ): number|undefined {
  let dist = maxBarWidth * pixelDistance;
  let result = step * Math.round( dist / step ) ;
  // console.log(`pickBestDistance: ${result}`)
  return result ;
}

interface Scale2DProps {
  ellipsoid: Ellipsoid;
  style?: any;
  className?: string;
  scalePixelPoints: [Cartesian3, Cartesian3]|undefined;
}

/**
 * 2D Map view scale
 * @param props
 * @constructor
 */
const Scale2D: React.FC<Scale2DProps> = (props) => {

  const [text, setText] = useState<string>();
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [fontSize, setFontSize] = useState<number>();
  const [margin, setMargin] = useState<number>();

  useEffect(()=> {
    if (props.scalePixelPoints) {
      const measuredPixelDistance = measureDistance(props.scalePixelPoints, props.ellipsoid);

      // console.log(`measuredPixelDistance=${measuredPixelDistance.toFixed(2)}`);

      if (measuredPixelDistance) {
        // ... Compute the svg's parameters
        const scaleDistance = pickBestDistance(measuredPixelDistance, .05);
        // console.log(`scaleDistance=${scaleDistance?.toFixed(2)}`);

        if (scaleDistance) {
          const width = Math.round(scaleDistance / measuredPixelDistance );
          const svgWidth = width/*maxBarWidth*/ + 10;
          const fs = 14;

          if ( scaleDistance >= 1000 ) {
            setText(`${(scaleDistance / 1000).toFixed(2)} km`)
          } else {
            setText(`${scaleDistance.toFixed(2)} m`);
          }

          setFontSize( fs );
          setWidth(svgWidth);
          setHeight(fs+10);
          setMargin((svgWidth - width) / 2);
        }
      }
    }
  }, [props.scalePixelPoints])

  // const updateScale = () => {
  //   const points = getPixelPoints(props.scene);
  //   console.log(`points=${points[0], points[1]}`);
  //   const measuredPixelDistance = measureDistance(points, props.scene.globe.ellipsoid);
  //
  //   console.log(`measuredPixelDistance=${measuredPixelDistance.toFixed(2)}`);
  //
  //   if (measuredPixelDistance) {
  //     // ... Compute the svg's parameters
  //     const scaleDistance = pickBestDistance(measuredPixelDistance);
  //     console.log(`scaleDistance=${scaleDistance?.toFixed(2)}`);
  //
  //     if (scaleDistance) {
  //       const width = Math.round(scaleDistance / measuredPixelDistance );
  //       const svgWidth = width/*maxBarWidth*/ + 10;
  //       const fs = 14;
  //
  //       if ( scaleDistance >= 1000 ) {
  //         setText(`${(scaleDistance / 1000).toFixed(2)} km`)
  //       } else {
  //         setText(`${scaleDistance.toFixed(2)} m`);
  //       }
  //
  //       setFontSize( fs );
  //       setWidth(svgWidth);
  //       setHeight(fs+10);
  //       setMargin((svgWidth - width) / 2);
  //     }
  //   }
  // };
  //
  // useCameraEvent( props.scene, ()=> {
  //   updateScale();
  // });

  // useEffect(()=>{updateScale();} );

  return (
      <div
          className={props.className}
          style={props.style}
      >
        { text && width && height && margin && fontSize && (
          <ScaleSvg
            text = {text}
            textColorCss={"#ffffffba"}
            width = {width}
            height = {height}
            fontSize = {fontSize}
            margin = {margin}
          />
        )}
      </div>
  );
}
export default Scale2D;