import React, {CSSProperties, useCallback, useEffect, useState} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import Cesium, {Cesium3DTileFeature, Entity as CesiumEntity, SceneMode, Cesium3DTileset as CesiumCesium3DTileset} from "cesium";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import {LayerType} from "../../../../model/LayerType";
import GenericAssetView, {getTargetId} from "./GenericAssetView";
import {ITilesetResource} from "./ITilesetResource";
import CustomInfoBox, {ICustomInfoBoxProps} from "../Widgets/CustomInfoBox";
import FloatingContainer from "../BasicControls/FloatingContainer";
import {ICameraVantagePoint} from "./ICameraVantagePoint";

interface IProps extends WithTranslation {
  className?:string|undefined;
  style?:CSSProperties|undefined;
  sceneMode:SceneMode;
  cameraVantagePoint?: ICameraVantagePoint|undefined;
  handleAllTilesLoad?: ((tileset: Cesium.Cesium3DTileset, resource: ITilesetResource)=>void)|undefined;
  linearSelectionMode: boolean;
  onLinearSelectionModeChanged: (v: boolean)=>void;
  onLoadingComplete?:()=>void;
}

const TilesetView: React.FC<IProps> = (props) => {
  const [userSession, userSessionActions] = useUserSessionContext();
  const [tilesetResources, setTilesetResources] = useState<ITilesetResource[]>([]);
  const [infoBoxProps, setInfoBoxProps] = useState<ICustomInfoBoxProps|undefined>();

  const sceneMode = props.sceneMode;

  /**
   * Initialize the resources that will be viewed
   */
  useEffect(()=>{

    if (userSession.assetSummaries) {
      let tilesetResourceArr: ITilesetResource[] = [];

      userSession.assetSummaries.forEach( assetSummary => {
        switch( assetSummary.layerType ) {
          case LayerType.Boundary:
            tilesetResourceArr.push( { ...assetSummary } as ITilesetResource );
            break;
          case LayerType.BlockModel:
            if ( sceneMode === SceneMode.SCENE3D ) {
              tilesetResourceArr.push({...assetSummary} as ITilesetResource);
            }
            break;
          case LayerType.BlastholeSecondarySegments:
            if ( sceneMode === SceneMode.SCENE3D ) {
              tilesetResourceArr.push({...assetSummary} as ITilesetResource);
            }
            break;
          case LayerType.BlastholeSingleSegment:
            if ( sceneMode === SceneMode.SCENE3D ) {
              tilesetResourceArr.push({...assetSummary} as ITilesetResource);
            }
            break;
          case LayerType.BlastholeClusters:
            if ( sceneMode === SceneMode.SCENE2D ) {
              tilesetResourceArr.push({...assetSummary} as ITilesetResource);
            }
            break;
          case LayerType.BlastholeFractures:
            if ( sceneMode === SceneMode.SCENE3D ) {
              tilesetResourceArr.push({...assetSummary} as ITilesetResource);
            }
            break;
          case LayerType.CrossSectionX:
            if ( sceneMode === SceneMode.SCENE3D ) {
              tilesetResourceArr.push({...assetSummary} as ITilesetResource);
            }
            break;
          case LayerType.CrossSectionY:
            if ( sceneMode === SceneMode.SCENE3D ) {
              tilesetResourceArr.push({...assetSummary} as ITilesetResource);
            }
            break;
          case LayerType.CrossSectionZ:
            if ( sceneMode === SceneMode.SCENE3D ) {
              tilesetResourceArr.push({...assetSummary} as ITilesetResource);
            }
            break;
        }
      })

      setTilesetResources(tilesetResourceArr);

    }
  }, [sceneMode, userSession.assetSummaries])

  const handleItemSelected = useCallback((item: Cesium3DTileFeature|CesiumEntity|undefined) => {
    console.log(`handleItemSelected=${getTargetId(item)}`)

    if ( sceneMode === SceneMode.SCENE3D ) {
      setInfoBoxProps({selected: item} as ICustomInfoBoxProps);
    }

  }, [sceneMode]);

  useEffect(()=>console.log(`props.sceneMode has changes`), [props.sceneMode]);

  const handleTilesetResourcesRejected = useCallback((rejected: { tilesetResource: ITilesetResource, reason: string}[]) => {
    rejected.forEach( reject => {
      console.log(`Rejected ${reject.tilesetResource.name} because: ${reject.reason}`)
    })
  }, []);

  /**
   * When a tileset has failed to load, it is most often caused by an expired refresh token.  Cesium doesn't perform
   * retries so we need to do manage that.
   * @param a
   * @param b
   */
  const handleResourceLoadingFailed = useCallback((tileset: CesiumCesium3DTileset, resource: ITilesetResource, err: any ) => {
    console.warn(`Tileset ${tileset.resource.url} failed to load because: ${JSON.stringify(err)}`)
  }, []);

  /**
   * Component design
   */
  return (
      <>
        {/* THE CESIUM VIEWER IS INSIDE HERE */}
        <GenericAssetView
          // debugMode={true}
          className={props.className}
          style={props.style}
          tilesetResources={tilesetResources}
          sceneMode={props.sceneMode}
          cameraVantagePoint={props.cameraVantagePoint}
          onItemSelected={handleItemSelected}
          handleAllTilesLoad={props.handleAllTilesLoad}
          onTilesetResourcesRejected={handleTilesetResourcesRejected}
          handleTileFailed={handleResourceLoadingFailed}
          linearSelectionMode={props.linearSelectionMode}
          onLinearSelectionModeChanged={props.onLinearSelectionModeChanged}
          onLoadingComplete={props.onLoadingComplete}
        >
          {/* CHILDREN FROM PARENT COMPONENT */}
          {props.children}
        </GenericAssetView>

        {/* Info box popup in 3D mode */}
        {infoBoxProps?.selected && props.sceneMode === SceneMode.SCENE3D && (
            <FloatingContainer
                name={"CustomInfoBox"}
                // className={`w3-round w3-opacity w3-hover-opacity-off`}
                className={`w3-round`}
                snapPosition={userSession.infoBoxSnapPosition}
                onPositionChanged={userSessionActions.setInfoBoxSnapPosition}
            >
              <CustomInfoBox {...infoBoxProps} className={"w3-text-light-blue w3-border-light-blue"}/>
            </FloatingContainer>
        )}
      </>
  )
}

export default withTranslation()(TilesetView);
