import React from "react";

interface IProps {
  className?:string;
}

export const HorizontalSeparator: React.FC<IProps> = (props) => {
  return (
      <hr
          className={props.className}
          style={{ borderTop: "0.5px solid" }}
      />
  )
}
