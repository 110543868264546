import React, {HTMLInputTypeAttribute, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon: IconProp;
}

const InputWithIcon: React.FC<IProps> = (props) => {
  const [hasFocus, setHasFocus] = useState<boolean>();

  return (
      <div
          className={`w3-padding w3-round w3-round-small w3-text-gray ${hasFocus && "w3-border w3-border-light-blue"} w3-round`}
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
      >
        <div
            style={{
              position: "absolute",
              left: "0",
              width: "100%"
            }}
        >
          <input
              {...props}
              className={`w3-input w3-black w3-text-white`}
              style={{
                borderBottom: 0,
                paddingLeft: "45px",
                outline: "none"
              }}
              onFocus={()=>setHasFocus(true)}
              onBlur={()=>setHasFocus(false)}
          />
        </div>
        <div style={{zIndex: 2}}>
          <FontAwesomeIcon
              icon={props.icon}
              size={"lg"}
          />
        </div>
      </div>
  )
}

export default InputWithIcon;
