import {
  Cartesian3,
  Cartesian4,
  Cesium3DTile,
  Cesium3DTileFeature,
  Cesium3DTileset,
  Cesium3DTileset as CesiumCesium3DTileset,
  Matrix4,
  Resource
} from "cesium";
import IAssetSummary from "../domain/IAssetSummary";
import {AttributeType, CATEGORY_ATTRIBUTE_TYPES, IAttributeTypeMap} from "../domain/AttributeType";
import {RockMassDomainRange} from "../../../model/RockMassDomainRange";
import {IBoundingBox} from "../domain/IBoundingBox";
import {FeatureIndex} from "../domain/FeatureIndex";
import {AttributeMapping} from "./AttributeMapping";

export const ADJUSTED_POS_PROPERTY_NAME = "adjustedPos";

export class TilesetUtils {

  /**
   * Extract a custom origin field that defines the relative position of the tileset's origin
   * with respect to the min local coordinate system's origin
   * @param tileset
   */
  public static getOriginReference(tileset: Cesium3DTileset): Cartesian3|undefined {
    // ... Sanity check
    if ( !tileset.extras ) {
      throw new Error(`Tileset (${tileset.basePath}) contains no 'extras' section`);
    }
    let extras: any = tileset.extras ;
    let origin: number[] = extras.hasOwnProperty("origin")
        ? extras.origin
        : extras.hasOwnProperty("Origin")
            ? extras.Origin
            : undefined ;
    if ( ! origin ) {
      throw new Error(`Tileset (${tileset.basePath}) contains no 'extras.origin' field`) ;
    }
    // ... Extract the origin in cartesian form
    return Cartesian3.fromArray(origin);
  }
  /**
   * Returns the URL of the asset as it should be fetched from the server
   * @param asset
   */
  public static getUrlFromAsset(asset: IAssetSummary) : string {
    return asset.downloadUri + '/' + asset.name + '.json' ;
  }
  public static getResourceFromUrl(url: string) : Resource {
    const resourceOptions = {
      url: url,
      headers: {
        Authorization: `${sessionStorage.getItem("auth_token_header")?.trim()} ${sessionStorage.getItem("auth_token")?.trim()}`
      }
    };
    return new Resource(resourceOptions) ;
  }
  /**
   * Given an origin reference, determine a tileset's offset from it.
   * @param tileset
   * @param originReference
   */
  public static getOffsetFromOrigin( tileset: Cesium3DTileset, originReference: Cartesian3 ): Cartesian3|undefined {
    let thisOrigin = TilesetUtils.getOriginReference(tileset);
    return thisOrigin
        ? Cartesian3.subtract( thisOrigin, originReference, new Cartesian3())
        : undefined;
  }
  public static getBlockSize( tileset: Cesium3DTileset ): number[]|undefined {
    return tileset.extras.BlockSize
        ? tileset.extras.BlockSize
        : tileset.extras["block-size"]
            ? tileset.extras["block-size"]
            : tileset.extras["BlockSize"]
                ? tileset.extras["BlockSize"]
                : undefined;
  }
  public static numBlocksInX (tileset: Cesium3DTileset): number {
    return tileset.properties.I.maximum - tileset.properties.I.minimum + 1;
  }
  public static numBlocksInY (tileset: Cesium3DTileset): number {
    return tileset.properties.J.maximum - tileset.properties.J.minimum + 1;
  }
  public static numBlocksInZ (tileset: Cesium3DTileset): number {
    return tileset.properties.K.maximum - tileset.properties.K.minimum + 1;
  }
  /**
   * Indexes features in 3 dimensions
   * @param tileset
   */
  public static buildFeatureIndex(tileset: Cesium3DTileset): FeatureIndex {
    return new FeatureIndex(tileset);
  }
  /**
   * Extracts private data from root tile info
   * @param rootTile
   * @private
   */
  public static extractBoundingVolumeFromRootTile( rootTile: any ): any | undefined {
    if (rootTile && rootTile._header) {
      return rootTile._header.boundingVolume;
    } else {
      return undefined;
    }
  }
  /**
   * Note: Unfortunately, we're directly accessing the internal variable '_header' (Cesium gives us no other choice) to
   * access the bounding box.  Apparently, they thought it was a good idea to replace it with a bounding sphere and hide
   * the original bounding volume definition. ... We can assume X, Y and Z axes are orthogonal w/r to ECEF.
   * @param tileset
   */
  public static getBoundingBoxOffset( tileset: Cesium3DTileset ): Cartesian3 {
    const bvol = TilesetUtils.extractBoundingVolumeFromRootTile( tileset.root );
    if (bvol.box) {
      const cx = 1.0 * bvol.box[0];
      const cy = 1.0 * bvol.box[1];
      const cz = 1.0 * bvol.box[2];
      const dx = -bvol.box[3];
      const dy = -bvol.box[10];
      const dz = -bvol.box[8];
      return Cartesian3.fromArray([ cx + dx, cy + dy, cz + dz, ]);
    } else {
      return Cartesian3.fromArray([0, 0, 0]);
    }
  }
  public static getBoundingBoxLimit( tileset: Cesium3DTileset ): Cartesian3 {
    const bbOff = TilesetUtils.getBoundingBoxOffset( tileset ) ;
    const blkSize = TilesetUtils.getBlockSize( tileset );
    if (!blkSize) {
      throw new Error(`Failed to read block size from the tileset`)
    }

    return Cartesian3.fromArray([
      bbOff.x + TilesetUtils.numBlocksInX( tileset ) * (+blkSize[0]),
      bbOff.y + TilesetUtils.numBlocksInY( tileset ) * (+blkSize[1]),
      bbOff.z + TilesetUtils.numBlocksInZ( tileset ) * (+blkSize[2]),
    ]);
  }
  public static localToWorld(pos: Cartesian3, modelMatrix: Matrix4 ): Cartesian3 {
    const result = Cartesian3.fromCartesian4(
        Matrix4.multiplyByVector(
            modelMatrix,
            Cartesian4.fromArray([ pos.x, pos.y, pos.z, 1 ]),
            new Cartesian4()
        )
    );
    // console.log(`_ecefToModel(${pos}) => ${result}`) ;
    return result ;
  }

  public static worldToLocal(pos: Cartesian3, modelMatrix: Matrix4 ): Cartesian3 {
    const m = Matrix4.inverseTransformation(modelMatrix, new Matrix4());
    const result = Cartesian3.fromCartesian4(
        Matrix4.multiplyByVector(
            m,
            Cartesian4.fromArray([ pos.x, pos.y, pos.z, 1 ]),
            new Cartesian4()
        )
    );
    return result ;
  }

  /**
   * Adds an "adjusted position" to the tileset features
   * @param tileset
   * @param modelMatrix
   * @param zKey
   * @param callback
   * @returns number of features affected
   */
  public static addAdjustedPositionPropertyFromXYZ(tileset: CesiumCesium3DTileset, modelMatrix: Matrix4, zKey: string, callback?:(feature: Cesium3DTileFeature)=>void): number {
    return TilesetUtils.addAdjustedPositionProperty( tileset, modelMatrix, "x", "y", zKey, callback );
  }

  public static addAdjustedPositionProperty(tileset: CesiumCesium3DTileset, modelMatrix: Matrix4, xKey: string, yKey: string, zKey: string, callback?:(feature: Cesium3DTileFeature)=>void): number {

    function getXyzFromFeature( feature: Cesium3DTileFeature ) {
      return {
        x: feature.getProperty(xKey),
        y: feature.getProperty(yKey),
        z: feature.getProperty(zKey),
      }
    }

    return TilesetUtils.addAdjustedPositionProperty2( tileset, modelMatrix, getXyzFromFeature, callback );
    //
    //
    // const root = tileset.root;
    // const origin = TilesetUtils.getOriginReference( tileset ) ;
    // let transform = root.transform ?? Matrix4.IDENTITY ;
    // let xyzIsAbsolute: boolean|undefined = undefined ;
    //
    // let featureCount = 0 ;
    //
    // if (/*root && root.content && */origin) {
    //
    //   TilesetUtils.visitFeatures( tileset, feature => {
    //     let x = feature.getProperty(xKey);
    //     let y = feature.getProperty(yKey);
    //     let z = feature.getProperty(zKey);
    //
    //     // ... Heuristic: are th XYZ values absolute or relative?
    //     if (!xyzIsAbsolute) {
    //       xyzIsAbsolute = Math.abs(x - origin.x) < 1000 && Math.abs(y - origin.y) < 1000;
    //     }
    //     if (xyzIsAbsolute) {
    //       x -= origin.x;
    //       y -= origin.y;
    //       z -= origin.z;
    //     }
    //
    //     // ... Add transform
    //     let transformed =
    //         Matrix4.multiplyByVector(
    //             transform, Cartesian4.fromArray([x, y, z, 1]), new Cartesian4());
    //
    //     // ... Convert local offset to XYZ coordinate
    //     let position = TilesetUtils.localToWorld( transformed, modelMatrix );
    //
    //     // ... Add properties to feature
    //     feature.setProperty(ADJUSTED_POS_PROPERTY_NAME, [position.x, position.y, position.z] )
    //     featureCount += 1 ;
    //
    //     // ... Invoke the callback, if one is defined
    //     callback && callback(feature);
    //   }) ;
    // }
    // return featureCount;
  }

  public static addAdjustedPositionProperty2(tileset: CesiumCesium3DTileset, modelMatrix: Matrix4, getXYZ: (feature: Cesium3DTileFeature)=>{x: number, y: number, z: number}, callback?:(feature: Cesium3DTileFeature)=>void): number {
    const root = tileset.root;
    const origin = TilesetUtils.getOriginReference( tileset ) ;
    let transform = root.transform ?? Matrix4.IDENTITY ;
    let xyzIsAbsolute: boolean|undefined = undefined ;

    let featureCount = 0 ;

    if (/*root && root.content && */origin) {

      TilesetUtils.visitFeatures( tileset, feature => {

        const xyz = getXYZ( feature ) ;

        let x = xyz.x
        let y = xyz.y
        let z = xyz.z

        // ... Heuristic: are th XYZ values absolute or relative?
        if (!xyzIsAbsolute) {
          xyzIsAbsolute = Math.abs(x - origin.x) < 1000 && Math.abs(y - origin.y) < 1000;
        }
        if (xyzIsAbsolute) {
          x -= origin.x;
          y -= origin.y;
          z -= origin.z;
        }

        // ... Add transform
        let transformed =
            Matrix4.multiplyByVector(
                transform, Cartesian4.fromArray([x, y, z, 1]), new Cartesian4());

        // ... Convert local offset to XYZ coordinate
        let position = TilesetUtils.localToWorld( transformed, modelMatrix );

        // ... Add properties to feature
        feature.setProperty(ADJUSTED_POS_PROPERTY_NAME, [position.x, position.y, position.z] )
        featureCount += 1 ;

        // ... Invoke the callback, if one is defined
        callback && callback(feature);
      }) ;
    }
    return featureCount;
  }

  public static visitFeatures(tileset: CesiumCesium3DTileset, callback?:(feature: Cesium3DTileFeature)=>void): number {
    const root = tileset.root;
    if ( root && callback ) {
      return TilesetUtils.visitFeaturesRecursive( root, callback );
    } else {
      return 0 ;
    }
    // let featureCount = 0 ;
    // if (root?.content) {
    //   for (let i = 0; i < root.content.featuresLength; ++i) {
    //     let feature = root.content.getFeature(i);
    //
    //     // ... Invoke the callback, if one is defined
    //     callback && callback(feature);
    //   }
    // }
    // return featureCount;
  }

  public static visitFeaturesRecursive( tile: Cesium3DTile, callback:(feature: Cesium3DTileFeature)=>void ): number {
    let featureCount = 0 ;

    if ( tile.content ) {
      for (let i = 0; i < tile.content.featuresLength; ++i) {
        let feature = tile.content.getFeature(i);

        // ... Invoke the callback, if one is defined
        callback && callback(feature);

        featureCount += 1;
      }
    }
    if ( tile.children ) {
      tile.children.forEach( childTile =>
          featureCount += TilesetUtils.visitFeaturesRecursive( childTile, callback ) ) ;
    }
    return featureCount ;
  }

  public static visitTilesRecursive( tile: Cesium3DTile, callback:(tile: Cesium3DTile)=>void ) {

    if ( tile.content ) {
      callback( tile ) ;
    }
    if ( tile.children ) {
      tile.children.forEach( childTile => TilesetUtils.visitTilesRecursive( childTile, callback ) ) ;
    }
  }

  public static findTile( tile: Cesium3DTile, predicate:(tile: Cesium3DTile)=>boolean ): Cesium3DTile|undefined {
    if ( tile.content ) {
      if ( predicate( tile ) ) {
        return tile ;
      }
    }
    if ( tile.children ) {
      for ( let i = 0; i < tile.children.length; i+=1) {
        let childResult = TilesetUtils.findTile( tile.children[i], predicate );
        if (childResult) {
          return childResult ;
        }
      }
    }
    return undefined ;
  }

  /**
   * Adds an attribute to all features in a tileset
   * @param tileset
   * @param key
   * @param value
   * @returns NUmber of features affected
   */
  public static addAttribute(tileset: CesiumCesium3DTileset, key: string, value: string): number {

    return TilesetUtils.visitFeatures( tileset, feature => {
      feature.setProperty( key, value ) ;
    } ) ;

    // const root = tileset.root;
    // const content = root?.content;
    // let featureCount = 0 ;
    // if (content) {
    //   for (let i = 0; i < content.featuresLength; ++i) {
    //     let feature = content.getFeature(i);
    //     feature.setProperty( key, value ) ;
    //     featureCount += 1 ;
    //   }
    //   if (featureCount === 0) {
    //     console.warn(`TilesetUtils.addAttribute: no features`)
    //   }
    // } else {
    //   console.error(`TilesetUtils.addAttribute: no content`)
    // }
    // return featureCount ;
  }
  public static getAttributes(tileset: CesiumCesium3DTileset): Set<string> {
    let result = new Set<string>();
    const properties = tileset.properties;
    if (properties) {
      for ( let key in properties ) {
        result.add( key );
      }
    }
    return result ;
  }
  public static hasAttribute(tileset: CesiumCesium3DTileset, attribute: keyof IAttributeTypeMap<string>): boolean {
    if ( !tileset.extras ) {
      throw new Error(`Tileset (${tileset.basePath}) contains no 'extras' section`);
    }
    let extras: any = tileset.extras ;
    return extras.hasOwnProperty(attribute)
  }
  /**
   * Extract the cluster range definitions from the "extras" section of the tileset's JSON metadata
   *
   * Note: this only applies to tilesets that contain rock mass domain / clusters information
   *
   * @param tileset
   */
  public static extractRangeStats( tileset: Cesium3DTileset ): Map<string,RockMassDomainRange[]> {
    // ... Read the rock domain definitions from the "extras" section
    let rangeStats: Map<string,RockMassDomainRange[]> = new Map<string, RockMassDomainRange[]>() ;

    let rockMassDomainSection : any = tileset.extras.RockMassDomain
        ? tileset.extras.RockMassDomain
        : undefined ;

    if ( rockMassDomainSection ) {
      for ( let key in rockMassDomainSection ) {
        rangeStats.set( key, []);
        let rangeStatsArray = rangeStats.get(key);
        if ( rangeStatsArray ) {
          let ranges: RockMassDomainRange[] | undefined = rockMassDomainSection[key]?.Domains;
          if (ranges) {
            for (let idx in ranges) {
              let rangeStatItem = ranges[idx];
              if (rangeStatItem) {
                rangeStatsArray.push(rangeStatItem as RockMassDomainRange);
              }
            }
          }
        }
      }
    }
    return rangeStats ;
  }
  /**
   * For each feature, dereference its rock domain index and use the rock domain's average [(min+max)/2] to define
   * the related attribute's value.  For example, if avg(CBI_Domain) == 10, then set a feature's CBI value to 10
   *
   * Note: this only applies to tilesets that contain rock mass domain / clusters information
   *
   */
  public static addRockDomainAverages(tile: Cesium3DTile, rangeStats: Map<string, RockMassDomainRange[]>): void {

    if ( TilesetUtils.visitFeaturesRecursive( tile, feature => {
      // ... For each cluster type
      rangeStats.forEach( (ranges, key) => {
        // console.log(`>>> Cluster type: ${key}`);
        // ... Dereference the cluster index
        let rockMassDomainName = key;
        let rockMassDomainIndex = feature.getProperty( rockMassDomainName );
        if ( rockMassDomainIndex < ranges.length ) {
          let rockMassDomainRange = ranges[rockMassDomainIndex];
          let relatedAttributeName = rockMassDomainName.substring( 0, rockMassDomainName.indexOf("_Domain"));
          // ... Assign the related attribute value as the cluster's avg
          let avg = (rockMassDomainRange.min+rockMassDomainRange.max)/2 ;
          feature.setProperty( relatedAttributeName, avg );
          // console.log(`"${relatedAttributeName}" = "${avg}"`) ;
        }
      }) ;
    } ) === 0 ) {
      console.log("*** addRockDomainAverages: Error! no tile features");
    }

    // if (tile && tile.content) {
    //   // ... For each feature
    //   for (let i = 0; i < tile.content.featuresLength; ++i) {
    //     let feature = tile.content.getFeature(i);
    //
    //     // ... For each cluster type
    //     rangeStats.forEach( (ranges, key) => {
    //       // console.log(`>>> Cluster type: ${key}`);
    //       // ... Dereference the cluster index
    //       let rockMassDomainName = key;
    //       let rockMassDomainIndex = feature.getProperty( rockMassDomainName );
    //       if ( rockMassDomainIndex < ranges.length ) {
    //         let rockMassDomainRange = ranges[rockMassDomainIndex];
    //         let relatedAttributeName = rockMassDomainName.substring( 0, rockMassDomainName.indexOf("_Domain"));
    //         // ... Assign the related attribute value as the cluster's avg
    //         let avg = (rockMassDomainRange.min+rockMassDomainRange.max)/2 ;
    //         feature.setProperty( relatedAttributeName, avg );
    //         // console.log(`"${relatedAttributeName}" = "${avg}"`) ;
    //       }
    //     }) ;
    //   }
    // } else {
    //   console.log("*** addRockDomainAverages: Error! no tile");
    // }
  }
  /**
   * Ensures the min/max ranges are defined correctly in the 'properties' section of the tileset's metadata
   *
   * Note: this only applies to tilesets that contain rock mass domain / clusters information
   *
   * @param tileset
   * @param ranges
   */
  public static overrideDomainMinMax(tileset: Cesium3DTileset, ranges: Map<string,RockMassDomainRange[]> ) {
    let propertiesSection : any = tileset.properties
        ? tileset.properties
        : undefined ;

    if ( !propertiesSection ) {
      throw new Error("extractClustersMinMax: no properties section");
    }

    for ( let propName in propertiesSection ) {
      let rangeStats = ranges.get( propName ) ;
      if ( rangeStats ) {
        if ( propName.indexOf("_Domain") !== -1 ) {
          let max = propertiesSection[propName].maximum;
          if ( max < rangeStats.length - 1 ) {
            propertiesSection[propName].maximum = rangeStats.length - 1 ;
          }
        }
      }
    }
  }
  /**
   * After loading a rock mass domain tileset (clusters info), this method will populate its subcomponent features with
   * additional data that will be ued in styling and rendering.
   * @param tileset
   */
  public static prepareRockMassDomainTileset(tileset: Cesium3DTileset) {
    let rangeStats: Map<string,RockMassDomainRange[]>|undefined ;
    rangeStats = TilesetUtils.extractRangeStats(tileset);
    TilesetUtils.addRockDomainAverages(tileset.root, rangeStats);
    TilesetUtils.overrideDomainMinMax(tileset, rangeStats);
  }
  /**
   * After loading a block model tileset, this method will populate its subcomponent features with additional data that
   * will be ued during styling and rendering.
   * @param tileset
   * @param originReference extras.Origin of the first tileset consumed for a given session
   * @param featureIndex
   * @returns
   */
  public static prepareBlockModelTileset( tileset: Cesium3DTileset, originReference: Cartesian3 ): IBoundingBox {
    const blkSz = TilesetUtils.getBlockSize( tileset )
    if ( !blkSz ) {
      throw new Error("_prepareBlockModelTileset: no block size")
    }

    // ... Extract bounding box; the coordinate domain is relative to 'extras.Origin'
    const bbo = TilesetUtils.getBoundingBoxOffset( tileset );

    // ... Consider the offset between tilesets, as each have their own 'extras.Origin'
    const offset = TilesetUtils.getOffsetFromOrigin( tileset, originReference ) ?? new Cartesian3();

    let minPt: Cartesian3|undefined;
    let maxPt: Cartesian3|undefined;
    let minIdx: Cartesian3|undefined;
    let maxIdx: Cartesian3|undefined;

    // ... Index the features
    let featureIndex = TilesetUtils.buildFeatureIndex( tileset ) ;

    // ... Add Offset tags to each feature (offset of the block w/r to origin reference)
    featureIndex.forEach((i, j, k, feature)=>{
      // console.log(`featureIndex.forEach(${i}, ${j}, ${k})`);

      // ... Add positional offset to the feature (offset of the block w/r to origin reference)
      let x: number = +(bbo.x + offset.x + i * blkSz[0]).toFixed(2);
      let y: number = +(bbo.y + offset.y + j * blkSz[1]).toFixed(2);
      let z: number = +(bbo.z + offset.z + k * blkSz[2]).toFixed(2);

      feature.setProperty("OffsetXYZ", [x, y, z]);

      // ... Add index offset to the feature (index offset of the block)
      let offsetIJK = [
        Math.round(x / blkSz[0]),
        Math.round(y / blkSz[1]),
        Math.round(z / blkSz[2]),
      ];
      feature.setProperty("OffsetIJK", offsetIJK);

      if ( !minIdx) {
        minIdx = Cartesian3.fromArray( offsetIJK )
      } else {
        minIdx.x = Math.min(minIdx.x, offsetIJK[0]);
        minIdx.y = Math.min(minIdx.y, offsetIJK[1]);
        minIdx.z = Math.min(minIdx.z, offsetIJK[2]);
      }
      if ( !maxIdx) {
        maxIdx = Cartesian3.fromArray( offsetIJK )
      } else {
        maxIdx.x = Math.max(maxIdx.x, offsetIJK[0]);
        maxIdx.y = Math.max(maxIdx.y, offsetIJK[1]);
        maxIdx.z = Math.max(maxIdx.z, offsetIJK[2]);
      }

      if (!minPt) {
        minPt = Cartesian3.fromArray([x, y, z]);
      } else {
        minPt.x = Math.min(minPt.x, x);
        minPt.y = Math.min(minPt.y, y);
        minPt.z = Math.min(minPt.z, z);
      }

      if (!maxPt) {
        maxPt = Cartesian3.fromArray([x, y, z]);
      } else {
        maxPt.x = Math.max(maxPt.x, x + blkSz[0]);
        maxPt.y = Math.max(maxPt.y, y + blkSz[1]);
        maxPt.z = Math.max(maxPt.z, z + blkSz[2]);
      }
    })

    if ( !((minPt && maxPt && minIdx && maxIdx)) ) {
      throw new Error("_prepareBlockModelTileset: no min/max")
    }

    // ... Return the bounding box, which is in a coordinate domain that is relative to the origin reference
    return {
      minPos: minPt,
      maxPos: maxPt,
      minIdx: minIdx,
      maxIdx: maxIdx
    } as IBoundingBox;
  }

  /**
   * Attributes of type "enum" (or "category") may be tied to a numeric range attribute within the data set.
   * This method extract any such mappings from the "extras" section of the JSON metadata of a tileset.
   * @param tileset
   */
  public static extractAttributeMappings( tileset: Cesium3DTileset): Map<AttributeType,AttributeMapping> {
    let result: Map<AttributeType,AttributeMapping> = new Map<AttributeType,AttributeMapping>();

    CATEGORY_ATTRIBUTE_TYPES.forEach( attributeType => {
      const key: string = AttributeType[ attributeType ];
      if ( tileset.extras.hasOwnProperty( key ) ) {
        let attributeMapping: AttributeMapping = tileset.extras[ key ];
        if (attributeMapping) {
          result.set(attributeType, attributeMapping);
        }
      }
    })

    return result ;
  }

  /**
   * Constructs a tileset style condition from:
   * - a given set of feature property names,
   * - a value to compare to
   * - an optional condition specifying if the condition should include "undefined properties"
   *
   * @param keys
   * @param op
   * @param value
   * @param includeUndefined
   * @private
   */
  public static featurePropertyConditionExpression( keys: string[], op: string, value: any, includeUndefined = false ): string {
    let expressions: string[] = [] ;

    keys.forEach( (key) => {
      if ( includeUndefined ) {
        expressions.push("(\${feature['" + key + "']} === undefined)");
      }
      expressions.push("((\${feature['" + key + "']} !== undefined) && (\${feature['" + key + "']} " + `${op} ${value}))`) ;
    });

    return expressions.length > 0 ? expressions.join(" || ") : "true" ;
  }
  public static featurePropertyConditionExpression2( keys: string[], op: string, value: any, includeUndefined = false ): string {
    let expressions: string[] = [] ;

    keys.forEach( (key) => {
      if ( includeUndefined ) {
        expressions.push("(\${" + key + "} === undefined)");
      }
      expressions.push("((\${" + key + "} !== undefined) && (\${" + key + "} " + `${op} ${value}))`) ;
    });

    return expressions.length > 0 ? expressions.join(" || ") : "true" ;
  }

  /**
   * Mapping of standard attribute names vs names as they may appear in the data files.
   *
   * The attributes are those deemed significant/important to the application.
   */
  static readonly ATTRIBUTE_NAMES = new Map<string, string[]>([
    ["x", ["easting"]],
    ["y", ["northing"]],
    ["z", ["elevation"]],
    ["from_z", ["top_elevation"]],
    ["to_z", ["bottom_elevation"]],
    ["SED", ["SED"]],
    ["BI", ["DWI", "BI"]],
    ["CBI", ["RMI", "CBI"]],
    ["CoalProbability", ["CoalProbability"]],
    ["Hardness", ["Hardness"]],
    ["K", ["K"]],
    ["FRF", ["FRF"]],
    ["ROP", ["ROP"]],
    ["BI_Domain", ["BI_Domain"]],
    ["CBI_Domain", ["CBI_Domain"]],
    ["Prominence", ["Prominence"]],
    ["Depth", ["Depth"]],
    ["Other", ["Other"]],
    ["Layer", ["Layer"]],
  ]);
  static readonly SUBDRILL_INDEX_NAME = "Sub-Drill";

  /**
   * Given an attribute name - as found in a data file - this method returns a standardized name.
   * @param attribute
   */
  static toStandardizedAttributeName( attribute: string ): string|undefined {
    let result: string|undefined;
    TilesetUtils.ATTRIBUTE_NAMES.forEach( (v, k) => {
      if ( !result ) {
        for (let i = 0; i < v.length; i += 1) {
          if (v[i] === attribute) {
            result = k;
            break;
          }
        }
      }
    });
    return result ;
  }

  /**
   * Given a standardized attribute name, this method returns an array of possible names as they may appear in data
   * files.
   * @param attribute
   */
  static fromStandardizedAttributeName( attribute: string ): string[] {
    return TilesetUtils.ATTRIBUTE_NAMES.get( attribute ) ?? [];
  }
}
