import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withTranslation} from "react-i18next";
import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {useMap} from "usehooks-ts";
import SubMenuIconButton from "../../../SubMenuIconButton";
import {SubMenuButtonState} from "../../../SideMenuItems/SubMenuButton";
import SideMenuContainer from "./SideMenuContainer";

interface IProps {
  showMenu?: boolean;
}

const CollapsibleSideMenuContainer: React.FC<IProps> = (props) => {
  const [showMenu, _setShowMenu] = useState<boolean>(props.showMenu ?? false);

  const setShowMenu = (state: boolean ) => {
    // console.log(`setShowMenu: ${state}`);
    if ( !state ) {
      notifyChildrenButtonStateChange(SubMenuButtonState.Collapsed);
    }
    _setShowMenu( state);
  }

  useEffect(()=> {
    (props.showMenu !== undefined) && setShowMenu(props.showMenu)
  }, [props.showMenu]);

  const notifyChildrenButtonStateChange = (buttonState: SubMenuButtonState) => {
    React.Children.forEach(props.children, child => {
      onSectionButtonStateChanged( child, buttonState ) ;
    })
  }

  const onSectionButtonStateChanged = ( child: ReactNode, state: SubMenuButtonState ) => {
    if (React.isValidElement(child)) {
      if (child.props) {
        if (child.props.onSubMenuButtonStateChanged) {
          child.props.onSubMenuButtonStateChanged(state);
        }
      }
    }
  }

  const [itemButtonStates, itemButtonStatesActions] = useMap<string, SubMenuButtonState>([]);
  const setItemButtonState = (key: string, state: SubMenuButtonState) => {
    itemButtonStatesActions.set( key, state ) ;
  }

  const resetSubMenuButtonStates = useCallback(() => {
    itemButtonStatesActions.setAll(
        Array.from(itemButtonStates.entries())
            .map(([k,v]) => ([k, SubMenuButtonState.Collapsed])));

    notifyChildrenButtonStateChange(SubMenuButtonState.Collapsed);
  }, [itemButtonStates, itemButtonStatesActions, notifyChildrenButtonStateChange]);

  const expandMenu = useCallback((key: string)=>{
    setShowMenu(true);
    setItemButtonState( key, SubMenuButtonState.Expanded );
  }, []);

  useEffect(()=>{
    React.Children.forEach(props.children, child => {
      if (!React.isValidElement(child)) {
        return;
      }
      if ( child.props ) {
        setItemButtonState( child.props.label, SubMenuButtonState.Collapsed );
        child.props.onSubMenuButtonStateChange && child.props.onSubMenuButtonStateChange(SubMenuButtonState.Collapsed);
      }
    })
  }, []);

  return (
      <>
        {/* Collapsed side menu */}
        <div className="w3-col w3-sidebar w3-theme-d5 clip" style={{ width: "45px", height: "calc(100vh)" }} >

          {!showMenu && (
              <div>
                <button className="w3-button" onClick={() => { setShowMenu(true); }}>
                  <FontAwesomeIcon icon={faBars} />
                </button>
                {
                  React.Children.map(props.children, child => {
                    if (!React.isValidElement(child)) {
                      return;
                    }
                    if (child.props) {
                      // setItemButtonState( child.props.label, SubMenuButtonState.Collapsed );
                      return (
                          <SubMenuIconButton
                              id={child.props.id}
                              key={child.props.label}
                              label={child.props.label}
                              icon={child.props.icon}
                              onClick={() => {
                                expandMenu(child.props.label);
                                onSectionButtonStateChanged( child, SubMenuButtonState.Expanded ) ;
                              }}
                              pillValue={child.props.pillValue}
                              onPillClicked={child.props.onPillClicked}
                          />
                      )
                    }
                  })
                }
              </div>
          )}
        </div>

        {/* Expanded side menu */}
        <div
            className="w3-sidebar w3-theme-d5"
            style={{ width: "400px", height: "calc(100vh - 85px)", display: showMenu ? "inherit" : "none", }}
        >
          <div className="w3-bar" style={{ height: "0px", overflow: "visible" }}>
            <button
                className="w3-bar-item w3-button w3-right w3-padding-8"
                onClick={() => {
                  setShowMenu(false);
                  resetSubMenuButtonStates();
                }}
            >
              x
            </button>
          </div>

          <div>
            {
              React.Children.map(props.children, child => {
                if (!React.isValidElement(child)) {
                  return;
                }
                if ( child.props ) {
                  let onSubMenuButtonStateChange: (value: SubMenuButtonState) => void =
                      (st) => {
                        setItemButtonState(child.props.label, st);
                        onSectionButtonStateChanged( child, st ) ;
                      }

                  const btnState = child.props.buttonState
                      ? child.props.buttonState
                      : (itemButtonStates.get(child.props.label)??SubMenuButtonState.Collapsed)

                  return (
                      <SideMenuContainer
                          id={child.props.id}
                          label={child.props.label}
                          icon={child.props.icon}
                          buttonState={btnState}
                          onSubMenuButtonStateChange={onSubMenuButtonStateChange}
                          pillValue={child.props.pillValue}
                          onPillClicked={child.props.onPillClicked}
                          helpTitle={child.props.helpTitle}
                          helpContent={child.props.helpContent}
                      >
                        {child}
                      </SideMenuContainer>
                  )
                }
              })
            }
          </div>
        </div>
      </>
  )
}

export default withTranslation()(CollapsibleSideMenuContainer);
