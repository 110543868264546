import {FeatureStylist} from "./FeatureStylist";
import {UserSessionState} from "../components/Contexts/UserSessionContext";
import {ISiteConfiguration} from "../domain/ISiteConfiguration";
import {LayerType} from "../../../model/LayerType";
import {Cartesian3, Cesium3DTileFeature, Plane, SceneMode} from "cesium";
import {getPlaneXZ, getPlaneYZ} from "../hooks/useTilesetStyleExpression";
import {ADJUSTED_POS_PROPERTY_NAME} from "../util/TilesetUtils";

type BlastholeLayer = LayerType.BlastholeSecondarySegments | LayerType.BlastholeFractures;

export class BaseHoleStylist extends FeatureStylist {

  private _sectionViewDistanceThreshold: number;
  private _selectedPlane: Plane|undefined = undefined ; // for the purposes of obscuring holes beyond a distance from the selected vertical cross-section
  private _checkDistanceFromVerticalCS: boolean = false ;

  constructor(userSessionState: UserSessionState, siteConfig: ISiteConfiguration, layer: BlastholeLayer, sceneMode: SceneMode) {
    super(userSessionState, siteConfig, layer, sceneMode);

    this._sectionViewDistanceThreshold = this.userSessionState.sectionViewDistanceThreshold;

    const boundingBox = this.userSessionState.boundingBox;

    if (boundingBox) {

      const isSelectedXZ = this.userSessionState.selectedCrossSection === LayerType.CrossSectionY;
      const isSelectedYZ = this.userSessionState.selectedCrossSection === LayerType.CrossSectionX;

      if (isSelectedXZ) {
        this._selectedPlane = getPlaneXZ(this.userSessionState.crossSectionOffsetY, siteConfig.modelMatrix, boundingBox, 1);
      } else if (isSelectedYZ) {
        this._selectedPlane = getPlaneYZ(this.userSessionState.crossSectionOffsetX, siteConfig.modelMatrix, boundingBox, 1);
      }
    }
    const csXY = this.userSessionState.layersVisibility.CrossSectionZ;
    const csXZ = this.userSessionState.layersVisibility.CrossSectionY;
    const csYZ = this.userSessionState.layersVisibility.CrossSectionX;
    this._checkDistanceFromVerticalCS = (this._selectedPlane !== undefined) && !csXY && (csXZ || csYZ ) ;
  }

  evaluateShow(feature: Cesium3DTileFeature): boolean {

    // ... Don't show in 2D
    if ( this.sceneMode !== SceneMode.SCENE3D ) {
      return false ;
    }

    // ... Get the layer visibility
    let result: boolean = super.evaluateShow(feature);

    // ... Show/hide based on distance from the selected vertical plane, if any
    if (this._checkDistanceFromVerticalCS) {
      result = this.isCloseToSelectedVerticalCrossSection( feature ) ;
    }

    return result;
  }

  private isCloseToSelectedVerticalCrossSection(feature: Cesium3DTileFeature): boolean {
    if ( !this._selectedPlane ) {
      return true ;
    }
    // ... Read position of interest
    const positionRawValue = feature.getProperty(ADJUSTED_POS_PROPERTY_NAME);
    if ( !positionRawValue ) {
      console.warn(`Error reading feature property: ${ADJUSTED_POS_PROPERTY_NAME}`) ;
      return true ;
    }
    const position = Cartesian3.fromArray(positionRawValue);
    const distFromPlane = BaseHoleStylist.computeDistanceFromPlane( position, this._selectedPlane ) ;

    return distFromPlane <= this._sectionViewDistanceThreshold ;
  }

  /**
   * Computes the distance of a point to a plane
   * @param point
   * @param plane
   * @private
   */
  private static computeDistanceFromPlane(point: Cartesian3, plane: Plane): number {
    let dist = plane.distance;
    let norm = plane.normal;
    return Math.abs( Cartesian3.dot( norm, point ) + dist ) ;
  }
}