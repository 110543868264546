import React, {useEffect, useMemo, useState} from "react";

export const ScreenRecordingContext = React.createContext<any>({});

export interface IScreenRecordingState {
  isSupported: boolean;
  isEnabled: boolean;
  isRecording: boolean;
}

export interface IScreenRecordingActions {
  setIsRecording: (v: boolean)=>void;
  setIsEnabled: (v: boolean)=>void;
}

export const ScreenRecordingContextProvider: React.FC = (props) => {
  const [state, setState] = useState<IScreenRecordingState>({
    isSupported: false,
    isEnabled: false,
    isRecording: false
  });

  useEffect(() => {
    // Check if the APIs are supported
    const isSupported = 'mediaDevices' in navigator &&
        'getDisplayMedia' in navigator.mediaDevices &&
        typeof MediaRecorder !== 'undefined' &&
        MediaRecorder.isTypeSupported('video/webm' ) ;
    setState( prevState => ({...prevState, isSupported: isSupported}));
  }, []);

  const actions = useMemo(()=>{
    return {
      setIsRecording: (v: boolean) => {
        setState( prevState => (prevState.isRecording !== v ? {...prevState, isRecording: v} : prevState ));
      },
      setIsEnabled: (v: boolean) => {
        setState( prevState => (prevState.isEnabled !== v ? {...prevState, isEnabled: v} : prevState ));
      }
    };
  }, []);

  return (
      <ScreenRecordingContext.Provider value={[state, actions]}>
        {props.children}
      </ScreenRecordingContext.Provider>
  )
}

export const useScreenRecording = (): [IScreenRecordingState, IScreenRecordingActions] => {
  const [state, actions] = React.useContext(ScreenRecordingContext);
  return [state, actions];
}
