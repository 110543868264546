import React from "react";
import ReactDOM from "react-dom";
import "./w3.css";
import "./w3-theme.css";
import "./w3-extensions.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/Login";
import Page404 from "./404";
import DataAdminPage from "./pages/DataAdmin";
import UserAccounts from "./pages/UserAccounts";
import UserProfile from "./pages/UserProfile";
import SiteConfigPage from "./pages/SiteConfigPage";
import Assets from "./pages/Assets";
import CesiumPage2 from "./pages/CesiumPage2";
import CustomerSites from "./pages/CustomerSites";
import ActiveUsers from "./pages/ActiveUsers";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Assets />} />
          <Route path="assets" element={<Assets />} />
          <Route path="customerSites" element={<CustomerSites />} />
          <Route path="activeUsers" element={<ActiveUsers />} />
          <Route path="viewer" element={<CesiumPage2 />} />
          <Route path="siteConfig" element={<SiteConfigPage />} />
          <Route path="dataAdmin" element={<DataAdminPage />} />
          <Route path="userAdmin" element={<UserAccounts />} />
          <Route path="userProfile" element={<UserProfile />} />
          {/*<Route path="test" element={<TestPage />} />*/}
          <Route path="*" element={<Page404 />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

