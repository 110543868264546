import {t} from "i18next";
import React, {useCallback} from "react";
import {withTranslation, WithTranslation} from "react-i18next";

export enum HoleLabelType {
  None,
  HoleName,
  DrillName,
  HoleID,
}

interface IProps extends WithTranslation {
  selection: HoleLabelType|undefined;
  onSelectionChanged: (selection: HoleLabelType) => void;
  className?:string;
  style?:any;
}

const HoleLabelSelector: React.FC<IProps> = (props) => {

  const onSelectionChanged = props.onSelectionChanged ;
  const handleSelectionChanged = useCallback(( value: string ) => {
    let selection = HoleLabelType[ value as keyof typeof HoleLabelType ];
    onSelectionChanged( selection );
  }, [onSelectionChanged]);

  return (
      <select
          className={`w3-select unselectable w3-theme-d4 w3-center w3-border w3-border-grey w3-round-medium ${props.className}`}
          style={{...props.style, fontSize: "12px", cursor: "pointer"}}
          name="HoleLabelSelector"
          value={props.selection ? `${HoleLabelType[props.selection]}` : undefined}
          onChange={(e) => handleSelectionChanged(e.target.value)}
      >
        <option value={HoleLabelType[HoleLabelType.None]}>{t(HoleLabelType[HoleLabelType.None])}</option>
        <option value={HoleLabelType[HoleLabelType.HoleID]}>{t(HoleLabelType[HoleLabelType.HoleID])}</option>
        <option value={HoleLabelType[HoleLabelType.HoleName]}>{t(HoleLabelType[HoleLabelType.HoleName])}</option>
        <option value={HoleLabelType[HoleLabelType.DrillName]}>{t(HoleLabelType[HoleLabelType.DrillName])}</option>
      </select>
  )
}

export default withTranslation()(React.memo(HoleLabelSelector));
