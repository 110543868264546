import React, {createContext, Dispatch, SetStateAction, useContext, useState} from "react";
import BasicDialog from "../components/Dialogs/BasicDialog";

export const HelpContext = createContext<any>({});

interface IProps {

}

export interface IHelpRequestedProps {
  helpTitle?:string;
  helpContent?: React.ReactNode;
  width?:string|undefined;
}

export const HelpContextProvider: React.FC<IProps> = (props) => {
  const [helpRequested, setHelpRequested] = useState<IHelpRequestedProps|undefined>();

  return (
     <HelpContext.Provider value={[helpRequested, setHelpRequested]}>
       {props.children}
       {
           helpRequested && helpRequested.helpContent && (
               <BasicDialog
                   title={helpRequested.helpTitle??''}
                   width={helpRequested.width??"calc(100vw - 400px)"}
                   btnRight={{ label: "Close", className: 'w3-grey w3-hover-light-blue', action: ()=>{
                       setHelpRequested( undefined ) ;
                     } }}
                   // content={helpRequested.helpContent}
               >
                 {helpRequested.helpContent}
               </BasicDialog>
           )
       }
     </HelpContext.Provider>
  )
}

export const useHelp = (): [IHelpRequestedProps | undefined, Dispatch<SetStateAction<IHelpRequestedProps | undefined>>] => {
  const [state, actions] = useContext(HelpContext);
  return [state, actions] ;
}
