import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

import TextAndImage from "./Widgets/TextAndImage";
import {VideoResourceUrl} from "../lib/VideoResourceUrl";

/**
 * Section Viewer side menu help
 *
 * @param props
 * @constructor
 */

const Help_CesiumView_FloatingColorLegend: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <>
        <div className={'w3-panel'}>
          <h2 className={"w3-left-align"}>{t("Using the 'Floating' Color Legend")}</h2>
          <p className={"text-full-justify"}>
            {t("The 'floating' color legend provides quick access to the color legend, allowing you to select the " +
                "geo-modeling attribute and to tweak the attribute range - or category - settings.")}
          </p>
          <TextAndImage
              title={""}
              text={"Move the mouse over the edge of the floating color legend and click-and-drag it anywhere in the 3D " +
                  "view. Please note that the movable item may feel somewhat 'sticky' when close to the edge of the " +
                  "viewing area; there is a distance (from the edge) where the element will 'snap' to the edge."}
              src={VideoResourceUrl("floating_legend_move.webm")}
              imgRatio={"w3-half"}/>
        </div>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage
            title={"Changing the Selected Geo-Modeling Attribute"}
            text={"Click on the top-most dropdown box to select a different geo-modeling attribute."}
            src={VideoResourceUrl("floating_legend_change_attr.webm")}
            imgRatio={"w3-half"}/>
        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <TextAndImage
            title={"Expand for More Options"}
            text={"Hover over the floating color legend and then click on " +
            "the top-right resize icon.  This will expand the color legend to reveal more options.  Once expanded, all " +
            "of the color legend parameters are accessible."}
            src={VideoResourceUrl("floating_legend_expand.webm")}
            imgRatio={"w3-half"}/>

      </>
  )
}

export default withTranslation()(Help_CesiumView_FloatingColorLegend);

