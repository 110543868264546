import React, {useMemo, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCaretRight,
  faChevronRight,
  faDivide,
  faEdit,
  faPalette,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import "../../vertical_range_editor.css";
import {ColorUtil} from "../../lib/ColorUtil";
import {t} from "i18next";
import DotMenu from "../DotMenu/DotMenu";
import {withTranslation, WithTranslation} from "react-i18next";
import DropdownPortal from "../DotMenu/DropdownPortal";

export enum VMultiSplitPanelItemOptions {
    None = 0x00,
    Resizable = 0x01,
    Splittable = 0x02,
    Removable = 0x04,
    LabelEditable = 0x08,
    ColorEditable = 0x10
}

interface IVMultiSplitPanelItemProps extends WithTranslation {
    height: number;     // height of the panel element pane
    cssColor: string;   // Color of the panel element pane
    text: string;       // Text to display in the middle of the element pane
    onSplitBtnClicked: (equalDistribution?:boolean)=>void;
    onColorSelectBtnClicked?: ()=>void;
    onRemoveBtnClicked: ()=>void;
    onLabelEditBtnClicked: ()=>void;
    options: VMultiSplitPanelItemOptions ;
    overrideMouseIsOver?: boolean;
}

const VMultiSplitPanelItem: React.FC<IVMultiSplitPanelItemProps> = (props) => {

    const [mouseIsOver, setMouseIsOver] = useState<boolean>(false);
    const [isResizing] = useState<boolean>((props.options & VMultiSplitPanelItemOptions.Resizable) !== 0x00 ) ;
    const [isSplittable] = useState<boolean>((props.options & VMultiSplitPanelItemOptions.Splittable) !== 0x00 ) ;
    const [isRemovable] = useState<boolean>((props.options & VMultiSplitPanelItemOptions.Removable) !== 0x00 ) ;
    const [isLabelEditable] = useState<boolean>((props.options & VMultiSplitPanelItemOptions.LabelEditable) !== 0x00 ) ;
    const [isColorEditable] = useState<boolean>((props.options & VMultiSplitPanelItemOptions.ColorEditable) !== 0x00 ) ;

    const divRef = useRef<HTMLDivElement>(null);

    const [menuOptions, setMenuOptions] = useState<{
      x?: number | undefined;
      y?: number | undefined;
      show: boolean;
    }>({ show: false });

    function handleMouseOver() {
        setMouseIsOver(true);
    }

    function handleMouseLeave() {
        setMouseIsOver(false);
    }

    function handleRightClick(e: React.MouseEvent<HTMLDivElement>) {
      e.stopPropagation();
      e.preventDefault();

      if (divRef.current) {
        setMenuOptions({
          x: e.clientX,
          y: e.clientY,
          show: true,
        })
      }
    }

    const cssColor = props.cssColor ;
    const contrastingColor = useMemo(()=>ColorUtil.contrastingColor(cssColor), [cssColor]);

    return (
        <div
            ref={divRef}
            className={"w3-display-container container__middle w3-center noselect"}
            style={{height: `${props.height}px`, backgroundColor: props.cssColor, color: `#${contrastingColor}`, border: mouseIsOver ? `thick solid #${contrastingColor}` : "inherit"}}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onContextMenu={(e)=>handleRightClick(e)}
        >
          {mouseIsOver ? t('Right-click for options') : props.text}

          {menuOptions?.show && (
              <PanelItemContextMenu
                    t={props.t}
                    i18n={props.i18n}
                    tReady={props.tReady}
                    show={menuOptions.show}
                    x={menuOptions.x}
                    y={menuOptions.y}
                    onDismiss={()=>{
                      setMenuOptions({show: false});
                      handleMouseLeave();
                    }}
                    onSlitRange={isSplittable ? (opts) => {
                      props.onSplitBtnClicked(opts);
                      handleMouseLeave();
                    } : undefined }
                    onEditColor={isColorEditable ? () => {
                      if (props.onColorSelectBtnClicked) props.onColorSelectBtnClicked();
                      handleMouseLeave();
                    } : undefined}
                    onEditLabel={isLabelEditable ? () => {
                      props.onLabelEditBtnClicked() ;
                      handleMouseLeave();
                    } : undefined}
                    onDelete={isRemovable ? () => {
                      props.onRemoveBtnClicked();
                      handleMouseLeave();
                    } : undefined}
              />
          )}
        </div>
    )
}

export default withTranslation()(VMultiSplitPanelItem) ;

interface IPanelItemDotMenuProps extends WithTranslation {
  show: boolean;
  x?: number|undefined;
  y?: number|undefined;
  onDismiss: ()=>void;
  onSlitRange?: ((equalDistribution?: boolean)=>void)|undefined;
  onEditLabel?: (()=>void)|undefined;
  onEditColor?: (()=>void)|undefined;
  onDelete?: (()=>void)|undefined;
}


const PanelItemContextMenu: React.FC<IPanelItemDotMenuProps> = (props) => {
  const t = props.t;

  const handleSplitRange = (equalDistribution?: boolean) => {
    console.log(`handleSplitRange: equalDistribution=${equalDistribution}`);
    if ( props.onSlitRange ) {
      props.onSlitRange( equalDistribution );
    }
  }
  return (
      <>
        <DropdownPortal
            x={props.x}
            y={props.y}
            onDismiss={props.onDismiss}
        >
            <div
                className="w3-dropdown-content w3-bar-block w3-card-4 w3-show"
                style={{transform: "translate(-90%)", width: "250px"}}
            >
              { props.onSlitRange &&
                <button className="w3-bar-item w3-button" onClick={()=>handleSplitRange(false)}>
                  <FontAwesomeIcon icon={faDivide} className={'w3-margin-right'}/>
                  {t("Split in Half")}
                </button>
              }
              { props.onSlitRange &&
                  <button className="w3-bar-item w3-button" onClick={()=>handleSplitRange(true)}>
                    <FontAwesomeIcon icon={faBars} className={'w3-margin-right'}/>
                    {t("Split and Redistribute")}
                  </button>
              }
              { props.onEditLabel &&
                <button className="w3-`bar-item w3-button" onClick={props.onEditLabel} >
                  <FontAwesomeIcon icon={faEdit} className={'w3-margin-right'} />
                  {t("Edit label")}
                </button>
              }
              { props.onEditColor &&
                <button className="w3-bar-item w3-button" onClick={props.onEditColor} >
                  <FontAwesomeIcon icon={faPalette} className={'w3-margin-right'} size={'sm'}/>
                  {t("Edit color")}
                </button>
              }
              { props.onDelete &&
                <button className="w3-bar-item w3-button" onClick={props.onDelete} >
                  <FontAwesomeIcon icon={faXmark} className={'w3-margin-right'} />
                  {t("Delete")}
                </button>
              }
            </div>
        </DropdownPortal>
      </>
  )
}
