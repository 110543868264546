import React, {useCallback, useMemo} from "react";
import {Cartesian3, Color, PropertyBag} from "cesium";
import {withTranslation, WithTranslation} from "react-i18next";
import FilledRectangle from "../BasicControls/FilledRectangle";
import {DEFAULT_NEUTRAL_COLOR} from "../../domain/IStyle";
import {IBoundingBox} from "../../domain/IBoundingBox";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import {LayerType} from "../../../../model/LayerType";
import {CesiumMovementEvent} from "resium";

interface IProps extends WithTranslation{
  originReference: Cartesian3;
  boundingBox: IBoundingBox;
  blockSize: Cartesian3;
  properties?: PropertyBag | {
    [key: string]: any;
  }
  onLeftClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onRightClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onDoubleClick?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseMove?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseUp?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
  onMouseDown?: ((movement: CesiumMovementEvent, target: any)=>void)|undefined;
}

const CrossSectionY: React.FC<IProps> = (props) => {
  const [siteData] = useSiteConfig();
  const [userSession] = useUserSessionContext();

  const isSelected = useMemo(()=>{
    return userSession.selectedCrossSection === LayerType.CrossSectionY ;
  }, [userSession.selectedCrossSection]);

  // ... Highlight the perimeter if this cross-section happens to be selected
  const color = useMemo(()=>{
    return isSelected ? userSession.selectedCrossSectionStyle.outlineColor : DEFAULT_NEUTRAL_COLOR;
  }, [isSelected, userSession.selectedCrossSectionStyle.outlineColor]);

  // ... Compute the cross-section's perimeter vertices
  const ecefVertices = useMemo(()=>{
    const y = props.boundingBox.minPos.y + userSession.crossSectionOffsetY ;
    return [
      Cartesian3.fromArray( [ props.boundingBox.minPos.x, y, props.boundingBox.minPos.z, ]),
      Cartesian3.fromArray( [ props.boundingBox.minPos.x, y, props.boundingBox.maxPos.z, ]),
      Cartesian3.fromArray( [ props.boundingBox.maxPos.x, y, props.boundingBox.maxPos.z, ]),
      Cartesian3.fromArray( [ props.boundingBox.maxPos.x, y, props.boundingBox.minPos.z, ]),
    ];
  }, [props.boundingBox, userSession.crossSectionOffsetY]);

  const handleLeftClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onLeftClick ) {
      props.onLeftClick( movement, target );
    }
  }, []);
  const handleRightClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onRightClick ) {
      props.onRightClick( movement, target );
    }
  }, []);
  const handleDoubleClick = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onDoubleClick ) {
      props.onDoubleClick( movement, target );
    }
  }, []);
  const handleMouseMove = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseMove ) {
      props.onMouseMove( movement, target );
    }
  }, []);
  const handleMouseUp = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseUp ) {
      props.onMouseUp( movement, target );
    }
  }, []);
  const handleMouseDown = useCallback((movement: CesiumMovementEvent, target: any) => {
    if ( props.onMouseDown ) {
      props.onMouseDown( movement, target );
    }
  }, []);

  return (
      <>
          <FilledRectangle
              show={userSession.layersVisibility.CrossSectionY}
              name={LayerType[LayerType.CrossSectionY]}
              properties={props.properties}
              outline={true}
              outlineColor={color}
              outlineWidth={1}
              fillColor={Color.WHITE.withAlpha(.10)}
              // fillColor={Color.BLACK.withAlpha(.01)}
              modelMatrix={siteData.modelMatrix}
              ecefVertices={ecefVertices}
              onLeftClick={handleLeftClick}
              onRightClick={handleRightClick}
              onDoubleClick={handleDoubleClick}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseDown={handleMouseDown}
          />
      </>
  )
}

export default withTranslation()(React.memo(CrossSectionY));
