import React, {useCallback, useMemo} from "react";

interface ISideMenuRowProps {
    id?:string;
    disabled?:boolean,
    widths?: string[],
    className?:string;
    style?:any;
}

const SideMenuRow: React.FC<ISideMenuRowProps> = (props) => {

    const wrapperClass = useMemo(()=>"w3-cell w3-cell-middle", []);

    const getWidth = useCallback((idx: number): string => {
        return props.widths
            ? props.widths[idx] ?? "auto"
            : "auto";
    }, []);

    return (
        <div
            id={props.id}
            className={`w3-container w3-cell-row ${props.className} ${props.disabled ? "w3-opacity":''}`}
            style={props.style}
        >
            {
                React.Children.map(props.children, ( child, childIndex ) => {
                    return <div className={wrapperClass} style={{width: getWidth(childIndex)}}>{child}</div>
                })
            }
        </div>
    )
}

export default SideMenuRow;