import React, {useCallback, useMemo, useRef, useState} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import AttributeSelector from "../BasicControls/AttributeSelector";
import {HorizontalSeparator} from "../BasicControls/HorizontalSeparator";
import ColorLegendTable from "../Views/ColorLegendTable";
import LabelIconButton from "../BasicControls/LabelIconButton";
import {t} from "i18next";
import {
  faDownLeftAndUpRightToCenter,
  faRotateLeft,
  faUpRightAndDownLeftFromCenter
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CompactColorLegend from "../Views/CompactColorLegend";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import {AttributeType, IAttributeTypeMap} from "../../domain/AttributeType";
import IColorLegendConfiguration from "../../domain/IColorLegendConfiguration";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import {ICameraVantagePoint} from "../Views/ICameraVantagePoint";

interface IProps extends WithTranslation {
  expanded: boolean;
  onExpandedChanged: (expanded: boolean, width?: number, height?:number)=>void;
  onCameraVantagePointSet: (cameraVantagePoint: ICameraVantagePoint)=>void;
}

const CollapsibleColorLegend: React.FC<IProps> = (props)=>{
  const divRef = useRef<HTMLDivElement>(null);
  const [siteConfig] = useSiteConfig();
  const [userSession, userSessionActions] = useUserSessionContext();

  const [colorLegendConfig, colorLegendState] = useMemo(()=>{
    if ( !userSession.selectedAttribute || !userSession.colorLegendStates || !siteConfig?.legendConfigs ) {
      return [undefined, undefined];
    }
    const attributeIndex = AttributeType[userSession.selectedAttribute] as keyof IAttributeTypeMap<IColorLegendConfiguration> ;
    const colorLegendConfig = siteConfig.legendConfigs[attributeIndex];
    const colorLegendState = userSession.colorLegendStates.get( userSession.selectedAttribute );

    return [colorLegendConfig, colorLegendState];

  }, [userSession.selectedAttribute, siteConfig?.legendConfigs, userSession.colorLegendStates]);


  const [showToggleIcon, setShowToggleIcon] = useState<boolean>(false);

  const icon = props.expanded ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter;

  const resetActiveColorLegend = useCallback(() => {
    if (colorLegendState) {
      userSessionActions.resetColorLegend( colorLegendState, {visible: true, opacity: 1.0} );
      userSessionActions.persistColorLegend( colorLegendState );
      // userSessionActions.resetLayersVisibilityAndOpacity();
    }
  }, [colorLegendState, userSessionActions]);

  return (
      <div
          ref={divRef}
          style={{width: `${props.expanded ? 400 : 200 }px`}}
          onMouseOver={()=>setShowToggleIcon(true)}
          onMouseLeave={()=>setShowToggleIcon(false)}
          onMouseDown={e=>e.stopPropagation()}
          onMouseUp={e=>e.stopPropagation()}
      >
        {
            props.expanded && userSession.attributesInData && (
            <>
              <div className={"w3-cell-row"}>
                <div className={"w3-cell w3-left-align"}>
                  <AttributeSelector
                      selectedAttribute={userSession.selectedAttribute}
                      availableAttributes={userSession.attributesInData??[]}
                      onSelectedAttributeChanged={userSessionActions.setSelectedAttribute}
                  />
                </div>
                <div className={"w3-cell w3-right-align"}>
                  <FontAwesomeIcon
                      className={"unselectable"}
                      style={{
                        cursor: "pointer",
                        color: showToggleIcon ? "lightgray" : "transparent",
                      }}
                      icon={icon}
                      onClick={(e) => {
                        showToggleIcon && props.onExpandedChanged(!props.expanded, divRef.current?.offsetWidth, divRef.current?.offsetHeight);
                      }}
                  />
                </div>
              </div>
              <HorizontalSeparator className={"w3-margin-left w3-margin-right"}/>
              <ColorLegendTable onCameraVantagePointSet={props.onCameraVantagePointSet} />
              <LabelIconButton
                  className={"w3-margin-bottom w3-hover-red w3-border w3-border-red"}
                  label={t("reset")}
                  icon={faRotateLeft}
                  onClick={resetActiveColorLegend}
              />
            </>
          )
        }
        {!props.expanded && userSession.attributesInData && (
            <>
              <div className={"w3-cell-row"}>
                <div className={"w3-cell w3-left-align"}>
                  <AttributeSelector
                      selectedAttribute={userSession.selectedAttribute}
                      availableAttributes={userSession.attributesInData??[]}
                      onSelectedAttributeChanged={userSessionActions.setSelectedAttribute}
                  />
                </div>
                <div className={"w3-cell w3-right-align"}>
                  <FontAwesomeIcon
                      className={"unselectable w3-margin-left"}
                      style={{
                        cursor: "pointer",
                        color: showToggleIcon ? "lightgray" : "transparent",
                      }}
                      icon={icon}
                      onClick={(e) => {
                        showToggleIcon && props.onExpandedChanged(!props.expanded, divRef.current?.offsetWidth, divRef.current?.offsetHeight);
                      }}
                  />
                </div>
              </div>
              <CompactColorLegend
                  width={150}
                  height={400}
                  margin={10}
                  fontSize={12}
                  colorLegendConfig={colorLegendConfig}
                  colorLegendState={colorLegendState}
              />
            </>
        )}

      </div>
  )
}

export default withTranslation()(CollapsibleColorLegend);

