import React, {CSSProperties, useCallback, useEffect, useMemo, useRef, useState} from "react";

interface IProps {
  className?:string|undefined;
  // style?:CSSProperties|undefined;
  min: number;
  max: number;
  step: number;
  value: number;
  onValueChanged?: ((value: number)=>void)|undefined;
  onRangeOpacityReleased?:(()=>void)|undefined;
  disabled?:boolean|undefined;
}

const Slider: React.FC<IProps> = (props) => {

  const min = props.min ;
  const max = props.max ;
  const step = props.step ;
  const value = props.value ;
  const onValueChanged = props.onValueChanged ;
  const onRangeOpacityReleased = props.onRangeOpacityReleased ;

  const [rangeValue, setRangeValue] = useState<number>(props.value);
  // const [moving, setMoving] = useState<boolean>();

  const moving = useRef<boolean>();

  const range = useMemo(()=>{
    if ( max <= min ) {
      throw new Error("Invalid properties: max must be greater than min");
    }
    return max - min ;
  }, [min, max]);

  const handleValueChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>)=>{
    // console.log(`handleValueChanged: ${+e.target.value}`);
    setRangeValue( +e.target.value );
    // if (onValueChanged) {
    //   const value = +e.target.value;
    //   (async () => {
    //     onValueChanged( value );
    //   } )();
    // }
  }, [/*onValueChanged, */setRangeValue])

  const handleRangeOpacityReleased = useCallback(()=>{
    // setMoving(false);
    moving.current = false;
    if (onValueChanged) {
      onValueChanged( rangeValue );
    }
    if (onRangeOpacityReleased) {
      onRangeOpacityReleased();
    }
  }, [onRangeOpacityReleased, /*setMoving, */rangeValue, onValueChanged]);

  useEffect(()=>{
    setRangeValue(value);
  }, [value, setRangeValue]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>)=>{
    event.preventDefault();
  }, [])

  const newValue = useMemo((): number=>{
    return Number( (rangeValue - min) * 100 / (range) );
  }, [rangeValue, min, range]);

  const newPosition = useMemo((): number=>{
    return 8 - (newValue * 0.15);
  }, [newValue]);

  const handleMouseDown = useCallback(() => {
    moving.current = true ;
    // setMoving(true);
  }, []);

  const display = moving.current ? "inherit" : "none" ;

  const style = useMemo((): CSSProperties=>{
    return { left: `calc(${newValue}% + (${newPosition}px))`, display: display }
  }, [newValue, newPosition, display]);

  return (
      <div className={`${props.className}`}>
        <div className={`range-wrap zoom-left`}>
          <div
              className={`range-value`}
              style={style}
          >
            <span>{rangeValue}</span>
          </div>
          <input
              type="range"
              min={min}
              max={max}
              step={step}
              value={rangeValue}
              onChange={handleValueChanged}
              onKeyDown={handleKeyDown}
              onMouseUp={handleRangeOpacityReleased}
              onMouseDown={handleMouseDown}
              disabled={props.disabled}
          />
        </div>
      </div>
  )
}

export default Slider ;
