import { WithTranslation, withTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { sortObjects } from "../../lib/sort";

import IUser from "../../model/UserAdministration/IUser";
import SortHeader from "../Sorting/SortHeader";
import SortHeaderItem, {IColumnSort} from "../Sorting/SortHeaderItem";
import UserRow from "./UserRow";
import {useState} from "react";

interface Props extends WithTranslation {
  users: IUser[];
}

const UserList: React.FC<Props> = ({ users, t }) => {
  const [columnSort, setColumnSort] = useState<IColumnSort>({
    sortBy: "username",
    sortOrder: "desc"
  });

  const sortedUsers = sortObjects(users, columnSort.sortBy, columnSort.sortOrder);

  return (
    <>
      <SortHeader>
        <SortHeaderItem
          widthPercent={29}
          defaultFilterprop="username"
          filterProp="username"
          columnSort={columnSort}
          onColumnSort={(sort=>setColumnSort(sort))}
        >
          {t("username")}
        </SortHeaderItem>
        <SortHeaderItem
          widthPercent={53}
          defaultFilterprop="username"
          filterProp="roles"
          columnSort={columnSort}
          onColumnSort={(sort=>setColumnSort(sort))}
        >
          {t("roles")}
        </SortHeaderItem>
        <SortHeaderItem
          widthPercent={17}
          defaultFilterprop="username"
          filterProp="enabled"
          columnSort={columnSort}
          onColumnSort={(sort=>setColumnSort(sort))}
        >
          {t("enabled")}
        </SortHeaderItem>
      </SortHeader>
      <ul className="w3-ul">
        {sortedUsers.map((user) => (
          <UserRow user={user} key={user.username}></UserRow>
        ))}
      </ul>
    </>
  );
};

export default withTranslation()(UserList);
