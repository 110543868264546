import React, {CSSProperties, useMemo, useState} from "react";
import {IconProp, SizeProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IProps {
  onChangeTooltip: (tooltip: React.ReactNode|undefined, position: {x:number, y:number})=>void;
}

const BottomBarContainer : React.FC<IProps> = (props)=>{

  const [hovering, setHovering] = useState(false);

  const onChangeTooltip = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, tooltip: React.ReactNode|undefined ) => {
    const rect = e.currentTarget.getBoundingClientRect() ;
    const x = (rect.x + rect.width/2);
    const y = e.currentTarget.parentElement?.offsetTop ?? (rect.y - rect.height);
    props.onChangeTooltip(tooltip, { x, y });
  }

  const itemStyle = useMemo((): CSSProperties=>{
    return {
      float: "none",
      display: "inline-block",
      backgroundColor: hovering ? "#000000FF":"#000000dd",
      verticalAlign: "middle",
      height: "50px",
    };
  }, [hovering]);

  return (
      <>
          <div
              style={{
                  position: "absolute",
                  bottom: "24px",
                  marginLeft: "45px",
                  minHeight: "40px",
                  zIndex: "1",
                  textAlign: "center",
              }}
              className={"w3-bar"}
              onMouseEnter={()=>setHovering(true)}
              onMouseLeave={()=>setHovering(false)}
          >
              {/* List of child elements (each toolbar button) */}
              {
                  React.Children.map(props.children, (child, idx) => {
                      if (!React.isValidElement(child)) {
                          return;
                      }
                      return (
                          //@ts-ignore
                          <span
                              className={`w3-bar-item ${child.props.className} ${hovering ? 'w3-opacity-off' : 'w3-opacity'}`}
                              style={itemStyle}
                              onClick={child.props.onClick}
                              onMouseEnter={(e)=>onChangeTooltip(e, child.props.tooltip)}
                              onMouseLeave={(e)=>onChangeTooltip(e, undefined)}
                          >
                              {child}
                          </span>
                      )
                  })
              }
          </div>
      </>
  )
}

export default BottomBarContainer;

interface IButtonBarItemProps {
    content: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLElement>;
    tooltip?: React.ReactNode;
    className?: string;
}

export const BottomBarItem: React.FC<IButtonBarItemProps> = (props) => {
    return (
        <div
            // style={{width: "32px", height: "32px"}}
            // className={"w3-hover-opacity-off"}
        >
          {props.content}
        </div>
    )
}
