import React, {CSSProperties, useEffect, useMemo, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretLeft, faCaretRight, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {withTranslation, WithTranslation} from "react-i18next";
import ResponsiveSquareGrid from "../BasicControls/ResponsiveSquareGrid";
import {t} from "i18next";
import mouseLeftMove from "../../../../img/mouse-left-move.png";
import mouseZoom from "../../../../img/mouse_zoom.png";
import {useUserSessionContext} from "../Contexts/UserSessionContext";

interface IProps extends ICrossSectionInHelpCallbacks, WithTranslation {
  style?:CSSProperties;
  className?:string;
  foreColor?: "white"|"black"|"light-blue"|"amber";
}

interface ICrossSectionInHelpCallbacks {
  onMoveCrossSection?: (delta: number)=>void;
  onResetCrossSectionOffset?: ()=>void;
}

const UIControlsHelp: React.FC<IProps> = (props) => {
  const [userSession] = useUserSessionContext();

  const textColor = props.foreColor ?? `w3-text-${props.foreColor}`;
  const svgColor = (props.foreColor && props.foreColor !== 'black') ? `svg-${props.foreColor}` : '';
  const borderColor = props.foreColor ?? `w3-border-${props.foreColor}`;

  const hasCrossSectionVisible = useMemo(()=>{
    return userSession.layersVisibility?.CrossSectionX || userSession.layersVisibility?.CrossSectionY || userSession.layersVisibility?.CrossSectionZ;
  }, [
    userSession.layersVisibility?.CrossSectionX,
    userSession.layersVisibility?.CrossSectionY,
    userSession.layersVisibility?.CrossSectionZ]);

  return (
      <div
        style={props.style}
        className={`unselectable w3-container w3-center ${props.className}`}
      >
        {/* Mouse controls */}

        <div className={`w3-small w3-margin-top ${textColor} w3-section`}>
          <b>{t('Mouse Controls')}</b>
        </div>

        {/* Pan */}
        <div className={'w3-container'}>
          <div className={`w3-show-inline-block ${textColor}`}>
            <span className={'w3-small'}>{t('Pan')}</span>
            : <img className={`${svgColor}`} src={mouseLeftMove} alt={t("Pan")} width={32} height={32}/>
          </div>
        </div>
        {/* Rotate scene */}
        <div className={'w3-container'}>
          {/*<hr className={"w3-margin"} style={{ borderTop: "0.5px solid" }}/>*/}
          <div className={`w3-show-inline-block ${textColor}`}>
            <span className={'w3-small'}>{t('Rotate')}</span>
            : CTRL + <img className={`${svgColor}`} src={mouseLeftMove} alt={t("Rotate")} width={32} height={32}/>
          </div>
        </div>
        {/* Zoom */}
        <div className={'w3-container'}>
          {/*<hr className={"w3-margin"} style={{ borderTop: "0.5px solid" }}/>*/}
          <div className={`w3-show-inline-block ${textColor}`}>
            <span className={'w3-small'}>{t('Zoom')}</span>
            : <img className={`${svgColor}`} src={mouseZoom} alt={t("Zoom")} width={32} height={32}/>
          </div>
        </div>

        {/* Cross-section keyboard controls */}
        {hasCrossSectionVisible &&
            <div className={'w3-container'}>
              <hr className={"w3-margin"} style={{ borderTop: "0.5px solid" }}/>
              <div className={`w3-small w3-margin-top ${textColor} w3-section`}>
                <b>{t('Section Offset Controls')}:</b>
              </div>
              <ResponsiveSquareGrid
                  rows={2} cols={5}
                  contents={[
                    {
                      content: <div className={`w3-tiny ${textColor}`}>{t("Fwd")}<br/>+1</div>,
                      className: `${textColor}`,
                    },
                    {
                      content: <div className={`w3-tiny ${textColor}`}>{t("Fwd")}<br/>+10</div>,
                      className: `${textColor}`,
                    },
                    {
                      content: <div className={`w3-tiny ${textColor}`}>{t("Reset")}</div>,
                      className: `${textColor}`,
                    },
                    {
                      content: <div className={`w3-tiny ${textColor}`}>{t("Back")}<br/>+1</div>,
                      className: `${textColor}`,
                    },
                    {
                      content: <div className={`w3-tiny ${textColor}`}>{t("Back")}<br/>+10</div>,
                      className: `${textColor}`,
                    },

                    {
                      content: <FontAwesomeIcon icon={faCaretUp}/>,
                      className: `w3-tag w3-round w3-theme-d4 w3-border ${borderColor} w3-hover-light-blue`,
                      onClick: ()=> props.onMoveCrossSection && props.onMoveCrossSection(1)
                    },
                    {
                      content: <div className={`w3-tiny ${textColor}`}>Pg<br/>Up</div>,
                      className: `w3-tag w3-round w3-theme-d4 w3-border ${borderColor} w3-hover-light-blue`,
                      onClick: ()=> props.onMoveCrossSection && props.onMoveCrossSection(10)
                    },
                    {
                      content: <div className={`w3-tiny ${textColor}`}>Home</div>,
                      className: `w3-tag w3-round w3-theme-d4 w3-border ${borderColor} w3-hover-light-blue`,
                      onClick: ()=> props.onResetCrossSectionOffset && props.onResetCrossSectionOffset()
                    },
                    {
                      content: <FontAwesomeIcon icon={faCaretDown}/>,
                      className: `w3-tag w3-round w3-theme-d4 w3-border ${borderColor} w3-hover-light-blue`,
                      onClick: ()=> props.onMoveCrossSection && props.onMoveCrossSection(-1)
                    },
                    {
                      content: <div className={`w3-tiny ${textColor}`}>Pg<br/>Dn</div>,
                      className: `w3-tag w3-round w3-theme-d4 w3-border ${borderColor} w3-hover-light-blue`,
                      onClick: ()=> props.onMoveCrossSection && props.onMoveCrossSection(-10)
                    },
                  ]}
              />
            </div>
        }
      </div>
  )
}

export default withTranslation()(UIControlsHelp);
