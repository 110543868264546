import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import Help_CesiumView_SideMenu_View from "./Help_CesiumView_SideMenu_View";
import Help_CesiumView_SideMenu_Filters from "./Help_CesiumView_SideMenu_Filters";
import Help_CesiumView_SideMenu_Layers from "./Help_CesiumView_SideMenu_Layers";
import Help_CesiumView_SideMenu_Legend from "./Help_CesiumView_SideMenu_Legend";
import Help_CesiumView_SideMenu_SectionViewer from "./Help_CesiumView_SideMenu_SectionViewer";
import Help_CesiumView_FloatingColorLegend from "./Help_CesiumView_FloatingColorLegend";
import Help_CesiumView_BottomToolbar from "./Help_CesiumView_BottomToolbar";
import VideoClip from "./Widgets/VideoClip";
import {VideoResourceUrl} from "../lib/VideoResourceUrl";

/**
 * Help page for using the Pattern Viewer page (also known as the Cesium View).
 *
 * @param props
 * @constructor
 */

const Help_CesiumView: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <div className={"w3-container"}>
        <h1>{t('Using the Pattern Viewer')}</h1>

        <div className={'w3-panel'}>
          <p className={"text-full-justify"}>
            {t("The Pattern Viewer allows you to examine the patterns of a user session in more detail.  Use mouse controls to pan, zoom and rotate the scene.")}
          </p>
          <img src={"/help/pattern_viewer.jpg"} className={'w3-block'}/>
        </div>

        <div className={'w3-panel'}>
          <h2 className={"w3-left-align"}>{t("Accessing the Side Menu")}</h2>
          <div className={"w3-row"}>
            <div className={"w3-col w3-half"}>
              <p className={"text-full-justify w3-margin-right"}>
                {t("Click on the 'burger' icon in the top left-hand corner to expand the side menu.")}
              </p>
              <p className={"text-full-justify w3-margin-right"}>
                {t("Click on any of the side menu items to expand or collapse individual sections.")}
              </p>
              <p className={"text-full-justify w3-margin-right"}>
                {t("With the side menu expanded, click on the top right-hand corner of the ide menu to collapse it")}
              </p>
            </div>
            <VideoClip src={VideoResourceUrl("side_menu_nav.webm")} className={"w3-col w3-half w3-padding"}/>
          </div>
        </div>

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <Help_CesiumView_SideMenu_View />

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <Help_CesiumView_SideMenu_Filters />

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <Help_CesiumView_SideMenu_Layers />

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <Help_CesiumView_SideMenu_Legend />

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <Help_CesiumView_SideMenu_SectionViewer />

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <Help_CesiumView_FloatingColorLegend />

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <Help_CesiumView_BottomToolbar />

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

      </div>
  )
}

export default withTranslation()(Help_CesiumView);
