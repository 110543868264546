import { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useFetchWithAuth } from "../../lib/auth/fetchWithAuth";
import { patternEquals } from "../../lib/patterns/compare";
import { parsePatternsResponse } from "../../lib/patterns/parsePatternResponse";
import { PatternAndAssetsApi } from "../../lib/routes";
import IPattern from "../../model/IPattern";
import WarningBox from "../WarningBox";

const PATTERN_POLL_INTERVAL_MS = 5000;

interface Props extends WithTranslation {
  existingPatterns: IPattern[];
}

const arePatternsUpdated = (listA: IPattern[], listB: IPattern[]) => {
  if (listA.length !== listB.length) return true;

  const sortByNameFunction = (a: IPattern, b: IPattern) =>
    a.name > b.name ? 1 : -1;

  // We need a shallow copy since the sort method does it in place
  const sortedListA = [...listA].sort(sortByNameFunction);
  const sortedListB = [...listB].sort(sortByNameFunction);

  return sortedListA.some(
    (pattern, index) => !patternEquals(pattern, sortedListB[index])
  );
};

const PatternsUpdatedWarning: React.FC<Props> = ({ existingPatterns, t }) => {
  const [newPatternsAvailable, setNewPatternsAvailable] = useState(false);
  const fetchWithAuth = useFetchWithAuth();

  useEffect(() => {
    // if new patterns are available, we stop polling.
    if (newPatternsAvailable) return;

    let timeout: NodeJS.Timeout;

    const fetchPatternsCallback = () => {
      fetchWithAuth(PatternAndAssetsApi.getPatternSummariesUrl())
        .then((res) => parsePatternsResponse(res))
        .then((newPatterns) => {
          if (arePatternsUpdated(existingPatterns, newPatterns)) {
            setNewPatternsAvailable(true);
          }

          clearTimeout(timeout);
          timeout = setTimeout(fetchPatternsCallback, PATTERN_POLL_INTERVAL_MS);
        });
    };

    timeout = setTimeout(fetchPatternsCallback, PATTERN_POLL_INTERVAL_MS);

    return () => clearTimeout(timeout);
  }, [newPatternsAvailable, existingPatterns]);

  return newPatternsAvailable ? (
    <WarningBox>
      {t("new_patterns_on_server")}
      &nbsp;&nbsp;
      <Link reloadDocument to="/assets">
        {t("reload")}
      </Link>
    </WarningBox>
  ) : null;
};

export default withTranslation()(PatternsUpdatedWarning);
