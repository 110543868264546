import {CSSProperties} from "react";
import packageInfo from '../package.json';

export namespace AppConstants {
  export namespace About {
    export const appName = "GeoModeling Viewer (BETA)";
    export const appVersion = packageInfo.version;
    export const appVendor = "Peck Tech Consulting Ltd."
    export namespace AppCopyright {
      export const startDate = 2020;
    }
  }
  export namespace Notifications {
    export const style: CSSProperties = {
      position: 'absolute',
      width: '200px',
      background: 'papayawhip',
      transition: 'all 0.5s ease-out',
    }
  }
  export namespace Notification {
    export const style: CSSProperties = {
      position: 'fixed',
      top: 60,
      left: "calc(100vw / 2)",
    }
  }
  // Optional features being evaluated as POCs
  export namespace OptionalFeatures {
    export const holeLabels = true;
    export const corridorSelectionFeature = true;
  }
}
