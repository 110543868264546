import React, {CSSProperties, useCallback, useEffect, useMemo, useState} from "react";
import {useUserSessionContext} from "../Contexts/UserSessionContext";
import IColorLegendConfiguration from "../../domain/IColorLegendConfiguration";
import {IColorLegend, IColorLegendItem} from "../../hooks/useColorLegendApi";
import FloatingContainer from "../BasicControls/FloatingContainer";
import TilesetView from "./TilesetView";
import {
  Cesium3DTileset,
  HeadingPitchRange,
  SceneMode
} from "cesium";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsToEye, faXmark} from "@fortawesome/free-solid-svg-icons";
import HorizontalColorLegend from "./HorizontalColorLegend";
import {AttributeType, IAttributeTypeMap} from "../../domain/AttributeType";
import {useSiteConfig} from "../../hooks/useSiteConfig";
import IColorLegendItemConfiguration from "../../domain/IColorLegendItemConfiguration";
import {LegendType} from "../../domain/LegendType";
import {ITilesetResource} from "./ITilesetResource";
import {LayerType} from "../../../../model/LayerType";
import {ICameraVantagePoint} from "./ICameraVantagePoint";

const STYLE_2D: CSSProperties = {
  cursor: "pointer",
  width: "500px",
  height: "500px",
}

/**
 * The 2D Map is a bit more complicated, with a floating frame and some additional widgets
 * @param props
 * @constructor
 */
const TilesetView2D: React.FC = (props) => {
  const [siteConfiguration] = useSiteConfig();
  const [userSession, userSessionActions] = useUserSessionContext();
  const [cameraVantagePoint2D, setCameraVantagePoint2D] = useState<ICameraVantagePoint>();
  const [rockMassDomainLegendItems, setRockMassDomainLegendItems] = useState<[IColorLegendConfiguration, IColorLegend]|undefined>() ;
  const [clusterLayerProperties, setClusterLayerProperties] = useState<string[]>();

  /**
   * Get the up-to-date color legend configs and state
   */
  const [colorLegendConfig, colorLegendState] = useMemo(()=>{
    if ( !userSession.selectedAttribute || !userSession.colorLegendStates || !siteConfiguration?.legendConfigs) {
      return [undefined, undefined];
    }
    const attributeIndex = AttributeType[userSession.selectedAttribute] as keyof IAttributeTypeMap<IColorLegendConfiguration> ;
    const conf = siteConfiguration.legendConfigs[attributeIndex];
    const state = userSession.colorLegendStates.get( userSession.selectedAttribute );
    return [conf, state];
  }, [userSession.selectedAttribute, siteConfiguration?.legendConfigs, userSession.colorLegendStates]);

  /**
   * The rock mass domain color legend is based on clusters related to the selected attibute (if these clusters are
   * defined in the assert files)
   */
  const getRockMassDomainStyles = useCallback(( selectedAttribute: AttributeType|undefined ): [IColorLegendConfiguration, IColorLegend]|undefined => {
    let config: IColorLegendConfiguration|undefined = undefined ;
    let state: IColorLegend|undefined = undefined ;

    if ( selectedAttribute && clusterLayerProperties && userSession.rockMassDefinitions ) {
      let attributeString = AttributeType[selectedAttribute];

      if ( clusterLayerProperties.indexOf(attributeString + "_Domain")) {
        let relatedConfig = colorLegendConfig;
        let relatedState = colorLegendState;

        if (relatedConfig && relatedState) {

          // ... Get the styles for each range's midpoint
          let ranges = userSession.rockMassDefinitions.get(attributeString + "_Domain");
          if (ranges) {

            let configItems: IColorLegendItemConfiguration[] = [];
            let stateItems: IColorLegendItem[] = [];

            // ... Repeat for each range
            for ( let i = 0; i < ranges.length; i += 1 ) {
              const range = ranges[i];
              const midRange = (range.min + range.max) / 2;

              // ... Invalid range?
              if ( midRange < 0 ) {
                configItems.push(relatedConfig.invalidItem) ;
                stateItems.push(relatedState.colorInvalidValue) ;
                continue ;
              }

              // ... Get the item index
              let results = relatedConfig.items.filter( item =>
                  ( ( midRange >= item.range[0] ) && (item.range.length === 1 || midRange < item.range[1]) ) )

              // ... No match?
              if ( !results || results.length === 0 ) {
                results = [relatedConfig.items[relatedConfig.items.length-1]];
              }

              // ... We have a match with one of the range items
              let itemIdx = relatedConfig.items.indexOf( results[0] );
              if ( itemIdx === -1 ) {
                throw new Error("getRockMassDomainStyles: error finding mid point range");
              }
              configItems.push({
                ...relatedConfig.items[itemIdx],
                label: range.Name,
                range: [itemIdx]
              }) ;
              stateItems.push(relatedState.colorLegendItems[itemIdx]) ;
            }

            config = {
              legendType: LegendType.Enum,
              invalidItem: relatedConfig.invalidItem,
              rangeExceededItem: relatedConfig.rangeExceededItem,
              items: configItems,
              maskedColor: relatedConfig.maskedColor,
              defaultRange: ranges.length,
              units: '',
              precision: 0
            };

            state = {
              attributeType: selectedAttribute,
              colorInvalidValue: relatedState.colorInvalidValue,
              colorRangeExceeded: relatedState.colorRangeExceeded,
              colorLegendItems: stateItems
            }
          }
        }
      }
    }
    return (config && state) ? [config, state] : undefined ;
  }, [clusterLayerProperties, colorLegendState, colorLegendConfig, userSession.rockMassDefinitions]);

  /**
   * Update the rock mass domain legend when the selected attribite changes
   */
  useEffect(()=>{
    let styles = getRockMassDomainStyles( userSession.selectedAttribute );
    setRockMassDomainLegendItems( styles );
  }, [clusterLayerProperties, getRockMassDomainStyles, userSession.selectedAttribute]);

  /**
   * We do the clusters info handling here instead of inside the generic viewer because it makes more sense that way.
   */
  const handleAllTilesLoad = useCallback((tileset: Cesium3DTileset, resource: ITilesetResource) => {
    // ... Only look at blast hole clusters
    if ( resource.layerType === LayerType.BlastholeClusters ) {
      setClusterLayerProperties( Object.keys( tileset.properties ) );
    }
  }, []);

  /**
   * Top right close button
   */
  const handleCloseMap2D = useCallback((e: React.MouseEvent<HTMLDivElement>)=> {
    e.stopPropagation();
    e.preventDefault();
    userSessionActions.setShowMap2D( false ) ;
  }, [userSessionActions]);

  // useEffect(()=>{console.log(`userSession.floatingMapSnapPosition=${JSON.stringify(userSession.floatingMapSnapPosition)}`);}, [userSession.floatingMapSnapPosition]);

  return (
      <>
        {userSession.showMap2D && userSession.originReference && userSession.blockSize &&
            <FloatingContainer
                name={"GeoModelingView2D"}
                className={"w3-round"}
                snapPosition={userSession.floatingMapSnapPosition}
                onPositionChanged={userSessionActions.setFloatingMapSnapPosition}
            >
              {/* THE 2D VIEW */}
              <TilesetView
                  style={STYLE_2D}
                  className={'w3-border'}
                  sceneMode={SceneMode.SCENE2D}
                  cameraVantagePoint={cameraVantagePoint2D}
                  handleAllTilesLoad={handleAllTilesLoad}
                  linearSelectionMode={false}
                  onLinearSelectionModeChanged={()=>{}}
              >

                {/* X button to close the window */}
                <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      zIndex: "2"
                    }}
                    onClick={handleCloseMap2D}
                >
                  <FontAwesomeIcon
                      icon={faXmark}
                      className={"w3-hover-text-light-blue w3-padding"}
                  />
                </div>

                {/* BOTTOM BAR */}
                <div
                    className={'w3-bar w3-text-white'}
                    style={{
                      position: "absolute",
                      width: "100%",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      zIndex: "2"
                    }}
                >
                  {/* CAMERA RESET BUTTON */}
                  <span
                      className={"w3-bar-item"}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#444444aa",
                        width: "50px"
                      }}
                  >
                    <FontAwesomeIcon
                        className={"w3-hover-text-light-blue"}
                        style={{
                          color: "#FFFFFFbb",
                        }}
                        icon={faArrowsToEye}
                        onClick={() => {
                          if ( userSession.boundingSphere ) {
                            setCameraVantagePoint2D({
                              boundingSphere: userSession.boundingSphere,
                              hpr: new HeadingPitchRange(0, -Math.PI / 2, 2 * userSession.boundingSphere.radius)
                            })
                          }
                        }}
                    />
                  </span>
                  {/* HORIZONTAL COLOR LEGEND (FOR CLUSTERS) */}
                  {rockMassDomainLegendItems && (
                      <HorizontalColorLegend
                          style={{
                            width: `calc( 100% - 50px )`
                          }}
                          colorLegendConfig={rockMassDomainLegendItems[0]}
                          colorLegendState={rockMassDomainLegendItems[1]}
                      />
                  )}
                </div>
              </TilesetView>
            </FloatingContainer>
        }
      </>
  )
}

export default React.memo( TilesetView2D );
