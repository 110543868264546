import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {t} from "i18next";

export interface INotificationDialogProps extends WithTranslation {
  title: string;
  prompt: string;
  onDismiss: () => void;
  extra?: React.ReactNode
}

const BORDER_RADIUS = "5px";

const NotificationDialog: React.FC<INotificationDialogProps> = ({
  title,
  prompt,
  onDismiss,
  extra
}) => {
  return (
    <>
      <div className="w3-modal" style={{ display: "block" }}>
        <div
          className="w3-modal-content w3-theme-d4 w3-card-4"
          style={{ borderRadius: BORDER_RADIUS }}
        >
          <header
            className="w3-container w3-light-blue w3-center"
            style={{
              borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0px 0px`,
            }}
          >
            <h6>{t(title)}</h6>
          </header>

          <div className="w3-container w3-padding">
            <div className="w3-margin w3-center w3-large">{prompt}</div>
          </div>

          { extra &&
              <div className="w3-container w3-padding">
                <div className="w3-margin w3-center">{extra}</div>
              </div>
          }

          <footer
            className="w3-container w3-center w3-theme-d3 w3-padding"
            style={{
              borderRadius: `0px 0px ${BORDER_RADIUS} ${BORDER_RADIUS}`,
            }}
          >
            <button
              className="w3-btn w3-grey w3-round-medium w3-hover-light-blue"
              onClick={onDismiss}
            >
              {t("Dismiss")}
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(NotificationDialog) ;