export enum CollarAppearance {
  Sphere,
  Disk,
  Point
}

export interface ICollarStyle {
  collarDiameter: number;
  collarAppearance: CollarAppearance;
}

export const DEFAULT_COLLAR_STYLE: ICollarStyle = {
  collarDiameter: 0.75,//0.3048,
  collarAppearance: CollarAppearance.Disk
};
