import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

import Help_SiteConfig_BaseTransformation from "./Help_SiteConfig_BaseTransformation";
import Help_SiteConfig_ColorLegend from "./Help_SiteConfig_ColorLegend";

/**
 * Help page for using the Pattern Explorer page.
 *
 *
 * Saving changes
 * Click on the 'Save' button to apply your changes.  This will save the site configuration at the back end server and
 * persist for all users.
 *
 * Undoing changes
 * Click on the 'Undo' button to undo any changes you have made.
 *
 * @param props
 * @constructor
 */

const Help_SiteConfig: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <div className={"w3-container"}>
        <h1>{t('Using the Site Configuration')}</h1>

        <div className={'w3-panel'}>
          <p className={"text-full-justify"}>
            {t("The Site configuration page allows an administrator user to set site-specific configuration parameters " +
                "that will apply to all users using this application.")}
          </p>
          <img src={"/help/site_config_1.jpg"} className={'w3-block'}/>

        </div>

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <Help_SiteConfig_BaseTransformation />

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <Help_SiteConfig_ColorLegend />

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>

        <div className={'w3-panel'}>
          <h3 className={"w3-left-align"}>{t("Saving and Undoing Changes")}</h3>
          <p className={"text-full-justify w3-margin-right"}>
            {t("Click on the 'Save' button to apply your changes. This will save the site configuration at the back end server and persist for all users.")}
          </p>
          <p className={"text-full-justify w3-margin-right"}>
            {t("Click on the 'Undo' button to undo any changes you have made.")}
          </p>
        </div>

        <hr style={{width: "50%", marginLeft: "auto", marginRight: "auto"}}/>
      </div>
  )
}

export default withTranslation()(Help_SiteConfig);
