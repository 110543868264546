import React, {useState} from "react";
import {RGBColor, SketchPicker} from 'react-color';
import {Color} from "cesium";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

/**
 * Cesium Colors:
 *  alpha: [0, 1]
 *  red: [0, 1]
 *  blue: [0, 1]
 *  green: [0, 1]
 *
 * React-Color:
 *  alpha: [0, 1]
 *  red: [0, 255]
 *  blue: [0, 255]
 *  green: [0, 255]
 */
const cesiumColor2reactColor = (cesiumColor: Color) : RGBColor => {
    return {
        a: cesiumColor.alpha,
        b: cesiumColor.blue * 255,
        g: cesiumColor.green * 255,
        r: cesiumColor.red * 255,
    } as RGBColor;
};

const reactColor2CesiumColor = (reactColor: RGBColor) : Color => {
    return new Color(reactColor.r / 255, reactColor.g / 255, reactColor.b / 255, reactColor.a );
};

const BORDER_RADIUS = "5px";

export interface IColorPickerDialogProps {
    color: Color,
    onColorSelected: (color: Color)=> void;
    onCancel: () => void;
}

const ColorPickerDialog : React.FC<IColorPickerDialogProps> =
({
    color,
    onColorSelected,
    onCancel
}) => {
    const [selectedColor, setSelectedColor] = useState<RGBColor>(cesiumColor2reactColor(color));

    return (
        <>
            <div className="w3-modal" style={{display: "block"}}>
                <div className="w3-modal-content w3-card-4 w3-theme-d4" style={{borderRadius: BORDER_RADIUS,  width: "250px"}}>
                    <header className="w3-container w3-light-blue w3-center" style={{borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0px 0px`}}>
                        <h6>
                            {t("color_selection")}
                        </h6>
                    </header>
                    <div className={"w3-panel"}>
                        <SketchPicker className="w3-centered" color={selectedColor} onChangeComplete={c => setSelectedColor(c.rgb)}/>
                    </div>
                    <footer className="w3-container w3-theme-d3 w3-padding" style={{borderRadius: `0px 0px ${BORDER_RADIUS} ${BORDER_RADIUS}`}}>
                        <button className="w3-btn w3-left w3-grey w3-round-medium" onClick={onCancel}>
                            {t("cancel")}
                        </button>
                        <button className="w3-btn w3-right w3-light-blue w3-round-medium" onClick={()=>onColorSelected(reactColor2CesiumColor(selectedColor))}>
                            {t("accept")}
                        </button>
                    </footer>

                </div>
            </div>
        </>
    );
}

export default withTranslation()(ColorPickerDialog) ;
