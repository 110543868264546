import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

/**
 * Help page for using the Pattern Explorer page.
 *
 * @param props
 * @constructor
 */

const Help_PatternExplorer_FilterPatterns: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <>
        <div className={"w3-panel"}>
          <h2 className={"w3-left-align"}>{t("Filtering the Pattern List")}</h2>
          <p className={"text-full-justify"}>
            {t("Click one the 'Filter Patterns' section title at the top right hand side of the page, this will expand " +
                "or collapse the pattern table filters section.")}
          </p>
          <div className={"w3-row"}>
            <div className={"w3-section w3-col w3-half"}>
              <ul>
                <li className={"w3-left-align w3-padding-small"}>
                  {t("You can filter the table by date/time range, or by the pattern name.")}
                </li>
                <li className={"w3-left-align w3-padding-small"}>
                  {t("Click the 'Show Inactive' checkbox to show/hide patterns that have been marked as 'inactive'")}
                </li>
                <li className={"w3-left-align w3-padding-small"}>
                  {t("Click one the 'Reset Filters' button to reset the filters to their default values")}
                </li>
              </ul>
            </div>
            <img src={"/help/filter_patterns.jpg"} className={"w3-col w3-half w3-margin-top"}/>
          </div>
        </div>
      </>
  )
}

export default withTranslation()(Help_PatternExplorer_FilterPatterns);
