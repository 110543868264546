import {useCallback} from "react";

export interface IEdgeDistances {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}

export enum Adhesiveness {
  None, // positioned freely anywhere
  Left = 0x01,
  Top = 0x02,
  Right = 0x04,
  Bottom = 0x08,
}

export interface ISnapPosition {
  adhesiveness: Adhesiveness;
  x: number;
  y: number;
}

const STICKY_DISTANCE = 15 ;

export const useSnapPositioning = (margin: IEdgeDistances):[(el: HTMLElement, offsetLeft?: number, offsetTop?: number)=>ISnapPosition] => {

  const marginLeft = margin.left ?? 0;
  const marginRight = margin.right ?? 0;
  const marginTop = margin.top ?? 0;
  const marginBottom = margin.bottom ?? 0;

  /**
   * Detects if there is proximity to edges, for a given element
   * @param el
   * @param offsetLeft
   * @param offsetTop
   */
  function detectStickiness( el: HTMLElement, offsetLeft?: number, offsetTop?: number ): Adhesiveness {
    let result = Adhesiveness.None;
    let parent = el.parentElement;
    if ( parent ) {

      const left = offsetLeft ?? el.offsetLeft;
      const top = offsetTop ?? el.offsetTop;

      const distFromLeft = left;
      const distFromTop = top;
      const distFromRight = parent.offsetWidth - (left + el.offsetWidth);
      const distFromBottom = parent.offsetHeight - (top + el.offsetHeight);

      if (distFromLeft <= marginLeft + STICKY_DISTANCE) {
        result |= Adhesiveness.Left;

      } else if (distFromRight <= marginRight + STICKY_DISTANCE) {
        result |= Adhesiveness.Right;
      }

      if (distFromTop <= marginTop + STICKY_DISTANCE) {
        result |= Adhesiveness.Top;
      }
      if (distFromBottom <= marginBottom + STICKY_DISTANCE) {
        result |= Adhesiveness.Bottom;
      }
    }
    return result ;
  }

  /**
   * The callback being provided
   */
  const getSnapPosition = useCallback((el: HTMLElement, offsetLeft?: number, offsetTop?: number): ISnapPosition =>{
    let parentEl = el.parentElement ;

    const left = offsetLeft ?? el.offsetLeft;
    const top  = offsetTop ?? el.offsetTop;

    let x, y;

    let adhesiveness = detectStickiness( el, offsetLeft, offsetTop );

    if ((adhesiveness & Adhesiveness.Left) == Adhesiveness.Left) {
      x = marginLeft;
    }
    else if ((adhesiveness & Adhesiveness.Right) == Adhesiveness.Right) {
      if ( !parentEl ) {
        throw new Error("getSnapPosition: cannot position without a parent element");
      }
      x = parentEl.offsetWidth - marginRight - el.offsetWidth;
    }
    else {
      x = left;
    }

    if ((adhesiveness & Adhesiveness.Top) == Adhesiveness.Top) {
      y = marginTop;
    }
    else if ((adhesiveness & Adhesiveness.Bottom) == Adhesiveness.Bottom) {
      if ( !parentEl ) {
        throw new Error("getSnapPosition: cannot position without a parent element");
      }
      y = parentEl.offsetHeight - marginBottom - el.offsetHeight;
    }
    else {
      y = top;
    }

    return { adhesiveness, x, y } ;

  }, [])

  return [getSnapPosition];
}
