import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

/**
 * Help page for using the Pattern Explorer page.
 *
 * @param props
 * @constructor
 */

const Help_PatternExplorer_RecentSessions: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <>
        <div className={"w3-panel"}>
          <h2 className={"w3-left-align"}>{t("Recent Sessions")}</h2>
          <div className={"w3-row"}>
            <div className={"w3-col w3-half w3-section"}>
              <p className={"text-full-justify"}>
                {t("Each time you view one or more patterns, the set of selected patterns is saved in what we call a user " +
                    "session.  The section entitled 'Recent Sessions' contains a list of 'session cards' created by you and " +
                    "sorted by the most recently viewed date/time.")}
              </p>
              <ul>
                <li className={"w3-left-align w3-padding-small"}>
                  {t("Click on a session card to select that user session.")}
                </li>
                <li className={"w3-left-align w3-padding-small"}>
                  {t("Click on the delete button on the session card to delete the session.")}
                </li>
                <li className={"w3-left-align w3-padding-small"}>
                  {t("Click on the 'Open' button on the session card to view the session patterns in a 3D view.")}
                </li>
              </ul>
            </div>
            <img src={"/help/recent_sessions.jpg"} className={"w3-col w3-half w3-padding-small w3-section"}/>
          </div>

          <h3 className={"w3-left-align"}>{t("Modifying a Session's Pattern List")}</h3>
          <div className={"w3-row"}>
            <div  className={"w3-col w3-threequarter w3-section"}>
              <p className={"text-full-justify"}>
                {t("When you select a session, the patterns for that session will automatically be selected in the " +
                    "'Available Patterns' table.  You can add or remove patterns from the sessions, in which case you have " +
                    "the option to save your changes by clicking on the 'Save' button on the session's card; alternatively, " +
                    "you can undo your changes by clicking on undo button on the session card.")}
              </p>
            </div>
            <span><img src={"/help/edit_session.jpg"} className={"w3-col w3-quarter w3-padding-small w3-section"}/></span>
          </div>

          <h3 className={"w3-left-align"}>{t("Renaming Sessions")}</h3>
          <div className={"w3-row"}>
            <div  className={"w3-col w3-half w3-section"}>
              <p className={"text-full-justify"}>
                {t("When you select a session, the patterns for that session will automatically be selected in the " +
                    "'Available Patterns' table.  You can add or remove patterns from the sessions, in which case you have " +
                    "the option to save your changes by clicking on the 'Save' button on the session's card; alternatively, " +
                    "you can undo your changes by clicking on undo button on the session card.")}
              </p>
            </div>
            <span><img src={"/help/rename_session.jpg"} className={"w3-col w3-half w3-padding-small w3-section"}/></span>
          </div>
        </div>
      </>
  )
}

export default withTranslation()(Help_PatternExplorer_RecentSessions);
