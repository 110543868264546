import React from "react";
import {CameraFrustum} from "../../domain/CameraFrustum";
import CameraFrustrumSelector from "../BasicControls/CameraFrustrumSelector";
import {t} from "i18next";
import {withTranslation, WithTranslation} from "react-i18next";

interface IProps extends WithTranslation {
  id?:string;
  selection: CameraFrustum;
  onCameraFrustrumChanged: (selection: CameraFrustum)=>void;
}

const CameraProjectionSideMenuItem: React.FC<IProps> = (props) => {
  return (
      <div id={props.id} className={"w3-container w3-cell-row w3-margin-top"}>
        <div
            className={"w3-cell w3-cell-middle w3-left-align unselectable"}
            style={{ fontSize: "12px"}}
        >
          {t("camera_frustrum")}
        </div>
        <div className={"w3-cell w3-cell-middle"}>
          <CameraFrustrumSelector selection={props.selection} onSelectionChanged={props.onCameraFrustrumChanged}/>
        </div>
      </div>
  )
}

export default withTranslation()(CameraProjectionSideMenuItem);
