import React, {CSSProperties, useEffect} from "react";
import useResizeObserver from "use-resize-observer";

interface IContentPageProps {
  className?:string|undefined;
  style?:CSSProperties|undefined;
  w3BorderColor?:string|undefined;
  onSizeChanged?:(w: number, h: number)=>void|undefined;
}

const ContentPage: React.FC<IContentPageProps> = (props)=> {

  const w3BorderColor = props.w3BorderColor ? `w3-border ${props.w3BorderColor}`: '';

  const {ref, width=1, height=1} = useResizeObserver<HTMLDivElement>();

  const onSizeChanged = props.onSizeChanged;
  useEffect(()=>{
    if ( width && height ) {
      onSizeChanged && onSizeChanged( width, height );
    }
  }, [width, height, onSizeChanged]);

  return (
      <>
        <div
            ref={ref}
            className={`w3-row w3-mobile ${props.className} ${w3BorderColor}`}
            style={{
              ...props.style, position: "absolute", top: "40px", bottom: "24px", width: "100%",
              overflowY: "hidden" // ets rid of momentary vscroll that appears as heights get recalculated (ex: when a collapsible section gets expaned)
            }}
        >
          {props.children}
        </div>
      </>
  )
}

export default ContentPage;
