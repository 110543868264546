import {ICustomerSite} from "../../pages/CustomerSites";
import {withTranslation, WithTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import ListItem from "../List/ListItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faWarning, faXmark} from "@fortawesome/free-solid-svg-icons";
import {IP_ADDRESS_PATTERN} from "./NewCustomerForm";
import {t} from "i18next";

interface IProps extends WithTranslation {
  customerSite: ICustomerSite;
  onCustomerSiteChanged: (customerSite: ICustomerSite) => void;
  isEditing: boolean;
  onEditing: (isEditing: boolean)=>void;
  onDelete: ()=>void;
  colWidths: number[];
}

const validateCustomerSiteRow = (fields: ICustomerSite) => {
  let errors: any = {};
  if (!fields.name) errors.name = `${t("Customer Site Name")} ${t("cannot_be_empty")}`;
  if (!fields.nick) errors.nick = `${t("Nickname / Abbreviation")} ${t("cannot_be_empty")}`;
  if (fields.dmmHost) {
    if (!IP_ADDRESS_PATTERN.test(fields.dmmHost)) errors.dmmHost = `${t("")} ${t("must be an IP address")}`;
    if ( Number.isNaN(fields.dmmPublicPort) ) errors.dmmPublicPort = `${t("DMM Public Port")} ${t("must be a valid port number")}`;
    if ( Number.isNaN(fields.dmmPrivatePort) ) errors.dmmPrivatePort = `${t("DMM Private Port")} ${t("must be a valid port number")}`;
  }

  return errors ;
}

const CustomerSiteRow: React.FC<IProps> = (props) => {
  const [localCustomerSite, setLocalCustomerSite] = useState(props.customerSite);
  const [editedField, setEditedField] = useState<string|undefined>();
  const [errors, setErrors] = useState<any>({});

  const isEditing = props.isEditing ;
  useEffect(()=>{
    if ( !isEditing ) {
      setEditedField( undefined ) ;
    }
  }, [isEditing]);

  const handleContentChanged = (item: "name"|"nick"|"dmmHost"|"dmmPublicPort"|"dmmPrivatePort", value: any) => {
    switch( item ) {
      case "name":
        setLocalCustomerSite( prevState => {
          const newState = {...prevState, name: value };
          setErrors(validateCustomerSiteRow(newState))
          return newState;
        });
        break;
      case "nick":
        setLocalCustomerSite( prevState => {
          const newState = {...prevState, nick: value };
          setErrors(validateCustomerSiteRow(newState))
          return newState;
        });
        break;
      case "dmmHost":
        setLocalCustomerSite( prevState => {
          const newState = {...prevState, dmmHost: value };
          setErrors(validateCustomerSiteRow(newState))
          return newState;
        });
        break;
      case "dmmPublicPort":
        setLocalCustomerSite( prevState => {
          const newState = {...prevState, dmmPublicPort: value };
          setErrors(validateCustomerSiteRow(newState))
          return newState;
        });
        break;
      case "dmmPrivatePort":
        setLocalCustomerSite( prevState => {
          const newState = {...prevState, dmmPrivatePort: value };
          setErrors(validateCustomerSiteRow(newState))
          return newState;
        });
        break;
    }
  }

  const onCustomerSiteChanged = props.onCustomerSiteChanged ;
  useEffect(()=>{
    if ( Object.keys(errors).length === 0 ) {
      console.log(`onCustomerSiteChanged: ${JSON.stringify(localCustomerSite)}`);
      onCustomerSiteChanged(localCustomerSite);
    } else {
      console.warn(`onCustomerSiteChanged: there are ${Object.keys(errors).length} errors: ${JSON.stringify(errors)}`);
    }
  }, [localCustomerSite, errors, onCustomerSiteChanged]);

  function handleEditing(v: "name"|"nick"|"dmmHost"|"dmmPublicPort"|"dmmPrivatePort"|undefined) {
    console.log(`handleEditing: ${v}`)

    setEditedField(v);
    props.onEditing(v !== undefined);
  }

  function handleDeleteRow() {
    props.onDelete();
  }

  return (
      <>
        <ListItem>
          <EditableField
              width={`${props.colWidths[0]}%`}
              content={localCustomerSite.name}
              isEditing={props.isEditing && editedField==='name'}
              onEditing={b=>handleEditing(b ? 'name': undefined)}
              onContentChanged={(value)=>handleContentChanged('name', value)}
              error={errors.name}
          />
          <EditableField
              className={'w3-center'}
              width={`${props.colWidths[1]}%`}
              content={localCustomerSite.nick}
              isEditing={editedField==='nick'}
              onEditing={b=>handleEditing(b ? 'nick': undefined)}
              onContentChanged={(value)=>handleContentChanged('nick', value)}
              error={errors.nick}
          />
          <EditableField
              className={'w3-center'}
              width={`${props.colWidths[2]}%`}
              content={localCustomerSite.dmmHost}
              isEditing={editedField==='dmmHost'}
              onEditing={b=>handleEditing(b ? 'dmmHost': undefined)}
              onContentChanged={(value)=>handleContentChanged('dmmHost', value)}
              error={errors.dmmHost}
          />
          <EditableField
              className={'w3-center'}
              width={`${props.colWidths[3]}%`}
              content={localCustomerSite.dmmPublicPort}
              isEditing={editedField==='dmmPublicPort'}
              onEditing={b=>handleEditing(b ? 'dmmPublicPort': undefined)}
              onContentChanged={(value)=>handleContentChanged('dmmPublicPort', value)}
              error={errors.dmmPublicPort}
          />
          <EditableField
              width={`${props.colWidths[4]}%`}
              className={'w3-center'}
              content={localCustomerSite.dmmPrivatePort}
              isEditing={editedField==='dmmPrivatePort'}
              onEditing={b=>handleEditing(b ? 'dmmPrivatePort': undefined)}
              onContentChanged={(value)=>handleContentChanged('dmmPrivatePort', value)}
              error={errors.dmmPrivatePort}
          />
          <div
              className="w3-cell w3-cell-middle w3-opacity-max w3-hover-opacity-off w3-right-align"
              style={{ width: `${props.colWidths[5]}%` }}
              onClick={()=>handleDeleteRow()}
          >
            <FontAwesomeIcon icon={faXmark}/>
          </div>
        </ListItem>
      </>
  )
}

export default withTranslation()(CustomerSiteRow);

interface IEditableFieldProps {
  content: any;
  width: string;
  isEditing: boolean;
  onEditing: (isEditing: boolean)=>void;
  onContentChanged: (content: any)=>void;
  error?:any;
  className?:string|undefined;
}

const EditableField: React.FC<IEditableFieldProps> = (props) => {
  const [contentLocal, setContentLocal] = useState<any>(props.content);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setContentLocal( e.target.value );
  }

  function handleSubmit() {
    props.onContentChanged( contentLocal );
    props.onEditing( false ) ;
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if ( e.key === "Enter" ) {
      e.stopPropagation();
      e.preventDefault()
      handleSubmit();
    }
  }

  return (
      <div
          className={`w3-cell w3-cell-middle ${props.className}`}
          style={{ width: props.width }}
          onClick={(e)=>props.isEditing && e.stopPropagation()}
          onDoubleClick={()=>!props.isEditing && props.onEditing(true)}
      >
        { props.isEditing
          ? (
              <>
                <input
                    value={contentLocal}
                    style={{width: "calc(100% - 50px)"}}
                    onChange={(e)=>handleChange(e)}
                    onKeyDown={(e)=>handleKeyDown(e)}
                />
                {props.error
                    ? (<span className="w3-text-red"><FontAwesomeIcon icon={faWarning} /> {props.error}</span>)
                    : <FontAwesomeIcon icon={faCircleCheck} style={{paddingLeft: "5px"}} onClick={()=>handleSubmit()}/>
                }
              </>
            )
          : props.content
        }
      </div>
  )
}
