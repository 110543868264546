import { Link } from "react-router-dom";
import LanguageButtons from "../LanguageButtons";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import React, {useCallback} from "react";
import LogoutComponent from "./LogoutComponent";
import UserSession from "../../lib/userSession";
import NavMenu from "./NavMenu";
import HelpMenu from "../Gen2/components/Widgets/HelpMenu";
import {
  faUser
} from "@fortawesome/free-solid-svg-icons";
import MessagesNavMenu from "./MessageNavMenu";
import ScreenRecorder from "../Gen2/components/Widgets/ScreenRecorder";
import {useScreenRecording} from "../Gen2/components/Contexts/ScreenRecordingContextProvider";

const Nav: React.FC = () => {
  const username = UserSession.getUsername();
  const [screenRecording, screenRecordingActions] = useScreenRecording();

  const getExtraStyle = useCallback((path:string)=>{
    // console.log(`window.location.pathname=${window.location.pathname}`);
    return ( path === window.location.pathname ) ? "w3-text-light-blue text-bold" : undefined;
  }, []);

  const isSupported = screenRecording.isSupported;
  const isEnabled = screenRecording.isEnabled;
  const isRecording = screenRecording.isRecording;
  const handleToggleRecordBtn = useCallback(() => {
    screenRecordingActions.setIsEnabled( isSupported && ! isEnabled );
  }, [isSupported, isEnabled, screenRecordingActions]);

  return (
    <div className="w3-bar w3-theme-d6">

      {UserSession.isInRoles(["DEV"]) && (
          <>
            <Link to="/customerSites" className={`w3-bar-item w3-button w3-mobile ${getExtraStyle("/customerSites")}`}
            >
              {t("Customers Management")}
            </Link>
            <Link to="/activeUsers" className={`w3-bar-item w3-button w3-mobile ${getExtraStyle("/activeUsers")}`}
            >
              {t("Active Users")}
            </Link>
          </>
      )}

      {UserSession.isInRoles(["SITE_ADMIN", "SITE_USER"]) && (
        <Link to="/assets" className={`w3-bar-item w3-button w3-mobile ${getExtraStyle("/assets")}`}
        >
          {`${t("patterns_explorer")}`}
        </Link>
      )}
      {UserSession.isInRoles(["SITE_ADMIN"]) && (
        <Link to="/siteConfig" className={`w3-bar-item w3-button w3-mobile ${getExtraStyle("/siteConfig")}`}
        >
          {t("site_configuration")}
        </Link>
      )}
      {UserSession.isInRoles(["SITE_ADMIN"]) && (
        <NavMenu title={t("Administrator")} className={getExtraStyle("/userAdmin")}>
          {UserSession.isInRoles(["SITE_ADMIN"]) && (
              <>
                <Link to="/userAdmin" className={`w3-bar-item w3-button w3-mobile`}>
                  {t("user_administration")}
                </Link>
                { isSupported && (
                    <button
                        className={`w3-bar-item w3-button w3-mobile`}
                        onClick={handleToggleRecordBtn}
                        disabled={isRecording}
                    >
                      {t(isEnabled? "Disable Screen Recording":"Enable Screen Recording")}
                    </button>
                )}
              </>
          )}
        </NavMenu>
      )}

      {window.location.pathname === "/viewer" && (
          <NavMenu title={t("Session Viewer")} className={getExtraStyle("/viewer")}/>
      )}

      {UserSession.isInRoles(["SITE_ADMIN"]) && (
          <ScreenRecorder
            className={"w3-button w3-red w3-round w3-small w3-hover-light-blue w3-margin-left"}
            style={{
              marginTop: "2px"
            }}
            // idealWidth={2560} idealHeight={2048}
          />
      )}

      {/*{UserSession.isInRoles(["SITE_ADMIN", "SITE_USER"]) && (*/}
      {/*  <UserEventComponent />*/}
      {/*)}*/}

      {/*<Link to={"/test"} className="w3-bar-item w3-button w3-mobile">TEST</Link>*/}
      <LogoutComponent />
      <NavMenu title={""} icon={faUser} className={`w3-right ${getExtraStyle("/userProfile")}`}>
        <Link to="/userProfile" className={`w3-bar-item w3-button w3-mobile`}
        >
          {`${t("profile")} (${username})`}
        </Link>
      </NavMenu>
      <div className="w3-right" style={{ marginRight: "1em" }}>
        <LanguageButtons variant="navbar" />
      </div>
      {UserSession.isInRoles(["SITE_ADMIN", "SITE_USER"]) && (
        <HelpMenu label={"Help"}/>
      )}

      {UserSession.isInRoles(["SITE_ADMIN", "SITE_USER"]) && (
        <MessagesNavMenu className={'w3-right'}/>
      )}

      {/*{UserSession.isInRoles(["DEV"] ) && (*/}
      {/*    <TextAvatarCollection*/}
      {/*        className={'w3-bar-item w3-mobile'}*/}
      {/*        style={{marginTop: "3px"}}*/}
      {/*        height={"32px"}*/}
      {/*        avatars={[*/}
      {/*          { label: "SM", type: "normal", color: "red" },*/}
      {/*          { label: "VS", type: "solid", color: "green" },*/}
      {/*          { label: "RP", type: "solid", color: "cyan" },*/}
      {/*          { label: "CK", type: "normal", color: "yellow" },*/}
      {/*          { label: "JK", type: "solid", color: "orange" },*/}
      {/*        ]}*/}
      {/*    />*/}
      {/*)}*/}

    </div>
  );
};

export default withTranslation()(Nav);

