/*
The `window.env` object is injected at runtime for dynamic variables
*/
const apiEndpoint =
  (window
    ? (window as any).env?.REACT_APP_API_ENDPOINT
    : process.env.REACT_APP_API_ENDPOINT) || "http://localhost:8080";

const apiBasePath = "api/4.0"; // "public"

export const apiRoot = `${apiEndpoint}/${apiBasePath}`;

// Auth controller
export class AuthApi {
  public static postLoginUrl = () => `${apiRoot}/auth/login`;
  public static postLogoutUrl = () => `${apiRoot}/auth/logout`;
  public static postRefreshTokenUrl = () => `${apiRoot}/auth/refresh`;
}
/*
 * User account API
 *
 * Note: the 'targetSiteId' optional parameter must be used when the user is the superuser (i.e., The Peck Tech
 * developer that is not tied to a specific customer site).
 */
export class UserAccountApi {
  public static listUserAccountsUrl = (targetSiteId?: string) =>
    `${apiRoot}/user${targetSiteId ? "?targetSiteId=" + targetSiteId : ""}`;
  public static getUserAccountByIdUrl = (
    username: string,
    targetSiteId?: string
  ) =>
    `${apiRoot}/user/${username}${
      targetSiteId ? "?targetSiteId=" + targetSiteId : ""
    }`;
  public static createUserAccountUrl = (targetSiteId?: string) =>
    `${apiRoot}/user/${targetSiteId ? "?targetSiteId=" + targetSiteId : ""}`;
  public static updateUserAccountUrl = (targetSiteId?: string) =>
    `${apiRoot}/user/${targetSiteId ? "?targetSiteId=" + targetSiteId : ""}`;
  public static deleteUserAccountByIdUrl = (
    username: string,
    targetSiteId?: string
  ) =>
    `${apiRoot}/user/${username}${
      targetSiteId ? "?targetSiteId=" + targetSiteId : ""
    }`;
  public static updateUserPasswordUrl = (targetSiteId?: string) =>
    `${apiRoot}/user/password${
      targetSiteId ? "?targetSiteId=" + targetSiteId : ""
    }`;
}

/*
 * Configuration API
 */
export class ConfigurationApi {
  public static getSiteConfigurationUrl = () =>
    `${apiRoot}/configuration/SiteConfiguration`;
  public static updateSiteConfigurationUrl = () =>
    `${apiRoot}/configuration/SiteConfiguration`;
}

/*
 * Customer site API
 */
export class CustomerSiteApi {
  public static createCustomerSiteUrl = () => `${apiRoot}/customerSites`;
  public static listCustomerSitesUrl = () => `${apiRoot}/customerSites`;
  public static getCustomerSiteUrl = (customerSiteId: string) =>
    `${apiRoot}/customerSites/${customerSiteId}`;
  public static updateCustomerSiteUrl = (customerSiteId: string) =>
    `${apiRoot}/customerSites/${customerSiteId}`;
  public static deleteCustomerSiteUrl = (customerSiteId: string) =>
    `${apiRoot}/customerSites/${customerSiteId}`;
}

/*
 * User session API
 */
export class UserSessionApi {
  public static listUserSessionsUrl = () => `${apiRoot}/userSession`;
  public static getUserSessionsByIdUrl = (userSessionId: string) =>
    `${apiRoot}/userSession/${userSessionId}`;
  public static createUserSessionUrl = () => `${apiRoot}/userSession`;
  public static updateUserSessionUrl = (userSessionId: string) =>
    `${apiRoot}/userSession/${userSessionId}`;
  public static deleteUserSessionUrl = (userSessionId: string) =>
    `${apiRoot}/userSession/${userSessionId}`;
  // ... Below: deletes all user sessions for logged-in user
  public static deleteAllUserSessionsUrl = () => `${apiRoot}/userSession`;
  public static shareUserSessionUrl = (
    userSessionId: string,
    username: string
  ) => `${apiRoot}/userSession/${userSessionId}?with=${username}`;
  public static updateViewModelUrl = (
    userSessionId: string,
    jsonPath: string
  ) =>
    `${apiRoot}/userSession/${userSessionId}/viewModel?fieldPath=${jsonPath}`;
}

/*
 * Pattern API
 */
const toUrlParams = (key: string, values: string[]): string => {
  return values.map((value) => `${key}=${value}`).join("&");
};

export class PatternAndAssetsApi {
  public static uploadAssetsUrl = (targetSiteId?: string) =>
    `${apiRoot}/asset${targetSiteId ? "?targetSiteId=" + targetSiteId : ""}`;
  public static uploadAssetsUrlV2 = (targetSiteId?: string) =>
    `${apiRoot}/asset/v2${targetSiteId ? "?targetSiteId=" + targetSiteId : ""}`;
  public static updatePatternTagUrl = (
    patternId: string,
    tags: string[],
    targetSiteId?: string
  ) =>
    `${apiRoot}/pattern/${patternId}/tags?${toUrlParams("tag", tags)}${
      targetSiteId ? "&targetSiteId=" + targetSiteId : ""
    }`;
  public static getPatternSummariesUrl = () => `${apiRoot}/pattern/summary`;
  public static getAssetsFilterPatternsUrl = (patterns: string[]) =>
    `${apiRoot}/pattern/asset?pattern=${patterns.join(",")}`;
  public static getPatternSummaryUrl = (patternId: string) =>
    `${apiRoot}/pattern/${patternId}/summary`;
  public static deletePatternUrl = (patternId: string) =>
    `${apiRoot}/pattern/${patternId}`;
}

// *** Deprecated
const getProjectsUrl = () => `${apiEndpoint}/v2/projects`;

// *** Deprecated
const getProjectUrl = (projectUuid: string) =>
  `${apiEndpoint}/v2/projects/${projectUuid}`;
export {
  getProjectsUrl, // Deprecated
  getProjectUrl, // Deprecated
};
