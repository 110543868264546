import React, {useCallback, useEffect, useRef, useState} from "react";
import VSplitter from "./VSplitter";
import VMultiSplitPanelItem, {VMultiSplitPanelItemOptions} from "./VMultiSplitPanelItem";
import "../../vertical_range_editor.css";

interface IVMultiSplitPanelProps {
    className?: string;
    items: { cssColor: string, weight: number, text: string }[];
    onItemWeightsUpdated: (idx1: number, weight1: number, idx2: number, weight2: number)=>void;
    onInsertAfter: (itemIdx: number, equalDistribution?:boolean)=>void;
    onRemoveAt: (itemIdx: number)=>void;
    onColorSelectBtnClicked?: (itemIdx: number)=>void;
    onLabelEdit: (itemIdx: number)=>void;
    options: VMultiSplitPanelItemOptions;
    overrideMouseIsOver?: boolean;
}

interface IDragContext {
    y:number;       // Mouse Y position on mouse down
    idx1: number;   // Index of the resizable div above the splitter
    h1:number;      // Height of the resizable div above the splitter at the time of mouse-down
    idx2: number;   // Index of the resizable div below the splitter
    h2:number;      // Height of the resizable div below the splitter at the time of mouse-down
}

const SPLITTER_HEIGHT: number = 2;

export function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state

    return () => {
        // console.log(">>> forcing update") ;
        setValue(value => value + 1);
    } // update state to force render
}

const VMultiSplitPanel : React.FC<IVMultiSplitPanelProps> =
({
    className,
    items,
    onItemWeightsUpdated,
    onInsertAfter,
    onRemoveAt,
    onColorSelectBtnClicked,
    onLabelEdit,
    options,
    overrideMouseIsOver
}) => {

    const forceUpdate = useForceUpdate();
    const parentRef = useRef(null) ;
    const [splitterContext, setSplitterContext] = useState<IDragContext|null>();
    const [mouseOverSplitter, setMouseOverSplitter] = useState<boolean>(false);

    function computeTotalWeight(items: { cssColor: string; weight: number; text: string }[]) {
        let result = 0;
        for ( let i = 0; i < items.length; i += 1 ) {
            result += items[i].weight;
        }
        return result ;
    }

    const [totalWeight, setTotalWeight] = useState<number>(computeTotalWeight(items)) ;

    useEffect( () => {
        // console.log(`>>> VMultiSplitPanel - useEffect[${JSON.stringify(items)}]`) ;
        setTotalWeight(computeTotalWeight(items));
        forceUpdate();
    }, [items]);

    // useEffect( () => {
    //     console.log(`totalWeight=${totalWeight} => items=${JSON.stringify(items)}`);
    // }, [totalWeight]);

    const computeHeight = (idx: number): number => {
        if ( !parentRef.current ) return 0;
        const availableHeight = (parentRef.current as HTMLElement).offsetHeight - (items.length-1) * SPLITTER_HEIGHT;
        let result = 0;
        if (parentRef.current) {
            return items[idx].weight * availableHeight / totalWeight;
        }
        return result ;
    };

    const getOptions = () => {
        if ( splitterContext != null ) {
            return options ;
        } else {
            return options & ~VMultiSplitPanelItemOptions.Resizable;
        }
    };

    /**
     * Updates heights for 2 contiguous neighbours
     * @param dy
     */
    const updateNeighbours = (dy: number) => {
        // console.log(`updateNeighbours: dy=${dy}`);
        if ( !parentRef.current ) return;
        const availableHeight = (parentRef.current as HTMLElement).offsetHeight - (items.length-1) * SPLITTER_HEIGHT;

        if ( !splitterContext ) return;

        const newH1 = splitterContext.h1 + dy ;
        if ( newH1 < 0 ) return;

        const newH2 = splitterContext.h2 - dy ;
        if ( newH2 < 0 ) return;

        // console.log(`>>> calling onItemWeightsUpdated(${splitterContext.idx1}, ${newH1 / availableHeight}, ${splitterContext.idx2}, ${newH2 / availableHeight})`);

        onItemWeightsUpdated(
            splitterContext.idx1,
            newH1 / availableHeight,
            splitterContext.idx2,
            newH2 / availableHeight,
        );
        // forceUpdate();
    };

    // useEffect( ()=> {console.log(`VMultiSplitPanel: render (${JSON.stringify(items)})`)} );

    return (
        <div
            ref={ parentRef }
            className={`${className} container vcontainer`}
            style={{cursor: `${(mouseOverSplitter || splitterContext != null) ? "row-resize" : "inherit"}` }}
            onMouseUp={(e)=>{
                if ( splitterContext ) {
                    setSplitterContext(null);
                    // notifyUpdatedWeights() ;
                }
            }}
            onMouseMove={(e)=>{
                if (splitterContext) {
                    const dy = e.clientY - splitterContext.y;
                    updateNeighbours( dy );
                }
            }}
            onMouseLeave={(e)=>{
                if ( splitterContext ) {
                    setSplitterContext(null);
                }
            }}
        >
            {
                parentRef.current &&
                items.map((item, idx)=>(
                    <React.Fragment
                        key={`VMultiSplitSection_${idx}`}
                    >
                        {/* The splitter bar */}
                        {
                            (idx > 0) && (
                                <VSplitter
                                    className={`${(options & VMultiSplitPanelItemOptions.Resizable) != 0x00 ? "site-config-drag-div":""}`}
                                    height={SPLITTER_HEIGHT}
                                    onMouseOver={(isOver)=>{
                                        setMouseOverSplitter( isOver );
                                    }}
                                    onClick={(y) => {
                                        setSplitterContext( {
                                            y: y,
                                            idx1: idx-1,
                                            h1: computeHeight(idx-1),
                                            idx2: idx,
                                            h2: computeHeight(idx)
                                        } ) ;
                                    }}
                                />
                            )
                        }
                        {/* The color div */}
                        <VMultiSplitPanelItem
                            height={computeHeight(idx)}
                            cssColor={items[idx].cssColor}
                            text={items[idx].text}
                            onSplitBtnClicked={(equalDistribution)=>onInsertAfter(idx, equalDistribution)}
                            onRemoveBtnClicked={()=>onRemoveAt(idx)}
                            onColorSelectBtnClicked={onColorSelectBtnClicked ? ()=>onColorSelectBtnClicked(idx) : undefined }
                            onLabelEditBtnClicked={()=>onLabelEdit(idx)}
                            options={getOptions()}
                            overrideMouseIsOver={overrideMouseIsOver}
                        />
                    </React.Fragment>
                ))
            }
        </div>
    );
}

export default VMultiSplitPanel;
