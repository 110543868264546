export type SegmentDisplayStyle = 'small'|'var'|'large';

export interface ISecondarySegmentOptions {
  segmentDisplayStyle: SegmentDisplayStyle;
}

export function isSegmentDisplayStyle( value: string ) {
  return value === 'small' || value === 'var' ||value === 'large';
}

export function strToSegmentDisplayStyle( value: string ): SegmentDisplayStyle {
  if ( !isSegmentDisplayStyle( value ) ) {
    throw new Error(`strToSegmentDisplayStyle: value '${value}' not a SegmentDisplayStyle`);
  }
  return value as SegmentDisplayStyle;
}
export function segmentDisplayStyleToString( value: SegmentDisplayStyle ): string {
  return value as string;
}
