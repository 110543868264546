import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";

/**
 * Help page for using the Pattern Explorer page.
 *
 *
 * Saving changes
 * Click on the 'Save' button to apply your changes.  This will save the site configuration at the back end server and
 * persist for all users.
 *
 * Undoing changes
 * Click on the 'Undo' button to undo any changes you have made.
 *
 * @param props
 * @constructor
 */

const Help_SiteConfig_BaseTransformation: React.FC<WithTranslation> = (props) => {
  const t = props.t;

  return (
      <>
        <div className={'w3-panel'}>
          <h2 className={"w3-left-align"}>{t("Adjusting the Base Transformation")}</h2>
          <p className={"text-full-justify"}>
            {t("This section is typically defined by Peck tech during setup.  It should never be altered without Peck " +
                "Tech supervision.  Its purpose is to transform the mine-local coordinate system and to position it " +
                "anywhere on the globe.")}
          </p>
        </div>
      </>
  )
}

export default withTranslation()(Help_SiteConfig_BaseTransformation);
