import React, {CSSProperties, useMemo} from "react";

interface IProps {
  src: string;
  className?:string|undefined ;
  style?:CSSProperties|undefined;
}

const VideoClip: React.FC<IProps> = (props)=>{

  const filename = props.src ;
  const fileNameExtension = useMemo((): string => {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }, [filename]);

  const isVideo = useMemo((): boolean => {
    switch( fileNameExtension ) {
      case "webm":
      case "mp4":
        return true ;
      default:
        return false ;
    }
  }, [fileNameExtension]);

  return (
      <>
        { isVideo
            ? <video className={props.className} style={props.style} controls>
                <source src={filename} type={`video/${fileNameExtension}`}/>
              </video>
            : <img src={filename} className={props.className} style={props.style} alt={'video clip'}/>
        }
      </>
  )
}

export default VideoClip;
